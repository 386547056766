import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-top: 2px;
    margin-left: 7px;
    font-family: Graphik LC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--sidebarSelectedIconBackground);
  }
`;

const IconLabelNewFolder = () => (
  <ButtonWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
      <path fill="currentColor" stroke="currentColor" strokeWidth=".3" d="M9.07 2.193v-.15H7.658v5.25h-5.25v1.414h5.25v5.25h1.414v-5.25h5.25V7.293h-5.25v-5.1z" />
    </svg>
    <div>New Folder</div>
  </ButtonWrapper>
);

export default IconLabelNewFolder;
