import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ListItemText } from '@mui/material';

import { StyledListItem, StyledList, Title } from './styles';

const SubjectMenu = ({ section, subjects = {}, noRedirect = false, onChangeSubject }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationsArray = location.pathname.split('/');
  const navTabs = Object.values(subjects);
  const lastRoute = locationsArray.pop();
  const prevTab = navTabs.find((navTab) => lastRoute === navTab.path);

  const [selectedSubject, setSelectedSubject] = useState(prevTab ? prevTab.title : navTabs[0]?.title);

  useEffect(() => {
    if (prevTab && prevTab.title) setSelectedSubject(prevTab.title);
  }, [prevTab]);

  return (
    <StyledList>
      <Title>SUBJECTS</Title>
      {Object.values(subjects).map(({ path, title }) => {
        const handleSubjectClick = () => {
          if (onChangeSubject != null) {
            onChangeSubject(title);
          }
          if (noRedirect) {
            setSelectedSubject(title);
            return;
          }
          navigate(`/help/${section}/${path}`);
          setSelectedSubject(title);
        };

        return (
          <StyledListItem
            key={title}
            selected={selectedSubject === title}
            button
            onClick={handleSubjectClick}
          >
            <ListItemText primary={title} />
          </StyledListItem>
        );
      })}
    </StyledList>
  );
};

export default SubjectMenu;
