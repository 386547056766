import React, { useEffect, useState } from 'react';
import SubscriptionsTable from 'subscriptions/components/SubscriptionsTable';
import SubscriptionsService from 'subscriptions/api/SubscriptionsService';

import { Root, Summary, Block, Text, Digits } from '../styles';

const BrokerCompanyPage = ({ searchFilter }) => {
  const [summary, setSummary] = useState({
    activeSubscriptionsCount: 0,
    litePackageSubscriptionsCount: 0,
    proPackageSubscriptionsCount: 0,
    globalPackageSubscriptionsCount: 0,
  });

  const subscriberType = 'BrokerCompany';

  const fetchSummary = async () => {
    const { data: { data } } = await SubscriptionsService.getSummaryInfo(subscriberType);
    setSummary(data);
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  return (
    <Root>
      <Summary>
        <Block widthPercentage="33%">
          <Text>Total Active Subscribers</Text>
          <Digits>{summary.activeSubscriptionsCount}</Digits>
        </Block>
        <Block widthPercentage="20%">
          <Text>Lite Package</Text>
          <Digits>{summary.litePackageSubscriptionsCount}</Digits>
        </Block>
        <Block widthPercentage="20%">
          <Text>Pro Package</Text>
          <Digits>{summary.proPackageSubscriptionsCount}</Digits>
        </Block>
        <Block widthPercentage="27%">
          <Text>Global Package</Text>
          <Digits>{summary.globalPackageSubscriptionsCount}</Digits>
        </Block>
      </Summary>
      <SubscriptionsTable isCompany searchFilter={searchFilter} subscriberType={subscriberType} />
    </Root>
  );
};

export default BrokerCompanyPage;
