import ApiClient from 'services/ApiClient';

class AccountService extends ApiClient {
  login(data) {
    return this.call({
      method: 'post',
      url: '/account/login',
      data,
      withCredentials: true,
    });
  }

  revokeToken() {
    return this.call({
      method: 'delete',
      baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : process.env.API_HOST,
      url: '/account/revoke-token',
      data: {},
      withCredentials: true,
    });
  }

  signUp(data) {
    return this.call({
      method: 'post',
      url: '/account/signup-user',
      data,
    });
  }

  riskCompanySignUp(data) {
    return this.call({
      method: 'post',
      url: '/account/signup-company/risk',
      data,
    });
  }

  insuranceCompanySignUp(data) {
    return this.call({
      method: 'post',
      url: '/account/signup-company/insurance',
      data,
    });
  }

  brokerCompanySignUp(data) {
    return this.call({
      method: 'post',
      url: '/account/signup-company/broker',
      data,
    });
  }

  forgotPassword(email) {
    return this.call({
      method: 'post',
      url: '/account/forgot-password',
      data: {
        email,
      },
    });
  }

  resetPassword(token, password) {
    return this.call({
      method: 'post',
      url: '/account/reset-password',
      data: {
        token,
        password,
      },
    });
  }

  updatePassword(data) {
    return this.call({
      method: 'post',
      url: '/account/update-password',
      data,
    });
  }

  verifyResetToken(token) {
    return this.call({
      method: 'post',
      url: '/account/verify-reset-password-token',
      data: {
        token,
      },
    });
  }

  getUserInfo() {
    return this.call({
      method: 'get',
      url: '/users/current',
    });
  }

  updateUserInfo(data) {
    return this.call({
      method: 'put',
      url: '/users/current',
      data,
    });
  }

  getICSummary(data) {
    return this.call({
      method: 'post',
      url: '/users/get-insurance-companies-summary',
      data,
    });
  }
}

export default new AccountService();
