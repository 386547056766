import React from 'react';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';

import { StyledInfoCard, Text } from './styles';

const ADMIN_INFO = {
  email: 'sales@imitig8risk.com',
  fullName: 'Sales',
};

const SalesDepartmentModal = ({ onClose }) => (
  <ConfirmationDialog
    open
    onClose={onClose}
    onConfirm={onClose}
    onCancel={onClose}
    title="Sales Department"
    confirmButtonTitle="Close"
    noCancel
  >
    <Text>For any information please contact us at:</Text>
    <StyledInfoCard adminInfo={ADMIN_INFO} fullWidth />
  </ConfirmationDialog>
);

export default SalesDepartmentModal;
