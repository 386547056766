import { loadModules } from 'esri-loader';
import { formatCurrency } from 'utils/CurrencyUtils';

const tivMarkerColors = [
  {
    color: '#083A5E',
    background: '#96CEF6',
  },
  {
    color: '#083A5E',
    background: '#969EF6',
  },
  {
    color: '#083A5E',
    background: '#FAE395',
  },
  {
    color: '#083A5E',
    background: '#FF9E22',
  },
  {
    color: '#083A5E',
    background: '#88EEAC',
  },
  {
    color: '#083A5E',
    background: '#96F6EE',
  },
  {
    color: '#FFFFFF',
    background: '#4205DE',
  },
  {
    color: '#FFFFFF',
    background: '#925E78',
  },
  {
    color: '#FFFFFF',
    background: '#059FDE',
  },
  {
    color: '#FFFFFF',
    background: '#BD93BD',
  },
  {
    color: '#FFFFFF',
    background: '#F87E7E',
  },
  {
    color: '#FFFFFF',
    background: '#3AC09D',
  },
];

// symbols
const globalMapBaseGraphicSymbolGlow = {
  type: 'simple-marker',
  color: 'rgba(150, 206, 246, 0.3);',
  size: '36px',
  outline: { width: 0 },
};

const getGlobalMapBaseGraphicSymbol = (theme) => ({
  type: 'simple-marker',
  color: '#96CEF6',
  size: '16px',
  outline: {
    color: theme.palette.map.outline,
    width: 4,
  },
});

export const getTivRoundMarker = (size, color) => {
  return {
    type: 'simple-marker',
    color,
    size,
    outline: { width: 0 },
  };
};

export const getTivTextSymbol = (size, color, text) => {
  return {
    type: 'text',
    color,
    text,
    // xoffset: 3,
    yoffset: -4,
    font: {
      size,
      weight: 'bold',
    },
  };
};

const mapPinSvgPathString = 'M66.9 41.8c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4 0 11.3 20.4 32.4 20.4 32.4s20.4-21.1 20.4-32.4zM37 41.4c0-5.2 4.3-9.5 9.5-9.5s9.5 4.2 9.5 9.5c0 5.2-4.2 9.5-9.5 9.5-5.2 0-9.5-4.3-9.5-9.5z';
const mapPinOutlineSvgPathString = 'M46 76.234l1.225-1.271c.857-.892 21.017-21.952 21.017-33.845 0-12.264-9.978-22.242-22.241-22.242-12.262 0-22.238 9.978-22.238 22.242 0 11.893 20.156 32.953 21.014 33.844L46 76.234zm0-53.956c10.389 0 18.841 8.452 18.841 18.841 0 8.156-12.622 23.473-18.841 30.188-6.218-6.713-18.838-22.025-18.838-30.188 0-10.389 8.451-18.841 18.838-18.841zM57.264 40.626c0-6.208-5.053-11.259-11.262-11.259-6.209 0-11.262 5.051-11.262 11.259 0 6.21 5.053 11.263 11.262 11.263 6.209 0 11.262-5.053 11.262-11.263zm-19.124 0c0-4.333 3.527-7.859 7.862-7.859s7.861 3.525 7.861 7.859c0 4.335-3.526 7.862-7.861 7.862s-7.862-3.527-7.862-7.862z';

const zoomAction = {
  title: 'Zoom to Location',
  id: 'zoomTo',
  className: 'esri-icon-zoom-in-magnifying-glass',
};

const getColorByStatus = (status) => {
  switch (status) {
    case 'Scheduled':
      return {
        dotColor: 'rgba(242, 201, 76, 1)',
        haloColor: 'rgba(242, 201, 76, 0.1)',
      };
    case 'Progressing':
      return {
        dotColor: 'rgba(58, 192, 157, 1)',
        haloColor: 'rgba(58, 192, 157, 0.1)',
      };
    case 'Completed':
      return {
        dotColor: 'rgba(31, 186, 250, 1)',
        haloColor: 'rgba(31, 186, 250, 0.1)',
      };
    case 'Closed':
      return {
        dotColor: 'rgba(220,220,220, 1)',
        haloColor: 'rgba(220,220,220, 0.1)',
      };
    case 'NotScheduled':
      return {
        dotColor: 'rgba(235, 87, 87, 1)',
        haloColor: 'rgba(235, 87, 87, 0.1)',
      };
    default:
      return {
        dotColor: 'rgba(242, 201, 76, 1)',
        haloColor: 'rgba(242, 201, 76, 0.1)',
      };
  }
};

export const drawGraphic = async (graphicLayer, graphicObject) => {
  graphicLayer.removeAll();
  if (!graphicObject) {
    return;
  }
  const [Graphic] = await loadModules(['esri/Graphic']);
  const graphicPoint = new Graphic(graphicObject);

  graphicLayer.add(graphicPoint);
};

export const drawManyGraphics = async (graphicLayer, graphicObjectsArray) => {
  graphicLayer.removeAll();
  const [Graphic] = await loadModules(['esri/Graphic']);
  const graphicPoints = graphicObjectsArray.map((g) => new Graphic(g));

  graphicLayer.addMany(graphicPoints);
};

export const drawProjectLocations = (graphicLayer, locations) => {
  if (!graphicLayer) {
    return;
  }

  const locationObjectsArray = locations.flatMap((location) => {
    const { address, isPrimary, coordinate: { longitude, latitude } } = location;

    return ([
      {
        geometry: {
          type: 'point',
          longitude,
          latitude,
        },
        symbol: {
          type: 'simple-marker',
          path: mapPinSvgPathString,
          color: isPrimary ? '#EB5757' : '#F2C94C',
          outline: null,
          size: '31px',
        },
        attributes: {
          address,
          coordinates: { longitude, latitude },
        },
      },
      {
        geometry: {
          type: 'point',
          longitude,
          latitude,
        },
        symbol: {
          type: 'simple-marker',
          path: mapPinOutlineSvgPathString,
          color: '#FFF',
          outline: null,
          size: '32px',
        },
        attributes: {
          address,
          coordinates: { longitude, latitude },
        },
      },
    ]);
  });

  drawManyGraphics(graphicLayer, locationObjectsArray);
};

export const drawMapPin = (graphicLayer, { address, longitude, latitude, isPrimary = false }) => {
  if (!graphicLayer) {
    return;
  }

  const geometry = {
    type: 'point',
    longitude,
    latitude,
  };
  const symbol = {
    type: 'simple-marker',
    path: mapPinSvgPathString,
    color: isPrimary ? [248, 126, 126] : [246, 219, 129],
    outline: null,
    size: '32px',
  };

  const attributes = {
    address,
    coordinates: `${Number(latitude).toFixed(6)}  ${Number(longitude).toFixed(6)}`,
  };
  const popupTemplate = {
    title: '{address}',
    content: '{coordinates}',
  };

  drawGraphic(graphicLayer, { geometry, symbol, attributes, popupTemplate });
};

export const drawPoint = (graphicLayer, { address, longitude, latitude }) => {
  if (!graphicLayer) {
    return;
  }

  const geometry = {
    type: 'point',
    longitude,
    latitude,
  };
  const symbol = {
    type: 'simple-marker',
    color: [150, 206, 246],
    outline: {
      color: [255, 255, 255],
      width: 1,
    },
  };
  const attributes = {
    address,
    coordinates: `${Number(latitude).toFixed(6)}  ${Number(longitude).toFixed(6)}`,
  };
  const popupTemplate = {
    title: '{address}',
    content: '{coordinates}',
  };

  drawGraphic(graphicLayer, { geometry, symbol, attributes, popupTemplate });
};

export const drawProjectsData = async (data, graphicsLayer, mapType, theme) => {
  if (!data || !graphicsLayer || !mapType) {
    return;
  }

  graphicsLayer.removeAll();
  let graphicsArray = [];
  const [Graphic] = await loadModules(['esri/Graphic']);

  switch (mapType) {
    case 'dashboard':
      graphicsArray = data.flatMap((project) => {
        const { id, name, location: { address, coordinate: { longitude, latitude } } } = project;
        const url = `${window.location.origin}/projects/${id}/details`;

        return [
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: globalMapBaseGraphicSymbolGlow,
          }),
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: getGlobalMapBaseGraphicSymbol(theme),
            attributes: {
              name,
              address,
              dotColor: getGlobalMapBaseGraphicSymbol(theme).color,
            },
            popupTemplate: {
              title: '{name}',
              content: `<div>${address}</div><br/><a href="${url}" target="_self">Go to Project</a>`,
            },
          }),
        ];
      });
      break;

    case 'inspection':
      graphicsArray = data.flatMap((project) => {
        const {
          id,
          status,
          total,
          remaining,
          scheduled,
          completed,
          name,
          coordinate: { longitude, latitude },
        } = project;

        const url = `${window.location.origin}/projects/${id}/details`;
        const { dotColor, haloColor } = getColorByStatus(status);

        return [
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: { ...globalMapBaseGraphicSymbolGlow, color: haloColor },
          }),
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: {
              type: 'simple-marker',
              size: '16px',
              outline: {
                color: theme.palette.map.outline,
                width: 4,
              },
              color: dotColor,
            },
            attributes: {
              status,
              total,
              remaining,
              scheduled,
              name,
              completed,
              dotColor,
            },
            popupTemplate: {
              title: '{name}',
              content: `
                <div class="row">
                  <div class="data">
                    <span>Total Inspections</span>
                    <span>${total}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="data">
                    <span>Completed Inspections</span>
                    <span>${completed}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="data">
                    <span>Remaining Inspections</span>
                    <span>${remaining}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="data">
                    <span>Scheduled Inspections</span>
                    <span>${scheduled}</span>
                  </div>
                </div>
                <a href="${url}" target="_self">Go to Project</a>
              `,
            },
          }),
        ];
      });
      break;

    case 'globalEcosystem':
      graphicsArray = data.flatMap((item) => {
        const {
          claimsCount, claimsTotalAmount, risksCount, country: { name, coordinate: { longitude, latitude } },
        } = item;
        const noData = claimsCount < 5;

        return [
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: globalMapBaseGraphicSymbolGlow,
          }),
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: getGlobalMapBaseGraphicSymbol(theme),
            attributes: {
              name,
              dotColor: getGlobalMapBaseGraphicSymbol(theme).color,
            },
            popupTemplate: {
              title: '{name}',
              content: `
                <div class="row">
                  <div class="marker" style="background-color: #96CEF6"}></div>
                  <div class="data">
                    <span>Claims</span>
                    <span${noData && ' class="no-data"'}>${noData ? 'NO DATA' : claimsCount}</span$>
                  </div>
                </div>
                <div class="row">
                  <div class="marker" style="background-color: #969EF6"></div>
                  <div class="data">
                    <span>Recommendations</span>
                    <span>${risksCount}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="marker" style="background-color: #96F6EE"></div>
                  <div class="data">
                    <span>Claims Total</span>
                    <span${noData && ' class="no-data"'}>${noData ? 'NO DATA' : `$${formatCurrency(claimsTotalAmount)}`}</span>
                  </div>
                </div>
              `,
            },
          }),
        ];
      });
      break;

    case 'tivVarCountries':
      graphicsArray = data.flatMap((item, i) => {
        const {
          countryName, totalTIV, totalVAR, projectsCount, countryCoordinate: { longitude, latitude },
        } = item;
        const colorNumber = Math.floor(i % 12);
        const milTiv = totalTIV / 1000000;
        const markerSize = milTiv > 500 ? 80 : Math.floor(totalTIV / 10000000) + 30;
        const fontSize = milTiv > 500 ? 15 : Math.floor(totalTIV / 100000000) + 10;
        let displayedTiv;

        switch (true) {
          case milTiv < 1:
            displayedTiv = '<1M';
            break;
          case milTiv > 500:
            displayedTiv = '>500M';
            break;
          default:
            displayedTiv = `${Math.ceil(milTiv)}M`;
            break;
        }

        return [
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: getTivRoundMarker(markerSize, tivMarkerColors[colorNumber].background),
            attributes: {
              countryName,
              markerColor: tivMarkerColors[colorNumber].background,
              textColor: tivMarkerColors[colorNumber].color,
              markerSize,
              fontSize,
              displayedTiv,
            },
            popupTemplate: {
              title: '{countryName}',
              content: `
                <div class="row">
                  <div class="data">
                    <span>Projects</span>
                    <span>${projectsCount}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="data">
                    <span>TIV</span>
                    <span>$${formatCurrency(totalTIV)}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="data">
                    <span>VAR</span>
                    <span>$${formatCurrency(totalVAR)}</span>
                  </div>
                </div>
              `,
              actions: [zoomAction],
              overwriteActions: true,
            },
          }),
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: getTivTextSymbol(fontSize, tivMarkerColors[colorNumber].color, displayedTiv),
            attributes: {
              countryName,
              markerColor: tivMarkerColors[colorNumber].background,
              textColor: tivMarkerColors[colorNumber].color,
              markerSize,
              fontSize,
              displayedTiv,
            },
          }),
        ];
      });
      break;

    case 'tivVarProjects':
      graphicsArray = data.flatMap((item) => {
        const {
          id, name, tiv, var: prVar, coordinate: { longitude, latitude },
        } = item;
        const url = `${window.location.origin}/projects/${id}/details`;

        return [
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: globalMapBaseGraphicSymbolGlow,
          }),
          new Graphic({
            geometry: {
              type: 'point',
              longitude,
              latitude,
            },
            symbol: getGlobalMapBaseGraphicSymbol(theme),
            attributes: {
              name,
              tiv,
              prVar,
              dotColor: getGlobalMapBaseGraphicSymbol(theme).color,
            },
            popupTemplate: {
              title: '{name}',
              content: `
                <div class="row">
                  <div class="data">
                    <span>TIV</span>
                    <span>$${formatCurrency(tiv)}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="data">
                    <span>VAR</span>
                    <span>$${formatCurrency(prVar)}</span>
                  </div>
                </div>
                <div>
                  <a href="${url}" target="_self">Go to Project</a>
                </div>
              `,
            },
          }),
        ];
      });
      break;

    default:
      break;
  }

  graphicsLayer.addMany(graphicsArray);
};
