import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import AuthService, { ROLES_IDS } from 'services/AuthService';
import PageLayout from 'layouts/PageLayout';
import NavTabs from 'components/NavTabs';
import { current } from 'modules/subscriptions/store/subscriptions.selectors';
import * as SubscriptionActions from 'modules/subscriptions/store/subscriptions.reducer';

import PersonalInfo from './PersonalInfo';
import UpdatePassword from './UpdatePassword';
import Subscription from './Subscription';

import { HeaderPanel, Title } from './styles';

export const defaultTabs = {
  personalInfo: {
    label: 'Personal Info',
    value: 'PersonalInfo',
  },
  updatePassword: {
    label: 'Update Password',
    value: 'UpdatePassword',
  },
  subscription: {
    label: 'Subscription',
    value: 'Subscription',
  },
};

const getDefaultTabsWithRestrictions = (isBrokerOrIROrRM, subscriptionInfo) => {
  const newTabs = { ...defaultTabs };
  if (!isBrokerOrIROrRM || !subscriptionInfo) newTabs.subscription = undefined;
  return newTabs;
};

export const adminTabs = {
  updatePassword: {
    label: 'Update Password',
    value: 'UpdatePassword',
  },
};

const AccountInfo = ({ defaultTabValue }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = AuthService.getUser();
  const isAdmin = role === ROLES_IDS.Admin;
  const isBroker = role === ROLES_IDS.Broker;
  const isIR = role === ROLES_IDS.InsuranceRepresentative;
  const isRM = role === ROLES_IDS.RiskManager;
  const isDO = role === ROLES_IDS.DeveloperOwner;
  const { subscriptionInfo } = useSelector(current);

  const tabs = isAdmin ? adminTabs : defaultTabs;
  let navTabs = Object.values(tabs);
  if (!isAdmin) {
    const isBrokerOrRMOrDO = isBroker || isRM || isDO || isIR;
    navTabs = Object.values(
      getDefaultTabsWithRestrictions(isBrokerOrRMOrDO, subscriptionInfo),
    ).filter((tab) => tab);
  }

  const defaultTab = navTabs.find((el) => el.value === defaultTabValue);
  const [selectedTab, setSelectedTab] = useState(
    (defaultTab || navTabs[0]).value,
  );

  const onSelectTab = (selectedTabValue) => {
    setSelectedTab(selectedTabValue);
    navigate(`${location.pathname}?tab=${selectedTabValue}`);
  };

  useEffect(() => {
    dispatch(SubscriptionActions.getCurrentSubscription());
  }, []);

  const renderCurrentTab = (currentTab) => {
    if (isAdmin) return <UpdatePassword />;
    switch (currentTab) {
      case tabs.personalInfo.value:
        return <PersonalInfo />;
      case tabs.updatePassword.value:
        return <UpdatePassword />;
      case tabs.subscription.value:
        return <Subscription />;
      default:
        return null;
    }
  };

  return (
    <PageLayout>
      <Fragment key="title">
        <HeaderPanel>
          <Title>Account</Title>
        </HeaderPanel>
      </Fragment>
      <Fragment key="content">
        <NavTabs
          selectedTab={selectedTab}
          onSelectTab={onSelectTab}
          tabsList={navTabs}
        />
        {renderCurrentTab(selectedTab)}
      </Fragment>
    </PageLayout>
  );
};

export default AccountInfo;
