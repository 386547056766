import React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
// import { getIn } from 'formik';

import CheckedIcon from 'components/Icon/Store/components/CheckedIcon';
import UncheckedIcon from 'components/Icon/Store/components/UncheckedIcon';

import { Wrapper, Text, Label } from './styles';

const Checkbox = ({ label, isWhite, isLight, disabled, value, onChange, onBlur, name, calculatedValue }) => {
  // TODO: handle validation errors (possible when it is required to check Checkbox somewhere)
  // const errorName = getIn(form.errors, name);
  // const isTouched = getIn(form.touched, name);
  // const error = isTouched && errorName || ''

  const isCalculatedValue = calculatedValue !== undefined;
  const inputValue = isCalculatedValue ? calculatedValue : value;

  return (
    <Wrapper
      isWhite={isWhite}
      disabled={disabled}
      isLight={isLight}
    >
      <Label>
        <MuiCheckbox
          color="primary"
          name={name}
          checked={inputValue}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          icon={<UncheckedIcon />}
          checkedIcon={<CheckedIcon />}
        />
        <Text
          isWhite={isWhite}
          disabled={disabled}
        >
          {label}
        </Text>
      </Label>
      {/* TODO: <ErrorMessage error={error} /> */}
    </Wrapper>
  );
};

export default Checkbox;
