import RatesService from 'myCompany/api/RatesService';

const GET_RATES = 'GET_RATES';
const ADD_EXPENSE_RATE = 'ADD_EXPENSE_RATE';
const ADD_TIME_RATE = 'ADD_TIME_RATE';
const DELETE_RATE = 'DELETE_RATE';
const EXPENSE_RATES_LIST_SORT_BY = 'EXPENSE_RATES_LIST_SORT_BY';

export const getRates = (params) => ({
  type: GET_RATES,
  apiCall: () => RatesService.getRates(params),
});

export const setExpenseRatesSortBy = (expenseSortBy) => ({
  type: EXPENSE_RATES_LIST_SORT_BY,
  expenseSortBy,
});

export const addExpenseRates = (params) => ({
  type: ADD_EXPENSE_RATE,
  apiCall: () => RatesService.addExpenseRates(params),
});

export const addTimeRates = (params) => ({
  type: ADD_TIME_RATE,
  apiCall: () => RatesService.addTimeRates(params),
});

export const deleteRate = (params) => ({
  type: DELETE_RATE,
  apiCall: () => RatesService.deleteRate(params),
});

const initialState = {
  ratesList: {
    timeRates: [],
    expenseRates: [],
    isLoading: true,
  },
  expenseSortBy: null,
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_RATES: {
      return {
        ...state,
        ratesList: {
          ...state.ratesList,
          ...response,
        },
      };
    }
    case EXPENSE_RATES_LIST_SORT_BY: {
      return {
        ...state,
        expenseSortBy: action.expenseSortBy,
      };
    }
    default:
      return state;
  }
};
