import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';
import AuthService, { ROLES_IDS } from 'services/AuthService';

const ProjectReallocation = ({ notify, mode }) => {
  const { role } = AuthService.getUser();
  const isRE = role === ROLES_IDS.RiskEngineer;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: { projectId, projectName } } = notify;
  const message = mode === 'inProgress' ? `${projectName} reallocation request, please complete the required actions.`
    : `${projectName} can be reallocated.\nWould you like to assign a resource to proceed with project reallocation?`;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToResources = () => {
    handleClose();
    closeNotificationList();
    navigate(`/projects/${projectId}/${isRE ? 'details' : 'resources'}`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="File Reallocation Request"
      cancelButtonTitle="Close"
      confirmButtonTitle="Go to project"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToResources}
    >
      <ConfirmationText
        text={message}
      />
    </ConfirmationDialog>
  );
};

export default ProjectReallocation;
