import React from 'react';

import { PulsatingTooltip } from 'components';

import { Wrapper, Tab, Separator } from './styles';

const NavTab = ({ selected, value, label, onSelectTab, separated, pulsatingLabel }) => {
  const handleSelectTab = () => {
    if (onSelectTab) onSelectTab(value);
  };
  return (
    <>
      {separated && <Separator />}
      <Tab
        onClick={handleSelectTab}
        selected={selected}
      >
        {pulsatingLabel && !selected && <PulsatingTooltip place={value} />}
        {label}
      </Tab>
    </>
  );
};

const NavTabs = ({ selectedTab, onSelectTab, tabsList = [], separatorPos }) => (
  <Wrapper>
    {tabsList.map((tab, i) => (
      <NavTab
        onSelectTab={onSelectTab}
        value={tab.value}
        label={tab.label}
        selected={selectedTab === tab.value}
        separated={separatorPos === i} // before witch menu item should be "|"
        pulsatingLabel={tab.pulsatingLabel}
        key={i}
      />
    ))}
  </Wrapper>
);

export default NavTabs;
