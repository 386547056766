import React from 'react';

const ThemeDark = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0492 1.50909C10.3358 2.73284 10.3748 4.00156 10.1639 5.2406C9.95294 6.47965 9.49634 7.66399 8.82093 8.72396C8.14552 9.78394 7.26494 10.6981 6.231 11.4128C5.19707 12.1274 4.03065 12.628 2.80037 12.8852C3.44722 13.5543 4.22191 14.0867 5.07846 14.4508C5.93501 14.8148 6.85595 15.003 7.78664 15.0043C7.87324 15.0043 7.96059 15.0075 8.04787 15.0043C9.6681 14.947 11.2171 14.324 12.4257 13.2435C13.6344 12.1629 14.4264 10.6932 14.6643 9.08951C14.9021 7.48581 14.5707 5.84946 13.7276 4.46468C12.8845 3.0799 11.583 2.03416 10.0492 1.50909ZM9.12536 0C9.16196 5.71303e-05 9.19849 0.00332086 9.23453 0.00975381C11.2255 0.363385 13.0151 1.4412 14.2588 3.0356C15.5025 4.63 16.1122 6.62821 15.9706 8.64534C15.8289 10.6625 14.9459 12.5558 13.4916 13.9608C12.0373 15.3657 10.1145 16.1828 8.0937 16.2547C7.9911 16.2585 7.8885 16.2547 7.78677 16.2547C6.47449 16.2554 5.18134 15.9401 4.01665 15.3355C2.85196 14.7309 1.84996 13.8547 1.09539 12.7811C1.03422 12.6886 0.998507 12.5816 0.991858 12.4708C0.985209 12.3601 1.00785 12.2496 1.05751 12.1504C1.10717 12.0513 1.18209 11.9669 1.27472 11.9059C1.36735 11.8449 1.47442 11.8094 1.58514 11.803C2.83427 11.6934 4.04147 11.298 5.11343 10.6475C6.18539 9.99692 7.0934 9.10858 7.76728 8.05113C8.44116 6.99368 8.86285 5.79542 8.99976 4.549C9.13668 3.30258 8.98515 2.04136 8.55688 0.862839C8.52045 0.768548 8.50707 0.666917 8.51786 0.566409C8.52865 0.465902 8.56329 0.369425 8.6189 0.285013C8.67452 0.200602 8.74949 0.130698 8.83759 0.0811222C8.92568 0.0315465 9.02434 0.00373383 9.12536 0Z" fill="#96CEF6" />
    </svg>
  );
};

export default ThemeDark;
