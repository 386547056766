import React from 'react';

import StepperModal from 'onboarding/components/StepperModal';
import ModalPage from 'onboarding/components/ModalPage';
import RedirectMenu from 'onboarding/components/RedirectMenu';

const getPageData = (step) => {
  switch (step) {
    case 0:
      return {
        title: 'Help - Overview',
        src: 'help-overview.png',
        description: 'Help section collates all the useful information that can help you better understand the platform, detailed description of each section can always be found here.',
      };
    case 1:
      return {
        title: 'Guides',
        src: 'help-guides.png',
        description: 'Guides for each section describes the most important aspects of the features available within the selected section.',
      };
    case 2:
      return {
        title: 'Contact Us',
        src: 'help-contact-us.png',
        description: 'If there are any questions left after reviewing the guides, you can always find the actual contact details under Contacts Us button.',
      };
    default:
      return {};
  }
};

const HelpModal = ({ open, onCancel }) => {
  const renderStepContent = (activeStep) => {
    switch (activeStep) {
      case 0:
      case 1:
      case 2: {
        const { title, src, description } = getPageData(activeStep);
        return (
          <ModalPage
            title={title}
            src={src}
            description={description}
          />
        );
      }
      case 3:
        return (
          <RedirectMenu
            onCancel={onCancel}
            currentSection="help"
          />
        );
      default:
        return null;
    }
  };

  return (
    <StepperModal
      renderStepContent={renderStepContent}
      numberOfSteps={4}
      open={open}
      onCancel={onCancel}
    />
  );
};

export default HelpModal;
