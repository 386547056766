import React from 'react';

const Dollar = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 .5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm0 13.978A6.477 6.477 0 118 1.523a6.477 6.477 0 010 12.955z" fill="#96CEF6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.34 7.532V4.828c.23.08.437.21.608.382.2.201.208.317.208.78a.511.511 0 101.022 0 1.817 1.817 0 00-.508-1.5 2.516 2.516 0 00-1.33-.712v-.204a.511.511 0 00-1.022 0v.276a2.42 2.42 0 00-1.875 2.444c0 1.272 1.023 1.896 1.875 2.117v3.103a1.705 1.705 0 01-1.023-1.65.511.511 0 10-1.022 0c0 1.363.886 2.512 2.045 2.703v.201a.511.511 0 001.023 0v-.235c1.023-.194 2.216-.86 2.216-2.492 0-1.466-1.364-2.315-2.216-2.51zM6.467 6.293a1.384 1.384 0 01.852-1.363v2.386a1.128 1.128 0 01-.852-1.023zm1.875 5.199V8.6c.474.184 1.193.681 1.193 1.445a1.422 1.422 0 01-1.193 1.446z" fill="#96CEF6" />
  </svg>
);

export default Dollar;
