import styled from 'styled-components';
import { mobile } from 'assets/styles/mixins';

import { Icon } from 'components';

export const IntroBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 40px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 150%;
  color: #0A2463;

  > div {
    margin: 5px 0;
  }
`;

export const StyledLink = styled.a`
  margin: 4px;
  color: #059FDE;
  text-decoration: none;

  &&:hover {
    color: #1FBAFA;
  }
`;

export const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #05112E;
`;

export const Line = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 30px 0 0;
  border-top: 2px solid #FFFFFF;

  &:last-child {
    margin: 0 0 0 30px;
  }

  ${mobile`
    display: none;
  `}
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 20px;
  left: 20px;

  ${mobile`
    left: 45%;
  `}
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 38px;
  color: #FFFFFF;

  ${mobile`
    width: 90%;
    height: 100px;
    margin-top: 10%;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 36px 40px 17px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #0A2463;
  overflow-y: scroll;
    scrollbar-width: auto;
    max-height: 72vh;
    margin-top: 0;
    padding-top: 0;
  margin-bottom: 0;


  ${mobile`
    width: 90%;
    padding: 6px 20px 52px;
  `}
`;

export const TextTitle = styled.div`
  margin: 10px 0 20px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: 0.16px;
  color: #0A2463;
`;

export const SubTitle = styled.div`
  margin: 5px 0;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.16px;
  color: #0A2463;
`;

export const List = styled.ol`
  margin: 5px 0 10px 0;
  letter-spacing: 0.16px;
  color: #0A2463;

  ${mobile`
    margin: 5px 0 10px -10px;
  `}
`;

export const TextSection = styled.div`
  margin-bottom: 10px;
  letter-spacing: 0.16px;
  color: #0A2463;
`;

export const BoldText = styled.span`
  font-weight: 900;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;
