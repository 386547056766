import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const PolicyExpire = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: { projectId, projectName } } = notify;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    handleClose();
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToDetails = () => {
    handleClose();
    closeNotificationList();
    navigate(`/projects/${projectId}/details`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="Policy Expire"
      cancelButtonTitle="Close"
      confirmButtonTitle="Go to Project"
      onClose={handleClose}
      onCancel={handleCancel}
      onConfirm={redirectToDetails}
    >
      <ConfirmationText
        value={projectName}
        text="Please be notified that the insurance policy for {placeholder} will expire in 7 days."
      />
    </ConfirmationDialog>
  );
};

export default PolicyExpire;
