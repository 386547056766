import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';

import { Tooltip } from 'components';

export const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressWrapper = styled.div`
  z-index: 10;
  position: fixed;
  bottom: 2%;
  right: 36px;
  display: flex;
  width: 366px;
  margin: 12px 0;
  padding: 14px 18px;
  background: var(--accordionHeader);
  border-radius: 4px;
`;

export const ProgressInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
`;

export const ProgressInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ProgressName = styled.div`
  flex: 1;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--textColor);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ProgressSize = styled.div`
  margin-left: 5px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--searchInputIconColor);
  text-align: right;
`;

export const ProgressCount = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 4px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--searchInputIconColor);
`;

export const StyledTooltip = styled(Tooltip)`
  margin-left: -7px;
  margin-right: 17px;
`;

export const StyledLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-determinate {
    border-radius: 4px;
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: #73778C;
  }
  & .MuiLinearProgress-barColorPrimary {
    border-radius: 4px;
    background-color: #88EEAC;
  }
`;

export const TypeIconWrapper = styled.div`
  margin-left: -10px;
  margin-right: 8px;
  svg path {
    fill: var(--navTabFill);
  }
`;
