import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as SubscribersActions from 'subscriptions/store/subscriptions.reducer';
import { subscriptionsList, subscriptionsSortBy } from 'subscriptions/store/subscriptions.selectors';
import AddSubscriptionModal from 'subscriptions/pages/ViewSubscriber/AddSubscriptionModal';
import { differenceInDays, formatPolicyDate } from 'utils/DateUtils';
import { SUBSCRIPTION_STATUSES } from 'utils/constants';
import { getPackageName } from 'subscriptions/constants';

import { Table, NoContent } from 'components';

import { StatusTag } from 'subscriptions/components/SubscriptionsTable/styles';
import SubscriptionsControls from './SubscriptionsControls';

const renderStatusTitle = (status) => SUBSCRIPTION_STATUSES[status].label;

const getPeriodName = (period) => {
  switch (period) {
    case 'ThreeMonths': return '3 Months';
    case 'SixMonths': return '6 Months';
    case 'Year': return '1 Year';
    default: return '1 Month';
  }
};

const columns = [
  {
    width: '15%', key: 'startDate', title: 'Start Date', noSort: true,
    render: ({ startDate }) => formatPolicyDate(startDate),
  },
  {
    width: '15%', key: 'endDate', title: 'End Date', noSort: true,
    render: ({ endDate }) => formatPolicyDate(endDate),
  },
  {
    width: '12%', key: 'period', title: 'Period', noSort: true,
    render: ({ period }) => getPeriodName(period),
  },
  {
    width: '14%', key: 'status', title: 'Status', noSort: true,
    render: ({ status }) => (
      <StatusTag status={status}>
        {renderStatusTitle(status)}
      </StatusTag>
    ),
  },
  {
    width: '14%', key: 'expiresIn', title: 'Expires In', noSort: true,
    render: ({ startDate, endDate }) => differenceInDays(startDate, endDate),
  },
  {
    width: '12%', key: 'package', title: 'Package', noSort: true,
    render: ({ package: packageType }) => getPackageName(packageType),
  },
  {
    width: '12%', key: 'type', title: 'Type', noSort: true,
  },
  {
    width: '6%', key: 'controls', title: '', align: 'right', noSort: true,
    minWidth: '70px',
  },
];

const SubscriptionsHistory = ({ subscriberData = {}, getSubscriptions, subscriberEmail, subscriberType }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [viewDialogState, setViewDialogState] = useState(null);
  const subscriptions = useSelector(subscriptionsList);
  const sortBy = useSelector(subscriptionsSortBy);

  const handleSortBy = (newSortBy) => {
    dispatch(SubscribersActions.setSubscriptionsSortBy(newSortBy));
  };

  const handlePrevPageClick = () => {
    setPage(page - 1);
  };

  const handleNextPageClick = () => {
    setPage(page + 1);
  };

  const handleGoToPage = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleRowClick = (subscription) => setViewDialogState(subscription);

  const closeViewDialog = () => setViewDialogState(null);

  useEffect(() => {
    getSubscriptions();
  }, [page, sortBy]);

  const renderNoContent = () => (
    <NoContent
      customIcon="subscriptions"
      title="There are no subscriptions yet."
      descr="Once they are added, they will appear here."
    />
  );

  const renderControls = (subscription) => {
    if (subscription.status === 'Expired') return null;
    return (
      <SubscriptionsControls
        subscription={subscription}
        onSuccess={getSubscriptions}
        name={subscriberData.name}
        subscriberEmail={subscriberEmail}
      />
    );
  };

  const { isLoading, items = [], totalPages, totalCount } = subscriptions;

  return (
    <>
      <Table
        items={items}
        columns={columns}
        sortBy={sortBy}
        isLoading={isLoading}
        onSortBy={handleSortBy}
        onNoContent={renderNoContent}
        onRenderControls={renderControls}
        onRowClick={handleRowClick}
        pagination={{
          page,
          totalPages,
          itemsCount: items.length,
          totalCount,
        }}
        onPrevPage={handlePrevPageClick}
        onNextPage={handleNextPageClick}
        onGoToPage={handleGoToPage}
      />
      {viewDialogState && (
        <AddSubscriptionModal
          isOpen={!!viewDialogState}
          onCancel={closeViewDialog}
          subscriberType={subscriberType}
          subscriberId={subscriberData.id}
          subscription={viewDialogState}
          view
        />
      )}
    </>
  );
};

export default SubscriptionsHistory;
