import React from 'react';

const Unknown = () => {
  return (
    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.67 8.933l-6.767-6.768a.879.879 0 00-.676-.29H6.62A1.934 1.934 0 004.687 3.81v23.203a1.934 1.934 0 001.934 1.934h16.406a1.934 1.934 0 001.934-1.934V9.61a.88.88 0 00-.29-.677zm-7.443-4.737L22.64 9.61h-5.414V4.196zm5.8 22.816H6.621V3.809h8.672v5.8a1.934 1.934 0 001.934 1.934h5.8v15.469z" fill="currentColor" fillOpacity=".87" opacity=".9" />
    </svg>
  );
};

export default Unknown;
