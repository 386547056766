import styled from 'styled-components';

import { Icon, Select } from 'components';

export const ContactInfo = styled.div`
  border: 1px solid var(--lightBorderColor);
  border-radius: 4px;
  padding: 18px 20px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--lightTextColor);

  > :first-child {
    margin-bottom: 8px;
    color: var(--textColor);
    font-weight: 600;
  }
`;

export const ContactRow = styled.div`
  height: 23px;
  display: flex;
  align-items: center;

  &&:not(:last-child) {
    margin-bottom: 5px;
  }

  > span {
    line-height: 14px;
  }
`;

export const ContactActionsRow = styled.div`
  height: 60px;
  display: flex;
  align-items: center;

  &&:not(:last-child) {
    margin-right: 50px;
  }

  > span {
    line-height: 14px;
  }
  > div {
    margin-right: 10px;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 9px;

  svg > path {
    fill: var(--iconPrimaryColor);
  }

  &.user {
    margin-bottom: 3px;
  }
`;

export const Root = styled.div`
  max-width: 500px;
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 17px;
`;
