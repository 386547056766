import styled from 'styled-components';

import { Tooltip } from 'components';

export const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
  margin-top: 25px;
`;

export const Text = styled.div`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--textColor);
`;

export const InputWrapper = styled.div`
  margin: 15px 0 14px;
`;

export const HighlitedText = styled.span`
  margin: 0 5px;
  font-weight: 500;
  color: var(--titleColor);
`;
