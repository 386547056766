import React from 'react';

const Search = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.875 15.0819L11.6281 10.8125C13.7371 8.26785 13.474 4.51498 11.0305 2.28956C8.58698 0.064134 4.82592 0.151919 2.48892 2.48892C0.151919 4.82592 0.064134 8.58698 2.28956 11.0305C4.51498 13.474 8.26785 13.7371 10.8125 11.6281L15.0819 15.875L15.875 15.0819ZM1.81251 6.87501C1.81251 4.07907 4.07907 1.81251 6.87501 1.81251C9.67095 1.81251 11.9375 4.07907 11.9375 6.87501C11.9375 9.67095 9.67095 11.9375 6.87501 11.9375C4.07907 11.9375 1.81251 9.67095 1.81251 6.87501Z" fill="white" fillOpacity="0.6" />
    </svg>
  );
};

export default Search;
