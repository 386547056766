import React, { Fragment, useReducer, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import PageLayout from 'layouts/PageLayout';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import { BackButton, Button } from 'components';
import {
  subscribersTypePaths, subscriberTypeOptions,
  addSubscriptionInitialValues,
} from 'subscriptions/constants';
import UserService from 'projects/api/UserService';
import MyCompanyService from 'myCompany/api/MyCompanyService';
import CompanyService from 'common/api/CompanyService';
import EditForm from './EditForm';

import { HeaderPanel, Title, Root, Subscribers, Headers, ButtonsContainer, StyledSelect } from './styles';

const reduceFunc = (prevState, newState) => ({
  ...prevState,
  ...newState,
});

const EditSubscriber = () => {
  const navigate = useNavigate();
  const { subscriberType, subscriberId } = useParams();
  const [, setState] = useReducer(reduceFunc, addSubscriptionInitialValues);
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
  const [, setReplaceProjectsContact] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [subscriberData, setSubscriberData] = useState();
  const [coordinators, setCoordinators] = useState([]);
  const [useExistingCoordinator, setUseExistingCoordinator] = useState(true);

  const role = Object.values(subscribersTypePaths).find(({ path }) => path === subscriberType) || {};

  const isBC = subscriberType === 'BrokerCompany';
  const isIC = role.name === 'InsuranceCompany';

  const handleSubscribersListClick = () => navigate('/subscriptions');

  const getInsuranceCompanyCoordinators = async () => {
    if (subscriberType === subscribersTypePaths.InsuranceCompany.path) {
      const getCoordinators = await MyCompanyService.getInsuranceCompanyCoordinatorsById(subscriberId);
      setCoordinators(getCoordinators.data.data);
    }
  };

  const fetchSubscriberData = async () => {
    let subscriber;
    switch (subscriberType) {
      case subscribersTypePaths.InvitedCompany.path:
        subscriber = await CompanyService.getInsuranceCompanyByInvitedICId(subscriberId);
        break;
      case subscribersTypePaths.InsuranceCompany.path:
        subscriber = await MyCompanyService.getInsuranceCompanyById(subscriberId);
        break;
      case subscribersTypePaths.RiskCompany.path:
        subscriber = await MyCompanyService.getRiskCompanyById(subscriberId);
        break;
      case subscribersTypePaths.Broker.path:
        subscriber = await MyCompanyService.getBrokerCompanyById(subscriberId);
        break;
      default:
        subscriber = await UserService.getUser(subscriberId);
    }
    setSubscriberData(subscriber.data?.data);
  };

  useEffect(() => {
    fetchSubscriberData();
  }, [subscriberType, subscriberId]);

  useEffect(() => {
    getInsuranceCompanyCoordinators();
  }, [subscriberType, subscriberId]);

  const handleCancelClick = () => {
    setConfirmDialogOpened(true);
  };

  const handleCancelConfirmationClick = () => {
    setConfirmDialogOpened(false);
  };

  const handleCloseForm = () => {
    setConfirmDialogOpened(false);
    handleSubscribersListClick();
  };

  const handleSubmit = async (values, formikProps) => {
    setIsSubmitted(true);
    try {
      const request = { id: subscriberId,
        email: values.primaryContactEmail,
        firstName: values.primaryContactName.firstName,
        lastName: values.primaryContactName.lastName };
      await MyCompanyService.updateInsuranceCompanyPrimaryContactInfo(request);
      navigate('/subscriptions');
      showNotifySuccess("Company's primary contact info was updated successfully");
    } catch (error) {
      const { fieldErrors } = error;
      if (fieldErrors) {
        formikProps.setErrors(fieldErrors);
      } else {
        showNotifyError(error);
      }
    } finally {
      setIsSubmitted(false);
    }
  };

  return (
    <PageLayout>
      <Fragment key="title">
        <Headers>
          <HeaderPanel>
            <BackButton
              text="subscribers"
              onClick={handleSubscribersListClick}
            />
          </HeaderPanel>
          <HeaderPanel>
            <Title>Edit Subscriber</Title>
          </HeaderPanel>
        </Headers>
      </Fragment>
      <Fragment key="content">
        <Formik
          initialValues={subscriberData}
        //   validationSchema={(isIC || isBC) ? validationSchemaIC : validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, submitForm }) => {
            useEffect(() => {
              if (values && subscriberData && values.primaryContactEmail && subscriberData.primaryContactEmail &&
                subscriberData.primaryContactEmail !== values.primaryContactEmail) {
                setReplaceProjectsContact(true);
              }
              isIC || isBC
                ? setState({ companyInviteInfo: values, subscriberId: null })
                : setState({ subscriberId: values.subscriberId, companyInviteInfo: null });
            }, [values, subscriberType]);
            return (
              <>
                <Root>
                  <Subscribers>
                    <StyledSelect
                      required
                      readOnly
                      value={role.name}
                      label="Subscription For"
                      options={subscriberTypeOptions}
                      noClear
                    />
                    {subscriberType && isIC && values && (
                      <EditForm
                        values={values}
                        setFieldValue={setFieldValue}
                        useExistingCoordinator={useExistingCoordinator}
                        setUseExistingCoordinator={setUseExistingCoordinator}
                        coordinators={coordinators}
                      />
                    )}
                  </Subscribers>
                </Root>
                <ButtonsContainer>
                  <Button
                    variant="contained"
                    onClick={handleCancelClick}
                    text="Cancel"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    text="Save"
                    type="submit"
                    disabled={isSubmitted || !subscriberType}
                  />
                </ButtonsContainer>
                {confirmDialogOpened && (
                  <ConfirmationDialog
                    open={confirmDialogOpened}
                    title="Close the form"
                    confirmButtonTitle="Continue"
                    onClose={handleCancelConfirmationClick}
                    onCancel={handleCancelConfirmationClick}
                    onConfirm={handleCloseForm}
                  >
                    Are you sure you want to close the form?
                    The data hasn’t been saved, if you continue it will be lost.
                  </ConfirmationDialog>
                )}
              </>
            );
          }}
        </Formik>
      </Fragment>
    </PageLayout>
  );
};

export default EditSubscriber;
