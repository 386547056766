export const overviewPage = {
  title: 'Projects - Overview',
  src: 'projects-overview.png',
  description: 'Projects section lists all your projects. Accessing the project provides the information about project details, inspections, recommendations and many others.',
};

export const locationPage = {
  title: 'Projects Location',
  src: 'projects-location.png',
  description: 'Within Project Location section, the location of the project can be selected on the map and various weather layer can be applied to see the location risk score.',
};

export const documentsPage = {
  title: 'Documents',
  src: 'projects-documents.png',
  description: 'Documents section allows you to store and share project related documents, photos & videos and layout plans.',
};

export const contactsPage = {
  title: 'Contacts',
  src: 'projects-contacts.png',
  description: 'Within Contacts section, project contacts such as Broker, Site Contact and Risk Manager can be invited to the project and get access to the system.',
};

export const recommendationsPage = {
  title: 'Recommendations',
  src: 'projects-recommendations.png',
  description: 'Recommendations section lists all the recommendations for the project. Recommendations are added by the Risk Engineer and are actioned by the Site Contact.',
};

export const analyticsPage = {
  title: 'Analytics',
  src: 'projects-analytics.png',
  description: 'Analytics section provides the analytical information about the project such as the risk score, recommendation charts and improvements suggestions.',
};

export const reportsPage = {
  title: 'Reports',
  src: 'projects-reports.png',
  description: 'Reports section allows you to automatically generate Abridged and Main reports and share it with the relevant stakeholders.',
};

export const resourcesPage = {
  title: 'Resources',
  src: 'projects-resources.png',
  description: 'Within the Resources section, the Risk Engineer is assigned to the project. You can also send out fee proposal and select the risk company you want to work with.',
};

export const dailyToolsICCorICU = {
  title: 'Daily Tools',
  src: 'dashboard-icc-daily-tools.png',
  description: 'The following data from all projects can always be accessed from the dashboard - inspection calendar, assignments, budgets, reports, external contacts and analytics.',
};
export const dailyToolsRCorRE = {
  title: 'Daily Tools',
  src: 'dashboard-re-daily-tools.png',
  description: 'The following data from all projects can always be accessed from the dashboard - inspection calendar, assignments, reports, time & expenses, contacts and analytics.',
};
export const dailyToolsRMorBRorSC = {
  title: 'Daily Tools',
  src: 'dashboard-rm-daily-tools.png',
  description: 'The following data from all projects can always be accessed from the dashboard - inspection calendar, reports and analytics.',
};

export const generalDashboardPage = {
  title: 'Dashboard',
  src: 'common-dashboard.png',
  description: 'Dashboard provides a quick access to the daily tools needed to get the work done as well as a visual overview of the most important information from all the projects.',
};
export const generalProjectsPage = {
  title: 'Projects',
  src: 'common-projects.png',
  description: 'Projects section lists all your projects. Accessing the project provides the information about the project details, inspections, recommendations and many others.',
};

export const generalGEPage = {
  title: 'Global Ecosystem',
  src: 'common-GE.png',
  description: 'Global Ecosystem collates the risk recommendations and claims data across all the users and provides the ability to get the insights from the collected data.',
};

export const generalMyCompanyPage = {
  title: 'My Company',
  src: 'common-my-company.png',
  description: 'My Company section allows you to manage your company data - team members, rates and the company data can be managed within that section.',
};

export const generalNotificationsPage = {
  title: 'Notifications',
  src: 'common-notifications.png',
  description: 'Notifications are sent to your notifications inbox and allows you to be informed about your projects or about the actions that need to be taken.',
};

export const generalHelpPage = {
  title: 'Help',
  src: 'help-overview.png',
  description: 'Help section collates all the useful information that can help you better understand the platform, detailed description of each section can always be found here.',
};
