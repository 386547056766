import React from 'react';
import { add } from 'date-fns';

import { typeOptions, periodOptions, getPackageOptions } from 'subscriptions/constants';
import { parseDateWithoutTime, getZeroOffsetDate, formatSubscriptionTime, getLocalOffsetDate } from 'utils/DateUtils';
import { RadioButtonGroup, DatePicker, Input } from 'components';

import { Root, RadioTitle, DateBlock, Section, Text, HighLightedText, TimeBlock, ProjectsNumberBlock } from './styles';

const AddSubscriptionForm = ({ subscriberType, state, setState, error = {}, view }) => {
  const { type, packageType, period, startDate, maxProjectsCount } = state;

  let isActive = false;
  let upcomingDate;

  const getAmount = (periodAmount) => {
    switch (periodAmount) {
      case 'ThreeMonths': return 3;
      case 'SixMonths': return 6;
      case 'Year': return 12;
      default: return 1;
    }
  };

  const endDate = add(startDate, { months: getAmount(period), days: -1 });

  const handleTypeChange = (value) => setState({ type: value });

  const handlePackageChange = (value) => setState({ packageType: value });

  const handlePeriodChange = (value) => setState({ period: value });

  const handlePeriodDateChange = (value) => setState({ startDate: value });

  const handleProjectsCountChange = (value) => {
    if (value > 50 || value < 20) return;
    setState({ maxProjectsCount: value });
  };

  const currentFormattedDate = getZeroOffsetDate(new Date());
  const parsedStartDate = parseDateWithoutTime(startDate);
  const parsedEndDate = parseDateWithoutTime(endDate);

  const isUpcoming = currentFormattedDate < parsedStartDate;

  if (isUpcoming) {
    upcomingDate = formatSubscriptionTime(getLocalOffsetDate(parsedStartDate));
  } else if (currentFormattedDate < parsedEndDate) {
    isActive = true;
  }

  return (
    <Root>
      <Section>
        <RadioTitle>Select Type</RadioTitle>
        <RadioButtonGroup
          value={type}
          setValue={handleTypeChange}
          options={typeOptions}
          disabled={!subscriberType || view}
        />
      </Section>
      <Section>
        <RadioTitle>Select Package</RadioTitle>
        <RadioButtonGroup
          value={packageType}
          setValue={handlePackageChange}
          options={getPackageOptions(subscriberType)}
          disabled={!subscriberType || view}
        />
        <ProjectsNumberBlock
          error={error.maxProjectsCount}
          visible={packageType === 'Lite'}
        >
          <Input
            type="counter"
            name="maxProjectsCount"
            value={maxProjectsCount}
            label="Maximum Projects Count"
            setValue={handleProjectsCountChange}
            error={error.maxProjectsCount}
          />
        </ProjectsNumberBlock>
      </Section>
      <Section error={error.date}>
        <RadioTitle>Select Period</RadioTitle>
        <RadioButtonGroup
          value={period}
          setValue={handlePeriodChange}
          options={periodOptions}
          disabled={!subscriberType || view}
        />
        <DateBlock>
          <DatePicker
            required
            setValue={handlePeriodDateChange}
            value={startDate}
            label="Start Date"
            disabled={!subscriberType || view}
            error={error.date}
          />
          {subscriberType && (
            <DatePicker
              value={endDate}
              readOnly={!view}
              disabled={view}
              name="endDate"
              label="End Date"
            />
          )}
        </DateBlock>
      </Section>
      {subscriberType && !view && (
        <TimeBlock>
          {isUpcoming && (
            <>
              <Text>
                Note that the subscription starts at
                <HighLightedText>00:00 UTC</HighLightedText>
                on the selected Start Date.
              </Text>
              <Text>
                Currently selected subscription will start in
                <HighLightedText>{upcomingDate}.</HighLightedText>
              </Text>
            </>
          )}
          {!isUpcoming && (
            <>
              <Text>
                Note that the subscription starts at
                <HighLightedText>00:00 UTC</HighLightedText>
                on the selected Start Date.
              </Text>
              <Text>
                Currently selected subscription will be
                <HighLightedText>{isActive ? 'active' : 'expired'}.</HighLightedText>
              </Text>
            </>
          )}
        </TimeBlock>
      )}
    </Root>
  );
};

export default AddSubscriptionForm;
