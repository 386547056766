import React from 'react';
import { useDispatch } from 'react-redux';

import { ActionButton } from 'components';
import * as RatesActions from 'myCompany/store/rates.reducer';
import { showNotifyError, showNotifySuccess } from 'services/toaster';

import RateDialogForm from '../RateDialogForm';
import { ControlsWrapper } from './styles';

const stopPropagation = (event) => {
  event.stopPropagation();
};

const RateControls = ({ rateId, expense, time }) => {
  const dispatch = useDispatch();

  const fetchRates = () => {
    dispatch(RatesActions.getRates());
  };

  async function deleteRate() {
    try {
      await dispatch(RatesActions.deleteRate(rateId));
      fetchRates();
      showNotifySuccess('Rates were successfully deleted.');
    } catch (error) {
      showNotifyError(error);
    }
  }

  return (
    <ControlsWrapper onClick={stopPropagation}>
      <ActionButton
        isIcon
        icon="edit"
        dialogTitle="Edit Rate"
        dialogForm={(
          <RateDialogForm
            isOpen
            mode="edit"
            rateId={rateId}
            time={time}
            expense={expense}
            onSuccess={fetchRates}
          />
        )}
        confirmButtonTitle="Save"
      />
      <ActionButton
        isIcon
        icon="deleteSmall"
        text="Delete Rate"
        dialogTitle="Delete Rate"
        dialogContent="Are you sure you want to delete this rate? It will be deleted immediately, you can't undo this action."
        onConfirm={deleteRate}
        confirmButtonTitle="Delete"
      />
    </ControlsWrapper>
  );
};

export default RateControls;
