import * as Yup from 'yup';

import { requiredString } from 'utils/validation/customTypes';
import { createUniversalDate } from 'utils/DateUtils';
import { COMPANY_TYPES } from 'types/company';

export const validationSchema = Yup.object().shape({
  subscriberId: requiredString,
});

export const validationSchemaIC = Yup.object().shape({
  primaryUserName: Yup.object().shape({
    firstName: requiredString,
    lastName: requiredString,
  }),
  address: Yup.object().shape({
    country: requiredString,
    state: requiredString,
    city: requiredString,
    addressLine: requiredString,
    zipCode: requiredString,
  }),
  phone: requiredString,
  companyName: requiredString,
  primaryUserEmail: requiredString.email('Please enter a valid email address.'),
});

export const subscriberTypeOptions = [
  {
    label: 'Insurance Company',
    value: 'InsuranceCompany',
  },
  {
    label: 'Risk Company',
    value: 'RiskCompany',
  },
  {
    label: 'Broker Company',
    value: 'BrokerCompany',
  },
  {
    label: 'Broker',
    value: 'Broker',
  },

  {
    label: 'Insurance Representative',
    value: 'InsuranceRepresentative',
  },
  {
    label: 'Risk Manager',
    value: 'RiskManager',
  },
  {
    label: 'Developer Owner',
    value: 'DeveloperOwner',
  },
];

export const PACKAGES_IDS = {
  Lite: 'Lite',
  LitePlus: 'LitePlus',
  Pro: 'Pro',
  Global: 'Global',
};

export const PACKAGE_STATUSES = {
  Active: 'Active',
  Upcoming: 'Upcoming',
  Expired: 'Expired',
};

export const addSubscriptionInitialValues = {
  type: 'Paid',
  packageType: PACKAGES_IDS.Lite,
  period: 'OneMonth',
  startDate: createUniversalDate(),
  ownerType: '',
  maxProjectsCount: 20,
};

export const initialValues = {
  subscriberId: '',
};

export const initialValuesIC = {
  primaryUserName: {
    firstName: '',
    lastName: '',
  },
  address: {
    country: '',
    state: '',
    city: '',
    addressLine: '',
    zipCode: '',
  },
  phone: '',
  companyName: '',
  primaryUserEmail: '',
};

export const typeOptions = [
  {
    label: 'Paid',
    value: 'Paid',
  },
  {
    label: 'Free',
    value: 'Free',
  },
];

export const periodOptions = [
  {
    label: '1 Month',
    value: 'OneMonth',
  },
  {
    label: '3 Months',
    value: 'ThreeMonths',
  },
  {
    label: '6 Months',
    value: 'SixMonths',
  },
  {
    label: '1 Year',
    value: 'Year',
  },
];

export const getPackageOptions = (role) => {
  switch (role) {
    case 'RiskManager':
    case 'DeveloperOwner':
      return [
        {
          label: 'Pro',
          value: PACKAGES_IDS.Pro,
        },
      ];
    case 'InsuranceRepresentative':
    case 'Broker':
    case 'RiskCompany':
      return [
        {
          label: 'Pro',
          value: PACKAGES_IDS.Pro,
        },
        {
          label: 'Global',
          value: PACKAGES_IDS.Global,
        },
      ];
    default:
      return [
        {
          label: 'Lite',
          value: PACKAGES_IDS.Lite,
        },
        {
          label: 'Lite+',
          value: PACKAGES_IDS.LitePlus,
        },
        {
          label: 'Pro',
          value: PACKAGES_IDS.Pro,
        },
        {
          label: 'Global',
          value: PACKAGES_IDS.Global,
        },
      ];
  }
};

export const subscribersTypes = {
  DeveloperOwner: {
    name: 'DeveloperOwner',
    path: 'developer-owner',
  },
  RiskManager: {
    name: 'RiskManager',
    path: 'risk-manager',
  },
  Broker: {
    name: 'Broker',
    path: 'broker',
  },
  InsuranceRepresentative: {
    name: 'InsuranceRepresentative',
    path: 'insurance-representative',
  },
  RiskCompany: {
    name: 'RiskCompany',
    path: 'risk-company',
  },
  InsuranceCompany: {
    name: 'InsuranceCompany',
    path: 'insurance-company',
  },
  BrokerCompany: {
    name: 'BrokerCompany',
    path: 'broker-company',
  },
  // unregistered insurance companies
  InvitedCompany: {
    name: 'InsuranceCompany',
    path: 'invited-company',
  },
};

export const PACKAGES_USER_COUNTS_BY_COMPANY_TYPE = {
  [COMPANY_TYPES.INSURANCE]: {
    Lite: {
      riskEngineers: 1,
      underwriters: 1,
    },
    LitePlus: {
      riskEngineers: 2,
      underwriters: 1,
    },
    Pro: {
      riskEngineers: 6,
      underwriters: 4,
    },
    Global: {
      riskEngineers: 25,
      underwriters: 10,
    },
  },
  [COMPANY_TYPES.BROKER]: {
    Lite: {
      riskEngineers: 1,
      underwriters: 1,
    },
    LitePlus: {
      riskEngineers: 2,
      underwriters: 1,
    },
    Pro: {
      riskEngineers: 6,
      underwriters: 4,
    },
    Global: {
      riskEngineers: 25,
      underwriters: 10,
    },
  },
  [COMPANY_TYPES.RISK]: {
    FreeUsage: {
      riskEngineers: 1,
      riskCoordinators: 1,
    },
    Lite: {
      riskEngineers: 1,
      riskCoordinators: 1,
    },
    Pro: {
      riskEngineers: 3,
      riskCoordinators: 1,
    },
    Global: {
      riskEngineers: 12,
      riskCoordinators: 3,
    },
  },
};

const standardLayers = { flood: true, wildfire: true, earthquake: true, windstorm: true };
const extendedLayers = { ...standardLayers, hurricane: true };

const globalPackageTooltip = 'This feature is only available for Global Package subscribers.';
const proPackageTooltip = 'This feature is only available for Pro package subscribers.';

export const PACKAGES_USER_LAYERS = {
  Insurance: {
    FreeUsage: {},
    Lite: standardLayers,
    LitePlus: standardLayers,
    Pro: standardLayers,
    Global: extendedLayers,
    tooltip: globalPackageTooltip,
  },
  Risk: {
    FreeUsage: standardLayers,
    Lite: standardLayers,
    Pro: standardLayers,
    Global: extendedLayers,
    tooltip: globalPackageTooltip,
  },
  ExternalRE: {
    FreeUsage: standardLayers,
    tooltip: globalPackageTooltip,
  },
  Broker: {
    FreeUsage: {},
    Lite: {},
    Pro: {},
    Global: extendedLayers,
    tooltip: globalPackageTooltip,
  },
  InsuranceRepresentative: {
    FreeUsage: {},
    Lite: {},
    Pro: {},
    Global: extendedLayers,
    tooltip: globalPackageTooltip,
  },
  RiskManager: {
    FreeUsage: {},
    Lite: {},
    Pro: extendedLayers,
    tooltip: proPackageTooltip,
  },
  DeveloperOwner: {
    FreeUsage: {},
    Lite: {},
    Pro: extendedLayers,
    tooltip: proPackageTooltip,
  },
};

export const getPackageName = (packageId) => (packageId === PACKAGES_IDS.LitePlus ? 'Lite+' : packageId);
