import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';

import { CloseButton } from 'components';
import AuthService from 'services/AuthService';

import {
  StyledFormDialog, Root, StyledStepper, Footer, StyledTypography, EmptyButtonContainer,
  StyledButton, StartButtonContainer, StyledStartButton, NextButton, StyledIcon, StyledDialogContent,
} from './styles';

const StepperModal = ({
  open, onCancel, numberOfSteps, renderStepContent, isGeneral,
}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === numberOfSteps - 1;

  const handleCancelClick = () => {
    setActiveStep(0);
    if (onCancel) onCancel();
  };

  const handleSkipClick = () => {
    setActiveStep(0);
    if (onCancel) onCancel();
    AuthService.skipOnboardConfig();
  };

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const isNextStepAvailable = !isLastStep && (!isGeneral || (isGeneral && !isFirstStep));

  return (
    <StyledFormDialog
      className={cx(theme.light && 'light-theme')}
      open={open}
      onClose={handleCancelClick}
      withScroll
    >
      <CloseButton onClick={handleCancelClick} />
      <StyledDialogContent>
        <Root>
          <StyledTypography component="div">
            {renderStepContent(activeStep)}
          </StyledTypography>
          <StartButtonContainer>
            {isGeneral && isFirstStep && (
              <StyledStartButton
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                Start Tour
                <StyledIcon icon="arrowForward" flipHorizontal noWrapper />
              </StyledStartButton>
            )}
          </StartButtonContainer>
          <Footer>
            {isFirstStep && isGeneral &&
              <EmptyButtonContainer />
            }
            {isFirstStep && !isGeneral && (
              <StyledButton
                variant="contained"
                onClick={handleSkipClick}
                text="Skip tour"
              />
            )}
            {!isFirstStep && (
              <StyledButton
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                <StyledIcon icon="arrowForward" noWrapper />
                Previous
              </StyledButton>
            )}
            <StyledStepper
              variant="dots"
              steps={numberOfSteps}
              position="static"
              activeStep={activeStep}
            />
            {isGeneral && isFirstStep && (
              <StyledButton
                variant="contained"
                onClick={handleSkipClick}
                text="Skip tour"
              />
            )}
            {isNextStepAvailable && (
              <NextButton
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                Next
                <StyledIcon icon="arrowForward" flipHorizontal noWrapper />
              </NextButton>
            )}
            {isLastStep && (
              <NextButton
                variant="contained"
                color="primary"
                onClick={handleCancelClick}
                text="Finish"
              />
            )}
          </Footer>
        </Root>
      </StyledDialogContent>
    </StyledFormDialog>
  );
};

export default StepperModal;
