import qs from 'qs';
import ApiClient from 'services/ApiClient';

class ExpensesServices extends ApiClient {
  getExpensesCount(projectId) {
    return this.call({
      method: 'get',
      url: `/expenses/count?${qs.stringify({ projectId })}`,
    });
  }

  getExpensesList(projectId) {
    return this.call({
      method: 'get',
      url: `/expenses?${qs.stringify({ projectId })}`,
    });
  }

  getExpense(id) {
    return this.call({
      method: 'get',
      url: `/expenses/${id}`,
    });
  }

  addExpense(data) {
    return this.call({
      method: 'post',
      url: '/expenses/resource',
      data: {
        ...data,
        type: 'Resource',
      },
    });
  }

  addTime(data) {
    return this.call({
      method: 'post',
      url: '/expenses/time',
      data: {
        ...data,
        type: 'Time',
      },
    });
  }

  updateExpense(data) {
    return this.call({
      method: 'put',
      url: '/expenses/resource',
      data: {
        ...data,
      },
    });
  }

  updateTime(data) {
    return this.call({
      method: 'put',
      url: '/expenses/time',
      data: {
        ...data,
      },
    });
  }

  getExpensesData(projectId) {
    return this.call({
      method: 'get',
      url: `/expenses/form-data?${qs.stringify({ projectId })}`,
    });
  }

  deleteExpense(id) {
    return this.call({
      method: 'delete',
      url: `/expenses/${id}`,
    });
  }

  getExchangeRate({ date, from, to }) {
    return this.call({
      method: 'get',
      url: `/expenses/exchange-rate?${qs.stringify({ date, from, to })}`,
    });
  }

  markAsBilled(ids) {
    return this.call({
      method: 'put',
      url: '/expenses/mark-as-billed',
      data: {
        ids,
      },
    });
  }
}

export default new ExpensesServices();
