import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FormDialog from 'components/Dialogs/FormDialog';
import { FormikField,
  Input,
  DatePicker,
  Select,
  Checkbox } from 'components';
import { createUniversalDate } from 'utils/DateUtils';
import * as CommunicationsActions from 'communication/store/communication.reducer';
import { pickBy } from 'lodash';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import * as Yup from 'yup';
import {
  Wrapper,
  Row,
  CheckboxWrapper,
} from './styles';
import { requiredArrayOfStrings, requiredMixed, requiredString } from '../../../../../utils/validation/customTypes';
import CommunicationService from '../../../api/CommunicationService';

const ROLES_OPTIONS = [
  { label: 'Broker', value: 'Broker' },
  { label: 'Risk Manager', value: 'RiskManager' },
  { label: 'Developer Owner', value: 'DeveloperOwner' },
  { label: 'Site Contact', value: 'SiteContact' },
  { label: 'Risk Engineer', value: 'RiskEngineer' },
  { label: 'RC Coordinator', value: 'RCCoordinator' },
  { label: 'IC Coordinator', value: 'ICCoordinator' },
  { label: 'Underwriter', value: 'ICUnderwriter' },
];

const initialAddValues = {
  title: '',
  startDate: createUniversalDate(new Date()),
  endDate: createUniversalDate(new Date()),
  roles: [],
};

const AddCommunication = ({
  onCancel,
  isView,
  onClose,
  formTitle,
  isEdit,
  isAdd,
  onSuccess,
  communication,
}) => {
  const dispatch = useDispatch();
  const minDate = createUniversalDate();
  const [, setForm] = useState({});

  const addNewCommunication = async (newCommunication) => {
    const response = await dispatch(CommunicationsActions.addCommunication(newCommunication));

    return response;
  };

  const updateCommunication = async (communicationUpdated) => {
    const response = await CommunicationService.editCommunication(communicationUpdated);
    return response;
  };

  const removeFalsyValues = (data) => pickBy(data, (value) => value || value === 0);

  const handleSubmit = async (formValues) => {
    const sendData = {
      ...formValues,
    };
    const newCommunication = removeFalsyValues(sendData);

    let response;
    if (isAdd) {
      response = await addNewCommunication(newCommunication);
    } else if (isEdit) {
      const res = await updateCommunication(newCommunication);
      response = res.data.data;
    }
    if (response.id) {
      onClose();
      onSuccess();
      const successMessage = isEdit ? 'Communication successfully updated' : 'Communication succesfully added';
      showNotifySuccess(successMessage);
    } else {
      const errorMessage = response;
      showNotifyError(errorMessage);
    }

    return response;
  };

  const getValuesForUsers = () => {
    const { id, title, startDate, endDate, roles, addToHelpSection } = communication;
    const values = {
      id,
      title,
      startDate,
      endDate,
      roles,
      addToHelpSection,
    };
    return removeFalsyValues(values);
  };

  const getFormProps = () => {
    if (isEdit) {
      return {
        submitButtonTitle: 'Save',
        noSubmit: false,
        onSubmit: handleSubmit,
        initialValues: getValuesForUsers(),
      };
    }

    return {
      submitButtonTitle: 'Save',
      noSubmit: false,
      onSubmit: handleSubmit,
      initialValues: initialAddValues,
    };
  };

  const { submitButtonTitle, onSubmit, initialValues } = getFormProps();

  function getValidations() {
    return Yup.object().shape({
      title: requiredString,
      startDate: requiredMixed,
      endDate: Yup
        .date()
        .when(
          'startDate',
          (startDate, schema) => (startDate && schema.min(startDate, 'End date cannot be earlier than start date')),
        )
        .required('Field is required.'),
      roles: requiredArrayOfStrings,
    });
  }

  return (
    <>
      <FormDialog
        title={formTitle}
        submitButtonTitle={submitButtonTitle}
        onCancel={onCancel}
        noCancel={isView}
        onSubmit={onSubmit}
        onClose={onClose}
        initialValues={initialValues}
        isLoading={false}
        open
        noSubmit={false}
        validationSchema={getValidations}
        onFormChange={setForm}
      >
        <Wrapper>
          <Row>
            <FormikField
              component={Input}
              name="title"
              label="Title"
              required
            />
          </Row>
          <Row>
            <FormikField
              component={DatePicker}
              name="startDate"
              label="Start Date"
              minDate={minDate}
              required
            />
            <FormikField
              component={DatePicker}
              name="endDate"
              label="End Date"
              minDate={minDate}
              required
            />
          </Row>

          <Row>
            <FormikField
              component={Select}
              required
              name="roles"
              label="Users"
              options={ROLES_OPTIONS}
              multiple
              readonly={isView}
          />
          </Row>
          <CheckboxWrapper>
            <FormikField
              component={Checkbox}
              name="addToHelpSection"
              label="Add to Help tab?"
              isWhite
          />
          </CheckboxWrapper>

        </Wrapper>
      </FormDialog>
    </>
  );
};

export default AddCommunication;
