import React from 'react';

const Cross = () => {
  return (
    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity=".87" fillRule="evenodd" clipRule="evenodd" d="M14 1.225L12.775 0 7 5.775 1.225 0 0 1.225 5.775 7 0 12.775 1.225 14 7 8.225 12.775 14 14 12.775 8.225 7 14 1.225z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Cross;
