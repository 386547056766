import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CONTACT_MAX_LENGTHS } from 'utils/constants';
import { getCountriesOptions, getCountries } from 'projects/store/contacts.selectors';
import { Input, FormikField, Select } from 'components';
import CoordinatorSelector from './CoordinatorSelector';

import { Row, StyledForm, Title } from './styles';

const EditForm = ({ values, setFieldValue, coordinators }) => {
  const countriesData = useSelector(getCountries);
  const countryOptions = useSelector(getCountriesOptions);
  const [user] = useState(null);

  const { addressInfo = {} } = values;

  const getStateOptions = (selectedCountry) => {
    const states = countriesData.find((countryItem) => countryItem.name === selectedCountry)?.states || [];
    return states.map((countryState) => ({
      label: countryState,
      value: countryState,
    }));
  };

  const companyStateOptions = getStateOptions(addressInfo.country);
  const companyStateComponent = ['United States', 'United Kingdom'].includes(addressInfo.country) ? Select : Input;
  const companyStateLabel = addressInfo.country === 'United States' ? 'State' : 'Region';
  const companyZipCodeLabel = addressInfo.country === 'United Kingdom' ? 'Postal Code' : 'Zip Code';

  const handleCountryChange = (value) => {
    setFieldValue('addressInfo.country', value);
    setFieldValue('addressInfo.state', '');
  };

  const onChangePrimaryUser = (value) => {
    if (value === 'new') {
      setFieldValue('primaryContactEmail', '');
      setFieldValue('primaryContactName.firstName', '');
      setFieldValue('primaryContactName.lastName', '');
      return;
    }
    const coordinator = coordinators.find((t) => t.email === value);
    setFieldValue('primaryContactEmail', value);
    setFieldValue('primaryContactName.firstName', coordinator.name.firstName);
    setFieldValue('primaryContactName.lastName', coordinator.name.lastName);
  };

  return (
    <StyledForm>
      <FormikField
        component={Input}
        required
        name="name"
        label="Company Name"
        maxLength={CONTACT_MAX_LENGTHS.companyName}
        readOnly
      />
      <FormikField
        component={Select}
        required
        name="addressInfo.country"
        label="Country"
        options={countryOptions}
        onChange={handleCountryChange}
        readOnly
      />
      <FormikField
        component={Input}
        required
        name="addressInfo.addressLine"
        label="Address Line"
        // maxLength={CONTACT_MAX_LENGTHS.address}
        readOnly
      />
      <Row>
        <FormikField
          component={companyStateComponent}
          required
          disabled={!addressInfo.country}
          name="addressInfo.state"
          label={companyStateLabel}
          maxLength={CONTACT_MAX_LENGTHS.state}
          options={companyStateOptions}
          readOnly
        />
        <FormikField
          component={Input}
          required
          name="addressInfo.city"
          label="City"
          maxLength={CONTACT_MAX_LENGTHS.city}
          readOnly
        />
      </Row>
      <Row>
        <FormikField
          component={Input}
          required
          name="addressInfo.zipCode"
          label={companyZipCodeLabel}
          maxLength={CONTACT_MAX_LENGTHS.zipCode}
          readOnly
        />
        <FormikField
          component={Input}
          required
          name="phone"
          type="phone"
          phoneCountryMask={addressInfo.country}
          label="Phone"
          readOnly
        />
      </Row>
      <Title>
        Primary Contact
      </Title>
      <CoordinatorSelector
        required
            // disabled={positionNotSelected || !isAdd}
        label="Contacts"
        options={coordinators.map((t) => ({ value: t.email, label: t.fullName }))}
        onChange={onChangePrimaryUser}
        value={values.primaryContactEmail}
        fullName={user && user.fullName}
            />
      <Row>
        <FormikField
          component={Input}
          required
          name="primaryContactName.firstName"
          label="First Name"
          maxLength={CONTACT_MAX_LENGTHS.name}
        />
        <FormikField
          component={Input}
          required
          name="primaryContactName.lastName"
          label="Last Name"
          maxLength={CONTACT_MAX_LENGTHS.name}
        />
      </Row>
      <FormikField
        component={Input}
        required
        name="primaryContactEmail"
        label="Email"
      />
    </StyledForm>
  );
};

export default EditForm;
