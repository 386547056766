import React from 'react';

const Documents = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.378 7.127l-5.136-5.135a.666.666 0 00-.514-.22H6.391c-.807 0-1.467.66-1.467 1.467v17.609c0 .807.66 1.467 1.467 1.467h11.74c.806 0 1.467-.66 1.467-1.467V7.64a.666.666 0 00-.22-.514zm-5.65-3.595l4.109 4.109h-4.109V3.532zm4.402 17.316H6.391V3.238h5.87v4.403c0 .807.66 1.467 1.467 1.467h4.402v11.74z" fill="#fff" fillOpacity=".87" />
      <path d="M7.858 16.445h8.805v1.468H7.858v-1.468zm0-4.402h8.805v1.468H7.858v-1.468z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Documents;
