import React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';

import CheckedIcon from 'components/Icon/Store/components/CheckedIcon';
import UncheckedIcon from 'components/Icon/Store/components/UncheckedIcon';
import { InputWrapper } from './styles';

// Simple Checkbox without any wrapped functionality for Formik Forms
// Needed for Checkable Table

const CheckboxInput = ({ checked, disabled, isLight, ...rest }) => {
  return (
    <InputWrapper
      isLight={isLight}
      disabled={disabled}
    >
      <MuiCheckbox
        color="primary"
        checked={checked}
        disabled={disabled}
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
        {...rest}
      />
    </InputWrapper>
  );
};

export default CheckboxInput;
