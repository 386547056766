import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';
import AuthService from 'services/AuthService';
import { getNextPageInfo, getFolderByRole } from 'help/constants';

import { Title, TextSection, Underline } from '../../styles';

const BudgetAndFeeProposal = () => {
  const { role } = AuthService.getUser();

  const folder = getFolderByRole(role);

  const budgetSrc = `projects/${folder}/projects-budget.png`;
  const addFeeProposalSrc = `projects/${folder}/projects-add-fee-proposal.png`;

  const { title, path } = getNextPageInfo(role, 'budget-fee-proposal');

  return (
    <>
      <ImageContainer src={budgetSrc} alt="budgets" />
      <Title>Budget</Title>
      <TextSection>
        Budget sub-section allows you to allocate the budget for the project services. You can also set up trigger
        alerts on spending the budget. In case the notifications are set up, you will be notified when the certain
        percentage of budget is reached. In case the notifications are turned off, you can always track
        the budget spend within that section.
      </TextSection>
      <ImageContainer src={addFeeProposalSrc} alt="fee-proposal" />
      <Title>Fee Proposal</Title>
      <TextSection>
        Fee Proposal sub-section allows you to send the invitation to the external risk companies to submit their
        proposals for the risk engineering services. When the fee proposal is created, all the added external
        companies receive the email invitation to submit their proposals for the project. When the proposals
        are submitted, you will receive the notification and can accept one of the proposals or reject
        them all and assign the resource manually.
      </TextSection>
      <TextSection>
        <Underline>In case the fee proposal was accepted</Underline>
        , the selected external company will be pre-selected in the
        ‘Resources’ section and you will need to confirm the assignment within that section. Note that even
        after accepting the fee proposal, you can still assign the internal resource for the project, but the
        other external company cannot be assigned when the fee proposal is accepted.
      </TextSection>
      <TextSection>
        <Underline>If you don’t want to use the fee proposal</Underline>
        {' '}
        for assigning the resource, you can skip this step as this is
        completely optional. In case you want to proceed without the fee proposal,
        you can go the ‘Resources’ section and assign internal or external resource manually.
      </TextSection>
      <RedirectButton redirectTitle={title} redirectPath={path} />
    </>
  );
};

export default BudgetAndFeeProposal;
