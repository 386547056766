import React from 'react';

import ImageContainer from '../ImageContainer';

import { Title, Description, Root, PreviewImage } from './styles';

const ModalPage = ({ title, src, description, isCommunication }) => {
  return (
    <Root>
      <Title>
        {title}
      </Title>
      {isCommunication ? <PreviewImage src={src} alt="" /> : <ImageContainer src={src} alt={title} isCommunication={isCommunication} />}

      <Description>
        {description}
      </Description>
    </Root>
  );
};

export default ModalPage;
