import React from 'react';

const Phone = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.375 12.6875H11.3006C2.70377 12.1931 1.48314 4.93938 1.31252 2.72563C1.29877 2.5535 1.31912 2.38036 1.3724 2.21612C1.42568 2.05187 1.51085 1.89976 1.62302 1.76849C1.73519 1.63721 1.87216 1.52936 2.02608 1.45112C2.18001 1.37287 2.34786 1.32577 2.52002 1.3125H4.93064C5.10589 1.31233 5.27714 1.36479 5.42223 1.46307C5.56732 1.56136 5.67955 1.70094 5.74439 1.86375L6.40939 3.5C6.47342 3.65905 6.48931 3.83341 6.45509 4.00142C6.42086 4.16942 6.33804 4.32367 6.21689 4.445L5.28502 5.38563C5.43058 6.21283 5.82672 6.97535 6.41985 7.57004C7.01297 8.16473 7.77445 8.56288 8.60127 8.71062L9.55064 7.77C9.6738 7.65019 9.8295 7.5693 9.99833 7.5374C10.1672 7.5055 10.3416 7.52401 10.5 7.59063L12.1494 8.25125C12.3097 8.31814 12.4466 8.43125 12.5424 8.57617C12.6382 8.72109 12.6887 8.89126 12.6875 9.065V11.375C12.6875 11.7231 12.5492 12.0569 12.3031 12.3031C12.057 12.5492 11.7231 12.6875 11.375 12.6875ZM2.62502 2.1875C2.50898 2.1875 2.3977 2.23359 2.31566 2.31564C2.23361 2.39769 2.18752 2.50897 2.18752 2.625V2.66C2.38877 5.25 3.67939 11.375 11.3488 11.8125C11.4062 11.8161 11.4639 11.8082 11.5183 11.7895C11.5727 11.7707 11.6229 11.7414 11.666 11.7032C11.7091 11.665 11.7442 11.6186 11.7694 11.5668C11.7945 11.515 11.8092 11.4587 11.8125 11.4012V9.065L10.1631 8.40437L8.90752 9.65125L8.69752 9.625C4.89127 9.14813 4.37502 5.34188 4.37502 5.3025L4.34877 5.0925L5.59127 3.83688L4.93502 2.1875H2.62502Z" fill="white" fillOpacity="0.68" />
    </svg>
  );
};

export default Phone;
