import React from 'react';
import { useNavigate } from 'react-router-dom';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';

import { Title, TextButton, TextSection, List } from '../../styles';

const imageSrc = 'my-company-overview.png';

const Overview = () => {
  const navigate = useNavigate();

  const redirectToCompanyInfo = () => navigate('company-info');

  const redirectToTeamMembers = () => navigate('team-members');

  const redirectToRates = () => navigate('rates');

  const redirectToSubscription = () => navigate('subscription');

  return (
    <>
      <ImageContainer src={imageSrc} alt="overview" />
      <Title>Overview</Title>
      <TextSection>
        My Company section allows you to manage users and data that belongs your company.
        That section is visible only to the coordinators of your company and consists of the four main sub-sections:
      </TextSection>
      <List>
        <li>
          <TextButton withMarginRight onClick={redirectToCompanyInfo}>Company Info</TextButton>
          - allows you to manage your company contact details;
        </li>
        <li>
          <TextButton withMarginRight onClick={redirectToTeamMembers}>Team Members</TextButton>
          - lists your company team members with the ability to manage them;
        </li>
        <li>
          <TextButton withMarginRight onClick={redirectToRates}>Rates</TextButton>
          - allows you to set up and manage rates of your company;
        </li>
        <li>
          <TextButton withMarginRight onClick={redirectToSubscription}>Subscription</TextButton>
          - provides the information about your current subscription.
        </li>
      </List>
      <RedirectButton redirectTitle="Company Info" redirectPath="company-info" />
    </>
  );
};

export default Overview;
