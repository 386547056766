import React, { useState } from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import RedirectButton from 'help/components/RedirectButton';
import SalesDepartmentModal from 'help/components/SalesDepartmentModal';

import { Title, TextSection, HighlightedText, List, TextButton } from '../../styles';

const imageSrc = 'my-company-team-members.png';

const TeamMebmers = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { role } = AuthService.getUser();

  const isICC = role === ROLES_IDS.ICCoordinator;

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <ImageContainer src={imageSrc} alt="team members" />
      <Title>Overview</Title>
      <TextSection>
        The section provides you the overview of all users that belong to your company.
        You can invite, edit and delete users as the company coordinator.
        The following system roles exists on the portal for your use:
      </TextSection>
      <List>
        <li>
          <HighlightedText>Coordinator</HighlightedText>
          - a system role that is able to administrate the portal, manage the
          company data as well as has access to the all the company projects.
        </li>
        {isICC && (
        <li>
          <HighlightedText>Underwriter</HighlightedText>
          - a system role that is assigned to the project to underwrite it.
          That role cannot administrate the portal and has access to the projects he was assigned to.
        </li>
        )}
        <li>
          <HighlightedText>Risk Engineer</HighlightedText>
          - a system role that is assigned to the project to perform the risk engineering services.
          That role cannot administrate the portal and has access tp the projects he was assigned to.
        </li>
      </List>
      <TextSection>
        The following actions are available within ‘Team Members’ section:
      </TextSection>
      <List>
        <li>
          <HighlightedText>Invite</HighlightedText>
          - you can invite the Coordinators, Underwriters and Risk Engineers to your company.
          When the Coordinators are invited, the email invite is sent to them. When the Underwriters or
          the Risk Engineers are invited, they are added to the list of the team members but they will only receive
          the invite when they are assigned to the first project. When they are assigned to the first project and
          registered, their status will be changed to ‘Has Access’ and they will no longer
          need to register for the further projects.
        </li>
      </List>
      <TextSection>
        The maximum number of people you can invite to your company is based on your subscription package.
        For the subscription details, please contact our
        <TextButton onClick={handleModalOpen}>
          sales departments
        </TextButton>
        .
      </TextSection>
      <List>
        <li>
          <HighlightedText>Edit</HighlightedText>
          - the contact details of the users that haven’t been registered yet can be updated within that section.
          In case the user has already been registered, his contact details can only be updated by himself.
        </li>
        <li>
          <HighlightedText>Delete</HighlightedText>
          - you can delete the users from your company. In case the user is deleted,
          he will no longer be able to access your company data and the projects that he was assigned to.
        </li>
      </List>
      <RedirectButton redirectTitle="Rates" redirectPath="rates" />
      {modalOpen && (
        <SalesDepartmentModal onClose={handleModalClose} />
      )}
    </>
  );
};

export default TeamMebmers;
