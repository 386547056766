import React from 'react';

const DeleteSmall = () => {
  return (
    <svg width="12" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#fff" fillOpacity=".87" fillRule="evenodd" clipRule="evenodd" d="M8 0H4v.933h4V0zM0 1.866V2.8h1v9.327c0 .515.448.932 1 .932h8c.552 0 1-.417 1-.932V2.799h1v-.933H0zm2 10.259V2.798h8v9.327H2z" />
    </svg>
  );
};

export default DeleteSmall;
