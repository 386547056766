import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import ProjectService from 'projects/api/ProjectService';
import DocumentService from 'projects/api/DocumentService';
import * as ProjectsActions from 'projects/store/projects.reducer';
import { Icon, Menu, Spinner } from 'components';
import ActionMenuItem from 'common/components/ActionMenuItem';

import {
  StyledButton, StyledFormDialog, ReportsRoot,
  StyledSubtext, StyledText, StyledSubtextHighlighted, ErrorsRoot,
  SmallText, StyledCrossIcon, StyledIcon, ErrorLine,
} from './styles';

const ImportProjects = ({ disabled }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [importStarted, setImportStarted] = useState(false);
  const [importSuccessful, setImportSuccessful] = useState(null);
  const [errors, setErrors] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);

  function getProjectsList() {
    const listOptions = { page: 1, sortBy: null, searchFilter: '' };
    dispatch(ProjectsActions.getProjectsList(listOptions));
  }

  function closeImport() {
    setImportStarted(false);
  }

  function openMenu() {
    setMenuOpened(true);
  }

  function closeMenu() {
    setMenuOpened(false);
  }

  async function downloadTemplate() {
    const { data: { data } } = await ProjectService.getProjectTemplate();
    await DocumentService.downloadFile(data, 'template.xlsx');
  }

  async function uploadTemplate(file) {
    setIsLoading(true);
    setImportStarted(true);
    setImportSuccessful(null);
    setErrors(null);
    try {
      const { data: { data } } = await ProjectService.importProjects(file);
      setImportSuccessful(data);
      getProjectsList();
    } catch (error) {
      if (error.additionalValues) {
        setErrors(error.additionalValues);
      } else if (error.fieldErrors) {
        setErrors(Object.values(error.fieldErrors));
      } else {
        setErrors([error.error]);
      }
    }
    setIsLoading(false);
  }

  const importMenuButton = (
    <StyledButton
      color="grey"
      text="Import"
      endIcon={<Icon icon={menuOpened ? 'arrowUp' : 'arrowDown'} noWrapper />}
    />
  );

  const handleFileSelect = (acceptedFiles) => {
    uploadTemplate(acceptedFiles[0]);
  };

  const { getInputProps, open } = useDropzone({
    onDrop: handleFileSelect,
    accept: '.xlsx',
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: false,
  });

  const renderSuccess = () => {
    return (
      <ReportsRoot>
        <StyledIcon icon="accepted" noWrapper />

        <StyledText>Import Successful</StyledText>
        <StyledSubtext>
          <StyledSubtextHighlighted>
            {`${importSuccessful.length} ${importSuccessful.length === 1 ? 'Project' : 'Projects'}`}
          </StyledSubtextHighlighted>
          {' '}
          were successfully imported.
        </StyledSubtext>
      </ReportsRoot>
    );
  };

  const renderProcess = () => {
    return (
      <ReportsRoot>
        <Spinner />
        <StyledText>Projects are being imported</StyledText>
        <StyledSubtext style={{ marginBottom: 70 }}>Be patient - it can take a few seconds.</StyledSubtext>
      </ReportsRoot>
    );
  };

  const renderErrors = () => {
    return (
      <>
        <ReportsRoot>
          <StyledIcon icon="rejected" noWrapper />

          <StyledText>Import Failed</StyledText>
          <StyledSubtext>
            Some errors occured during the import, please fix the <br /> issues below and try once again.
          </StyledSubtext>
        </ReportsRoot>
        <ErrorsRoot>
          <SmallText>Errors occured:</SmallText>
          {errors && errors.map((text, i) => (
            <ErrorLine key={`error${i}`}>
              <StyledCrossIcon icon="rejected" noWrapper />
              <span>{text}</span>
            </ErrorLine>
          ))}
        </ErrorsRoot>
      </>
    );
  };

  const noCancel = isLoading || importSuccessful;
  const onConfirm = errors ? open : closeImport;
  const confirmButtonTitle = errors && !isLoading ? 'Import New File' : 'Close';

  return (
    <>
      <input {...getInputProps()} />

      <Menu component={importMenuButton} onOpen={openMenu} onClose={closeMenu}>
        <ActionMenuItem
          text="Import Projects"
          onClick={open}
          disabled={disabled}
        />
        <ActionMenuItem
          text="Download Template"
          onClick={downloadTemplate}
        />
      </Menu>

      {importStarted && (
        <StyledFormDialog
          title="Import Projects"
          open
          onCancel={closeImport}
          noCancel={noCancel}
          onClose={closeImport}
          noButtons={isLoading}
          onConfirm={onConfirm}
          confirmButtonTitle={confirmButtonTitle}
        >
          {isLoading && renderProcess()}
          {!isLoading && importSuccessful && renderSuccess()}
          {!isLoading && errors && renderErrors()}
        </StyledFormDialog>
      )}
    </>
  );
};

export default ImportProjects;
