import styled from 'styled-components';
import { MenuItem as MuiMenuItem } from '@mui/material';

export const StyledMenuItem = styled(MuiMenuItem)`
  height: 36px;
`;

export const TextWrapper = styled.div`
  margin-left: 15px;
  margin-top: 2px;
`;
