import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import { Menu } from '@mui/material';
import { IconButton, Icon } from 'components';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px 2px 0;
`;

export const StyledIconButton = styledMui(IconButton)(({ theme }) => {
  return {
    width: 40,
    height: 40,
    svg: {
      transform: 'scale(1.5)',
      '> path': {
        fill: `${theme.palette.icon.primary.color} !important`,
      },
    },
  };
});

export const StyledMenu = styledMui(Menu)(({ theme }) => {
  return {
    '.MuiMenu-paper': {
      minWidth: 193,
      color: theme.palette.text.main,
    },
  };
});

export const UserName = styledMui('div')(({ theme }) => {
  return {
    marginBottom: 2,
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '22px',
    color: theme.palette.text.main,
  };
});

export const UserEmail = styledMui('div')(({ theme }) => {
  return {
    fontFamily: 'Graphik LC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.text.main,
    opacity: 0.6,
  };
});

export const UserInfo = styledMui('div')(({ theme }) => {
  return {
    borderLeft: `1px solid ${theme.palette.border.light}`,
    paddingLeft: 24,
  };
});

export const UserMenuRoot = styled.div`
  display: flex;
`;

export const StyledIcon = styledMui(Icon)(({ theme }) => {
  return {
    'svg > path': {
      fill: `${theme.palette.icon.primary.color} !important`,
    },
  };
});
