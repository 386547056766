import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { Icon, Button } from 'components';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';

export const HeaderPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Controls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;

  > :last-child {
    margin-left: 24px;
  }
`;

export const StyledButton = styledMui(Button)(({ theme }) => {
  return {
    height: 40,
    maxWidth: 121,
    marginLeft: 24,
    color: theme.palette.button.text,
    background: theme.palette.button.color,
    borderRadius: 4,
    fontSize: 14,
  };
});

export const StyledFormDialog = styled(ConfirmationDialog)`
  .MuiDialog-paper {
    max-width: 600px;
  }
`;

export const ReportsRoot = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 249px;
  margin: 8px 0 0;
  background: var(--sidebarSelectedBackground);
  border-radius: 4px;
`;

export const ErrorsRoot = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  max-height: 195px;
  padding: 12px 16px 12px;
  margin: 24px 0 0;
  background: var(--sidebarSelectedBackground);
  border-radius: 4px;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const StyledSubtext = styled.div`
  font-family: Graphik LC;
  color: var(--lightTextColor);
  letter-spacing: 0.16px;
  text-align: center;
`;

export const StyledSubtextHighlighted = styled(StyledSubtext)`
  display: inline-block;
  font-weight: 500;
`;

export const StyledText = styled.div`
  font-family: Graphik LC;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: var(--textColor);
`;

export const SmallText = styled.div`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: var(--textColor);
`;

export const StyledCrossIcon = styled(Icon)`
  width: 10px;
  height: 10px;
  margin: 4px 8px 0 0;
`;

export const StyledIcon = styled(Icon)`
  width: 48px;
  height: 48px;
  color: var(--success);
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ErrorLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: top;
`;
