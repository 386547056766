import React, { useState } from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import { getNextPageInfo, getFolderByRole } from 'help/constants';
import SalesDepartmentModal from 'help/components/SalesDepartmentModal';

import { Title, TextSection, List, TextButton } from '../../styles';

const ProjectDetailsAndLocation = () => {
  const { role } = AuthService.getUser();
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  const folder = getFolderByRole(role);

  const detailsSrc = `projects/${folder}/projects-details.png`;
  const locationSrc = `projects/${folder}/projects-location.png`;

  const isICC = role === ROLES_IDS.ICCoordinator;
  const isBRorRMorDO =
    role === ROLES_IDS.Broker ||
    role === ROLES_IDS.InsuranceRepresentative ||
    role === ROLES_IDS.RiskManager ||
    role === ROLES_IDS.DeveloperOwner;

  const { title, path } = getNextPageInfo(role, 'details-location');

  return (
    <>
      <ImageContainer src={detailsSrc} alt="details" />
      <Title>Project Details</Title>
      {isICC && (
        <>
          <TextSection>
            Project Details sub-section contains some general information about
            the project. Project details are initially populated by the project
            coordinator at the time of creating the project and later on can be
            updated.
          </TextSection>
          <TextSection>
            In case the project should be closed, the status of the project can
            also be updated from that sub-section. In order to close the
            project, the closing reason must be specified. When the project is
            closed, the notification is sent to the project stakeholders.
          </TextSection>
        </>
      )}
      {!isICC && (
        <>
          <TextSection>
            Project Details sub-section contains some general information about
            the project which is populated by the insurance company at the time
            of creating the project.
          </TextSection>
          <TextSection>The project can have 2 statuses:</TextSection>
          <List>
            <li>Open</li>
            <li>Closed</li>
          </List>
          <TextSection>
            When the project is closed by the insurance company, all the project
            stakeholders gets the notification with the project closing reason.
          </TextSection>
        </>
      )}
      <ImageContainer src={locationSrc} alt="location" />
      <Title>Location</Title>
      {isBRorRMorDO && (
        <TextSection>
          Location sub-section shows the location of the project. The location
          is initially selected by the insurance company but can be updated by
          the risk engineer in case something has changed.
        </TextSection>
      )}
      {!isBRorRMorDO && (
        <>
          <TextSection>
            Location sub-section allows you to add project location with a help
            of the map or by searching the address in the search field.
            {!isICC &&
              ' Initially, the location is selected by the insurance company but can be updated by the risk company in case something has changed.'}
          </TextSection>
          <TextSection>
            By default, the first added location is the primary location of the
            project. You can add more locations and choose the primary one. Up
            to 5 secondary locations can be added for the project.
          </TextSection>
        </>
      )}
      <TextSection>
        Within the ‘Location’ sub-section, you can also apply various weather
        layers to see the location risk score. The following layers are
        available for your selection:
      </TextSection>
      <List>
        <li>Flood</li>
        <li>Wildfire</li>
        <li>Earthquake</li>
        <li>Windstorm</li>
        <li>Hurricane</li>
      </List>
      {(isICC || isBRorRMorDO) && (
        <TextSection>
          <i>
            {isBRorRMorDO
              ? 'Note that the layer selection is only available for the product subscribers. '
              : 'Note that the layer selection is only available if your company has subscribed to the product. '}
            For more details about the subscription, please contact our
            <TextButton onClick={handleModalOpen}>sales departments</TextButton>
            .
          </i>
        </TextSection>
      )}
      <RedirectButton redirectTitle={title} redirectPath={path} />
      {modalOpen && <SalesDepartmentModal onClose={handleModalClose} />}
    </>
  );
};

export default ProjectDetailsAndLocation;
