import styled from 'styled-components';

import { Button, Icon } from 'components';

export const StyledButton = styled(Button)`
  margin-left: 15px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.16px;
  color: var(--titleColor);
  
  & > :last-child {
    margin-left: 2px;
  }

  &&:hover {
    color: var(--buttonPrimaryColor);

    svg > path {
      fill: var(--buttonPrimaryColor);
    }
  }
`;

export const StyledIcon = styled(Icon)`
  margin-left: 10px;
  
  svg > path {
    fill: var(--iconPrimaryColor);
  }
`;
