import React from 'react';

const Location = () => {
  return (
    <svg width="27" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.478 14.978a4.077 4.077 0 110-8.153 4.077 4.077 0 010 8.153zm0-6.522a2.446 2.446 0 100 4.892 2.446 2.446 0 000-4.892z" fill="#fff" fillOpacity=".87" />
      <path d="M13.478 24.76l-6.877-8.11c-.039-.046-.284-.368-.284-.368a8.876 8.876 0 01-1.806-5.38 8.967 8.967 0 1117.934 0 8.873 8.873 0 01-1.805 5.378l-.001.002s-.245.322-.281.365l-6.88 8.114zm-5.86-9.46c.001 0 .19.251.234.305l5.626 6.635 5.633-6.644c.036-.045.227-.298.227-.298a7.256 7.256 0 001.477-4.396 7.337 7.337 0 10-14.674 0A7.26 7.26 0 007.62 15.3z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Location;
