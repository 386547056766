import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { ActionButton, SearchInput, PulsatingTooltip } from 'components';
import * as TeamMembersActions from 'myCompany/store/teamMembers.reducer';
import { membersSortBy, currentPage } from 'myCompany/store/teamMembers.selectors';
import * as SubscribersActions from 'subscriptions/store/subscriptions.reducer';
import { showNotifySuccess } from 'services/toaster';

import TeamMembersForm from './TeamMembersForm';
import TeamMembersTable from './TeamMembersTable';
import { TeamMembersRoot, HeaderPanel, Controls, ButtonWrapper } from './styles';
import { userInfo } from '../../../projects/store/users.selectors';

const TeamMembers = () => {
  const dispatch = useDispatch();

  const [searchFilter, setSearchFilter] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const page = useSelector(currentPage);
  const sortBy = useSelector(membersSortBy);
  const currrentUser = useSelector(userInfo);

  const getCurrentSubscription = () => dispatch(SubscribersActions.getCurrentSubscription());

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedMember(null);
  };

  const handleTableRowClick = (contactToView) => {
    setSelectedMember(contactToView);
    handleModalOpen();
  };

  const debouncedSetSearchFilter = debounce((value) => setSearchFilter(value), 400);

  const handleFilterChange = (event) => {
    debouncedSetSearchFilter(event.target.value);
  };

  const clearSearchFilter = () => {
    setSearchFilter('');
  };

  const fetchTeamMembersList = () => {
    dispatch(TeamMembersActions.getTeamMembersList({ page, sortBy }));
  };

  const updateMember = async (formValues) => {
    const response = await dispatch(TeamMembersActions.updateTeamMember(formValues));
    if (response.fieldErrors) return response;
    fetchTeamMembersList();
    showNotifySuccess(`${formValues.fullName} data was successfully updated.`);
    return response;
  };

  const addTeamMember = async (formValues) => {
    const response = await dispatch(TeamMembersActions.addTeamMember(formValues));
    if (response.fieldErrors) return response;
    fetchTeamMembersList();
    let successMessage = `${formValues.name.firstName} ${formValues.name.lastName} was successfully added to your company list.`;
    if (formValues.sendInvite) {
      successMessage = `${formValues.name.firstName} ${formValues.name.lastName} was successfully added to your company list. The invitation email was sent to ${formValues.email}.`;
    }
    showNotifySuccess(successMessage);
    return response;
  };

  return (
    <TeamMembersRoot>
      <HeaderPanel>
        <Controls>
          <SearchInput
            fullWidth
            placeholder="Search by Name"
            value={searchFilter}
            onChange={handleFilterChange}
            onClearSearch={clearSearchFilter}
          />
          <ButtonWrapper>
            <PulsatingTooltip place="TeamMembersButton" />
            <ActionButton
              text="New Team Member"
              color="primary"
              variant="contained"
              small
              dialogTitle="New Team Member"
              dialogForm={(
                <TeamMembersForm locationId={currrentUser.locationId} />
              )}
              onSubmit={addTeamMember}
              confirmButtonTitle="Save"
            />
          </ButtonWrapper>
        </Controls>
      </HeaderPanel>

      <TeamMembersTable
        searchFilter={searchFilter}
        onRowClick={handleTableRowClick}
      />

      {modalOpen && (
        <TeamMembersForm
          isOpen
          mode="view"
          onCancel={handleModalClose}
          onClose={handleModalClose}
          onSubmit={updateMember}
          member={selectedMember}
        />
      )}
    </TeamMembersRoot>
  );
};

export default TeamMembers;
