import { ModulePermissions } from "types";

export const help: ModulePermissions = {
  Help: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    RCCoordinator: {},
    RiskEngineer: {},
    SiteContact: {},
  },
  Account: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    RCCoordinator: {},
    RiskEngineer: {},
    SiteContact: {},
  },
  Subscriptions: {
    Admin: {},
  },
  Projects: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    SiteContact: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
  Dashboard: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    SiteContact: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
  'Global Ecosystem': {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
  'My Company': {
    ICCoordinator: {},
    RCCoordinator: {},
  },
  'Custom Reports': {
    ICCoordinator: {},
  },
  Communications: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    SiteContact: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
};
