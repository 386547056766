import { styled as styledMui } from '@mui/material/styles';

export const Title = styledMui('div')(({ theme }) => ({
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: 24,
  lineHeight: '33px',
  color: theme.palette.text.main,
  whiteSpace: 'nowrap',
  marginRight: 20,
}));
