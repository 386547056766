import React from 'react';

import { ToastRoot, Title, Message } from './styles';

const getTypeString = (type) => {
  switch (type) {
    case 'success':
      return 'Success';
    case 'alert':
      return 'Claim Alert';
    case 'error':
      return 'Error';
    case 'info':
      return 'New Notification';
    default:
      return `${type}`;
  }
};

const ToastMsg = ({ type, message, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ToastRoot onClick={handleClick}>
      <Title type={type}>
        {getTypeString(type)}
      </Title>
      <Message>
        {message}
      </Message>
    </ToastRoot>
  );
};

export default ToastMsg;
