import styled from 'styled-components';

import FormDialog from 'components/Dialogs/FormDialog';
import { Spinner } from 'components';

export const GreyText = styled.span`
  color: var(--inputDisabledText);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

export const StyledSpinner = styled(Spinner)`
  width: 95%;
`;

export const StyledFormDialog = styled(FormDialog)`
  .MuiDialog-paper {
    max-width: 700px;
  }
`;

export const FieldContainer = styled.div`
  margin-bottom: 30px;
`;

export const Root = styled.div`
  font-family: Graphik LC;
  font-size: 16px;
  line-height: 150%;
  color: var(--lightTextColor);
  margin-bottom: 30px;
`;
