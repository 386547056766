import InspectionsService from 'projects/api/InspectionsService';

const GET_INSPECTIONS_WITH_RISKS = 'GET_INSPECTIONS_WITH_RISKS';
const GET_INSPECTIONS_SHORT_LIST = 'GET_INSPECTIONS_SHORT_LIST';
const GET_INSPECTIONS_LIST = 'GET_INSPECTIONS_LIST';
const ADD_NEW_INSPECTION = 'ADD_NEW_INSPECTION';
const UPDATE_INSPECTION = 'UPDATE_INSPECTION';
const GET_PENDING_INSPECTION = 'GET_PENDING_INSPECTION';
const SET_INSPECTIONS_MARKERS = 'SET_INSPECTIONS_MARKERS';
const GET_NEXT_RISK_NUMBER = 'GET_NEXT_RISK_NUMBER';
const GET_INSPECTIONS_SUMMARY = 'GET_INSPECTIONS_SUMMARY';

export const getInspectionsList = (params) => ({
  type: GET_INSPECTIONS_LIST,
  apiCall: () => InspectionsService.getInspectionsList(params),
});

export const getInspectionsSummary = (id) => ({
  type: GET_INSPECTIONS_SUMMARY,
  apiCall: () => InspectionsService.getInspectionsSummary(id),
});

export const addNewInspection = (data) => (dispatch) => dispatch({
  type: ADD_NEW_INSPECTION,
  apiCall: () => InspectionsService.addNewInspection(data),
});

export const getInspectionsWithRisks = (params) => ({
  type: GET_INSPECTIONS_WITH_RISKS,
  apiCall: () => InspectionsService.getInspectionsWithRisks(params),
});

export const getNextRiskNumber = (id) => ({
  type: GET_NEXT_RISK_NUMBER,
  apiCall: () => InspectionsService.getNextRiskNumber(id),
});

export const getInspectionsShortList = (params) => ({
  type: GET_INSPECTIONS_SHORT_LIST,
  apiCall: () => InspectionsService.getInspectionsShortList(params),
});

export const updateInspection = (data) => (dispatch) => dispatch({
  type: UPDATE_INSPECTION,
  apiCall: () => InspectionsService.updateInspection(data),
});

export const getPendingInspection = (params) => ({
  type: GET_PENDING_INSPECTION,
  apiCall: () => InspectionsService.getPendingInspection(params),
});

export const setInspectionsMarkers = (markers) => ({
  type: SET_INSPECTIONS_MARKERS,
  markers,
});

const initialState = {
  inspectionsList: {
    data: [],
  },
  inspectionsWithRisks: {
    data: [],
  },
  inspectionsShortList: {
    data: [],
  },
  inspectionsMaxCount: {},
  inspectionsMarkers: {},
  nextRiskNumber: {},
  inspectionSummary: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_INSPECTIONS_LIST: {
      return {
        ...state,
        inspectionsList: {
          ...state.inspectionsList,
          ...response,
        },
      };
    }

    case ADD_NEW_INSPECTION: {
      return {
        ...state,
        addNewInspection: {
          ...state.addNewInspection,
          ...response,
        },
      };
    }

    case GET_INSPECTIONS_WITH_RISKS: {
      return {
        ...state,
        inspectionsWithRisks: {
          ...state.inspectionsWithRisks,
          ...response,
        },
      };
    }

    case GET_INSPECTIONS_SHORT_LIST: {
      return {
        ...state,
        inspectionsShortList: {
          ...state.inspectionsShortList,
          ...response,
        },
      };
    }

    case GET_PENDING_INSPECTION: {
      return {
        ...state,
        pendingInspection: {
          ...state.pendingInspection,
          ...response,
        },
      };
    }

    case SET_INSPECTIONS_MARKERS: {
      return {
        ...state,
        inspectionsMarkers: action.markers,
      };
    }

    case GET_NEXT_RISK_NUMBER: {
      return {
        ...state,
        nextRiskNumber: {
          ...state.nextRiskNumber,
          ...response,
        },
      };
    }

    case GET_INSPECTIONS_SUMMARY: {
      return {
        ...state,
        inspectionSummary: {
          ...state.inspectionSummary,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
