import React, { useState } from 'react';
import * as Yup from 'yup';

import { FormikField, Input } from 'components';
import { nullableRequiredString, requiredString } from 'utils/validation/customTypes';

import { Wrapper, StyledFormDialog } from './styles';

const LocationForm = ({ isOpen, mode: initialMode, onCancel, onSubmit, onClose, location, locationId }) => {
  const [mode, setMode] = useState(initialMode || 'add');
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState(location || { name: '', location: locationId });
  const [fieldsToChange, setFieldsToChange] = useState(null);
  const { address } = values;
  const { country } = address || {};

  const isAdd = mode === 'add';
  const isEdit = mode === 'edit';
  const isView = mode === 'view';

  let dialogTitle;
  if (isAdd) {
    dialogTitle = 'New Location';
  }
  if (isEdit) {
    dialogTitle = 'Edit Location';
  }
  if (isView) {
    dialogTitle = 'View Location';
  }

  const readOnlyMode = isView;

  const formInitialValues = {
    ...location,
    ...values,
  };

  const changeModeToEdit = () => {
    setMode('edit');
  };

  const handleSubmit = async (formValues, formikBag) => {
    const response = await onSubmit(formValues, formikBag);
    if (response.fieldErrors && response.fieldErrors.email) {
      setEmailError(response.fieldErrors);
    }
    return response;
  };

  const validationSchema = Yup.object().noUnknown().shape({
    name: requiredString,
  });

  return (
    <StyledFormDialog
      isLoading={isLoading}
      title={dialogTitle}
      submitButtonTitle="Save"
      open={isOpen}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      noCancel={isView}
      onClose={onClose}
      onFormChange={setValues}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      fieldsToChange={fieldsToChange}
    >
      <Wrapper>
        <FormikField
          component={Input}
          required
          readOnly={readOnlyMode}
          name="name"
          label="Name"
          />
      </Wrapper>
    </StyledFormDialog>
  );
};

export default LocationForm;
