import SubscriptionsService from 'non-subscribers/api/SubscriptionsService';

const SUBSCRIBERS_LIST = 'SUBSCRIBERS_LIST';
const SUBSCRIBERS_LIST_SORT_BY = 'SUBSCRIBERS_LIST_SORT_BY';
const SUBSCRIPTIONS_LIST = 'SUBSCRIPTIONS_LIST';
const SUBSCRIPTIONS_LIST_SORT_BY = 'SUBSCRIPTIONS_LIST_SORT_BY';
const SUBSCRIPTIONS_GET_CURRENT = 'SUBSCRIPTIONS_GET_CURRENT';

export const getSubscribersList = (params) => ({
  type: SUBSCRIBERS_LIST,
  apiCall: () => SubscriptionsService.getSubscribersList(params),
});

export const setSubscribersSortBy = (sortBy) => ({
  type: SUBSCRIBERS_LIST_SORT_BY,
  sortBy,
});

export const getSubscriptionsList = (params) => ({
  type: SUBSCRIPTIONS_LIST,
  apiCall: () => SubscriptionsService.getSubscriptionsList(params),
});

export const setSubscriptionsSortBy = (sortBy) => ({
  type: SUBSCRIPTIONS_LIST_SORT_BY,
  sortBy,
});

export const getCurrentSubscription = (params) => ({
  type: SUBSCRIPTIONS_GET_CURRENT,
  apiCall: () => SubscriptionsService.getCurrentSubscription(params),
});

const initialState = {
  subscribers: { isLoading: true },
  subscriptions: { isLoading: true },
  current: { isLoading: true },
};

export default (state = initialState, action = {}) => {
  const { response } = action;

  switch (action.type) {
    case SUBSCRIBERS_LIST: {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          ...response,
        },
      };
    }
    case SUBSCRIBERS_LIST_SORT_BY: {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          sortBy: action.sortBy,
        },
      };
    }

    case SUBSCRIPTIONS_LIST: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          ...response,
        },
      };
    }
    case SUBSCRIPTIONS_LIST_SORT_BY: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          sortBy: action.sortBy,
        },
      };
    }

    case SUBSCRIPTIONS_GET_CURRENT: {
      return {
        ...state,
        current: {
          ...state.current,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
