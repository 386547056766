import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';

import { Title, TextSection, List } from '../../styles';

const mapSrc = 'ge-map.png';
const chartSrc = 'ge-charts.png';
const filtersSrc = 'ge-filters.png';

const AdvancedDetails = () => (
  <>
    <Title>Overview</Title>
    <TextSection>
      Global Ecosystem section allows you use the map, charts and filters to get the insights from the collected data.
    </TextSection>
    <ImageContainer src={mapSrc} alt="map" />
    <Title>Map</Title>
    <TextSection>
      The map view provides an overview of the recommendations and claims grouped by country. For each country you can
      find the number of recommendations, number of claims as well as combined claim value for all the projects
      within that country.
    </TextSection>
    <ImageContainer src={chartSrc} alt="charts" />
    <Title>Charts</Title>
    <TextSection>
      The information gathered across the platform is displayed with a help of charts to provide a quick visual
      overview of the most  important data collected grouped by various parameters. Here you can find the visual
      overview of the data grouped by:
    </TextSection>
    <List>
      <li>Project Types</li>
      <li>Recommendation Types</li>
      <li>Recommendation Priorities</li>
      <li>Recommendation Statuses</li>
      <li>Claim Types</li>
      <li>Claim Amounts </li>
    </List>
    <ImageContainer src={filtersSrc} alt="filters" />
    <Title>Filters</Title>
    <TextSection>
      Various filters can be applied to the gathered data to help you get the insights you are looking for.
      The data can be filtered by the following parameters:
    </TextSection>
    <List>
      <li>Projects Type</li>
      <li>Business Category</li>
      <li>Construction Type</li>
      <li>Country</li>
      <li>Date</li>
    </List>
    <TextSection>
      When the filters are applied, all the data displayed within that section (overview, map, charts)
      is filtered according to the set criteria.
    </TextSection>
  </>
);

export default AdvancedDetails;
