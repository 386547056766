import styled from 'styled-components';

import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background-color: var(--notificationsBackground);
    padding: 30px;
    border-radius: 4px;
  }

  .MuiDialogTitle-root {
    padding: 16px 0;
  }

  .MuiDialogContent-root {
    padding: 0;
    overflow-y: hidden;
  }

  h2.MuiTypography-root {
    display: flex;
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 30px;
    color: var(--titleColor);

    > div:not(:last-child) {
      margin-right: 24px;
      flex-shrink: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;

  > div {
    margin-left: 15px;
  }
`;
