import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { ListItem, ListItemIcon } from "@mui/material";

import { IconButton } from "components";

export const ChildrenWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 33px;
`;

export const StyledListItem = styledMui(ListItem)(({ theme, selected }) => ({
  "&.MuiListItem-root": {
    minWidth: 42,
    height: 48,
    marginBottom: 5,
    borderRadius: 4,
    color: theme.palette.sidebar.text,
  },
  "& .MuiTypography-body1": {
    fontFamily: "Graphik LC",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "19px",
    color: theme.palette.text.main,
    opacity: selected ? 1 : 0.6,
  },
  "&.MuiListItem-button:hover": {
    backgroundColor: theme.palette.input.hover,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.sidebar.selected.backgroundColor,
    color: theme.palette.text.main,
  },
  "&.MuiListItem-gutters": {
    padding: 0,
  },
  "> .MuiListItemIcon-root": {
    minWidth: 40,
    justifyContent: "center",
  },
  "&.draggedOver": {
    backgroundColor: theme.palette.navbar.tab.active,
    "& .MuiTypography-body1": {
      color: "#142426",
    },
    "svg > path": {
      fill: theme.palette.text.main,
    },
  },
}));

export const StyledItemIcon = styledMui(ListItemIcon)(
  ({ selected, theme }) => ({
    ...(selected && { background: theme.palette.navbar.tab.active }),
    borderRadius: 4,
    "&.MuiListItemIcon-root": {
      minWidth: 30,
      minHeight: 30,
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      marginLeft: 6,
      marginRight: 10,
      "& svg > path": {
        fill: selected
          ? theme.palette.sidebar.selected.icon.color
          : theme.palette.icon.primary.color,
      },
    },
  })
);

export const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
`;
