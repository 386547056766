import React from 'react';

import StepperModal from 'onboarding/components/StepperModal';
import ModalPage from 'onboarding/components/ModalPage';
import RedirectMenu from 'onboarding/components/RedirectMenu';

const getPageData = (step) => {
  switch (step) {
    case 0:
      return {
        title: 'Global Ecosystem - Overview',
        src: 'ge-overview.png',
        description: 'Global Ecosystem collates the risk recommendations and claims data across all the users and provides the ability to get the insights from the collected data.',
      };
    case 1:
      return {
        title: 'Map',
        src: 'ge-map.png',
        description: 'Map view provides an overview of all the recommendations and claims across the platform grouped by country.',
      };
    case 2:
      return {
        title: 'Charts',
        src: 'ge-charts.png',
        description: 'The information gathered is displayed with a help of charts to provide a quick visual overview of the most important data collected.',
      };
    case 3:
      return {
        title: 'Filters',
        src: 'ge-filters.png',
        description: 'Various filters can be applied to the displayed data to help you get the insights you are looking for.',
      };
    default:
      return {};
  }
};

const GlobalEcosystemModal = ({ open, onCancel }) => {
  const renderStepContent = (activeStep) => {
    switch (activeStep) {
      case 0:
      case 1:
      case 2:
      case 3: {
        const { title, src, description } = getPageData(activeStep);
        return (
          <ModalPage
            title={title}
            src={src}
            description={description}
          />
        );
      }
      case 4:
        return (
          <RedirectMenu
            onCancel={onCancel}
            currentSection="globalEcosystem"
          />
        );
      default:
        return null;
    }
  };

  return (
    <StepperModal
      renderStepContent={renderStepContent}
      numberOfSteps={5}
      open={open}
      onCancel={onCancel}
    />
  );
};

export default GlobalEcosystemModal;
