import React from 'react';

import { formatSize, getFileType } from 'utils/FormatUtils';
import { Icon } from 'components';

import {
  ProgressWrapper, ProgressInfo, ProgressInfoWrapper, StyledLinearProgress,
  ProgressName, ProgressSize, ProgressCount, TypeIconWrapper,
} from './styles';

function Progress(props) {
  const { isLayoutsFolder, currentProgressInfo, progressValue } = props;
  const { name, maxCount, count, layouts, size } = currentProgressInfo;
  let value = progressValue;
  if (Array.isArray(progressValue)) {
    value = progressValue.reduce((acc, curr) => (acc + curr));
  }

  return (
    <ProgressWrapper>
      <TypeIconWrapper>
        {!isLayoutsFolder && <Icon icon={getFileType(name || '')} fallback="unknown" />}
        {isLayoutsFolder && <Icon icon="pdf" fallback="unknown" />}
      </TypeIconWrapper>
      <ProgressInfoWrapper>
        {maxCount > 1 && !isLayoutsFolder && (
          <ProgressInfo>
            <ProgressCount>{`${count}/${maxCount}`}</ProgressCount>
          </ProgressInfo>
        )}
        {isLayoutsFolder && layouts && layouts.map((layout) => (
          <ProgressInfo>
            <ProgressName>{layout.name}</ProgressName>
            {layout.size && <ProgressSize>{formatSize(layout.size)}</ProgressSize>}
          </ProgressInfo>
        ))}
        {(!isLayoutsFolder || !layouts) && (
          <ProgressInfo>
            <ProgressName>{name}</ProgressName>
            {size && <ProgressSize>{formatSize(size)}</ProgressSize>}
          </ProgressInfo>
        )}
        <StyledLinearProgress variant="determinate" value={value} />
      </ProgressInfoWrapper>
    </ProgressWrapper>
  );
}

export default Progress;
