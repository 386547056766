import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CONTACT_MAX_LENGTHS } from 'utils/constants';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import DesignationField from 'common/components/DesignationField';
import UploadImage from 'common/components/UploadImage';
import { Input, Select, FormikField, Button } from 'components';
import { designationsList } from 'common/store/designations.selectors';

import * as DesignationsActions from 'common/store/designations.reducer';
import { DataWrapper, StyledForm, Column, Row, ButtonWrapper, Title } from './styles';
import { DESIGNATIONS } from '../../../../../myCompany/pages/Rates/constants';
import { COMPANY_TYPES } from 'types/company';

const PersonalInfoForm = ({ formikProps, countriesData, setCvFileId, cvFileInfo, forceUpdate }) => {
  const dispatch = useDispatch();
  const [modalType, setModalType] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const { role, companyType } = AuthService.getUser();
  const designations = useSelector(designationsList);
  const isICC = role === ROLES_IDS.ICCoordinator;
  const isRCC = role === ROLES_IDS.RCCoordinator;
  const isRE = role === ROLES_IDS.RiskEngineer;
  const isERE = isRE && companyType === COMPANY_TYPES.NO_COMPANY;
  const [additionalDesignations, setAdditionalDesignations] = useState([]);

  const countryOptions = countriesData.map((countryItem) => ({
    label: countryItem.name,
    value: countryItem.name,
  }));

  const getStateOptions = (selectedCountry) => {
    const states = countriesData.find((countryItem) => countryItem.name === selectedCountry)?.states || [];
    return states.map((countryState) => ({
      label: countryState,
      value: countryState,
    }));
  };

  const { address: { country } } = formikProps.values;
  const companyStateOptions = getStateOptions(country);
  const companyStateComponent = (['United States', 'United Kingdom'].includes(country) ? Select : Input);
  const companyStateLabel = (country === 'United States' ? 'State' : 'Region');
  const companyZipCodeLabel = (country === 'United Kingdom' ? 'Postal Code' : 'Zip Code');

  const handleCountryChange = (value) => {
    formikProps.setFieldValue('address.country', value);
    formikProps.setFieldValue('address.state', '');
  };

  const cancelChanges = () => {
    setModalType('');
    formikProps.resetForm();
    setIsEditable(false);
  };

  const handleUpdateClick = () => {
    setIsEditable(true);
  };

  const handleSaveClick = () => {
    setModalType('Update');
  };

  const handleCancelClick = () => {
    setModalType('Cancel');
  };

  const handleCloseModal = () => {
    setModalType('');
  };

  const getModalData = () => {
    if (modalType === 'Cancel') {
      return {
        title: 'Cancel changes',
        button: 'Continue',
        confirm: cancelChanges,
        text: "Are you sure you want to cancel changes? Your changes haven't been saved, if you continue they will be lost.",
      };
    }
    return {
      title: 'Update changes',
      button: 'Update',
      confirm: formikProps.submitForm,
      text: "Are you sure you want to update your personal info? Your previous data will be lost, you can't undo this action.",
    };
  };

  useEffect(() => {
    dispatch(DesignationsActions.getDesignations());
  }, []);

  useEffect(() => {
    const diffDesignations = [...new Set(designations)];
    const newDesignations = [];
    diffDesignations.forEach((designation) => {
      const desig = DESIGNATIONS.find((t) => t.value === designation);
      if (desig == null) {
        newDesignations.push({ value: designation, label: designation });
      }
    });
    setAdditionalDesignations(newDesignations);
  }, [designations]);

  const { title, button, confirm, text } = getModalData();

  const renderFormRows = () => {
    return (
      <Column>
        <Title>Personal Details</Title>
        <Row>
          <FormikField
            component={Input}
            required
            name="name.firstName"
            label="First Name"
            readOnly={!isEditable}
            maxLength={CONTACT_MAX_LENGTHS.name}
          />
          <FormikField
            component={Input}
            required
            name="name.lastName"
            label="Last Name"
            readOnly={!isEditable}
            maxLength={CONTACT_MAX_LENGTHS.name}
          />
        </Row>
        <Row>
          <FormikField
            component={Input}
            required
            name="email"
            label="Email"
            readOnly={!isEditable}
            disabled
          />
          <DesignationField
            name="designation"
            readOnly={!isEditable}
            isRE={isRE}
            required={isRE}
            additionalDesignations={additionalDesignations}
            companyType={companyType}
          />
        </Row>
        <Row>
          <FormikField
            component={Select}
            required
            name="address.country"
            label="Country"
            options={countryOptions}
            readOnly={!isEditable}
            onChange={handleCountryChange}
          />
        </Row>
        <Row>
          <FormikField
            component={Input}
            required
            name="address.addressLine"
            label="Address Line"
            maxLength={CONTACT_MAX_LENGTHS.address}
            readOnly={!isEditable}
          />
        </Row>
        <Row>
          <FormikField
            component={companyStateComponent}
            required
            disabled={!country}
            name="address.state"
            label={companyStateLabel}
            maxLength={CONTACT_MAX_LENGTHS.state}
            options={companyStateOptions}
            readOnly={!isEditable}
          />
          <FormikField
            component={Input}
            required
            name="address.city"
            label="City"
            maxLength={CONTACT_MAX_LENGTHS.city}
            readOnly={!isEditable}
          />
        </Row>
        <Row>
          <FormikField
            component={Input}
            required
            name="address.zipCode"
            label={companyZipCodeLabel}
            maxLength={CONTACT_MAX_LENGTHS.zipCode}
            readOnly={!isEditable}
          />
          <FormikField
            readOnly={!isEditable}
            component={Input}
            required
            name="phone"
            type="phone"
            phoneCountryMask={country}
            label="Phone"
          />
        </Row>
        <Row>
          <FormikField
            component={Input}
            required
            name="companyName"
            label="Company"
            readOnly={!isEditable}
            disabled={isICC || isRCC || (isRE && !isERE)}
          />
        </Row>
      </Column>
    );
  };
  return (
    <>
      <StyledForm>
        <DataWrapper>
          {renderFormRows()}
          {isRE && (
            <Column>
              <Title>Upload File</Title>
              <UploadImage
                name="cvFileId"
                setFieldsToChange={setCvFileId}
                fileInfo={cvFileInfo}
                disabled={!isEditable}
                showEmptyMessage
                forceUpdate={forceUpdate}
                label="Upload CV"
                reinitialize
              />
            </Column>
          )}
        </DataWrapper>
        {!isEditable && (
          <Button
            onClick={handleUpdateClick}
            label="update"
            color="primary"
            variant="contained"
            formNoValidate
            text="Update"
          />
        )}
        {isEditable && (
          <ButtonWrapper>
            <Button
              onClick={handleCancelClick}
              label="cancel"
              variant="contained"
              formNoValidate
              text="Cancel"
            />
            <Button
              onClick={handleSaveClick}
              label="submit"
              color="primary"
              variant="contained"
              formNoValidate
              text="Save"
            />
          </ButtonWrapper>
        )}
      </StyledForm>
      {modalType && (
        <ConfirmationDialog
          open
          title={title}
          confirmButtonTitle={button}
          onClose={handleCloseModal}
          onCancel={handleCloseModal}
          onConfirm={confirm}
        >
          {text}
        </ConfirmationDialog>
      )}
    </>
  );
};
export default PersonalInfoForm;
