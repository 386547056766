import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const NavTabs = styledMui('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '10px',
  borderBottom: `1px solid ${theme.palette.navbar.tab.border}`,
  '& > div:not(:last-child)': {
    marginRight: '5px',
  },
}));

export const NavLabel = styled.div`
  min-width: 80px;
  margin-bottom: 14px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
`;

export const SelectedBorder = styled.div`
  position: absolute;
  bottom: -2.5px;
  width: 0;
  transition: width 300ms ease-out 0ms;

  ${({ selected }) => selected && `
    width: 36px;
  `}
`;

export const NavTab = styledMui(Link)(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none !important',
  'svg path': {
    fillOpacity: 1,
    fill: theme.palette.navbar.tab.color,
  },
  '> .selected-border': {
    borderBottom: `4px solid ${theme.palette.navbar.tab.active}`,
  },
  '> .nav-label': {
    color: theme.palette.navbar.tab.label.color,
  },
  '&:active, &.selected': {
    '& svg path': {
      fill: theme.palette.navbar.tab.active,
    },
    '& .nav-label': {
      color: theme.palette.navbar.tab.label.active,
    },
  },
  '&:hover': {
    '& svg path': {
      fill: theme.palette.navbar.tab.active,
    },
    '& .nav-label': {
      color: theme.palette.navbar.tab.label.hover,
    },
  },
  '&.disabled': {
    cursor: 'default',
    pointerEvents: 'none',
    '& svg path': {
      fill: theme.palette.navbar.tab.disabled,
    },
    '& .nav-label': {
      color: theme.palette.navbar.tab.label.disabled,
    },
  },
}));

// export const NavTab = styled(Link)`
//   cursor: pointer;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   text-decoration: none;

//   ${SelectedBorder} {
//     border-bottom: 4px solid var(--navTabFillActive);
//   }

//   ${NavLabel} {
//     color: var(--navTabLabel);
//   }

//   svg path {
//     fill-opacity: 1;
//     fill: var(--navTabFill);
//   }

//   &:active, &.selected {
//     svg path {
//       fill: var(--navTabFillActive);
//     }

//     ${NavLabel} {
//       color: var(--navTabLabelActive);
//     }
//   }

//   &:hover {
//     svg path {
//       fill: var(--navTabFillActive);
//     }

//     ${NavLabel} {
//       color: var(--navTabLabelHover);
//     }
//   }

//   &.disabled {
//     cursor: default;
//     pointer-events: none;

//     svg path {
//       fill: var(--navTabFillDisabled);
//     }

//     ${NavLabel} {
//       color:var(--navTabLabelDisabled);
//     }
//   }
// `;

export const IconWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NavItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
