import React from 'react';

const ThemeLight = () => {
  return (
    <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 9.254h0a3.754 3.754 0 00-3.75 3.75v0A3.75 3.75 0 1013 9.254zm-2.43.112a4.375 4.375 0 114.86 7.275 4.375 4.375 0 01-4.86-7.275zM5.09 5.536l.442-.442 2.141 2.141-.442.442L5.09 5.536zM2.125 12.691h3.063v.625H2.124v-.625zM5.09 20.473l2.141-2.141.442.442-2.141 2.141-.442-.442zM12.688 20.817h.624v3.062h-.624v-3.063zM18.328 18.773l.442-.442 2.142 2.142-.442.442-2.142-2.142zM20.813 12.691h3.062v.625h-3.063v-.625zM18.328 7.234l2.142-2.14.442.441-2.142 2.141-.442-.442zM12.688 2.129h.624v3.063h-.624V2.129z" fill="#059FDE" stroke="#059FDE" />
    </svg>
  );
};

export default ThemeLight;
