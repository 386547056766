import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { Tooltip as MuiTooltip } from '@mui/material';

import { showNotifySuccess, showNotifyError } from 'services/toaster';
import * as LibraryActions from 'library/store/library.reducer';
import { IconButton, ActionButton, SearchInput, NoContent, Input } from 'components';
import ConfirmationText from 'components/Dialogs/ConfirmationText';
import { userInfo } from 'projects/store/users.selectors';
import LibraryService from 'library/api/LibraryService';
import useProgress from 'utils/hooks/useProgress';
import useQuery from 'utils/hooks/useQuery';
import AuthService, { ROLES_IDS } from 'services/AuthService';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import { getFolderById } from '../FoldersList/helpers';

import FilesTable from '../FilesTable';
import FileUploadButton from './FileUploadButton';
import {
  FilesListRoot,
  Title,
  Controls,
  Buttons,
  ButtonGroup,
  StyledIcon,
} from './styles';

const FilesList = ({ permissions, folders }) => {
  const { role } = AuthService.getUser();
  const dispatch = useDispatch();
  const user = useSelector(userInfo);
  const query = useQuery();
  const [isEditNameOpened, setIsEditNameOpened] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [selectedFileId, setSelectedFileId] = useState('');
  const [selectedFileExtension, setSelectedFileExtension] = useState('');

  const [searchFilter, setSearchFilter] = useState('');
  const [checkedItems, setCheckedItems] = useState([]);

  const folderId = query.get('folderId');
  const selectedFolder = getFolderById(folders, folderId);
  const isFolderOwner = selectedFolder?.creatorId === user?.id;

  const {
    setInProgress,
    setCurrentProgressInfo,
    setProgressValue,
    handleMultiDownloadProgress,
    renderProgress,
    clearProgress,
  } = useProgress(false);

  const { ownerRightsCheck } = permissions;
  const defaultUploadAllowed =
        permissions.upload;
  const uploadAllowed =
        defaultUploadAllowed || isFolderOwner;
  const deleteForbidden = !permissions.delete && !isFolderOwner;
  const deletePossible =
        !ownerRightsCheck ||
        checkedItems.filter((item) => item.creatorId === user.id).length > 0;
  const disableDelete =
        checkedItems.length === 0 || deleteForbidden || !deletePossible;
  const isRE = role === ROLES_IDS.RiskEngineer;

  const canEditName = selectedFolder;

  const debouncedSetSearchFilter = debounce(
    (value) => setSearchFilter(value),
    400,
  );

  const privateTooltipText =
        permissions.makePrivate && 'Make Private';
  const isPrivateDisabled =
        checkedItems.length === 0 ||
        !permissions.makePrivate;

  const handleFilterChange = (event) => {
    debouncedSetSearchFilter(event.target.value);
  };

  const clearSearchFilter = () => {
    setSearchFilter('');
  };

  const refreshFiles = () => {
    dispatch(LibraryActions.getFiles({ folderId }));
    setCheckedItems([]);
  };

  const getDeleteConfirmationText = () => {
    if (checkedItems.length === 1) {
      return (
        <ConfirmationText
          value={checkedItems[0].name}
          text="Are you sure you want to delete {placeholder} file? It will be deleted immediately, you can't undo this action."
                />
      );
    }

    return (
      <ConfirmationText
        value={checkedItems.length}
        text="Are you sure you want to delete {placeholder} files? They will be deleted immediately, you can't undo this action."
            />
    );
  };

  const handleMakePrivateFiles = async () => {
    if (checkedItems.length > 0) {
      try {
        const fileIds = checkedItems.map((file) => file.id);
        await LibraryService.updateVisibility(fileIds, true);
        let successMsg = `${checkedItems.length} files were made private.`;
        if (checkedItems.length === 1) {
          successMsg = `${checkedItems[0].name} file is private now.`;
        }
        showNotifySuccess(successMsg);
        refreshFiles();
      } catch (error) {
        showNotifyError(error);
      }
    }
  };

  const openEditName = (id, filename) => {
    const n = filename.split('.');
    setSelectedFileExtension(filename.split('.').pop());
    setSelectedName(n[0]);
    setSelectedFileId(id);
    setIsEditNameOpened(true);
  };

  const editNameChange = (v) => {
    setSelectedName(v.target.value.replace(/[/\\?%*:.|"<>]/g, ''));
  };

  const submitEditName = async () => {
    if (selectedName == null || selectedName === '') return;
    try {
      await LibraryService.renameFile(selectedFileId, `${selectedName}.${selectedFileExtension}`);
      const successMsg = 'File name updated';
      showNotifySuccess(successMsg);
      refreshFiles();
    } catch (e) {
      const error = Object.values(e.fieldErrors)[0];
      showNotifyError(error);
    }
    setIsEditNameOpened(false);
  };

  function renderEditNameModal() {
    return (
      <ConfirmationDialog
        open
        onClose={() => setIsEditNameOpened(false)}
        onConfirm={submitEditName}
        onCancel={() => setIsEditNameOpened(false)}
        title="Edit File Name"
        confirmButtonTitle="Rename">
        <Input
          value={selectedName}
          label="Name"
          formControlStyle={{ flexBasis: '25%' }}
          onChange={editNameChange}
          required
          error={selectedName === '' && 'Name required'}
          errorExpand={selectedName === ''}
                />
      </ConfirmationDialog>
    );
  }

  const handleDownloadFiles = async () => {
    if (checkedItems.length > 0) {
      try {
        setInProgress(true);
        setProgressValue(0);
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const maxCount = checkedItems.length;
        const size = checkedItems.map((val) => val.size).reduce(reducer);
        setCurrentProgressInfo({
          name: checkedItems.map(({ name }) => name).join(', '),
          size,
          count: 1,
          maxCount,
        });
        await Promise.all(
          checkedItems.map(({ downloadUrl, name }, index) => {
            return LibraryService.downloadFile(
              downloadUrl,
              name,
              false,
              handleMultiDownloadProgress,
              { maxCount, size, index },
            );
          }),
        );
        if (isRE) {
          await Promise.all(
            checkedItems.map(({ id }) => {
              return LibraryService.markAsOpened(id);
            }),
          );
        }
      } catch (error) {
        showNotifyError(error);
      } finally {
        clearProgress();
      }
    }
  };

  const handleDeleteFiles = async () => {
    if (checkedItems.length > 0) {
      try {
        const fileIds = checkedItems
          .map((file) => {
            let isOwner = true;
            if (ownerRightsCheck) {
              isOwner = file.creatorId === user.id;
            }
            return isOwner ? file.id : null;
          })
          .filter((el) => el);
        if (!fileIds.length) return;

        let successMsg = `${fileIds.length} files were successfully deleted.`;
        if (fileIds.length === 1) {
          const deletedFileName = checkedItems.find(
            (checkedFile) => checkedFile.id === fileIds[0],
          ).name;
          successMsg = `${deletedFileName} file was successfully deleted.`;
        }
        await LibraryService.deleteFiles(fileIds);
        showNotifySuccess(successMsg);
        refreshFiles();
      } catch (error) {
        showNotifyError(error);
      }
    }
  };

  const handleUploadProgress = (progressEvent) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setProgressValue(percentCompleted);
  };

  const handleFileUpload = async (file) => {
    let response = null;
    setInProgress(true);
    setProgressValue(0);
    try {
      const { url, s3Key } = await LibraryService.getUploadFileUrl(
        folderId,
        file.name,
      );
      await LibraryService.uploadFileToAWS(
        file,
        url,
        handleUploadProgress,
      );
      response = { s3Key, name: file.name };
    } catch (error) {
      let errMsg = error;

      if (error.status === 400 && error.fieldErrors) {
        errMsg = Object.values(error.fieldErrors).join('. ');
      }
      if (errMsg !== 'The value should not be empty.') showNotifyError(errMsg);
    }
    setInProgress(false);
    return response;
  };

  const handleLayoutsPdfUpload = async (file) => {
    let response = null;
    setInProgress(true);
    setProgressValue(0);
    try {
      const {
        data: { data },
      } = await LibraryService.uploadPdfToLayouts(
        folderId,
        file,
        handleUploadProgress,
      );
      response = data;
    } catch (error) {
      let errMsg = error;
      if (error.status === 400 && error.fieldErrors) {
        errMsg = Object.values(error.fieldErrors).join('. ');
      }
      if (errMsg !== 'The value should not be empty.') showNotifyError(errMsg);
    }
    setInProgress(false);
    return response;
  };

  const handleUpload = async (file) => {
    const response = await handleFileUpload(file);
    return response;
  };

  if (!selectedFolder) {
    return (
      <FilesListRoot>
        <NoContent
          title="No folder selected."
          descr="Please select a folder to view documents."
                />
      </FilesListRoot>
    );
  }

  return (
    <FilesListRoot>
      <Title>
        {selectedFolder.name}
        <StyledIcon icon="chevronRight" />
        Files
      </Title>

      <Controls>
        <SearchInput
          fullWidth
          borderless
          placeholder="Search by Name"
          value={searchFilter}
          onChange={handleFilterChange}
          onClearSearch={clearSearchFilter}
                />
        <Buttons>
          <ButtonGroup noUpload={!uploadAllowed}>
            <MuiTooltip arrow title={privateTooltipText}>
              <span>
                <IconButton
                  icon="unlocked"
                  color="primary"
                  disabled={isPrivateDisabled}
                  onClick={handleMakePrivateFiles}
                  transparent
                                />
              </span>
            </MuiTooltip>
            <IconButton
              icon="download"
              color="primary"
              disabled={checkedItems.length === 0}
              onClick={handleDownloadFiles}
              transparent
                        />
            <ActionButton
              isIcon
              icon="deleteSmall"
              color="primary"
              text="Delete Files"
              dialogTitle="Delete Files"
              disabled={disableDelete
                            }
              dialogContent={getDeleteConfirmationText()
                            }
              onConfirm={handleDeleteFiles}
              confirmButtonTitle="Delete"
                        />
          </ButtonGroup>

          {uploadAllowed && (
            <FileUploadButton
              folder={selectedFolder}
              isLayoutsFolder={false}
              onFileSelect={handleUpload}
              setCurrentUpload={setCurrentProgressInfo}
              refreshFiles={refreshFiles}
                        />
          )}
        </Buttons>
      </Controls>

      <FilesTable
        folderId={folderId}
        searchFilter={searchFilter}
        checkedItems={checkedItems}
        onSetCheckedItems={setCheckedItems}
        permissions={permissions}
        isLayoutsFolder={false}
        isRiskReviewFolder={false}
        canEditName={canEditName}
        openEditName={openEditName}
        isFolderOwner={isFolderOwner}
            />

      {renderProgress()}

      {isEditNameOpened && renderEditNameModal()}
    </FilesListRoot>
  );
};

export default FilesList;
