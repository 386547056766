import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as SubscribersActions from 'non-subscribers/store/subscriptions.reducer';
import {
  subscribersList,
  subscribersSortBy,
} from 'non-subscribers/store/subscriptions.selectors';
import { getAmountWithCurrencySymbol } from 'utils/CurrencyUtils';
import {
  renderStatusLabelByValue,
  CONTACT_STATUSES,
} from 'utils/constants';
import { subscribersTypes } from 'non-subscribers/constants';
import { Table, NoContent } from 'components';

import SubscriptionsControls from './SubscriptionsControls';

import { InviteStatusTag } from './styles';
import AccordionTable from '../../../../components/AccordionTable';

const renderCountTitle = (count) => `Users: ${count}`;
const renderProjectCountTitle = (projectCount) => `Projects: ${projectCount}`;
const renderTotalValueOfProjectsTitle = (totalValueOfProjects) => `Total Value of Projects: ${getAmountWithCurrencySymbol(
  totalValueOfProjects,
  'USD',
)}`;

const getColumns = (isCompany) => [
  {
    width: '14%',
    key: 'name',
    title: isCompany ? 'Company Name' : 'Name',
  },
  {
    width: '14%',
    key: 'email',
    title: isCompany ? 'Email' : 'Email',
  },
  {
    width: '14%',
    key: 'phone',
    title: 'Phone Number',
  },
  {
    width: '14%',
    key: 'parentCompany',
    title: 'Parent Company',
  },
  {
    width: '11%',
    key: 'count',
    title: 'Count',
    noSort: true,
    render: ({ count }) => count != null && renderCountTitle(count),
  },
  {
    width: '11%',
    key: 'projectCount',
    title: '# Projects',
    noSort: true,
    render: ({ projectCount }) => projectCount != null && renderProjectCountTitle(projectCount),
  },
  {
    width: '40%',
    key: 'totalValueOfProjects',
    title: 'Total Value of Projects',
    noSort: true,
    render: ({ totalValueOfProjects }) => totalValueOfProjects != null &&
      renderTotalValueOfProjectsTitle(totalValueOfProjects),
  },
  {
    width: '6%',
    key: 'controls',
    title: '',
    noSort: true,
    align: 'right',
    minWidth: '90px',
  },
];

const columns = [
  {
    width: '20%',
    key: 'name',
    title: 'Name',
    noSort: true,
  },
  {
    width: '20%',
    key: 'email',
    title: 'Email',
    noSort: true,
  },
  {
    width: '14%',
    key: 'parentCompany',
    title: 'Parent Company',
  },
  {
    width: '20%',
    key: 'role',
    title: 'Role',
    noSort: true,
  },
  {
    width: '20%',
    key: 'location',
    title: 'Location',
    noSort: true,
  },
  {
    width: '70%',
    key: 'inviteStatus',
    title: 'Invite Status',
    noSort: true,
    render: ({ inviteStatus }) => (
      <InviteStatusTag status={inviteStatus}>
        {renderStatusLabelByValue(inviteStatus, CONTACT_STATUSES)}
      </InviteStatusTag>
    ),
  },
  {
    width: '6%',
    key: 'controls',
    title: '',
    noSort: true,
    align: 'right',
    minWidth: '90px',
  },
];

const SubscriptionsTable = ({ searchFilter, isCompany, subscriberType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const subscribers = useSelector(subscribersList);
  const sortBy = useSelector(subscribersSortBy);

  const handleSortBy = (newSortBy) => {
    dispatch(SubscribersActions.setSubscribersSortBy(newSortBy));
  };

  const handlePrevPageClick = () => {
    setPage(page - 1);
  };

  const handleNextPageClick = () => {
    setPage(page + 1);
  };

  const handleGoToPage = (selectedPage) => {
    setPage(selectedPage);
  };

  const viewSubscription = (value, icInviteStatus) => {
    const id = value.id || value;
    icInviteStatus === 'Sent'
      ? navigate(`/non-subscribers/view/invited-company/${value}`)
      : navigate(
        `/non-subscribers/view/${subscribersTypes[subscriberType].path}/${id}`,
      );
  };

  const getSubscriptions = () => {
    dispatch(
      SubscribersActions.getSubscribersList({
        page,
        sortBy,
        searchFilter,
        subscriberType,
      }),
    );
  };

  const renderControls = (company) => {
    if (subscriberType !== 'InsuranceCompany') return null;
    return (
      <SubscriptionsControls
        company={company}
        onSuccess={getSubscriptions}
        viewSubscription={viewSubscription}
      />
    );
  };

  useEffect(() => {
    setPage(1);
  }, [searchFilter]);

  useEffect(() => {
    getSubscriptions();
  }, [page, sortBy, searchFilter]);

  const renderNoContent = () => {
    if (searchFilter) {
      return (
        <NoContent
          customIcon="subscriptions"
          title="No results found."
          descr="Try adjust your search to find what you are looking for."
        />
      );
    }
    return (
      <NoContent
        customIcon="subscriptions"
        title="There are no subscribers yet."
        descr="Once they are added, they will appear here."
      />
    );
  };

  const { isLoading, items = [], totalPages, totalCount } = subscribers;

  const mappedItems = items.map((item) => ({
    ...item.subscriptionInfo,
    ...item,
    columns,
  }));
  const [expandedTables, setExpandedTables] = useState([]);

  return isCompany ? (
    <AccordionTable
      nonCheckableRows
      items={mappedItems}
      columns={getColumns(isCompany)}
      isLoading={isLoading}
      onNoContent={renderNoContent}
      onRenderControls={renderControls}
      setExpandedTables={setExpandedTables}
      expandedTables={expandedTables}
      pagination={{
        page,
        totalPages,
        itemsCount: items.length,
        totalCount,
      }}
      onPrevPage={handlePrevPageClick}
      onNextPage={handleNextPageClick}
      onGoToPage={handleGoToPage}
    />
  ) : (
    <Table
      items={mappedItems}
      columns={getColumns(isCompany)}
      sortBy={sortBy}
      isLoading={isLoading}
      onSortBy={handleSortBy}
      onNoContent={renderNoContent}
      pagination={{
        page,
        totalPages,
        itemsCount: items.length,
        totalCount,
      }}
      onPrevPage={handlePrevPageClick}
      onNextPage={handleNextPageClick}
      onGoToPage={handleGoToPage}
      onRenderControls={renderControls}
    />
  );
};

export default SubscriptionsTable;
