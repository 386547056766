export const layers = [
  {
    label: 'Flood',
    value: 'flood',
    tooltip: 'Global Synoptic Flood Risk based on historical large-scale, impactful flood events across the globe the past 35 years.  Hazard analysis based on frequency, significance, and distribution of flood events.',
  },
  {
    label: 'Wildfire',
    value: 'wildfire',
    tooltip: 'Global Wildfire Risk based on historical satellite derived monthly burn area from 2000 to 2020. A global landcover filter was applied to account for agricultural controlled burning. Hazard analysis based on exceedance of spatially unique annual burn frequency threshold over 20 years.',
  },
  {
    label: 'Earthquake',
    value: 'earthquake',
    tooltip: 'Global Earthquake Risk accounting for historical earthquakes, ground shaking measurements, location and distribution of faults, and deformation zones.  Hazard analysis based on 10% probability of exceedance in 50 years, return period of 475 years, in units of horizontal ground movement in units of gravity (m/s²).',
  },
  {
    label: 'Windstorm',
    value: 'windstorm',
    tooltip: 'Global Windstorm Risk represented with the 50-year peak wind gust speed (m/s) across the globe derived from 40-years of global reanalysis data.',
  },
  {
    label: 'Hurricane',
    value: 'hurricane',
    tooltip: 'This layer describes the observed path, forecast track, and intensity of tropical cyclone activity (hurricanes, typhoons, cyclones) from the National Hurricane Center (NHC) and Joint Typhoon Warning Center (JTWC).',
  },
];

export const comingSoonLayers = ['Lightning', 'Tornado', 'Hailstorm', 'Coastal Storm Surge', 'Tsunami', 'Landslide', 'Geology', 'Sinkholes', 'Riverine Flood Risk'];
