import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NOTIFICATION_ENUM } from 'utils/constants';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import DocumentService from 'projects/api/DocumentService';
import { formatCommonDate, formatNotificationTime } from 'utils/DateUtils';

import { Text, HighlightedText, InfoWrapper, Contact, StyledButton, StyledIcon, Line, AgendaLine, Link } from './styles';

const getNotificationText = (type, notify) => {
  const { data: { projectName, inspectionCancelReason } } = notify;

  switch (type) {
    case NOTIFICATION_ENUM.inspectionUpcomingSoon:
      return (
        <>
          Please be notified that you have an upcoming insurance inspection for the
          <HighlightedText>
            {projectName}
          </HighlightedText>
          .
        </>
      );

    case NOTIFICATION_ENUM.riskInspectionScheduled:
      return (
        <>
          Please be notified that following agreement, the insurance inspection has been scheduled for the
          <HighlightedText>
            {projectName}
          </HighlightedText>
          project. See the agenda attached. If you have any questions, please contact the Risk Engineer.
        </>
      );

    case NOTIFICATION_ENUM.virtualInspectionScheduled:
      return (
        <>
          Please be notified that following agreement, the insurance virtual inspection for the
          <HighlightedText>
            {projectName}
          </HighlightedText>
          has been scheduled. This will not entail a physical inspection of the site. Instead a
          review of documents requested in the attached agenda should be sent 5-days prior to the scheduled date
          and an online meeting will be held on the day. If you have any questions, please contact the Risk Engineer.
        </>
      );

    case NOTIFICATION_ENUM.inspectionUpdated:
      return (
        <>
          Please be notified that following agreement, the insurance inspection for the
          <HighlightedText>
            {projectName}
          </HighlightedText>
          has been updated. If you have any questions, please contact the Risk Engineer.
        </>
      );

    case NOTIFICATION_ENUM.inspectionCanceled:
      return (
        <>
          Please be notified that following agreement, the insurance inspection for the
          <HighlightedText>
            {projectName}
          </HighlightedText>
          has been canceled. If you have any questions, please contact the Risk Engineer.
        </>
      );

    case NOTIFICATION_ENUM.inspectionCanceledWithReason:
      return (
        <>
          Please be notified that following agreement, the insurance inspection for the
          <HighlightedText>
            {projectName}
          </HighlightedText>
          has been canceled with reason: {inspectionCancelReason}. If you have any questions, please contact the Risk Engineer.
        </>
      );

    case NOTIFICATION_ENUM.inspectionScheduleReminder:
      return (
        <>
          iMitig8 Risk Global has detected that you have an anticipated inspection for the
          <HighlightedText>
            {projectName}
          </HighlightedText>
          in a month. Please make sure you schedule the inspection in advance.
          {' '}
          If the inspection date has been changed or the inspection have already been scheduled,
          {' '}
          just ignore this notification.
        </>
      );

    case NOTIFICATION_ENUM.furtherActionRiskActioned:
      return (
        <> iMitig8risk Global has detected that the insured Site Contact has taken additional actions to update
          the recommendations on project {projectName}, where you have requested additional information to close the risk.
        </>
      );

    default: return null;
  }
};

const InspectionsNotifications = ({ notify, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = AuthService.getUser();
  const isSC = role === ROLES_IDS.SiteContact;

  const { data, subject } = notify;
  const {
    projectId, projectName, inspectionStartDateTime, inspectionEndDateTime,
    inspectionLocation, inspectionOnlineMeetingLink,
    inspectionAgendaFileUrl, inspectionAgendaFileName,
  } = data;

  const text = getNotificationText(type, notify);

  const handleClose = () => dispatch(NotificationsActions.setOpenedNotification(null));

  const closeNotificationList = () => dispatch(NotificationsActions.setNotificationsView(''));

  const defaultProps = {
    route: isSC ? '/dashboard/inspections' : `/projects/${projectId}/details`,
    confirmButtonTitle: isSC ? 'Go to Inspections' : 'Go to Project',
    withInfo: true,
  };
  const notificationsProps = {
    [NOTIFICATION_ENUM.inspectionCanceled]: {
      ...defaultProps,
      withInfo: false,
    },
    [NOTIFICATION_ENUM.inspectionCanceledWithReason]: {
      ...defaultProps,
      withInfo: false,
    },
    [NOTIFICATION_ENUM.inspectionScheduleReminder]: {
      route: `/projects/${projectId}/inspections`,
      confirmButtonTitle: 'Schedule Inspection',
      withInfo: false,
    },
    [NOTIFICATION_ENUM.furtherActionRiskActioned]: {
      ...defaultProps,
      withInfo: false,
    },
    default: defaultProps,
  };
  const { route, confirmButtonTitle, withInfo } = notificationsProps[type] || notificationsProps.default;

  const redirectToInspectionsOrProjectDetails = () => {
    handleClose();
    closeNotificationList();
    navigate(route);
  };

  const handleDownloadClick = async () => {
    DocumentService.downloadFile(inspectionAgendaFileUrl, inspectionAgendaFileName);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title={subject}
      cancelButtonTitle="Close"
      confirmButtonTitle={confirmButtonTitle}
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToInspectionsOrProjectDetails}
    >
      <Text>
        {text}
        <br />
        {withInfo && (
          <InfoWrapper>
            <Contact>
              {inspectionStartDateTime && (
                <Line>
                  <HighlightedText>Date & Time:</HighlightedText>
                  <div>{`${formatCommonDate(inspectionStartDateTime, true)}, ${formatNotificationTime(inspectionStartDateTime)} - ${formatNotificationTime(inspectionEndDateTime)} (your local time)`}</div>
                </Line>
              )}
              {inspectionLocation && (
                <div>
                  <HighlightedText>Location: </HighlightedText>
                  {inspectionLocation}
                </div>
              )}
              {inspectionOnlineMeetingLink && (
                <div>
                  <HighlightedText>Online Meeting Link: </HighlightedText>
                  <Link target="_blank" href={inspectionOnlineMeetingLink} rel="noopener noreferrer">
                    {inspectionOnlineMeetingLink}
                  </Link>
                </div>
              )}
              <AgendaLine>
                <HighlightedText>Agenda: </HighlightedText>
                {inspectionAgendaFileUrl
                  ? (
                    <StyledButton
                      small
                      type="button"
                      label="download"
                      color="primary"
                      variant="text"
                      text="Download File"
                      onClick={handleDownloadClick}
                    >
                      <StyledIcon icon="download" noWrapper />
                    </StyledButton>
                  )
                  : <div>No Agenda</div>
                }
              </AgendaLine>
            </Contact>
          </InfoWrapper>
        )}
      </Text>
    </ConfirmationDialog>
  );
};

export default InspectionsNotifications;
