import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import { getNextPageInfo, getFolderByRole } from 'help/constants';
import ContactsSection from 'help/pages/Projects/components/ContactsSection';

import { Title, TextSection, List, Underline } from '../../styles';

const ContactsAndResources = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const resourcesSrc = `projects/${folder}/projects-resources.png`;

  const isICC = role === ROLES_IDS.ICCoordinator;

  const { title, path } = getNextPageInfo(role, 'contacts-resources');
  return (
    <>
      <ContactsSection />
      <ImageContainer src={resourcesSrc} alt="resources" />
      <Title>Resources</Title>
      <TextSection>
        Resources sub-section allows you to assign the risk engineer to the project.
        Two options are available when assigning the risk engineer:
      </TextSection>
      <List>
        <li>Internal Risk Engineer</li>
        <li>External Risk Engineer</li>
      </List>
      {isICC && (
        <TextSection>
          When the <Underline>Internal Risk Engineer</Underline> is assigned to the project,
          such user receives the invite and get access to the project. When the external
          Risk Company is assigned, the coordinator of the company receives the
          invite and is able to assign either the internal or external risk engineer.
          Once assigned, such risk engineer receives the access to the project.
        </TextSection>
      )}
      {!isICC && (
        <TextSection>
          When the Internal or External Risk Engineer assigned to the project, they will
          receive the email invitation for the project. If they already have account the portal,
          they will also receive a new assignment notification within the web and mobile app.
        </TextSection>
      )}
      <RedirectButton redirectTitle={title} redirectPath={path} />
    </>
  );
};

export default ContactsAndResources;
