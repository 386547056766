import React, { useState } from 'react';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import {
  Content,
  IntroBlock,
  List,
  StyledLink,
  TextSection,
  Underline,
} from './styles';

const EulaAgreement = ({ accept, close }) => {
  const today = new Date().toString();
  const [canAccept, setCanAccept] = useState(false);
  const onScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1;
    if (bottom) {
      setCanAccept(true);
    } else {
      setCanAccept(false);
    }
  };
  return (
    <ConfirmationDialog
      open
      onClose={close}
      onConfirm={accept}
      onCancel={close}
      title="iMitig8 End User License and Website Terms of Use Agreement"
      confirmButtonTitle="I Agree"
      cancelButtonTitle="Decline"
      disabledConfirm={!canAccept}
      maxWidth="md"
    >
      <Content onScroll={onScroll}>
        <IntroBlock>
          <div>iMitig8 End User License and Website Terms of Use Agreement</div>
        </IntroBlock>
        <TextSection>
          This End User License and Website Terms of Use Agreement (&quot;
          <strong>Agreement</strong>&quot; or &quot;<strong>EULA</strong>&quot;)
          is a binding agreement between you and, if you represent an entity
          identified in your registration information posted in the Services,
          that entity (collectively &quot;<strong>you</strong>&quot; or
          &quot;End User&quot;) and iMitig8 Risk LLC, a Florida limited
          liability company (&quot;Company&quot; or &quot;We&quot;). This
          Agreement governs your use of our &quot;iMitig8&quot; Platform Portal,
          including the website located at IMITIG8RISK.COM (&quot;Platform
          Portal&quot;) and each of our mobile Services available in the Apple
          and Google App Stores as developed or provided by &quot;iMitig8&quot;,
          &quot;iMitig8 Risk LLC&quot; or &quot;iMitig8 Risk Global&quot; (each
          an &quot;Application&quot;), and each other website and mobile
          Services that requires your agreement to these terms, which we refer
          to as the &quot;Services&quot;.
        </TextSection>
        <TextSection>
          The Services are licensed, not sold, to you or any other person.
        </TextSection>
        <TextSection>
          SPECIAL NOTICE FOR USERS REGISTERED PRIOR TO <span>{today}</span>.
          YOUR ACCEPTANCE OF THIS AGREEMENT, AS DETAILED IN THE FOLLOWING
          PARAGRAPH, INCLUDES YOUR AGREEMENT THAT THIS AGREEMENT SUPERCEDES AND
          REPLACES ANY AND ALL PRIOR TERMS AND CONDITIONS, LICENSES AND PRIVACY
          POLICIES BETWEEN YOU AND COMPANY, AND APPLIES RETROACTIVELY AS TO YOUR
          FIRST USE OF THE SERVICES.
        </TextSection>
        <TextSection>
          BY CLICKING THE &quot;I AGREE&quot; BUTTON, YOU (A) ACKNOWLEDGE THAT
          YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU
          ARE 18 YEARS OF AGE OR OLDER/OF LEGAL AGE TO ENTER INTO A BINDING
          AGREEMENT; AND (C) YOU INDIVIDUALLY, AND IF YOU REGISTERED SPECIFYING
          AN ENTITY THAT YOU REPRESENT, ALSO THAT ENTITY, ACCEPT THIS AGREEMENT
          AND AGREE THAT YOU (AND, IF APPLICABLE, THAT ENTITY) ARE LEGALLY BOUND
          BY ITS TERMS. IF YOU (OR THAT ENTITY) DO NOT AGREE TO THESE TERMS, DO
          NOT ACCESS OR USE THE SERVICES.
        </TextSection>
        <List>
          <li>
            <Underline>License Grant</Underline>. Subject to the terms of this
            Agreement, Company grants you a limited, non-exclusive, and
            nontransferable license to access and use the Platform Portal and
            download, install, and use the Application:
            <List type="a">
              <li>
                Only by accessing the Services using a single specific account
                for each named user (&quot;User Account&quot;) created through
                the Services’ registration process, and only so long as the
                information provided during registration (or later required by
                the Company) is accurate in all respects; and
              </li>
              <li>
                Solely for commercial purposes, either:
                <List type="i">
                  <li>
                    As an employee or agent of an insurance company that has a
                    separate written agreement with the Company (each, an
                    “Insurer”); or
                  </li>
                  <li>
                    As a third party granted access to the Services by an
                    Insurer or its designee with respect to one or more projects
                    initiated by the Insurer (“Project”), in each case, only
                    with respect to such features, roles and responsibilities
                    for such Project assigned to you by such Insurer (“Project
                    Roles”), and subject at all times to modifications to, or
                    revocations of, such Project Roles by the Insurer for such
                    Project (“Applicable Insurer”) or the Company; and
                  </li>
                </List>
              </li>
              <li>
                The Services may incorporate data from third party data
                providers, such as weather data (“Third Party Data)”) All Third
                Party Data may be subject to specific terms and conditions. Your
                right to access and use such Third Party Data is subject to your
                acceptance of such terms and conditions.
              </li>
            </List>
          </li>
          <li>
            <Underline>License Restrictions</Underline>. You shall not:
            <List type="a">
              <li>
                use the Services for any purpose other than such Projects to
                which you have been assigned by the Applicable Insurer, and only
                in a manner consistent with your assigned Project Roles for each
                such Project;
              </li>
              <li>
                use the Services on devices not owned or otherwise controlled by
                you (&quot;Mobile Device&quot;);
              </li>
              <li>
                use the Services other than strictly in accordance with the
                Services&quot; documentation;
              </li>
              <li>
                copy the Services, except as expressly permitted by this
                license;
              </li>
              <li>
                modify, translate, adapt, or otherwise create derivative works
                or improvements, whether or not patentable, of the Services;
              </li>
              <li>
                reverse engineer, disassemble, decompile, decode, or otherwise
                attempt to derive or gain access to the source code of the
                Services or any part thereof, or otherwise attempt to identify,
                document or define the business, functional or technical details
                of the Services;
              </li>
              <li>
                remove, delete, alter, or obscure any trademarks or any
                copyright, trademark, patent, or other intellectual property or
                proprietary rights notices from the Services, including any copy
                thereof;
              </li>
              <li>
                rent, lease, lend, sell, sublicense, assign, distribute,
                publish, transfer, or otherwise make available the Services, or
                any features or functionality of the Services, to any third
                party for any reason;
              </li>
              <li>
                remove, disable, circumvent, or otherwise create or implement
                any workaround to any copy protection, rights management, or
                security features in or protecting the Services;
              </li>
              <li>
                use the Services in a manner that violates the Company’s
                Acceptable Use Policy stated in Section 4; or
              </li>
              <li>
                use any version of any Application other than its most recent
                update.
              </li>
            </List>
          </li>
          <li>
            <Underline>Submitted Content; Insurer Content.</Underline>
            <List type="a">
              <li>
                The Services, depending on your Project Role(s), may allow you
                to submit, provide, exchange, communicate, and/or display to
                other users or other persons for specific Projects (“Post” )
                content or materials (collectively, “Project Content”). You
                represent that you own or have the right to submit all Project
                Content and you specifically agree that the Project Content does
                not violate any law or regulation, this Agreement, or the rights
                of others.
              </li>
              <li>
                You agree that automatically upon your Posting Project Content,
                you grant:
                <List type="i">
                  <li>
                    To the Applicable Insurer controlling the Project into which
                    the Project Content was Posted, and its affiliates and
                    sublicensees, an unlimited, worldwide, irrevocable license
                    to use the Project Content (or such greater rights granted
                    by you to the Applicable Insurer by separate written
                    agreement or by operation of law);
                  </li>
                  <li>
                    To the Company and our affiliates and sublicenses, the right
                    to use, reproduce, modify, display, and distribute the
                    Project Content, subject only to the Company’s written
                    agreement with the Applicable Insurer restricting such use
                    and our Privacy Policy.
                  </li>
                </List>
              </li>
              <li>
                You understand and acknowledge that you are responsible for any
                and all Project Content you submit or contribute and you, not
                the Company, have full responsibility to confirm the accuracy of
                such Project Content as made available by the Services once
                Posted, and for its legality, reliability, accuracy and
                appropriateness.
              </li>
              <li>
                We are not responsible, or liable to you or any third party, for
                the content or accuracy of any Project Content posted by you or
                any other user of the Site.
              </li>
              <li>
                We have the right to remove or refuse any Project Content for
                any or no reason in in our sole discretion and may take any
                action we deem necessary in respect to any Project Content,
                subject only to our agreements with the Applicable Insurer.
              </li>
              <li>
                Without limiting the foregoing, we have the right to fully
                cooperate with any law enforcement authorities or court order
                requesting or directing us to disclose the identity or other
                information of anyone posting any materials on or in the
                Services.
                <strong>
                  You waive and hold harmless the Company from any claims
                  resulting from any action taken by the Company resulting from
                  any action taken during or as a result of its investigations
                  and from any actions taken a s a consequence of investigations
                  by the Company or law enforcement.
                </strong>
              </li>
              <li>
                You may have access to data within in the Project, including by
                way of example Project Content submitted by other users within a
                Project or from third party data providers (“Third-Party
                Materials”). You acknowledge that you have no rights in any
                Third-Party Materials. You acknowledge and agree that Company is
                not responsible for Third-Party Materials, including their
                accuracy, completeness, timeliness, validity, copyright
                compliance, legality, decency, quality, or any other aspect
                thereof. Company does not assume and will not have any liability
                or responsibility to you or any other person or entity for any
                Third-Party Materials. Third-Party Materials and links thereto
                are provided solely as a convenience to you, and you access and
                use them entirely at your own risk and subject to such third
                parties&quot; terms and conditions.
              </li>
              <li>
                Each Applicable Insurer is a third party beneficiary to this
                Agreement solely with respect to Subsection 3(b) for its
                Projects.
              </li>
            </List>
          </li>
          <li>
            <Underline>Acceptable Use Policy. </Underline> You agree never to
            use or to enable others to use the Services to do or attempt to:
            <List type="a">
              <li>
                Use the login credentials of another person or allow another
                person to use your login credentials;
              </li>
              <li>
                Violate any applicable federal or state law, regulation,
                judicial or governmental order, treaty, Company’s rights, any
                Applicable Insurer’s rights, or the rights of any person, firm,
                or enterprise;
              </li>
              <li>
                Engage in conduct that is or could be considered libelous,
                defamatory, indecent, vulgar, obscene, pornographic, sexually
                explicit or suggestive, racially or ethically offensive,
                harmful, harassing, intimidating, threatening, discriminatory,
                or abusive;
              </li>
              <li>
                Use the Services for posting or uploading malicious or
                unauthorized code (e.g., viruses, time bombs, cancel bots,
                worms, Trojan horses, spyware) or other potentially harmful
                material or information which in any way might interrupt,
                damage, interfere with, destroy, or limit the functionality of
                any computer software or hardware or communication equipment;
              </li>
              <li>
                Modify the Services’ data, code or configurations in any manner,
                other than such settings generally made available to you for
                your Project Role in a Project;
              </li>
              <li>
                Use any robot, spider, or other automatic device, process or
                means to access the Services for any purpose, including
                monitoring or copying any material on or in the Services; or
              </li>
              <li>
                Use the Services in any manner that could disable, disrupt,
                damage, impair, or interfere with the use, features, functions,
                operations, or maintenance of the Services.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Services, the server(s) on which the
                Services is stored, or any server, computer or database
                connected to the Services;
              </li>
              <li>
                Attack the Services via a denial-of-service attack or a
                distributed denial-of-service attack; and
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of the
                Services.
              </li>
            </List>
          </li>
          <li>
            <Underline>Reservation of Rights.</Underline> You acknowledge and
            agree that the Services are provided under license, and not sold, to
            you. You do not acquire any ownership interest in the Services under
            this Agreement, or any other rights thereto other than to use the
            Services in accordance with the license granted, and subject to all
            terms, conditions, and restrictions, under this Agreement. Company
            and its affiliates, licensors and service providers reserves and
            shall retain their entire right, title, and interest in and to the
            Services, including all copyrights, trademarks, and other
            intellectual property rights therein or relating thereto, except as
            expressly granted to you in this Agreement.
          </li>
          <li>
            <Underline>Confidentiality.</Underline> You agree to keep the
            functionality of the Services its documentation, and all data made
            available to you within any Project (“Confidential Information”)
            confidential and not disclose to any third party such Confidential
            Information of the other party, unless permitted in writing by the
            Company, with respect to the functionality of the Services, and the
            Applicable Insurer with respect to data made available to you within
            any Project.
          </li>
          <li>
            <Underline>Privacy</Underline>
            <List type="a">
              <li>
                You agree to receive emails, notices, alerts, notifications and
                information from the Services using the contact information
                provided by you in your User Account.
              </li>
              <li>
                The Company will take reasonable steps to ensure that your
                personal information collected from or about you held by the
                Company (“Your Data”) is secure from unauthorized access, use or
                disclosure and will treat Your data in accordance with the
                Company’s Privacy Policy found at
                <StyledLink
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy.
                </StyledLink>
                , as it may be updated from time to time by the Company.
              </li>
              <li>
                It is your responsibility to satisfy yourself that your use of
                the Services will allow you to meet any legal obligations
                applicable to you in respect of Your Data, and Company disclaims
                all liability for your non-compliance with any such laws or
                standards arising from your use of the Services.
              </li>
            </List>
          </li>
          <li>
            <Underline>Geographic Restrictions.</Underline> The Services are
            based in the United. You acknowledge that access thereto may not be
            legal by certain persons or in certain countries. If you access the
            Content and Services from outside the United States, you agree to do
            so only if the Services comply with all applicable laws and
            regulations, and that your use of the Services are in compliance
            with all applicable laws and regulations.
          </li>
          <li>
            <Underline>Updates.</Underline> Company may from time to time in its
            sole discretion develop and provide Applications and Platform Portal
            updates, which may include upgrades, bug fixes, patches, other error
            corrections, and/or new features (collectively, including related
            documentation, &quot;Updates&quot;). Updates may also modify or
            delete in their entirety certain features and functionality. You
            agree that Company has no obligation to provide any Updates or to
            continue to provide or enable any particular features or
            functionality. You shall promptly download and install all Updates
            to Applications and acknowledge and agree that the Services or
            portions thereof may not properly operate should you fail to do so.
            You further agree that all Updates will be deemed part of the
            Services and be subject to all terms and conditions of this
            Agreement.
          </li>
          <li>
            <Underline>Effective Date; Term and Termination. </Underline> This
            Agreement shall be deemed effective as of the earlier of: (a) your
            first use of the Services, even if such date occurred prior to your
            acceptance of this Agreement, or (b) the date you accepted this
            Agreement. This Agreement shall continue in effect for so long as
            you use any Services, or retain a copy of any Application on any
            Mobile Device, unless
            <List type="a">
              <li>
                Terminated earlier by the Company in its sole discretion, with
                or without notice;
              </li>
              <li>
                Automatically, if you violate any of the terms and conditions of
                this Agreement.
              </li>
              <li>
                You may terminate this Agreement by requesting that the Company
                “cancel” your registration.
              </li>
              <li>
                The Company updates or modifies this Agreement or its Privacy
                Policy in a manner requiring your agreement, and you do not
                agree to such updates or modifications.
              </li>
            </List>
          </li>
          <li>
            <Underline>Effect of termination.</Underline>
            <List type="a">
              <li>
                Upon any termination of this Agreement,
                <List type="i">
                  <li>
                    all rights granted to you under this Agreement shall
                    automatically terminate;
                  </li>
                  <li>
                    you must immediately cease all use of the Services and
                    delete all copies of the Application from your Mobile Device
                    and account;
                  </li>
                  <li>
                    the termination will not limit any of Company&apos;s rights
                    or remedies at law or in equity; and
                  </li>
                  <li>
                    such termination shall not impact the right to access and
                    use your submitted Content as granted by you in Section 3.
                  </li>
                </List>
              </li>
              <li>
                The following sections of this Agreement shall survive all
                terminations of this Agreement:
                <Underline>
                  Sections 3 (“Submitted Content; Insurer Content”),5
                  (“Reservation of Rights”) ,6 (“Confidentiality”), 7
                  (“Privacy”), 11 (“Effect of Termination”), 12 (“Disclaimer of
                  Warranties”), 13 (“Limitation of Liability”), 14
                  (“Indemnification”), 17 (“U.S. Government Rights”), 18
                  (“Agreement to Arbitrate”), 19 (“No Class Actions, Class
                  Arbitrations, or Representative Actions”) 20 (“Other Terms)
                </Underline>
              </li>
            </List>
          </li>
          <li>
            <Underline>Disclaimer of Warranties.</Underline> THE SERVICES ARE
            PROVIDED TO YOU &quot;AS IS&quot; AND WITH ALL FAULTS AND DEFECTS
            WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER
            APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS
            AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE
            PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
            IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SERVICES,
            INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT
            MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR
            TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, COMPANY
            PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF
            ANY KIND THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY
            INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
            SERVICES, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET
            ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT
            ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
            <br />
            <br />
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
            IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY
            RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND
            LIMITATIONS MAY NOT APPLY TO YOU.
          </li>
          <li>
            <Underline>Limitation of Liability.</Underline> TO THE FULLEST
            EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS
            AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE
            PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF
            OR INABILITY TO USE THE SERVICES OR THE CONTENT AND SERVICES FOR:
            <List type="a">
              <li>
                PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF
                SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL,
                BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY
                OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
                OR PUNITIVE DAMAGES.
              </li>
              <li>
                DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE
                AMOUNT ACTUALLY PAID BY YOU FOR THE SERVICES.
              </li>
            </List>
            THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT
            OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND
            REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO
            NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE
            ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
          </li>
          <li>
            <Underline>Indemnification.</Underline> You agree to indemnify,
            defend, and hold harmless Company and its officers, directors,
            employees, agents, affiliates, successors, and assigns from and
            against any and all losses, damages, liabilities, deficiencies,
            claims, actions, judgments, settlements, interest, awards,
            penalties, fines, costs, or expenses of whatever kind, including
            attorneys&apos; fees, arising from or relating to your (and with
            respect to entities, each of your End Users) use or misuse of the
            Services or your breach of this Agreement, including but not limited
            to the content you submit or make available through the Services
          </li>
          <li>
            <Underline>Company Name/Address: </Underline>
            All questions, complaints, claims or other notices to the Company
            shall be in writing and shall be made either via email or
            conventional mail to the addresses set forth below, and shall be
            deemed delivered on the date actually received by Company:
            <br />
            <TextSection>
              <Underline>Company:</Underline> iMitig8 Risk LLC
            </TextSection>
            <TextSection>
              <Underline>Email:</Underline> legal@imitig8risk.com
            </TextSection>
            <TextSection>
              <Underline>Address:</Underline>
              <br />
              Atlanta Tech Village
              <br />
              3423 Piedmont Road NE
              <br />
              Atlanta, Georgia, 30305
            </TextSection>
          </li>
          <li>
            <Underline>Export Regulation; Prohibited Countries</Underline>The
            Services may be subject to US export control laws, including the
            Export Control Reform Act and its associated regulations. You shall
            not, directly or indirectly, export, re-export, or release the
            Services to, or make the Services accessible from, any jurisdiction
            or country to which export, re-export, or release is prohibited by
            law, rule, or regulation. You shall comply with all applicable
            federal laws, regulations, and rules, and complete all required
            undertakings (including obtaining any necessary export license or
            other governmental approval), prior to exporting, re-exporting,
            releasing, or otherwise making the Services available outside the
            US. You represent and warrant that you (and any entity you
            represent) (i) are not locatPrivaed in a country that is subject to
            a U.S. Government embargo, or that has been designated by the U.S.
            Government as a “terrorist supporting” country; and (ii) are not
            listed on any U.S. Government list of prohibited or restricted
            parties.
          </li>
          <li>
            <Underline>US Government Rights.</Underline> The Services are
            commercial computer software, as such term is defined in 48 C.F.R.
            §2.101. Accordingly, if you are an agency of the US Government or
            any contractor therefor, you receive only those rights with respect
            to the Services as are granted to all other end users under license,
            in accordance with (a) 48 C.F.R. §227.7201 through 48 C.F.R.
            §227.7204, with respect to the Department of Defense and their
            contractors, or (b) 48 C.F.R. §12.212, with respect to all other US
            Government licensees and their contractors.
          </li>
          <li>
            <Underline>Agreement to Arbitrate.</Underline>Company and you agree
            to arbitrate all Disputes between us. This agreement to arbitrate is
            intended to be broadly interpreted. It includes, but is not limited
            to: (i) Disputes arising out of or relating to any aspect of the
            relationship between us, whether based in equity, contract, tort,
            statute, fraud, misrepresentation or any other legal theory; (ii)
            Disputes that arose before this or any prior Agreement (including,
            but not limited to, claims relating to advertising); (iii) Disputes
            that are currently the subject of purported class action litigation
            in which you are not a member of a certified class; and (iv)
            Disputes that may arise after the termination of this Agreement.
            References to “Company” in this Section include our Affiliates.
            <strong>
              You agree that, by entering into this Agreement, you and Company
              are each waiving the right to a trial by jury or to participate in
              a class action. THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND
              STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A
              JUDGE OR A JURY,
            </strong>{' '}
            instead electing that all Disputes shall be resolved by arbitration.
            <strong>
              {' '}
              YOU ACKNOWLEDGE THAT YOU HAVE BEEN ADVISED THAT YOU MAY CONSULT
              WITH AN ATTORNEY IN DECIDING TO ACCEPT THIS AGREEMENT TO
              ARBITRATE.
            </strong>
            This Agreement evidences a transaction in interstate commerce, and
            thus the Federal Arbitration Act governs the interpretation and
            enforcement of this provision. This arbitration provision shall
            survive termination of this Agreement.
            <br />A party who intends to seek arbitration must first send to the
            other a written Notice of Dispute (“<strong>Notice</strong>”). The
            Notice to Company should be addressed to: legal@imitig8risk.com (“
            <strong>Notice Address</strong>”). We may provide notice to your
            email address that you use during registration; provided that you
            may inform Company of an address for Notice within 15 days of the
            date this Agreement is first effective on you by providing such
            address in writing by certified mail to the Company address in the
            foregoing sentence and a copy to that email address above. The
            Notice must (a) describe the nature and basis of the claim or
            dispute; and (b) set forth the specific relief sought (“
            <strong>Demand</strong>”). If Company and you do not reach an
            agreement to resolve the Dispute within 30 days after the Notice is
            received, you or Company may commence an arbitration proceeding.
            During the arbitration, the amount of any settlement offer made by
            the Company, or you shall not be disclosed to the arbitrator until
            after the arbitrator determines the amount, if any, to which you or
            Company is entitled.
            <br />
            The arbitration will be governed by the Commercial Arbitration Rules
            (collectively, “AAA Rules”) of the American Arbitration Association
            (“AAA”), as modified by this Agreement, and will be administered by
            the AAA. The AAA Rules are available online at adr.org, by calling
            the AAA at 1-800-778-7879. The arbitrator is bound by the terms of
            this Agreement. All issues are for the arbitrator to decide, other
            than issues relating to the scope and enforceability of the
            arbitration provision are for the court to decide. The arbitration
            will be conducted solely on the basis of documents submitted to the
            arbitrator, through a telephonic hearing, or by an in-person hearing
            as established by the AAA Rules. Regardless of the manner in which
            the arbitration is conducted, the arbitrator shall issue a reasoned
            written decision sufficient to explain the essential findings and
            conclusions on which the award is based. The payment of all AAA
            filing, administration, and arbitrator fees for any arbitration
            initiated in accordance with the notice requirements above will be
            governed by the AAA Rules. The arbitrator may award declaratory or
            injunctive relief only in favor of the individual party seeking
            relief and only to the extent necessary to provide relief warranted
            by that party’s individual claim.
          </li>
          <li>
            <Underline>
              No Class Actions, Class Arbitrations, or Representative Actions
            </Underline>
            <strong>
              YOU AND COMPANY AGREE THAT EACH MAY BRING DISPUTES AND CLAIMS
              AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT
              AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
              REPRESENTATIVE PROCEEDING.
            </strong>
            Further, unless both you and Company agree otherwise, the arbitrator
            may not consolidate more than one person’s Disputes and may not
            otherwise preside over any form of a representative or class
            proceeding. If this specific provision is found to be unenforceable,
            then the entirety of this arbitration provision shall be null and
            void.
          </li>
          <li>
            <Underline>Other Terms</Underline>
            <List type="a">
              <li>
                <Underline>Severability.</Underline> If any provision of this
                Agreement is illegal or unenforceable under applicable law, the
                remainder of the provision will be amended to achieve as closely
                as possible the effect of the original term and all other
                provisions of this Agreement will continue in full force and
                effect.
              </li>
              <li>
                <Underline>Governing Law.</Underline> This Agreement is governed
                by and construed in accordance with the internal laws of the
                State of Florida without giving effect to any choice1 or
                conflict of law provision or rule. Solely to the extent Section
                19 is found not to mandate arbitration, any legal suit, action,
                or proceeding arising out of or related to this Agreement or the
                Services shall be instituted exclusively in the federal courts
                of the United States or the courts of the State of Florida in
                each case located in Broward County. You waive any and all
                objections to the exercise of jurisdiction over you by such
                courts and to venue in such courts.
              </li>
              <li>
                <Underline>Amendments.</Underline> The Company may update or
                modify the terms of this Agreement and the Privacy Policy from
                time to time and may condition your continued use of the
                Services on your acceptance of such updates or modifications.
              </li>
              <li>
                <Underline>Entire Agreement.</Underline> This Agreement and our
                Privacy Policy constitute the entire agreement between you and
                Company with respect to the Services and supersede all prior or
                contemporaneous understandings and agreements, whether written
                or oral, with respect to the Services.
              </li>
              <li>
                <Underline>Waiver.</Underline> No failure to exercise, and no
                delay in exercising, on the part of either party, any right or
                any power hereunder shall operate as a waiver thereof, nor shall
                any single or partial exercise of any right or power hereunder
                preclude further exercise of that or any other right hereunder.
                In the event of a conflict between this Agreement and any
                applicable purchase or other terms, the terms of this Agreement
                shall govern.
              </li>
            </List>
          </li>
          <li>
            <strong>Third Party Data Provider Terms.</strong>The following terms
            are required by our supplier of mapping data is Monarch Weather
            Consulting (“<strong>Monarch</strong>”).
            <List type="a">
              <li>
                To the extent applicable, the Software is subject to United
                States Export Controls. The Software may not be downloaded,
                exported, reexported, transferred, diverted, used, or accessed,
                directly or indirectly: (a) into, or to a national or resident
                of, any other country to which the United States has embargoed
                goods; (b) to anyone to the United States Treasury Department’s
                list of Specially Designated Nations or the U.S. Commerce
                Department’s Table of Deny Orders; or (c) in violation of any
                United States export laws and regulations. By downloading or
                using the Software, you represent and warrant that you are not
                located in, under the control of, or a national or resident of
                any such country or any such list. In addition to the foregoing,
                you agree to comply fully with all relevant export laws and
                regulations of the United States, including but not limited to
                the Export Administration Regulations (“EAR”), and specifically
                including prohibited end users and end uses as referenced in
                Part 44 and Supplement No. 4 to Part 44 of the EAR
              </li>
            </List>
            <br />
            (https://www.bis.doc.gov/index.php/documents/regulations-docs/2343-part-744-control-policy-end-user-and-end-use-based-2/file
            and
            https://www.bis.doc.gov/index.php/documents/regulations-docs/2347-744-supp-4-6/file);
            International Traffic in Arms Regulations (“ITAR”); and the United
            States Department of Treasury, Office of Foreign Assets Control
            (“OFAC”) regulations.
            <br />
            You also agree that we are able to share you download data with
            Monarch so that it may provide its affiliates with information about
            your export and distribution activities as required to meet any
            obligations under the United States export control laws and
            regulations
          </li>
          <li>
            <Underline>Apple App Store Minimal Terms</Underline>. With respect
            to Application acquired from the Apple App Store or operated on an
            Apple Mobile Device, the following additional terms apply:
            <List type="a">
              <li>
                <strong>Acknowledgement.</strong> Company and the End User
                acknowledge that this EULA is concluded between Company and the
                End User only, and not with Apple, and Company, not Apple, is
                solely responsible for the Application and the content thereof.
                This EULA may not provide for usage rules for the App that are
                in conflict with, the Apple Media Services Terms and Conditions
                as of the Effective Date (which Company and End User acknowledge
                have had the opportunity to review).
              </li>
              <li>
                <strong>Scope of License.</strong> The license granted to the
                End User for Application is limited to a non-transferable
                license to use the Application on any Apple-branded Products
                that the End User owns or controls and as permitted by the Usage
                Rules set forth in the Apple Media Services Terms and
                Conditions, except that such Application may be accessed and
                used by other accounts associated with the purchaser via Family
                Sharing or volume purchasing.
              </li>
              <li>
                <strong>Maintenance and Support:</strong> You must be solely
                responsible for providing any maintenance and support services
                with respect to the Services, as specified in the EULA, or as
                required under applicable law. You and the End User must
                acknowledge that Apple has no obligation whatsoever to furnish
                any maintenance and support services with respect to the
                Licensed Services.
              </li>
              <li>
                <strong>Warranty:</strong> In the event of any failure of the
                Application to conform to any applicable warranty, the End User
                may notify Apple, and Apple will refund the purchase price for
                the Licensed Services to that End User; and, to the maximum
                extent permitted by applicable law, Apple will have no other
                warranty obligation whatsoever with respect to the Application,
                and any other claims, losses, liabilities, damages, costs or
                expenses attributable to any failure to conform to any warranty
                will be Company’s sole responsibility.
              </li>
              <li>
                <strong>Product Claims.</strong> Company and the End User
                acknowledge that Company, not Apple, is responsible for
                addressing any claims of the End User or any third party
                relating to the Application or the end- user’s possession and/or
                use of that Application, including, but not limited to: (i)
                product liability claims; (ii) any claim that the Application
                fails to conform to any applicable legal or regulatory
                requirement; and (iii) claims arising under consumer protection,
                privacy, or similar legislation, including in connection with
                any by the Application (if any) of the HealthKit and HomeKit
                frameworks.
              </li>
              <li>
                <strong>Intellectual Property Rights:</strong> Company and the
                End User acknowledge that, in the event of any third party claim
                that the Application or the End User’s possession and use of
                that Application infringes that third party’s intellectual
                property rights, Company, not Apple, will be solely responsible
                for the investigation, defense, settlement and discharge of any
                such intellectual property infringement claim.
              </li>
              <li>
                <strong>Third Party Beneficiary:</strong> You and the End User
                acknowledge and agree that Apple, and Apple’s subsidiaries, are
                third party beneficiaries of the EULA, and that, upon the End
                User’s acceptance of the terms and conditions of the EULA, Apple
                will have the right (and will be deemed to have accepted the
                right) to enforce the EULA against the End User as a third party
                beneficiary thereof.
              </li>
            </List>
          </li>
        </List>
      </Content>
    </ConfirmationDialog>
  );
};
export default EulaAgreement;
