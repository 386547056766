import DocumentService from 'projects/api/DocumentService';

const GET_FILES = 'GET_FILES';
const FILES_SORT_BY = 'FILES_SORT_BY';

export const getFiles = (params) => ({
  type: GET_FILES,
  apiCall: () => DocumentService.getFiles(params),
});

export const setFilesSortBy = (sortBy) => ({
  type: FILES_SORT_BY,
  sortBy,
});

const initialState = {
  filesList: {
    items: [],
    totalCount: 0,
    totalPages: 0,
  },
  sortBy: null,
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_FILES: {
      const { items, ...restResponse } = response;

      const files = items || [];

      return {
        ...state,
        filesList: {
          ...state.filesList,
          ...restResponse,
          items: files,
        },
      };
    }
    case FILES_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }

    default:
      return state;
  }
};
