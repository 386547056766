import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const TableRoot = styled.div`
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Head = styledMui('div')(({ theme, checkable, isSubtable }) => {
  const styles = {
    position: 'relative',
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: checkable ? '11px' : '24px',
    fontFamily: 'Graphik LC',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '138.5%',
    color: theme.palette.table.header.color,
  };
  if (isSubtable) {
    styles.height = '36px';
    styles.fontSize = '11px';
    styles.borderBottom = '1px solid rgba(239, 242, 247, 0.38)';
  } else {
    styles.height = '48px';
    styles.fontSize = '13px';
    styles.background = theme.palette.table.header.background;
    styles.borderRadius = '4px';
  }
  return styles;
});

export const HeadCell = styledMui('div')(({ theme, highlighted }) => {
  const styles = {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '10px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '> div > svg': {
      marginLeft: 5,
    },
  };
  if (highlighted) {
    styles.height = '100%';
    styles.backgroundColor = theme.palette.table.highlight;
  }
  return styles;
});

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${(p) => (p.isSubtable
    ? `
    > div:not(:last-child) {
      border-bottom: 1px solid var(--tableBorder);
    }
  `
    : `
    margin-bottom: 16px;
    > div {
      border-bottom: 1px solid var(--tableBorder);
    }
  `)};
`;

export const Row = styledMui('div')(({ theme, checkable, disabled }) => {
  const styles = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    paddingRight: '12px',
    paddingLeft: checkable ? '10px' : '24px',
    cursor: 'pointer',
    fontFamily: 'Graphik LC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '138.5%',
    color: disabled ? theme.palette.text.light : theme.palette.table.row.color,
    border: '1px solid transparent',
    ':hover': {
      background: disabled ? 'transparent' : 'rgba(150, 206, 246, 0.15)',
      border: `1px solid ${disabled ? theme.palette.text.light : '#96cef6'}`,
      borderRadius: '4px',
    },
  };
  return styles;
});

export const Cell = styledMui('div')(({ theme, highlighted, inTwoLines }) => {
  const styles = {
    position: 'relative',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    ':not(:last-child)': {
      paddingRight: 30,
    },
  };
  if (highlighted) {
    styles.display = 'flex';
    styles.alignItems = 'center';
    styles.height = '100%';
    styles.backgroundColor = theme.palette.table.highlight;
  }
  if (inTwoLines) {
    styles.display = '-webkit-box';
    styles.webkitLineClamp = 2;
    styles.webkitBoxOrient = 'vertical';
  }

  return styles;
});

export const CheckableHeadCell = styled(HeadCell)`
  min-width: 36px;
  padding: 0;
  margin-right: 20px;
`;

export const CheckableCell = styled(Cell)`
  min-width: 36px;
  padding: 0;
  margin-right: 20px;
`;
