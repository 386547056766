import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'components';
import * as RatesActions from 'myCompany/store/rates.reducer';
import { ratesList, expenseRatesSortBy } from 'myCompany/store/rates.selectors';
import { getCurrencySymbol, formatCurrency } from 'utils/CurrencyUtils';

import { sortList } from '../helpers';
import RateControls from '../RateControls';
import { EXPENSE_CATEGORY } from '../constants';
import { StyledNoContent } from './styles';

const columns = [
  {
    width: '20%', key: 'category', title: 'Category',
    render: ({ category }) => (EXPENSE_CATEGORY.find((cat) => cat.value === category).label),
  },
  {
    width: '70%', key: 'costPerItem', title: 'Cost per Item',
    render: ({ costPerItem, currency }) => (`${getCurrencySymbol(currency)}${formatCurrency(costPerItem)}`),
  },
  {
    width: '10%', key: 'controls', title: '', align: 'right', noSort: true,
    minWidth: '80px',
  },
];

function renderControls({ id }) {
  return (
    <RateControls
      rateId={id}
      expense
    />
  );
}

function renderNoContent() {
  return (
    <StyledNoContent
      title="There are no expense rates yet"
      descr="Click “Add Rates” to add new rate."
      customIcon="expenses"
    />
  );
}

const defaultSortByParams = { field: 'category', direction: 'Ascending' };

const ExpenseRatesTable = ({ onRowClick, setCounter }) => {
  const dispatch = useDispatch();
  const expenseRatesData = useSelector(ratesList);
  const storedSortBy = useSelector(expenseRatesSortBy);
  const { expenseRates, isLoading } = expenseRatesData;
  const sortedExpenseRates = sortList(expenseRates, storedSortBy, defaultSortByParams);

  function handleRowClick(item) {
    if (onRowClick) {
      onRowClick({ ...item, expense: true });
    }
  }

  const handleSortBy = (newSortBy) => dispatch(RatesActions.setExpenseRatesSortBy(newSortBy));

  useEffect(() => {
    setCounter({ expenseCounter: expenseRates.length });
  }, [expenseRates]);

  return (
    <Table
      items={sortedExpenseRates}
      columns={columns}
      isLoading={isLoading}
      onSortBy={handleSortBy}
      sortBy={storedSortBy}
      onRowClick={handleRowClick}
      onNoContent={renderNoContent}
      onRenderControls={renderControls}
    />
  );
};

export default ExpenseRatesTable;
