import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { CONTACT_STATUSES_COLORS } from 'utils/constants';

import { Icon } from 'components';

export const StatusTag = styled.div`
  max-width: 120px;
  padding: 4px 12px;
  border-radius: 4px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  vertical-align: middle;

  color: ${({ status }) => CONTACT_STATUSES_COLORS[status].color};
  background-color: ${({ status }) => CONTACT_STATUSES_COLORS[status].background};
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  > :first-child {
    margin-right: 10px;
  }
`;

export const StyledIcon = styledMui(Icon)(({ theme }) => {
  return {
    'svg > path': {
      fill: theme.palette.icon.primary.color,
    },
  };
});
