import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Form } from "formik";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    width: 48%;
  }
`;

export const StyledForm = styled(Form)`
  > div {
    margin-top: 24px;
  }
`;

export const Title = styledMui("div")(({ theme }) => ({
  fontFamily: "Avenir",
  fontWeight: 900,
  lineHeight: "18px",
  color: theme.palette.text.title,
}));
