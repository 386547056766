import React from 'react';

const Xls = () => {
  return (
    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity=".9" fill="#fff" fillOpacity=".87">
        <path d="M24.67 8.933l-6.767-6.768a.879.879 0 00-.676-.29H6.62A1.934 1.934 0 004.687 3.81v23.203a1.934 1.934 0 001.934 1.934h16.406a1.934 1.934 0 001.934-1.934V9.61a.88.88 0 00-.29-.677zm-7.443-4.737L22.64 9.61h-5.414V4.196zm5.8 22.816H6.621V3.809h8.672v5.8a1.934 1.934 0 001.934 1.934h5.8v15.469z" />
        <path d="M9.216 21h.816l1.008-1.674L12.048 21h.912l-1.374-2.22 1.266-2.07h-.822l-.906 1.524-.912-1.524h-.9l1.284 2.088L9.216 21zm4.448 0h2.628v-.636h-1.83V16.71h-.798V21zm4.704.06c.948 0 1.578-.582 1.578-1.326 0-.858-.522-1.146-1.53-1.284-.744-.108-.888-.282-.888-.63 0-.33.252-.564.738-.564.492 0 .75.192.828.642h.732c-.084-.822-.636-1.242-1.56-1.242-.894 0-1.494.528-1.494 1.23 0 .804.486 1.116 1.512 1.254.696.114.894.264.894.66s-.336.66-.81.66c-.726 0-.9-.36-.954-.792h-.768c.06.81.552 1.392 1.722 1.392z" />
      </g>
    </svg>
  );
};

export default Xls;
