import { ModulePermissions } from "types";

export const globalEcosystem: ModulePermissions = {
  // ['ICCoordinator', 'ICUnderwriter', 'Broker', 'RiskManager', 'RCCoordinator', 'RiskEngineer']
  globalEcosystem: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    InsuranceRepresentative: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskManager: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    DeveloperOwner: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
};
