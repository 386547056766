import React from 'react';

const Download = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 7.5l-.705-.705-3.795 3.79V1h-1v9.585l-3.795-3.79L3 7.5l5 5 5-5z" fill="currentColor" fillOpacity=".87" />
      <path d="M13 12v2H3v-2H2v2a1 1 0 001 1h10a1 1 0 001-1v-2h-1z" fill="currentColor" fillOpacity=".87" />
    </svg>
  );
};

export default Download;
