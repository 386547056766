import styled from 'styled-components';

export const ControlsWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const PdfWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;
