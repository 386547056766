import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 34%;
  height: 95%;
  min-width: ${({ minWidth }) => minWidth || '194px'};
  padding: 8px 0 0 8px;
  overflow-y: auto;
  scrollbar-width: thin;
  ${({ noScroll }) => noScroll && 'overflow-y: unset;'}

  > div {
    ${({ minWidth }) => minWidth && 'min-width: 150px'};
  }
`;

export const NoDataWrapper = styledMui(Wrapper)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  overflowY: 'hidden',
  scrollbarWidth: 'thin',
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '160%',
  letterSpacing: 0.16,
  color: theme.palette.text.main,
  margin: '14px 0 0 10px',
  span: {
    position: 'absolute',
    textAlign: 'left',
  },
  '@media (min-width: 1920px)': {
    span: {
      fontSize: 18,
      whiteSpace: 'nowrap',
    },
  },
}));

const getColor = (index, colors) => {
  return colors[index];
};

const getColorByName = (name, colors) => {
  const color = colors.find((c) => (c.name === name));

  return color?.color;
};

export const DataName = styledMui('div')(({ theme, noScroll, colorsByName, name, colors, colorIndex }) => ({
  minWidth: 180,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 12,
  lineHeight: '14px',
  color: theme.palette.text.main,
  marginBottom: 16,
  ...(noScroll && {
    fontWeight: 'normal',
    fontSize: 10,
    color: '#0A2463',
  }),
  '@media (min-width: 1920px)': {
    fontSize: 14,
    ...(noScroll && { fontSize: 10 }),
  },
  '&::before': {
    content: '""',
    display: 'inline-block',
    width: 15,
    height: 4,
    marginRight: 8,
    background: colorsByName ? getColorByName(name, colorsByName) : getColor(colorIndex, colors),
    borderRadius: 2,
  },
}));

export const PercentText = styled.div`
  display: inline-block;
  margin-left: 4px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #0A2463;
  opacity: 0.5;
`;
