import React from 'react';

import AuthService from 'services/AuthService';
import { getNextPageInfo } from 'help/constants';

import RedirectButton from 'help/components/RedirectButton';
import RecommendationsSection from 'help/pages/Projects/components/RecommendationsSection';
import ClaimsSection from 'help/pages/Projects/components/ClaimsSection';
import AnalyticsSection from 'help/pages/Projects/components/AnalyticsSection';

const RecommendationsAndClaimsAndAnalytics = () => {
  const { role } = AuthService.getUser();

  const { title, path, isCurrentPageLast } = getNextPageInfo(role, 'recommendations-claims-analytics');

  return (
    <>
      <RecommendationsSection />
      <ClaimsSection />
      <AnalyticsSection />
      {!isCurrentPageLast && <RedirectButton redirectTitle={title} redirectPath={path} />}
    </>
  );
};

export default RecommendationsAndClaimsAndAnalytics;
