import ApiClient from 'services/ApiClient';

class DesignationService extends ApiClient {
  getDesignations() {
    return this.call({
      method: 'get',
      url: '/users/designations',
    });
  }

  addDesignation(designationName) {
    return this.call({
      method: 'post',
      url: '/designations',
      data: {
        value: designationName,
      },
    });
  }

  updateDesignation(id, designationName) {
    return this.call({
      method: 'post',
      url: '/rates/expense',
      data: {
        id,
        value: designationName,
      },
    });
  }
}

export default new DesignationService();
