export function getSummary({ globalEcosystem }) {
  return globalEcosystem.summary;
}

export function getFilters({ globalEcosystem }) {
  return globalEcosystem.filters;
}

export function getCharts({ globalEcosystem }) {
  return globalEcosystem.charts;
}
