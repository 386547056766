import React from 'react';

const Accepted = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fill="currentColor" fillRule="evenodd" d="M8 0C3.644 0 0 3.644 0 8s3.644 8 8 8 8-3.556 8-8-3.556-8-8-8zM6.844 11.111L4 8.267l.889-.89 1.955 1.956 4.267-4.266.889.889-5.156 5.155z" clipRule="evenodd" />
    <path fill="currentColor" fillRule="evenodd" d="M6.844 11.111L4 8.267l.889-.889 1.955 1.956 4.267-4.267.889.889-5.156 5.155z" clipRule="evenodd" opacity=".01" />
  </svg>
);

export default Accepted;
