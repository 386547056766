import styled from 'styled-components';
import { SUBSCRIPTION_STATUSES, CONTACT_STATUSES_COLORS } from 'utils/constants';

export const StatusTag = styled.div`
  max-width: 120px;
  padding: 4px 12px;
  border-radius: 4px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  vertical-align: middle;
  color: ${({ status }) => SUBSCRIPTION_STATUSES[status].color};
  background-color: ${({ status }) => SUBSCRIPTION_STATUSES[status].background};
`;

export const InviteStatusTag = styled.div`
  max-width: 120px;
  padding: 4px 12px;
  border-radius: 4px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  vertical-align: middle;

  color: ${({ status }) => CONTACT_STATUSES_COLORS[status].color};
  background-color: ${({ status }) => CONTACT_STATUSES_COLORS[status].background};
`;
