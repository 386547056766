import React, { useState, useEffect } from 'react';

import AuthService from 'services/AuthService';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';

const SESSION_TIMEOUT_SEC = 60;
const REFRESH_INTERVAL_MS = 1000;

const ExpiredSessionWarning = ({ closeModal }) => {
  const [timer, setTimer] = useState(SESSION_TIMEOUT_SEC);

  const updateTimer = () => setTimer((prevState) => prevState - 1);

  useEffect(() => {
    const interval = setInterval(updateTimer, REFRESH_INTERVAL_MS);

    return () => clearInterval(interval);
  }, []);

  if (timer === 0) {
    closeModal();
    AuthService.signOut();
  }

  return (
    <ConfirmationDialog
      open
      noCancel
      title="Session Timeout"
      confirmButtonTitle="Stay Signed In"
      onClose={closeModal}
      onCancel={closeModal}
      onConfirm={closeModal}
    >
      Your session is about to expire.
      You will be automatically signed out in<br />
      <b>{` ${timer} `}</b>
      seconds.
      If you want to continue your session, please select Stay Signed In.
    </ConfirmationDialog>
  );
};

export default ExpiredSessionWarning;
