import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';

import { HighlightedText } from './styles';

const FeeProposalSubmitted = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: { projectId, companyName, projectName } } = notify;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToFeeProposal = () => {
    handleClose();
    closeNotificationList();

    const { companyContactId, feeProposalId } = notify.data;
    navigate(`/projects/${projectId}/feeProposal?feeProposalId=${feeProposalId}&companyContactId=${companyContactId}&companyName=${notify.data.companyName}`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="Fee Proposal Submitted"
      cancelButtonTitle="Close"
      confirmButtonTitle="View Proposal"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToFeeProposal}
    >
      Fee proposal has been submitted by
      {' '}
      <HighlightedText>{companyName}</HighlightedText>
      {' '}
      for
      {' '}
      <HighlightedText>{projectName}</HighlightedText>
      {' '}
      project.
    </ConfirmationDialog>
  );
};

export default FeeProposalSubmitted;
