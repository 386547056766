import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import Icon from 'components/Icon';


export const ChartBlock = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  minWidth: '770px',
  height: '288px',
  marginLeft: '25px',
  padding: '0 16px',
  background: theme.palette.background.default,
  borderRadius: '6px',
}
));

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  margin: '2px -5px 0 3px',
  color: theme.palette.icon.primary,
}));

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  padding: 18px 0 12px 0;
  background-color: transparent;

  > div:last-child {
    height: 84%;
    margin: 44px 0 0 10px;
  }
`;

export const TypeTitle = styledMui('div')(({ theme }) => ({
  position: 'absolute',
  minWidth: '250px',
  margin: '0 0 0 16px',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '18px',
  lineHeight: '25px',
  color: theme.palette.text.title,
}
));

export const ChartWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
  width: 40%;
  min-width: 220px;
  height: 100%;

  & .recharts-wrapper {
    height: 10px;
  }

  @media (min-width: 1920px) {
    width: 43%;
  }
`;

export const ChartMenu = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '30%',
  minWidth: '270px',
  height: '100%',
  padding: '16px',
  borderLeft: '1px solid '.concat(theme.palette.navbar.tab.border),
  '@media (min-width: 1500px)': {
    marginLeft: 'auto',
  },
  '@media (min-width: 1920px)': {
    width: '410px',
  },
}));

export const MenuItem = styledMui('div')(({ theme, isSelected }) => ({
  borderRadius: '4px',
  background: 'transparent',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
  color: theme.palette.text.light,
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.sidebar.selected.backgroundColor,
    color: theme.palette.text.main,
  },
  ...(isSelected && {
    background: theme.palette.sidebar.selected.backgroundColor,
    color: theme.palette.text.main,
  })
}));

export const ButtonBack = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'absolute',
  width: 'auto',
  padding: '0 10px 0 0',
  cursor: 'pointer',
  borderRadius: '6px',
  boxSizing: 'border-box',
  transition: 'border 0.14s linear',
  border: '1px solid transparent',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '18px',
  lineHeight: '27px',
  color: theme.palette.text.main,
  textIndent: '16px',
  div: {
    whiteSpace: 'nowrap',
  },
  '& svg': {
    width: '16px',
    height: '100%',
    transform: 'rotate(180deg)',
    paddingTop: '2px',
  },
  '&:focus': {
    border: '1px solid '.concat(theme.palette.background.workspace),
  },
  '&:hover': {
    border: '1px solid #96CEF6',
    path: {
      fill: '#96CEF6',
    },
  },
}));
