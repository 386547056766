import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { Button } from 'components';
import { VIDEO_FORMATS, PROJECT_CLOSED } from 'utils/constants';

import { upload, uploadLayouts, rejectFileFormatMessage } from './helpers';
import { UploadButtonContainer, StyledTooltip } from './styles';

const MAX_FILE_SIZE = 500 * 1000 * 1000 * 1000; // 500 GB
const FILE_FORMATS = 'image/jpeg,image/png,image/gif,image/bmp,.pdf,.xlsx,.doc,.docx';

const FileUploadButton = ({ onFileSelect, setCurrentUpload, refreshFiles, isLayoutsFolder, folder, disabled }) => {
  let count = 0;
  let maxCount = 1;
  const loaded = [];

  const onDropLayouts = async (acceptedFiles) => {
    maxCount = acceptedFiles.length;
    await uploadLayouts({ files: acceptedFiles, count, maxCount, onFileSelect, setCurrentUpload, refreshFiles });
  };

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 1 && maxCount === 1) {
      maxCount = acceptedFiles.length;
    }
    count += 1;
    const last = acceptedFiles.length === 1 ? loaded : null;
    const file = acceptedFiles[0];
    if (file) {
      await upload({ file, last, count, maxCount, loaded, folder, setCurrentUpload, onFileSelect, refreshFiles });
    }
    const restFiles = acceptedFiles.slice(1);
    if (restFiles.length) {
      onDrop(restFiles);
    } else {
      maxCount = 0;
    }
  };

  const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
    onDrop: isLayoutsFolder ? onDropLayouts : onDrop,
    accept: isLayoutsFolder ? '.pdf' : FILE_FORMATS.concat(`,${VIDEO_FORMATS}`),
    maxSize: MAX_FILE_SIZE,
    multiple: true,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: false,
  });

  useEffect(() => {
    if (!fileRejections.length) return;
    fileRejections.forEach(({ errors }) => {
      if (errors && errors.find((er) => er.code === 'file-invalid-type')) {
        rejectFileFormatMessage(isLayoutsFolder);
      }
    });
  }, [fileRejections]);

  return (
    <div className="container">
      <UploadButtonContainer {...getRootProps({ className: 'dropzone' })}>
        {isLayoutsFolder && (
          <StyledTooltip
            isWarning
            placement="top"
            text="Please use PDF files for the layouts, the other formats are not supported. This is recommended to upload one-page documents for better user experience."
          />
        )}
        <input {...getInputProps()} />
        <Button
          small
          type="button"
          label="upload"
          color="primary"
          variant="contained"
          text="Upload File"
          onClick={open}
          disabled={disabled}
          disabledTooltip={disabled && PROJECT_CLOSED}
        />
      </UploadButtonContainer>
    </div>
  );
};

export default FileUploadButton;
