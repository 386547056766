import DashboardService from 'dashboard/api/DashboardService';

const DASHBOARD_GET_SUMMARY = 'DASHBOARD_GET_SUMMARY';
const DASHBOARD_GET_CHARTS = 'DASHBOARD_GET_CHARTS';

export const getDashboardSummary = () => ({
  type: DASHBOARD_GET_SUMMARY,
  apiCall: () => DashboardService.getDashboardSummary(),
});

export const getDashboardCharts = () => ({
  type: DASHBOARD_GET_CHARTS,
  apiCall: () => DashboardService.getDashboardCharts(),
});

const initialState = {
  summary: {},
  charts: { isLoading: true },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case DASHBOARD_GET_SUMMARY: {
      return {
        ...state,
        summary: {
          ...state.summary,
          ...response,
        },
      };
    }
    case DASHBOARD_GET_CHARTS: {
      return {
        ...state,
        charts: {
          ...state.charts,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
