import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";

import {
  InputLabel,
  FilledInput,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

export const StyledInput = styledMui(FilledInput)(
  ({ theme, multiline, readOnly, scroll }) => ({
    backgroundColor: theme.palette.input.color,
    borderRadius: 4,
    border: `1px solid ${theme.palette.input.border.color}`,
    color: theme.palette.input.text,
    ...(readOnly && {
      border: `1px solid ${theme.palette.input.readonly.border}`,
      backgroundColor: "transparent",
    }),
    ...(readOnly &&
      !scroll && {
        pointerEvents: "none",
      }),
    ":hover": {
      backgroundColor: theme.palette.input.hover.color,
    },
    ...(multiline && { height: 120 }),
    "&.Mui-error": {
      border: `1px solid ${theme.palette.error.main}`,
    },
    "&.Mui-focused": {
      border: `1px solid ${theme.palette.input.focused.border}`,
      backgroundColor: theme.palette.input.color,
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.input.disabled.color,
      color: theme.palette.input.disabled.text,
    },
    "&&.MuiFilledInput-multiline": {
      alignItems: "flex-start",
      padding: "24px 16px 6px",
      textarea: {
        overflowX: "hidden",
        overflowY: scroll ? "scroll" : "hidden",
        scrollbarWidth: "thin",
      },
    },
    ".MuiFilledInput-input::-webkit-outer-spin-button, .MuiFilledInput-input::-webkit-inner-spin-button":
      {
        WebkitAppearance: "none",
        margin: 0,
      },
  })
);

export const StyledInputLabel = styledMui(InputLabel)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    fontSize: 15,
    color: theme.palette.text.main,
    "&.Mui-focused": {
      color: theme.palette.text.main,
    },
    "> div": {
      marginLeft: 5,
    },
    "&.Mui-disabled": {
      color: theme.palette.text.main,
    },
  };
});

export const StyledFormHelperText = styledMui(FormHelperText)(
  ({ multiline, errorExpand }) => ({
    ...(multiline && {
      top: "unset",
      bottom: -20,
    }),
    ...(errorExpand && {
      position: "relative",
      top: "unset",
      height: 18,
    }),
  })
);

export const EndIcon = styled(InputAdornment)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  pointer-events: none;
`;

export const CountButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
`;

export const Link = styledMui("a")(({ theme }) => ({
  color: theme.palette.sidebar.selected.icon.background,
  input: {
    color: theme.palette.sidebar.selected.icon.background,
  },
}));
