import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';

import AuthService, { ROLES_IDS, ROLES } from 'services/AuthService';
import AccountService from 'modules/account/api/AccountService';

import { UserMenuRoot, StyledIcon, IconWrapper, StyledMenu, UserName, UserEmail, UserInfo, StyledIconButton } from './styles';

const USER_NAME = 'name';
const USER_ROLE = 'role';

const UserMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { role } = AuthService.getUser();

  const [userInfo, setUserInfo] = useState({ name: '', email: '' });

  const [anchorEl, setAnchorEl] = useState(null);
  const isAdmin = role === ROLES_IDS.Admin;
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SignOut = async () => {
    await AccountService.revokeToken();
    AuthService.resetToken();
    handleClose();
    window.location.replace('/account/signin');
  };

  const handleAccountClick = async () => {
    handleClose();
    navigate('/my-account');
  };

  useEffect(() => {
    setUserInfo({
      name: localStorage.getItem(USER_NAME),
      role: localStorage.getItem(USER_ROLE),
    });
  }, []);

  return (
    <UserMenuRoot>
      <UserInfo>
        <UserName>
          {isAdmin ? 'Administrator' : userInfo.name}
        </UserName>
        <UserEmail>
          {ROLES[userInfo.role]}
        </UserEmail>
      </UserInfo>
      <StyledIconButton
        icon="threeDots"
        onClick={handleClick}
        transparent
      />
      <StyledMenu
        className={cx(theme.light && 'light-theme')}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -17,
          horizontal: 'right',
        }}
      >
        <MenuItem
          className={cx(theme.light && 'light-theme')}
          onClick={handleAccountClick}
        >
          <IconWrapper>
            <StyledIcon icon="account" noWrapper />
          </IconWrapper>
          Account
        </MenuItem>
        <MenuItem
          className={cx(theme.light && 'light-theme')}
          onClick={SignOut}
        >
          <IconWrapper>
            <StyledIcon icon="logOut" noWrapper />
          </IconWrapper>
          Sign Out
        </MenuItem>
      </StyledMenu>
    </UserMenuRoot>
  );
};

export default UserMenu;
