import React, { useState } from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';

import { Menu, MenuItem, IconButton } from 'components';
import CompanyService from 'common/api/CompanyService';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';
import { showNotifyError, showNotifySuccess } from 'services/toaster';

import DeleteICSubscriptionModal from '../../DeleteICSubscriptionModal';

import { ControlsWrapper } from './styles';

const stopPropagation = (event) => event.stopPropagation();

const SubscritionsControls = ({ company, onSuccess, viewSubscription }) => {
  const [deleteDialogState, setDeleteDialogState] = useState(null);
  const [resendDialogState, setResendDialogState] = useState(false);

  const { id, name, icInviteStatus, status } = company;

  const closeDeleteDialog = () => setDeleteDialogState(null);

  const handleContactDeleteClick = () => setDeleteDialogState(true);

  const handleEmailResendClick = () => setResendDialogState(true);

  const closeResendDialog = () => setResendDialogState(false);

  const handleResendConfirm = async () => {
    try {
      await CompanyService.resendInsuranceCompanyInvite(id);
      closeResendDialog();
      showNotifySuccess(`The invitation email was successfully sent to ${name}.`);
    } catch (error) {
      showNotifyError(error);
    }
  };

  return (
    <ControlsWrapper onClick={stopPropagation}>
      {icInviteStatus === 'Sent' && status === 'Active' && (
        <MuiTooltip arrow title="Resend Invite">
          <IconButton
            transparent
            icon="reset"
            onClick={handleEmailResendClick}
          />
        </MuiTooltip>
      )}
      <Menu>
        <MenuItem icon="edit" text="View" onClick={() => viewSubscription(id, icInviteStatus)} />
        <MenuItem icon="deleteSmall" text="Delete" onClick={handleContactDeleteClick} />
      </Menu>

      {deleteDialogState && (
        <DeleteICSubscriptionModal
          onSuccess={onSuccess}
          onCancel={closeDeleteDialog}
          company={company}
        />
      )}
      {resendDialogState && (
        <ConfirmationDialog
          open
          title="Resend Invite"
          confirmButtonTitle="Send Email"
          onClose={closeResendDialog}
          onCancel={closeResendDialog}
          onConfirm={handleResendConfirm}
        >
          <ConfirmationText
            value={name}
            text="Do you want to send another invitation email to {placeholder}?"
          />
        </ConfirmationDialog>
      )}
    </ControlsWrapper>
  );
};

export default SubscritionsControls;
