import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  newNotificationsList, allNotificationsList, notificationsView, getOpenedNotification,
} from 'notifications/store/notification.selectors';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import NotificationsList from 'notifications/components/NotificationsList';

const NotificationsDashboard = () => {
  const dispatch = useDispatch();

  const allNotifications = useSelector(allNotificationsList);
  const newNotifications = useSelector(newNotificationsList);
  const currentView = useSelector(notificationsView);
  const openedNotification = useSelector(getOpenedNotification);

  if (!currentView && !openedNotification) {
    return null;
  }

  const handleOpenNotification = (notify) => {
    dispatch(NotificationsActions.setOpenedNotification(notify));
  };

  const handleClose = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const handleSetViewNew = () => {
    if (currentView !== 'new') {
      dispatch(NotificationsActions.setNotificationsView('new'));
    }
  };

  const handleSetViewAll = () => {
    if (currentView !== 'all') {
      dispatch(NotificationsActions.setNotificationsView('all'));
    }
  };

  return (
    <NotificationsList
      onSetViewAll={handleSetViewAll}
      onSetViewNew={handleSetViewNew}
      onClose={handleClose}
      allNotifications={allNotifications}
      newNotifications={newNotifications}
      currentView={currentView}
      openedNotification={openedNotification}
      onOpenNotification={handleOpenNotification}
    />
  );
};

export default NotificationsDashboard;
