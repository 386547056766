import React from 'react';

const TimeAndExpenses = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.31 11.449a1.131 1.131 0 01-.804-1.929 1.164 1.164 0 011.601 0c.215.213.335.502.334.804a.964.964 0 001.929 0 3.021 3.021 0 00-2.14-2.906l-.04-.642a.964.964 0 10-1.928 0v.7a3.053 3.053 0 001.041 5.927 1.363 1.363 0 11-1.356 1.228.97.97 0 00-1.928-.193 3.253 3.253 0 002.23 3.44v1.111a.964.964 0 101.929 0v-1.067a3.252 3.252 0 001.446-.829 3.291 3.291 0 00-2.327-5.625l.013-.02zM22.12 6.73c.557-.61.871-1.403.88-2.23C23 1.89 20.023 0 15.929 0c-4.095 0-7.072 1.89-7.072 4.5.002.834.3 1.64.843 2.276A3.426 3.426 0 008.857 9c.002.834.3 1.64.843 2.275a3.426 3.426 0 00-.843 2.225c.002.834.3 1.64.843 2.275A3.426 3.426 0 008.857 18c0 2.61 2.816 4.5 6.91 4.5C19.864 22.5 23 20.61 23 18a3.439 3.439 0 00-.88-2.27c.557-.61.871-1.404.88-2.23a3.439 3.439 0 00-.88-2.27c.557-.61.871-1.404.88-2.23a3.439 3.439 0 00-.88-2.27zM21.07 13.5c0 1.42-2.462 2.571-5.303 2.571-2.841 0-4.982-1.15-4.982-2.571a1.55 1.55 0 01.424-1.016 9.59 9.59 0 004.558 1.016 10.24 10.24 0 004.815-1.087c.297.286.472.675.488 1.087zM15.768 9a10.24 10.24 0 004.815-1.087c.297.286.472.675.488 1.087 0 1.42-2.462 2.571-5.303 2.571-2.841 0-4.982-1.15-4.982-2.571.012-.38.163-.74.424-1.016A9.591 9.591 0 0015.768 9zm.16-7.071c2.842 0 5.143 1.15 5.143 2.57 0 1.422-2.462 2.572-5.303 2.572-2.841 0-4.982-1.15-4.982-2.571 0-1.42 2.301-2.571 5.143-2.571zm-.16 18.642c-2.841 0-4.982-1.15-4.982-2.572.012-.379.163-.74.424-1.015a9.59 9.59 0 004.558 1.015c1.67.036 3.322-.337 4.815-1.086.297.285.472.675.488 1.087 0 1.42-2.462 2.57-5.303 2.57z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default TimeAndExpenses;
