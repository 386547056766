import React from 'react';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import Icon from 'components/Icon';
import Button from 'components/buttons/Button';

/*
  Fix for Warning: Received `false` for a non-boolean attribute `opened`.
  If you want to write it to the DOM, pass a string instead: opened="false" or opened={value.toString()}.
*/

export const StyledButton = styledMui(Button)(({ opened, lighter, theme }) => ({
  width: 127,
  height: 40,
  backgroundColor: `${theme.palette.background.default} !important`,
  border: `1px solid ${theme.palette.input.search.border.color}`,
  '&:hover': {
    background: theme.palette.sidebar.selected.background,
  },
  ...(lighter && {
    background: theme.palette.input.color,
  }),
  ...(opened && {
    border: `1px solid ${theme.palette.sidebar.selected.backgroundColor}`,
  }),
}));

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  'svg path': {
    fill: theme.palette.sidebar.selected.icon.background,
  },
}));

export const ButtonText = styledMui('div')(({ theme }) => ({
  margin: '0 10px',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '14px',
  lineHeight: '14px',
  color: theme.palette.text.main,
}));
