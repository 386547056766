import React, { useState, useEffect } from 'react';

import SubjectMenu from 'help/components/SubjectMenu';

import { Root } from '../styles';

import CommunicationService from '../../../communication/api/CommunicationService';
import { Description, Line, PreviewImage, SectionTitle } from './styles';

const Communication = () => {
  const [communication, setCommunication] = useState();
  const [communications, setCommunications] = useState([]);
  const [sections, setSections] = useState({});
  const [selectedSection, setSelectedSection] = useState('');

  const loadCommunications = async () => {
    const res = await CommunicationService.getHelpCommunication();
    setCommunications(res.data.data ?? []);
  };

  useEffect(() => {
    loadCommunications();
  }, []);

  useEffect(() => {
    if (communications == null) return;
    const subj = () => communications.reduce((acc, cur) => ({ ...acc, [cur.title]: {
      title: cur.title,
      path: cur.id,
    } }), {});
    setSections(subj);
    setSelectedSection(communications[0]?.title);
  }, [communications]);

  useEffect(() => {
    if (selectedSection == null) return;
    const sect = communications.find((t) => t.title === selectedSection);
    setCommunication(sect);
  }, [selectedSection]);

  return (
    <>
      <SubjectMenu
        section="account"
        subjects={sections}
        onChangeSubject={setSelectedSection}
        noRedirect
    />
      <Root>
        {communication && communication.sections && communication.sections.map((section) => (
          <>
            <SectionTitle>
              <Line />
              <div>
                {section.title}
              </div>
              <Line />
            </SectionTitle>
            <PreviewImage src={section && section.fileInfo && section.fileInfo.url} alt="" />
            <Description>
              {section.description}
            </Description>
          </>
        ))}
      </Root>
    </>
  );
};

export default Communication;
