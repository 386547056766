import React from 'react';
import cx from 'classnames';
import { useTheme } from '@mui/material/styles';

import AlertsDashboard from 'notifications/components/AlertsDashboard';
import NotificationsDashboard from 'notifications/components/NotificationsDashboard';
import MinimizedNotification from 'notifications/components/MinimizedNotification';

import Sidebar from './Sidebar';
import Header from './Header';

import { Main, Wrapper, Workspace, Content } from './styles';

const MainLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <Main className={cx(theme.light && 'light-theme')}>
      <Wrapper>
        <Sidebar />

        <Workspace>
          <Header />
          <Content>
            {children}
          </Content>
        </Workspace>
      </Wrapper>

      <NotificationsDashboard />
      <AlertsDashboard />
      <MinimizedNotification />
    </Main>
  );
};

export default MainLayout;
