import styled from 'styled-components';
import { Title } from 'common/components/styles';

export const RatesRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderPanel = styled.div`
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubTitle = styled(Title)`
  margin-bottom: 16px;
`;

export const Controls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;

  > div:last-of-type {
    margin-left: 8px;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
`;

export const Counter = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 138.5%;
  width: 100%;
  display: inline-block;
  text-align: right;
  color: var(--textColor);
  opacity: 0.75;
`;
