import BudgetsService from 'dashboard/api/BudgetsService';

const DASHBOARD_GET_BUDGETS_LIST = 'DASHBOARD_GET_BUDGETS_LIST';
const DASHBOARD_BUDGETS_LIST_SORT_BY = 'DASHBOARD_BUDGETS_LIST_SORT_BY';

export const getDashboardBudgets = (params) => ({
  type: DASHBOARD_GET_BUDGETS_LIST,
  apiCall: () => BudgetsService.getDashboardBudgets(params),
});

export const setBudgetsSortBy = (sortBy) => ({
  type: DASHBOARD_BUDGETS_LIST_SORT_BY,
  sortBy,
});

const initialState = {
  budgets: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case DASHBOARD_GET_BUDGETS_LIST: {
      return {
        ...state,
        budgets: {
          ...state.budgets,
          ...response,
        },
      };
    }
    case DASHBOARD_BUDGETS_LIST_SORT_BY: {
      return {
        ...state,
        budgets: {
          ...state.budgets,
          sortBy: action.sortBy,
        },
      };
    }

    default:
      return state;
  }
};
