import DashboardReportsService from 'dashboard/api/ReportsService';

const DASHBOARD_GET_REPORTS_LIST = 'DASHBOARD_GET_REPORTS_LIST';
const DASHBOARD_REPORTS_LIST_SORT_BY = 'DASHBOARD_REPORTS_LIST_SORT_BY';

export const getDashboardReports = (params) => ({
  type: DASHBOARD_GET_REPORTS_LIST,
  apiCall: () => DashboardReportsService.getDashboardReports(params),
});

export const setReportsSortBy = (sortBy) => ({
  type: DASHBOARD_REPORTS_LIST_SORT_BY,
  sortBy,
});

const initialState = {
  reports: {},
};

export default (state = initialState, action = {}) => {
  const { response } = action;

  switch (action.type) {
    case DASHBOARD_GET_REPORTS_LIST: {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...response,
        },
      };
    }
    case DASHBOARD_REPORTS_LIST_SORT_BY: {
      return {
        ...state,
        reports: {
          ...state.reports,
          sortBy: action.sortBy,
        },
      };
    }

    default:
      return state;
  }
};
