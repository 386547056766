import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';

import { Icon, Tooltip } from 'components';

export const FilesListRoot = styled.div`
  position: relative;
  width: 65%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: var(--textColor);
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ noUpload }) => !noUpload && `
    padding-right: 14px;
    margin: 0 24px;
    border-right: 1px solid var(--lightBorderColor);
  `}
`;

export const StyledIcon = styled(Icon)`
  color: var(--textColor);
`;

export const StyledTooltip = styled(Tooltip)`
  margin-left: -7px;
  margin-right: 17px;
`;

export const StyledLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-determinate {
    border-radius: 4px;
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: #73778C;
  }
  & .MuiLinearProgress-barColorPrimary {
    border-radius: 4px;
    background-color: #88EEAC;
  }
`;

export const TypeIconWrapper = styled.div`
  margin-left: -10px;
  margin-right: 8px;
  svg path {
    fill: var(--navTabFill);
  }
`;
