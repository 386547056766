import styled from 'styled-components';
import { Dialog } from '@mui/material';

import { Icon } from 'components';

export const AttachmentRoot = styled.div`
  display: flex;
  cursor: pointer;
`;

export const NameWithIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
  :hover * {
    text-decoration: underline;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background-color: rgba(29, 30, 36, 0.5);
    padding-top: 30px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  overflow: hidden;

  & > * {
    max-height: 100vh;
    max-width: 100vw;
  }
`;

export const PreviewWrapper = styled.div`
  display: flex;
`;

export const UnderscoredButton = styled.div`
  display: flex;
  margin-top: 2px;
  line-height: 12px;
  text-decoration: none;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: 380px;
`;

export const PaperClip = styled(Icon)`
color: var(--textColor);
  path {
    fill: var(--textColor);
  }
`;

export const StyledImage = styled.img`
  position: relative;
  z-index: 2;
`;

export const InvisibleContainer = styled.div`
  position: fixed;
  visibility: hidden;
  width: 100%;
  height: 100%;
`;
