import React, { useState } from 'react';
import { PDFObject } from 'react-pdfobject';
import { useSelector } from 'react-redux';
import { Tooltip as MuiTooltip } from '@mui/material';

import LibraryService from 'library/api/LibraryService';
import { showNotifySuccess, showNotifyError } from 'services/toaster';
import { ActionButton, IconButton, Spinner } from 'components';
import ConfirmationText from 'components/Dialogs/ConfirmationText';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import { userInfo } from 'projects/store/users.selectors';
import { isProjectClosed } from 'projects/store/projects.selectors';
import useProgress from 'utils/hooks/useProgress';
import { PROJECT_CLOSED } from 'utils/constants';

import { ControlsWrapper, PdfWrapper } from './styles';

const containerProps = { style: { width: '100%' } };
const pdfOpenParams = { view: 'FitV' };

const stopPropagation = (event) => {
  event.stopPropagation();
};

const FileControls = ({ file, onSuccess, permissions, isLayoutsFolder, isRiskReviewFolder, markAsOpened, canEditName, openEditName, isFolderOwner }) => {
  const user = useSelector(userInfo);
  const isClosed = useSelector(isProjectClosed);
  const [opened, setOpened] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const {
    inProgress,
    setInProgress,
    setCurrentProgressInfo,
    handleDownloadProgress,
    renderProgress,
    clearProgress,
  } = useProgress(isLayoutsFolder, isRiskReviewFolder);

  const ownerRightsCheck = permissions.ownerRightsCheck || permissions.layout.ownerRightsCheck;
  let isOwner = true;
  if (ownerRightsCheck) {
    isOwner = file.creatorId === user.id;
  }
  const layoutDeletePermission = isLayoutsFolder && permissions.layout.delete;
  const risksDeletePermisiion = isRiskReviewFolder && permissions.risks.delete;
  const defaultDeletePermission = !isLayoutsFolder && !isRiskReviewFolder && permissions.delete;
  const deletePermissions = isFolderOwner || defaultDeletePermission || layoutDeletePermission || risksDeletePermisiion;
  const disableDelete = !deletePermissions || !isOwner;
  const disableEditName = !canEditName || !isOwner;

  const deleteConfirmationText = (
    <ConfirmationText
      value={file.name}
      text="Are you sure you want to delete {placeholder} file? It will be deleted immediately, you can&apos;t undo this action."
        />
  );

  const revokeURL = (url) => {
    if (url) {
      setPdfUrl(null);
      URL.revokeObjectURL(url);
    }
  };

  const handleCloseDialog = () => {
    setOpened(false);
    revokeURL(pdfUrl);
  };

  async function handleDownload() {
    try {
      setInProgress(true);
      setCurrentProgressInfo({ name: file.name, size: file.size });
      await LibraryService.downloadFile(file.downloadUrl, file.name, false, handleDownloadProgress);
      await markAsOpened(file);
    } catch (error) {
      showNotifyError(error);
    } finally {
      clearProgress();
    }
  }

  async function handleDelete() {
    try {
      await LibraryService.deleteFiles([file.id]);
      showNotifySuccess(`${file.name} file was successfully deleted.`);
      onSuccess();
    } catch (error) {
      showNotifyError(error);
    }
  }

  return (
    <>
      <ControlsWrapper onClick={stopPropagation}>
        {canEditName && (
        <MuiTooltip arrow title="Edit Name">
          <span>
            <IconButton
              icon="edit"
              onClick={() => openEditName(file.id, file.name)}
              transparent
              color="primary"
              disabled={disableEditName}
                            />
          </span>
        </MuiTooltip>
        )}
        <IconButton
          icon="download"
          onClick={handleDownload}
          transparent
          inProgress={inProgress}
          disabled={inProgress}
                />
        <ActionButton
          isIcon
          icon="deleteSmall"
          text="Delete File"
          dialogTitle="Delete File"
          dialogContent={deleteConfirmationText}
          onConfirm={handleDelete}
          confirmButtonTitle="Delete"
          disabled={disableDelete || isClosed}
          disabledToolTip={isClosed && PROJECT_CLOSED}
                />
      </ControlsWrapper>
      {opened && (
        <ConfirmationDialog
          open
          title="PDF Preview"
          cancelButtonTitle="Close"
          maxWidth="xl"
          onCancel={handleCloseDialog}
          onClose={handleCloseDialog}
          noConfirm
                >
          <PdfWrapper>
            {pdfUrl ? (
              <PDFObject
                url={pdfUrl}
                pdfOpenParams={pdfOpenParams}
                containerProps={containerProps}
                            />
            ) : (
              <Spinner />
            )}
          </PdfWrapper>
        </ConfirmationDialog>
      )}

      {renderProgress()}
    </>
  );
};

export default FileControls;
