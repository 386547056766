import qs from 'qs';
import ApiClient from 'services/ApiClient';

class InspectionsService extends ApiClient {
  getInspectionsList({ projectId }) {
    return this.call({
      method: 'get',
      url: `/inspections?${qs.stringify({ projectId })}`,
    });
  }

  getInspectionById({ id }) {
    return this.call({
      method: 'get',
      url: `/inspections/${id}`,
    });
  }

  addNewInspection(inspectionData) {
    return this.call({
      method: 'post',
      url: '/inspections',
      data: {
        ...inspectionData,
      },
    });
  }

  updateInspection(inspectionData) {
    return this.call({
      method: 'put',
      url: '/inspections',
      data: {
        ...inspectionData,
      },
    });
  }

  deleteInspection(id) {
    return this.call({
      method: 'delete',
      url: `/inspections/${id}`,
    });
  }

  getInspectionsByInterval({ projectId, startDateTime, endDateTime }) {
    const query = {
      projectId,
      startDateTime,
      endDateTime,
    };

    return this.call({
      method: 'get',
      url: `/inspections/by-interval?${qs.stringify(query)}`,
    });
  }

  getInspectionsWithRisks({ projectId }) {
    return this.call({
      method: 'get',
      url: `/inspections/risks?${qs.stringify({ projectId })}`,
    });
  }

  getInspectionsWithAllRisks({ projectId }) {
    return this.call({
      method: 'get',
      url: `/inspections/all-risks?${qs.stringify({ projectId })}`,
    });
  }

  getInspectionsShortList({ projectId }) {
    return this.call({
      method: 'get',
      url: `/inspections/dropdown?${qs.stringify({ projectId })}`,
    });
  }

  sendInspectionForApproval(id) {
    return this.call({
      method: 'put',
      url: `/inspections/${id}/send-for-approval`,
    });
  }

  sendInspectionToRE(id) {
    return this.call({
      method: 'put',
      url: `/inspections/${id}/send-to-risk-engineer`,
    });
  }

  issueInspection(id) {
    return this.call({
      method: 'put',
      url: `/inspections/${id}/issue`,
    });
  }

  completeInspection(id) {
    return this.call({
      method: 'put',
      url: `/inspections/${id}/complete`,
    });
  }

  cancelInspection(values) {
    return this.call({
      method: 'put',
      url: '/inspections/cancel',
      data: values,
    });
  }

  getRisk(id) {
    return this.call({
      method: 'get',
      url: `/risks/${id}`,
    });
  }

  addRisk(riskData) {
    return this.call({
      method: 'post',
      url: '/risks',
      data: {
        ...riskData,
      },
    });
  }

  updateRisk(riskData) {
    return this.call({
      method: 'put',
      url: '/risks',
      data: {
        ...riskData,
      },
    });
  }

  deleteRisk(id) {
    return this.call({
      method: 'delete',
      url: `/risks/${id}`,
    });
  }

  approveRisk(id) {
    return this.call({
      method: 'put',
      url: `/risks/${id}/approve`,
    });
  }

  rejectRisk(id, rejectionData) {
    return this.call({
      method: 'put',
      url: `/risks/${id}/reject`,
      data: {
        ...rejectionData,
      },
    });
  }

  updateRiskStatus(id, status) {
    return this.call({
      method: 'put',
      url: `/risks/${id}/status`,
      data: {
        status,
      },
    });
  }

  bulkApproveRisks(ids) {
    return this.call({
      method: 'put',
      url: '/risks/bulk-approve',
      data: {
        ids,
      },
    });
  }

  addComment(id, commentData) {
    return this.call({
      method: 'put',
      url: `/risks/${id}/comment`,
      data: {
        ...commentData,
      },
    });
  }

  addAction(id, actionData) {
    return this.call({
      method: 'put',
      url: `/risks/${id}/action`,
      data: {
        ...actionData,
      },
    });
  }

  getNextRiskNumber(id) {
    return this.call({
      method: 'get',
      url: `/projects/${id}/next-risk-number`,
    });
  }

  getShouldGeneratePreliminaryReport(projectId) {
    return this.call({
      method: 'get',
      url: `/inspections/should-generate-preliminary-report?${qs.stringify({ projectId })}`,
    });
  }

  getPendingInspection(projectId) {
    return this.call({
      method: 'get',
      url: `/inspections/max-count?${qs.stringify({ projectId })}`,
    });
  }

  getAnticipatedDate(projectId, inspectionNumber) {
    return this.call({
      method: 'get',
      url: `/inspections/anticipated-date?${qs.stringify({ projectId, inspectionNumber })}`,
    });
  }

  getTimeZonesByLocations(ids, atDateTime) {
    return this.call({
      method: 'get',
      url: `/i18n/timezone/by-location?${qs.stringify({ ids, atDateTime })}`,
    });
  }

  getTimeZonesByInspections(ids) {
    return this.call({
      method: 'get',
      url: `/i18n/timezone/by-inspection?${qs.stringify({ ids })}`,
    });
  }

  getInspectionsSummary(projectId) {
    return this.call({
      method: 'get',
      url: `/inspections/summary?${qs.stringify({ projectId })}`,
    });
  }
}

export default new InspectionsService();
