import qs from 'qs';
import ApiClient from 'services/ApiClient';

class ResourcesService extends ApiClient {
  getResourceData({ id }) {
    return this.call({
      method: 'get',
      url: `/assignments/${id}`,
    });
  }

  getResourcesList(projectId) {
    return this.call({
      method: 'get',
      url: `/assignments?${qs.stringify({ projectId })}`,
    });
  }

  getResourceSuggestions(projectId) {
    return this.call({
      method: 'get',
      url: `/assignments/suggestions?${qs.stringify({ projectId })}`,
    });
  }

  addResource(resourceData) {
    return this.call({
      method: 'post',
      url: '/assignments',
      data: {
        ...resourceData,
      },
    });
  }

  updateResource(resourceData) {
    return this.call({
      method: 'put',
      url: '/assignments',
      data: {
        ...resourceData,
      },
    });
  }

  assignRE(resourceData) {
    return this.call({
      method: 'put',
      url: '/assignments/assign/risk-engineer',
      data: {
        ...resourceData,
      },
    });
  }

  deleteResource(resourceId) {
    return this.call({
      method: 'delete',
      url: `/assignments/${resourceId}`,
    });
  }

  sendPendingBills(resourceData) {
    return this.call({
      method: 'post',
      url: '/assignments/send-pending-bills',
      data: {
        ...resourceData,
      },
    });
  }

  completeTransfer(projectId) {
    return this.call({
      method: 'patch',
      url: '/assignments/complete-transfer',
      data: {
        id: projectId,
      },
    });
  }

  getProjectProximitySuggestions(data) {
    return this.call({
      method: 'get',
      url: `/assignments/proximity-suggestions?${qs.stringify(data)}`,
    });
  }
}

export default new ResourcesService();
