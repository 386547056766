import styled from 'styled-components';

export const Description = styled.div`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 138%;
  letter-spacing: 0.16px;
  color: var(--lightTextColor);
`;

export const RejectionReasonWrapper = styled.div`
  border: 1px solid var(--lightBorderColor);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 20px 18px;
`;

export const RejectionReasonTitle = styled.span`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: var(--textColor);
`;

export const RejectionReasonText = styled.span`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: var(--textColor);
  opacity: 0.9;
`;
