import React from 'react';

import { ROLES_IDS } from 'services/AuthService';

import { TextSection, List } from '../../styles';

const inspectionCalendar = {
  title: 'Inspections',
  component:
  <TextSection>
    Inspection calendar provides the list, map and calendar view of all the inspections from your projects.
    When the inspection is scheduled by the risk engineer, such inspection appears in the calendar and the
    notification is sent to the project stakeholders. By clicking the inspection in the calendar, you can
    see the inspection details.
  </TextSection>,
};

const assignmentsICCorRCC = {
  title: 'Assignments',
  component:
  <TextSection>
    Assignments section lists all the projects with the resource assigned. If the project has not been allocated
    the resource, such project will always be displayed at the top of the list until the resource is assigned.
    By clicking the project, you will be redirected to the associated section of the project.
  </TextSection>,
};

const assignmentsRE = {
  title: 'Assignments',
  component:
  <TextSection>
    Assignments section lists all the projects you are assigned to. By clicking the project, you will be
    redirected to the project page where the detailed information about the project is displayed.
  </TextSection>,
};

const reportsICC = {
  title: 'Reports',
  component:
  <TextSection>
    Report section lists abridge and main reports from all your projects. If the main report hasn’t been
    approved yet, such report will be displayed at the top of the list until this is approved.
    By clicking the report icon, you can open the report. You can also share the report with the project
    stakeholders, by selecting ‘Share’ option for the report that you want to share.
  </TextSection>,
};

const reportsRCCorSC = {
  title: 'Reports',
  component:
  <TextSection>
    Report section lists abridge and main reports from all your projects. When the report is generated by
    the risk engineer, it appears in that section. By clicking the report icon, you can open the report.
  </TextSection>,
};

const reportsRE = {
  title: 'Reports',
  component:
  <TextSection>
    Report section lists abridge and main reports from all your projects. When the report is generated for the
    project, it will also appear in that section. By clicking the report icon, you can open the report.
  </TextSection>,
};

const budgets = {
  title: 'Budgets',
  component:
  <TextSection>
    Budget section lists all the project and associated budgets allocated. If the budget has not been allocated
    for the project yet, such project will be displayed at the of the list. By clicking the project, you will be
    redirected to the associated section of the project.
  </TextSection>,
};

const TandERCC = {
  title: 'Time & Expenses',
  component:
  <TextSection>
    Time & Expenses section lists the resources assigned to the projects providing the information about the
    budget spent on the specific project. By clicking the project, you will be redirected to the associated project
    section where the detailed information about the time and expenses for such project is displayed.
  </TextSection>,
};

const TandERE = {
  title: 'Time & Expenses',
  component:
  <TextSection>
    Time & Expenses section lists all your projects and the budget spent so far for these projects.
    By clicking the project, you will be redirected to the associated project section where the detailed
    information about the time and expenses is displayed.
  </TextSection>,
};

const contactsICCorRCC = {
  title: 'Contacts',
  component:
  <TextSection>
    Contacts section allows you add and store the contact information about the external contacts you are working
    with. You can add the external contact once and then invite such contact to your projects without a need to
    populate the contact information from scratch every time. Think of this like of your platform contact book.
    Note that if a contact is a part of your company, then such contact will be stored within ‘My Company’
    section that is available to company coordinators.
  </TextSection>,
};

const contactsRE = {
  title: 'Contacts',
  component:
  <TextSection>
    Contacts section allows you add and store the contact information about the external contacts you are working with.
    You can add the external contact once and then invite such contact to your projects without a need to populate the
    contact information from scratch every time. Think of this like of your platform contact book.
  </TextSection>,
};

const analytics = {
  title: 'Analytics',
  component:
  <>
    <TextSection>
      Analytics section collates the risk recommendations and claims data across all your projects.
      The information gathered is displayed with a help of the charts providing the ability to filter the data
      and get the insights from that data. The data can be filtered by the following parameters:
    </TextSection>
    <List>
      <li>Project Types</li>
      <li>Recommendation Types</li>
      <li>Recommendation Priorities</li>
      <li>Recommendation Statuses</li>
    </List>
    <TextSection>
      When the filters are applied, all the data displayed within that section (overview, map, charts)
      is filtered according to the set criteria.
    </TextSection>
  </>,
};

export const getDataByRole = (role) => {
  switch (role) {
    case ROLES_IDS.ICCoordinator:
    case ROLES_IDS.ICUnderwriter:
      return {
        roleForPath: 'icc',
        sections: [
          inspectionCalendar,
          assignmentsICCorRCC,
          reportsICC,
          budgets,
          contactsICCorRCC,
          analytics,
        ],
      };
    case ROLES_IDS.RCCoordinator:
      return {
        roleForPath: 're',
        sections: [
          inspectionCalendar,
          assignmentsICCorRCC,
          reportsRCCorSC,
          TandERCC,
          contactsICCorRCC,
          analytics,
        ],
      };
    case ROLES_IDS.RiskEngineer:
      return {
        roleForPath: 're',
        sections: [
          inspectionCalendar,
          assignmentsRE,
          reportsRE,
          TandERE,
          contactsRE,
          analytics,
        ],
      };
    case ROLES_IDS.RiskManager:
    case ROLES_IDS.DeveloperOwner:
    case ROLES_IDS.InsuranceRepresentative:
    case ROLES_IDS.Broker:
    case ROLES_IDS.SiteContact:
      return {
        roleForPath: 'rm',
        sections: [
          inspectionCalendar,
          reportsRCCorSC,
          analytics,
        ],
      };
    default: return [];
  }
};
