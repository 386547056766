import React from 'react';
import { Navigate } from 'react-router-dom';

import AuthService from 'services/AuthService';

function withPermission(WrappedComponent: any, neededPermission: string, section: string) {
  return function PermittedComponent(props: any) {
    const isRouteAllowed = AuthService.hasPermission(neededPermission, section);
    const role = AuthService.getRoleId();

    if (!isRouteAllowed) {
      return <Navigate to="/" />;
    }

    return (
      <WrappedComponent role={role} {...props} />
    );
  };
}

export default withPermission;
