import React from 'react';

const UploadFile = () => {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17 28a1 1 0 010 2H6a2.006 2.006 0 01-2-2V4a2.006 2.006 0 012-2h12a.909.909 0 01.7.3l7 7a.91.91 0 01.3.7v4a1 1 0 11-2 0v-2h-6a2.006 2.006 0 01-2-2V4H6v24h11zm6.6-18L18 4.4V10h5.6z" fill="currentColor" fillOpacity=".68" />
      <path d="M29.293 23.707a1 1 0 000-1.414L25 18l-4.293 4.293a1 1 0 101.414 1.414L24 21.828V29a1 1 0 102 0v-7.172l1.879 1.88a1 1 0 001.414 0z" fill="currentColor" fillOpacity=".68" />
    </svg>
  );
};

export default UploadFile;
