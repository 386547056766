import styled from 'styled-components';
import { getColorByStatus } from 'dashboard/constants';

export const ProjectListRoot = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 576px;
`;

export const StatusTag = styled.div`
  max-width: 120px;
  padding: 4px 12px;
  border-radius: 4px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  vertical-align: middle;

  ${({ $status, $readyToBeReallocated }) => getColorByStatus($status, $readyToBeReallocated)}
`;
