import NotificationService from 'projects/api/NotificationService';

const GET_PROJECT_NOTIFICATIONS_LIST = 'GET_PROJECT_NOTIFICATIONS_LIST';
const PROJECT_NOTIFICATIONS_LIST_SORT_BY = 'PROJECT_NOTIFICATIONS_LIST_SORT_BY';

export const getProjectNotificationsList = (params) => ({
  type: GET_PROJECT_NOTIFICATIONS_LIST,
  apiCall: () => NotificationService.getProjectNotificationsList(params),
});

export const setProjectNotificationsSortBy = (sortBy) => ({
  type: PROJECT_NOTIFICATIONS_LIST_SORT_BY,
  sortBy,
});

const initialState = {
  sortBy: null,
  notificationsList: {
    items: [],
  },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case PROJECT_NOTIFICATIONS_LIST_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }
    case GET_PROJECT_NOTIFICATIONS_LIST: {
      return {
        ...state,
        notificationsList: {
          ...state.notificationsList,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
