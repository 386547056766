import * as Yup from 'yup';

import { requiredString, requiredArrayOfStrings } from 'utils/validation/customTypes';

export const EXPENSE_SCHEMA = Yup.object().shape({
  categories: requiredArrayOfStrings,
  currency: requiredString,
  costPerItem: requiredString,
});

export const EXPENSE_INITIAL_VALUES = process.env.NODE_ENV === 'development' ? {
  categories: [],
  currency: 'USD',
  costPerItem: 10,
} : {
  categories: [],
  currency: 'USD',
  costPerItem: '',
};

export const TIME_SCHEMA = Yup.object().shape({
  designations: requiredArrayOfStrings,
  categories: requiredArrayOfStrings,
  currency: requiredString,
  ratePerHour: requiredString,
});

export const TIME_INITIAL_VALUES = process.env.NODE_ENV === 'development' ? {
  designations: [],
  categories: [],
  currency: 'USD',
  ratePerHour: 100,
} : {
  designations: [],
  categories: [],
  currency: 'USD',
  ratePerHour: '',
};

export const EXPENSE_CATEGORY = [
  { label: 'Mileage', value: 'Mileage' },
  { label: 'Photocopying', value: 'Photocopying' },
  { label: 'Photographs', value: 'Photographs' },
  { label: 'Printing', value: 'Printing' },
  { label: 'Set up files', value: 'SetUpFiles' },
];

export const DESIGNATIONS = [
  {
    label: 'Risk Engineer',
    value: 'Engineer',
  },
  {
    label: 'Senior Risk Engineer',
    value: 'Senior Engineer',
  },
  {
    label: 'Surveyor',
    value: 'Surveyor',
  },
  {
    label: 'Senior Surveyor',
    value: 'Senior Surveyor',
  },
  {
    label: 'Risk Coordinator',
    value: 'Risk Coordinator',
  },
  {
    label: 'Assistant',
    value: 'Assistant',
  },
  {
    label: 'Administrative',
    value: 'Administrative',
  },
];

export const tooltipTexts = {
  ICC: 'When the rates are set up, the internal risk engineers can input their time and expenses based on the company rates which allows tracking how the project budget is spent.',
  other: 'When the rates are set up, the internal risk engineers can track their time and expenses based on the company rates which allows tracking how the project budget is spent. The rates are not shared with the external risk engineers.',
};
