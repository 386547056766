import React from 'react';

const User = () => {
  return (
    <svg width="14" height="14" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path fill="#fff" fillOpacity=".68" d="M7 1.75a2.187 2.187 0 110 4.375A2.187 2.187 0 017 1.75zm0-.875A3.062 3.062 0 107 7 3.062 3.062 0 007 .875zM11.375 13.125H10.5v-2.188A2.188 2.188 0 008.312 8.75H5.688A2.188 2.188 0 003.5 10.938v2.187h-.875v-2.188a3.063 3.063 0 013.063-3.062h2.625a3.062 3.062 0 013.062 3.063v2.187z" />
    </svg>
  );
};

export default User;
