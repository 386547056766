import styled from 'styled-components';
import { styled as styledMui } from "@mui/material/styles";

export const FoldersRoot = styledMui('div')(({ theme }) => ({
  position: 'relative',
  width: '35%',
  display: 'flex',
  flexDirection: 'column',
  paddingRight: 30,
  marginRight: 37,
  borderRight: '1px solid '.concat(theme.palette.navbar.tab.border),
}));

export const ListRoot = styled.div`
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 372px;
  margin-top: 16px;
  margin-left: -13px;
`;

export const Title = styledMui('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 40,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: 20,
  lineHeight: '27px',
  color: theme.palette.text.title,
}));
