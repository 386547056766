import React, { useReducer, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { requiredString } from 'utils/validation/customTypes';
import AccountService from 'account/api/AccountService';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import { getCountries } from 'projects/store/contacts.selectors';
import { Spinner } from 'components';

import PersonalInfoForm from './PersonalInfoForm';

import { Root } from './styles';

const validationSchema = Yup.object().shape({
  name: Yup.object().shape({
    firstName: requiredString,
    lastName: requiredString,
  }),
  address: Yup.object().shape({
    country: requiredString,
    state: requiredString,
    city: requiredString,
    addressLine: requiredString,
    zipCode: requiredString,
  }),
  phone: requiredString,
  companyName: requiredString,
});

const reduceFunc = (prevState, newState) => ({
  ...prevState,
  ...newState,
});

const PersonalInfo = () => {
  const countriesData = useSelector(getCountries);
  const [{ userData, isLoading, cvFileId, forceUpdate }, setState] = useReducer(reduceFunc, {
    isLoading: false,
    userData: null,
    cvFileId: '',
    forceUpdate: false,
  });

  const fetchUserData = async () => {
    const { data: { data } } = await AccountService.getUserInfo();
    setState({ userData: data });
  };

  const setCvFileId = ({ value }) => {
    setState({ cvFileId: value });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      setState({ forceUpdate: userData.cvFileInfo });
    }
  }, [userData]);

  if (!userData) {
    return (
      <Root>
        <Spinner />
      </Root>
    );
  }

  const handleSubmit = async (values, formikBag) => {
    try {
      setState({ isLoading: true, isEditable: false });
      const updateValues = {
        ...values,
        cvFileId: cvFileId || '',
      };
      await AccountService.updateUserInfo(updateValues);
      fetchUserData();
      setState({ isLoading: false });
      showNotifySuccess('Your personal info was successfully updated.');
    } catch (error) {
      const { fieldErrors } = error;
      if (fieldErrors) {
        formikBag.setErrors(fieldErrors);
      } else {
        showNotifyError(error);
      }
    }
  };

  const initialValues = {
    ...userData,
    ...userData.info,
  };

  return (
    <Root>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {isLoading ? <Spinner /> : (
          (formikProps) => (
            <PersonalInfoForm
              formikProps={formikProps}
              countriesData={countriesData}
              setState={setState}
              setCvFileId={setCvFileId}
              cvFileInfo={userData.cvFileInfo}
              forceUpdate={forceUpdate}
            />
          )
        )}
      </Formik>
    </Root>
  );
};

export default PersonalInfo;
