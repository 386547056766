import React, { useState, useEffect } from 'react';

import { SearchInput } from 'components';
import SubscriptionsService from 'subscriptions/api/SubscriptionsService';

import { StyledForm, ContactInfo, ContactRow, StyledIcon, StyledAutocomplete } from './styles';

const AddRCForm = ({ role, resetForm, subscriberId, setFieldValue }) => {
  const [dropdownData, setDropdownData] = useState([]);
  const [subscriberData, setSubscriberData] = useState();
  const [addressString, setAddressString] = useState();

  const isRC = role === 'RiskCompany';

  const fetchDropdownData = async () => {
    const { data: { data } } = isRC
      ? await SubscriptionsService.getRCWithoutSubscription()
      : await SubscriptionsService.getUsersWithoutSubscription(role);

    setDropdownData(data);
  };

  const dropdownOptions = dropdownData.map((item) => ({
    label: item.name.fullName || item.name,
    value: item.id,
  }));

  const handleChange = (_, inputValue) => {
    if (inputValue) setFieldValue('subscriberId', inputValue.value);
  };

  const clearSearch = () => {
    setFieldValue('subscriberId', '');
  };

  const getOptionLabel = (inputValue) => {
    if (!inputValue.label) {
      const option = dropdownOptions.find(({ value }) => value === inputValue);
      return (option && option.label) || '';
    }
    return inputValue.label || '';
  };

  useEffect(() => {
    setSubscriberData(...dropdownData.filter((item) => item.id === subscriberId));
  }, [subscriberId]);

  useEffect(() => {
    fetchDropdownData();
    resetForm();
    setSubscriberData(null);
  }, [role]);

  useEffect(() => {
    if (subscriberData && subscriberData.address) {
      const resultString = ['addressLine', 'country', 'state', 'city', 'zipCode']
        .map((key) => subscriberData.address[key])
        .filter((el) => el)
        .join(', ');
      setAddressString(resultString);
    }
  }, [subscriberData]);

  const renderInput = (params) => {
    const { inputProps } = params;
    return (
      <SearchInput
        {...params}
        {...inputProps}
        placeholder="Subscription For"
        fullWidth
        onClearSearch={clearSearch}
      />
    );
  };

  return (
    <>
      <StyledForm>
        {dropdownData && (
          <StyledAutocomplete
            fullWidth
            getOptionLabel={getOptionLabel}
            options={dropdownOptions}
            value={subscriberId}
            onChange={handleChange}
            renderInput={renderInput}
          />
        )}
      </StyledForm>
      {subscriberData && (
        <ContactInfo>
          <div>
            {subscriberData.name.fullName || subscriberData.name}
          </div>
          {!isRC && (
            <ContactRow>
              <StyledIcon icon="company" noWrapper />
              <span>{subscriberData.companyName}</span>
            </ContactRow>
          )}
          <ContactRow>
            <StyledIcon icon="address" noWrapper />
            <span>{isRC ? subscriberData.address : addressString}</span>
          </ContactRow>
          <ContactRow>
            <StyledIcon icon="phone" noWrapper />
            <span>{subscriberData.phone}</span>
          </ContactRow>
          {!isRC && (
            <ContactRow>
              <StyledIcon icon="mail" noWrapper />
              <span>{subscriberData.email}</span>
            </ContactRow>
          )}
          {isRC && (
            <ContactRow>
              <StyledIcon icon="user" noWrapper />
              <span>
                {subscriberData.primaryContactFullName}
                &nbsp;
                {' • '}
                &nbsp;
                {subscriberData.primaryContactEmail}
              </span>
            </ContactRow>
          )}
        </ContactInfo>
      )}
    </>
  );
};

export default AddRCForm;
