import React from 'react';

const Details = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.87 10.576h8.07v1.467H5.87v-1.467zm0 3.668h9.538v1.468H5.87v-1.468z" fill="#fff" fillOpacity=".87" />
      <path d="M20.544 3.239H2.934a1.467 1.467 0 00-1.467 1.467V19.38a1.467 1.467 0 001.468 1.468h17.609a1.467 1.467 0 001.467-1.468V4.706a1.467 1.467 0 00-1.467-1.467zm0 1.467v1.468H2.934V4.706h17.61zM2.934 19.38V7.641h17.61v11.74H2.934z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Details;
