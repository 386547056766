import React, { useState, useEffect, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";

import MainLayout from "layouts/MainLayout";
import { showNotifyInfo, showNotifyAlert } from "services/toaster";
import AuthService, { ROLES_IDS } from "services/AuthService";
import withPermission from "utils/hooks/withPermission";
import useQuery from "utils/hooks/useQuery";
import { NOTIFICATION_SUBJECTS, ALERT_TYPES } from "utils/constants";

import GlobalEcosystem from "globalEcosystem/pages/GlobalEcosystem";
import MyCompany from "myCompany/pages/MyCompany";
import SubscriptionsRoutes from "subscriptions/routes";
import NonSubscriberRoutes from "non-subscribers/routes";
import Help from "help/pages/Help";
import AccountInfo from "account/pages/AccountInfo";
// import Project from "projects/pages/Project";
import ProjectsList from "projects/pages/ProjectsList";
import EulaAgreement from "account/components/EulaAgreement";
import PrivacyPolicyAgreement from "account/components/PrivacyPolicyAgreement";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";

import * as NotificationsActions from "notifications/store/notification.reducer";
import * as UserActions from "projects/store/users.reducer";
import * as ContactsActions from "projects/store/contacts.reducer";
import * as TeamMembersActions from "myCompany/store/teamMembers.reducer";
import * as RatesActions from "myCompany/store/rates.reducer";
import ExpiredSessionWarning from "./ExpiredSessionModal/ExpiredSessionWarning";
import UserService from "./projects/api/UserService";
import CommunicationsRoutes from "./communication/routes";
import LibraryRoutes from "./library/routes";
import Spinner from "components/Spinner";

const Project = React.lazy(
  () => import(/* webpackChunkName: "ProjectPage" */ "projects/pages/Project"),
  { fallback: <Spinner /> }
);

const DashboardRoutes = React.lazy(
  () => import(/* webpackChunkName: "Dashboard" */ "dashboard/routes"),
  { fallback: <Spinner /> }
);

const ProjectAddRoute = withPermission(Project, "add", "projects");
const GlobalEcosystemRoute = withPermission(
  GlobalEcosystem,
  "globalEcosystem",
  "globalEcosystem"
);
const MyCompanyRoute = withPermission(MyCompany, "myCompany", "myCompany");
const SubscriptionsRoute = withPermission(
  SubscriptionsRoutes,
  "subscriptions",
  "subscriptions"
);

const SESSION_TIMEOUT_MIN = 20;
const IDLE_TIMER_TIMEOUT_MS = SESSION_TIMEOUT_MIN * 60 * 1000;

const MainRoutes = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { role, hasAcceptedLicense, hasAcceptedEula } = AuthService.getUser();
  const allowAccessDueToPrivacyLicense =
    hasAcceptedLicense != null && hasAcceptedLicense;
  const allowAccessDueToEula = hasAcceptedEula != null && hasAcceptedEula;
  const [loading, setLoading] = useState(true);
  const [expiredSessionModalOpen, setExpiredSessionModalOpen] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(
    allowAccessDueToPrivacyLicense
  );
  const [acceptedEula, setAcceptedEula] = useState(allowAccessDueToEula);
  const [showModal, setShowModal] = useState({
    isOpen: false,
    message: "",
    title: "",
    onClick: null,
    confirmButtonTitle: "",
    onCancel: null,
    onClose: null,
  });

  // because of annoying popups from ARCGIS when connecting to staging from local machine
  const userInitialRoute =
    process.env.NODE_ENV === "development" ? "/projects" : "/dashboard";
  const defaultRoute =
    role === ROLES_IDS.Admin ? "/subscriptions" : userInitialRoute;
  const isICC = role === ROLES_IDS.ICCoordinator;
  const isRCC = role === ROLES_IDS.RCCoordinator;

  const loadAllNeededData = async () => {
    await dispatch(UserActions.getCurrentUserInfo());
    await dispatch(ContactsActions.getCountriesData());
    if (isICC || isRCC) {
      await dispatch(RatesActions.getRates());
      await dispatch(TeamMembersActions.getTeamMembersList({ page: 1 }));
    }
    const notifications = await dispatch(
      NotificationsActions.getAllInboxNotificationsList()
    );
    if (!allowAccessDueToEula) {
      setLoading(false);
      return;
    }
    if (!allowAccessDueToPrivacyLicense) {
      setLoading(false);
      return;
    }
    if (!Array.isArray(notifications)) {
      // show anything if backend not available (504 Gateway Timeout for example)
      setLoading(false);
      return;
    }
    const newNotifications = notifications.filter(
      ({ isViewed, type }) => !isViewed && !ALERT_TYPES.includes(type)
    );
    const newAlerts = notifications.filter(
      ({ isViewed, type }) => !isViewed && ALERT_TYPES.includes(type)
    );

    let handleClick;
    if (newNotifications.length === 1) {
      handleClick = () => {
        const firstNotification = newNotifications[0];
        const notify = {
          ...firstNotification,
          subject: NOTIFICATION_SUBJECTS[firstNotification.type],
        };
        dispatch(NotificationsActions.setOpenedNotification(notify));
      };
      showNotifyInfo("You have a new notification.", handleClick);
    } else if (newNotifications.length > 1) {
      handleClick = () =>
        dispatch(NotificationsActions.setNotificationsView("new"));
      showNotifyInfo(
        `You have ${newNotifications.length} new notifications.`,
        handleClick
      );
    }
    if (newAlerts.length === 1) {
      handleClick = () => {
        const notify = newAlerts[0];
        dispatch(NotificationsActions.setOpenedAlert(notify));
      };
      showNotifyAlert("You have a new claim alert.", handleClick);
    } else if (newAlerts.length > 1) {
      handleClick = () => dispatch(NotificationsActions.setAlertsView("new"));
      showNotifyAlert(
        `You have ${newAlerts.length} new claim alerts.`,
        handleClick
      );
    }
    setLoading(false);
  };

  const handleOnActive = () => {
    if (!expiredSessionModalOpen) AuthService.setActiveTime();
  };

  const closeModal = () => {
    AuthService.setModalState("closed");
    setExpiredSessionModalOpen(false);
    handleOnActive();
  };

  const handleOnIdle = () => {
    const lastActivityTime = AuthService.getActiveTime();
    const differenceInMS = Date.now() - lastActivityTime;
    const differenceInMin = differenceInMS / 1000 / 60;
    if (differenceInMin >= SESSION_TIMEOUT_MIN) {
      AuthService.setModalState("opened");
      setExpiredSessionModalOpen(true);
    }
  };

  const handleStorageChange = () => {
    const modalState = AuthService.getModalState();
    setExpiredSessionModalOpen(modalState === "opened");
  };

  const showConfirmationModal = () => {
    if (!showModal.isOpen) return null;
    return (
      <ConfirmationDialog
        open={showModal.isOpen}
        onClose={showModal.onClose}
        onConfirm={showModal.onClick}
        onCancel={showModal.onCancel}
        title={showModal.title}
        confirmButtonTitle={showModal.confirmButtonTitle}
        noCancel
      >
        {showModal.message}
      </ConfirmationDialog>
    );
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnActive,
    timeout: IDLE_TIMER_TIMEOUT_MS,
    throttle: 500,
  });

  useEffect(() => {
    loadAllNeededData();
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  if (loading) return null;

  if (!AuthService.getToken()) {
    AuthService.signOut();
    return null;
  }

  return (
    <MainLayout>
      <Routes>
        <Route
          path="/my-account"
          element={<AccountInfo defaultTabValue={query.get("tab")} />}
        />
        <Route path="/projects" element={<ProjectsList />} />
        <Route
          path="/projects/:projectId/*"
          element={
            <Suspense fallback={<Spinner />}>
              <Project setShowConfirmationModal={setShowModal} />
            </Suspense>
          }
        />
        <Route path="/projects/add" exact element={<ProjectAddRoute />} />
        {/* <Route path="/projects/:projectId/:tab/:action" element={<Project/>} /> */}
        <Route
          path="/dashboard/*"
          element={
            <Suspense fallback={<Spinner />}>
              <DashboardRoutes />
            </Suspense>
          }
        />
        <Route
          path="/global-ecosystem"
          exact
          element={<GlobalEcosystemRoute />}
        />
        <Route path="/my-company" exact element={<MyCompanyRoute />} />
        <Route path="/subscriptions/*" element={<SubscriptionsRoute />} />
        <Route path="/non-subscribers/*" element={<NonSubscriberRoutes />} />
        <Route path="/help" element={<Help />} />
        <Route path="/communications/*" element={<CommunicationsRoutes />} />
        <Route path="/library" element={<LibraryRoutes />} />
        <Route path="/library/:tab" element={<LibraryRoutes />} />

        <Route path="/" element={<Navigate to={defaultRoute} replace />} />
      </Routes>
      {expiredSessionModalOpen && (
        <ExpiredSessionWarning closeModal={closeModal} />
      )}
      {!acceptedEula && (
        <EulaAgreement
          accept={async () => {
            await UserService.acceptEula();
            AuthService.acceptEula();
            setAcceptedEula(true);
            if (acceptedPrivacy) {
              window.location.reload();
            }
          }}
          close={() => {
            AuthService.signOut();
          }}
        />
      )}
      {!acceptedPrivacy && acceptedEula && (
        <PrivacyPolicyAgreement
          accept={async () => {
            await UserService.acceptLicenseAgreement();
            AuthService.acceptLicense();
            setAcceptedPrivacy(true);
            if (acceptedEula) {
              window.location.reload();
            }
          }}
          close={() => {
            AuthService.signOut();
          }}
        />
      )}
      {showConfirmationModal()}
    </MainLayout>
  );
};

export default MainRoutes;
