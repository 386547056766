import * as Yup from 'yup';

import { ROLES_IDS } from 'services/AuthService';
import { requiredString, nullableString } from 'utils/validation/customTypes';
import { COMPANY_TYPES } from 'types/company';

const memberShape = {
  position: requiredString,
  email: requiredString.email('Please enter a valid email address.'),
  phone: nullableString,
  name: Yup.object().shape({
    firstName: requiredString,
    lastName: requiredString,
  }),
  address: Yup.object().shape({
    country: nullableString,
    state: nullableString,
    city: nullableString,
    zipCode: nullableString,
    addressLine: nullableString,
  }),
};

export const MEMBER_ICC_SCHEMA = Yup.object().shape(memberShape);

export const MEMBER_RCC_SCHEMA = Yup.object().shape({
  ...memberShape,
  designation: nullableString,
});

export const MEMBER_RE_SCHEMA = Yup.object().shape({
  ...memberShape,
  designation: requiredString,
});

export const newContactInitialValues = process.env.NODE_ENV === 'development' ? {
  position: '',
  designation: '',
  email: 'mail@mail.com',
  phone: '2020327',
  name: {
    firstName: 'Biba',
    lastName: 'Boba',
  },
  address: {
    country: 'Belarus',
    state: 'Minsk',
    city: 'Minsk',
    zipCode: '220000',
    addressLine: 'Kulman 11',
  },
  location: '',
} : {
  position: '',
  location: '',
  designation: '',
  email: '',
  name: {
    firstName: '',
    lastName: '',
  },
  address: {
    country: '',
    state: '',
    city: '',
    zipCode: '',
    phone: '',
    addressLine: '',
  },
};

export const REPosition = 'InternalRE';
export const CoordinatorPosition = ROLES_IDS.ICCoordinator;
export const positionsCompanyTeamMembers = {
  [COMPANY_TYPES.INSURANCE]: [
    {
      label: 'Coordinator',
      value: ROLES_IDS.ICCoordinator,
    },
    {
      label: 'Underwriter',
      value: ROLES_IDS.ICUnderwriter,
    },
    {
      label: 'Risk Engineer',
      value: REPosition,
    },
  ],
  [COMPANY_TYPES.BROKER]: [
    {
      label: 'Coordinator',
      value: ROLES_IDS.ICCoordinator,
    },
    {
      label: 'Client Account Handler',
      value: ROLES_IDS.ICUnderwriter,
    },
    {
      label: 'Risk Engineer',
      value: REPosition,
    },
  ],
  [COMPANY_TYPES.RISK]: [
    {
      label: 'Coordinator',
      value: ROLES_IDS.RCCoordinator,
    },
    {
      label: 'Risk Engineer',
      value: REPosition,
    },
  ],
};

export const deleteDisabledTooltipRE = 'Risk Engineer that is assigned to at least one project cannot be deleted.';
export const deleteDisabledTooltipPrimary = 'Primary Contact cannot be deleted. If you want to remove that user from your company, please update the Primary Contact.';
