import React from 'react';

const ChevronRight = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity=".5">
        <path d="M9.625 7L5.25 11.375l-.613-.613L8.4 7 4.637 3.237l.613-.612L9.625 7z" fill="currentColor" fillOpacity=".87" />
        <path d="M9.731 7.106L9.837 7l-.106-.106-4.375-4.375-.106-.106-.106.106-.613.612-.106.107.106.106L8.188 7 4.53 10.656l-.106.106.106.107.613.612.106.106.106-.106 4.375-4.375z" stroke="currentColor" strokeOpacity=".87" strokeWidth=".3" />
      </g>
    </svg>
  );
};

export default ChevronRight;
