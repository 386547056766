import React from 'react';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const domNode = document.querySelector('#root');

const root = createRoot(domNode!);

root.render(<App />);

// serviceWorkerRegistration.register();