import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NOTIFICATION_ENUM } from 'utils/constants';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import {
  Text, HighlightedText, HighlightedEmail, InfoWrapper, Contact, ButtonOpenProject, StyledIcon, StyledArrow,
} from './styles';

const TextLine = ({ text, value, withoutNewLine }) => {
  const [startText, endText] = text.split('{placeholder}');

  return (
    <div>
      <span>
        {startText}
        {!withoutNewLine && <br />}
        <HighlightedText>{value}</HighlightedText>
        {endText}
      </span>
    </div>
  );
};

const getFormDescription = (notify, isCompany, redirectStraightToProject, isRCC) => {
  const { data } = notify;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openProjectDetails = () => {
    navigate(`/projects/${data.projectId}/details`);
    if (redirectStraightToProject) {
      dispatch(NotificationsActions.setOpenedNotification(null));
      dispatch(NotificationsActions.setPendingNotification(null));
      dispatch(NotificationsActions.setNotificationsView(''));
      return;
    }
    dispatch(NotificationsActions.setPendingNotification(notify));
    dispatch(NotificationsActions.setNotificationsView(''));
    dispatch(NotificationsActions.setSlideOut(true));
  };

  return (
    <Text>
      {isCompany ? (
        <div>
          A new project has been assigned to your company,
          please review the project information and assign the resource.
        </div>
      ) : (
        <div>
          A new project has been assigned to you,
          please confirm the assignment by selecting
          {' '}
          <HighlightedText>“Acknowledge Assignment”</HighlightedText>
          {' '}
          button below.
        </div>
      )}

      <br />
      <InfoWrapper>

        <Contact>
          <TextLine value={data.companyName} text="Insurance Company Name: {placeholder}" />
          <TextLine value={data.projectLocation} text="Location: {placeholder}" />
          <TextLine value={data.projectType} text="Project Type: {placeholder}" />
          <TextLine value={data.projectBusinessType} text="Business Type: {placeholder}" />
          <TextLine value={data.policyStartDate} text="Policy Start Date: {placeholder}" />
          <TextLine value={data.policyExpirationDate} text="Policy Expiration Date: {placeholder}" />
          <TextLine value={data.specialInstruction} text="Special Instructions: {placeholder}" />
        </Contact>

      </InfoWrapper>
      {isRCC && (
        <ButtonOpenProject onClick={openProjectDetails}>
          <StyledIcon icon="details" noWrapper />
          <div>Open Project</div>
          <StyledArrow icon="chevronRight" noWrapper />
        </ButtonOpenProject>
      )}

      <br />
      <div>
        If this is not your assignment, please contact us at
        {' '}
        <HighlightedEmail>{data.email}</HighlightedEmail>
      </div>
    </Text>
  );
};

const FormDescription = ({ notify, redirectStraightToProject }) => {
  const { type } = notify;
  const { role } = AuthService.getUser();
  const isCompany = type === NOTIFICATION_ENUM.newCompanyAssignmentReceived;
  const isRCC = role === ROLES_IDS.RCCoordinator;

  return getFormDescription(notify, isCompany, redirectStraightToProject, isRCC);
};

export default FormDescription;
