import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import AuthService from 'services/AuthService';
import { PACKAGES_IDS } from 'subscriptions/constants';
import { SECTIONS } from 'help/constants';

import { Root, StyledButton, Line, IconContainer, StyledIcon, SectionTitle } from './styles';
import Comment from '../../../../../components/Icon/Store/ui/Comment';

const SectionMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const locationsArray = location.pathname.split('/');
  const navTabs = Object.values(SECTIONS);
  const prevTab = navTabs.find((navTab) => locationsArray[locationsArray.length - 2] === navTab.path);
  const [currentSection, setCurrentSection] = useState(prevTab ? prevTab.title : 'Dashboard');

  const subscription = AuthService.getUserSubscription();
  const isPackageGlobal = subscription === PACKAGES_IDS.Global;

  const sectionsList = Object.keys(SECTIONS).filter((section) => (
    (section !== 'globalEcosystem' || isPackageGlobal) &&
    AuthService.hasPermission(SECTIONS[section].title, 'help')
  ));

  const renderBody = () => sectionsList.map((section) => {
    const { title, path } = SECTIONS[section];
    const isSelected = title === currentSection;
    const icon = title === 'Communications' ? Comment : section;

    const redirectToSection = () => {
      navigate(`/help/${path}`);
      setCurrentSection(title);
    };

    return (
      <StyledButton
        key={section}
        onClick={redirectToSection}
        isSelected={isSelected}
      >
        <IconContainer>
          <StyledIcon icon={icon} />
        </IconContainer>
        {title}
      </StyledButton>
    );
  });

  return (
    <>
      <Root>
        {renderBody()}
      </Root>
      <SectionTitle>
        <Line />
        <div>{currentSection}</div>
        <Line />
      </SectionTitle>
    </>
  );
};

export default SectionMenu;
