import styled from 'styled-components';
import FormDialog from '../../../../../../components/Dialogs/FormDialog';
import FormikField from '../../../../../../components/FormikField';

export const ControlsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const StyledFormDialog = styled(FormDialog)`
  .MuiDialog-paper {
    max-width: 700px;
  }
`;

export const StyledFormikField = styled(FormikField)`
  margin-bottom: 15px;
`;

export const SelectContainer = styled.div`
  margin: 30px 0;
`;

export const Root = styled.div`
  font-family: Graphik LC;
  font-size: 16px;
  line-height: 150%;
  color: var(--lightTextColor);
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
