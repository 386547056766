import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import Table from 'components/Table';
import Spinner from 'components/Spinner';
import NoContent from 'components/NoContent';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import { RISK_STATUSES } from 'utils/constants';
import TableFooter from './TableFooter';

import { TableRoot, Body, Row, Cell, FullWidthWrapper, StyledIcon, StyledAccordion } from './styles';

const EMPTY_ARRAY = [];

const devMode = process.env.NODE_ENV === 'development';
const expandIcon = <StyledIcon icon="chevronDown" noWrapper />;

const TableCell = ({ item, column, checkedItems, onRenderControls }) => {
  const { key, width, minWidth, render } = column;
  const value = item[key] || '';
  const alignRight = column.align === 'right';

  const cellStyle = {
    flexBasis: width,
    textAlign: column.align,
  };

  const controlsCellStyle = {
    ...cellStyle,
    minWidth,
    ...(alignRight && {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '30px',
    }),
  };

  if (key === 'controls' && onRenderControls) {
    return (
      <Cell key={key} style={controlsCellStyle}>
        {onRenderControls({ ...item, checkedItems })}
      </Cell>
    );
  }

  return (
    <Cell key={key} style={cellStyle}>
      {render ? render(item) : value}
    </Cell>
  );
};

const TableRow = ({
  item, id, expandedTables, setExpandedTables, nonCheckableRows, columns,
  onRenderControls, onRowClick, isRecommendations, clearGlobalChecks, isRiskEngineer,
}) => {
  const [checkedItems, setCheckedItems] = useState([]);

  const isIssued = item.status === RISK_STATUSES.issued.value;
  const isExpanded = expandedTables.includes(id);

  const handleExpandChange = () => {
    isExpanded
      ? setExpandedTables((prevState) => [...prevState.filter((itemId) => itemId !== id)])
      : setExpandedTables((prevState) => [...prevState, id]);
  };

  const renderNoRisksContent = () => {
    const descrText = isRiskEngineer ? 'Click "Add Risk" to add a new risk.' : 'Once they are added, they will appear here.';
    return (
      <NoContent
        title={isIssued ? 'No recommendations were found during the inspection.' : 'There are no recommendations yet.'}
        descr={!isIssued && descrText}
      />
    );
  };

  if (nonCheckableRows) {
    const {
      items: subtableItems,
      columns: subtableColumns,
      onNoContent: subtableNoContent,
      subtableRenderControls,
    } = item;

    return (
      <StyledAccordion
        onChange={handleExpandChange}
        expanded={isExpanded}
      >
        <AccordionSummary expandIcon={expandIcon}>
          <Row>
            {columns.map((column) => (
              <TableCell
                key={column.key}
                item={item}
                column={column}
                checkedItems={EMPTY_ARRAY}
                onRenderControls={onRenderControls}
              />
            ))}
          </Row>
        </AccordionSummary>
        <AccordionDetails>
          <FullWidthWrapper>
            <Table
              isSubtable
              onRowClick={onRowClick}
              onNoContent={subtableNoContent}
              items={subtableItems}
              columns={subtableColumns}
              onRenderControls={subtableRenderControls}
            />
          </FullWidthWrapper>
        </AccordionDetails>
      </StyledAccordion>
    );
  }

  const {
    items: subtableItems,
    columns: subtableColumns,
    onNoContent: subtableNoContent,
    subtableRenderControls,
    checkable,
    setCheckedInCategory,
    checkedCategory,
  } = item;

  const handleCheckItems = (checkItems) => {
    setCheckedItems(checkItems);
    if (setCheckedInCategory) setCheckedInCategory(checkedCategory, [...checkItems]);
  };

  return (
    <Accordion
      onChange={handleExpandChange}
      expanded={isExpanded}
    >
      <AccordionSummary expandIcon={expandIcon}>
        <Row>
          {columns.map((column) => (
            <TableCell
              key={column.key}
              item={item}
              column={column}
              checkedItems={checkedItems}
              onRenderControls={onRenderControls}
            />
          ))}
        </Row>
      </AccordionSummary>
      {item.items.length === 0 && isRecommendations
        ? renderNoRisksContent()
        : (
          <AccordionDetails>
            <FullWidthWrapper>
              <Table
                isSubtable
                checkable={checkable}
                checkedItems={checkedItems}
                onCheckItems={handleCheckItems}
                onRowClick={onRowClick}
                onNoContent={subtableNoContent}
                items={subtableItems}
                columns={subtableColumns}
                onRenderControls={subtableRenderControls}
                clearGlobalChecks={clearGlobalChecks}
              />
            </FullWidthWrapper>
          </AccordionDetails>
        )}
    </Accordion>
  );
};

const AccordionTable = (props) => {
  const { columns, items, isLoading, onNoContent, pagination, onPrevPage, onNextPage, onGoToPage, pageSize } = props;
  const { role } = AuthService.getUser();

  const isRiskEngineer = role === ROLES_IDS.RiskEngineer;

  const fullWidth = columns?.reduce((sum, col) => {
    return sum + Number(col.width.replace('%', ''));
  }, 0);

  if (fullWidth !== 100) {
    if (devMode) {
      console.warn('fullWidth !== 100:', fullWidth); // eslint-disable-line no-console
    }
  }

  function renderBody() {
    if (items.length === 0 && onNoContent) {
      return onNoContent();
    }

    return (
      <>
        <Body>
          {items.map((item, index) => (
            <TableRow
              key={index}
              isRiskEngineer={isRiskEngineer}
              item={item}
              id={item.id || index}
              {...props}
          />
          ))}
        </Body>
        <TableFooter
          pageSize={pageSize}
          pagination={pagination}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          onGoToPage={onGoToPage}
        />
      </>
    );
  }

  return (
    <TableRoot>
      {isLoading
        ? <Spinner />
        : renderBody()
      }
    </TableRoot>
  );
};

export default AccordionTable;
