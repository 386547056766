export const checkChildrensIds = (currentFolder, selectedFolderId) => {
  if (!currentFolder || !selectedFolderId) return false;
  const isCurrentFoldrenChild = currentFolder.childrenFolders.find((item) => item.id === selectedFolderId);

  if (isCurrentFoldrenChild) return true;

  const result = currentFolder.childrenFolders
    .map((item) => checkChildrensIds(item, selectedFolderId)).filter(Boolean);

  return !!result.length;
};

export const getFolderById = (folders, id) => (
  folders.find((folder) => folder.id === id) ||
  folders.map((folder) => getFolderById(folder.childrenFolders, id)).filter(Boolean)[0]
);
