import React from 'react';

import AuthService from 'services/AuthService';

const Warning = () => {
  const theme = AuthService.getTheme();
  const mainColor = theme === 'light' ? '#0A2463' : 'rgba(255, 255, 255, 0.87)';

  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 13.75a.938.938 0 100 1.875.938.938 0 000-1.875zm-.625-6.875h1.25V12.5h-1.25V6.875z" fill={mainColor} fillOpacity=".8" />
      <path d="M18.125 18.125H1.875a.625.625 0 01-.556-.913L9.444 1.587a.625.625 0 011.113 0l8.125 15.625a.625.625 0 01-.557.913zm-15.218-1.25h14.187L10 3.231 2.907 16.875z" fill={mainColor} fillOpacity=".8" />
    </svg>
  );
};

export default Warning;
