import React, { useState, useEffect } from 'react';

import Switch from 'components/Switch';
import { showNotifySuccess } from 'services/toaster';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import { Column, Root, Row, SubTitle, Title } from './styles';
import MyCompanyService from '../../api/MyCompanyService';

const scopeSettings = (settings) => {
  const scopes = [...new Set(settings.map((t) => t.scope))];
  const scopesWithSettings = scopes.map((t) => {
    const settingsByScope = settings.filter((s) => s.scope === t);
    const scope = settings.find((s) => s.scope === t)?.scopeName;
    return {
      type: t,
      name: scope,
      settings: settingsByScope,
    };
  });
  return scopesWithSettings;
};

const Settings = () => {
  const [settings, setSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const scopedSettings = scopeSettings(settings);

  const toggleShowWarning = (value = false) => {
    setShowWarning(value);
  };

  const loadSettings = async () => {
    const { data } = await MyCompanyService.getInsuranceCompanySettings();
    setSettings(data.data);
  };

  const updateSetting = async (setting, newValue) => {
    await MyCompanyService.updateInsuranceCompanySettings({ id: setting.id, value: newValue });
    showNotifySuccess('Setting has been updated');
    const newSettings = settings.map((t) => {
      if (t.id === setting.id) {
        return { ...t, value: newValue };
      }
      return t;
    });
    toggleShowWarning(false);
    setSettings(newSettings);
    setSelectedSetting(null);
  };

  const onChangeSetting = async (setting, value) => {
    if (setting.warningText && value === false) {
      setSelectedSetting({ setting, value });
      toggleShowWarning(true);
    } else {
      await updateSetting(setting, value);
    }
  };
  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <Root>
      {scopedSettings.map((scope) => (
        <Row key={scope.type}>
          <Column>
            <Row key={scope.type}>
              <Title>
                {scope.name}
              </Title>
            </Row>
            {scope.settings.map((setting) => (
              <Row key={setting.id}>
                <Column>
                  <SubTitle>
                    {setting.name}
                  </SubTitle>
                </Column>
                <Column>
                  <Switch value={setting.value} onChange={(event) => onChangeSetting(setting, event.target.checked)} />
                </Column>
              </Row>
            ))}
          </Column>
        </Row>
      ))}

      {showWarning && (
      <ConfirmationDialog
        open
        title={selectedSetting.setting.name}
        confirmButtonTitle="Yes"
        cancelButtonTitle="No"
        onClose={() => toggleShowWarning(false)}
        onCancel={() => toggleShowWarning(false)}
        onConfirm={() => updateSetting(selectedSetting.setting, selectedSetting.value)}
        >
        {selectedSetting.setting.warningText}
        <br />
        Do you want to continue?
      </ConfirmationDialog>
      )}
    </Root>
  );
};

export default Settings;
