import React from 'react';

import AuthService from 'services/AuthService';

const Notification = () => {
  const theme = AuthService.getTheme();
  const mainColor = theme === 'light' ? '#0A2463' : 'rgba(255, 255, 255, 0.87)';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
      <path fill={mainColor} fillOpacity=".8" fillRule="evenodd" d="M19.8 13.887L17.875 12.1V8.938c0-3.576-2.613-6.463-6.188-6.876V.688h-1.374v1.375c-3.438.412-6.188 3.3-6.188 6.874V12.1L2.2 13.887c-.138.138-.275.275-.138.55V16.5c0 .413.275.688.55.688h4.95A3.404 3.404 0 0011 20.625a3.404 3.404 0 003.438-3.438h4.812c.413 0 .688-.274.688-.55v-2.2c0-.137-.138-.412-.138-.55zM11 19.25c-1.1 0-2.063-.962-2.063-2.063h4.126c0 1.1-.963 2.063-2.063 2.063zm7.563-3.438H3.437v-1.1l1.788-1.925c.137 0 .275-.275.275-.412V8.937c0-3.024 2.475-5.5 5.5-5.5s5.5 2.475 5.5 5.5v3.438c0 .137.137.412.137.55l1.925 1.787v1.1z" clipRule="evenodd" />
    </svg>
  );
};

export default Notification;
