import React from 'react';

const Edit = () => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="white" fillOpacity="0.87" fillRule="evenodd" clipRule="evenodd" d="M0 12H14V13H0V12ZM11.7 3.5C12.1 3.1 12.1 2.5 11.7 2.1L9.9 0.3C9.5 -0.1 8.9 -0.1 8.5 0.3L1 7.8V11H4.2L11.7 3.5ZM9.2 1L11 2.8L9.5 4.3L7.7 2.5L9.2 1ZM2 10V8.2L7 3.2L8.8 5L3.8 10H2Z" />
    </svg>
  );
};

export default Edit;
