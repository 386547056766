import React, { useState } from 'react';
import { IconButton, FormControl, FormHelperText, ListItemSecondaryAction } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';

import SelectComponent from 'components/Select/SelectComponent';
import Checkbox from 'components/Checkbox/CheckboxInput';
import Tooltip from 'components/Tooltip';

import {
  StyledInputLabel,
  StyledMenuItem,
  StyledIcon,
} from './styles';

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: -2,
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const EditableSelect = ({
  value = '',
  options,
  required,
  disabled,
  label,
  error,
  readOnly,
  dialogForm,
  dialogFormProps,
  addNewOnly = false,
  projectTypeId,
  onSubmit,
  addBtnText = '+ Add New',
  multiple,
  setValue,
  setTouched,
  setError,
  displayEmpty,
  labelTooltip,
  noClear,
  addNewHandler,
  ...props
}) => {
  const theme = useTheme();

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(false);

  const handleAddModalOpen = () => {
    if (addNewHandler) addNewHandler();
    setAddModalOpen(true);
  };

  const handleAddModalClose = () => setAddModalOpen(false);

  const handleEditModalOpen = (item) => {
    setItemToEdit(item);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => setEditModalOpen(false);

  const renderSelectedValue = (selectedValue) => {
    if (!multiple) {
      const singleValue = options.find((option) => option.value === selectedValue);
      return singleValue ? singleValue.label : displayEmpty;
    }

    const multiValue = options
      .filter((option) => selectedValue.includes(option.value))
      .map((option) => {
        if (typeof option.label === 'string') {
          return option.label;
        }
        return option.label?.text;
      });
    return multiValue.join(', ');
  };

  const DialogForm = dialogForm;

  return (
    <FormControl
      variant="filled"
      fullWidth
      required={required}
      error={!!error}
      disabled={disabled}
    >
      <StyledInputLabel shrink={!!(displayEmpty || value)} error={!!error}>
        {label}
        {labelTooltip && <Tooltip text={labelTooltip} />}
      </StyledInputLabel>

      <SelectComponent
        isEditable
        value={value}
        disableUnderline
        error={!!error}
        readOnly={readOnly}
        MenuProps={menuProps}
        renderValue={renderSelectedValue}
        defaultValue=""
        displayEmpty={!!displayEmpty}
        multiple={multiple}
        setValue={setValue}
        canBeCleared={!disabled && !readOnly}
        noClear={noClear}
        {...props}
      >
        {displayEmpty && (
          <StyledMenuItem
            className={cx(theme.light && 'light-theme')}
            value=""
          >
            {displayEmpty}
          </StyledMenuItem>
        )}

        {options.map((option) => (
          <StyledMenuItem
            className={cx(theme.light && 'light-theme')}
            key={option.value}
            value={option.value}
            multiple={multiple}
            disabled={option.disabled}
          >
            {multiple && addNewOnly && (
              <Checkbox
                checked={value.includes(option.value)}
              />
            )}
            {option.label}
            {!addNewOnly && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => handleEditModalOpen(option)}
                >
                  <StyledIcon
                    className={cx(theme.light && 'light-theme')}
                    icon="edit"
                    noWrapper
                  />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </StyledMenuItem>
        ))}

        <StyledMenuItem
          onClick={handleAddModalOpen}
          className={cx(theme.light && 'light-theme', 'addItem')}
        >
          {addBtnText}
        </StyledMenuItem>
      </SelectComponent>

      {addModalOpen && (
        <DialogForm
          isOpen
          projectTypeId={projectTypeId}
          onCancel={handleAddModalClose}
          onClose={handleAddModalClose}
          onSubmit={onSubmit}
          {...dialogFormProps}
        />
      )}

      {editModalOpen && (
        <DialogForm
          isOpen
          isEdit
          item={itemToEdit}
          onCancel={handleEditModalClose}
          onClose={handleAddModalClose}
          onSubmit={onSubmit}
          {...dialogFormProps}
        />
      )}

      <FormHelperText>
        {error || ' '}
      </FormHelperText>
    </FormControl>
  );
};

export default EditableSelect;
