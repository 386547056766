import { styled as styledMui } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers';

export const StyledDatePicker = styledMui(MobileDatePicker)(({ error, readOnly, theme }) => {
  return {
    '.MuiOutlinedInput-notchedOutline': {
      border: ' none ! important ',
    },
    '.MuiInputBase-root': {
      fontFamily: 'Graphik LC',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.16,
      color: theme.palette.input.text,
      height: 48,
      cursor: 'pointer',
      borderRadius: 4,
      backgroundColor: theme.palette.input.color,
      border: `1px solid ${theme.palette.input.border.color} !important`,
      input: {
        cursor: 'pointer',
      },
      ...(error && { border: `1px solid ${theme.palette.error.main}` }),
      ':hover': {
        backgroundColor: theme.palette.input.hover.color,
      },
      ...(readOnly && {
        border: `1px solid ${theme.palette.input.readonly.border}`,
        backgroundColor: 'transparent',
        pointerEvents: 'none',
      }),
      'MuiOutlinedInput-input': {
        padding: '22px 16px 6px',
      },
    },
    '.MuiInputBase-root.Mui-focused': {
      border: `1px solid ${theme.palette.input.focused.border}`,
    },
    '.MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.input.disabled.color,
      input: {
        cursor: 'auto',
      },
    },
    '.MuiInputLabel-root.Mui-disabled': {
      color: theme.palette.input.disabled.text,
    },
    '.MuiFormLabel-root': {
      fontFamily: 'Graphik LC',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.16,
    },
  };
});
