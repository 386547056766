import TimeAndExpensesService from 'dashboard/api/TimeAndExpensesService';

const DASHBOARD_GET_EXPENSES_LIST = 'DASHBOARD_GET_EXPENSES_LIST';
const DASHBOARD_GET_EXPENSES_FILTERS = 'DASHBOARD_GET_EXPENSES_FILTERS';
const DASHBOARD_EXPENSES_LIST_SORT_BY = 'DASHBOARD_EXPENSES_LIST_SORT_BY';

export const getDashboardTE = (params) => ({
  type: DASHBOARD_GET_EXPENSES_LIST,
  apiCall: () => TimeAndExpensesService.getDashboardTE(params),
});

export const setTESortBy = (sortBy) => ({
  type: DASHBOARD_EXPENSES_LIST_SORT_BY,
  sortBy,
});

export const getFilters = (params) => {
  return ({
    type: DASHBOARD_GET_EXPENSES_FILTERS,
    apiCall: () => TimeAndExpensesService.getDashboardTEFilters(params),
  });
};

const initialState = {
  expenses: {},
  filters: { companyId: '' },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case DASHBOARD_GET_EXPENSES_LIST: {
      return {
        ...state,
        expenses: {
          ...state.expenses,
          ...response,
        },
      };
    }
    case DASHBOARD_GET_EXPENSES_FILTERS: {
      return {
        ...state,
        filters: {
          ...response,
        },
      };
    }
    case DASHBOARD_EXPENSES_LIST_SORT_BY: {
      return {
        ...state,
        expenses: {
          ...state.expenses,
          sortBy: action.sortBy,
        },
      };
    }

    default:
      return state;
  }
};
