import ApiClient from 'services/ApiClient';

class ProjectsService extends ApiClient {
  ignoreProjectHasNoAssignment(projectId) {
    return this.call({
      method: 'put',
      url: `/projects/${projectId}/ignore-notification-project-has-no-assignment`,
    });
  }
}

export default new ProjectsService();
