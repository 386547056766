import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const HeaderPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styledMui('div')(({ theme }) => ({
  margin: 0,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: 24,
  lineHeight: '33px',
  color: theme.palette.text.main,
  whiteSpace: 'nowrap',
  textTransform: 'none',
}));
//
// export const Title = styled.div`
//   margin: 0;
//   font-family: Avenir;
//   font-style: normal;
//   font-weight: 800;
//   font-size: 24px;
//   line-height: 33px;
//   color: var(--titleColor);
//   white-space: nowrap;
//   text-transform: none;
// `;
