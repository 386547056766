import React, { useEffect, useState } from 'react';

import SubscriptionsTable from 'non-subscribers/components/SubscriptionsTable';
import SubscriptionsService from 'non-subscribers/api/SubscriptionsService';
import { getAmountWithCurrencySymbol } from 'utils/CurrencyUtils';

import { Root, Summary, Block, Text, Digits } from '../styles';

const InsuranceRepresentative = ({ searchFilter }) => {
  const [summary, setSummary] = useState({
    activeCount: 0,
    tiv: 0,
  });

  const subscriberType = 'InsuranceRepresentative';

  const fetchSummary = async () => {
    const { data: { data } } = await SubscriptionsService.getSummaryInfo(subscriberType);
    setSummary(data);
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  return (
    <Root>
      <Summary>
        <Block widthPercentage="50%">
          <Text>Total Active Non-Subscribers</Text>
          <Digits>{summary.activeCount}</Digits>
        </Block>
        <Block widthPercentage="50%">
          <Text>TIV</Text>
          <Digits>{getAmountWithCurrencySymbol(summary.tiv, 'USD')}</Digits>
        </Block>
      </Summary>
      <SubscriptionsTable searchFilter={searchFilter} subscriberType={subscriberType} />
    </Root>
  );
};

export default InsuranceRepresentative;
