import styled from 'styled-components';

export const ScrollBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 12px;
  /* for scroll to start of all page not only header */
  margin-right: 220px;
  padding-top: 245px; 
  margin-top: -245px;
`;

export const ContactUsPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 12px;
`;

export const HeaderPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 12px;
`;

export const Title = styled.div`
  margin: 0;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: var(--titleColor);
  white-space: nowrap;
  text-transform: none;
`;

export const Text = styled.div`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 138.5%;
  color: var(--lightTextColor);
  white-space: nowrap;
  text-transform: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 138.5%;
  color: var(--titleColor);
  white-space: nowrap;
  text-transform: none;

  > div {
    margin-left: 20px;
  }
`;

export const Root = styled.div`
  display: flex;
  min-width: 1000px;
`;
