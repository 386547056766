import React from 'react';

const ChevronUp = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6.25l6.25 6.25-.875.875L10 8l-5.375 5.375-.875-.875L10 6.25z" fill="currentColor" fillOpacity=".87" />
    </svg>
  );
};

export default ChevronUp;
