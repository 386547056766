import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const InputWrapper = styledMui('div')(({ theme, disabled, isLight }) => ({
  '.MuiCheckbox-root': {
    padding: 0,
    width: 32,
    height: 32,
    color: isLight ? 'rgba(244, 245, 250, 0.7)' : theme.palette.input.checkbox.color,
  },
  '.Mui-checked': {
    color: theme.palette.sidebar.selected.icon.background,
  },
  '.Mui-disabled': {
    color: theme.palette.navbar.tab.label.disabled,
  },
  ...(disabled && { pointerEvents: 'none' }),
}));

export const Wrapper = styledMui('div')(({ theme, disabled, isWhite, isLight }) => ({
  position: 'relative',
  color: theme.palette.icon.primary.color,
  '.MuiCheckbox-root': {
    padding: 0,
    width: 32,
    height: 32,
    color: isLight ? 'rgba(244, 245, 250, 0.7)' : theme.palette.input.checkbox.color,
  },
  '.Mui-checked': {
    color: theme.palette.sidebar.selected.icon.background,
  },
  '.Mui-disabled': {
    color: theme.palette.navbar.tab.label.disabled,
  },
  ...(disabled && { pointerEvents: 'none' }),
  ...(isWhite && { color: '#FFFFFF' }),
}));

export const Text = styledMui('div')(({ theme, isWhite, disabled }) => ({
  flex: '1 0',
  marginTop: 2,
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '24px',
  ...(isWhite && { color: '#FFFFFF' }),
  ...(disabled && { color: theme.palette.border.light }),
}));

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: -11px;

  ${({ disabled }) => disabled && `
    cursor: default;
  `}
`;
