import React from 'react';

const Video = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.125 16.25H2.5A1.25 1.25 0 011.25 15V5A1.25 1.25 0 012.5 3.75h10.625A1.25 1.25 0 0114.375 5v2.537l3.387-2.418a.625.625 0 01.988.506v8.75a.625.625 0 01-.988.506l-3.387-2.418V15a1.25 1.25 0 01-1.25 1.25zM2.5 5v10h10.625v-3.75a.625.625 0 01.988-.506l3.387 2.418V6.837l-3.387 2.42a.625.625 0 01-.988-.507V5H2.5z" fill="#96CEF6" />
    </svg>
  );
};

export default Video;
