import React, { useState } from 'react';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import MyCompanyService from 'myCompany/api/MyCompanyService';
import { Input } from 'components';

import { Text, HighlitedText, InputWrapper, StyledTooltip } from './styles';

const TOOLTIP_TEXT = 'Removing Insurance Company is a strong action and we want to ensure that this action is not performed accidentally as the data cannot be restored. Please type the name of the company that you are trying to delete to confirm the action.';

const DeleteICSubscriptionModal = ({ onSuccess, onCancel, company = {} }) => {
  const [confirmationInput, setConfirmationInput] = useState('');
  const [error, setError] = useState(null);

  const handleDeleteConfirm = async () => {
    if (company.name !== confirmationInput) {
      setError('Insurance Company Name is incorrect.');
      return;
    }

    try {
      await MyCompanyService.deleteInsuranceCompanyById(company.id);
      showNotifySuccess('The insurance company was successfully deleted. The notification email was sent to the primary contact.');
      onCancel();
      onSuccess();
    } catch (err) {
      showNotifyError(err);
    }
  };

  const handleInputChange = ({ target }) => {
    if (error) setError(null);
    setConfirmationInput(target.value);
  };

  return (
    <ConfirmationDialog
      open
      title="Delete Insurance Company"
      confirmButtonTitle="Delete Forever"
      onClose={onCancel}
      onCancel={onCancel}
      onConfirm={handleDeleteConfirm}
    >
      <Text>
        Are you sure you want to delete
        <HighlitedText>{company.name}</HighlitedText>
        insurance company?
        Note that once the company is deleted, all its internal users and projects are
        deleted as well if they exist. If you continue, it will be deleted immediately,
        you can’t undo this action.
      </Text>
      <Text>
        Confirm by typing
        <HighlitedText>{company.name}</HighlitedText>
        below.
        <StyledTooltip text={TOOLTIP_TEXT} />
      </Text>
      <InputWrapper>
        <Input
          label={company.name}
          value={confirmationInput}
          onChange={handleInputChange}
          error={error}
        />
      </InputWrapper>
    </ConfirmationDialog>
  );
};

export default DeleteICSubscriptionModal;
