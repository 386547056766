import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const ToastRoot = styled.div`
  position: relative;
`;

export const Title = styledMui('div')(({ theme, type }) => ({
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: 14,
  lineHeight: '19px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 6,
  ...(type === 'success' && {
    color: theme.palette.toast.success,
  }),
  ...(type === 'alert' && {
    color: theme.palette.toast.alert,
  }),
  ...(type === 'error' && {
    color: theme.palette.toast.error,
  }),
  ...(type === 'info' && {
    color: theme.palette.toast.info,
  }),
}));

export const Message = styled.div`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
`;
