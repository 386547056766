import styled from 'styled-components';

export const IconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  pointer-events: auto;
`;

export const TooltipText = styled.div`
  white-space: pre-wrap;
`;
