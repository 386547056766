export function teamMembersData({ teamMembers }) {
  return teamMembers.teamMembersData;
}

export function membersSortBy({ teamMembers }) {
  return teamMembers.sortBy;
}

export function currentPage({ teamMembers }) {
  return teamMembers.currentPage;
}

export function teamMembersList({ teamMembers }) {
  return teamMembers.teamMembersData.items || [];
}
