import AssignmentsService from 'dashboard/api/AssignmentsService';

const DASHBOARD_GET_RESOURCES_LIST = 'DASHBOARD_GET_RESOURCES_LIST';
const DASHBOARD_RESOURCES_LIST_SORT_BY = 'DASHBOARD_RESOURCES_LIST_SORT_BY';

export const getDashboardResources = (params) => ({
  type: DASHBOARD_GET_RESOURCES_LIST,
  apiCall: () => AssignmentsService.getDashboardResources(params),
});

export const getDashboardREResources = (params) => ({
  type: DASHBOARD_GET_RESOURCES_LIST,
  apiCall: () => AssignmentsService.getDashboardREResources(params),
});

export const setResourcesSortBy = (sortBy) => ({
  type: DASHBOARD_RESOURCES_LIST_SORT_BY,
  sortBy,
});

const initialState = {
  resources: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case DASHBOARD_GET_RESOURCES_LIST: {
      return {
        ...state,
        resources: {
          ...state.resources,
          ...response,
        },
      };
    }
    case DASHBOARD_RESOURCES_LIST_SORT_BY: {
      return {
        ...state,
        resources: {
          ...state.resources,
          sortBy: action.sortBy,
        },
      };
    }

    default:
      return state;
  }
};
