import styled from 'styled-components';

export const ButtonText = styled.div`
  margin-left: 5px;
  margin-right: 8px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--navTabFillActive);
`;

export const Counter = styled.div`
  min-width: 21px;
  height: 14px;
  padding: 2px 8px 0;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 10px;
  color: var(--counterTextColor);

  border-radius: 10px;
  background: var(--navTabFillActive);
`;
