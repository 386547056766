import React from 'react';

import Icon from 'components/Icon';
import { StyledSelect as EditableStyledSelect } from 'components/EditableSelect/styles';

import { StyledSelect, CloseButton } from './styles';

const SelectComponent = (props) => {
  const { children, setValue, canBeCleared, noClear, isEditable, ...selectProps } = props;

  const clearValue = () => {
    if (setValue) {
      const noValue = selectProps.multiple ? [] : '';
      setValue(noValue);
    }
  };

  const Select = isEditable ? EditableStyledSelect : StyledSelect;
  const valueExists = selectProps.multiple ? selectProps.value.length > 0 : selectProps.value;
  const clearAllowed = !noClear && canBeCleared && valueExists;

  return (
    <>
      <Select {...selectProps}>
        {children}
      </Select>
      {clearAllowed && (
        <CloseButton onClick={clearValue}>
          <Icon icon="crossSmall" noWrapper />
        </CloseButton>
      )}
    </>
  );
};

export default SelectComponent;
