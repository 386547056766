import ApiClient from 'services/ApiClient';
import qs from 'qs';

class AnalyticService extends ApiClient {
  getProjects(params) {
    return this.call({
      method: 'get',
      url: `/dashboard/analytics/projects?${qs.stringify(params)}`,
    });
  }

  getCountriesWithTiv(params) {
    return this.call({
      method: 'get',
      url: `/dashboard/analytics/countries?${qs.stringify(params)}`,
    });
  }

  getFilters(params) {
    return this.call({
      method: 'get',
      url: `/dashboard/analytics/filters?${qs.stringify(params)}`,
    });
  }

  getCharts(params) {
    return this.call({
      method: 'get',
      url: `/dashboard/charts?${qs.stringify(params)}`,
    });
  }
}

export default new AnalyticService();
