import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';

import { Title, TextSection, List } from '../../styles';

const imageSrc = 'my-company-info.png';

const CompanyInfo = () => (
  <>
    <ImageContainer src={imageSrc} alt="company info" />
    <Title>Overview</Title>
    <TextSection>
      The section allows you to manage the contact details of your company,
      upload the logo and select the primary contact of your company.
    </TextSection>
    <Title>Contact Details</Title>
    <TextSection>
      The contact details of your company contains the
      following data and are visible for the users who work with you on the projects:
    </TextSection>
    <List>
      <li>Company Name</li>
      <li>Country, State or Region, City, Address, Zip Code</li>
      <li>Phone Number</li>
    </List>
    <TextSection>
      These contacts details are populated upon the registration and can be updated without any restrictions
      in the future. When your company details are updated,
      they are updated for all the projects your company is involved into.
    </TextSection>
    <Title>Logo</Title>
    <TextSection>
      Your company logo can be optionally uploaded to the portal. If uploaded, that logo will be used in the
      reports that are generated by the Risk Engineers from your company. If the logo is not uploaded,
      no logo will be used in the reports.
    </TextSection>
    <Title>Primary Contact</Title>
    <TextSection>
      The primary contact is the main portal administrator of your company which can be selected from
      users with ‘Coordinator’ system role only. The primary contact email is the identifier of your
      company that be used by the other users or companies to find your company. The contact details of the
      primary contact is the contact information that will be used for solving any
      questions that are related to your company.
    </TextSection>
    <RedirectButton redirectTitle="Team Members" redirectPath="team-members" />
  </>
);

export default CompanyInfo;
