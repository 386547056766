import React from 'react';

const Congrats = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="73" height="75" fill="none">
    <path fill="#96CEF6" d="M27.8 22.88a1.04 1.04 0 011.96-.74c1.42 3.78 4.66 8.21 9.73 13.29 5.07 5.07 9.5 8.3 13.28 9.72a1.04 1.04 0 01-.73 1.95c-4.1-1.53-8.77-4.94-14.03-10.2-5.26-5.26-8.66-9.92-10.2-14.02z" />
    <path fill="#96CEF6" d="M15.14 59.77l36.33-14.4c.1-2.33-2.87-6.74-9.03-12.9-6.16-6.15-10.57-9.13-12.9-9.03l-14.4 36.33zM28.51 21.5c3.23-.88 8.23 2.33 15.4 9.5 7.18 7.17 10.38 12.17 9.5 15.4-.09.32-.32.58-.62.7L13.67 62.6c-.85.33-1.7-.5-1.36-1.36l15.5-39.12c.13-.3.38-.53.7-.62zM63.52 17.4a1.04 1.04 0 01.27 2.06 21 21 0 00-9.14 3.52 1.04 1.04 0 11-1.18-1.73 23.1 23.1 0 0110.05-3.85z" />
    <path fill="#96CEF6" d="M55.7 34.1a1.04 1.04 0 11.26 2.07 21 21 0 00-9.14 3.5 1.04 1.04 0 01-1.18-1.72 23.1 23.1 0 0110.05-3.85zM37.05 27.8a1.04 1.04 0 01-1.6-1.34 29.36 29.36 0 006.87-18.92 1.04 1.04 0 112.09 0c0 7.51-2.63 14.62-7.36 20.26zM21.23 59.45l-1.9-.88a11.43 11.43 0 00-1.02-11.46l1.7-1.2a13.51 13.51 0 011.22 13.54zM27.47 56.87l-1.89-.89a16.9 16.9 0 00-3.03-18.86l1.52-1.43a18.98 18.98 0 013.4 21.18zM32.18 55.18l-1.7-1.23a22.07 22.07 0 004.22-13.01c0-4.18-1.15-8.18-3.3-11.65l1.77-1.1a24.16 24.16 0 013.62 12.75c0 5.19-1.63 10.13-4.61 14.24zM41.37 51.52l-1.78-1.09c1.8-2.94 2.9-6.26 3.18-9.74l2.08.17a24.13 24.13 0 01-3.48 10.66zM30.98 35.25a1.04 1.04 0 01-1.87.94 22.05 22.05 0 00-3.63-5.24A1.04 1.04 0 0127 29.52a24.14 24.14 0 013.98 5.73z" />
    <path fill="#96CEF6" d="M31.36 40.42a1.04 1.04 0 100-2.09 1.04 1.04 0 000 2.09zM33.19 17.2a3.4 3.4 0 110-6.8 3.4 3.4 0 010 6.8zm0-2.1a1.3 1.3 0 100-2.6 1.3 1.3 0 000 2.6zM54.59 13.02a3.4 3.4 0 110-6.79 3.4 3.4 0 010 6.79zm0-2.09a1.3 1.3 0 100-2.6 1.3 1.3 0 000 2.6zM61.9 32.33a3.4 3.4 0 110-6.79 3.4 3.4 0 010 6.79zm0-2.09a1.3 1.3 0 100-2.6 1.3 1.3 0 000 2.6zM46.5 23.46a1.04 1.04 0 012.09 0v4.17a1.04 1.04 0 11-2.1 0v-4.17z" />
    <path fill="#96CEF6" d="M45.45 26.59a1.04 1.04 0 110-2.09h4.18a1.04 1.04 0 010 2.09h-4.18zM57.98 52.16a1.04 1.04 0 112.09 0v4.18a1.04 1.04 0 01-2.09 0v-4.18z" />
    <path fill="#96CEF6" d="M56.94 55.3a1.04 1.04 0 110-2.1h4.17a1.04 1.04 0 010 2.1h-4.17zM20.4 12.5a1.04 1.04 0 012.09 0v4.17a1.04 1.04 0 11-2.09 0V12.5z" />
    <path fill="#96CEF6" d="M19.36 15.63a1.04 1.04 0 010-2.09h4.17a1.04 1.04 0 010 2.09h-4.17zM60.07 40.68a1.04 1.04 0 100-2.09 1.04 1.04 0 000 2.09zM63.72 49.03a1.04 1.04 0 100-2.09 1.04 1.04 0 000 2.09zM48.58 17.2a1.04 1.04 0 100-2.1 1.04 1.04 0 000 2.1zM67.37 19.8a1.04 1.04 0 100-2.08 1.04 1.04 0 000 2.08zM26.66 9.89a1.04 1.04 0 100-2.09 1.04 1.04 0 000 2.09z" />
  </svg>
);

export default Congrats;
