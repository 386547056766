import React, { useState, Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';

import IconButton from 'components/buttons/IconButton';

import { StyledMenu } from './styles';

const menuAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};
const menuTransformOrigin = {
  vertical: -5,
  horizontal: 'right',
};

const Menu = ({
  children,
  dotsIcon,
  component,
  onOpen,
  onClose,
  noActions,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpened = !!anchorEl;

  function handleOpenMenu(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (onOpen) onOpen();
  }

  function handleCloseMenu(event) {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
    if (onClose) onClose();
  }

  function renderMenuItems() {
    return React.Children.map(children, (child) => {
      if (!child) return child; // skip empty children

      // overriding original MenuItem handler with additional functionality
      const handleClickMenuItem = (event) => {
        event.stopPropagation();
        handleCloseMenu();
        const { onClick } = child.props;
        if (onClick) onClick();
      };
      return React.cloneElement(child, { onClick: handleClickMenuItem });
    });
  }

  const DotsIcon = dotsIcon || IconButton;

  return (
    <>
      <div onClick={handleOpenMenu}>
        {component || <DotsIcon transparent icon="threeDots" />}
      </div>
      <StyledMenu
        className={cx(theme.light && 'light-theme')}
        open={menuOpened}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={menuAnchorOrigin}
        transformOrigin={menuTransformOrigin}
        hidden={noActions}
      >
        {renderMenuItems()}
      </StyledMenu>
    </>
  );
};

export default Menu;
