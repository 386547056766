import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { Icon } from 'components';

export const InfoCardWrapper = styledMui('div')(({ theme, fullWidth, withBottomMargin }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '18px 20px 10px',
  border: `1px solid ${theme.palette.border.light}`,
  boxSizing: 'border-box',
  borderRadius: 4,
  width: fullWidth ? '100%' : 405,
  marginBottom: withBottomMargin ? 40 : 0,
  '& > div': {
    margin: '0 auto 8px 0',
  },
}));

export const ContactRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ContactName = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '138.5%',
  color: theme.palette.text.main,
}));

export const ContactInfoText = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '138.5%',
  color: theme.palette.text.main,
  opacity: 0.9,
}));

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  marginRight: 9,
  svg: {
    path: {
      fill: theme.palette.icon.primary.color,
    },
  },
}));
