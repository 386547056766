import React from 'react';

import { StyledButton, ButtonText, StyledIcon } from './styles';

const FilterButton = ({ opened, disabled, onClick, lighter }) => {
  const chevronIcon = opened ? 'chevronUp' : 'chevronDown';
  return (
    <StyledButton
      small
      opened={opened}
      disabled={disabled}
      onClick={onClick}
      lighter={lighter}
    >
      <StyledIcon icon="filter" noWrapper />
      <ButtonText>
        Filters
      </ButtonText>
      <StyledIcon icon={chevronIcon} noWrapper />
    </StyledButton>
  );
};

export default FilterButton;
