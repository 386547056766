import React from 'react';

const CheckMarkRound = () => {
  return (
    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5.938a6.562 6.562 0 100 13.124A6.562 6.562 0 007.5.938zm0 12.187a5.625 5.625 0 110-11.25 5.625 5.625 0 010 11.25z" fill="#96CEF6" />
      <path d="M6.563 10.078L4.219 7.753l.745-.736 1.598 1.585 3.474-3.446.745.74-4.219 4.182z" fill="#96CEF6" />
    </svg>
  );
};

export default CheckMarkRound;
