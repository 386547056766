import React from 'react';

const CrossSmall = () => {
  return (
    <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 1.288L8.713.5 5 4.213 1.288.5.5 1.288 4.213 5 .5 8.713l.788.787L5 5.787 8.713 9.5l.787-.787L5.787 5 9.5 1.288z" fill="currentColor" fillOpacity=".6" />
    </svg>
  );
};

export default CrossSmall;
