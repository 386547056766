import React from 'react';

const Company = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
    <path fill="#fff" fillOpacity=".68" d="M4.375 3.5H3.5v1.75h.875V3.5zM4.375 6.125H3.5v1.75h.875v-1.75zM7 3.5h-.875v1.75H7V3.5zM7 6.125h-.875v1.75H7v-1.75zM4.375 8.75H3.5v1.75h.875V8.75zM7 8.75h-.875v1.75H7V8.75z" />
    <path fill="#fff" fillOpacity=".68" d="M13.125 6.125a.875.875 0 00-.875-.875H9.625v-3.5A.875.875 0 008.75.875h-7a.875.875 0 00-.875.875v11.375h12.25v-7zM1.75 1.75h7v10.5h-7V1.75zm7.875 10.5V6.125h2.625v6.125H9.625z" />
  </svg>
);

export default Company;
