import CommunicationService from 'communication/api/CommunicationService';

const ADD_SECTION = 'ADD_SECTION';
const GET_SECTION = 'GET_SECTION';
const UPDATE_SECTION = 'UPDATE_SECTION';
const DELETE_SECTION = 'DELETE_SECTION';

export const addCommunicationSection = (params) => ({
  type: ADD_SECTION,
  apiCall: () => CommunicationService.addCommunicationSection(params),
});

export const getSection = (params) => ({
  type: GET_SECTION,
  apiCall: () => CommunicationService.getSection(params),
});

export const updateSection = (params) => ({
  type: UPDATE_SECTION,
  apiCall: () => CommunicationService.updateSection(params),
});

export const deleteSection = (params) => ({
  type: DELETE_SECTION,
  apiCall: () => CommunicationService.deleteCommunicationSection(params),
});

const initialState = {
  current: {},
  sortBy: null,
  sectionList: {
    items: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
