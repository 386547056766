import React from 'react';

const Complete = () => {
  return (
    <svg width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.875 3.188a1.062 1.062 0 00-1.063-1.063h-2.124V1.062h-1.063v1.063h-4.25V1.062H5.312v1.063H3.188a1.062 1.062 0 00-1.062 1.063v10.624a1.063 1.063 0 001.063 1.063h2.124v-1.063H3.188V3.188h2.126V4.25h1.062V3.187h4.25V4.25h1.063V3.187h2.124v3.188h1.063V3.187z" fill="#fff" fillOpacity=".87" />
      <path d="M10.094 13.595l-1.907-1.908-.75.75 2.657 2.656 4.781-4.781-.75-.75-4.031 4.033z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Complete;
