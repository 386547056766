import styled from 'styled-components';

export const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--textColor);
  svg path {
    fill: ${({ status }) => (status === 'accepted' ? 'var(--success)' : 'var(--error)')}
  }
  
`;
