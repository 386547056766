import React from 'react';

import { FormControlLabel, FormControl, RadioGroup } from '@mui/material';

import Tooltip from 'components/Tooltip';

import { RowWrapper, StyledFormLabel, StyledRadio, StyledRadioLabel } from './styles';

const UncheckedIcon = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7.5" stroke="#fff" strokeOpacity=".87" />
  </svg>
);

const CheckedIcon = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12a4 4 0 100-8 4 4 0 000 8z" fill="#96CEF6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-1 0A7 7 0 111 8a7 7 0 0114 0z" fill="#96CEF6" />
  </svg>
);

export const RadioBtn = (props) => (
  <StyledRadio
    {...props}
    color="primary"
    icon={<UncheckedIcon />}
    checkedIcon={<CheckedIcon />}
  />
);

const RadioButtonGroup = ({ label, options, value, setValue, disabled }) => {
  const handleChange = (event) => {
    if (setValue) {
      setValue(event.target.value);
    }
  };

  return (
    <FormControl component="fieldset">
      <RowWrapper>
        {label && (
          <StyledFormLabel>{label}:</StyledFormLabel>
        )}
        <RadioGroup
          defaultValue={options[0].value}
          row
          aria-label={label}
          name={label}
          onChange={handleChange}
          value={value}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<RadioBtn />}
              label={(
                <StyledRadioLabel>
                  {option.label}
                  {option.tooltip && <Tooltip text={option.tooltip} />}
                </StyledRadioLabel>
              )}
              disabled={disabled || option.disabled}
            />
          ))}
        </RadioGroup>
      </RowWrapper>
    </FormControl>
  );
};

export default RadioButtonGroup;
