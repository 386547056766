import React from 'react';

const Plus = () => {
  return (
    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="white" stroke="white" fillOpacity=".87" strokeWidth=".3" d="M8.07 1.264v-.15H6.658v5.25h-5.25v1.414h5.25v5.25h1.414v-5.25h5.25V6.364h-5.25v-5.1z" />
    </svg>
  );
};

export default Plus;
