import React from 'react';

const Claims = () => {
  return (
    <svg width="27" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.087 16.641l-2.445-2.446a.815.815 0 00-1.153 0l-7.576 7.576v3.599h3.599l7.575-7.576a.816.816 0 000-1.153zm-8.25 7.098h-1.293v-1.293l4.076-4.076 1.293 1.293-4.077 4.076zm5.228-5.229l-1.293-1.293 1.293-1.292 1.293 1.292-1.293 1.293zM7.717 13.957h3.261v1.63h-3.26v-1.63zM7.717 10.696h4.892v1.63H7.717v-1.63zM7.717 7.435h8.153v1.63H7.717v-1.63z" fill="#fff" fillOpacity=".87" />
      <path d="M10.652 25.37H5.761a1.632 1.632 0 01-1.63-1.63V4.173a1.632 1.632 0 011.63-1.63h13.043a1.632 1.632 0 011.63 1.63v8.152h-1.63V4.174H5.761v19.565h4.891v1.63z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Claims;
