import styled from 'styled-components';

export const RateDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0 0 24px;

  > div:not(:last-of-type) {
    margin-right: 24px;
  }
`;
