import React from 'react';

import { Tooltip as MuiTooltip } from '@mui/material';

import Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import { StyledButton, Wrapper, TooltipBlock } from './styles';

const spinnerStyles = { width: 20, height: 20, margin: 0 };

const IconButton = ({
  disabled, disabledTooltip, icon, color, label, onClick,
  transparent, flipHorizontal, rotateLeft, rotateRight, noWrapper,
  inProgress,
  ...rest
}) => {
  const renderIcon = () => {
    if (inProgress) {
      return <Spinner style={spinnerStyles} size={20} />;
    }
    return <Icon icon={icon} noWrapper />;
  };
  return (
    <>
      <StyledButton
        disabled={disabled}
        aria-label={label}
        color={color}
        onClick={onClick}
        transparent={transparent ? 1: undefined}
        $noWrapper={noWrapper ? 1 : undefined}
        {...rest}
      >
        <Wrapper
          disabled={disabled}
          $flipHorizontal={flipHorizontal ? 1 : undefined}
          rotateLeft={rotateLeft ? 1 : undefined}
          rotateRight={rotateRight ? 1 : undefined}
        >
          {renderIcon()}
        </Wrapper>
      </StyledButton>
      {disabled && disabledTooltip && (
        <MuiTooltip arrow title={disabledTooltip}>
          <TooltipBlock />
        </MuiTooltip>
      )}
    </>
  );
};

export default IconButton;
