import styled from 'styled-components';

export const CommunicationsListRoot = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 576px;
`;

export const StatusTag = styled.div`
  max-width: 380px;
  padding: 4px 12px;
  border-radius: 4px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  background: #F6DB81;
  display:inline-block;
  margin: 5px 5px 5px 5px;
  text-align: center;
  vertical-align: middle;
  color:black;
`;

export const ControlsWrapper = styled.div`
  position: relative;
  display: flex;
`;
