import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const PasswordExpirationReminder = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: { expireInDays } } = notify;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToUpdatePassword = async () => {
    handleClose();
    closeNotificationList();
    navigate('/my-account?tab=UpdatePassword');
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      title="Password Expiration Reminder"
      cancelButtonTitle="Close"
      confirmButtonTitle="Update Password"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToUpdatePassword}
    >
      <ConfirmationText
        value={[expireInDays]}
        text="Your password will expire in {placeholder} days. Please go to your account and update the password."
      />
    </ConfirmationDialog>
  );
};

export default PasswordExpirationReminder;
