import React from 'react';

const ReportNotes = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 13.5h-6V15h6v-1.5zM16.5 9.75h-9v1.5h9v-1.5zM11.25 17.25H7.5v1.5h3.75v-1.5z" fill="#fff" fillOpacity=".87" />
      <path d="M18.75 3.75H16.5V3A1.5 1.5 0 0015 1.5H9A1.5 1.5 0 007.5 3v.75H5.25a1.5 1.5 0 00-1.5 1.5V21a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5zM9 3h6v3H9V3zm9.75 18H5.25V5.25H7.5V7.5h9V5.25h2.25V21z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default ReportNotes;
