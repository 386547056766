import ApiClient from 'services/ApiClient';

class DashboardService extends ApiClient {
  getDashboardSummary() {
    return this.call({
      method: 'get',
      url: '/dashboard/summary',
    });
  }

  getDashboardCharts() {
    return this.call({
      method: 'get',
      url: '/dashboard/charts',
    });
  }
}

export default new DashboardService();
