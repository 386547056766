import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";

import { IconButton } from "components";

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 74px;
  margin: 0 24px 0 36px;
  padding: 16px 0;
`;

export const StyledRightArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 74px;

  > button {
    margin-right: 20px;
  }
`;

export const StyledIconButton = styledMui(IconButton)(({ theme }) => {
  return {
    width: 36,
    height: 36,
    background: `${theme.palette.background.default} !important`,
    borderRadius: 4,

    "& svg > path": {
      fill: `${theme.palette.sidebar.selected.icon.background} !important`,
    },
  };
});
