import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showNotifyError, showNotifySuccess, showNotifyInfo } from 'services/toaster';

import * as SubscribersActions from 'subscriptions/store/subscriptions.reducer';
import { subscribersList, subscribersSortBy } from 'subscriptions/store/subscriptions.selectors';
import { differenceInDays, formatPolicyDate } from 'utils/DateUtils';
import { SUBSCRIPTION_STATUSES, renderStatusLabelByValue, CONTACT_STATUSES } from 'utils/constants';
import { subscribersTypePaths, getPackageName } from 'subscriptions/constants';
import { Table, NoContent } from 'components';

import SubscriptionsControls from './SubscriptionsControls';

import { StatusTag, InviteStatusTag } from './styles';
import AccordionTable from '../../../../components/AccordionTable';
import SubscriptionsService from '../../api/SubscriptionsService';

const renderStatusTitle = (status) => SUBSCRIPTION_STATUSES[status].label;

const getPeriodName = (period) => {
  switch (period) {
    case 'ThreeMonths': return '3 Months';
    case 'SixMonths': return '6 Months';
    case 'Year': return '1 Year';
    default: return '1 Month';
  }
};

const getColumns = (isCompany) => [
  {
    width: '14%', key: 'name', title: isCompany ? 'Company Name' : 'Name',
  },
  {
    width: '12%', key: 'status', title: 'Status', noSort: true,
    render: ({ status }) => status && (
      <StatusTag status={status}>
        {renderStatusTitle(status)}
      </StatusTag>
    ),
  },
  {
    width: '11%', key: 'package', title: 'Package', noSort: true,
    render: ({ package: packageType }) => getPackageName(packageType),
  },
  {
    width: '11%', key: 'type', title: 'Type', noSort: true,
  },
  {
    width: '11%', key: 'period', title: 'Period', noSort: true,
    render: ({ period }) => period && getPeriodName(period),
  },
  {
    width: '12%', key: 'startDate', title: 'Start Date', noSort: true,
    render: ({ startDate }) => startDate && formatPolicyDate(startDate),
  },
  {
    width: '12%', key: 'endDate', title: 'End Date', noSort: true,
    render: ({ endDate }) => endDate && formatPolicyDate(endDate),
  },
  {
    width: '11%', key: 'expiresIn', title: 'Expires In', noSort: true,
    render: ({ endDate, status }) => (
      status === SUBSCRIPTION_STATUSES.Active.value && differenceInDays(new Date(), endDate)
    ),
  },
  {
    width: '6%', key: 'controls', title: '', noSort: true, align: 'right', minWidth: '90px',
  },
];

const columns = [
  {
    width: '20%', key: 'name', title: 'Name', noSort: true,
  },
  {
    width: '20%', key: 'email', title: 'Email', noSort: true,
  },
  {
    width: '20%', key: 'role', title: 'Role', noSort: true,
  },
  {
    width: '34%', key: 'inviteStatus', title: 'Invite Status', noSort: true,
    render: ({ inviteStatus }) => (
      <InviteStatusTag status={inviteStatus}>
        {renderStatusLabelByValue(inviteStatus, CONTACT_STATUSES)}
      </InviteStatusTag>
    ),
  },
  {
    width: '6%', key: 'controls', title: '', noSort: true, align: 'right', minWidth: '90px',
  },
];

const SubscriptionsTable = ({ searchFilter, isCompany, subscriberType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const subscribers = useSelector(subscribersList);
  const sortBy = useSelector(subscribersSortBy);

  const handleSortBy = (newSortBy) => {
    dispatch(SubscribersActions.setSubscribersSortBy(newSortBy));
  };

  const handlePrevPageClick = () => {
    setPage(page - 1);
  };

  const handleNextPageClick = () => {
    setPage(page + 1);
  };

  const handleGoToPage = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleDownloadSubscriberData = async (id, companyName) => {
    try {
      showNotifyInfo('Processing has started');
      const res = await SubscriptionsService.downloadData(id);
      showNotifySuccess('Download is ready');
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `iMitig8_${companyName}_data.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      showNotifyError('Error downloading data. ');
    }
  };

  const viewSubscription = (value, icInviteStatus) => {
    const id = value.id || value;
    icInviteStatus === 'Sent'
      ? navigate(`/subscriptions/view/invited-company/${value}`)
      : navigate(`/subscriptions/view/${subscribersTypePaths[subscriberType].path}/${id}`);
  };

  const editSubscription = (value) => {
    const id = value.id || value;
    navigate(`/subscriptions/edit/${subscribersTypePaths[subscriberType].path}/${id}`);
  };

  const getSubscriptions = () => {
    dispatch(SubscribersActions.getSubscribersList({ page, sortBy, searchFilter, subscriberType }));
  };

  const renderControls = (company) => {
    if (subscriberType !== 'InsuranceCompany' && subscriberType !== 'RiskCompany') return null;
    return (
      <SubscriptionsControls company={company} onSuccess={getSubscriptions}
        viewSubscription={viewSubscription} editSubscription={editSubscription}
        isIC={subscriberType === 'InsuranceCompany'} downloadData={handleDownloadSubscriberData} />
    );
  };

  useEffect(() => {
    setPage(1);
  }, [searchFilter]);

  useEffect(() => {
    getSubscriptions();
  }, [page, sortBy, searchFilter]);

  const renderNoContent = () => {
    if (searchFilter) {
      return (
        <NoContent
          customIcon="subscriptions"
          title="No results found."
          descr="Try adjust your search to find what you are looking for."
        />
      );
    }
    return (
      <NoContent
        customIcon="subscriptions"
        title="There are no subscribers yet."
        descr="Once they are added, they will appear here."
      />
    );
  };

  const { isLoading, items = [], totalPages, totalCount } = subscribers;

  const mappedItems = items.map((item) => ({ ...item.subscriptionInfo, ...item, columns, items: item.items ?? [] }));

  const [expandedTables, setExpandedTables] = useState([]);

  return isCompany ? (
    <AccordionTable
      nonCheckableRows
      items={mappedItems}
      columns={getColumns(isCompany)}
      isLoading={isLoading}
      onRowClick={viewSubscription}
      onNoContent={renderNoContent}
      onRenderControls={renderControls}
      setExpandedTables={setExpandedTables}
      expandedTables={expandedTables}
      pagination={{
        page,
        totalPages,
        itemsCount: items.length,
        totalCount,
      }}
      onPrevPage={handlePrevPageClick}
      onNextPage={handleNextPageClick}
      onGoToPage={handleGoToPage}
    />
  ) : (
    <Table
      items={mappedItems}
      columns={getColumns(isCompany)}
      sortBy={sortBy}
      isLoading={isLoading}
      onRowClick={viewSubscription}
      onSortBy={handleSortBy}
      onNoContent={renderNoContent}
      pagination={{
        page,
        totalPages,
        itemsCount: items.length,
        totalCount,
      }}
      onPrevPage={handlePrevPageClick}
      onNextPage={handleNextPageClick}
      onGoToPage={handleGoToPage}
      onRenderControls={renderControls}
    />
  );
};

export default SubscriptionsTable;
