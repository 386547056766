import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { subscribersTypePaths, subscriberTypeOptions } from 'subscriptions/constants';
import { Button, Spinner } from 'components';

import AccountService from 'account/api/AccountService';
import InviteService from 'modules/notifications/api/InviteService';
import { showNotifySuccess, showNotifyError } from 'services/toaster';
import Switch from 'components/Switch';
import { ContactInfo, ContactRow, StyledIcon, Root, StyledSelect, ContactActionsRow } from './styles';
import RequestToCancelForm from '../../../../account/components/RequestToCancelForm/index';
import RequestService from '../../../../account/api/RequestService';

const ViewSubscriber = ({ subscriberData }) => {
  const [addressString, setAddressString] = useState();
  const [cancellationRequest, setCancellationRequest] = useState();
  const [showRequestModal, setShowRequestModal] = useState(false);
  const { subscriberType, subscriberId } = useParams();

  const isIC = subscriberType === subscribersTypePaths.InsuranceCompany.path;
  const isCompany = subscriberType === subscribersTypePaths.InsuranceCompany.path ||
    subscriberType === subscribersTypePaths.RiskCompany.path ||
    subscriberType === subscribersTypePaths.InvitedCompany.path;
  const isUser = subscriberData && subscriberData.type === 'User';
  const isContact = subscriberData && subscriberData.type === 'Contact';
  const isDownloadingEnabled = cancellationRequest && cancellationRequest.status === 'Approved';

  const toggleShowRequestModal = () => {
    setShowRequestModal(!showRequestModal);
  };

  const loadCancellationRequest = async () => {
    const data = await RequestService.getRequestToCancelSubscription(subscriberId);
    setCancellationRequest(data.data.data);
  };

  useEffect(() => {
    loadCancellationRequest();
  }, []);

  useEffect(() => {
    if (subscriberData && subscriberData.address) {
      const resultString = ['addressLine', 'country', 'state', 'city', 'zipCode']
        .map((key) => subscriberData.address[key])
        .filter((el) => el)
        .join(', ');
      setAddressString(resultString);
    }
  }, [subscriberData]);

  if (!subscriberData) {
    return <Spinner />;
  }

  const role = Object.values(subscribersTypePaths).find(({ path }) => path === subscriberType);

  const subscriberNameOptions = [{
    label: subscriberData.name.fullName || subscriberData.name,
    value: subscriberData.name.fullName || subscriberData.name,
  }];

  const resetPassword = async () => {
    await AccountService.forgotPassword(subscriberData.primaryContactEmail);
    showNotifySuccess("Password reset link has been sent to user's email");
  };

  const resendInvites = () => {
    InviteService.resendTeamMember(subscriberData.id)
      .then(() => {
        showNotifySuccess(`The invitation email was successfully sent to ${subscriberData.primaryContactEmail}.`);
      })
      .catch((error) => showNotifyError(error));
  };

  const updateDownloadDataSetting = async (value) => {
    if (value) {
      const data = await RequestService.approveRequest({ id: cancellationRequest.id });
      const req = data.data.data;
      setCancellationRequest(req);
    } else {
      const data = await RequestService.rejectRequest({ id: cancellationRequest.id });
      const req = data.data.data;
      setCancellationRequest(req);
    }
  };

  return (
    <Root>
      <StyledSelect
        required
        readOnly
        value={role.name}
        label="Subscription For"
        options={subscriberTypeOptions}
        noClear
      />
      {!isIC && (
        <StyledSelect
          required
          readOnly
          value={subscriberData.name.fullName || subscriberData.name}
          label="Name"
          options={subscriberNameOptions}
          noClear
        />
      )}
      <ContactInfo>
        <div>
          {subscriberData.name.fullName || subscriberData.name}
        </div>
        {!isCompany && subscriberData.companyName && (
          <ContactRow>
            <StyledIcon icon="company" noWrapper />
            <span>{subscriberData.companyName}</span>
          </ContactRow>
        )}
        {((isCompany && subscriberData.address) || addressString) && (
          <ContactRow>
            <StyledIcon icon="address" noWrapper />
            <span>{isCompany ? subscriberData.address : addressString}</span>
          </ContactRow>
        )}
        {subscriberData.phone && (
          <ContactRow>
            <StyledIcon icon="phone" noWrapper />
            <span>{subscriberData.phone}</span>
          </ContactRow>
        )}
        {!isCompany && subscriberData.email && (
          <ContactRow>
            <StyledIcon icon="mail" noWrapper />
            <span>{subscriberData.email}</span>
          </ContactRow>
        )}
        {isCompany && subscriberData.primaryContactFullName && subscriberData.primaryContactEmail && (
          <ContactRow>
            <StyledIcon icon="user" noWrapper />
            <span>
              {subscriberData.primaryContactFullName}
              &nbsp;
              {' • '}
              &nbsp;
              {subscriberData.primaryContactEmail}
            </span>
          </ContactRow>
        )}
      </ContactInfo>
      <ContactActionsRow>
        {(isUser || isContact) && (
          <>
            <Button
              text="Reset Password"
              small
              onClick={resetPassword}
              disabled={!isUser}
          />
            <Button
              text="Resend Invitation"
              small
              onClick={resendInvites}
              disabled={!isContact}
          />
          </>
        )}
        {cancellationRequest && !isUser && !isContact && (
        <>
          <Button
            text="View Cancellation Form"
            small
            onClick={toggleShowRequestModal}
          />
          <Switch value={isDownloadingEnabled} onChange={(event) => updateDownloadDataSetting(event.target.checked)} label="Allow data download" />
        </>
        )}

      </ContactActionsRow>
      {showRequestModal && (
      <>
        <RequestToCancelForm isOpen={showRequestModal} onClose={toggleShowRequestModal} onSubmit={null} isView data={cancellationRequest} />
      </>
      )}
    </Root>
  );
};

export default ViewSubscriber;
