import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import RedirectButton from 'help/components/RedirectButton';
import { getNextPageInfo, getFolderByRole } from 'help/constants';

import { Title, TextSection, List } from 'help/pages/styles';

const DocumentsSection = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const documentsSrc = `projects/${folder}/projects-documents.png`;

  const isICC = role === ROLES_IDS.ICCoordinator;
  const isBroker = role === ROLES_IDS.Broker;
  const isIR = role === ROLES_IDS.InsuranceRepresentative;
  const isRM = role === ROLES_IDS.RiskManager;
  const isDO = role === ROLES_IDS.DeveloperOwner;

  const { title, path } = getNextPageInfo(role, 'documents');

  return (
    <>
      <ImageContainer src={documentsSrc} alt="documents" />
      <Title>Documents</Title>
      <TextSection>
        Documents sub-section allows you to store and share documents and files
        that are related to the project. The following default folders are
        automatically created for each project:
      </TextSection>
      <List>
        <li>
          Insurance Documents - use for sharing and storing the insurance
          documents.
        </li>
        <li>
          Layouts - use for uploading the site plans. Once the site plan is
          uploaded, the risk engineer is notified about that and can use such
          plan for the inspection.
        </li>
        <li>
          Inspections folder - when the inspection is scheduled, a default
          folder is automatically created for such inspection. The reports,
          photos and videos that are produced during the inspection, will
          automatically go that folder.
        </li>
      </List>
      {!isBroker && !isIR && !isRM && !isDO && (
        <TextSection>
          If you need additional folders for the project, you can create them
          manually.
        </TextSection>
      )}
      {isICC && (
        <TextSection>
          If you don’t want to share the file with all the project stakeholders,
          you can make it private so that such file is only visible to people
          from your company.
        </TextSection>
      )}
      {(isRM || isDO) && (
        <RedirectButton redirectTitle={title} redirectPath={path} />
      )}
    </>
  );
};

export default DocumentsSection;
