import {
  format, isToday, isYesterday, differenceInWeeks, parseISO,
  differenceInHours, formatDistanceToNowStrict, subYears, formatDuration,
  differenceInCalendarDays, addWeeks, addMinutes, subMinutes, differenceInMinutes,
  set, differenceInMilliseconds, intervalToDuration,
} from 'date-fns';
import { isString } from 'lodash';

const POLICY_DATE_FORMAT = 'MMMM d, yyyy';
const COMMON_DATE_FORMAT = 'MM/dd/yyyy';
const COMMON_DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
const COMMON_TIME_FORMAT = 'H:mm';
const NOTIF_TIME_FORMAT = 'h:mm a';
const NOTIF_DAY_FORMAT = 'cccc, MMMM do yyyy';
const SHORT_FORMAT = 'ccc d MMM';
const MONTH_DATE_FORMAT = 'MMMM';
const YEAR_DATE_FORMAT = 'yyyy';
const NOT_FULL_FORMAT = 'MM/yyyy';
const NOT_FULL_FORMAT_CALENDAR = 'MMM yyyy';

export const parseDateWithoutTime = (isoString) => {
  if (!isoString) return null;
  if (isoString instanceof Date) return isoString;
  const splittedDate = isoString.split('T');

  if (splittedDate.length === 1) { // not ISO
    return new Date(isoString);
  }

  return parseISO(splittedDate[0]);
};

export const convertUTCStringToDate = (date) => {
  if (!date) return 'undefined';

  return new Date(`${date}+00`);
};

export const subYearsFromDate = (isoString, numberOfYears) => {
  if (!isoString) return 'undefined';
  const date = new Date(isoString);

  return subYears(date, numberOfYears);
};

export const differenceInDays = (earlierDate, laterDate, withDays = true) => {
  const firstDate = new Date(earlierDate);
  const secondDate = new Date(laterDate);
  const count = differenceInCalendarDays(secondDate, firstDate);
  const withDaysString = count === 1 ? 'day' : 'days';

  return withDays ? `${count} ${withDaysString}` : count;
};

export const formatPolicyDate = (isoString) => {
  if (!isoString) return 'undefined';
  const date = parseDateWithoutTime(isoString);

  return format(date, POLICY_DATE_FORMAT);
};

export const formatCommonDate = (isoString, respectTime = false) => {
  if (!isoString) return 'undefined';
  const date = respectTime ? new Date(isoString) : parseDateWithoutTime(isoString);

  return format(date, COMMON_DATE_FORMAT);
};

export const formatMonth = (isoString) => {
  if (!isoString) return 'undefined';
  const date = new Date(isoString);

  return format(date, MONTH_DATE_FORMAT);
};

export const formatYear = (isoString) => {
  if (!isoString) return 'undefined';
  const date = new Date(isoString);

  return format(date, YEAR_DATE_FORMAT);
};

export const formatNotificationTime = (isoString) => {
  if (!isoString) return 'undefined';
  const date = new Date(isoString);

  return format(date, NOTIF_TIME_FORMAT);
};

export const formatSubscriptionTime = (date) => {
  if (!date) return 'undefined';

  const currentDate = new Date();
  const endDate = new Date(date);

  let days = 0;
  let hours = 0;
  let minutes = -differenceInMinutes(currentDate, endDate);

  if (minutes > 60) {
    hours = Math.floor(minutes / 60);
    minutes -= hours * 60;
  }
  if (hours > 24) {
    days = Math.floor(hours / 24);
    hours -= days * 24;
  }

  return formatDuration({ days, hours, minutes });
};

export const formatCommonTime = (isoString) => {
  if (!isoString) return 'undefined';
  const date = new Date(isoString);

  return format(date, COMMON_TIME_FORMAT);
};

export const formatShortDate = (isoString) => {
  if (!isoString) return 'undefined';
  const date = new Date(isoString);

  return format(date, SHORT_FORMAT);
};

export const formatNoFullDate = (isoString, addExtraDay = false) => {
  if (!isoString) return 'undefined';
  const date = new Date(isoString);

  if (addExtraDay) {
    date.setDate(date.getDate() + 1);
  }

  return format(date, NOT_FULL_FORMAT);
};

export const formatNoFullDateForCalendar = (isoString) => {
  if (!isoString) return 'undefined';
  const date = new Date(isoString);

  return format(date, NOT_FULL_FORMAT_CALENDAR);
};

export const dateToString = (date) => {
  if (date) {
    return isString(date) ? date : date.toISOString();
  }

  return '';
};

export const formatCommentDateTime = (isoString) => {
  const now = new Date();
  const dateTime = parseISO(isoString);
  const difference = differenceInHours(now, dateTime);

  if (difference < 24) {
    return formatDistanceToNowStrict(dateTime, { addSuffix: true });
  }

  return format(dateTime, COMMON_DATE_TIME_FORMAT);
};

export const formatDifferenceInDays = (isoString) => {
  const now = new Date();
  const dateTime = parseISO(isoString);
  const difference = differenceInHours(now, dateTime);

  if (difference > 0) {
    return { overdue: formatDistanceToNowStrict(dateTime, { unit: 'day' }) };
  }

  return { daysLeft: formatDistanceToNowStrict(dateTime, { unit: 'day' }) };
};

export const getNotifTime = (date) => format(date, NOTIF_TIME_FORMAT);

export const getNotifDay = (date) => format(date, NOTIF_DAY_FORMAT);

export const getWeeksBetween = (leftDate, rightDate) => {
  const earlierDate = new Date(leftDate);
  const laterDate = new Date(rightDate);

  const weeksDifference = differenceInWeeks(earlierDate, laterDate);
  const tempDate = addWeeks(laterDate, weeksDifference);
  const daysDifference = differenceInCalendarDays(earlierDate, tempDate);
  const decimalDifference = weeksDifference + (daysDifference / 7);

  return parseFloat(decimalDifference.toFixed(1));
};

export const getNotificationsByDay = (items) => {
  const result = [];
  const days = {};

  items.forEach((item) => {
    if (isToday(item.creationDateTime)) {
      days.Today = days.Today || [];
      days.Today.push(item);
    } else if (isYesterday(item.creationDateTime)) {
      days.Yesterday = days.Yesterday || [];
      days.Yesterday.push(item);
    } else {
      const dayOfYear = format(item.creationDateTime, COMMON_DATE_FORMAT);

      days[dayOfYear] = days[dayOfYear] || [];
      days[dayOfYear].push(item);
    }
  });

  if (days.Today) {
    result.push({
      day: 'Today',
      items: days.Today,
    });
  }

  if (days.Yesterday) {
    result.push({
      day: 'Yesterday',
      items: days.Yesterday,
    });
  }

  const otherDays = Object.keys(days)
    .filter((day) => !['Today', 'Yesterday'].includes(day))
    .map((day) => new Date(day))
    .sort((a, b) => {
      return (+a) - (+b);
    })
    .reverse()
    .map((day) => format(day, COMMON_DATE_FORMAT));

  otherDays.forEach((dayNo) => {
    result.push({
      day: getNotifDay(days[dayNo][0].creationDateTime),
      items: days[dayNo],
    });
  });

  return result;
};

export const getZeroOffsetDate = (date) => {
  const currDate = new Date(date);
  const offset = currDate.getTimezoneOffset();

  return addMinutes(currDate, offset);
  // warning: this date still has local timezone prefix
  // but equal to utc or 0 offset date
};

export const getLocalOffsetDate = (date) => {
  const currDate = new Date(date);
  const offset = currDate.getTimezoneOffset();

  return subMinutes(currDate, offset);
};

export function handleDateUpdateWithTZ(timeZoneOffset, date) {
  let offset = timeZoneOffset;

  if (offset === 'local' || !(timeZoneOffset || timeZoneOffset === 0)) {
    const currDate = new Date();

    offset = -currDate.getTimezoneOffset();
  }
  const newDate = getZeroOffsetDate(date);

  return addMinutes(newDate, offset);
}

export const getTimeRangeForNewTZ = (timeZone, startTime, endTime, timeZoneData) => {
  const newRange = `${formatNotificationTime(handleDateUpdateWithTZ(timeZone, startTime))} - ${formatNotificationTime(handleDateUpdateWithTZ(timeZone, endTime))}`;

  if (!timeZoneData) {
    return newRange;
  }
  const { shortName } = timeZoneData;
  const offsetStringInHours = timeZone >= 0 ? `+${timeZone / 60}` : timeZone / 60;
  const timeZoneHint = `GMT${offsetStringInHours} ${shortName ? ` - ${shortName}` : ''}`;

  return `${newRange} (${timeZoneHint})`;
};

export const formatCommonDateWithTZ = (timeZone, startTime) => {
  const formattedNewDate = format(handleDateUpdateWithTZ(timeZone, startTime), COMMON_DATE_FORMAT);

  return formattedNewDate;
};

export const normalizeMonthDate = (date, endOfMonth) => {
  let normalizedDate = date;

  if (date == null) return date;
  const year = normalizedDate.getFullYear();
  const month = normalizedDate.getMonth();

  if (endOfMonth) {
    const lastDayOfCurrentMonth = new Date(year, month + 1, 0).getDate();

    normalizedDate = new Date(year, month, lastDayOfCurrentMonth);
    normalizedDate.setHours(23, 59, 59, 59);
  } else {
    normalizedDate = new Date(year, month, 1);
    normalizedDate.setHours(0, 0, 0, 0);
  }

  return normalizedDate;
};

export const createUniversalDate = (date) => {
  const dateObj = date ? parseDateWithoutTime(date) : new Date();
  // next conversion ensures that local date will be equal to utc date
  const resetToZeroTimeDate = set(dateObj, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  const offset = -dateObj.getTimezoneOffset();

  return (offset > 0)
    ? addMinutes(resetToZeroTimeDate, offset) // GMT+X eg. Wed Jun 09 2021 11:00:00 GMT+1100 = 2021-06-09T00:00:00.000Z
    : resetToZeroTimeDate; // GMT-X eg. Wed Jun 09 2021 00:00:00 GMT-0700 = 2021-06-09T07:00:00.000Z
};

export const readableTimeToNow = (end) => {
  const msToUnlock = differenceInMilliseconds(new Date(end), new Date());
  const durations = intervalToDuration({ start: 0, end: msToUnlock });

  return formatDuration(durations);
};
