import styled from 'styled-components';
import { ListItem, List } from '@mui/material';

export const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    justify-content: space-between;
    height: 40px;
    border-radius: 4px;
    color: var(--lightTextColor);
  }

  & .MuiTypography-body1 {
    font-size: 16px;
    font-weight: 500;
  }

  &.MuiListItem-button:hover {
    background-color: var(--inputHoverBackground);
  }

  &.Mui-selected {
    background-color: var(--iconContainerBackground);
    color: var(--textColor);
  }

  &.MuiListItem-gutters {
    padding: 10px 16px;
  }

  > .MuiListItemIcon-root {
    justify-content: center;
    min-width: 40px;
  }
`;

export const StyledList = styled(List)`
  min-height: 440px;
  min-width: 292px;
  margin-right: 30px;
  padding-right: 24px;
  border-right: 1px solid var(--notificationItemBorderBottom);
`;

export const Title = styled.div`
  padding: 0 16px 20px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--textColor);
`;
