import React from 'react';

const Locked = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7h-1V4a3 3 0 00-6 0v3H4a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V8a1 1 0 00-1-1zM6 4a2 2 0 114 0v3H6V4zm6 10H4V8h8v6z" fill="currentColor" />
    </svg>
  );
};

export default Locked;
