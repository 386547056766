import ApiClient from 'services/ApiClient';
import qs from 'qs';

import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class DashboardInspectionsService extends ApiClient {
  getDashboardInspections(filters) {
    const { page = 1, pageSize = PAGE_SIZE, sortBy, searchFilter } = filters;
    const query = {
      ...filters,
      page,
      pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    if (searchFilter) {
      query.Name = searchFilter;
    }

    return this.call({
      method: 'get',
      url: `/dashboard/inspections/list?${qs.stringify(query)}`,
    });
  }

  getInspectionsForCalendar(filters) {
    return this.call({
      method: 'get',
      url: `/dashboard/inspections/calendar?${qs.stringify(filters)}`,
    });
  }

  getInspectionsForMap(filters) {
    return this.call({
      method: 'get',
      url: `/dashboard/inspections/map?${qs.stringify(filters)}`,
    });
  }

  getFilters(params) {
    return this.call({
      method: 'get',
      url: `/dashboard/inspections/filters?${qs.stringify(params)}`,
    });
  }
}

export default new DashboardInspectionsService();
