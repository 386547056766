import React from 'react';

const Billing = () => {
  return (
    <svg width="27" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.625 5.063H3.375A1.687 1.687 0 001.687 6.75v13.5a1.687 1.687 0 001.688 1.688h20.25a1.687 1.687 0 001.688-1.688V6.75a1.687 1.687 0 00-1.688-1.688zm0 1.687v2.531H3.375V6.75h20.25zm-20.25 13.5v-9.281h20.25v9.281H3.375z" fill="#fff" fillOpacity=".87" />
      <path d="M13.5 16.875H5.062v1.688H13.5v-1.688z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Billing;
