import React from 'react';

const Recommendations = () => {
  return (
    <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.864 17.647v4.646H5.473V3.706h7.744V2.157H5.473a1.549 1.549 0 00-1.549 1.55v18.586a1.55 1.55 0 001.549 1.55h12.391a1.549 1.549 0 001.55-1.55v-4.646h-1.55z" fill="#fff" fillOpacity=".87" />
      <path d="M23.704 5.07l-2.556-2.556a1.24 1.24 0 00-1.735 0L8.571 13.356v4.29h4.282L23.696 6.805a1.239 1.239 0 000-1.734h.008zM12.21 16.098H10.12v-2.091l7.31-7.319 2.1 2.099-7.32 7.31zm8.41-8.403l-2.099-2.099 1.759-1.758 2.098 2.099-1.758 1.758z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Recommendations;
