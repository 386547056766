import ClaimService from 'projects/api/ClaimService';

const GET_CAUSES_OF_LOSS = 'GET_CAUSES_OF_LOSS';
const GET_CLAIMS_LIST = 'GET_CLAIMS_LIST';
const UPDATE_CLAIM = 'UPDATE_CLAIM';
const ADD_CLAIM = 'ADD_CLAIM';
const CLAIMS_LIST_SORT_BY = 'CLAIMS_LIST_SORT_BY';

export const getClaimsList = (params) => ({
  type: GET_CLAIMS_LIST,
  apiCall: () => ClaimService.getClaimsList(params),
});

export const addClaim = (data) => ({
  type: ADD_CLAIM,
  apiCall: () => ClaimService.addClaim(data),
});

export const updateClaim = (data) => ({
  type: UPDATE_CLAIM,
  apiCall: () => ClaimService.updateClaim(data),
});

export const getCausesOfLoss = (params) => ({
  type: GET_CAUSES_OF_LOSS,
  apiCall: () => ClaimService.getCausesOfLoss(params),
});

export const setClaimsSortBy = (sortBy) => ({
  type: CLAIMS_LIST_SORT_BY,
  sortBy,
});

const initialState = {
  claimsList: {
    items: [],
  },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_CLAIMS_LIST: {
      return {
        ...state,
        claimsList: {
          ...state.claimsList,
          ...response,
        },
      };
    }
    case CLAIMS_LIST_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }
    default:
      return state;
  }
};
