import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

import { Spinner } from 'components';

import { Root } from './styles';

const ImageContainer = ({ alt, src, isCommunication }) => {
  const [isLoading, setIsLoading] = useState(true);
  const muiTheme = useTheme();

  const themeColor = muiTheme.light ? 'light' : 'dark';
  const imageSrc = `/img/onboarding/${themeColor}/${src}`;

  useEffect(() => {
    setIsLoading(true);
    const img = new Image();
    if (isCommunication) {
      img.src = src;
    } else {
      img.src = imageSrc;
    }
    img.onload = () => {
      setIsLoading(false);
    };
  }, [src]);

  return (
    <Root>
      {isLoading ? <Spinner /> : <img src={imageSrc} alt={alt} />}
    </Root>
  );
};

export default ImageContainer;
