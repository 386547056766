import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import { Button, Icon } from 'components';

export const Subtitle = styledMui('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  color: theme.palette.text.main,
}));

export const ErrorMessage = styledMui('div')(({ theme }) => ({
  marginTop: 10,
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 12,
  letterSpacing: '0.16px',
  color: theme.palette.error.main,
}));

export const ProgressWrapper = styled.div`
  width: 100%;
  margin: 8px;

  .MuiLinearProgress-root {
    border-radius: 10px;
  }
`;

export const UploadingFileInfo = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    max-width: 220px;
  }
`;

export const UploadButton = styledMui('div')(({ theme, previewImage }) => ({
  display: 'flex',
  justifyContent: 'row',
  alignItems: 'center',
  height: previewImage ? 'auto' : 50,
}));

export const FormatsString = styledMui('span')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.input.disabled}`,
  paddingLeft: 12,
  color: theme.palette.text.light,
}));

// export const FormatsString = styled.span`
//   border-left: 1px solid var(--inputDisabledText);
//   padding-left: 12px;
//   color: var(--lightTextColor);
// `;
//

export const ColoredIcon = styledMui(Icon)(({ theme }) => ({
  color: theme.palette.sidebar.selected.icon.background,
}));

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  color: theme.palette.sidebar.selected.icon.background,
  transform: 'rotate(180deg)',
  marginRight: 10,
}));

export const StyledButton = styledMui(Button)(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontSize: 9,
  lineHeight: "24px",
  color: theme.palette.sidebar.selected.icon.background,
  paddingLeft: 0,
  fontWeight: 'normal',
}));

export const FileRoot = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 5px 0;

  & > :last-child {
    width: 40px;
    height: 40px;
    margin: auto 10px auto auto;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;

export const FileSize = styledMui('div')(({ theme }) => ({
  color: theme.palette.input.disabled.color,
}));

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyMessage = styledMui('div')(({ theme, previewImage }) => ({
  ...(!previewImage && { marginBottom: 'auto' }),
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '18px',
  letterSpacing: '0.16px',
  color: theme.palette.button.disabled.text,
}));

export const PreviewWrapper = styledMui('div')(({ theme, disabled }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 80,
  height: 80,
  margin: '14px 24px 14px 0',
  border: `1px solid ${theme.palette.border.light}`,
  borderRadius: 4,
  overflow: 'hidden',
  transition: 'opacity 0.3s',
  ':hover': {
    ...!disabled && { opacity: 0.7 },
  },
}));


export const PreviewImage = styled.img`
  max-width: 80px;
  max-height: 80px;
`;

export const PreviewEmptyIcon = styledMui(Icon)(({ theme }) => ({
  width: 30,
  height: 30,
  svg: {
    width: '100%',
    height: '100%',
  },
  path: {
    fill: theme.palette.input.select.topBorder,
  },
}));
