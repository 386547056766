import styled from 'styled-components';

import FormDialog from 'components/Dialogs/FormDialog';

export const StyledFormDialog = styled(FormDialog)`
  .MuiDialog-paper {
    max-width: 700px;
  }

  .MuiDialogContent-root {
    position: relative;
    overflow: visible;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 30px;

  > div:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  > div:not(:last-of-type) {
    margin-right: 24px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 40px;
  left: 2px;
  bottom: -70px;

  > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const DetailsTitle = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;

  > :not(:last-child) {
    margin-right: 22px;
  }
`;

export const FoundUserTitle = styled.div`
  margin-bottom: 8px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--textColor);
`;

export const FoundUserInfo = styled.div`
  padding: 18px 20px;
  background-color: var(--inputBackground);
  border-radius: 4px;
  margin-top: 24px;
  border: 1px solid transparent;
  ${(p) => p.error && 'border: 1px solid var(--error);'}
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--textColor);

  > div:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

export const ErrorText = styled.div`
  margin-top: 4px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: var(--error);
`;
