import styled from 'styled-components';
import { Form } from 'formik';

export const DataWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 125px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 48px;
  width: 95%;
  margin-bottom: 24px;

  > div {
    margin-right: 15px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 15px;
  }
`;

export const Title = styled.div`
  margin-bottom: 24px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: rgba(255, 255, 255, 0.87);
`;
