import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';


export const Main = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-width: 1400px;
  overflow-y: hidden;
  overflow-x: auto;
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1 0;
  display: flex;
`;

export const Workspace = styledMui('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: theme.palette.background.workspace,
  overflowX: 'hidden',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
}));

export const Content = styled.div`
  position: relative;
  flex: 1 0;
  min-height: 0;
  padding: 0 36px;
`;

export const FlexContainer = styled.div`
  display: flex;
`;
