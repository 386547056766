import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  newAlertsList, allAlertsList, alertsView, getOpenedAlert,
} from 'notifications/store/notification.selectors';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import NotificationsList from 'notifications/components/NotificationsList';

const AlertsDashboard = () => {
  const dispatch = useDispatch();

  const allAlerts = useSelector(allAlertsList);
  const newAlerts = useSelector(newAlertsList);
  const currentView = useSelector(alertsView);
  const openedAlert = useSelector(getOpenedAlert);

  if (!currentView && !openedAlert) {
    return null;
  }

  const handleOpenAlert = (notify) => {
    dispatch(NotificationsActions.setOpenedAlert(notify));
  };

  const handleClose = () => {
    dispatch(NotificationsActions.setAlertsView(''));
  };

  const handleSetViewNew = () => {
    if (currentView !== 'new') {
      dispatch(NotificationsActions.setAlertsView('new'));
    }
  };

  const handleSetViewAll = () => {
    if (currentView !== 'all') {
      dispatch(NotificationsActions.setAlertsView('all'));
    }
  };

  return (
    <NotificationsList
      onSetViewAll={handleSetViewAll}
      onSetViewNew={handleSetViewNew}
      onClose={handleClose}
      allNotifications={allAlerts}
      newNotifications={newAlerts}
      currentView={currentView}
      openedNotification={openedAlert}
      onOpenNotification={handleOpenAlert}
      isAlert
    />
  );
};

export default AlertsDashboard;
