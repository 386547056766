import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import RedirectButton from 'help/components/RedirectButton';
import { getNextPageInfo, getFolderByRole } from 'help/constants';

import { Title, TextSection, List, Underline } from 'help/pages/styles';

const ContactsSection = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const contactsSrc = `projects/${folder}/projects-contacts.png`;

  const isRE = role === ROLES_IDS.RiskEngineer;

  const { title, path } = getNextPageInfo(role, 'contacts');

  return (
    <>
      <ImageContainer src={contactsSrc} alt="contacts" />
      <Title>Contacts</Title>
      <TextSection>
        Contacts sub-section allows you to invite project contacts to the project.
        The following contacts can be invited within that section:
      </TextSection>
      <List>
        <li>Broker</li>
        <li>Insurance Representative</li>
        <li>Site Contact</li>
        <li>Risk Manager</li>
        <li>Underwriter (assigned to the project)</li>
      </List>
      <Title>Invite</Title>
      <TextSection>
        <Underline>When the new contact is invited</Underline>
        , the email invitation is sent to the selected email address.
        Once the external contacts (Broker, Site Contact or Risk Manager) register the account,
        their details must be verified before they get the access to the system. Once verified,
        they receive the email notification that they now can access the project.
      </TextSection>
      <TextSection>
        <Underline>When the user already has account on the portal</Underline>
        , such user can be invited as well and the verification
        process is not needed. Once invited, such user will receive the notification and can
        immediately access the project.
      </TextSection>
      <Title>Delete</Title>
      <TextSection>
        When the contact is deleted from project contacts, such user will receive the
        email notification and will no longer be able to access the project data.
      </TextSection>
      {isRE && <RedirectButton redirectTitle={title} redirectPath={path} />}
    </>
  );
};

export default ContactsSection;
