import styled from 'styled-components';

import { Button } from '@mui/material';
import Icon from 'components/Icon';

export const StyledIcon = styled(Icon)`
  svg > path {
    fill: var(--iconPrimaryColor);
  }
`;

export const StyledSkipButton = styled(Button)`
  display: flex;
  justify-content: start;
  width: 268px;
  height: 70px;
  margin: 12px;
  padding: 12px 36px;
  white-space: nowrap;
  font-size: 16px;
  text-transform: none;
  color: var(--textColor);
  border: 1px solid var(--lightBorderColor);
  border-radius: 6px;
  background-color: transparent;
  ${({ highlighted }) => highlighted && `
    border: 2px dashed var(--buttonPrimaryColor);
  `}
  ${({ small }) => small && `
    flex: 1 0 30%;
    max-width: 248px;
    height: 44px;
    padding: 12px 36px 12px 6px;
  `}
`;

export const Root = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  max-width: 600px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-right: 25px;
  background: var(--tableHeaderBackground);
  border-radius: 4px;
  ${({ small }) => small && `
    width: 32px;
    height: 32px;
  `}
`;

export const LastPageRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
`;

export const LogoText = styled.div`
  font-family: Avenir;
  font-weight: 900;
  font-size: 32px;
  line-height: 44px;
  color: var(--textColor);
`;

export const LogoTextSmall = styled(LogoText)`
  margin-top: 10px;
  font-size: 20px;
`;

export const Intro = styled.h1`
  max-width: ${({ maxWidth }) => `${maxWidth || 600}px`};
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.16px;
  color: var(--textColor);
`;

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin: 3% 0 2%;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: var(--textColor);
  div {
    width: 100%;
    height: 1px;
    margin: 0 14px;
    background: var(--inputReadOnlyBorder);
  }
`;
