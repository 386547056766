import React from 'react';

import { getNotifTime } from 'utils/DateUtils';

import ItemDescription from './ItemDescription';
import { ItemRoot, Header, Subject, Time, StyledIcon } from './styles';

const NotificationItem = ({ notify, onClick, isAlert }) => {
  const { subject, creationDateTime, isViewed } = notify;
  const icon = isAlert ? 'unreadAlert' : 'unreadNotification';

  const handleClick = () => {
    if (onClick) {
      onClick(notify);
    }
  };

  return (
    <ItemRoot onClick={handleClick}>
      <Header>
        <Subject>
          {subject}
          {!isViewed && <StyledIcon icon={icon} noWrapper />}
        </Subject>
        <Time>{getNotifTime(creationDateTime)}</Time>
      </Header>
      <ItemDescription notify={notify} />
    </ItemRoot>
  );
};

export default NotificationItem;
