import React from 'react';

const OpenFullScreen = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)" fill="currentColor" fillOpacity=".87">
      <path d="M10 1v1h3.293L9 6.291 9.707 7 14 2.707V6h1V1h-5zM7 9.708L6.296 9 2 13.293V10H1v5h5v-1H2.707L7 9.708z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default OpenFullScreen;
