import DashboardRecommendationsService from 'dashboard/api/RecommendationsService';

const DASHBOARD_GET_RECOMMENDATIONS_LIST = 'DASHBOARD_GET_RECOMMENDATIONS_LIST';
const DASHBOARD_RECOMMENDATIONS_LIST_SORT_BY = 'DASHBOARD_RECOMMENDATIONS_LIST_SORT_BY';

export const getDashboardRecommendations = (params) => ({
  type: DASHBOARD_GET_RECOMMENDATIONS_LIST,
  apiCall: () => DashboardRecommendationsService.getDashboardRecommendations(params),
});

export const setRecommendationsSortBy = (sortBy) => ({
  type: DASHBOARD_RECOMMENDATIONS_LIST_SORT_BY,
  sortBy,
});

const initialState = {
  recommendations: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case DASHBOARD_GET_RECOMMENDATIONS_LIST: {
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          ...response,
        },
      };
    }
    case DASHBOARD_RECOMMENDATIONS_LIST_SORT_BY: {
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          sortBy: action.sortBy,
        },
      };
    }

    default:
      return state;
  }
};
