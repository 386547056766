import DashboardInspectionsService from 'dashboard/api/InspectionsService';

const DASHBOARD_GET_INSPECTIONS_LIST = 'DASHBOARD_GET_INSPECTIONS_LIST';
const DASHBOARD_INSPECTIONS_LIST_SORT_BY = 'DASHBOARD_INSPECTIONS_LIST_SORT_BY';
const GET_DASHBOARD_INSPECTIONS_FILTERS = 'GET_DASHBOARD_INSPECTIONS_FILTERS';

export const getDashboardInspections = (params) => ({
  type: DASHBOARD_GET_INSPECTIONS_LIST,
  apiCall: () => DashboardInspectionsService.getDashboardInspections(params),
});

export const getFilters = (params) => ({
  type: GET_DASHBOARD_INSPECTIONS_FILTERS,
  apiCall: () => DashboardInspectionsService.getFilters(params),
});

export const setInspectionsSortBy = (sortBy) => ({
  type: DASHBOARD_INSPECTIONS_LIST_SORT_BY,
  sortBy,
});

const initialState = {
  inspections: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case DASHBOARD_GET_INSPECTIONS_LIST: {
      return {
        ...state,
        inspections: {
          ...state.inspections,
          ...response,
        },
      };
    }
    case DASHBOARD_INSPECTIONS_LIST_SORT_BY: {
      return {
        ...state,
        inspections: {
          ...state.inspections,
          sortBy: action.sortBy,
        },
      };
    }

    case GET_DASHBOARD_INSPECTIONS_FILTERS: {
      return {
        ...state,
        filters: {
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
