import NoteService from 'projects/api/NoteService';

const ADD_NEW_NOTE = 'ADD_NEW_NOTE';
const GET_NOTES_LIST = 'GET_NOTES_LIST';
const UPDATE_NOTE = 'UPDATE_NOTE';
const DELETE_NOTE = 'DELETE_NOTE';
const NOTES_LIST_SORT_BY = 'NOTES_LIST_SORT_BY';

export const getNotesList = (params) => ({
  type: GET_NOTES_LIST,
  apiCall: () => NoteService.getNotesList(params),
});

export const setNotesSortBy = (sortBy) => ({
  type: NOTES_LIST_SORT_BY,
  sortBy,
});

export const addNewNote = (data) => (dispatch) => dispatch({
  type: ADD_NEW_NOTE,
  apiCall: () => NoteService.addNewNote(data),
});

export const updateNote = (data) => ({
  type: UPDATE_NOTE,
  apiCall: () => NoteService.updateNote(data),
});

export const deleteNote = (data) => ({
  type: DELETE_NOTE,
  apiCall: () => NoteService.deleteNote(data),
});

const initialState = {
  sortBy: null,
  notesList: {
    items: [],
  },
  addNewNote: {},
  updateNote: {},
  deleteNote: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case NOTES_LIST_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }
    case GET_NOTES_LIST: {
      return {
        ...state,
        notesList: {
          ...state.notesList,
          ...response,
        },
      };
    }
    case ADD_NEW_NOTE: {
      return {
        ...state,
        addNewNote: {
          ...state.addNewNote,
          ...response,
        },
      };
    }
    case UPDATE_NOTE: {
      return {
        ...state,
        updateNote: {
          ...state.updateNote,
          ...response,
        },
      };
    }
    case DELETE_NOTE: {
      return {
        ...state,
        deleteNote: {
          ...state.deleteNote,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
