import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const ApprovalSettingUpdated = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = notify;
  const location = data.locationName == null ? '' : ` - ${data.locationName}`;
  const message = `Please be notified that the Insurance Company Coordinator for ${data.companyName}${location} has turned ${data.value} the approval for ${data.settingName}. ${data.settingName} submitted will ${data.requiredAction}`;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToResources = () => {
    handleClose();
    closeNotificationList();
    navigate('/dashboard/assignments');
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={data.projectName}
      title={`${data.settingName} turned ${data.value}`}
      cancelButtonTitle="Close"
      confirmButtonTitle="Go to assignments"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToResources}
        >
      <ConfirmationText
        text={message}
            />
    </ConfirmationDialog>
  );
};

export default ApprovalSettingUpdated;
