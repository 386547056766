import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SubjectMenu from "help/components/SubjectMenu";
import { DASHBOARD_SUBJECTS } from "help/constants";

import DailyTools from "./DailyTools";
import Overview from "./Overview";

import { Root } from "../styles";

const Dashboard = () => (
  <>
    <SubjectMenu section="dashboard" subjects={DASHBOARD_SUBJECTS} />
    <Root>
      <Routes>
        <Route path="/help/dashboard/overview" exact element={<Overview/>} />
        <Route path="/help/dashboard/tools" exact element={<DailyTools/>} />
        <Route
          path="/help/dashboard"
          element={<Navigate to="/help/dashboard/overview" replace />}
        />
      </Routes>
    </Root>
  </>
);

export default Dashboard;
