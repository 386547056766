import React from 'react';

import { InfoCardWrapper, ContactName, ContactRow, ContactInfoText, StyledIcon } from './styles';

const defaultName = 'Administrator';
const defaultEmail = 'sales@imitig8risk.com';

const InfoCard = ({ adminInfo = {}, className, fullWidth, withBottomMargin }) => {
  const { fullName, email, phone } = adminInfo;

  return (
    <InfoCardWrapper className={className} fullWidth={fullWidth} withBottomMargin={withBottomMargin}>
      <ContactName>{fullName || defaultName}</ContactName>
      {phone && (
        <ContactRow>
          <StyledIcon icon="phone" noWrapper />
          <ContactInfoText>{phone}</ContactInfoText>
        </ContactRow>
      )}
      <ContactRow>
        <StyledIcon icon="mail" noWrapper />
        <ContactInfoText>{email || defaultEmail}</ContactInfoText>
      </ContactRow>
    </InfoCardWrapper>
  );
};

export default InfoCard;
