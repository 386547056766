import React from 'react';

const Notes = () => {
  return (
    <svg width="27" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.968 8.152h5.706v1.63h-5.706v-1.63zm0 4.076h5.706v1.63h-5.706v-1.63zm0 4.076h5.706v1.63h-5.706v-1.63z" fill="#fff" fillOpacity=".87" />
      <path d="M23.305 4.076H3.739a1.632 1.632 0 00-1.63 1.63v14.675a1.632 1.632 0 001.63 1.63h19.566a1.633 1.633 0 001.63-1.63V5.707a1.632 1.632 0 00-1.63-1.63zM3.739 5.706h8.968v14.675H3.739V5.707zm10.598 14.675V5.707h8.968l.001 14.674h-8.969z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Notes;
