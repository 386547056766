export function feeProposalsList({ feeProposal }) {
  return feeProposal.feeProposalsList;
}

export function feeProposalsSortBy({ feeProposal }) {
  return feeProposal.sortBy;
}

export function feeProposalFormData({ feeProposal }) {
  return feeProposal.feeProposalFormData;
}

export function notificationFeeProposal({ feeProposal }) {
  return feeProposal.notificationFeeProposal;
}
