import qs from 'qs';
import ApiClient from 'services/ApiClient';

class NotificationService extends ApiClient {
  getInboxNotificationsList(onlyNew = false) {
    const query = {};

    if (onlyNew) {
      query.IsShowOnlyNew = true;
    }

    return this.call({
      method: 'get',
      url: `/notifications/inbox?${qs.stringify(query)}`,
    });
  }

  markAsViewed(notificationId) {
    return this.call({
      method: 'put',
      url: `/notifications/${notificationId}/mark-as-viewed`,
    });
  }

  acknowledgeAssignment(notificationId) {
    return this.call({
      method: 'put',
      url: `/notifications/${notificationId}/acknowledge-assignment`,
    });
  }

  developmentCreateNotification() {
    return this.call({
      method: 'get',
      url: '/seed/generate-notification',
    });
  }
}

export default new NotificationService();
