import styled from 'styled-components';

import IconButton from 'components/buttons/IconButton';

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: ${(p) => p.top || '24px'};
  right: ${(p) => p.right || '24px'};

  &&:hover {
    background: transparent;

    svg > path {
      fill: #BEE0F9;
    }
  }

  &&:active {
    svg > path {
      fill: #96CEF6;
    }
  }
`;
