import React, { useEffect, useState, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { FormikField, Input, Button, BackButton } from 'components';
import * as CommunicationSectionActions from 'communication/store/communicationSection.reducer';
import { useDispatch } from 'react-redux';
import UploadImage from '../../../../common/components/UploadImage/index';
import { showNotifyError, showNotifySuccess } from '../../../../../services/toaster';
import { HeaderPanel, Title, Headers, Row } from './styles';
import PageLayout from '../../../../../layouts/PageLayout/index';
import CommunicationService from '../../../api/CommunicationService';

const MAX_LOGO_FILE_SIZE = 104900000;

const ViewSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({ title: 'Test',
    description: 'Test',
    fileId: 'id',
    fileInfo: {} });
  const [fieldsToChange, setFieldsToChange] = useState(null);
  const { id, communicationId } = useParams();

  const getSection = () => {
    CommunicationService.getSection(id, communicationId)
      .then((response) => {
        setData(response.data.data);
      });
  };

  useEffect(() => {
    getSection();
  }, []);

  const handleCommunicationsListClick = () => navigate('/communications');

  const update = async (section) => {
    const response = await dispatch(CommunicationSectionActions.updateSection(section));

    return response;
  };

  const deleteCommunicationSection = async () => {
    const response = await dispatch(CommunicationSectionActions.deleteSection(id));
    return response;
  };

  const deleteSection = async () => {
    const response = await deleteCommunicationSection();

    if (response && response.status !== 400) {
      const successMessage = 'This page was successfully deleted';
      handleCommunicationsListClick();
      showNotifySuccess(successMessage);
    } else {
      const errorMessage = 'Section was not deleted';
      showNotifyError(errorMessage);
    }
  };

  const updateData = async () => {
    const { value } = fieldsToChange;
    data.fileId = value;
    const response = await update(data);
    if (response && response.status !== 400) {
      const successMessage = 'This page was successfully updated';
      showNotifySuccess(successMessage);
    } else {
      const errorMessage = 'Section was not updated , please check the fields';
      showNotifyError(errorMessage);
    }
  };

  return (
    <PageLayout>
      <Fragment key="title">
        <Headers>
          <HeaderPanel>
            <BackButton
              text="communications"
              onClick={handleCommunicationsListClick}
            />
          </HeaderPanel>
          <HeaderPanel>
            <Title>Edit Section</Title>
          </HeaderPanel>
        </Headers>
      </Fragment>
      <Fragment key="content">
        <Formik
          initialValues={data}
          onSubmit={() => { updateData(); }}
          enableReinitialize
        >
          {({ values }) => {
            useEffect(() => {
              setData(values);
            }, [values]);
            return (
              <Form>
                <Row>
                  <FormikField
                    component={Input}
                    name="title"
                    label="Title"
                    required
                  />
                </Row>
                <br />
                <Row>
                  <FormikField
                    component={Input}
                    name="description"
                    label="Description"
                    required
                  />
                </Row>
                <br />
                <Row>
                  <UploadImage
                    name="fileId"
                    setFieldsToChange={setFieldsToChange}
                    fileInfo={data.fileInfo}
                    showEmptyMessage
                    onlyPicture
                    previewImage
                    maxSize={MAX_LOGO_FILE_SIZE}
                  />
                </Row>
                <br />
                <Row style={{ width: '30%' }}>
                  <Button
                    label="submit"
                    type="submit"
                    color="primary"
                    variant="contained"
                    formNoValidate
                    text="Update"
                  />
                  <Button
                    color="error"
                    variant="contained"
                    formNoValidate
                    text="Delete"
                    onClick={() => { deleteSection(); }}
                  />
                </Row>

              </Form>
            );
          }}
        </Formik>
      </Fragment>
    </PageLayout>
  );
};

export default ViewSection;
