import React from 'react';

import { StyledButton } from './styles';

const chevronLeft = (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 15 15">
    <path fill="#96CEF6" d="M5.013 7.375l4.374 4.375.613-.613-3.763-3.762L10 3.612 9.387 3 5.012 7.375z" />
  </svg>
);

const BackButton = ({ text, onClick }) => (
  <StyledButton
    startIcon={chevronLeft}
    variant="text"
    color="primary"
    text={text}
    onClick={onClick}
  />
);

export default BackButton;
