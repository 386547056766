import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";

export const HeaderPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styledMui("div")(({ theme }) => ({
  margin: 0,
  fontFamily: "Avenir",
  fontWeight: 800,
  fontSize: 24,
  lineHeight: "33px",
  color: theme.palette.text.title,
  whiteSpace: "nowrap",
  textTransform: "none",
}));

export const Root = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: 48%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > div {
    margin-left: 10px;
  }
`;

export const Subscribers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
`;
