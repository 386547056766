import React from 'react';
import { Switch as MuiSwitch } from '@mui/material';
import { Wrapper, Text, Label } from './styles';

const Switch = ({ label, isWhite, isLight, disabled, value, onChange, onBlur, name, calculatedValue }) => {
  const isCalculatedValue = calculatedValue !== undefined;
  const inputValue = isCalculatedValue ? calculatedValue : value;

  return (
    <Wrapper
      isWhite={isWhite}
      disabled={disabled}
      isLight={isLight}
    >
      <Label>
        <MuiSwitch
          color="primary"
          name={name}
          checked={inputValue}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
        />
        <Text
          isWhite={isWhite}
          disabled={disabled}
        >
          {label}
        </Text>
      </Label>
    </Wrapper>
  );
};

export default Switch;
