import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { validPassword } from 'utils/validation/customTypes';
import { readableTimeToNow } from 'utils/DateUtils';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import AuthService from 'services/AuthService';
import AccountService from 'account/api/AccountService';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import { Button, FormikField, PasswordInput } from 'components';

import { FormWrapper } from './styles';

const validationSchema = Yup.object().shape({
  currentPassword: validPassword
    .required('Please enter your password.'),
  newPassword: validPassword
    .notOneOf([Yup.ref('currentPassword'), ''], 'Your new password cannot be the same as your current password. Please try another one.')
    .required('Please enter new password.'),
  confirmPassword: validPassword
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords don\'t match. Please try again.')
    .required('Please repeat your password.'),
});

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const UpdatePassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const { user } = AuthService.getUser();

  const closeModal = () => {
    setFormValues(null);
    setIsModalOpen(false);
  };

  const openModal = (values, formikProps) => {
    setFormValues({ values, formikProps });
    setIsModalOpen(true);
  };

  const handleSubmit = async () => {
    setIsModalOpen(false);

    try {
      await AccountService.updatePassword({
        ...formValues.values,
        email: user.email,
      });
      setFormValues(null);
      formValues.formikProps.resetForm();
      showNotifySuccess('Your password was successfully updated.');
    } catch (error) {
      if (error.fieldErrors) {
        formValues.formikProps.setErrors(error.fieldErrors);
      } else if (error.code === '400.1') { // InvalidEmailOrPassword
        formValues.formikProps.setErrors({
          currentPassword: error.error,
        });
      } else if (error.code === '400.6') {
        const { additionalData: { accessLockOutEndDateTime } } = error;
        formValues.formikProps.setErrors({
          currentPassword: `Your account is temporarily locked out. Please, try again in ${readableTimeToNow(accessLockOutEndDateTime)}.`,
        });
      } else {
        showNotifyError(error);
      }
    }
  };

  return (
    <>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={openModal}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <FormikField
                  component={PasswordInput}
                  required
                  name="currentPassword"
                  label="Current Password"
                />
                <FormikField
                  component={PasswordInput}
                  required
                  name="newPassword"
                  label="New Password"
                />
                <FormikField
                  component={PasswordInput}
                  required
                  name="confirmPassword"
                  label="Confirm New Password"
                />
                <Button
                  onClick={submitForm}
                  label="update"
                  color="primary"
                  variant="contained"
                  formNoValidate
                  text="Update Password"
                />
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
      {isModalOpen && (
        <ConfirmationDialog
          open
          title="Update Password"
          confirmButtonTitle="Update"
          onClose={closeModal}
          onCancel={closeModal}
          onConfirm={handleSubmit}
        >
          Are you sure you want to update your password?
          If you continue, your current password will no longer be valid.
        </ConfirmationDialog>
      )}
    </>
  );
};

export default UpdatePassword;
