import { PROJECT_STATUSES } from 'utils/constants';

export const renderStatusTitle = (value) => {
  const matchedStatus = PROJECT_STATUSES.find((status) => status.value === value);

  return matchedStatus ? matchedStatus.viewLabel : `Unknown status ${value}`;
};

export const getColorByStatus = (status, readyToBeReallocated = false) => {
  switch (status) {
    case PROJECT_STATUSES[0].value: {
      if (readyToBeReallocated) {
        return `color: #000;
      background: #F6DB81;`;
      }

      return `color: #123B35;
      background: #88EEAC;`;
    }
    case PROJECT_STATUSES[1].value: {
      return `color: #FFF;
      background: #8F92A3;`;
    }
    default:
      return '';
  }
};
