import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const WeatherAlert = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data,
  } = notify;
  const message = '';

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToProject = () => {
    handleClose();
    closeNotificationList();
    navigate(`/projects/${data.projectId}/details`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={data.projectName}
      title="Weather Alert"
      cancelButtonTitle="Close"
      confirmButtonTitle="Go to Project"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToProject}
        >
      <ConfirmationText
        text={data.value}
            />
    </ConfirmationDialog>
  );
};

export default WeatherAlert;
