import styled from 'styled-components';
import { Form } from 'formik';

import { Button, IconButton, Icon } from 'components';

export const Page = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  background: var(--mainLayoutWorkspaceBackground);
`;

export const Sider = styled.div`
  display: flex;
  justify-content: center;
  width: 62px;
  background-color: var(--sidebarBackground);
  flex-shrink: 0;
  padding-top: 16px;
`;

export const Main = styled.div`
  width: 100%;
  height: fit-content;
  background-color: var(--pageLayoutBackground);
  border-radius: 6px;
  padding: 36px 40px 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--lightTextColor);
`;

export const MainWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  padding: 38px;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const ExpiredMainWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 38px;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  > :not(:last-child){
    margin-bottom: 12px;
  }
`;

export const PageTitle = styled.div`
  margin-bottom: 8px;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--textColor);
`;

export const ProjectInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ProjectName = styled.span`
  margin-right: 20px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
  color: var(--titleColor);
`;

export const StyledIcon = styled(Icon)`
  transform: scale(0.6);
  margin-bottom: 2px;
  svg > path {
    fill: var(--buttonPrimaryColor);
  }
`;

export const ProjectAddress = styled.div`
  display: inline-flex;
  align-items: center;
  padding-left: 8px;
  min-height: 20px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138.5%;
  color: var(--textColor);
  border-left: 1px solid var(--lightBorderColor);
`;

export const DialogContent = styled.div`
  > :not(:last-child){
    margin-bottom: 12px;
  }
`;

export const HintWrapper = styled.div`
  display: flex;
  align-items: center;

  > :first-child {
    margin-bottom: 2px;
  }
`;

export const Subtitle = styled.div`
  margin-bottom: 18px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: var(--textColor);
`;

export const ServiceName = styled.div`
  margin-bottom: 12px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--lightTextColor);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  :not(:last-of-type) {
    margin-bottom: 24px;
  }

  > div:not(:last-of-type) {
    margin-right: 24px;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top: 1px solid var(--notificationItemBorderBottom);
  padding-top: 18px;
  margin-right: 40px;
`;

export const FieldWrapper = styled.div`
  display: flex;
`;

export const FeeProposalInfo = styled.div`
  display: flex;
  margin: 8px 0 50px;
`;

export const OverallCosts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 340px;
  border: 1px solid var(--notificationItemBorderBottom);
  border-radius: 4px;
  padding: 26px;

  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138.5%;
  color: var(--textColor);
`;

export const OverallTitle = styled.div`
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 138.5%;
  color: var(--textColor);
`;

export const CostsSection = styled.div`
  border-bottom: 1px solid var(--lightBorderColor);
  padding: 17px 0;
  width: 100%;

  > * {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
  :nth-child(4) {
    font-weight: 500;
    border-bottom: 1px solid #EFF2F7;
  }
  :last-child {
    font-size: 16px;
    font-weight: 500;
    border-bottom: none;
  }
`;

export const OverallSubTitle = styled.div`
  color: var(--lightTextColor);
`;

export const Cost = styled.span`
  font-weight: 500;
`;

export const TotalCost = styled.span`
  font-weight: 600;
  font-size: 18px;
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 8px;
  align-self: center;
  svg > path {
    fill: var(--buttonPrimaryColor);
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 24px;
`;

export const SuccessMessage = styled.div`
  max-width: 530px;
  margin-left: 150px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: var(--textColor);
`;

export const SuccessMessageTitle = styled.div`
  margin-bottom: 10px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 31px;
  color: var(--titleColor);
`;
