import { styled as styledMui } from '@mui/material/styles';

const devMode = process.env.NODE_ENV === 'development';

export const PageRoot = styledMui('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  padding: '28px 40px',
  backgroundColor: theme.palette.background.default,
  borderRadius: 6,
  color: theme.palette.text.main,
  ...(devMode ? {} : { minWidth: 1080 }),
  '&:last-child': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

// export const PageRoot = styledMui('div')`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   min-height: 100%;
//   padding: 28px 40px;
//   background-color: var(--pageLayoutBackground);
//   border-radius: 6px;
//   color: var(--pageLayoutTextColor);

//   ${() => !devMode && `
//     min-width: 1080px;
//   `}
//   ${({ height }) => height === '100%' && `
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//   `}
// `;

export const PageTitle = styledMui('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 0,
  minHeight: 40,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 11,
  lineHeight: '15px',
  letterSpacing: 0.05,
  textTransform: 'uppercase',
}));

export const ProjectTitle = styledMui('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: 24,
  lineHeight: '33px',
  color: theme.palette.text.main,
}));

export const Header = styledMui('div')`
  margin: 0 0 15px 0;
  padding-bottom: 3px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
`;

export const Content = styledMui('div')`
  position: relative;
  ${() => !devMode &&
    `
    min-width: 1000px;
  `}
`;

export const Footer = styledMui('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138.5%;
`;
