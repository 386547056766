import React, { useRef, useState, useEffect } from 'react';
import { FormControl } from '@mui/material';
import InputMask from 'react-input-mask';

import IconButton from 'components/buttons/IconButton';
import Tooltip from 'components/Tooltip';

import { formatCurrency, getCurrencySymbol } from 'utils/CurrencyUtils';
import { isNumberValid } from 'utils/validation/customValidations';

import { StyledInput, StyledInputLabel, EndIcon, CountButtons, StyledFormHelperText, Link } from './styles';

const Input = ({
  value,
  calculatedValue,
  type,
  phoneCountryMask,
  readOnly,
  required,
  disabled,
  label,
  rows,
  error,
  currency,
  maxLength,
  maxValue,
  maxDecimals,
  setError,
  setValue,
  setTouched,
  onChange,
  fullWidth = true,
  formControlStyle,
  labelTooltip,
  scroll,
  errorExpand,
  linkType,
  maxRowsCount,
  ...props
}) => {
  const inputRef = useRef(null);
  const [cursor, setCursor] = useState(0);

  const isCalculatedValue = calculatedValue !== undefined;
  const inputValue = isCalculatedValue ? calculatedValue : value;
  const isCurrency = type === 'currency' || !!currency;
  const currencySymbol = getCurrencySymbol(currency);
  const isCounter = type === 'counter';
  const isPercentage = type === 'percentage';
  const isPhone = type === 'phone';
  const isDigit = type === 'number';
  const isLink = type === 'link';
  const isNumber = isCurrency || isPercentage || isCounter || isDigit;

  let phonePrefix = '+99';
  if (phoneCountryMask === 'United States') {
    phonePrefix = '+19';
  }
  if (phoneCountryMask === 'United Kingdom') {
    phonePrefix = '+44';
  }

  const handleChange = (event) => {
    if (maxRowsCount) {
      const numberOfLines = String(event.target.value).split(/\r\n|\r|\n/).length;
      if (numberOfLines > maxRowsCount) return;
    }
    if (onChange && (!isNumber || isNumberValid(event.target.value, maxLength, maxValue, maxDecimals))) {
      onChange(event);
    }
  };

  const handleCurrencyChange = (event) => {
    if (onChange && (!isNumber || isNumberValid(event.target.value, maxLength, maxValue))) {
      const newValue = formatCurrency(event.target.value);
      const prevValue = formatCurrency(value);
      const newCommasCount = newValue?.replace(/[^,]/g, '').length;
      const oldCommasCount = prevValue?.replace(/[^,]/g, '').length;
      if (newCommasCount >= 5) return;
      const currCursor = event.target.selectionStart;
      event.target.value = newValue?.replace(',', '');
      onChange(event);
      setCursor(currCursor + newCommasCount - oldCommasCount);
    }
  };

  useEffect(() => {
    // only for currency
    if (!isCurrency) return;
    const target = inputRef.current;
    if (target && !isCalculatedValue) {
      target.setSelectionRange(cursor, cursor);
    }
  }, [value]);

  const handleIncrease = () => {
    if (setValue && isNumberValid(Number(value) + 1, maxLength, maxValue)) {
      setValue(Number(value) + 1);
    }
  };

  const handleDecrease = () => {
    if (setValue && value > 0) {
      setValue(Number(value) - 1);
    }
  };

  const inputProps = {
    maxLength,
    disabled: (disabled && !readOnly) || isCalculatedValue,
    type: isCounter || isDigit ? 'number' : undefined,
  };

  const currencyValue = formatCurrency(inputValue);

  useEffect(() => {
    if (setValue && isCalculatedValue) {
      setValue(calculatedValue);
    }
  }, [calculatedValue]);

  let renderedValue = inputValue;
  if (isCurrency) {
    renderedValue = currencyValue;
  }
  if (isLink) {
    renderedValue = inputValue;
  }

  const renderInput = () => {
    if (isPhone) {
      return (
        <InputMask
          mask={`${phonePrefix}99999999999999999`}
          maskChar=" "
          // all props will be passed to Input components
          value={inputValue}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={disabled && !readOnly}
          multiline={!!rows}
          rows={rows}
          fullWidth={fullWidth}
          disableUnderline
          error={!!error}
          inputProps={inputProps}
          {...props}
        >
          {(inputMaskProps) => (
            <StyledInput
              // readOnly doesn't pass to the Input
              {...{ ...inputMaskProps, readOnly }}
            />
          )}

        </InputMask>
      );
    }

    if (isLink) {
      return (
        <Link target="_blank" href={renderedValue} rel="noopener noreferrer">
          <StyledInput
            inputRef={inputRef}
            readOnly={readOnly}
            disabled={disabled && !readOnly}
            multiline={!!rows}
            rows={rows}
            fullWidth={fullWidth}
            disableUnderline
            error={!!error}
            scroll={scroll}
            value={renderedValue}
            inputProps={inputProps}
            onChange={isCurrency ? handleCurrencyChange : handleChange}
            {...props}
          />
        </Link>
      );
    }

    return (
      <StyledInput
        inputRef={inputRef}
        readOnly={readOnly}
        disabled={disabled && !readOnly}
        multiline={!!rows}
        rows={rows}
        fullWidth={fullWidth}
        disableUnderline
        error={!!error}
        scroll={scroll}
        value={renderedValue}
        inputProps={inputProps}
        onChange={isCurrency ? handleCurrencyChange : handleChange}
        {...props}
      />
    );
  };

  return (
    <FormControl
      variant="filled"
      fullWidth={fullWidth}
      required={required}
      error={!!error}
      disabled={disabled}
      style={formControlStyle}
    >
      <StyledInputLabel
        theme={props.theme}
        error={!!error}
        type={type}
      >
        {label}
        {labelTooltip && <Tooltip text={labelTooltip} />}
      </StyledInputLabel>

      {renderInput()}

      {isCurrency && <EndIcon>{currencySymbol || ' '}</EndIcon>}
      {isPercentage && <EndIcon>%</EndIcon>}
      {isCounter && !isCalculatedValue && !readOnly && (
        <CountButtons>
          <IconButton
            icon="arrowFilled"
            onClick={handleIncrease}
            noWrapper
            disabled={disabled}
            transparent
          />
          <IconButton
            icon="arrowFilled"
            flipHorizontal
            onClick={handleDecrease}
            noWrapper
            disabled={disabled}
            transparent
          />
        </CountButtons>
      )}

      <StyledFormHelperText
        multiline={!!rows}
        errorExpand={errorExpand && error}
      >
        {error || ' '}
      </StyledFormHelperText>
    </FormControl>
  );
};

export default Input;
