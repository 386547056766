import React, { useEffect } from "react";
import { omit, isEmpty } from "lodash";
import { Formik, Form } from "formik";
import { DialogTitle, DialogContent, FormHelperText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import cx from "classnames";

import { showNotifyError } from "services/toaster";

import { Button, CloseButton, Spinner } from "components";

import {
  StyledDialog,
  StyledDialogActions,
  AdditionalErrorsWrapper,
  Footer,
  ScrollableArea,
  SubTitle,
} from "./styles";
import { RISK_STATUS_BLOCKS } from "../../utils/constants";

// Helps display errors for fields not existed in Schema like { id }
const renderAdditionalFieldErrors = (errorsObject) => {
  const result = Object.keys(errorsObject).map((field) => (
    <FormHelperText key={field}>
      {`${field}: ${errorsObject[field]}`}
    </FormHelperText>
  ));
  return result;
};

const FormDialog = ({
  readOnly,
  shortTitle,
  isLoading,
  initialValues,
  validationSchema,
  onSubmit,
  open,
  title,
  children,
  onCancel,
  noCancel,
  onClose,
  noSubmit,
  onReject,
  rejectButtonTitle = "Reject",
  onFormChange,
  enableReinitialize,
  maxWidth,
  className,
  footer,
  noPadding,
  borderedTitle,
  fixedFooter,
  submitButton,
  submitButtonTitle = "Save",
  titleControls,
  noButtons,
  fieldsToChange,
  fieldsToTouch,
  disableBackdropClick,
  onBackdropClick,
  subTitle,
  setErrors,
  formRef,
  notReady,
}) => {
  const theme = useTheme();
  const SubmitButton = submitButton;

  const handleSubmitForm = async (values, formikBag, ...args) => {
    if (noSubmit && onSubmit) {
      onSubmit(values, formikBag, ...args);
    } else if (onSubmit) {
      try {
        const response = await onSubmit(values, formikBag, ...args);
        // for redux actions
        if (response && response.fieldErrors) {
          formikBag.setErrors(response.fieldErrors);
        } else if (onClose && response !== null) {
          onClose();
        }
      } catch (error) {
        // for manually calling API Service
        const { fieldErrors, error: globalError } = error;

        if (fieldErrors) {
          formikBag.setErrors(fieldErrors);
        }
        if (globalError) {
          showNotifyError(globalError);
        }
      }
    }
  };

  // Attention!
  // If you make some fetches on initial mounting of FormDialog
  // You should provide <isLoading> prop to prevent values flickering
  // like from initialValues to loadedValues
  if (isLoading) {
    return <Spinner fullscreen />;
  }

  return (
    <StyledDialog
      className={cx(theme.light && "light-theme", className)}
      open={open}
      onClose={onBackdropClick || onCancel}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={maxWidth}
      isForm
      shortTitle={shortTitle}
      borderedTitle={borderedTitle}
      noPadding={noPadding}
      disableBackdropClick={disableBackdropClick}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        enableReinitialize={enableReinitialize}
        innerRef={formRef}
      >
        {(formikProps) => {
          const {
            isSubmitting,
            submitForm,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
          } = formikProps;

          const errorsWithoutField = omit(errors, Object.keys(initialValues));

          const renderSubmitButton = () => {
            if (noButtons) return null;
            if (submitButton) return <SubmitButton />;
            return (
              <Button
                small
                type="button"
                onClick={readOnly ? onCancel : submitForm}
                label="submit"
                color="primary"
                variant="contained"
                text={submitButtonTitle}
                disabled={isSubmitting || notReady}
                disabledTooltip={!isSubmitting && RISK_STATUS_BLOCKS}
              />
            );
          };

          useEffect(() => {
            if (setErrors) {
              setErrors(errors);
            }
          }, [errors]);

          useEffect(() => {
            if (onFormChange) {
              onFormChange(values);
            }
          }, [values]);

          // TODO: [high-priority]: refactor code to use setFieldValue/setFieldTouched from formik's ref instead of fieldsToChange/fieldsToTouch
          useEffect(() => {
            if (!fieldsToChange || fieldsToChange.length === 0) return;
            if (Array.isArray(fieldsToChange)) {
              fieldsToChange.forEach((field) => setFieldValue(field.name, field.value));
            } else {
              setFieldValue(fieldsToChange.name, fieldsToChange.value);
            }
          }, [fieldsToChange]);

          useEffect(() => {
            if (!fieldsToTouch || fieldsToTouch.length === 0) return;
            if (Array.isArray(fieldsToTouch)) {
              fieldsToTouch.forEach((field) => setFieldTouched(field.name));
            } else {
              setFieldTouched(fieldsToTouch.name);
            }
          }, [fieldsToTouch]);

          return (
            <Form>
              <ScrollableArea fixedFooter={fixedFooter}>
                {subTitle && <SubTitle>{subTitle}</SubTitle>}
                <DialogTitle id="form-dialog-title">
                  <div>{title}</div>
                  {titleControls && React.cloneElement(titleControls)}
                </DialogTitle>

                <CloseButton onClick={onCancel} />

                <DialogContent>
                  {children}

                  {!isEmpty(errorsWithoutField) && (
                    <AdditionalErrorsWrapper>
                      {renderAdditionalFieldErrors(errorsWithoutField)}
                    </AdditionalErrorsWrapper>
                  )}
                </DialogContent>
              </ScrollableArea>

              <Footer isFooter={!!footer} fixedFooter={fixedFooter}>
                {footer && React.cloneElement(footer)}
                <StyledDialogActions>
                  {!noCancel && (
                    <Button
                      color="secondary"
                      small
                      onClick={onCancel}
                      variant="contained"
                      text="Cancel"
                    />
                  )}
                  {onReject && (
                    <Button
                      small
                      color="error"
                      onClick={onReject}
                      variant="contained"
                      text={rejectButtonTitle}
                      disabled={notReady}
                      disabledTooltip={RISK_STATUS_BLOCKS}
                    />
                  )}
                  {renderSubmitButton()}
                </StyledDialogActions>
              </Footer>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};

export default FormDialog;
