import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import Icon from 'components/Icon';

export const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  margin-top: 2px;
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  position: relative;

  li.MuiMenuItem-root {
    width: 100%;
  }
`;

export const TooltipBlock = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const StyledIcon = styledMui(Icon)(({ theme }) => {
  return {
    svg: {
      path: {
        fill: theme.palette.icon.primary.color,
      },
    }
  }
});
