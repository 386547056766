import React from 'react';

const LogoLight = () => {
  return (
    <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <use xlinkHref="#light-logo" />
    </svg>
  );
};

export default LogoLight;
