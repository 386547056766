import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Subscriptions from "subscriptions/pages/Subscriptions";
import AddNewSubscriber from "subscriptions/pages/AddNewSubscriber";
import ViewSubscriber from "subscriptions/pages/ViewSubscriber";
import EditSubscriber from "subscriptions/pages/EditSubscriber";

const SubscriptionsRoutes = () => (
  <Routes>
    <Route
      path="/edit/:subscriberType/:subscriberId/*"
      element={<EditSubscriber/>}
    />
    <Route
      path="/view/:subscriberType/:subscriberId/*"
      element={<ViewSubscriber/>}
    />
    <Route
      path="/view/:subscriberType/subscriber/:subscriberId/*"
      element={<ViewSubscriber/>}
    />
    <Route path="/list/*" exact element={<Subscriptions/>} />
    <Route path="/new" exact element={<AddNewSubscriber/>} />
    <Route
      path="*"
      element={<Navigate to="/subscriptions/list" replace />}
    />
  </Routes>
);

export default SubscriptionsRoutes;
