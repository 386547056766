import React from 'react';

const Calendar = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 1.39999H9V0.399994H8V1.39999H4V0.399994H3V1.39999H1C0.45 1.39999 0 1.84999 0 2.39999V12.4C0 12.95 0.45 13.4 1 13.4H11C11.55 13.4 12 12.95 12 12.4V2.39999C12 1.84999 11.55 1.39999 11 1.39999ZM11 12.4H1V5.39999H11V12.4ZM11 4.39999H1V2.39999H3V3.39999H4V2.39999H8V3.39999H9V2.39999H11V4.39999Z" fill="white" fillOpacity="0.6" />
    </svg>

  );
};

export default Calendar;
