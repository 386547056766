import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AccordionTable from 'components/AccordionTable';
import { ratesList } from 'myCompany/store/rates.selectors';
import { getCurrencySymbol, formatCurrency } from 'utils/CurrencyUtils';
import { TIME_EXPENSES_CATEGORIES_RE } from 'utils/constants';

import { sortList } from '../helpers';
import { DESIGNATIONS } from '../constants';
import RateControls from '../RateControls';
import { DesignationTitle, StyledNoContent } from './styles';

const columns = [
  {
    width: '20%', key: 'category', title: 'Category', noSort: true,
    render: ({ category }) => (TIME_EXPENSES_CATEGORIES_RE.time.find((cat) => cat.value === category).label),
  },
  {
    width: '70%', key: 'ratePerHour', title: 'Hourly Rate', noSort: true,
    render: ({ ratePerHour, currency }) => (`${getCurrencySymbol(currency)}${formatCurrency(ratePerHour)}`),
  },
  {
    width: '10%', key: 'controls', noSort: true,
    minWidth: '80px',
  },
];

const columnControls = [
  {
    width: '87%', key: 'designationTitle', noSort: true, align: 'left',
    render: ({ designationTitle }) => (
      <DesignationTitle>
        {designationTitle}
      </DesignationTitle>
    ),
  },
  {
    width: '13%', key: 'controls', noSort: true, align: 'right',
  },
];

const renderNoRates = () => {
  return (
    <StyledNoContent
      title="There are no time rates yet."
      descr="Click “Add Rates” to add new rate."
      customIcon="expenses"
    />
  );
};

const subtableRenderControls = ({ id }) => {
  return (
    <RateControls
      rateId={id}
      time
    />
  );
};

const defaultSortByParams = { field: 'category', direction: 'Ascending' };

const TimeRatesTable = ({ onRowClick, setCounter }) => {
  const ratesData = useSelector(ratesList);
  const { timeRates, isLoading } = ratesData;
  const [expandedTables, setExpandedTables] = useState([]);
  const [designations, setDesignations] = useState(DESIGNATIONS);

  const data = designations.map((designation) => {
    const { label, value } = designation;
    const items = timeRates.filter((rate) => (rate.designation === value));
    if (items && items.length === 0) return null;
    return {
      items: sortList(items, null, defaultSortByParams),
      designationTitle: label,
      columns,
      subtableRenderControls,
    };
  });
  const items = data.filter((d) => d);

  function handleRowClick(item) {
    if (onRowClick) {
      onRowClick({ ...item, time: true });
    }
  }

  useEffect(() => {
    setCounter({ timeCounter: items.length });
  }, [timeRates]);

  useEffect(() => {
    if (items.length === 1) setExpandedTables([0]);
  }, [ratesData]);

  useEffect(() => {
    const diffDesignations = [...new Set(timeRates.map((t) => t.designation))];
    const designations1 = DESIGNATIONS.slice();
    diffDesignations.forEach((designation) => {
      if (designations1.find((t) => t.value === designation) == null) {
        designations1.push({ value: designation, label: designation });
      }
    });
    setDesignations(designations1);
  }, [timeRates]);

  return (
    <AccordionTable
      nonCheckableRows
      items={items}
      columns={columnControls}
      isLoading={isLoading}
      onRowClick={handleRowClick}
      onNoContent={renderNoRates}
      onRenderControls={null}
      setExpandedTables={setExpandedTables}
      expandedTables={expandedTables}
    />
  );
};

export default TimeRatesTable;
