import React from 'react';

const ChevronDown = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 13.75l6.25-6.25-.875-.875L10 12 4.625 6.625 3.75 7.5 10 13.75z" fill="currentColor" fillOpacity=".87" />
    </svg>
  );
};

export default ChevronDown;
