import qs from 'qs';
import ApiClient from 'services/ApiClient';
import { CreateLocation } from 'types/location';

class LocationService extends ApiClient {
  getLocationsList({ projectId }: { projectId: string }) {
    return this.call({
      method: 'get',
      url: `/locations?${qs.stringify({ projectId })}`,
    });
  }

  addLocation(locationData: CreateLocation) {
    return this.call({
      method: 'post',
      url: '/locations',
      data: {
        ...locationData,
      },
    });
  }

  setPrimaryLocation(id: string) {
    return this.call({
      method: 'put',
      url: `/locations/${id}/set-as-primary`,
    });
  }

  deleteLocation(id: string) {
    return this.call({
      method: 'delete',
      url: `/locations/${id}`,
    });
  }

  getAccessToken() {
    return this.call({
      method: 'get',
      url: '/locations/access-token',
    });
  }
}

export default new LocationService();
