import { combineReducers } from 'redux';

import projects from 'modules/projects/store/projects.reducer';
import notes from 'modules/projects/store/notes.reducer';
import projectNotifications from 'modules/projects/store/notifications.reducer';
import documents from 'modules/projects/store/documents.reducer';
import contacts from 'modules/projects/store/contacts.reducer';
import users from 'modules/projects/store/users.reducer';
import company from 'modules/projects/store/company.reducer';
import budget from 'modules/projects/store/budget.reducer';
import feeProposal from 'modules/projects/store/feeProposal.reducer';
import resources from 'modules/projects/store/resources.reducer';
import inspections from 'modules/projects/store/inspections.reducer';
import reportNotes from 'modules/projects/store/reportNotes.reducer';
import claims from 'modules/projects/store/claims.reducer';
import expenses from 'modules/projects/store/expenses.reducer';
import location from 'modules/projects/store/location.reducer';
import analytics from 'modules/projects/store/analytics.reducer';
import reports from 'modules/projects/store/reports.reducer';

import globalEcosystem from 'modules/globalEcosystem/store/globalEcosystem.reducer';

import dashboard from 'modules/dashboard/store/dashboard.reducer';
import dashboardResources from 'modules/dashboard/store/assignments.reducer';
import dashboardBudgets from 'modules/dashboard/store/budgets.reducer';
import dashboardInspections from 'modules/dashboard/store/inspections.reducer';
import dashboardAnalytics from 'modules/dashboard/store/analytics.reducer';
import dashboardExpenses from 'modules/dashboard/store/expenses.reducer';
import dashboardReports from 'modules/dashboard/store/reports.reducer';
import dashboardRecommendations from 'modules/dashboard/store/recommendations.reducer';
import globalContacts from 'modules/dashboard/store/globalContacts.reducer';

import companyInfo from 'modules/myCompany/store/companyInfo.reducer';
import rates from 'modules/myCompany/store/rates.reducer';
import designations from 'modules/common/store/designations.reducer';
import teamMembers from 'modules/myCompany/store/teamMembers.reducer';

import notification from 'modules/notifications/store/notification.reducer';

import subscriptions from 'modules/subscriptions/store/subscriptions.reducer';
import { createReduxHistoryContext } from 'redux-first-history';
import browserHistory from 'services/routerHistory';
import library from '../modules/library/store/library.reducer';
import communications from '../modules/communication/store/communication.reducer';

// import { createWouterHook } from "redux-first-history/wouter";

const projectReducers = {
  projects,
  notes,
  projectNotifications,
  documents,
  contacts,
  users,
  company,
  budget,
  feeProposal,
  resources,
  inspections,
  reportNotes,
  claims,
  expenses,
  location,
  analytics,
  reports,
};

const dashboardReducers = {
  dashboard,
  dashboardResources,
  dashboardBudgets,
  dashboardInspections,
  dashboardAnalytics,
  dashboardExpenses,
  dashboardReports,
  globalContacts,
  dashboardRecommendations,
};

const reducers = {
  ...dashboardReducers,
  ...projectReducers,
  subscriptions,
  globalEcosystem,
  notification,
  rates,
  designations,
  teamMembers,
  communications,
  companyInfo,
  library,
};

const {
  createReduxHistory: createReduxHistory1,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({ history: browserHistory });

const rootReducer = combineReducers({
  router: routerReducer,
  ...reducers,
});

export const createReduxHistory = createReduxHistory1;
export const routerMiddleWare = routerMiddleware;

export default rootReducer;
