import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import * as ResourcesActions from 'projects/store/resources.reducer';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const InspectionScheduledInLocation = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: {
      projectId, projectName, companyName, countryName, state, region, city,
      companyContactId,
    },
  } = notify;

  const locationString = state ? `${countryName}, ${state || region}, ${city}` : `${countryName}, ${city}`;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToResources = async () => {
    handleClose();
    const suggestion = { companyContactId };
    await dispatch(ResourcesActions.addResourceSuggestionFromInbox(suggestion));
    closeNotificationList();
    navigate(`/projects/${projectId}/resources`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="Project Assignment"
      cancelButtonTitle="Close"
      confirmButtonTitle="Assign"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToResources}
    >
      <ConfirmationText
        value={[companyName, locationString, projectName]}
        text="The {placeholder} risk company has scheduled an inspection in {placeholder}.
        You have {placeholder} unassigned in the same vicinity. Do you wish to assign it to that Risk Company?"
      />
    </ConfirmationDialog>
  );
};

export default InspectionScheduledInLocation;
