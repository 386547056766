import history from 'services/routerHistory';
import AuthService from 'services/AuthService';

export const REQUEST_SUBTYPE = '_STARTED';
export const SUCCESS_SUBTYPE = '_SUCCESS';
export const FAILURE_SUBTYPE = '_FAILURE';

const ERROR_STATUSES = [400, 401, 403, 404, 500];
const { sessionStorage } = window;
const authPath = '/account/signin';

const apiCallMiddleware = ({ dispatch, getState }: any) => (next: any) => (action: { type: any; apiCall: any; shouldCallAPI: any; }) => {
  const { type, apiCall, shouldCallAPI } = action;

  // Normal action: pass it on
  if (!apiCall) {
    return next(action);
  }

  // mechanism for caching || preventing duplicate requests
  if (shouldCallAPI && !shouldCallAPI(getState())) {
    return null;
  }

  const requestType = `${type}${REQUEST_SUBTYPE}`;
  const successType = `${type}${SUCCESS_SUBTYPE}`;
  const failureType = `${type}${FAILURE_SUBTYPE}`;

  dispatch({ type, subtype: requestType, payload: {} }); // isLoading

  return apiCall()
    .then((response: any) => {
      // response.data from axios
      const { data } = response;
      // data.data from Backend API
      const result = data.data || data;

      dispatch({ type, subtype: successType, payload: { result } });

      // allow to handle .then() on action creators if exists
      return result;
    })
    .catch((error: any) => {
      if (error.status && ERROR_STATUSES.includes(error.status)) {
        dispatch({ type, subtype: failureType, payload: { ...error } });
        // Unauthorized
        if (error.status === 401) {
          const { pathname } = window.location;

          AuthService.resetToken();
          if (pathname !== authPath) {
            sessionStorage.setItem('pendingRoute', pathname);
            history.push(authPath);
          }
        }
      } else {
        dispatch({ type, subtype: failureType, payload: { error } });
      }

      // allow to handle .catch() on action creators if exists
      return error;
    });
};

export default apiCallMiddleware;
