import React, { useState } from "react";
import { FormControl, FormHelperText } from "@mui/material";

import { StyledInput, StyledInputLabel, EndIcon, StyledIcon } from "./styles";

const PasswordInput = ({
  value,
  calculatedValue,
  type,
  readOnly,
  required,
  disabled,
  label,
  rows,
  error,
  currencySymbol,
  maxLength,
  maxValue,
  setError,
  setValue,
  setTouched,
  onChange,
  theme,
  ...props
}) => {
  const [currentType, setCurrentType] = useState("password");

  const toggleType = () => {
    if (currentType === "text") {
      setCurrentType("password");
    } else {
      setCurrentType("text");
    }
  };

  const changeHandler = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  const inputProps = {
    maxLength,
  };

  return (
    <FormControl
      variant="filled"
      fullWidth
      required={required}
      error={!!error}
      disabled={disabled}
    >
      <StyledInputLabel error={!!error} type={type}
        theme={theme}>
        {label}
      </StyledInputLabel>

      <StyledInput
        readOnly={readOnly}
        disabled={disabled && !readOnly}
        $multiline={!!rows}
        rows={rows}
        fullWidth
        disableUnderline
        error={!!error}
        value={value}
        inputProps={inputProps}
        onChange={changeHandler}
        type={currentType}
        theme={theme}
        {...props}
      />

      <EndIcon onClick={toggleType} position="end">
        <StyledIcon
          icon={currentType === 'text' ? 'eyeClosed' : 'eye'}
          theme={theme}
          noWrapper
        />
      </EndIcon>

      <FormHelperText>{error || " "}</FormHelperText>
    </FormControl>
  );
};

export default PasswordInput;
