import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 16px 0 24px 0;
`;

export const Tab = styledMui('div')(({ theme, selected }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: '0 0 8px',
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 13,
  lineHeight: '150%',
  color: theme.palette.text.light,
  cursor: 'pointer',
  transition: 'color 0.15s',
  '&:after': {
    position: 'absolute',
    width: '0%',
    left: '50%',
    bottom: 0,
    height: 1,
    borderBottom: '3px solid '.concat(theme.palette.sidebar.selected.icon.background),
    transition: 'all 300ms ease-out 0ms',
    content: '""',
    ...(selected && {
      width: '100%',
      left: 0
    })
  },
  ...(selected && {
    color: theme.palette.sidebar.selected.icon.background,
    fontWeight: 500
  }),
  '&:hover': {
    color: theme.palette.sidebar.selected.icon.background
  },
  '&:not(:last-child)': {
    marginRight: 16
  },
}));

export const Separator = styledMui('div')(({ theme }) => ({
  height: 12,
  margin: '0 16px 10px 0',
  borderLeft: '1px solid '.concat(theme.palette.notifications.item.borderBottom)
}));
