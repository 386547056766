// TODO: adjust all permissions (done: locations, contacts, claims, time&expenses)

import { ModulePermissions } from "types";


export const dashboard: ModulePermissions = {
  // all
  dashboard: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    InsuranceRepresentative: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskManager: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    DeveloperOwner: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    SiteContact: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ACCoordinator: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  },
  // ['ICCoordinator']
  budgets: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  assignments: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  contacts: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ACCoordinator: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  },
  inspections: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    InsuranceRepresentative: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskManager: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    DeveloperOwner: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    SiteContact: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ACCoordinator: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  },
  analytics: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    InsuranceRepresentative: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskManager: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    DeveloperOwner: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    SiteContact: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  expenses: {
    RCCoordinator: {},
    RiskEngineer: {},
  },
  reports: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    RCCoordinator: {},
    RiskEngineer: {},
    SiteContact: {},
  },
  recommendations: {
    ICCoordinator: {},
    ICUnderwriter: {},
  },
};
