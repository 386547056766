import qs from 'qs';
import ApiClient from 'services/ApiClient';
import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 5;

class NoteService extends ApiClient {
  getNotesList({ projectId, page = 1, pageSize = PAGE_SIZE, sortBy }) {
    const query = {
      ProjectId: projectId,
      Page: page,
      PageSize: pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    return this.call({
      method: 'get',
      url: `/notes?${qs.stringify(query)}`,
    });
  }

  addNewNote(noteData) {
    return this.call({
      method: 'post',
      url: '/notes',
      data: {
        ...noteData,
      },
    });
  }

  updateNote(noteData) {
    return this.call({
      method: 'put',
      url: '/notes',
      data: {
        ...noteData,
      },
    });
  }

  deleteNote(noteId) {
    return this.call({
      method: 'delete',
      url: `/notes/${noteId}`,
    });
  }
}

export default new NoteService();
