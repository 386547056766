import React, { Fragment, useReducer, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import PageLayout from 'layouts/PageLayout';
import AddSubscriptionForm from 'subscriptions/components/AddSubscriptionForm';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import SubscriptionsService from 'subscriptions/api/SubscriptionsService';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import { BackButton, Select, Button } from 'components';
import {
  subscribersTypePaths,
  validationSchema,
  validationSchemaIC,
  subscriberTypeOptions,
  addSubscriptionInitialValues,
  initialValues,
  initialValuesIC,
  PACKAGES_IDS,
} from 'subscriptions/constants';
import AddICForm from './AddICForm';
import AddRCForm from './AddRCForm';
import AddBCForm from './AddBCForm';

import {
  HeaderPanel,
  Title,
  Root,
  Subscribers,
  Headers,
  ButtonsContainer,
} from './styles';

const reduceFunc = (prevState, newState) => ({
  ...prevState,
  ...newState,
});

const AddNewSubscriber = () => {
  const navigate = useNavigate();
  const [state, setState] = useReducer(
    reduceFunc,
    addSubscriptionInitialValues,
  );
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    subscriberType,
    type,
    packageType,
    period,
    startDate,
    maxProjectsCount,
  } = state;

  const isIC = subscriberType === 'InsuranceCompany';
  const isBC = subscriberType === 'BrokerCompany';

  const handleSubscribersListClick = () => navigate('/subscriptions');

  const handleOwnerTypeChange = ({ target: { value } }) => {
    setState({
      subscriberType: value,
      packageType:
        value === 'InsuranceCompany' || value === 'BrokerCompany' ? PACKAGES_IDS.Lite : PACKAGES_IDS.Pro,
    });
  };

  const handleCancelClick = () => {
    setConfirmDialogOpened(true);
  };

  const handleCancelConfirmationClick = () => {
    setConfirmDialogOpened(false);
  };

  const handleCloseForm = () => {
    setConfirmDialogOpened(false);
    handleSubscribersListClick();
  };

  const handleSubmit = async (values, formikProps) => {
    setIsSubmitted(true);
    const submitData =
      isIC || isBC
        ? {
          companyInviteInfo: {
            ...values,
            phone: values.phone.replace(/\s/g, ''),
          },
          packageType,
        }
        : values;

    try {
      await SubscriptionsService.addSubscription({
        ...submitData,
        subscriberType,
        type,
        package: packageType,
        period,
        startDate,
        maxProjectsCount,
      });
      const { path } = subscribersTypePaths[subscriberType];
      if (values.primaryUserEmail) {
        showNotifySuccess(
          `New subscriber was successfully added. The notification email was sent to ${values.primaryUserEmail}.`,
        );
      } else {
        showNotifySuccess(
          'New subscriber was successfully added.',
        );
      }
      navigate(`/subscriptions/list/${path}`);
    } catch (error) {
      const { fieldErrors } = error;
      if (fieldErrors) {
        formikProps.setErrors(fieldErrors);
      } else {
        showNotifyError(error);
      }
    } finally {
      setIsSubmitted(false);
    }
  };

  return (
    <PageLayout>
      <Fragment key="title">
        <Headers>
          <HeaderPanel>
            <BackButton
              text="subscribers"
              onClick={handleSubscribersListClick}
            />
          </HeaderPanel>
          <HeaderPanel>
            <Title>Add New Subscriber</Title>
          </HeaderPanel>
        </Headers>
      </Fragment>
      <Fragment key="content">
        <Formik
          initialValues={isIC || isBC ? initialValuesIC : initialValues}
          validationSchema={
            isIC || isBC ? validationSchemaIC : validationSchema
          }
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, submitForm, resetForm }) => {
            useEffect(() => {
              isIC || isBC
                ? setState({ companyInviteInfo: values, subscriberId: null })
                : setState({
                  subscriberId: values.subscriberId,
                  companyInviteInfo: null,
                });
            }, [values, subscriberType]);

            return (
              <>
                <Root>
                  <Subscribers>
                    <Select
                      required
                      value={subscriberType}
                      label="Subscription For"
                      options={subscriberTypeOptions}
                      onChange={handleOwnerTypeChange}
                      noClear
                    />
                    {subscriberType && isIC && (
                      <AddICForm
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {subscriberType && isBC && (
                      <AddBCForm
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {subscriberType && !isIC && !isBC && (
                      <AddRCForm
                        role={subscriberType}
                        resetForm={resetForm}
                        subscriberId={values.subscriberId}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Subscribers>
                  <AddSubscriptionForm
                    subscriberType={subscriberType}
                    state={state}
                    setState={setState}
                  />
                </Root>
                <ButtonsContainer>
                  <Button
                    variant="contained"
                    onClick={handleCancelClick}
                    text="Cancel"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(values1, formikProps) => {
                      return submitForm(values1, formikProps);
                    }}
                    text="Activate & Send Invite"
                    type="submit"
                    disabled={isSubmitted || !subscriberType}
                  />
                </ButtonsContainer>
                {confirmDialogOpened && (
                  <ConfirmationDialog
                    open={confirmDialogOpened}
                    title="Close the form"
                    confirmButtonTitle="Continue"
                    onClose={handleCancelConfirmationClick}
                    onCancel={handleCancelConfirmationClick}
                    onConfirm={handleCloseForm}
                  >
                    Are you sure you want to close the form? The data hasn’t
                    been saved, if you continue it will be lost.
                  </ConfirmationDialog>
                )}
              </>
            );
          }}
        </Formik>
      </Fragment>
    </PageLayout>
  );
};

export default AddNewSubscriber;
