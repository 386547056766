import React, { Fragment, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Button, SearchInput } from 'components';
import PageLayout from 'layouts/PageLayout';
import * as CommunicationsActions from 'communication/store/communication.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  communicationsSortBy,
} from 'communication/store/communication.selectors';
import { HeaderPanel, Title, Controls } from './styles';
import AddCommunication from '../AddCommunication';
import CommunicationsTable from '../../../components/communicationsTable';

const Communications = () => {
  const [searchFilter, setSearchFilter] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [page] = useState(1);
  const sortBy = useSelector(communicationsSortBy);
  const dispatch = useDispatch();
  const debouncedSetSearchFilter = debounce((value) => setSearchFilter(value), 400);

  function handleFilterChange(e) {
    debouncedSetSearchFilter(e.target.value);
  }

  function clearSearchFilter() {
    setSearchFilter('');
  }

  const handleModalOpen = () => setModalOpen(true);

  function getCommunicationsList() {
    dispatch(CommunicationsActions.getCommunicationsList({ page, sortBy, searchFilter }));
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getCommunicationsList();
  }, [page, sortBy, searchFilter]);

  return (
    <PageLayout>
      <Fragment key="title">
        <HeaderPanel>
          <Title>Communications</Title>
        </HeaderPanel>
      </Fragment>
      <Fragment key="subTitleNode">
        <HeaderPanel>
          <Controls>
            <SearchInput
              placeholder="Search by title"
              fullWidth
              value={searchFilter}
              onChange={handleFilterChange}
              onClearSearch={clearSearchFilter}
            />
            <Button
              small
              text="Add Communication"
              color="primary"
              variant="contained"
              onClick={handleModalOpen}
            />
          </Controls>
        </HeaderPanel>
      </Fragment>
      <Fragment key="content">
        <CommunicationsTable
          searchFilter={searchFilter}
          getCommunicationsList={getCommunicationsList}
        />

        {modalOpen && (
          <AddCommunication
            isOpen
            onCancel={handleModalClose}
            formTitle="Add Communication"
            isEdit={false}
            isView={false}
            isAdd
            onClose={handleModalClose}
            onSuccess={getCommunicationsList}
          />
        )}
      </Fragment>
    </PageLayout>
  );
};

export default Communications;
