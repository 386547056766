import { sortBy } from 'lodash';

export const sortList = (list, sortByParams, defaultSortByParams) => {
  let sortedExpenseRates = list;

  const sortFunc = (rate) => rate[sortByParams.field];
  const defaultSortFunc = (rate) => rate[defaultSortByParams.field];

  if (!sortByParams) {
    return defaultSortByParams ? sortBy(list, defaultSortFunc) : list;
  }

  const isAscending = sortByParams.direction === 'Ascending';
  const isDescending = sortByParams.direction === 'Descending';

  if (isAscending) {
    sortedExpenseRates = sortBy(list, sortFunc);
  }
  if (isDescending) {
    sortedExpenseRates = sortBy(list, sortFunc).reverse();
  }

  return sortedExpenseRates;
};
