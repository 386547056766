import React from 'react';

import { TextWrapper, StyledMenuItem } from './styles';

const ActionMenuItem = React.forwardRef(({ text, onClick, disabled }, ref) => (
  <StyledMenuItem disabled={disabled} onClick={onClick} ref={ref}>
    <TextWrapper>
      {text}
    </TextWrapper>
  </StyledMenuItem>
));

export default ActionMenuItem;
