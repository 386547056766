import qs from 'qs';
import ApiClient from 'services/ApiClient';

class AnalyticsService extends ApiClient {
  hasAccess(projectId) {
    return this.call({
      method: 'get',
      url: `/analytics/access?${qs.stringify({ projectId })}`,
    });
  }

  getRiskScore(projectId) {
    return this.call({
      method: 'get',
      url: `/analytics/risk-score?${qs.stringify({ projectId })}`,
    });
  }

  getCharts(projectId) {
    return this.call({
      method: 'get',
      url: `/analytics/charts?${qs.stringify({ projectId })}`,
    });
  }

  getImprovements(projectId) {
    return this.call({
      method: 'get',
      url: `/analytics/improvements?${qs.stringify({ projectId })}`,
    });
  }
}

export default new AnalyticsService();
