import { styled } from '@mui/system';
import { InputLabel, FilledInput, InputAdornment } from '@mui/material';

import Icon from 'components/Icon';

export const StyledIcon = styled(Icon)`
  svg > path {
    fill: var(--iconPrimaryColor);
  }
`;

export const StyledInput = styled(FilledInput)(
  ({ error, readOnly, scroll, theme }) => {
    const styles = {
      backgroundColor: theme?.palette?.input.color,
      borderRadius: 4,
      border: `1px solid ${theme?.palette?.input.border.color}`,
      color: theme.palette.input.text,

      "&:hover": {
        backgroundColor: theme?.palette?.input.hover.color,
      },
      "&.Mui-error": {
        border: `1px solid ${theme?.palette?.error.main}`,
        marginBottom: 12
      },
      "&.Mui-focused": {
        border: `1px solid ${theme?.palette?.input.focused.border}`,
        backgroundColor: theme?.palette?.input.focused.color,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.input.disabled.color,
        color: theme.palette.input.disabled.text,
      },
    };

    if (error) {
      styles.marginBottom = 12;
    }

    if (readOnly) {
      styles.border = `1px solid ${theme.palette.input.readonly.border}`;
      styles.backgroundColor = "transparent";
      styles['pointerEvents'] = "none";
    }
    return styles;
  }
);

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: 14,
  "&.Mui-focused": {
    color: theme.palette.text.main,
  },
}));

export const EndIcon = styled(InputAdornment)`
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 24px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);

  &:hover {
      color: var(--iconHover);
  }

  &:active {
    color: var(--navBarSelectedTabTextColor);
  }
`;
