import qs from 'qs';
import ApiClient from 'services/ApiClient';
import { pickBy } from 'lodash';

import { formatSortField } from 'utils/FormatUtils';
import AuthService from 'services/AuthService';
import { COMPANY_TYPES } from 'types/company';

const PAGE_SIZE = 10;

class CompanyService extends ApiClient {
  principalCompanyPath = AuthService.getCompanyType() === COMPANY_TYPES.BROKER ? '/broker-companies' : '/insurance-companies';

  getGlobalCompanyContactsList({ name, page = 1, pageSize = PAGE_SIZE, sortBy }) {
    const query = pickBy({
      name,
      page,
      pageSize,
    });

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      if (sortBy.field === 'name') {
        query.SortFieldName = 'Info.Name';
      } else {
        query.SortFieldName = formatSortField(sortBy.field);
      }
      query.SortDirection = sortBy.direction;
    }

    return this.call({
      method: 'get',
      url: `/company-contacts?${qs.stringify(query)}`,
    });
  }

  getCompanyContactsForDropdown({ showDetails } = {}) {
    return this.call({
      method: 'get',
      url: `/company-contacts/dropdown?${qs.stringify({ showDetails })}`,
    });
  }

  getCompanyContact(id) {
    return this.call({
      method: 'get',
      url: `/company-contacts/${id}`,
    });
  }

  addCompanyContact(data) {
    return this.call({
      method: 'post',
      url: '/company-contacts',
      data,
    });
  }

  updateCompanyContact(data) {
    return this.call({
      method: 'put',
      url: '/company-contacts',
      data,
    });
  }

  deleteCompanyContact(id) {
    return this.call({
      method: 'delete',
      url: `/company-contacts/${id}`,
    });
  }

  getRiskCompanyContactByInvite(inviteToken) {
    return this.call({
      method: 'get',
      url: `/invites/${inviteToken}/company/risk`,
    });
  }

  getInsuranceCompanyContactByInvite(inviteToken) {
    return this.call({
      method: 'get',
      url: `/invites/${inviteToken}/company/insurance`,
    });
  }

  getBrokerCompanyByInvite(inviteToken) {
    return this.call({
      method: 'get',
      url: `/invites/${inviteToken}/company/broker`,
    });
  }

  getInsuranceCompanyByInvitedICId(id) {
    return this.call({
      method: 'get',
      url: `/invites/by-invited-ic-id/${id}`,
    });
  }

  getRiskCompanyByEmail(email) {
    return this.call({
      method: 'get',
      url: `/risk-companies/by-email?${qs.stringify({ email })}`,
    });
  }

  resendInsuranceCompanyInvite(insuranceCompanyId) {
    return this.call({
      method: 'put',
      url: '/invites/company/insurance/resend',
      data: { insuranceCompanyId },
    });
  }

  getInsuranceCompanySettingsByProject(id) {
    return this.call({
      method: 'get',
      url: `${this.principalCompanyPath}/project/${id}/settings`,
    });
  }
}

export default new CompanyService();
