import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import { NOTIFICATION_ENUM, NOTIFICATION_SUBJECTS } from 'utils/constants';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import RecommendationDescription from './RecommendationDescription';
import RecommendationActions from './RecommendationActions';

const RecommendationApproval = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { type, data: { projectId, projectName, risks, inspectionId } } = notify;

  let risksData;
  try {
    risksData = JSON.parse(risks);
  } catch (e) {
    risksData = [];
  }

  const isRisksAwaitingActions = type === NOTIFICATION_ENUM.recommendationAwaitingAction && risksData;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToRecommendations = () => {
    handleClose();
    closeNotificationList();
    const path = inspectionId
      ? `/projects/${projectId}/recommendations?inspectionId=${inspectionId}`
      : `/projects/${projectId}/recommendations`;

    navigate(path);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title={NOTIFICATION_SUBJECTS[type]}
      cancelButtonTitle="Close"
      confirmButtonTitle="View Recommendations"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToRecommendations}
    >
      <RecommendationDescription notify={notify} />

      {isRisksAwaitingActions && (
        <RecommendationActions
          risksAwaiting={risksData}
        />
      )}
    </ConfirmationDialog>
  );
};

export default RecommendationApproval;
