import React from 'react';

const Image = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.875 8.75a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zm0-2.5a.625.625 0 110 1.25.625.625 0 010-1.25z" fill="#96CEF6" />
      <path d="M16.25 2.5H3.75A1.25 1.25 0 002.5 3.75v12.5a1.25 1.25 0 001.25 1.25h12.5a1.25 1.25 0 001.25-1.25V3.75a1.25 1.25 0 00-1.25-1.25zm0 13.75H3.75V12.5l3.125-3.125 3.494 3.494a1.25 1.25 0 001.762 0l.994-.994L16.25 15v1.25zm0-3.019l-2.244-2.243a1.25 1.25 0 00-1.762 0l-.994.993-3.494-3.493a1.25 1.25 0 00-1.762 0L3.75 10.73V3.75h12.5v9.481z" fill="#96CEF6" />
    </svg>
  );
};

export default Image;
