import styled from 'styled-components';

export const InputWrapper = styled.div`
  .MuiCheckbox-root {
    padding: 0;
    width: 32px;
    height: 32px;
    color: ${({ isLight }) => (isLight ? 'rgba(244, 245, 250, 0.7)' : 'var(--iconPrimaryColor)')};
  }

  .Mui-checked {
    color: var(--sidebarSelectedIconBackground);
  }

  .Mui-disabled {
    color: var(--navTabLabelDisabled);
  } 

  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  color: var(--iconPrimaryColor);

  .MuiCheckbox-root {
    padding: 0;
    width: 32px;
    height: 32px;
    color: ${({ isLight }) => (isLight ? 'rgba(244, 245, 250, 0.7)' : 'var(--iconPrimaryColor)')};
  }

  .Mui-checked {
    color: var(--sidebarSelectedIconBackground);
  }

  .Mui-disabled {
    color: var(--navTabLabelDisabled);
  } 

  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}

  ${({ isWhite }) => isWhite && `
    color: #FFFFFF;
  `}
`;

export const Text = styled.div`
  flex: 1 0;
  margin-top: 2px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--textColor);

  ${({ isWhite }) => isWhite && `
    color: #FFFFFF;
  `}

  ${({ disabled }) => disabled && `
    color: var(--lightBorderColor);
  `}
`;

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: -11px;

  ${({ disabled }) => disabled && `
    cursor: default;
  `}
`;
