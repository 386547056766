import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';
import AuthService from 'services/AuthService';
import { getNextPageInfo, getFolderByRole } from 'help/constants';
import DocumentsSection from 'help/pages/Projects/components/DocumentsSection';

import { Title, TextSection } from '../../styles';

const DocumentsAndContacts = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const contactsSrc = `projects/${folder}/projects-contacts.png`;

  const { title, path, isCurrentPageLast } = getNextPageInfo(role, 'documents-contacts');
  return (
    <>
      <DocumentsSection />
      <ImageContainer src={contactsSrc} alt="contacts" />
      <Title>Contacts</Title>
      <TextSection>
        Contacts sub-section allows you to view the project contacts. All the users that are related
        to the project are listed within that section. By clicking the user, you can view the
        detailed contact information of such user.
      </TextSection>
      {!isCurrentPageLast && <RedirectButton redirectTitle={title} redirectPath={path} />}
    </>
  );
};

export default DocumentsAndContacts;
