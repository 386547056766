import React from 'react';

const Reports = ({ color = '#fff', size = '24', opacity }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" opacity={opacity} width={size} height={size} fill="none" viewBox="0 0 24 24">
      <path fill={color} d="M12.75 15h-1.5v3h1.5v-3zM16.5 13.5H15V18h1.5v-4.5zM9 10.5H7.5V18H9v-7.5z" />
      <path fill={color} d="M18.75 3.75H16.5V3A1.5 1.5 0 0015 1.5H9A1.5 1.5 0 007.5 3v.75H5.25a1.5 1.5 0 00-1.5 1.5V21a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5zM9 3h6v3H9V3zm9.75 18H5.25V5.25H7.5V7.5h9V5.25h2.25V21z" />
    </svg>
  );
};

export default Reports;
