import ContactService from 'common/api/ContactService';

const ADD_CONTACT = 'ADD_CONTACT';
const ADD_CONTACT_FROM_USER = 'ADD_CONTACT_FROM_USER';
const UPDATE_CONTACT = 'UPDATE_CONTACT';
const UPDATE_CONTACT_FROM_USER = 'UPDATE_CONTACT_FROM_USER';
const GET_CONTACT_DATA = 'GET_CONTACT_DATA';
const GET_GLOBAL_CONTACT_DATA = 'GET_GLOBAL_CONTACT_DATA';
const GET_CONTACTS_LIST = 'GET_CONTACTS_LIST';
const GET_GLOBAL_CONTACTS_FOR_POSITION = 'GET_GLOBAL_CONTACTS_FOR_POSITION';
const GET_GLOBAL_INTERNAL_RISK_ENGINEERS = 'GET_GLOBAL_INTERNAL_RISK_ENGINEERS';
const GET_GLOBAL_EXTERNAL_RISK_ENGINEERS = 'GET_GLOBAL_EXTERNAL_RISK_ENGINEERS';
const CONTACTS_LIST_SORT_BY = 'CONTACTS_LIST_SORT_BY';
const GET_COUNTRIES_DATA = 'GET_COUNTRIES_DATA';
const GET_DROPDOWN_CONTACT_DATA = 'GET_DROPDOWN_CONTACT_DATA';

export const getContactsList = (params) => ({
  type: GET_CONTACTS_LIST,
  apiCall: () => ContactService.getContactsList(params),
});

export const getDropdownContactData = (params) => {
  return {
    type: GET_DROPDOWN_CONTACT_DATA,
    apiCall: () => ContactService.getDropdownContactData(params),
  };
};

export const getGlobalContactsForPosition = (params) => ({
  type: GET_GLOBAL_CONTACTS_FOR_POSITION,
  apiCall: () => ContactService.getGlobalContactsForPosition(params),
});

export const getGlobalInternalRiskEngineers = (params) => ({
  type: GET_GLOBAL_INTERNAL_RISK_ENGINEERS,
  apiCall: () => ContactService.getGlobalInternalRiskEngineers(params),
});

export const getGlobalExternalRiskEngineers = (params) => ({
  type: GET_GLOBAL_EXTERNAL_RISK_ENGINEERS,
  apiCall: () => ContactService.getGlobalExternalRiskEngineers(params),
});

export const getContactData = (id) => ({
  type: GET_CONTACT_DATA,
  apiCall: () => ContactService.getContactData(id),
});

export const getGlobalContactData = (id) => ({
  type: GET_GLOBAL_CONTACT_DATA,
  apiCall: () => ContactService.getGlobalContactData(id),
});

export const setContactsSortBy = (sortBy) => ({
  type: CONTACTS_LIST_SORT_BY,
  sortBy,
});

export const addContact = (data) => (dispatch) => dispatch({
  type: ADD_CONTACT,
  apiCall: () => ContactService.addContact(data),
});

export const addContactFromUser = (data) => (dispatch) => dispatch({
  type: ADD_CONTACT_FROM_USER,
  apiCall: () => ContactService.addContactFromUser(data),
});

export const updateContact = (data) => (dispatch) => dispatch({
  type: UPDATE_CONTACT,
  apiCall: () => ContactService.updateContact(data),
});

export const updateContactFromUser = (data) => (dispatch) => dispatch({
  type: UPDATE_CONTACT_FROM_USER,
  apiCall: () => ContactService.updateContactFromUser(data),
});

export const getCountriesData = () => ({
  type: GET_COUNTRIES_DATA,
  apiCall: () => ContactService.getCountriesData(),
});

const initialState = {
  sortBy: null,
  contactsList: {
    items: [],
  },
  contactsDropdown: {},
  contactsForPosition: {},
  globalInternalRiskEngineers: {},
  globalExternalRiskEngineers: {},
  addContact: {},
  countriesData: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case CONTACTS_LIST_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }
    case GET_CONTACTS_LIST: {
      return {
        ...state,
        contactsList: {
          ...state.contactsList,
          ...response,
        },
      };
    }
    case GET_DROPDOWN_CONTACT_DATA: {
      return {
        ...state,
        contactsDropdown: {
          ...state.contactsDropdown,
          ...response,
        },
      };
    }
    case GET_GLOBAL_CONTACTS_FOR_POSITION: {
      return {
        ...state,
        contactsForPosition: {
          ...state.contactsForPosition,
          ...response,
        },
      };
    }
    case GET_GLOBAL_INTERNAL_RISK_ENGINEERS: {
      return {
        ...state,
        globalInternalRiskEngineers: {
          ...state.globalInternalRiskEngineers,
          ...response,
        },
      };
    }
    case GET_GLOBAL_EXTERNAL_RISK_ENGINEERS: {
      return {
        ...state,
        globalExternalRiskEngineers: {
          ...state.globalExternalRiskEngineers,
          ...response,
        },
      };
    }
    case GET_CONTACT_DATA: {
      return {
        ...state,
        contact: {
          response,
        },
      };
    }
    case GET_GLOBAL_CONTACT_DATA: {
      return {
        ...state,
        globalContact: {
          response,
        },
      };
    }
    case ADD_CONTACT: {
      return {
        ...state,
        addContact: {
          ...state.addContact,
          ...response,
        },
      };
    }
    case GET_COUNTRIES_DATA: {
      return {
        ...state,
        countriesData: {
          ...state.countriesData,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
