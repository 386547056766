import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Subscriptions from "non-subscribers/pages/Subscriptions";
import ViewSubscriber from "non-subscribers/pages/ViewSubscriber";

const SubscriptionsRoutes = () => (
  <Routes>
    <Route
      path="/view/:subscriberType/:subscriberId/*"
      element={<ViewSubscriber />}
    />
    <Route
      path="/view/:subscriberType/subscriber/:subscriberId/*"
      element={<ViewSubscriber />}
    />
    <Route path="/list/*" element={<Subscriptions />} />
    {/* <Route path="/non-subscribers/new" exact component={AddNewSubscriber} /> */}

    <Route
      path="/"
      element={<Navigate to="/non-subscribers/list" replace />}
    />
  </Routes>
);

export default SubscriptionsRoutes;
