import React from 'react';

const Help = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1.125a7.875 7.875 0 100 15.75 7.875 7.875 0 000-15.75zM9 15.75a6.75 6.75 0 110-13.5 6.75 6.75 0 010 13.5z" fill="white" />
    <path d="M9 14.063a.844.844 0 100-1.688.844.844 0 000 1.688zM9.563 4.5h-.844a2.525 2.525 0 00-2.531 2.531v.282h1.125V7.03a1.406 1.406 0 011.406-1.406h.844a1.406 1.406 0 110 2.813H8.438v2.53h1.125V9.564a2.531 2.531 0 000-5.063z" fill="white" />
  </svg>
);

export default Help;
