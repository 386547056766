import React from 'react';

const Subscriptions = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path fill="#fff" fillOpacity=".87" d="M17.5 3.75v12.5h-15V3.75h15zm0-1.25h-15a1.25 1.25 0 00-1.25 1.25v12.5A1.25 1.25 0 002.5 17.5h15a1.25 1.25 0 001.25-1.25V3.75A1.25 1.25 0 0017.5 2.5z" />
    <path fill="#fff" fillOpacity=".87" d="M8.125 6.25H3.75V7.5h4.375V6.25zM6.25 8.75h-2.5V10h2.5V8.75zM14.375 11.25h-3.75a1.875 1.875 0 00-1.875 1.875v1.25H10v-1.25a.624.624 0 01.625-.625h3.75a.624.624 0 01.625.625v1.25h1.25v-1.25a1.875 1.875 0 00-1.875-1.875zM12.5 10.625a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0-3.75a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z" />
  </svg>
);

export default Subscriptions;
