import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Communications from "communication/pages/Communications/CommunicationsPage";
import ViewSection from "./pages/Sections/ViewSection";

const CommunicationsRoutes = () => (
  <Routes>
    <Route path="/list" element={<Communications/>} />
    <Route
      path="/view/:communicationId/:id"
      element={<ViewSection />}
    />
    <Route
      path="/"
      element={<Navigate to="/communications/list" replace />}
    />
  </Routes>
);

export default CommunicationsRoutes;
