import styled from 'styled-components';

import { Button } from 'components';

export const Text = styled.div`
  font-family: Graphik LC;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: var(--textColor);
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  margin: 15px 0;
  background: var(--notificationInfoBackground);
  border-radius: 4px;
`;

export const StyledButton = styled(Button)`
  &&& {
    min-width: 0;
    min-height: 0;
    font-family: Graphik LC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
`;
