import React from 'react';

const Mail = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.25 2.625H1.75C1.51794 2.625 1.29538 2.71719 1.13128 2.88128C0.967187 3.04538 0.875 3.26794 0.875 3.5V10.5C0.875 10.7321 0.967187 10.9546 1.13128 11.1187C1.29538 11.2828 1.51794 11.375 1.75 11.375H12.25C12.4821 11.375 12.7046 11.2828 12.8687 11.1187C13.0328 10.9546 13.125 10.7321 13.125 10.5V3.5C13.125 3.26794 13.0328 3.04538 12.8687 2.88128C12.7046 2.71719 12.4821 2.625 12.25 2.625ZM11.2875 3.5L7 6.46625L2.7125 3.5H11.2875ZM1.75 10.5V3.89812L6.75063 7.35875C6.82386 7.40956 6.91087 7.43678 7 7.43678C7.08913 7.43678 7.17614 7.40956 7.24937 7.35875L12.25 3.89812V10.5H1.75Z" fill="white" fillOpacity="0.68" />
    </svg>
  );
};

export default Mail;
