import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as NotificationSelectors from 'notifications/store/notification.selectors';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import { NOTIFICATION_SUBJECTS } from 'utils/constants';

import { MinimizedWrapper, OpenFull, ProjectName, Title, Dot } from './styles';

function MinimizedNotification() {
  const dispatch = useDispatch();
  const pendingNotification = useSelector(NotificationSelectors.getPendingNotification);
  const openedNotification = useSelector(NotificationSelectors.getOpenedNotification);
  const slideOut = useSelector(NotificationSelectors.slideOut);

  const { type, data } = pendingNotification;

  const clearMinimized = () => {
    dispatch(NotificationsActions.setPendingNotification(null));
  };

  const openFullNotification = () => {
    dispatch(NotificationsActions.setOpenedNotification(pendingNotification));
    dispatch(NotificationsActions.setPendingNotification(null));
  };

  useEffect(() => {
    if (openedNotification) clearMinimized();
  }, [openedNotification]);

  if (!data || slideOut) return null;

  return (
    <MinimizedWrapper onClick={openFullNotification}>
      <ProjectName>{data.projectName}</ProjectName>
      <Title>
        {NOTIFICATION_SUBJECTS[type]}
        <Dot icon="unreadNotification" noWrapper />
      </Title>
      <OpenFull icon="openFullScreen" />
    </MinimizedWrapper>
  );
}

export default MinimizedNotification;
