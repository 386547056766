import React from 'react';

const Report = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.61777 11.6659H11.6658V12.9279H6.61777V11.6659ZM6.61777 8.51082H14.1899V9.77284H6.61777V8.51082ZM6.61777 14.8209H9.77282V16.0829H6.61777V14.8209Z" fill="#969EF6" />
      <path d="M16.0829 3.46274H14.1899V2.83173C14.1899 2.49702 14.0569 2.17602 13.8203 1.93935C13.5836 1.70267 13.2626 1.56971 12.9279 1.56971H7.8798C7.5451 1.56971 7.2241 1.70267 6.98742 1.93935C6.75075 2.17602 6.61779 2.49702 6.61779 2.83173V3.46274H4.72476C4.39005 3.46274 4.06905 3.5957 3.83237 3.83238C3.5957 4.06905 3.46274 4.39005 3.46274 4.72476V17.976C3.46274 18.3107 3.5957 18.6317 3.83237 18.8683C4.06905 19.105 4.39005 19.238 4.72476 19.238H16.0829C16.4176 19.238 16.7386 19.105 16.9753 18.8683C17.212 18.6317 17.3449 18.3107 17.3449 17.976V4.72476C17.3449 4.39005 17.212 4.06905 16.9753 3.83238C16.7386 3.5957 16.4176 3.46274 16.0829 3.46274ZM7.8798 2.83173H12.9279V5.35577H7.8798V2.83173ZM16.0829 17.976H4.72476V4.72476H6.61779V6.61779H14.1899V4.72476H16.0829V17.976Z" fill="#969EF6" />
    </svg>
  );
};

export default Report;
