import React from 'react';
import { useSelector } from 'react-redux';

import * as NotificationSelectors from 'notifications/store/notification.selectors';
import { getNotificationsByDay } from 'utils/DateUtils';
import { CloseButton, NoContent } from 'components';

import { NOTIFICATION_ENUM, RECOMMENDATION_TYPES } from 'utils/constants';
import ClaimAlert from 'notifications/components/dialogs/ClaimAlert';
import NotificationItem from '../NotificationItem';
import {
  NotificationsBackdrop, NotificationsRoot, Title, Body,
  Tabs, Tab, SelectedBorder, DayGroup, DayTitle,
} from './styles';
import RegFormSubmitted from '../dialogs/RegFormSubmitted';
import FeeProposalSubmitted from '../dialogs/FeeProposalSubmitted';
import AssignmentReceived from '../dialogs/AssignmentReceived';
import AssignmentConfirmed from '../dialogs/AssignmentConfirmed';
import RecommendationApproval from '../dialogs/RecommendationApproval';
import BudgetReached from '../dialogs/BudgetReached';
import ContractValueIncreased from '../dialogs/ContractValueIncreased';
import ReportGenerated from '../dialogs/ReportGenerated';
import ReportNotification from '../dialogs/ReportNotification';
import InspectionScheduledInLocation from '../dialogs/InspectionScheduledInLocation';
import PasswordExpirationReminder from '../dialogs/PasswordExpirationReminder';
import DocumentsNotifications from '../dialogs/DocumentsNotifications';
import ProjectStatusChanged from '../dialogs/ProjectStatusChanged';
import ProjectNotAssigned from '../dialogs/ProjectNotAssigned';
import PolicyExpire from '../dialogs/PolicyExpire';
import InspectionsNotifications from '../dialogs/InspectionsNotifications';
import ProjectLocationNotAssigned from '../dialogs/ProjectLocationNotAssigned';
import RecommendationsAwaitingIssue from '../dialogs/RecommendationsAwaitingIssue';
import ProjectReallocation from '../dialogs/ProjectReallocation';
import InspectionStartsSoon from '../dialogs/InspectionStartsSoon';
import ApprovalSettingUpdated from '../dialogs/ApprovalSettingUpdated';
import UploadDocumentsPending from '../dialogs/UploadDocumentsPending/index';
import WeatherAlert from '../dialogs/WeatherAlert/index';

const stopPropagation = (event) => {
  event.stopPropagation();
};

const canceledProject = { canceled: true };
const closedProject = { closed: true };

const HIDDEN_NOTIFICATIONS = [
  'OfflineModeReminder',
];

const NotificationsList = ({
  allNotifications,
  newNotifications,
  currentView,
  openedNotification,
  onOpenNotification,
  isAlert,
  onSetViewAll,
  onSetViewNew,
  onClose,
}) => {
  const slideOut = useSelector(NotificationSelectors.slideOut);
  function renderGroupsByDay(items) {
    if (items.length === 0) {
      if (isAlert) {
        return (
          <NoContent
            customIcon="email"
            title="There are no claim alerts yet."
            descr="Your claim alerts will appear here."
                    />
        );
      }
      return (
        <NoContent
          customIcon="email"
          title="There are no notifications yet."
          descr="Your notifications will appear here."
                />
      );
    }

    const notificationsByDay = getNotificationsByDay(items);

    return notificationsByDay.map((dayItem) => (
      <DayGroup key={dayItem.day}>
        <DayTitle>{dayItem.day}</DayTitle>
        {dayItem.items
          .filter((notify) => !HIDDEN_NOTIFICATIONS.includes(notify.type))
          .map((notify) => (
            <NotificationItem
              key={notify.id}
              notify={notify}
              onClick={onOpenNotification}
              isAlert={isAlert}
                        />
          ))
                }
      </DayGroup>
    ));
  }

  const handleBackdropClick = () => {
    if (!openedNotification && onClose) {
      onClose();
    }
  };

  const renderOpenedNotification = () => {
    const { type } = openedNotification;

    if (RECOMMENDATION_TYPES.includes(type)) {
      return <RecommendationApproval notify={openedNotification} />;
    }

    switch (type) {
      case NOTIFICATION_ENUM.claimAlert:
        return <ClaimAlert notify={openedNotification} />;

      case NOTIFICATION_ENUM.registration:
        return <RegFormSubmitted notify={openedNotification} />;

      case NOTIFICATION_ENUM.companyRegistration:
        return <RegFormSubmitted notify={{ ...openedNotification, isCompany: true }} />;

      case NOTIFICATION_ENUM.feeProposal:
        return <FeeProposalSubmitted notify={openedNotification} />;

      case NOTIFICATION_ENUM.assignmentReceived:
        return <AssignmentReceived notify={openedNotification} />;

      case NOTIFICATION_ENUM.assignmentConfirmed:
        return <AssignmentConfirmed notify={openedNotification} />;

      case NOTIFICATION_ENUM.newCompanyAssignmentReceived:
        return <AssignmentReceived notify={openedNotification} />;

      case NOTIFICATION_ENUM.budgetReached:
        return <BudgetReached notify={openedNotification} />;

      case NOTIFICATION_ENUM.contractValueIncreased:
        return <ContractValueIncreased notify={openedNotification} />;

      case NOTIFICATION_ENUM.abridgedReportGenerated:
        return <ReportGenerated notify={openedNotification} />;

      case NOTIFICATION_ENUM.abridgedReportRequestApproval:
        return <ReportGenerated notify={openedNotification} />;

      case NOTIFICATION_ENUM.abridgedReportApproved:
        return <ReportNotification notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.mainReportSentForApproval:
        return <ReportGenerated notify={openedNotification} main />;

      case NOTIFICATION_ENUM.mainReportAvailable:
        return <ReportNotification notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.mainReportAvailableWithoutApproval:
        return <ReportNotification notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.mainReportRejected:
        return <ReportNotification notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.mainReportApproved:
        return <ReportNotification notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.abridgedReportPending:
        return <ReportNotification notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.mainReportPending:
        return <ReportNotification notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.mainReportIssued:
        return <ReportNotification notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.inspectionScheduledInLocation:
        return <InspectionScheduledInLocation notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.passwordExpirationReminder:
        return <PasswordExpirationReminder notify={openedNotification} />;

      case NOTIFICATION_ENUM.filesUploadedToProject:
        return <DocumentsNotifications notify={openedNotification} />;

      case NOTIFICATION_ENUM.filesDeletedFromProject:
        return <DocumentsNotifications notify={openedNotification} isDelete />;

      case NOTIFICATION_ENUM.layoutsUploadedToProject:
        return <DocumentsNotifications notify={openedNotification} isLayouts />;

      case NOTIFICATION_ENUM.riskReviewsUploadedToProject:
        return <DocumentsNotifications notify={openedNotification} isRisks />;

      case NOTIFICATION_ENUM.projectCanceled:
        return <ProjectStatusChanged notify={openedNotification} status={canceledProject} />;

      case NOTIFICATION_ENUM.projectClosed:
        return <ProjectStatusChanged notify={openedNotification} status={closedProject} />;

      case NOTIFICATION_ENUM.projectHasNoAssignment:
        return <ProjectNotAssigned notify={openedNotification} isIC />;

      case NOTIFICATION_ENUM.projectHasNoAssignedRE:
        return <ProjectNotAssigned notify={openedNotification} />;

      case NOTIFICATION_ENUM.projectPolicyExpireSoon:
        return <PolicyExpire notify={openedNotification} />;

      case NOTIFICATION_ENUM.inspectionUpcomingSoon:
        return <InspectionsNotifications notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.riskInspectionScheduled:
        return <InspectionsNotifications notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.virtualInspectionScheduled:
        return <InspectionsNotifications notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.inspectionUpdated:
        return <InspectionsNotifications notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.inspectionCanceled:
        return <InspectionsNotifications notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.inspectionCanceledWithReason:
        return <InspectionsNotifications notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.inspectionScheduleReminder:
        return <InspectionsNotifications notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.projectLocationNotAssigned:
        return <ProjectLocationNotAssigned notify={openedNotification} type={type} />;

      case NOTIFICATION_ENUM.recommendationAwaitingIssue:
        return <RecommendationsAwaitingIssue notify={openedNotification} />;

      case NOTIFICATION_ENUM.projectReallocation:
        return <ProjectReallocation notify={openedNotification} mode="inProgress" />;

      case NOTIFICATION_ENUM.projectCanBeReassigned:
        return <ProjectReallocation notify={openedNotification} mode="reallocate" />;

      case NOTIFICATION_ENUM.inspectionStartingSoon:
        return <InspectionStartsSoon notify={openedNotification} />;

      case NOTIFICATION_ENUM.approvalSettingUpdated:
        return <ApprovalSettingUpdated notify={openedNotification} />;

      case NOTIFICATION_ENUM.missingDocumentsReminder:
        return <UploadDocumentsPending notify={openedNotification} type="3-day" />;

      case NOTIFICATION_ENUM.missingDocumentsDeadline:
        return <UploadDocumentsPending notify={openedNotification} type="deadline" />;

      case NOTIFICATION_ENUM.weatherAlert:
        return <WeatherAlert notify={openedNotification} />;

      case NOTIFICATION_ENUM.furtherActionRiskActioned:
        return <InspectionsNotifications notify={openedNotification} type={type} />;

      default:
        return <div>Notification</div>;
    }
  };

  return (
    <NotificationsBackdrop onClick={handleBackdropClick} animations={slideOut}>
      {!openedNotification && (
        <NotificationsRoot onClick={stopPropagation}>
          <Title>
            {isAlert ? 'Claim Alerts' : 'Notifications'}
          </Title>
          <CloseButton onClick={onClose} top="16px" right="16px" />
          <Tabs>
            <Tab selected={currentView === 'new'} onClick={onSetViewNew}>
              {isAlert ? 'New Alerts' : 'New Notifications'}
              <SelectedBorder />
            </Tab>
            <Tab selected={currentView === 'all'} onClick={onSetViewAll}>
              {isAlert ? 'All Alerts' : 'All Notifications'}
              <SelectedBorder />
            </Tab>
          </Tabs>

          <Body>
            {renderGroupsByDay(currentView === 'new' ? newNotifications : allNotifications)}
          </Body>
        </NotificationsRoot>
      )}

      {openedNotification && renderOpenedNotification()}
    </NotificationsBackdrop>
  );
};

export default NotificationsList;
