import * as Yup from 'yup';

import { noSpecialCharRegexp } from 'utils/validation/customValidations';

const filenameErrorText =
    'Should not contain special characters except ( ) # № / -';
const passwordErrorText =
    'The password must contain 8 or more characters with at least one letter (a-z) and one number (0-9).';

export const nullableString = Yup.string().default('').nullable();

export const nullableNumber = Yup.number().default(0).nullable();

export const nullableMixed = Yup.mixed().default(null).nullable();

export const nullableRequiredString =
    nullableString.required('Field is required.');

export const requiredString = Yup.string().trim().required('Field is required.');

export const requiredMixed = Yup.mixed().required('Field is required.');

export const validPassword = requiredString.matches(
  /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
  passwordErrorText,
);

export const repeatPassword = validPassword
  .oneOf([Yup.ref('password'), ''], "Passwords don't match. Please try again.")
  .required('Please repeat your password.');

export const requiredArrayOfStrings = Yup.array()
  .of(requiredString)
  .min(1, 'Field is required')
  .required('Field is required');

export const positiveNumber = Yup.number().moreThan(0, 'Must be more than 0');

export const positiveNumberNullable = Yup.number()
  .moreThan(0, 'Must be more than 0')
  .nullable();

export const positiveNumberRequired =
    positiveNumber.required('Field is required');

export const filenameRequiredString = requiredString.matches(
  noSpecialCharRegexp,
  filenameErrorText,
);

export const maxWithWords = nullableString.test(
  'Description',
  'Field must be shorter than 1,000 words.',
  (value) => {
    let valid = false;
    const wordCount: number = value
      ?.replace(/\s{2,}/g, ' ')
      .trim()
      .split(' ').length ?? 0;

    if (wordCount <= 1000) {
      valid = true;
    }

    return valid;
  },
);
