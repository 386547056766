import { styled } from '@mui/system';

export const Description = styled('div')(({ theme, isNotificationAndAlerts }) => ({
  position: 'relative',
  margin: 0,
  letterSpacing: '0.16px',
  maxHeight: '51px',
  overflow: 'hidden',
  fontFamily: 'Graphik LC',
  ...(isNotificationAndAlerts && {
    maxHeight: '100px',
    fontSize: '14px',
    lineHeight: '20px',
    '&, & *': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: theme.palette.text.main,
    },
  }),
  ...(!isNotificationAndAlerts && {
    fontSize: '13px',
    lineHeight: '17px',
    '&, & *': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: theme.palette.text.light,
    },
  }),
}));
