import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';

import { Title, TextSection, List } from '../../styles';

const overviewSrc = 'dashboard-overview.png';
const mapSrc = 'dashboard-map.png';
const chartsSrc = 'dashboard-charts.png';
const toolsSrc = 'dashboard-icc-daily-tools.png';

const Overview = () => (
  <>
    <ImageContainer src={overviewSrc} alt="overview" />
    <Title>Overview</Title>
    <TextSection>
      Dashboard section provides you a quick overview of the most important information from all of your projects as
      well as provides a quick access to your main platform tools. The section consists of the following elements:
    </TextSection>
    <List>
      <li>Map</li>
      <li>Charts</li>
      <li>Tools</li>
    </List>
    <ImageContainer src={mapSrc} alt="map" />
    <Title>Map</Title>
    <TextSection>
      The map provides an overview of all projects located in the portfolio. Selecting the dot on the map
      will provide you the project overview with the ability to go to the project and see the full details of the
      selected project.
    </TextSection>
    <ImageContainer src={chartsSrc} alt="charts" />
    <Title>Charts</Title>
    <TextSection>
      The information gathered from all your projects is displayed with a help of charts to provide a quick visual
      overview of the most important data collected grouped by various parameters. Here you can find the visual
      overview of the data grouped by:
    </TextSection>
    <List>
      <li>Project Types</li>
      <li>Recommendation Types</li>
      <li>Recommendation Priorities</li>
      <li>Recommendation Statuses</li>
    </List>
    <ImageContainer src={toolsSrc} alt="tools" />
    <Title>Tools</Title>
    <TextSection>
      The sections that are located on your dashboard and the main tools that you need to perform and overview your
      daily activities. The list of available tools vary based on your system role.
    </TextSection>
    <RedirectButton redirectTitle="Tools" redirectPath="tools" />
  </>
);

export default Overview;
