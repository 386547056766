import qs from 'qs';
import ApiClient from 'services/ApiClient';
import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class CommunicationService extends ApiClient {
  getCommunicationsList({ page = 1, pageSize = PAGE_SIZE, sortBy, searchFilter }) {
    const query = {
      Page: page,
      PageSize: pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    if (searchFilter) {
      query.title = searchFilter;
    }

    return this.call({
      method: 'get',
      url: `/communication?${qs.stringify(query)}`,
    });
  }

  addCommunication(data) {
    return this.call({
      method: 'post',
      url: '/communication',
      data,
    });
  }

  deleteCommunication(id) {
    return this.call({
      method: 'delete',
      url: `/communication/${id}`,
    });
  }

  editCommunication(data) {
    return this.call({
      method: 'put',
      url: '/communication',
      data,
    });
  }

  getActiveCommunication() {
    return this.call({
      method: 'get',
      url: '/communication/active',
    });
  }

  getCommunication(id) {
    return this.call({
      method: 'get',
      url: `/communication/${id}`,
    });
  }

  getHelpCommunication() {
    return this.call({
      method: 'get',
      url: '/communication/help',
    });
  }

  addCommunicationSection(data) {
    return this.call({
      method: 'post',
      url: '/communication/section',
      data,
    });
  }

  deleteCommunicationSection(data) {
    return this.call({
      method: 'delete',
      url: '/communication/section',
      data,
    });
  }

  getSection(id, communicationId) {
    return this.call({
      method: 'get',
      url: `/communication/section/${communicationId}/${id}`,
    });
  }

  updateSection(data) {
    return this.call({
      method: 'put',
      url: '/communication/section',
      data,
    });
  }

  dontShowCommunication(data) {
    return this.call({
      method: 'put',
      url: '/communication/dont-show-communication-for-user',
      data,
    });
  }
}

export default new CommunicationService();
