import React from 'react';

const FolderEmpty = () => {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.283 3.375l1.924 1.918.326.332h7.217v9H2.25V3.375h4.033zm0-1.125H2.25a1.125 1.125 0 00-1.125 1.125v11.25A1.125 1.125 0 002.25 15.75h13.5a1.125 1.125 0 001.125-1.125v-9A1.125 1.125 0 0015.75 4.5H9L7.082 2.582a1.125 1.125 0 00-.799-.332z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default FolderEmpty;
