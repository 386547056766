import React from 'react';
import { useDispatch } from 'react-redux';
import { DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';
import CloseButton from 'components/buttons/CloseButton';
import Button from 'components/buttons/Button';
import * as NotificationsActions from 'notifications/store/notification.reducer';

import { StyledDialog, StyledDialogActions, StyledDialogContent } from './styles';

const NotificationDialog = ({
  title, children, open, footer, onClose, animations, slideOut, closeBtn,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const animationEnd = () => {
    dispatch(NotificationsActions.setSlideOut(false));
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  return (
    <StyledDialog
      className={cx(theme.light && 'light-theme')}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      borderedTitle
      slideOut={slideOut}
      animations={animations}
      onAnimationEnd={animations ? animationEnd : null}
    >
      <DialogTitle id="form-dialog-title">
        {title}
      </DialogTitle>

      <CloseButton onClick={onClose} />

      <StyledDialogContent>
        {children}
      </StyledDialogContent>

      <StyledDialogActions>
        {footer}
        {closeBtn && (
        <Button
          small
          onClick={onClose}
          color="error"
          variant="contained"
          text="Close"
            />
        )}
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default NotificationDialog;
