import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pickBy } from 'lodash';

import FormDialog from 'components/Dialogs/FormDialog';
import { TIME_EXPENSES_CATEGORIES_RE, CURRENCIES } from 'utils/constants';
import { showNotifySuccess } from 'services/toaster';
import { currencyStringToNumber } from 'utils/CurrencyUtils';
import * as RatesActions from 'myCompany/store/rates.reducer';
import { ratesList } from 'myCompany/store/rates.selectors';
import DesignationField from 'common/components/DesignationField';
import { FormikField, Input, Select, RadioButtonGroup } from 'components';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import { DESIGNATIONS,
  TIME_INITIAL_VALUES, EXPENSE_INITIAL_VALUES, EXPENSE_SCHEMA, TIME_SCHEMA, EXPENSE_CATEGORY } from '../constants';

import { RateDialogWrapper, Row } from './styles';
import { COMPANY_TYPES } from 'types/company';

const removeFalsyValues = (data) => pickBy(data, (value) => value || value === 0);

const types = {
  expense: 'Expense',
  time: 'Time',
};
const typeOptions = [
  {
    label: 'Time',
    value: types.time,
  },
  {
    label: 'Expense',
    value: types.expense,
  },
];

const RateDialogForm = ({
  isOpen, mode: initialMode, companyType, rateId, onCancel, onClose = onCancel, time, expense, onSuccess,
}) => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState(initialMode || 'add');
  const [isLoading, setIsLoading] = useState(!!rateId);
  const [valuesExpense, setValuesExpense] = useState(null);
  const [valuesTime, setValuesTime] = useState(null);
  const [createNewDesignation, setCreateNewDesignation] = useState(false);
  const [newDesignationName, setNewDesignationName] = useState('');
  const [additionalDesignations, setAdditionalDesignations] = useState([]);

  let initialFormType;
  if (expense || valuesExpense) initialFormType = types.expense;
  if (time || valuesTime) initialFormType = types.time;
  const [formType, setFormType] = useState(initialFormType || types.time);

  const ratesData = useSelector(ratesList);
  const { timeRates = [], expenseRates = [] } = ratesData;

  const isAdd = mode === 'add';
  const isEdit = mode === 'edit';
  const isView = mode === 'view';

  let dialogTitle;
  if (isAdd) {
    dialogTitle = 'Add Rate';
  }
  if (isEdit) {
    dialogTitle = 'Edit Rate';
  }
  if (isView) {
    dialogTitle = 'View Rate';
  }

  const isExpense = expense || formType === types.expense;
  const isTime = time || formType === types.time;

  const validationSchema = isExpense ? EXPENSE_SCHEMA : TIME_SCHEMA;

  useEffect(() => {
    if (isExpense) {
      setValuesTime(null);
    }
    if (isTime) {
      setValuesExpense(null);
    }
  }, [formType]);

  useEffect(() => {
    if (rateId) {
      setIsLoading(false);
    }
  }, [rateId]);

  const addNewExpenses = async (newExpense) => {
    let response;
    if (isExpense) {
      response = await dispatch(RatesActions.addExpenseRates(newExpense));
    }
    if (isTime) {
      response = await dispatch(RatesActions.addTimeRates(newExpense));
    }
    return response;
  };

  const handleSubmit = async (formValues) => {
    const { costPerItem, ratePerHour } = formValues;
    const sendData = {
      ...formValues,
      costPerItem: currencyStringToNumber(costPerItem),
      ratePerHour: currencyStringToNumber(ratePerHour),
    };
    const newExpense = removeFalsyValues(sendData);

    let response;
    if (isAdd || isEdit) {
      response = await addNewExpenses(newExpense);
    }
    if (response && !response.fieldErrors) {
      onClose();
      const successMessage = isEdit ? 'The time and expenses were successfully updated.' : 'The time and expenses were successfully added.';
      showNotifySuccess(successMessage);
      onSuccess();
    }

    return response;
  };

  const changeModeToEdit = () => {
    setMode('edit');
  };

  const getValuesForRate = () => {
    const data = expenseRates.find((rate) => rate.id === rateId) ||
      timeRates.find((rate) => rate.id === rateId);
    const { id, currency, category, designation, costPerItem, ratePerHour } = data;

    const values = {
      id,
      currency,
      categories: [category],
      costPerItem,
      designations: [designation],
      ratePerHour,
    };

    return removeFalsyValues(values);
  };

  const getFormProps = () => {
    if (isView) {
      return {
        submitButtonTitle: 'Update',
        noSubmit: true,
        onSubmit: changeModeToEdit,
        initialValues: getValuesForRate(),
      };
    }
    if (isEdit) {
      return {
        submitButtonTitle: 'Save',
        noSubmit: false,
        onSubmit: handleSubmit,
        initialValues: getValuesForRate(),
      };
    }
    const initialAddValues = isExpense ? EXPENSE_INITIAL_VALUES : {
      ...TIME_INITIAL_VALUES,
      ...valuesTime,
    };
    return {
      submitButtonTitle: 'Save',
      noSubmit: false,
      onSubmit: handleSubmit,
      initialValues: initialAddValues,
    };
  };

  useEffect(() => {
    if (valuesTime?.designations?.includes('New')) {
      setCreateNewDesignation(true);
    }
  }, [valuesTime]);

  useEffect(() => {
    const diffDesignations = [...new Set(timeRates.map((t) => t.designation))];
    const newDesignations = [];
    diffDesignations.forEach((designation) => {
      const desig = DESIGNATIONS.find((t) => t.value === designation);
      if (desig == null) {
        newDesignations.push({ value: designation, label: designation });
      }
    });
    setAdditionalDesignations(newDesignations);
  }, []);

  const { submitButtonTitle, noSubmit, onSubmit, initialValues } = getFormProps();
  const onFormChange = isExpense ? setValuesExpense : setValuesTime;
  const isExpenseFormReady = isExpense && valuesExpense;
  const isTimeFormReady = isTime && valuesTime;

  const cancelCreateNewDesignation = () => {
    setCreateNewDesignation(false);
    setNewDesignationName('');
  };

  const submitCreateNewDesignation = () => {
    if (valuesTime?.designations?.includes(newDesignationName)) {
      return;
    }
    const newItem = {
      value: newDesignationName,
      label: newDesignationName,
    };
    const newValuesTime = { ...valuesTime, designations: valuesTime?.designations?.filter((t) => t !== 'New').concat(newDesignationName) };
    const newDesignations = additionalDesignations.concat(newItem);
    setValuesTime(newValuesTime);
    setAdditionalDesignations(newDesignations);
    setCreateNewDesignation(false);
    setNewDesignationName('');
  };

  const filterDesignations = (designation) => {
    if (companyType !== COMPANY_TYPES.BROKER) return true;
    return designation.value === 'Engineer';
  }

  return (
    <>
      <FormDialog
        isLoading={isLoading}
        title={dialogTitle}
        submitButtonTitle={submitButtonTitle}
        open={isOpen}
        onCancel={onCancel}
        noSubmit={noSubmit}
        onSubmit={onSubmit}
        onClose={onClose}
        onFormChange={onFormChange}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnMount
    >
        <RateDialogWrapper>
          <RadioButtonGroup
            value={formType}
            setValue={setFormType}
            label="Type"
            options={typeOptions}
            disabled={isView || isEdit}
        />
          {isExpenseFormReady && (
          <>
            <FormikField
              component={Select}
              required
              name="categories"
              label="Category"
              options={EXPENSE_CATEGORY}
              readOnly={isView}
              multiple
            />
            <Row>
              <FormikField
                component={Select}
                required
                readOnly={isView}
                name="currency"
                label="Currency"
                options={CURRENCIES}
              />
              <FormikField
                component={Input}
                required
                type="currency"
                readOnly={isView}
                maxLength={10}
                maxValue={999999.99}
                currency={valuesExpense.currency}
                name="costPerItem"
                label="Cost per Item"
              />
            </Row>
          </>
          )}
          {isTimeFormReady && (
          <>
            <DesignationField
              name="designations"
              readOnly={isView}
              multiple
              isRE
              additionalDesignations={additionalDesignations}
              withNewDesignation={companyType !== COMPANY_TYPES.BROKER}
              filterDesignations={filterDesignations}
              companyType={companyType}
            />
            <FormikField
              component={Select}
              required
              name="categories"
              label="Category"
              options={TIME_EXPENSES_CATEGORIES_RE.time}
              readOnly={isView}
              multiple
            />
            <Row>
              <FormikField
                component={Select}
                required
                readOnly={isView}
                name="currency"
                label="Currency"
                options={CURRENCIES}
              />
              <FormikField
                component={Input}
                type="currency"
                readOnly={isView}
                maxLength={8}
                maxValue={9999.99}
                currency={valuesTime.currency}
                name="ratePerHour"
                label="Rate per Hour"
              />
            </Row>
          </>
          )}
        </RateDialogWrapper>
      </FormDialog>
      {createNewDesignation && (
      <ConfirmationDialog
        open
        onClose={cancelCreateNewDesignation}
        onConfirm={submitCreateNewDesignation}
        onCancel={cancelCreateNewDesignation}
        title="New Designation"
        disabledConfirm={newDesignationName.trim() === ''}
        confirmButtonTitle="Add">
        <Input
          value={newDesignationName}
          label="Name"
          formControlStyle={{ flexBasis: '25%' }}
          onChange={(e) => {
            setNewDesignationName(e.target.value);
          }}
          required
          error={newDesignationName.trim() === '' && 'Name required'}
          errorExpand={newDesignationName.trim() === ''}
           />
      </ConfirmationDialog>
      )}
    </>
  );
};

export default RateDialogForm;
