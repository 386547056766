import React, { Fragment, useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { debounce } from "lodash";

import PageLayout from "layouts/PageLayout";
import NavTabs from "components/NavTabs";
import RiskCompany from "non-subscribers/pages/RiskCompany";
import Broker from "non-subscribers/pages/Broker";
import InsuranceRepresentative from "non-subscribers/pages/InsuranceRepresentative";
import RiskManager from "non-subscribers/pages/RiskManager";
import DeveloperOwner from "non-subscribers/pages/DeveloperOwner";

import { HeaderPanel, Title, NavContainer, StyledSearchInput } from "./styles";

export const tabs = {
  riskCompany: {
    label: "Risk Company",
    value: "RiskCompany",
    path: "risk-company",
  },
  broker: {
    label: "Broker",
    value: "Broker",
    path: "broker",
  },
  insuranceRepresentative: {
    label: "Insurance Representative",
    value: "InsuranceRepresentative",
    path: "insurance-representative",
  },
  riskManager: {
    label: "Risk Manager",
    value: "RiskManager",
    path: "risk-manager",
  },
  developerOwner: {
    label: "Developer Owner",
    value: "DeveloperOwner",
    path: "developer-owner",
  },
};

const Subscriptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchFilter, setSearchFilter] = useState("");
  const navTabs = Object.values(tabs);
  const prevTab = navTabs.find(
    (navTab) => location.pathname.split("/").pop() === navTab.path
  );

  const [selectedTab, setSelectedTab] = useState(
    prevTab && prevTab !== "list" ? prevTab.value : navTabs[0].value
  );

  const debouncedSetSearchFilter = debounce(
    (value) => setSearchFilter(value),
    400
  );

  const handleFilterChange = (event) => {
    debouncedSetSearchFilter(event.target.value);
  };

  const clearSearchFilter = () => {
    setSearchFilter("");
  };

  useEffect(() => {
    const selectedTabObject = navTabs.find((tab) => tab.value === selectedTab);
    navigate(`/non-subscribers/list/${selectedTabObject.path}`);
  }, [selectedTab]);

  return (
    <PageLayout>
      <Fragment key="title">
        <HeaderPanel>
          <Title>Non-Subscribers</Title>
        </HeaderPanel>
      </Fragment>
      <Fragment key="content">
        <NavContainer>
          <NavTabs
            selectedTab={selectedTab}
            onSelectTab={setSelectedTab}
            tabsList={navTabs}
          />
          <StyledSearchInput
            fullWidth
            placeholder="Search by Email"
            value={searchFilter}
            onChange={handleFilterChange}
            onClearSearch={clearSearchFilter}
          />
        </NavContainer>
        <Routes>
          <Route
            path="/risk-company"
            exact
            element={<RiskCompany searchFilter={searchFilter} />}
          ></Route>
          <Route
            path="/broker"
            exact
            element={<Broker searchFilter={searchFilter} />}
          ></Route>
          <Route
            path="/insurance-representative"
            exact
            element={<InsuranceRepresentative searchFilter={searchFilter} />}
          ></Route>
          <Route
            path="/risk-manager"
            exact
            element={<RiskManager searchFilter={searchFilter} />}
          ></Route>
          <Route
            path="/developer-owner"
            exact
            element={<DeveloperOwner searchFilter={searchFilter} />}
          ></Route>
          <Route
            path="/"
            element={
              <Navigate to="/non-subscribers/list/insurance-company" replace />
            }
          />
        </Routes>
      </Fragment>
    </PageLayout>
  );
};

export default Subscriptions;
