import FeeProposalService from 'projects/api/FeeProposalService';

const GET_FEE_PROPOSALS_LIST = 'GET_FEE_PROPOSALS_LIST';
const FEE_PROPOSALS_LIST_SORT_BY = 'FEE_PROPOSALS_LIST_SORT_BY';
const ADD_FEE_PROPOSAL = 'ADD_FEE_PROPOSAL';
const GET_FEE_PROPOSALS_FORM_DATA = 'GET_FEE_PROPOSALS_FORM_DATA';
const SET_NOTIFICATION_FEE_PROPOSAL = 'SET_NOTIFICATION_FEE_PROPOSAL';
const CLEAN_NOTIFICATION_FEE_PROPOSAL = 'CLEAN_NOTIFICATION_FEE_PROPOSAL';

export const getFeeProposalsList = (projectId, sortBy) => (dispatch) => dispatch({
  type: GET_FEE_PROPOSALS_LIST,
  apiCall: () => FeeProposalService.getFeeProposalsList(projectId, sortBy),
});

export const getFeeProposalFormData = (params) => (dispatch) => dispatch({
  type: GET_FEE_PROPOSALS_FORM_DATA,
  apiCall: () => FeeProposalService.getFeeProposalFormData(params),
});

export const setFeeProposalsSortBy = (sortBy) => ({
  type: FEE_PROPOSALS_LIST_SORT_BY,
  sortBy,
});

export const addFeeProposal = (data) => (dispatch) => dispatch({
  type: ADD_FEE_PROPOSAL,
  apiCall: () => FeeProposalService.addFeeProposal(data),
});

export const setNotificationFeeProposal = (notificationFeeProposal) => ({
  type: SET_NOTIFICATION_FEE_PROPOSAL,
  notificationFeeProposal,
});

export const cleanNotificationFeeProposal = () => ({
  type: CLEAN_NOTIFICATION_FEE_PROPOSAL,
});

const initialState = {
  feeProposalsList: {
    items: [],
  },
  feeProposalFormData: {
    data: {},
  },
  notificationFeeProposal: null,
  sortBy: null,
  addFeeProposal: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case FEE_PROPOSALS_LIST_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }

    case GET_FEE_PROPOSALS_LIST: {
      return {
        ...state,
        feeProposalsList: {
          ...response,
          items: response.items || [],
        },
      };
    }

    case GET_FEE_PROPOSALS_FORM_DATA: {
      return {
        ...state,
        feeProposalFormData: {
          ...state.feeProposalFormData,
          ...response,
        },
      };
    }

    case ADD_FEE_PROPOSAL: {
      return {
        ...state,
        addFeeProposal: {
          ...state.addFeeProposal,
          ...response,
        },
      };
    }

    case SET_NOTIFICATION_FEE_PROPOSAL: {
      return {
        ...state,
        notificationFeeProposal: action.notificationFeeProposal,
      };
    }

    case CLEAN_NOTIFICATION_FEE_PROPOSAL: {
      return {
        ...state,
        notificationFeeProposal: null,
      };
    }

    default:
      return state;
  }
};
