import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  background: 'black';
`;

export const Summary = styled.div`
  display: flex;
  height: 116px;
  width: 100%;
  padding: 15px 0;
  margin-bottom: 24px;
  background: var(--tableHeaderBackground);
  border-radius: 6px;

  > div:not(:last-child) {
      border-right: 1px solid var(--textColor);
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  font-family: Avenir;
  letter-spacing: 0.5px;

  ${({ widthPercentage }) => `width: ${widthPercentage}`};
`;

export const Text = styled.div`
  font-weight: 800;
  text-align: center;
  color: var(--textColor);
`;

export const Digits = styled.div`
  margin-top: 20px;
  font-weight: 900;
  font-size: 32px;
  color: var(--sidebarSelectedIconBackground);
`;
