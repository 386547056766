import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';
import AuthService from 'services/AuthService';
import { getNextPageInfo, getFolderByRole } from 'help/constants';
import AnalyticsSection from 'help/pages/Projects/components/AnalyticsSection';

import { Title, TextSection, List, Underline } from '../../styles';

const AnalyticsAndReports = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const reportsSrc = `projects/${folder}/projects-reports.png`;

  const { title, path, isCurrentPageLast } = getNextPageInfo(role, 'analytics-reports');

  return (
    <>
      <AnalyticsSection />
      <ImageContainer src={reportsSrc} alt="reports" />
      <Title>Reports</Title>
      <TextSection>
        Reports sub-section allows you to generate the abridged
        and the main report for the project with a help of our pre-defined templates.
      </TextSection>
      <List>
        <li>
          <Underline>Abrdiged Report</Underline> is generated automatically after clicking the button.
        </li>
        <li>
          <Underline>Main Report</Underline> can be manually customized by the risk engineer.
          When the main report is generated, it should be sent for the approval to the insurance company.
          Once approved, the report is automatically sent to the Broker for distribution.
        </li>
      </List>
      <TextSection>
        When the report is generated for the project and sent, the notification is sent to the relevant stakeholders.
        Once the report is sent, it can also be found under the Reports section on your dashboard.
      </TextSection>
      {!isCurrentPageLast && <RedirectButton redirectTitle={title} redirectPath={path} />}
    </>
  );
};

export default AnalyticsAndReports;
