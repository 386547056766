import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import { getNextPageInfo, getFolderByRole } from 'help/constants';
import DocumentsSection from 'help/pages/Projects/components/DocumentsSection';

import { Title, TextSection } from '../../styles';

const DocumentsAndNotes = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const notesSrc = `projects/${folder}/projects-notes.png`;

  const isICC = role === ROLES_IDS.ICCoordinator;

  const { title, path } = getNextPageInfo(role, 'documents-notes');

  return (
    <>
      <DocumentsSection />
      <ImageContainer src={notesSrc} alt="notes" />
      <Title>Notes</Title>
      {isICC && (
        <>
          <TextSection>
            Notes sub-section allows you to store the important notes about the project.
            In case you don’t want to share the note information with the other project stakeholders,
            you can make it private so that it is only visible to people from your company.
          </TextSection>
          <TextSection>
            Additionally, within that sub-section you can track all the notifications and alerts that go to the other
            project stakeholders. By using that section you track project history and be aware of what is going
            on the project. Note that email notifications are not displayed in that sub-section.
          </TextSection>
        </>
      )}
      {!isICC && (
        <TextSection>
          Notes sub-section allows you to store the important notes about the project.
          When the note is added to the project, it also becomes visible to the insurance company.
        </TextSection>
      )}
      <RedirectButton redirectTitle={title} redirectPath={path} />
    </>
  );
};

export default DocumentsAndNotes;
