import styled from 'styled-components';
import { ListItem, ListItemIcon } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export const Root = styledMui('div')(({ theme, open }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minWidth: 62,
  maxWidth: 292,
  flexShrink: 0,
  padding: open ? '1rem 1.5rem' : '1rem 10px',
  backgroundColor: theme.palette.background.sidebar,
  a: {
    textDecoration: 'none',
  },
}));

export const Wrapper = styled.div`
  position: relative;
  z-index: 5;
  flex: 1 0;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  margin-top: 32px;
  width: ${({ open }) => (open ? '244px' : '30px')};
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  cursor: pointer;

  div {
    word-wrap: nowrap;
    font-size: 20px;
  }
`;

export const ToggleSidebarButton = styledMui('div')(({ theme, open }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10,
  width: 24,
  height: 24,
  bottom: 48,
  right: -33,
  borderRadius: '100px',
  backgroundColor: theme.palette.sidebar.toggle,
  transform: open ? 'rotate(180deg)' : 'unset',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  svg: {
    marginLeft: 1,
  },
}));

export const StyledListItem = styledMui(ListItem)(({ theme, open }) => ({
  '&.MuiListItem-root': {
    borderRadius: 4,
    color: theme.palette.sidebar.text,
    marginBottom: 20,
    height: 42,
    minWidth: 42,
  },
  '& .MuiTypography-body1': {
    fontSize: 16,
    fontWeight: 500,
  },
  '&.MuiListItem-button:hover': {
    backgroundColor: theme.palette.sidebar.hover,
  },
  '&.MuiListItem-root.Mui-selected:not(:hover)': {
    backgroundColor: open && 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.sidebar.selected,
    color: theme.palette.sidebar.selectedText,
  },
  '&.MuiListItem-gutters': {
    padding: 0,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 40,
    justifyContent: 'center',
  },
}));


export const StyledListItem1 = styled(ListItem)`
  &.MuiListItem-root {
    min-width: 42px;
    height: 42px;
    margin-bottom: 20px;
    border-radius: 4px;
    color: var(--sidebarTextColor);
  }

  & .MuiTypography-body1 {
    font-size: 16px;
    font-weight: 500;
  }

  &.MuiListItem-button:hover {
    background-color: var(--sidebarHoverBackground);
  }

  &.MuiListItem-root.Mui-selected:not(:hover) {
    ${(p) => (!p.open && 'background-color: transparent')};
  }

  &.Mui-selected {
    background-color: var(--sidebarSelectedBackground);
    color: var(--sidebarSelectedTextColor);
  }

  &.MuiListItem-gutters {
    padding: 0;
  }

  > .MuiListItemIcon-root {
    min-width: 40px;
    justify-content: center;
  }
`;

export const StyledItemIcon = styled(ListItemIcon)`
  ${(p) => (p.selected && 'background: var(--sidebarSelectedIconBackground)')};
  border-radius: 4px;
  &.MuiListItemIcon-root {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    margin-left: 6px;
    margin-right: 18px;

    svg > path {
      ${(p) => (p.selected && 'fill: var(--sidebarSelectedIcon)')};
      ${(p) => (!p.selected && 'fill: rgb(255, 255, 255)')};
    }
  }
`;

export const DisabledItemWrapper = styled.div`
  position: relative;
`;

export const TooltipAnchor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
