import ExpensesService from 'projects/api/ExpensesService';

const GET_EXPENSES_LIST = 'GET_EXPENSES_LIST';
const GET_CURRENT_EXPENSE = 'GET_CURRENT_EXPENSE';
const GET_ADDITIONAL_EXPENSE_DATA = 'GET_ADDITIONAL_EXPENSE_DATA';
const ADD_EXPENSE = 'ADD_EXPENSE';
const ADD_TIME = 'ADD_TIME';
const GET_CURRENT_EXCHANGE_RATE = 'GET_CURRENT_EXCHANGE_RATE';
const CLEAR_CURRENT_EXCHANGE_RATE = 'CLEAR_CURRENT_EXCHANGE_RATE';
const SET_EXPENSES_LOADED = 'SET_EXPENSES_LOADED';

const mapItem = (expense) => {
  const { resourceExpense, timeExpense, ...rest } = expense;

  return {
    ...rest,
    ...resourceExpense,
    ...timeExpense,
  };
};

const mapSectionsReadyForForm = (data) => {
  const { sections } = data;
  const readySections = sections.map((section) => ({
    ...section,
    items: section.items.map((expense) => mapItem(expense)),
  }));

  const readyData = {
    ...data,
    sections: readySections,
  };

  return readyData;
};

export const getExpensesList = (projectId) => async (dispatch) => {
  const response = await ExpensesService.getExpensesList(projectId);
  const { data: { data } } = response;
  const expenses = mapSectionsReadyForForm(data);

  return dispatch({
    type: GET_EXPENSES_LIST,
    response: expenses,
  });
};

export const getExpense = (params) => async (dispatch) => {
  const response = await ExpensesService.getExpense(params);
  const { data: { data } } = response;
  const expense = mapItem(data);

  return dispatch({
    type: GET_CURRENT_EXPENSE,
    response: expense,
  });
};

export const getExpensesData = (projectId) => (dispatch) => dispatch({
  type: GET_ADDITIONAL_EXPENSE_DATA,
  apiCall: () => ExpensesService.getExpensesData(projectId),
});

export const addExpense = (data) => (dispatch) => dispatch({
  type: ADD_EXPENSE,
  apiCall: () => ExpensesService.addExpense(data),
});

export const addTime = (data) => (dispatch) => dispatch({
  type: ADD_TIME,
  apiCall: () => ExpensesService.addTime(data),
});

export const updateExpense = (data) => (dispatch) => dispatch({
  type: ADD_EXPENSE,
  apiCall: () => ExpensesService.updateExpense(data),
});

export const updateTime = (data) => (dispatch) => dispatch({
  type: ADD_TIME,
  apiCall: () => ExpensesService.updateTime(data),
});

export const getExchangeRate = (params) => async (dispatch) => {
  const response = await ExpensesService.getExchangeRate(params);
  const { data: { data } } = response;

  return dispatch({
    type: GET_CURRENT_EXCHANGE_RATE,
    response: data,
  });
};

export const clearExchangeRate = () => (dispatch) => dispatch({
  type: CLEAR_CURRENT_EXCHANGE_RATE,
});

export const setListAsLoaded = () => (dispatch) => dispatch({
  type: SET_EXPENSES_LOADED,
});

const initialState = {
  expensesList: {
    sections: [],
    totalAmount: 0,
    billedAmount: 0,
    isLoading: true,
  },
  addExpense: {},
  addTime: {},
  serviceTypes: [],
  riskEngineerRates: {},
  riskEngineerCurrency: '',
  currentExpense: null,
  exchangeRate: 1,
};

export default (state = initialState, action) => {
  const { response = {} } = action;
  const { serviceTypes, riskEngineerRates, riskEngineerCurrency } = response;

  switch (action.type) {
    case GET_EXPENSES_LIST: {
      return {
        ...state,
        expensesList: {
          ...initialState.expensesList,
          ...response,
        },
      };
    }
    case GET_CURRENT_EXPENSE: {
      return {
        ...state,
        currentExpense: response,
      };
    }
    case GET_ADDITIONAL_EXPENSE_DATA: {
      return {
        ...state,
        serviceTypes,
        riskEngineerRates,
        riskEngineerCurrency,
      };
    }
    case GET_CURRENT_EXCHANGE_RATE: {
      return {
        ...state,
        exchangeRate: response,
      };
    }
    case CLEAR_CURRENT_EXCHANGE_RATE: {
      return {
        ...state,
        exchangeRate: initialState.exchangeRate,
      };
    }
    case SET_EXPENSES_LOADED: {
      return {
        ...state,
        expensesList: {
          ...state.expensesList,
          isLoading: false,
        },
      };
    }

    default:
      return state;
  }
};
