import React from 'react';

const Pdf = () => {
  return (
    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity=".9" fill="#fff" fillOpacity=".87">
        <path d="M24.67 8.933l-6.767-6.768a.879.879 0 00-.676-.29H6.62A1.934 1.934 0 004.687 3.81v23.203a1.934 1.934 0 001.934 1.934h16.406a1.934 1.934 0 001.934-1.934V9.61a.88.88 0 00-.29-.677zm-7.443-4.737L22.64 9.61h-5.414V4.196zm5.8 22.816H6.621V3.809h8.672v5.8a1.934 1.934 0 001.934 1.934h5.8v15.469z" />
        <path d="M9.492 21h.798v-1.632h.6c.918 0 1.572-.402 1.572-1.308v-.024c0-.9-.63-1.326-1.59-1.326h-1.38V21zm.798-2.208v-1.464h.582c.51 0 .81.198.81.708v.024c0 .474-.282.732-.81.732h-.582zM13.148 21h1.29c1.506 0 2.232-.888 2.232-2.136v-.054c0-1.248-.726-2.1-2.22-2.1h-1.302V21zm.798-.63v-3.03h.462c1.008 0 1.428.558 1.428 1.482v.048c0 .948-.462 1.5-1.422 1.5h-.468zm3.486.63h.798v-1.758h1.463v-.618H18.23v-1.278h1.86v-.636H17.43V21z" />
      </g>
    </svg>
  );
};

export default Pdf;
