import { pickBy } from 'lodash';
import { currencyStringToNumber } from 'utils/CurrencyUtils';
import { dateToString } from 'utils/DateUtils';
import { PLANS_MANAGEMENT, PLANS_CONSTRUCTION } from './constants';

const systemsAndProceduresFields = PLANS_MANAGEMENT.map((t) => t.items).flat().map((plan) => ({ type: plan.name, recommendation: '', observation: 'Average', availability: 'Yes' }));
const constructionGenericFields = PLANS_CONSTRUCTION.map((t) => t.items).flat().map((plan) => ({ type: plan.name, comment: 'New Comment' }));

export const initialValues = {
  projectInformation: {
    weatherCondition: '',
    constructor: '',
    architect: '',
    structuralEngineer: '',
    civilEngineer: '',
    mep: '',
    numberOfLTIs: '',
    comment: '',
    resourcesOnSite: '',
    contractStartDate: '',
    contractEndDate: '',
    extensionOfTimeInWeeks: '',
    changeOrderValue: '',
    spendValue: '',
    contractValue: '',
    contractDurationInWeeks: '',
    durationToDateInWeeks: '',
    totalDuration: '',
    timeRemainingInWeeks: '',
    durationCompletedPercent: '',
    projectedFinalAccount: '',
    forecastFinalAccountPercent: '',
    averageSpendPerWeek: '',
    spendRemaining: '',
    spendRemainingPerWeek: '',
    sectionalValues: [{ value: '', deliveryTarget: '' }],
  },
  systemsAndProcedures: {
    fields: systemsAndProceduresFields,
    categories: [],
  },
  constructionInformation: {
    fields: constructionGenericFields,
    foundation: '',
    frame: '',
    roof: '',
    cladding: '',
    interiorFinishes: '',
    buildingSeparation: '',
    buildingElevation: '',
    compartmentation: '',
    treatedWoodFireProtection: '',
    securitySystem: { values: [], comment: '' },
  },
};

const removeFalsyValues = (data) => pickBy(data, (value) => value || value === 0);

const mapSystemsAndProceduresGenericFields = (values) => PLANS_MANAGEMENT.map((t) => t.items).flat().map((plan) => {
  const existingValue = values.find((value) => value.type === plan.name);

  return existingValue || { type: plan.name, recommendation: '', observation: '', availability: '' };
});

const mapConstructionInformationFields = (values) => {
  return PLANS_CONSTRUCTION.map((t) => t.items).flat().map((plan) => {
    if (plan.name in initialValues.constructionInformation) return null;
    const existingValue = values.find((value) => value.type === plan.name);

    return existingValue || { type: plan.name, comment: '' };
  }).filter((t) => t);
};

export const mapReportNotesToForm = (note) => {
  if (note.questionnaire != null) {
    const response = {
      ...note,
      projectInformation: {
        ...initialValues.projectInformation,
        ...note.projectInformation,
      },
      dynamic: note.responses,
    };

    return response;
  }

  const { projectInformation = {}, systemsAndProcedures = {}, constructionInformation = {} } = note;

  return {
    ...note,
    projectInformation: {
      ...initialValues.projectInformation,
      ...projectInformation,
    },
    systemsAndProcedures: {
      fields: mapSystemsAndProceduresGenericFields(systemsAndProcedures.fields),
      categories: systemsAndProcedures.categories,
    },
    constructionInformation: {
      ...initialValues.constructionInformation,
      ...constructionInformation,
      fields: mapConstructionInformationFields(constructionInformation.fields),
      categories: constructionInformation.categories,
    },
    totalManagementFieldsCount: systemsAndProcedures.totalFieldsCount,
    totalPopulatedManagementsFieldsCount: systemsAndProcedures.populatedFieldsCount,
    totalConstructionFieldsCount: constructionInformation.totalFieldsCount,
    totalPopulatedConstructionFieldsCount: constructionInformation.populatedFieldsCount,
  };
};

export const getReadyForSend = (reportId, { projectInformation, systemsAndProcedures, constructionInformation, answers }, projectId) => {
  const {
    numberOfLTIs, contractValue, resourcesOnSite,
    contractStartDate, contractEndDate, extensionOfTimeInWeeks,
    changeOrderValue, spendValue, sectionalValues,
  } = projectInformation;

  const sectionalValuesValid = sectionalValues
    .map(({ value, deliveryTarget }) => {
      if (!value && !deliveryTarget) return false;

      return {
        deliveryTarget: dateToString(deliveryTarget) || undefined, // otherwise value will be equal '' and could not be converted to System.Nullable`1[System.DateTime]
        value: currencyStringToNumber(value || undefined), // otherwise value will be equal 0 after conversion to number
      };
    });

  const preparedProjectInformation = {
    ...projectInformation,
    numberOfLTIs: numberOfLTIs && Number(numberOfLTIs),
    contractValue: contractValue && currencyStringToNumber(contractValue),
    resourcesOnSite: resourcesOnSite && Number(resourcesOnSite),
    contractStartDate: contractStartDate && dateToString(contractStartDate),
    contractEndDate: contractEndDate && dateToString(contractEndDate),
    extensionOfTimeInWeeks: extensionOfTimeInWeeks && Number(extensionOfTimeInWeeks),
    changeOrderValue: changeOrderValue && currencyStringToNumber(changeOrderValue),
    spendValue: spendValue && currencyStringToNumber(spendValue),
    sectionalValues: sectionalValuesValid.filter((el) => (el)),
  };

  const validProjectInformation = {
    ...removeFalsyValues(preparedProjectInformation),
    constructor: preparedProjectInformation.constructor || '',
  };

  const validSystemsAndProcedures = {
    fields: systemsAndProcedures.fields.map((el) => removeFalsyValues(el)),
  };

  const validConstructionInformation = {
    ...removeFalsyValues(constructionInformation),
    fields: constructionInformation.fields.map((el) => removeFalsyValues(el)),
  };

  return {
    id: reportId,
    projectInformation: validProjectInformation,
    systemsAndProcedures: validSystemsAndProcedures,
    constructionInformation: validConstructionInformation,
    answers,
    projectId,
  };
};
