import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AuthService, { ROLES_IDS } from 'services/AuthService';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const ReportGenerated = ({ notify, main }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = AuthService.getUser();
  const isRE = role === ROLES_IDS.RiskEngineer;

  const { data: { policyNumber, projectName, projectId } } = notify;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToReports = () => {
    handleClose();
    closeNotificationList();
    const reportsRoute = isRE
      ? `/projects/${projectId}/reports`
      : '/dashboard/reports';
    navigate(reportsRoute);
  };
  const title = main ? 'Risk Engineering Report – Approval Required' : 'Abridged Recommendation Report Issued';
  const mainConfirmationText = 'Please be advised that following the inspection of {placeholder}, under the Policy, {placeholder}, the Risk Report has been sent for your approval. Please review the report and should changes be needed, please add your comments for the Risk Engineer to act upon. Or approve the report so this maybe formally issued to the Broker for distribution.';
  const abridgedConformationText = 'Please be advised that following the inspection of the above project with Policy no: {placeholder} the Abridged Risk Recommendation Report has been issued by the Risk Engineer to the Insured. You can now access the report on your dashboard.';
  const values = main ? [projectName, policyNumber] : policyNumber;

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title={title}
      cancelButtonTitle="Close"
      confirmButtonTitle="View Report"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToReports}
    >
      <ConfirmationText
        value={values}
        text={main ? mainConfirmationText : abridgedConformationText}
      />
    </ConfirmationDialog>
  );
};

export default ReportGenerated;
