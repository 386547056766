import React from 'react';

import { NOTIFICATION_ENUM } from 'utils/constants';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const getRecommendationDescription = (notify) => {
  const { type, data: { projectName, numberOfRisks, risks } } = notify;

  switch (type) {
    case NOTIFICATION_ENUM.recommendationRequireApproval:
      return (
        <ConfirmationText
          value={projectName}
          text="Following the inspection of {placeholder}, the recommendations require your approval prior to being issued to the Insured. Please review and approve the recommendations."
        />
      );

    case NOTIFICATION_ENUM.recommendationAwaitingApproval:
      return (
        <ConfirmationText
          value={projectName}
          text="iMR Global has detected that the recommendations for {placeholder} are awaiting your approval prior to being issued to the Insured.
          Please review and approve the recommendations."
        />
      );

    case NOTIFICATION_ENUM.recommendationRejected:
      return (
        <ConfirmationText
          value={`${projectName}, ${numberOfRisks}`}
          text="Following a review of the recommendations for {placeholder} recommendation(s) require amending.
          Please review the suggested amendments and resubmit the recommendations for approval."
        />
      );

    case NOTIFICATION_ENUM.recommendationAmended:
      return (
        <ConfirmationText
          value={projectName}
          text="Following a review of the suggested amendments for {placeholder}, the recommendations have now been updated. Please review the amendments prior to the submission to the Insured."
        />
      );

    case NOTIFICATION_ENUM.recommendationApproved:
      return (
        <ConfirmationText
          value={projectName}
          text="Following a review of the suggested recommendations for {placeholder}, risk recommendations have been approved. You may issue these recommendations to the Insured."
        />
      );

    case NOTIFICATION_ENUM.recommendationAutoApproved:
      return (
        <ConfirmationText
          value={projectName}
          text="Risk recommendations have been automatically approved. You may issue these recommendations to the Insured."
        />
      );

    case NOTIFICATION_ENUM.recommendationIssuedSiteContact:
      return (
        <ConfirmationText
          value={projectName}
          text="Following the inspection of {placeholder}, the risk recommendations have now been issued by our risk engineer.
          Please review the recommendations and update each recommendation according to the priority date to
          ensure that such action is taken within the timescale."
        />
      );

    case NOTIFICATION_ENUM.recommendationIssuedOther:
      return (
        <ConfirmationText
          value={projectName}
          text="Following the inspection of {placeholder}, the risk recommendations have now been issued by our risk engineer."
        />
      );

    case NOTIFICATION_ENUM.recommendationAwaitingAction:
      return (
        <ConfirmationText
          value={projectName}
          text="Following the inspection of {placeholder}, iMR Global has detected that the following recommendations have yet to be actioned."
        />
      );

    case NOTIFICATION_ENUM.recommendationActioned: {
      let risksData;
      try {
        risksData = JSON.parse(risks).map((risk) => risk.number);
      } catch (e) {
        risksData = [];
      }
      const placeholderString = risksData.map(() => '#{placeholder}').join(', ');
      return (
        <ConfirmationText
          value={risksData}
          text={`The Insured has updated the action taken for the following risks: ${placeholderString}. Please review and updated the recommendation status.`}
        />
      );
    }

    case NOTIFICATION_ENUM.recommendationStatusUpdatedSiteContact:
      return (
        <div>
          Further to the action taken to address the recommendations, our risk engineer
          has now reviewed the information and updated the status for actioned risk items.
        </div>
      );

    case NOTIFICATION_ENUM.recommendationStatusUpdatedOther:
      return (
        <div>
          Further to the action taken by the Insured to address the recommendations, our risk engineer
          has now reviewed the information and updated the status for actioned risk items.
        </div>
      );

    case NOTIFICATION_ENUM.recommendationClosedSiteContact:
      return (
        <div>
          Further to the action taken to address the recommendations,
          our risk engineer has now reviewed the information and found that this satisfies the requirements.
          As a result, all risk items are now closed.
        </div>
      );

    case NOTIFICATION_ENUM.recommendationClosedOther:
      return (
        <div>
          Further to the action taken by the Insured to address the recommendations,
          our risk engineer has now reviewed the information and found that this satisfies the requirements.
          As a result, all risk items are now closed.
        </div>
      );

    default:
      return null;
  }
};

const RecommendationDescription = ({ notify }) => {
  return getRecommendationDescription(notify);
};

export default RecommendationDescription;
