import React, { useEffect, useState } from 'react';

import SubscriptionsTable from 'subscriptions/components/SubscriptionsTable';
import SubscriptionsService from 'subscriptions/api/SubscriptionsService';

import { Root, Summary, Block, Text, Digits } from '../styles';

const RiskManager = ({ searchFilter }) => {
  const [summary, setSummary] = useState({
    activeSubscriptionsCount: 0,
  });

  const subscriberType = 'RiskManager';

  const fetchSummary = async () => {
    const { data: { data } } = await SubscriptionsService.getSummaryInfo(subscriberType);
    setSummary(data);
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  return (
    <Root>
      <Summary>
        <Block widthPercentage="100%">
          <Text>Total Active Subscribers</Text>
          <Digits>{summary.activeSubscriptionsCount}</Digits>
        </Block>
      </Summary>
      <SubscriptionsTable searchFilter={searchFilter} subscriberType="RiskManager" />
    </Root>
  );
};

export default RiskManager;
