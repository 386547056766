import styled from 'styled-components';
import { Typography, DialogContent } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';

import { StyledDialog } from 'components/Dialogs/styles';

import { Button, Icon } from 'components';

export const StyledFormDialog = styled(StyledDialog)`
  .MuiDialog-paper {
    max-width: 800px;
    width: 746px;
    height: 746px;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    display: flex;
  }
`;

export const StyledIcon = styled(Icon)`
  margin: 5px;
`;

export const StyledStepper = styled(MobileStepper)`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: transparent;
`;

export const StyledTypography = styled(Typography)`
  margin: 25px 0;
`;

export const StyledButton = styled(Button)`
  width:  140px;
  background: transparent;
  border: solid var(--textColor) 1px;
  color: var(--textColor);
`;

export const NextButton = styled(Button)`
  width:  140px;
`;

export const StyledStartButton = styled(Button)`
  width: 270px;

  > span {
    padding-left: 25px;
  }
`;

export const EmptyButtonContainer = styled.div`
  width: 245px;
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const StartButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  height: 50px;
  margin-top: auto;
  margin-bottom: 20px;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
