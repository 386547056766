import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as ProjectsActions from 'projects/store/projects.reducer';
import NotificationService from 'notifications/api/NotificationService';
import * as ToasterService from 'services/toaster';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import * as NotificationSelectors from 'notifications/store/notification.selectors';
import { NOTIFICATION_ENUM } from 'utils/constants';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import NotificationDialog from 'components/Dialogs/NotificationDialog';

import FormDescription from './FormDescription';
import { Status, StyledIcon } from './styles';

const AcceptedStatus = () => (
  <Status>
    <StyledIcon icon="accepted" />
    Accepted
  </Status>
);

const AssignmentReceived = ({ notify }) => {
  const { type, data: { projectId } } = notify;
  const isCompany = type === NOTIFICATION_ENUM.newCompanyAssignmentReceived;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slideOut = useSelector(NotificationSelectors.slideOut);

  const handleClose = (event) => {
    event.stopPropagation();
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const handleAcknowledge = async (event) => {
    event.stopPropagation();
    try {
      const { id } = notify;
      await NotificationService.acknowledgeAssignment(id);
      await dispatch(NotificationsActions.markAsViewed(notify.id));
      await dispatch(NotificationsActions.setOpenedNotification(null));
      dispatch(NotificationsActions.getAllInboxNotificationsList());
      ToasterService.showNotifySuccess('New assignment was successfully confirmed.');
    } catch (error) {
      ToasterService.showNotifyError(error);
    } finally {
      dispatch(ProjectsActions.getProjectsList({ page: 1 }));
    }
  };

  const redirectToResources = (event) => {
    event.stopPropagation();
    handleClose(event);
    closeNotificationList();
    dispatch(NotificationsActions.markAsViewed(notify.id));
    navigate(`/projects/${projectId}/resources`);
  };

  if (notify.isViewed && !isCompany) {
    return (
      <NotificationDialog
        open
        projectName={notify.data.projectName}
        title="New Assignment"
        footer={<AcceptedStatus />}
        onClose={handleClose}
      >
        <FormDescription notify={notify} redirectStraightToProject />
      </NotificationDialog>
    );
  }

  const confirmButton = isCompany ? {
    confirmButtonTitle: 'Assign Resource',
    onConfirm: redirectToResources,
  } : {
    confirmButtonTitle: 'Acknowledge Assignment',
    onConfirm: handleAcknowledge,
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={notify.data.projectName}
      title="New Assignment"
      cancelButtonTitle="Cancel"
      confirmButtonTitle={confirmButton.confirmButtonTitle}
      onClose={handleClose}
      onCancel={handleCancel}
      onConfirm={confirmButton.onConfirm}
      slideOut={slideOut}
      animations
      withScroll
    >
      <FormDescription notify={notify} />
    </ConfirmationDialog>
  );
};

export default AssignmentReceived;
