import React from 'react';

import AuthService from 'services/AuthService';

const WarningNew = () => {
  const theme = AuthService.getTheme();

  const mainColor = theme === 'light' ? '#0A2463' : 'rgba(255, 255, 255, 0.87)';
  const circleColor = theme === 'light' ? '#F2C94C' : '#F6DB81';

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 13.75C9.81458 13.75 9.63332 13.805 9.47915 13.908C9.32498 14.011 9.20482 14.1574 9.13386 14.3287C9.06291 14.5 9.04434 14.6885 9.08051 14.8704C9.11669 15.0523 9.20598 15.2193 9.33709 15.3504C9.4682 15.4815 9.63525 15.5708 9.8171 15.607C9.99896 15.6432 10.1875 15.6246 10.3588 15.5536C10.5301 15.4827 10.6765 15.3625 10.7795 15.2083C10.8825 15.0542 10.9375 14.8729 10.9375 14.6875C10.9375 14.4389 10.8387 14.2004 10.6629 14.0246C10.4871 13.8488 10.2486 13.75 10 13.75ZM9.375 6.875H10.625V12.5H9.375V6.875Z" fill={mainColor} fillOpacity="0.8" />
      <path d="M18.125 18.125H1.87496C1.76755 18.1252 1.6619 18.0977 1.5682 18.0452C1.4745 17.9927 1.3959 17.917 1.34 17.8253C1.28409 17.7336 1.25276 17.629 1.24903 17.5217C1.24531 17.4143 1.2693 17.3078 1.31871 17.2125L9.44371 1.58746C9.49616 1.48509 9.57584 1.39917 9.67399 1.33918C9.77213 1.27918 9.88493 1.24744 9.99996 1.24744C10.115 1.24744 10.2278 1.27918 10.3259 1.33918C10.4241 1.39917 10.5038 1.48509 10.5562 1.58746L18.6812 17.2125C18.7306 17.3078 18.7546 17.4143 18.7509 17.5217C18.7472 17.629 18.7158 17.7336 18.6599 17.8253C18.604 17.917 18.5254 17.9927 18.4317 18.0452C18.338 18.0977 18.2324 18.1252 18.125 18.125ZM2.90621 16.875H17.0937L9.99996 3.23121L2.90621 16.875Z" fill={mainColor} fillOpacity="0.8" />
      <path d="M14.75 8.25C16.2688 8.25 17.5 7.01878 17.5 5.5C17.5 3.98122 16.2688 2.75 14.75 2.75C13.2312 2.75 12 3.98122 12 5.5C12 7.01878 13.2312 8.25 14.75 8.25Z" fill={circleColor} />
    </svg>
  );
};

export default WarningNew;
