import styled from 'styled-components';

import InfoCard from 'common/components/InfoCard';

export const StyledInfoCard = styled(InfoCard)`
  margin-top: 20px;
`;

export const Text = styled.div`
  margin-top: 20px;
`;
