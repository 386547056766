import React, { useEffect, useState, Fragment } from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FieldArray } from 'formik';
import cx from 'classnames';
import qs from 'qs';

import AuthService from 'services/AuthService';
import FeeProposalService from 'projects/api/FeeProposalService';
import * as FeeProposalActions from 'projects/store/feeProposal.reducer';
import { feeProposalFormData } from 'projects/store/feeProposal.selectors';
import { EXPENSE_CATEGORIES } from 'utils/constants';
import { requiredString } from 'utils/validation/customTypes';
import { findOptionByValue } from 'utils/FormatUtils';
import { formatCurrency, getCurrencySymbol, currencyStringToNumber } from 'utils/CurrencyUtils';
import { showNotifyError } from 'services/toaster';
import OpenAttachmentButton from 'common/components/OpenAttachmentButton';

import { Button, Input, Select, FormikField, Icon, Tooltip, ActionButton } from 'components';

import { HighlightedText } from 'components/Dialogs/styles';
import {
  StyledIcon, StyledButton, StyledIconButton,
  Sider, Main, Page, Header, PageTitle, MainWrapper,
  ProjectAddress, ProjectInfo, ProjectName,
  HintWrapper, DialogContent,
  FeeProposalInfo, Subtitle, ServiceName, Row,
  OverallCosts, OverallTitle, CostsSection, OverallSubTitle, Cost, TotalCost,
  StyledForm, FieldWrapper, ExpiredMainWrapper,
  SuccessMessage, SuccessMessageTitle,
} from './styles';

const SUBMIT_FEE_PROPOSAL_SCHEMA = Yup.object().shape({
  engineerRates: Yup.array().of(
    Yup.object().shape({
      serviceType: requiredString,
      amount: requiredString,
      hours: requiredString,
      inspectionCount: requiredString,
    }),
  ),

  travelCost: Yup.object().shape({
    amount: requiredString,
    hours: requiredString,
    inspectionCount: requiredString,
  }),

  expenseCosts: Yup.array().of(
    Yup.object().shape({
      category: requiredString,
      amount: requiredString,
      count: requiredString,
    }),
  ),
});

const submitFeeProposalInitialValues = process.env.NODE_ENV === 'development' ? {
  engineerRates: [
    {
      serviceType: '',
      amount: 500,
      hours: 30,
      inspectionCount: 1,
    },
  ],

  travelCost: {
    amount: 600,
    hours: 40,
    inspectionCount: 1,
  },

  expenseCosts: [
    {
      category: '',
      amount: 300,
      count: 8,
    },
  ],
} : {
  engineerRates: [
    {
      serviceType: '',
      amount: '',
      hours: '',
      inspectionCount: '',
    },
  ],

  travelCost: {
    amount: '',
    hours: '',
    inspectionCount: '',
  },

  expenseCosts: [
    {
      category: '',
      amount: '',
      count: '',
    },
  ],
};

const SubmitFeeProposalPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = AuthService.getTheme();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { feeProposalId, companyContactId, insuranceCompanyEmail } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const formData = useSelector(feeProposalFormData);
  const { feeProposalFileInfo } = formData;

  const fetchFormData = () => {
    dispatch(FeeProposalActions.getFeeProposalFormData({ feeProposalId, companyContactId }));
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const feeProposalData = {
      feeProposalId,
      companyContactId,
      engineerRates: values.engineerRates.map((item) => (
        { ...item, amount: currencyStringToNumber(item.amount) }
      )),
      travelCost: {
        ...values.travelCost,
        amount: currencyStringToNumber(values.travelCost.amount),
      },
      expenseCosts: values.expenseCosts.map((item) => (
        { ...item, amount: currencyStringToNumber(item.amount) }
      )),
    };

    try {
      await FeeProposalService.submitFeeProposal(feeProposalData);
      setShowSuccessMessage(true);
    } catch (error) {
      if (error.fieldErrors) {
        setErrors(error.fieldErrors);
      } else {
        showNotifyError('Fee Proposal cannot be submitted');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const renderFieldArray = (fieldValues, arrayHelpers) => {
    const filteredExpenseOptions = EXPENSE_CATEGORIES
      .filter((category) => !fieldValues
        .some((fieldValue) => category.value === fieldValue.category));

    const renderButtonAddExpenseCost = () => {
      return (
        <div>
          <Button
            startIcon={<Icon icon="plus" noWrapper />}
            variant="text"
            color="primary"
            text="Add Expense Cost"
            onClick={() => arrayHelpers.push(submitFeeProposalInitialValues.expenseCosts[0])}
          />
        </div>
      );
    };

    if (fieldValues.length === 0) {
      return renderButtonAddExpenseCost();
    }

    return (
      fieldValues.map((value, index) => {
        const selectedOption = findOptionByValue(value.category, EXPENSE_CATEGORIES);
        const fieldExpenseOptions = selectedOption
          ? [selectedOption, ...filteredExpenseOptions]
          : filteredExpenseOptions;

        return (
          <Fragment key={index}>
            <Row>
              <FormikField
                component={Select}
                required
                name={`expenseCosts[${index}].category`}
                label="Expense Title"
                options={fieldExpenseOptions}
                formControlStyle={{ flexBasis: '25%' }}
              />
              <Input
                label="Currency"
                formControlStyle={{ flexBasis: '25%' }}
                calculatedValue={`${getCurrencySymbol(formData.projectCurrency)} ${formData.projectCurrency}`}
              />
              <FormikField
                component={Input}
                required
                currency={formData.projectCurrency}
                name={`expenseCosts[${index}].amount`}
                label="Charge"
                maxLength={7}
                formControlStyle={{ flexBasis: '25%' }}
              />
              <FieldWrapper style={{ flexBasis: '25%' }}>
                <FormikField
                  component={Input}
                  required
                  name={`expenseCosts[${index}].count`}
                  label="Items"
                  maxLength={2}
                  type="counter"
                />
                <StyledIconButton
                  icon="deleteSmall"
                  label="Previous page"
                  transparent
                  onClick={() => arrayHelpers.remove(index)}
                />
              </FieldWrapper>
            </Row>

            {index === (fieldValues.length - 1) && index < 8 && (
              renderButtonAddExpenseCost()
            )}
          </Fragment>
        );
      })
    );
  };

  useEffect(() => {
    fetchFormData();
  }, []);

  if (showSuccessMessage) {
    return (
      <Page className={cx(theme === 'light' && 'light-theme')}>
        <Sider>
          <Icon icon="logo" />
        </Sider>
        <ExpiredMainWrapper>
          <SuccessMessage>
            <SuccessMessageTitle>Fee proposal is sent</SuccessMessageTitle>
            Thank you, your fee proposal has been successfully sent to the insurance company.
            Once it is reviewed, you will be notified via email.
          </SuccessMessage>
        </ExpiredMainWrapper>
      </Page>
    );
  }

  if (!(formData.isLoading === false)) {
    return null;
  }

  if (formData.error || formData.fieldErrors) {
    return (
      <Page className={cx(theme === 'light' && 'light-theme')}>
        <Sider>
          <Icon icon="logo" />
        </Sider>
        <ExpiredMainWrapper>
          <SuccessMessage>
            <SuccessMessageTitle>The link has expired</SuccessMessageTitle>
            <div>Sorry, your fee proposal link has expired.</div>
            <div>
              Please contact your insurance company
              {' '}
              { insuranceCompanyEmail && (
              <>
                at
                {' '}
                <HighlightedText>{insuranceCompanyEmail}</HighlightedText>
                {' '}
              </>
              )}
              to get access to the portal.
            </div>
          </SuccessMessage>
        </ExpiredMainWrapper>
      </Page>
    );
  }

  submitFeeProposalInitialValues.engineerRates = Object.keys(formData.feeProposalServiceTypes).map((serviceType) => ({
    ...submitFeeProposalInitialValues.engineerRates[0],
    serviceType: serviceType === 'projectType' ? 'ProjectType' : serviceType.toUpperCase(),
    ...(formData.feeProposalInspectionCount && { inspectionCount: formData.feeProposalInspectionCount }),
  }));
  submitFeeProposalInitialValues.travelCost.inspectionCount = formData.feeProposalInspectionCount ||
    submitFeeProposalInitialValues.travelCost.inspectionCount;

  return (
    <Page className={cx(theme === 'light' && 'light-theme')}>
      <Sider>
        <Icon icon="logo" />
      </Sider>

      <MainWrapper>
        <Main>
          <Formik
            initialValues={submitFeeProposalInitialValues}
            validationSchema={SUBMIT_FEE_PROPOSAL_SCHEMA}
            onSubmit={handleSubmit}
          >
            {({
              submitForm,
              isSubmitting,
              values: {
                engineerRates,
                travelCost,
                expenseCosts,
              },
              setFieldValue,
            }) => {
              const engineerRatesWithSums = engineerRates.map((rate, index) => (
                {
                  ...rate,
                  serviceName: Object.values(formData.feeProposalServiceTypes)[index],
                  sum: currencyStringToNumber(rate.amount) * rate.hours * rate.inspectionCount,
                }
              ));
              const travelCostWithSum = {
                ...travelCost,
                sum: currencyStringToNumber(travelCost.amount) * travelCost.hours * travelCost.inspectionCount,
              };
              const expenseCostsWithSums = expenseCosts.map((expense) => (
                {
                  ...expense,
                  sum: currencyStringToNumber(expense.amount) * expense.count,
                }
              ));
              const engineerRatesTotal = engineerRatesWithSums.reduce((acc, rate) => (acc + rate.sum), 0);
              const travelCostTotal = travelCostWithSum.sum;
              const expenseCostsTotal = expenseCostsWithSums.reduce((acc, expense) => (acc + expense.sum), 0);
              const total = engineerRatesTotal + travelCostTotal + expenseCostsTotal;

              const handleCountChange = (value) => {
                if (value !== null) {
                  Object.values(formData.feeProposalServiceTypes).forEach((serviceName, index) => {
                    setFieldValue(`engineerRates[${index}].inspectionCount`, value);
                  });
                  setFieldValue('travelCost.inspectionCount', value);
                }
              };

              return (
                <>
                  <div>
                    <Header>
                      <PageTitle>
                        FEE PROPOSAL FOR PROJECT
                      </PageTitle>
                      <ProjectInfo>
                        <ProjectName>
                          {formData.projectName}
                        </ProjectName>
                        <ProjectAddress>
                          <StyledIcon icon="location" noWrapper />
                          {formData.projectLocation}
                        </ProjectAddress>
                      </ProjectInfo>
                      <div>
                        {formData.feeProposalDescription}
                      </div>
                      <HintWrapper>
                        <Tooltip text="When the alerts are turned on, you'll be notified when a certain percentage of budget is spent." />
                        <ActionButton
                          dialogTitle="How to complete the form?"
                          variant="text"
                          color="primary"
                          text="How to complete the form?"
                          dialogContent={(
                            <DialogContent>
                              <div>
                                The form below is dedicated to indicate your estimate costs,
                                which should include the
                                <HighlightedText> expenses for each inspection.</HighlightedText>
                              </div>
                              <div>
                                When completing the expenses,
                                <HighlightedText>
                                  {' '}
                                  select the category from the drop-down and insert your estimate
                                </HighlightedText>
                                . To add more fields, select “Add Expense Cost“
                                under the expenses field and repeat the process. The overall amount of
                                expenses is auto-calculated and is displayed on the right side of the form.
                              </div>
                              <div>
                                Note that your price
                                <HighlightedText> should be submitted on or before the closing date</HighlightedText>
                                , as late submissions will automatically be
                                <HighlightedText> rejected</HighlightedText>
                                .
                              </div>
                            </DialogContent>
                          )}
                          confirmButtonTitle="Close"
                          noCancel
                        />
                      </HintWrapper>
                    </Header>

                    <FeeProposalInfo>
                      <StyledForm>
                        <Subtitle>
                          Engineer Rate
                        </Subtitle>
                        {Object.values(formData.feeProposalServiceTypes).map((serviceName, index) => (
                          <Fragment key={index}>
                            <ServiceName>
                              {serviceName}
                            </ServiceName>
                            <Row key={index}>
                              <FormikField
                                component={Input}
                                required
                                currency={formData.projectCurrency}
                                name={`engineerRates[${index}].amount`}
                                label="Engineer Rate / Hour"
                                maxLength={7}
                                formControlStyle={{ flexBasis: '25%' }}
                              />
                              <Input
                                label="Currency"
                                formControlStyle={{ flexBasis: '25%' }}
                                calculatedValue={`${getCurrencySymbol(formData.projectCurrency)} ${formData.projectCurrency}`}
                              />
                              <FormikField
                                component={Input}
                                maxLength={4}
                                maxDecimals={0}
                                type="counter"
                                name={`engineerRates[${index}].hours`}
                                label="Hours"
                                formControlStyle={{ flexBasis: '25%' }}
                              />
                              <FormikField
                                component={Input}
                                maxValue={10}
                                maxLength={2}
                                type="counter"
                                name={`engineerRates[${index}].inspectionCount`}
                                label="Inspection Count"
                                formControlStyle={{ flexBasis: '25%' }}
                                calculatedValue={formData.feeProposalInspectionCount || undefined}
                                onChange={handleCountChange}
                                setValue={handleCountChange}
                              />
                            </Row>
                          </Fragment>
                        ))}
                        <Subtitle>
                          Travel Cost
                        </Subtitle>
                        <Row>
                          <FormikField
                            component={Input}
                            required
                            currency={formData.projectCurrency}
                            name="travelCost.amount"
                            label="Travel Time Rate / Hour"
                            maxLength={7}
                            formControlStyle={{ flexBasis: '25%' }}
                          />
                          <Input
                            label="Currency"
                            formControlStyle={{ flexBasis: '25%' }}
                            calculatedValue={`${getCurrencySymbol(formData.projectCurrency)} ${formData.projectCurrency}`}
                          />
                          <FormikField
                            component={Input}
                            maxLength={4}
                            maxDecimals={0}
                            type="counter"
                            name="travelCost.hours"
                            label="Hours"
                            formControlStyle={{ flexBasis: '25%' }}
                          />
                          <FormikField
                            component={Input}
                            maxValue={10}
                            maxLength={2}
                            type="counter"
                            name="travelCost.inspectionCount"
                            label="Inspection Count"
                            formControlStyle={{ flexBasis: '25%' }}
                            calculatedValue={formData.feeProposalInspectionCount || undefined}
                            onChange={handleCountChange}
                            setValue={handleCountChange}
                          />
                        </Row>

                        <Subtitle>
                          Expense Cost
                        </Subtitle>
                        <FieldArray
                          name="expenseCosts"
                          render={(arrayHelpers) => renderFieldArray(expenseCosts, arrayHelpers)}
                        />
                      </StyledForm>

                      <OverallCosts>
                        <OverallTitle>
                          Overall Costs
                        </OverallTitle>

                        <CostsSection>
                          <OverallSubTitle>
                            Engineer Rate
                          </OverallSubTitle>
                          {engineerRatesWithSums.map((engineerRate, index) => (
                            (
                              <div key={index}>
                                {`${engineerRate.serviceName} Sum`}
                                <Cost>
                                  {getCurrencySymbol(formData.projectCurrency)}
                                  {formatCurrency(engineerRate.sum)}
                                </Cost>
                              </div>
                            )
                          ))}
                        </CostsSection>

                        <CostsSection>
                          <OverallSubTitle>
                            Travel Cost
                          </OverallSubTitle>
                          <div>
                            Travel Time Rate Sum
                            <Cost>
                              {getCurrencySymbol(formData.projectCurrency)}
                              {formatCurrency(travelCostWithSum.sum)}
                            </Cost>
                          </div>
                        </CostsSection>

                        <CostsSection>
                          <OverallSubTitle>
                            Expense Costs
                          </OverallSubTitle>
                          {expenseCostsWithSums.map((expense) => (
                            expense.category && (
                            <div key={expense.category}>
                              {findOptionByValue(expense.category, EXPENSE_CATEGORIES).label}
                              <Cost>
                                {getCurrencySymbol(formData.projectCurrency)}
                                {formatCurrency(expense.sum)}
                              </Cost>
                            </div>
                            )
                          ))}
                        </CostsSection>

                        <CostsSection>
                          <div>
                            Engineer Rate Total
                            <Cost>
                              {getCurrencySymbol(formData.projectCurrency)}
                              {formatCurrency(engineerRatesTotal)}
                            </Cost>
                          </div>
                          <div>
                            Travel Cost Total
                            <Cost>
                              {getCurrencySymbol(formData.projectCurrency)}
                              {formatCurrency(travelCostTotal)}
                            </Cost>
                          </div>
                          <div>
                            Expense Cost Total
                            <Cost>
                              {getCurrencySymbol(formData.projectCurrency)}
                              {formatCurrency(expenseCostsTotal)}
                            </Cost>
                          </div>
                        </CostsSection>
                        <CostsSection>
                          <div>
                            TOTAL:
                            <TotalCost>
                              {getCurrencySymbol(formData.projectCurrency)}
                              {formatCurrency(total)}
                            </TotalCost>
                          </div>
                        </CostsSection>
                      </OverallCosts>
                    </FeeProposalInfo>

                  </div>
                  <Row>
                    {feeProposalFileInfo && feeProposalFileInfo.name && (
                      <OpenAttachmentButton
                        fileInfo={feeProposalFileInfo}
                        attachedName
                        withIcons
                      />
                    )}
                    <div />
                    <StyledButton
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      type="submit"
                      formNoValidate
                      text="Submit"
                    />
                  </Row>
                </>
              );
            }}
          </Formik>
        </Main>
      </MainWrapper>
    </Page>
  );
};

export default SubmitFeeProposalPage;
