import React from 'react';
import FlipNumbers from 'react-flip-numbers';

import { formatCurrency, formatNoDecimalCurrency } from 'utils/CurrencyUtils';

import { StyledFlipNumbers, NoData } from './styles';

const numberStyle = { fontSize: '26px', fontWeight: '900', lineHeight: '36px' };

const FlipNumber = ({ numbers, isPlayed, isCurrency, noDecimal, withNoData, props = {} }) => {
  const currencyValue = noDecimal ? formatNoDecimalCurrency(numbers) : formatCurrency(numbers);
  return (
    <StyledFlipNumbers>
      {withNoData && numbers === '0'
        ? <NoData>NO DATA</NoData>
        : (
          <>
            {isCurrency && '$'}
            <FlipNumbers
              numberStyle={numberStyle}
              height={30}
              width={15}
              background="transparent"
              play={isPlayed}
              numbers={isCurrency ? currencyValue : numbers}
              duration={2}
              {...props}
            />
          </>
        )
      }
    </StyledFlipNumbers>
  );
};

export default FlipNumber;
