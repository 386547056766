import React, { Fragment } from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import AuthService from 'services/AuthService';

import { getDataByRole } from './constants';

import { Title, TextSection, List } from '../../styles';

const DailyTools = () => {
  const { role } = AuthService.getUser();

  const { roleForPath, sections } = getDataByRole(role);

  const toolsSrc = `dashboard-${roleForPath}-daily-tools.png`;

  return (
    <>
      <ImageContainer src={toolsSrc} alt="tools" />
      <Title>Overview</Title>
      <TextSection>
        The sections that are located on your dashboard are the main tools that you need to perform
        and overview your daily activities. The following sections are available for you on the dashboard:
      </TextSection>
      <List>
        {sections.map(({ title }) => <li key={title}>{title}</li>)}
      </List>
      {sections.map(({ title, component }) => (
        <Fragment key={title}>
          <Title>{title}</Title>
          {component}
        </Fragment>
      ))}
    </>
  );
};

export default DailyTools;
