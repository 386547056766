import ApiClient from 'services/ApiClient';
import qs from 'qs';

import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class DashboardService extends ApiClient {
  getDashboardBudgets({ page = 1, pageSize = PAGE_SIZE, sortBy, searchFilter }) {
    const query = {
      page,
      pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    if (searchFilter) {
      query.Name = searchFilter;
    }

    return this.call({
      method: 'get',
      url: `/dashboard/budgets?${qs.stringify(query)}`,
    });
  }
}

export default new DashboardService();
