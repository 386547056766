import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const UploadDocumentsPending = ({ notify, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data,
  } = notify;
  const message = type === 'deadline' ? `Unfortunately, the time has passed, and no further document uploads are permitted for the project ${data.projectName} – Please contact the Risk Engineer` : `Please be notified that the project ${data.projectName} is missing documentation to be uploaded. Please upload these documents before ${data.dueDate}. If you have any questions, please contact ${data.contactName} at ${data.contactEmail}`;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToResources = () => {
    handleClose();
    closeNotificationList();
    if (type === 'deadline') return;
    navigate(`/projects/${data.projectId}/reports`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={data.projectName}
      title={type === 'deadline' ? 'Document Upload' : 'Document Upload - Pending Items'}
      cancelButtonTitle="Close"
      confirmButtonTitle={type === 'deadline' ? 'Ok' : 'Go to documents'}
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToResources}
        >
      <ConfirmationText
        text={message}
            />
    </ConfirmationDialog>
  );
};

export default UploadDocumentsPending;
