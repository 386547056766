import ContactService from 'common/api/ContactService';
import CompanyService from 'common/api/CompanyService';

const GLOBAL_CONTACTS_GET_CONTACTS = 'GLOBAL_CONTACTS_GET_CONTACTS';
const GLOBAL_CONTACTS_GET_COMPANY_CONTACTS = 'GLOBAL_CONTACTS_GET_COMPANY_CONTACTS';
const GLOBAL_CONTACTS_LIST_SORT_BY = 'GLOBAL_CONTACTS_LIST_SORT_BY';

export const getGlobalContactsList = (params) => ({
  type: GLOBAL_CONTACTS_GET_CONTACTS,
  apiCall: () => ContactService.getGlobalContactsList(params),
});

export const getGlobalCompanyContactsList = (params) => ({
  type: GLOBAL_CONTACTS_GET_COMPANY_CONTACTS,
  apiCall: () => CompanyService.getGlobalCompanyContactsList(params),
});

export const setContactsSortBy = (sortBy) => ({
  type: GLOBAL_CONTACTS_LIST_SORT_BY,
  sortBy,
});

const initialState = {
  sortBy: null,
  contactsList: {
    items: [],
  },
  companiesList: {
    items: [],
  },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GLOBAL_CONTACTS_LIST_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }
    case GLOBAL_CONTACTS_GET_CONTACTS: {
      return {
        ...state,
        contactsList: {
          ...state.contactsList,
          ...response,
        },
      };
    }
    case GLOBAL_CONTACTS_GET_COMPANY_CONTACTS: {
      return {
        ...state,
        companiesList: {
          ...state.companiesList,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
