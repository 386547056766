import { createSelector } from '@reduxjs/toolkit';

const selectNotification = (state) => state.notification;

export const allNotificationsList = createSelector([selectNotification], (notification) => notification.allNotifications.data);
export const allAlertsList = createSelector([selectNotification], (notification) => notification.allAlerts?.data);
export const newNotificationsList = createSelector([selectNotification], (notification) => {
  const {
    data = [],
  } = notification.allNotifications;

  return data.filter((notif) => !notif.isViewed);
});

export const newAlertsList = createSelector([selectNotification], (notification) => {
  const {
    data = [],
  } = notification.allAlerts;

  return data.filter((notif) => !notif.isViewed);
});

export const isNewAlerts = createSelector([newAlertsList], (newList) => newList.length > 0);

// export function isNewAlerts({ notification }) {
//   const newList = newAlertsList({ notification });
//
//   return newList.length > 0;
// }

export function isNewNotifications({ notification }) {
  const newList = newNotificationsList({ notification });

  return newList.length > 0;
}

export function notificationsView({ notification }) {
  return notification.notificationsView;
}

export function alertsView({ notification }) {
  return notification.alertsView;
}

export const getOpenedNotification = createSelector([selectNotification], (notf) => notf.openedNotification);
export const getPendingNotification = createSelector([selectNotification], (notf) => notf.pendingNotification || {});

export function getOpenedAlert({ notification }) {
  return notification.openedAlert;
}

export function globalTheme({ notification }) {
  return notification.theme;
}

export function slideOut({ notification }) {
  return notification.slideOut;
}
