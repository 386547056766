import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { Select, InputLabel } from '@mui/material';

import Icon from 'components/Icon';

export const StyledSelect = styledMui(Select)(({ closeIcon, selected, theme, error, readOnly }) => {
  const styles = {
    fontFamily: 'Graphik LC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: 'initial',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0.16,
    color: theme.palette.input.text,
    backgroundColor: theme.palette.input.color,
    borderRadius: 4,
    border: `1px solid ${theme.palette.input.border.color}`,
    '.MuiFilledInput-input': {
      padding: '22px 16px 6px',
    },
    ':hover': {
      backgroundColor: theme.palette.input.hover.color,
    },
    '&.Mui-focused': {
      border: `1px solid ${theme.palette.input.focused.border.color}`,
      backgroundColor: theme.palette.input.color,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.input.disabled.color,
      // color: theme.palette.input.text,
      color: 'red'
    },
    '.MuiSelect-icon': {
      top: 'calc(50% - 11px)',
      path: {
        fill: theme.palette.icon.primary.color,
      },
    },
  };
  if (error) {
    styles.border = `1px solid ${theme.palette.error.main}`;
  }
  if (readOnly) {
    styles.border = `1px solid ${theme.palette.input.readonly.border}`;
    styles.backgroundColor = 'transparent';
    styles.pointerEvents = 'none';
    styles['& .MuiSelect-icon'] = {
      display: 'none',
    };
  }
  if (selected) {
    styles.color = theme.palette.sidebar.selected.icon.background;
    if (closeIcon) {
      styles['& .MuiSelect-icon'] = {
        display: 'none',
      };
    }
  }
  return styles;
});

export const StyledInputLabel = styledMui(InputLabel)(({ theme, error }) => {
  const styles = {
    '&.Mui-disabled': {
      color: theme.palette.text.disabled.text,
    }
  };
  if (error) {
    styles.color = theme.palette.error.main;
  }
  return styles;
});

export const TooltipAnchor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const WrapperMenuItemWithTooltip = styled.div`
  position: relative;
  padding: 0;
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 40%;
  right: 20px;
  cursor: pointer;
  pointer-events: none;
  color: var(--iconPrimaryColor);
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  cursor: pointer;
  color: var(--iconPrimaryColor);

  &:hover {
    svg {
      color: var(--sidebarSelectedIconBackground);
    }
  }
`;

export const LabelPrefix = styled.span`
  color: var(--lightTextColor);
`;
