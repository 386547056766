import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const InspectionStartsSoon = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = notify;
  const message = `As a reminder, an inspection at ${data.projectName} ${data.inspectionLocation} is due to commence at ${data.inspectionStartDateTime} today.  If you need to change the start time, please do so now, otherwise the inspection will be available to record data 15-minutes prior to the start time.`;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToResources = () => {
    handleClose();
    closeNotificationList();
    navigate(`/projects/${data.projectId}/inspections`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={data.projectName}
      title="Inspection Starts Soon"
      cancelButtonTitle="Close"
      confirmButtonTitle="Go to project"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToResources}
        >
      <ConfirmationText
        text={message}
            />
    </ConfirmationDialog>
  );
};

export default InspectionStartsSoon;
