import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import { getFolderByRole } from 'help/constants';

import { Title, TextSection } from 'help/pages/styles';

const ClaimsSection = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const claimsSrc = `projects/${folder}/projects-claims.png`;

  const isICC = role === ROLES_IDS.ICCoordinator;

  return (
    <>
      <ImageContainer src={claimsSrc} alt="claims" />
      <Title>Claims</Title>
      <TextSection>
        Claims sub-section lists all the project claims that were added to the portal.
        {!isICC && 'Claims are added to the portal by the Insurance Company. When the claim is added by the insurance company, the relevant stakeholders receive the notification about that.'}
      </TextSection>
      {isICC && (
        <>
          <TextSection>
            Claims are added to the portal by the Insurance Company and can only be visible without
            restrictions by the insurance company users and by the Broker. The external risk company
            users and the risk manager has a limited access to the data displayed within that section.
            The site contact has no access to that data at all.
          </TextSection>
          <TextSection>
            When the claim is added by the insurance company,
            the relevant stakeholders receive the notification about that.
          </TextSection>
        </>
      )}
    </>
  );
};

export default ClaimsSection;
