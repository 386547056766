import React from 'react';

import StepperModal from 'onboarding/components/StepperModal';
import ModalPage from 'onboarding/components/ModalPage';
import RedirectMenu from 'onboarding/components/RedirectMenu';

const getPageData = (step) => {
  switch (step) {
    case 0:
      return {
        title: 'My Company - Overview',
        src: 'my-company-overview.png',
        description: 'My Company section allows you to manage your company data - team members, rates and the company data can be managed within that section.',
      };
    case 1:
      return {
        title: 'Company Info',
        src: 'my-company-info.png',
        description: 'Company Info view allows you to manage your company data and select the Primary Contact of the company.',
      };
    case 2:
      return {
        title: 'Team Members',
        src: 'my-company-team-members.png',
        description: 'Team Members view provides you the overview of all the users that belongs to your company. Keep the list up to date by adding new employees and by removing the old ones.',
      };
    case 3:
      return {
        title: 'Rates',
        src: 'my-company-rates.png',
        description: 'Rates view allows you to assign hourly rates to the Risk Engineers so that the budget of the project can be tracked later on.',
      };
    default:
      return {};
  }
};

const MyCompanyModal = ({ open, onCancel }) => {
  const renderStepContent = (activeStep) => {
    switch (activeStep) {
      case 0:
      case 1:
      case 2:
      case 3: {
        const { title, src, description } = getPageData(activeStep);
        return (
          <ModalPage
            title={title}
            src={src}
            description={description}
          />
        );
      }
      case 4:
        return (
          <RedirectMenu
            onCancel={onCancel}
            currentSection="myCompany"
          />
        );
      default:
        return null;
    }
  };

  return (
    <StepperModal
      renderStepContent={renderStepContent}
      numberOfSteps={5}
      open={open}
      onCancel={onCancel}
    />
  );
};

export default MyCompanyModal;
