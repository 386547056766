import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';
import AuthService from 'services/AuthService';
import { getNextPageInfo, getFolderByRole } from 'help/constants';

import { Title, TextSection, HighlightedText, List, Underline } from '../../styles';

const InspectionsAndTimeAndExpenses = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const inspectionsSrc = `projects/${folder}/projects-inspections.png`;
  const teSrc = `projects/${folder}/projects-time-and-expenses.png`;

  const { title, path, isCurrentPageLast } = getNextPageInfo(role, 'inspections-time-and-expenses');

  return (
    <>
      <ImageContainer src={inspectionsSrc} alt="inspections" />
      <Title>Inspections</Title>
      <TextSection>
        Inspections sub-section allows you to create the inspections for the project and lists all the inspections
        that were already scheduled for the project. The inspection can be scheduled by the risk company coordinator
        or by the risk engineer. When the inspection is scheduled, the notification is sent to the project stakeholders.
      </TextSection>
      <TextSection>
        The inspection can have one of the following statuses:
      </TextSection>
      <List>
        <li>
          <HighlightedText>Upcoming </HighlightedText>
          - the inspection is in the future.
        </li>
        <li>
          <HighlightedText>In Progress / Ongoing </HighlightedText>
          - the inspection automatically goes from &aposUpcoming&apos status to
          &aposIn Progress&apos / &aposOngoing&apos when its time for the inspection and stays
          in that status until the inspection is manually closed by the risk engineer.
        </li>
        <li>
          <HighlightedText>Completed</HighlightedText>
          - when the inspection is manually completed by the risk engineer
          from the mobile app or from the web portal, the inspection status changes to “Completed”.
        </li>
      </List>
      <TextSection>
        Before the inspection starts, you can also <Underline>edit or delete</Underline> the inspection.
        In case the inspection was updated, the notification is sent to the project stakeholders.
      </TextSection>
      <ImageContainer src={teSrc} alt="time-and-expenses" />
      <Title>Time and Expenses</Title>
      <TextSection>
        Time and Expenses sub-section allows the risk engineer and the risk company coordinator to add time
        and expenses for the project and also lists all the time and expenses that were added so far.
      </TextSection>
      <TextSection>
        When the time and expenses are added, they can be <Underline>marked as billed</Underline> in
        case they were billed. When the time and expenses are marked as billed, the amount is
        added to the billed amount and such time and expenses disappear from the list.
      </TextSection>
      {!isCurrentPageLast && <RedirectButton redirectTitle={title} redirectPath={path} />}
    </>
  );
};

export default InspectionsAndTimeAndExpenses;
