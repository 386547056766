import React, { useState, useEffect } from 'react';

import Spinner from 'components/Spinner';
import { Chart, Legend } from 'components';
import {
  COLORS_GLOBAL_ECOSYSTEM_CHART, DASHBOARD_CHART_OPTIONS,
  CHART_DATA_TYPES,
} from 'components/Chart/constants';

import { ChartWrapper, LeftPanel, TypeTitle, ChartMenu, MenuItem, ChartBlock, ButtonBack, StyledIcon } from './styles';

const options = DASHBOARD_CHART_OPTIONS;
const hiddenValues = ['Project Types', 'Claims', 'Claims Total'];

const ChartSection = ({ chartData }) => {
  const [mainChartData, setMainChartData] = useState([]);
  const [chartOptionType, setChartOptionType] = useState(options[0].value);
  const [secondaryChartData, setSecondaryChartData] = useState(null);
  const [secondaryTitle, setSecondaryTitle] = useState('');

  const dataName = options.find((o) => (o.value === chartOptionType))?.label;

  const mapData = (obj) => {
    return Object.entries(obj).map(([name, { value }]) => ({ name, value }));
  };

  useEffect(() => {
    if (chartData && chartOptionType && chartData[chartOptionType]) {
      setMainChartData(mapData(chartData[chartOptionType]));
    }
  }, [chartOptionType, chartData]);

  const selectSecondaryChartData = (type) => {
    const dataForType = chartData[chartOptionType];
    const riskTypesData = dataForType[type]?.riskTypes;
    if (riskTypesData) {
      setSecondaryTitle(type);
      setMainChartData([]);
      setSecondaryChartData(mapData(riskTypesData));
    }
  };

  const clearSecondaryChartData = () => {
    setSecondaryChartData(null);
    if (chartData && chartOptionType && chartData[chartOptionType]) {
      setMainChartData(mapData(chartData[chartOptionType]));
    }
  };

  const deepChartHelper = {
    projectTypes: {},
    claimAmounts: {},
    claimTypes: {},
    riskCategories: {
      onClick: selectSecondaryChartData,
      buttonTitle: 'Recommendations Types',
    },
    riskPriorities: {},
    riskStatuses: {},
  };

  if (chartData?.isLoading || !chartData) {
    return (
      <ChartBlock>
        <Spinner />
      </ChartBlock>
    );
  }

  const selectChartOption = (value) => {
    if (secondaryChartData) setSecondaryChartData(null);
    setChartOptionType(value);
  };
  const renderMenu = () => {
    return (
      <ChartMenu>
        {options.map(({ value, label }, i) => {
          const handleClick = () => selectChartOption(value);

          return (
            <MenuItem
              onClick={handleClick}
              key={i}
              isSelected={value === chartOptionType}
            >
              {label}
            </MenuItem>
          );
        })}
      </ChartMenu>
    );
  };

  if (mainChartData.length === 0 && !secondaryChartData) {
    return (
      <ChartBlock>
        <LeftPanel>
          <TypeTitle>
            {dataName}
          </TypeTitle>
          <Legend minWidth="150px" />
        </LeftPanel>
        <ChartWrapper>
          <Chart
            empty
            dataName={dataName}
            hiddenValues={hiddenValues}
            innerR={70}
            outerR={115}
          />
        </ChartWrapper>
        {renderMenu()}
      </ChartBlock>
    );
  }

  if (secondaryChartData?.length) {
    return (
      <ChartBlock>
        <LeftPanel>
          <ButtonBack onClick={clearSecondaryChartData}>
            <StyledIcon
              icon="chevronRight"
              noWrapper
            />
            <TypeTitle>{secondaryTitle}</TypeTitle>
          </ButtonBack>
          <Legend data={secondaryChartData} />
        </LeftPanel>
        <ChartWrapper>
          <Chart
            data={secondaryChartData}
            dataName={dataName}
            hiddenValues={hiddenValues}
            innerR={70}
            outerR={115}
            dataType={CHART_DATA_TYPES[chartOptionType]}
          />
        </ChartWrapper>
        {renderMenu()}
      </ChartBlock>
    );
  }

  return (
    <ChartBlock>
      <LeftPanel>
        <TypeTitle>
          {options.find((o) => (o.value === chartOptionType)).label}
        </TypeTitle>
        <Legend
          data={mainChartData}
          colorsByName={COLORS_GLOBAL_ECOSYSTEM_CHART[chartOptionType]}
        />
      </LeftPanel>
      <ChartWrapper>
        <Chart
          data={mainChartData}
          onClick={deepChartHelper[chartOptionType].onClick}
          dataName={dataName}
          hiddenValues={hiddenValues}
          colors={COLORS_GLOBAL_ECOSYSTEM_CHART[chartOptionType]}
          innerR={70}
          outerR={115}
          dataType={CHART_DATA_TYPES[chartOptionType]}
        />
      </ChartWrapper>
      {renderMenu()}
    </ChartBlock>
  );
};

export default ChartSection;
