import React from 'react';
import { Tooltip as MuiTooltip, CircularProgress } from '@mui/material';

import { StyledButton, ButtonWrapper, TooltipBlock } from './styles';

const Button = (props) => {
  const {
    variant = 'contained', text, disabled, disabledTooltip,
    color, label, onClick, type, children, small, inline, loading, ...rest
  } = props;

  return (
    <>
      <ButtonWrapper>
        <StyledButton
          variant={variant}
          aria-label={label}
          color={color}
          onClick={onClick}
          type={type}
          small={small ? 1 : undefined}
          inline={inline}
          disabled={disabled}

          {...rest}
      >
          {children}
          {text}
        </StyledButton>
        {loading && (
        <CircularProgress
          className="progress"
          size={24}
          sx={{
            color,
          }}
          />
        )}
        {disabled && disabledTooltip && (
        <MuiTooltip arrow title={disabledTooltip}>
          <TooltipBlock />
        </MuiTooltip>
        )}

      </ButtonWrapper>
    </>
  );
};

export default Button;
