import styled from 'styled-components';

export const FilesTableRoot = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1 0 auto;
`;

export const TypeIconWrapper = styled.div`
  margin-left: -10px;
  svg path {
    fill: var(--navTabFill);
  }
`;

export const Disclaimer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  padding: 15px 30px;
  border-radius: 4px;
  border: 1px solid var(--inputReadOnlyBorder);
  & > :first-child {
    margin: 0 9px 2px 0;
    svg path:first-child {
      fill: #96CEF6;
    }
    svg path:last-child {
      fill: none;
    }
  }
`;
