import { styled as styledMui } from '@mui/material/styles';

export const StyledFlipNumbers = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  color: theme.palette.text.title,
}));

export const NoData = styledMui('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 900,
  fontSize: 18,
  textTransform: 'uppercase',
  color: theme.palette.text.tooltip,
}));

