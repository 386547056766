import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import { getFolderByRole } from 'help/constants';

import { Title, TextSection, Underline } from 'help/pages/styles';

const RecommendationsSection = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const recommendationsSrc = `projects/${folder}/projects-recommendations.png`;

  const isICC = role === ROLES_IDS.ICCoordinator;
  const isRE = role === ROLES_IDS.RiskEngineer;

  return (
    <>
      <ImageContainer src={recommendationsSrc} alt="recommendations" />
      <Title>Recommendations</Title>
      {!isRE && (
        <TextSection>
          Recommendations sub-section lists all the recommendations that were issued by the risk engineer.
        </TextSection>
      )}
      {isRE && (
        <TextSection>
          Recommendations sub-section lists all the recommendations that were added for the project.
        </TextSection>
      )}
      {!isICC && (
        <TextSection>
          Recommendations are added by the risk engineer during the inspections and are actioned by the
          Site Contact within the calculated timeline. Before the recommendations are issued by the
          Risk Engineer, they should be approved by the primary underwriter or risk coordinator.
        </TextSection>
      )}
      {isICC && (
        <TextSection>
          Recommendations are added by the risk engineer during the inspections and are actioned by
          the Site Contact within the calculated timeline. Before the recommendations are issued
          {' '}
          <Underline>by the external Risk Engineer</Underline>
          , they should be approved by the project coordinator. In case
          {' '}
          <Underline>the internal risk engineer</Underline> is assigned to project, the recommendations
          require no approval and can be issued by the risk engineer once reviewed.
        </TextSection>
      )}
    </>
  );
};

export default RecommendationsSection;
