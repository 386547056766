import styled from 'styled-components';

import { Icon } from 'components';

export const StyledIcon = styled(Icon)`
  color: var(--navTabFillActive);
`;

export const ItemRoot = styled.div`
  flex: 1 0;
  cursor: pointer;
  height: 110px;
  max-height: 110px;
  background: var(--notificationsBackground);
  padding: 13px 24px;
  border-bottom: 1px solid var(--notificationItemBorderBottom);

  :last-child {
    border-bottom: 1px solid transparent;
  }

  :hover {
    opacity: 0.8;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Subject = styled.div`
  display: flex;
  align-items: center;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: var(--navTabLabelActive);

  > div {
    margin-left: 9px;
  }
`;

export const Time = styled.div`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 150%;
  text-align: right;
  color: var(--navTabLabelActive);
  opacity: 0.5;
`;
