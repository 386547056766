import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';

import { FormikField, Select, Button } from 'components';
import { CONSTRUCTION_TYPES, PROJECT_STATUSES } from 'utils/constants';

import { Filters } from './styles';

const initialGlobalValues = {
  projectType: '',
  businessTypeCategory: '',
  constructionType: '',
  countryName: '',
  fromPolicyStartDateTime: '',
};

const initialAnalyticsValues = {
  parentCompanyId: '',
  projectTypeId: '',
  businessTypeId: '',
  constructionType: '',
  countryName: '',
  fromPolicyStartDateTime: '',
  insuranceCompanyId: '',
  projectStatus: '',
};

const formTypes = {
  parentCompanyId: 'parentCompanyId',
  projectType: 'projectType',
  projectTypeId: 'projectTypeId',
  categoryType: 'categoryType',
  businessTypeId: 'businessTypeId',
  countryName: 'countryName',
  constructionType: 'constructionType',
  fromPolicyStartDateTime: 'fromPolicyStartDateTime',
  insuranceCompanyId: 'insuranceCompanyId',
  projectStatus: 'projectStatus',
};

const getInsuranceCompaniesOptions = ({ insuranceCompanies = [] }) => {
  const insuranceCompanyOptions = insuranceCompanies.map(({ id, value }) => ({
    value: id,
    label: value,
  }));
  return insuranceCompanyOptions;
};

const getParentCompanyOptions = ({ parentCompanies = [] }) => {
  const projectTypeOptions = parentCompanies.map(({ id, value }) => ({
    value: id,
    label: value,
  }));
  return projectTypeOptions;
};

const getProjectTypeOptions = ({ projectTypes = [] }) => {
  const projectTypeOptions = projectTypes.map((projectType) => ({
    value: projectType.name,
    label: projectType.name,
  }));
  return projectTypeOptions;
};

const getCategoryOptionsByType = (projectType, { projectTypes = [] }) => {
  if (!projectType) {
    const allOptions = projectTypes
      .map((type) => type.businessTypeCategories)
      .flat();
    const optionForAllTypes = [...new Set(allOptions)].map((category) => {
      return { value: category, label: category };
    });
    return optionForAllTypes;
  }
  const projectTypeData = projectTypes.find(
    (type) => type.name === projectType,
  ) || { businessTypeCategories: [] };
  const businessTypeCategories = projectTypeData.businessTypeCategories.map(
    (category) => ({ value: category, label: category }),
  );
  return businessTypeCategories;
};

const getConstructionTypeOptions = ({ constructionTypes = [] }) => {
  const types = Object.values(CONSTRUCTION_TYPES).map(({ value, label }) => [
    value,
    label,
  ]);
  const constructionTypesNames = Object.fromEntries(types);
  const constructionTypeOptions = constructionTypes.map((constructionType) => ({
    value: constructionType,
    label: constructionTypesNames[constructionType],
  }));
  return constructionTypeOptions;
};

const getCountryOptions = ({ countries = [] }) => {
  const countryOptions = countries.map((country) => ({
    value: country,
    label: country,
  }));
  return countryOptions;
};

const getProjectTypeOptionsAnalytics = ({ projectTypes = [] }) => {
  const projectTypeOptions = projectTypes.map(({ id, value }) => ({
    value: id,
    label: value,
  }));
  return projectTypeOptions;
};

const getBusinessTypeOptions = (
  projectId,
  { projectTypes = [] },
  isAnalytics,
) => {
  if (!projectId && !isAnalytics) {
    const allOptions = projectTypes
      .map((type) => type.businessTypeCategories)
      .flat();
    const optionForAllTypes = [...new Set(allOptions)].map((category) => {
      return { value: category.id, label: category.value };
    });
    return optionForAllTypes;
  }
  const projectTypeData = projectTypes.find(
    (type) => type.id === projectId,
  ) || { businessTypes: [] };
  const businessTypes = projectTypeData.businessTypes.map(({ id, value }) => ({
    value: id,
    label: value,
  }));
  return businessTypes;
};

const getProjectStatusOptions = ({ projectStatus = [] }) => {
  const statuses = Object.values(PROJECT_STATUSES).map(({ value }) => [
    value,
    value,
  ]);
  const statusNames = Object.fromEntries(statuses);
  const statusOptions = projectStatus.map((s) => ({
    value: s,
    label: statusNames[s],
  }));
  return statusOptions;
};

const getOptions = (filters, values, isAnalytics) => {
  const options = {
    parentCompanyOptions: getParentCompanyOptions(filters),
    projectTypesOptions: getProjectTypeOptions(filters),
    categoryTypeOptions: getCategoryOptionsByType(values.projectType, filters),
    projectTypesOptionsAnalytics: getProjectTypeOptionsAnalytics(filters),
    businessTypeOptions: getBusinessTypeOptions(
      values.projectTypeId,
      filters,
      isAnalytics,
    ),
    countryOptions: getCountryOptions(filters),
    constructionOptions: getConstructionTypeOptions(filters),
    dateOptions: filters.dates,
    insuranceCompanyOptions: getInsuranceCompaniesOptions(filters),
    projectStatusOptions: getProjectStatusOptions(filters),
  };
  return options;
};

const FilterForm = ({
  filters = {},
  appliedFilter,
  onSubmit,
  onFiltersChange,
  isCleared,
  setIsCleared,
  isAnalytics,
  isSubscribed = false,
}) => {
  const setDefaultValues = (resetForm) => {
    setIsCleared(false);
    resetForm();
  };

  const defaultInitialValues = isAnalytics
    ? initialAnalyticsValues
    : initialGlobalValues;
  const initialValues = appliedFilter || defaultInitialValues;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ submitForm, values, setFieldValue, resetForm }) => {
        const options = getOptions(filters, values, isAnalytics);
        const {
          parentCompanyOptions,
          projectTypesOptions,
          projectTypesOptionsAnalytics,
          businessTypeOptions,
          categoryTypeOptions,
          countryOptions,
          constructionOptions,
          dateOptions,
          insuranceCompanyOptions,
          projectStatusOptions,
        } = options;

        function handleParentCompanyClear() {
          if (isAnalytics && values.parentCompanyId) {
            setFieldValue(formTypes.parentCompanyId, '');
          }
        }

        function handleProjectTypeClear() {
          if (isAnalytics && values.projectTypeId) {
            setFieldValue(formTypes.projectTypeId, '');
          } else if (values.projectType) {
            setFieldValue(formTypes.projectType, '');
          }
        }

        function handleBusinessTypeClear() {
          if (isAnalytics && values.businessTypeId) {
            setFieldValue(formTypes.businessTypeId, '');
          } else if (values.categoryType) {
            setFieldValue(formTypes.categoryType, '');
          }
        }

        function handleConstructionClear() {
          if (values.constructionType) {
            setFieldValue(formTypes.constructionType, '');
          }
        }

        function handleCountryClear() {
          if (values.countryName) {
            setFieldValue(formTypes.countryName, '');
          }
        }

        function handleDateClear() {
          if (values.fromPolicyStartDateTime) {
            setFieldValue(formTypes.fromPolicyStartDateTime, '');
          }
        }

        function handleInsuranceCompanyClear() {
          if (values.insuranceCompanyId) {
            setFieldValue(formTypes.insuranceCompanyId, '');
          }
        }

        function handleProjectStatusClear() {
          if (values.projectStatus) {
            setFieldValue(formTypes.projectStatus, '');
          }
        }

        useEffect(() => {
          onFiltersChange(values);
        }, [values]);

        useEffect(() => {
          setDefaultValues(resetForm);
        }, [isCleared]);

        useEffect(() => {
          const filterExists =
            Object.values(values).filter((el) => el).length > 0;
          if (!filterExists) {
            setDefaultValues(resetForm);
          }
        }, [filters]);

        return (
          <Form>
            <Filters>
              {isAnalytics && (
                <>
                  {isSubscribed && (
                    <>
                      <FormikField
                        selected={values.insuranceCompanyId}
                        component={Select}
                        name={formTypes.insuranceCompanyId}
                        label="Insurance Company"
                        displayEmpty="All"
                        options={insuranceCompanyOptions}
                        onOpen={handleInsuranceCompanyClear}
                        closeIcon
                        noClear
                      />
                      <FormikField
                        selected={values.projectStatus}
                        component={Select}
                        name={formTypes.projectStatus}
                        label="Project Status"
                        options={projectStatusOptions}
                        displayEmpty="All"
                        onOpen={handleProjectStatusClear}
                        closeIcon
                        noClear
                      />
                    </>
                  )}
                  <FormikField
                    selected={values.parentCompanyId}
                    component={Select}
                    name={formTypes.parentCompanyId}
                    label="Parent Company"
                    displayEmpty="All"
                    options={parentCompanyOptions}
                    onOpen={handleParentCompanyClear}
                    closeIcon
                    noClear
                  />
                  <FormikField
                    selected={values.projectTypeId}
                    component={Select}
                    name={formTypes.projectTypeId}
                    label="Project Type"
                    displayEmpty="All"
                    options={projectTypesOptionsAnalytics}
                    onOpen={handleProjectTypeClear}
                    closeIcon
                    noClear
                  />
                  <FormikField
                    selected={values.businessTypeId}
                    component={Select}
                    name={formTypes.businessTypeId}
                    label="Business Type"
                    displayEmpty="All"
                    options={businessTypeOptions}
                    onOpen={handleBusinessTypeClear}
                    closeIcon
                    noClear
                  />
                </>
              )}
              {!isAnalytics && (
                <>
                  <FormikField
                    selected={values.projectType}
                    component={Select}
                    name={formTypes.projectType}
                    label="Project Type"
                    options={projectTypesOptions}
                    displayEmpty="All"
                    onOpen={handleProjectTypeClear}
                    closeIcon
                    noClear
                  />
                  <FormikField
                    selected={values.categoryType}
                    component={Select}
                    name={formTypes.categoryType}
                    label="Business Category"
                    displayEmpty="All"
                    options={categoryTypeOptions}
                    onOpen={handleBusinessTypeClear}
                    closeIcon
                    noClear
                  />
                </>
              )}
              <FormikField
                selected={values.constructionType}
                component={Select}
                name={formTypes.constructionType}
                label="Construction Type"
                displayEmpty="All"
                options={constructionOptions}
                onOpen={handleConstructionClear}
                closeIcon
                noClear
              />
              <FormikField
                selected={values.countryName}
                component={Select}
                name={formTypes.countryName}
                label="Country"
                displayEmpty="All"
                options={countryOptions}
                onOpen={handleCountryClear}
                closeIcon
                noClear
              />
              <FormikField
                selected={values.fromPolicyStartDateTime}
                component={Select}
                name={formTypes.fromPolicyStartDateTime}
                label="Date"
                displayEmpty="All"
                options={dateOptions}
                onOpen={handleDateClear}
                closeIcon
                noClear
              />
              <Button onClick={submitForm} color="primary" text="Apply" />
            </Filters>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FilterForm;
