import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import { Icon } from 'components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 572px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 125px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: Avenir;
  font-weight: 900;
  font-size: 14px;
  & > * {
    margin-left: 8px;
  }
`;

export const ContactInfo = styledMui('div')(({ theme }) => ({
  width: '100%',
  padding: '18px 20px',
  marginBottom: '25px',
  border: `1px solid ${theme.palette.border.light}`,
  borderRadius: '4px',
  fontFamily: 'Graphik LC',
  color: theme.palette.text.light,
  '& > :first-child': {
    marginBottom: '8px',
    color: theme.palette.text.main,
    fontWeight: 600,
  },
}));

// export const ContactInfo = styled.div`
//   width: 100%;
//   padding: 18px 20px;
//   margin-bottom: 25px;
//   border: 1px solid var(--lightBorderColor);
//   border-radius: 4px;
//   font-family: Graphik LC;
//   color: var(--lightTextColor);
//
//   > :first-child {
//     margin-bottom: 8px;
//     color: var(--textColor);
//     font-weight: 600;
//   }
// `;

export const ContactRow = styled.div`
  display: flex;
  align-items: center;
  height: 23px;

  &&:not(:last-child) {
    margin-bottom: 5px;
  }

  > span {
    line-height: 14px;
  }
`;

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  marginRight: '9px',
  '& svg > path': {
    fill: theme.palette.icon.primary.color,
  },
  '&.user': {
    marginBottom: '3px',
  },
}));

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin-bottom: 24px;

  > div {
    margin-right: 15px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 15px;
  }
`;
