import React from 'react';

import { NOTIFICATION_ENUM, RECOMMENDATION_TYPES } from 'utils/constants';
import RecommendationDescription from '../../dialogs/RecommendationApproval/RecommendationDescription';

import { Description } from './styles';

const getDescriptionString = (type, data) => {
  const locationString = data.state || data.region
    ? `${data.countryName}, ${data.state || data.region}, ${data.city}` : `${data.countryName}, ${data.city}`;

  switch (type) {
    case NOTIFICATION_ENUM.claimAlert:
      return `Please be advised that a new insurance claim has been added for ${data.projectName}.`;

    case NOTIFICATION_ENUM.registration:
      return `The registration form has been submitted by ${data.userFullName}.\nPlease verify the data below before providing the access.`;

    case NOTIFICATION_ENUM.companyRegistration:
      return `The registration form has been submitted by ${data.companyName}.\nPlease verify the data below before providing the access.`;

    case NOTIFICATION_ENUM.feeProposal:
      return `Fee proposal has been submitted by ${data.companyName} for ${data.projectName} project.`;

    case NOTIFICATION_ENUM.assignmentReceived:
      return 'A new project has been assigned to you, please confirm the assignment by selecting “Acknowledge Assignment” button.';

    case NOTIFICATION_ENUM.assignmentConfirmed:
      return `${data.userFullName} has confirmed the assignment for ${data.projectName} project. You'll be notified on further activity.`;

    case NOTIFICATION_ENUM.newCompanyAssignmentReceived:
      return 'A new project has been assigned to your company, please review the project information and assign the resource.';

    case NOTIFICATION_ENUM.budgetReached:
      return `iMR Global has detected that the budget for ${data.serviceNames} services has reached ${data.budgetThreshold}% of the spend total for ${data.projectName}.
      If you need to increase the budget, please update this in the budget section.`;

    case NOTIFICATION_ENUM.contractValueIncreased:
      return `iMR Global has detected that there has been an increase in the Contract Value for ${data.projectName} from ${data.fromValue} to ${data.toValue}. This represents a ${data.increasePercent}% increase.
      You may wish to review and discuss this with the risk engineer and the insurance broker.`;

    case NOTIFICATION_ENUM.abridgedReportGenerated:
      return `Please be advised that following the inspection of the above project with Policy no: ${data.policyNumber} the Abridged Risk Recommendation Report has been issued by the Risk Engineer to the Insured.
      You can now access the report on your dashboard.`;

    case NOTIFICATION_ENUM.abridgedReportRequestApproval:
      return `Please be advised that following the inspection of ${data.projectName}, under the Policy, ${data.policyNumber}, the Risk Report has been sent for your approval.
      Please review the report Reject Or approve the report`;

    case NOTIFICATION_ENUM.abridgedReportApproved:
      return `Please be advised that the Risk Engineering Report for ${data.projectName} has been approved.`;

    case NOTIFICATION_ENUM.mainReportSentForApproval:
      return `Please be advised that following the inspection of ${data.projectName}, under the Policy, ${data.policyNumber}, the Risk Report has been sent for your approval.
      Please review the report and should changes be needed, please add your comments for the Risk Engineer to act upon.
      Or approve the report so this maybe formally issued to the Broker for distribution.`;

    case NOTIFICATION_ENUM.mainReportApproved:
      return `Please be advised that the Risk Engineering Report for ${data.projectName} has been approved by the Underwriter at ${data.companyName}.
      The report has automatically been generated for the Insurance Broker.`;

    case NOTIFICATION_ENUM.mainReportRejected:
      return `Following a review of the Risk Engineering Report for ${data.projectName}, we have found it necessary to reject the report with comments that require your action.
      Please review the suggested amendments and resubmit the report for approval within 3-days.`;

    case NOTIFICATION_ENUM.mainReportAvailable:
      return `Please be advised that following the inspection for the above project, the Risk Engineering Report is approved by Underwriters for issue.
      You can access the report on your dashboard, download and share this with the relevant stakeholders.`;

    case NOTIFICATION_ENUM.mainReportAvailableWithoutApproval:
      return `Please be advised that following the inspection for the above project, the Risk Engineering Report has been automatically approved.
      The report has automatically been generated for the Insurance Broker.`;

    case NOTIFICATION_ENUM.mainReportPending:
      return `iMitig8 Risk Global has detected that the Risk Report has not been issued.
      Please generate the report and send it for approval to the Underwriter.`;

    case NOTIFICATION_ENUM.abridgedReportPending:
      return `iMitig8 Risk Global has detected that the Abridged Report has not been issued to the Insured.
      Please generate the report and issue to the Site Contact on file.`;

    case NOTIFICATION_ENUM.mainReportIssued:
      return `Please be advised that following the inspection of the above named project,
      the Risk Report is now available and can be accessed from your dashboard.`;

    case NOTIFICATION_ENUM.inspectionScheduledInLocation:
      return `The ${data.companyName} risk company has scheduled an inspection in ${locationString}.
      You have ${data.projectName} unassigned in the same vicinity. Do you wish to assign it to that Risk Company?`;

    case NOTIFICATION_ENUM.passwordExpirationReminder:
      return `Your password will expire in ${data.expireInDays} days. Please go to your account and update the password.`;

    case NOTIFICATION_ENUM.filesUploadedToProject:
    case NOTIFICATION_ENUM.riskReviewsUploadedToProject:
      return data.numberOfFiles === '1'
        ? `Please be notified that a new document was uploaded to ${data.projectName}, with policy number ${data.policyNumber}.
          The document was uploaded by ${data.userFullName} and was named ${data.fileName}.`
        : `Please be notified that ${data.numberOfFiles} documents were uploaded to ${data.projectName}, with policy number ${data.policyNumber}.
          The documents were uploaded by ${data.userFullName}.`;

    case NOTIFICATION_ENUM.layoutsUploadedToProject:
      return data.numberOfFiles === '1'
        ? `Please be notified that a new layout map was uploaded to ${data.projectName}, with policy number ${data.policyNumber}.
          The layout plan was uploaded by ${data.userFullName} and was named ${data.fileName}. This will be available for your use during any future inspections.`
        : `Please be notified that ${data.numberOfFiles} layout plans were uploaded to ${data.projectName}, with policy number ${data.policyNumber}.
          The layout plans were uploaded by ${data.userFullName}. This will be available for your use during any future inspections.`;

    case NOTIFICATION_ENUM.filesDeletedFromProject:
      return data.numberOfFiles === '1'
        ? `Please be notified that the document stored under ${data.fileName} has been deleted by ${data.userFullName} from ${data.projectName} with policy number ${data.policyNumber}.
          This document will no longer be available to view.`
        : `Please be notified that ${data.numberOfFiles} documents have been deleted by ${data.userFullName} from ${data.projectName} with policy number ${data.policyNumber}.
          These documents will no longer be available to view.`;

    case NOTIFICATION_ENUM.projectClosed:
      return `Please be notified that the ${data.projectName} is now closed. Closing reason: ${data.closingReason}. Analytical information will still be available on the portal, as well as documents for review.`;

    case NOTIFICATION_ENUM.projectCanceled:
      return `Please be notified that ${data.projectName} has been canceled by the Insurance Company. The files will no longer be available.`;

    case NOTIFICATION_ENUM.projectHasNoAssignment:
      return `iMR Global has detected that the ${data.projectName} has yet to be allocated to a Risk Engineer. If you do not wish to allocate a resource to this project, please indicate by selecting "Do not Assign" button.`;

    case NOTIFICATION_ENUM.projectHasNoAssignedRE:
      return `iMR Global has detected that the ${data.projectName} project has yet to be allocated to a Risk Engineer.`;

    case NOTIFICATION_ENUM.projectPolicyExpireSoon:
      return `Please be notified that the insurance policy for ${data.projectName} will expire in 7 days.`;

    case NOTIFICATION_ENUM.inspectionUpcomingSoon:
      return `Please be notified that you have an upcoming insurance inspection for the ${data.projectName}.`;

    case NOTIFICATION_ENUM.riskInspectionScheduled:
      return `Please be notified that following agreement, the insurance inspection has been scheduled for the ${data.projectName} project.
        See the agenda attached. If you have any questions, please contact the Risk Engineer.`;

    case NOTIFICATION_ENUM.virtualInspectionScheduled:
      return `Please be notified that following agreement, the insurance virtual inspection for the ${data.projectName} has been scheduled. This will not entail a physical inspection of the site.
        Instead a review of documents requested in the attached agenda should be sent 5-days prior to the scheduled date and an online meeting will be held on the day.
        If you have any questions, please contact the Risk Engineer.`;

    case NOTIFICATION_ENUM.inspectionUpdated:
      return `Please be notified that following agreement, the insurance inspection for the ${data.projectName} has been updated.
        If you have any questions, please contact the Risk Engineer.`;

    case NOTIFICATION_ENUM.inspectionCanceled:
      return `Please be notified that following agreement, the insurance inspection for the ${data.projectName} has been canceled.
        If you have any questions, please contact the Risk Engineer.`;

    case NOTIFICATION_ENUM.inspectionCanceledWithReason:
      return `Please be notified that following agreement, the insurance inspection for the ${data.projectName} has been canceled with reason: ${data.inspectionCancelReason}.
        If you have any questions, please contact the Risk Engineer.`;

    case NOTIFICATION_ENUM.inspectionScheduleReminder:
      return `iMitig8 Risk Global has detected that you have an anticipated inspection for the ${data.projectName} in a month. Please make sure you schedule the inspection in advance.
        If the inspection date has been changed or the inspection have already been scheduled, just ignore this notification.`;

    case NOTIFICATION_ENUM.projectLocationNotAssigned:
      return `iMR Global has detected that the location hasn’t been assigned for the following projects.
        If you want to assign the location, please select the project in the list and assign the project location within the ‘Location’ sub-section.`;

    case NOTIFICATION_ENUM.recommendationAwaitingIssue:
      return `Following the inspection of ${data.projectName}, please review the recommendations (if there are any) and issue them to the Insured or select "No Recommendations".
        Note that the recommendations should be issued or sent for the approval from the web portal.`;

    case NOTIFICATION_ENUM.projectCanBeReassigned:
      return `Please be advised that the project has been updated by the Risk Company for project ${data.projectName}. `;

    case NOTIFICATION_ENUM.projectReallocation:
      return 'File Reallocation Request, please complete the required actions.';

    case NOTIFICATION_ENUM.inspectionStartingSoon:
      return `As a reminder, an inspection at ${data.projectName} ${data.inspectionLocation} is due to commence at ${data.inspectionStartDateTime} today.  If you need to change the start time, please do so now, otherwise the inspection will be available to record data 15-minutes prior to the start time.`;

    case NOTIFICATION_ENUM.approvalSettingUpdated:
      return `Please be notified that the Insurance Company Coordinator for ${data.companyName} has turned ${data.value} the approval for ${data.settingName}. ${data.settingName} submitted will ${data.requiredAction}`;

    case NOTIFICATION_ENUM.missingDocumentsReminder:
      return `Please be notified that the project ${data.projectName} is missing documentation to be uploaded. Please upload these documents before ${data.dueDate}. If you have any questions, please contact ${data.contactName} at ${data.contactEmail}`;

    case NOTIFICATION_ENUM.missingDocumentsDeadline:
      return `Unfortunately, the time has passed, and no further document uploads are permitted for the project ${data.projectName} – Please contact the Risk Engineer`;

    case NOTIFICATION_ENUM.weatherAlert:
      return `Please be notified that the project ${data.projectName} has a weather alert`;

    default:
      return `unknown notify type: ${type}.`;
  }
};

const ItemDescription = ({ notify, isNotificationAndAlerts }) => {
  const { type, data } = notify;

  if (RECOMMENDATION_TYPES.includes(type)) {
    return (
      <Description isNotificationAndAlerts={isNotificationAndAlerts}>
        <RecommendationDescription notify={notify} />
      </Description>
    );
  }

  return (
    <Description isNotificationAndAlerts={isNotificationAndAlerts}>
      {getDescriptionString(type, data)}
    </Description>
  );
};

export default ItemDescription;
