import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const BudgetReached = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: { projectId, serviceNames, projectName, budgetThreshold } } = notify;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToBudget = () => {
    handleClose();
    closeNotificationList();
    navigate(`/projects/${projectId}/budget`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="Budget Reached"
      cancelButtonTitle="Close"
      confirmButtonTitle="View Budgets"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToBudget}
    >
      <ConfirmationText
        value={[serviceNames, budgetThreshold, projectName]}
        text="iMR Global has detected that the budget for {placeholder} services has reached {placeholder}% of the spend total for {placeholder}.
        If you need to increase the budget, please update this in the budget section."
      />
    </ConfirmationDialog>
  );
};

export default BudgetReached;
