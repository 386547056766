import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  line-height: 180%;
  font-family: Graphik LC;

  form {
    > div, > button {
      width: 100%;
      margin-bottom: 24px;
    }
  }
`;
