import * as Yup from 'yup';

import { requiredString } from 'utils/validation/customTypes';
import { createUniversalDate } from 'utils/DateUtils';
import { COMPANY_TYPES } from 'types/company';
import { LabelValue, NamePath } from 'types';
import { SUBSCRIPTION_PACKAGE, SUBSCRIBER_TYPE } from 'types/subscription';

export const validationSchema = Yup.object().shape({
  subscriberId: requiredString,
});

export const validationSchemaIC = Yup.object().shape({
  primaryUserName: Yup.object().shape({
    firstName: requiredString,
    lastName: requiredString,
  }),
  address: Yup.object().shape({
    country: requiredString,
    state: requiredString,
    city: requiredString,
    addressLine: requiredString,
    zipCode: requiredString,
  }),
  phone: requiredString,
  companyName: requiredString,
  primaryUserEmail: requiredString.email('Please enter a valid email address.'),
});

export const subscriberTypeOptions: LabelValue[] = [
  {
    label: 'Insurance Company',
    value: 'InsuranceCompany',
  },
  {
    label: 'Risk Company',
    value: 'RiskCompany',
  },
  {
    label: 'Broker Company',
    value: 'BrokerCompany',
  },
  {
    label: 'Broker',
    value: 'Broker',
  },
  {
    label: 'Insurance Representative',
    value: 'InsuranceRepresentative',
  },
  {
    label: 'Risk Manager',
    value: 'RiskManager',
  },
  {
    label: 'Developer Owner',
    value: 'DeveloperOwner',
  },
];

export const PACKAGES_IDS = {
  Lite: 'Lite',
  LitePlus: 'LitePlus',
  Pro: 'Pro',
  Global: 'Global',
};

export const PACKAGE_STATUSES = {
  Active: 'Active',
  Upcoming: 'Upcoming',
  Expired: 'Expired',
};

export const addSubscriptionInitialValues = {
  type: 'Paid',
  packageType: PACKAGES_IDS.Lite,
  period: 'OneMonth',
  startDate: createUniversalDate(),
  ownerType: '',
  maxProjectsCount: 20,
};

export const initialValues = {
  subscriberId: '',
};

export const initialValuesIC = {
  primaryUserName: {
    firstName: '',
    lastName: '',
  },
  address: {
    country: '',
    state: '',
    city: '',
    addressLine: '',
    zipCode: '',
  },
  phone: '',
  companyName: '',
  primaryUserEmail: '',
  locationName: 'Main Location',
};

export const typeOptions = [
  {
    label: 'Paid',
    value: 'Paid',
  },
  {
    label: 'Free',
    value: 'Free',
  },
];

export const periodOptions = [
  {
    label: '1 Month',
    value: 'OneMonth',
  },
  {
    label: '3 Months',
    value: 'ThreeMonths',
  },
  {
    label: '6 Months',
    value: 'SixMonths',
  },
  {
    label: '1 Year',
    value: 'Year',
  },
];

export const getPackageOptions = (subscription: SUBSCRIBER_TYPE) => {
  switch (subscription) {
    case SUBSCRIBER_TYPE.DEVELOPER_OWNER:
    case SUBSCRIBER_TYPE.RISK_MANAGER:
      return [
        {
          label: 'Pro',
          value: PACKAGES_IDS.Pro,
        },
      ];
    case SUBSCRIBER_TYPE.BROKER:
    case SUBSCRIBER_TYPE.INSURANCE_REPRESENTATIVE:
    case SUBSCRIBER_TYPE.RISK_COMPANY:
      return [
        {
          label: 'Pro',
          value: PACKAGES_IDS.Pro,
        },
        {
          label: 'Global',
          value: PACKAGES_IDS.Global,
        },
      ];
    default:
      return [
        {
          label: 'Lite',
          value: PACKAGES_IDS.Lite,
        },
        {
          label: 'Lite+',
          value: PACKAGES_IDS.LitePlus,
        },
        {
          label: 'Pro',
          value: PACKAGES_IDS.Pro,
        },
        {
          label: 'Global',
          value: PACKAGES_IDS.Global,
        },
      ];
  }
};

type SubscriberTypePaths = {
  [key in SUBSCRIBER_TYPE]: NamePath;
};

export const subscribersTypePaths: SubscriberTypePaths = {
  [SUBSCRIBER_TYPE.RISK_MANAGER]: {
    name: 'RiskManager',
    path: 'risk-manager',
  },
  [SUBSCRIBER_TYPE.DEVELOPER_OWNER]: {
    name: 'DeveloperOwner',
    path: 'developer-owner',
  },
  [SUBSCRIBER_TYPE.BROKER]: {
    name: 'Broker',
    path: 'broker',
  },
  [SUBSCRIBER_TYPE.INSURANCE_REPRESENTATIVE]: {
    name: 'InsuranceRepresentative',
    path: 'insurance-representative',
  },
  [SUBSCRIBER_TYPE.RISK_COMPANY]: {
    name: 'RiskCompany',
    path: 'risk-company',
  },
  [SUBSCRIBER_TYPE.INSURANCE_COMPANY]: {
    name: 'InsuranceCompany',
    path: 'insurance-company',
  },
  [SUBSCRIBER_TYPE.BROKER_COMPANY]: {
    name: 'BrokerCompany',
    path: 'broker-company',
  },
  // unregistered insurance companies
  [SUBSCRIBER_TYPE.INVITED_COMPANY]: {
    name: 'InsuranceCompany',
    path: 'invited-company',
  },
};

type PackageUserCountsByCompanyType = {
  [key in COMPANY_TYPES]: {
    [key1 in SUBSCRIPTION_PACKAGE]: {
      riskEngineers: number;
      underwriters: number;
      coordinators: number;
      riskCoordinators: number;
    };
  };
};

export const PACKAGES_USER_COUNTS_BY_COMPANY_TYPE: PackageUserCountsByCompanyType = {
  [COMPANY_TYPES.NO_COMPANY]: {
    [SUBSCRIPTION_PACKAGE.FREE_USAGE]: {
      riskEngineers: 0,
      underwriters: 0,
      coordinators: 0,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.LITE]: {
      riskEngineers: 0,
      underwriters: 0,
      coordinators: 0,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.LITE_PLUS]: {
      riskEngineers: 0,
      underwriters: 0,
      coordinators: 0,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.PRO]: {
      riskEngineers: 0,
      underwriters: 0,
      coordinators: 0,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.GLOBAL]: {
      riskEngineers: 0,
      underwriters: 0,
      coordinators: 0,
      riskCoordinators: 0,
    },
  },
  [COMPANY_TYPES.INSURANCE]: {
    [SUBSCRIPTION_PACKAGE.FREE_USAGE]: {
      riskEngineers: 0,
      underwriters: 0,
      coordinators: 0,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.LITE]: {
      riskEngineers: 1,
      underwriters: 1,
      coordinators: 1,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.LITE_PLUS]: {
      riskEngineers: 2,
      underwriters: 2,
      coordinators: 1,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.PRO]: {
      riskEngineers: 10,
      underwriters: 6,
      coordinators: 2,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.GLOBAL]: {
      riskEngineers: 30,
      underwriters: 10,
      coordinators: 3,
      riskCoordinators: 0,
    },
  },
  [COMPANY_TYPES.BROKER]: {
    [SUBSCRIPTION_PACKAGE.FREE_USAGE]: {
      riskEngineers: 0,
      underwriters: 0,
      coordinators: 0,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.LITE]: {
      riskEngineers: 1,
      underwriters: 1,
      coordinators: 1,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.LITE_PLUS]: {
      riskEngineers: 2,
      underwriters: 2,
      coordinators: 1,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.PRO]: {
      riskEngineers: 10,
      underwriters: 6,
      coordinators: 2,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.GLOBAL]: {
      riskEngineers: 30,
      underwriters: 10,
      coordinators: 3,
      riskCoordinators: 0,
    },
  },
  [COMPANY_TYPES.RISK]: {
    [SUBSCRIPTION_PACKAGE.FREE_USAGE]: {
      riskEngineers: 1,
      riskCoordinators: 1,
      coordinators: 0,
      underwriters: 0,
    },
    [SUBSCRIPTION_PACKAGE.LITE]: {
      riskEngineers: 1,
      riskCoordinators: 1,
      coordinators: 0,
      underwriters: 0,
    },
    [SUBSCRIPTION_PACKAGE.LITE_PLUS]: {
      riskEngineers: 0,
      underwriters: 0,
      coordinators: 0,
      riskCoordinators: 0,
    },
    [SUBSCRIPTION_PACKAGE.PRO]: {
      riskEngineers: 3,
      riskCoordinators: 1,
      coordinators: 0,
      underwriters: 0,
    },
    [SUBSCRIPTION_PACKAGE.GLOBAL]: {
      riskEngineers: 12,
      riskCoordinators: 3,
      coordinators: 0,
      underwriters: 0,
    },
  },
};

const standardLayers = { flood: true, wildfire: true, earthquake: true, windstorm: true };
const extendedLayers = { ...standardLayers, hurricane: true };

const globalPackageTooltip = 'This feature is only available for Global Package subscribers.';
const proPackageTooltip = 'This feature is only available for Pro package subscribers.';

export const PACKAGES_USER_LAYERS = {
  Insurance: {
    FreeUsage: {},
    [SUBSCRIPTION_PACKAGE.LITE]: standardLayers,
    LitePlus: standardLayers,
    Pro: standardLayers,
    Global: extendedLayers,
    tooltip: globalPackageTooltip,
  },
  BrokerCompany: {
    FreeUsage: {},
    Lite: standardLayers,
    LitePlus: standardLayers,
    Pro: standardLayers,
    Global: extendedLayers,
    tooltip: globalPackageTooltip,
  },
  Risk: {
    FreeUsage: standardLayers,
    Lite: standardLayers,
    Pro: standardLayers,
    Global: extendedLayers,
    tooltip: globalPackageTooltip,
  },
  ExternalRE: {
    FreeUsage: standardLayers,
    tooltip: globalPackageTooltip,
  },
  Broker: {
    FreeUsage: {},
    Lite: {},
    Pro: {},
    Global: extendedLayers,
    tooltip: globalPackageTooltip,
  },
  InsuranceRepresentative: {
    FreeUsage: {},
    Lite: {},
    Pro: {},
    Global: extendedLayers,
    tooltip: globalPackageTooltip,
  },
  RiskManager: {
    FreeUsage: {},
    Lite: {},
    Pro: extendedLayers,
    tooltip: proPackageTooltip,
  },
  DeveloperOwner: {
    FreeUsage: {},
    Lite: {},
    Pro: extendedLayers,
    tooltip: proPackageTooltip,
  },
};

export const getPackageName = (packageId: string) => (packageId === PACKAGES_IDS.LitePlus ? 'Lite+' : packageId);

export const SUBSCRIPTION_CANCELLATION_REASONS = [
  'The software didn’t give me the tools I needed.',
  'Too complicated to use',
  'I have another tool',
  'Other',
];
