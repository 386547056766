import { COMPANY_TYPES } from 'types/company';

export function addRestrictionsToMenu(menu, isPackageGlobal, companyType) {
  if (companyType === COMPANY_TYPES.BROKER) {
    return { ...menu, 'Global Ecosystem': undefined };
  }
  if (!isPackageGlobal) {
    return {
      ...menu,
      'Global Ecosystem': {
        ...menu['Global Ecosystem'],
        disabled: true,
        tooltip: 'This feature is only available for Global package subscribers',
      },
    };
  }

  return menu;
}
