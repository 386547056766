import React, { useEffect, useState } from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import TeamMembersService from 'myCompany/api/TeamMembersService';
import { showNotifySuccess, showNotifyError } from 'services/toaster';
import { CONTACT_STATUSES } from 'utils/constants';
import InviteService from 'modules/notifications/api/InviteService';
import * as TeamMembersActions from 'myCompany/store/teamMembers.reducer';
import { userInfo } from 'projects/store/users.selectors';
import { nullableString, requiredString } from 'utils/validation/customTypes';

import TeamMembersForm from 'myCompany/pages/TeamMembers/TeamMembersForm';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';
import { IconButton, Menu, MenuItem, Select, Checkbox } from 'components';
import { REPosition, deleteDisabledTooltipRE, deleteDisabledTooltipPrimary } from '../../constants';

import { CheckboxWrapper, ControlsWrapper, Root, SelectContainer, StyledFormDialog, StyledFormikField } from './styles';
import { ROLES_IDS } from '../../../../../../services/AuthService';

const stopPropagation = (event) => {
  event.stopPropagation();
};

function getDeleteConfirmationMessage(contactStatus) {
  switch (contactStatus) {
    case CONTACT_STATUSES.inviteSent.value:
    case CONTACT_STATUSES.needApproval.value:
      return 'Are you sure you want to delete {placeholder} from project contacts? The invitation link will be expired, user will no longer be able to register.';
    case CONTACT_STATUSES.hasAccess.value:
      return 'Are you sure you want to delete {placeholder} from project contacts? It will be deleted immediately, user will no longer be able to access the project.';
    case CONTACT_STATUSES.noAccess.value:
    case CONTACT_STATUSES.accessRejected.value:
    default:
      return 'Are you sure you want to delete {placeholder} from project contacts? It will be deleted immediately, you can\'t undo this action.';
  }
}

const Controls = ({ member, onSuccess, members }) => {
  const dispatch = useDispatch();
  const user = useSelector(userInfo);
  const [deleteDialog, setDeleteDialog] = useState(null);
  const [editDialog, setEditDialog] = useState(null);
  const [resendDialog, setResendDialog] = useState(false);
  const [deleteValues, setDeleteValues] = useState({ targetId: '', shouldNotTransfer: false });
  const [disableTransfer, setDisableTransfer] = useState(false);
  const [targetOptions, setTargetOptions] = useState([]);

  const canResendInvite = member.status === CONTACT_STATUSES.inviteSent.value;
  const canEdit = member.status === CONTACT_STATUSES.noAccess.value;
  const canDelete = member.canBeDeleted;
  const isUW = member.position === ROLES_IDS.ICUnderwriter;
  const showTransferProjects = isUW || member.position === REPosition;

  const validationSchema = Yup.object().shape({
    shouldNotTransfer: Yup.boolean(),
    targetId: Yup.string()
      .when('shouldNotTransfer', {
        is: false,
        then: Yup.string().required('Please select another team member to transfer their projects.'),
      }),
  });

  let deleteTooltip = member.position === REPosition && deleteDisabledTooltipRE;
  if (member.isPrimaryCoordinator) deleteTooltip = deleteDisabledTooltipPrimary;

  function handleEmailResendClick() {
    setResendDialog(true);
  }

  function handleContactEditClick() {
    const { id, fullName } = member;
    setEditDialog({ id, fullName });
  }

  function closeDeleteDialog() {
    setDeleteDialog(null);
  }

  useEffect(() => {
    setDisableTransfer(deleteValues.shouldNotTransfer);
    if (deleteValues.shouldNotTransfer) {
      setDeleteValues({ targetId: null, shouldNotTransfer: true });
    }
  }, [deleteValues.shouldNotTransfer]);

  useEffect(() => {
    const options = members.filter((t) => t.position === member.position && t.id !== member.id);
    if (options.length > 1) {
      setDeleteValues({ ...deleteValues, targetId: options[0].id });
    }
    const targetOpts = members.filter((t) => t.position === member.position && t.id !== member.id).map((t) => ({ value: t.id, label: t.fullName }));
    setTargetOptions(targetOpts);
  }, [members]);

  function handleDeleteConfirm() {
    TeamMembersService.deleteTeamMember(deleteDialog.id)
      .then(() => {
        showNotifySuccess('Team member was successfully deleted from your company.');
        closeDeleteDialog();
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => showNotifyError(error));
  }

  const handleDeleteWithTransferConfirm = async (values) => {
    try {
      if (values.shouldNotTransfer) {
        await TeamMembersService.deleteTeamMember(member.id);
        showNotifySuccess('Team Member was successfully deleted from your company.');
        closeDeleteDialog();
        if (onSuccess) {
          onSuccess();
        }
        return;
      }
      await TeamMembersService.deleteTeamMemberWithTransfer({ id: member.id, targetId: values.targetId });
      showNotifySuccess('Team Member was successfully deleted from your company.');
      closeDeleteDialog();
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      showNotifyError(error);
    }
  };

  function handleContactDeleteClick() {
    const { id, fullName, status } = member;
    setDeleteDialog({ id, fullName });
  }

  function closeEditDialog() {
    setEditDialog(null);
  }

  function closeResendDialog() {
    setResendDialog(false);
  }

  async function handleEditConfirm(values) {
    const response = await dispatch(TeamMembersActions.updateTeamMember({ ...values, id: editDialog.id }));
    if (!response.fieldErrors) {
      showNotifySuccess(`${editDialog.fullName} data was successfully updated.`);
      closeEditDialog();
      if (onSuccess) onSuccess();
    }

    return response;
  }

  function handleResendConfirm() {
    InviteService.resendTeamMember(member.id)
      .then(() => {
        showNotifySuccess(`The invitation email was successfully sent to ${member.email}.`);
        closeDeleteDialog();
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => showNotifyError(error));
  }

  return (
    <ControlsWrapper onClick={stopPropagation}>
      {canResendInvite && (
        <MuiTooltip arrow title="Resend Invite">
          <div>
            <IconButton
              transparent
              icon="reset"
              onClick={handleEmailResendClick}
            />
          </div>
        </MuiTooltip>
      )}

      {(canEdit || canDelete || user.email !== member.email) && (
        // user.email !== member.email self check
        <Menu>
          {canEdit && <MenuItem icon="edit" text="Edit" onClick={handleContactEditClick} />}

          <MenuItem
            icon="deleteSmall"
            text="Delete"
            onClick={handleContactDeleteClick}
            disabled={!canDelete}
            tooltip={!canDelete && deleteTooltip}
          />
        </Menu>
      )}

      {deleteDialog && (
        <ConfirmationDialog
          open
          title="Delete Contact"
          confirmButtonTitle="Delete"
          onClose={closeDeleteDialog}
          onCancel={closeDeleteDialog}
          onConfirm={handleDeleteConfirm}
        >
          {deleteDialog.deleteConfirmationText}
          {showTransferProjects ? (
            <>
              <StyledFormDialog
                title="Delete Contact"
                submitButtonTitle="Delete"
                open
                onCancel={closeDeleteDialog}
                onSubmit={handleDeleteWithTransferConfirm}
                onClose={closeDeleteDialog}
                onFormChange={setDeleteValues}
                validationSchema={validationSchema}
                initialValues={deleteValues}
                enableReinitialize
              >
                <Root>
                  <ConfirmationText
                    value={member.fullName}
                    text={getDeleteConfirmationMessage(member.status)}
                  />
                  <br />
                  Before you continue, please select the user you want to transfer the projects to:
                  <SelectContainer>
                    <StyledFormikField
                      component={Select}
                      name="targetId"
                      label="User to transfer projects"
                      required
                      options={targetOptions}
                      disabled={disableTransfer}
                    />
                  </SelectContainer>
                  {isUW && (
                    <CheckboxWrapper>
                      <StyledFormikField
                        component={Checkbox}
                        name="shouldNotTransfer"
                        label="Do not transfer Projects"
                      />
                    </CheckboxWrapper>
                  )}
                </Root>
              </StyledFormDialog>
            </>
          )
            : (
              <ConfirmationText
                value={member.fullName}
                text={getDeleteConfirmationMessage(member.status)}
              />
            )
          }
        </ConfirmationDialog>
      )}

      {editDialog && (
        <TeamMembersForm
          isOpen
          mode="edit"
          onCancel={closeEditDialog}
          onSubmit={handleEditConfirm}
          onClose={closeEditDialog}
          member={member}
        />
      )}

      {resendDialog && (
        <ConfirmationDialog
          open
          title="Resend Invite"
          confirmButtonTitle="Send Email"
          onClose={closeResendDialog}
          onCancel={closeResendDialog}
          onConfirm={handleResendConfirm}
        >
          <ConfirmationText
            value={member.fullName}
            text="Do you want to send another invitation email to {placeholder}?"
          />
        </ConfirmationDialog>
      )}
    </ControlsWrapper>
  );
};

export default Controls;
