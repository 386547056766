import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 20px auto 40px auto;
  font-family: Graphik LC;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: var(--textColor);

  @media (max-height: 800px) {
    margin: 1% auto 5% auto;
  }
`;

export const Description = styled.div`
  margin-top: 50px;
  font-family: Graphik LC;
  font-size: 16px;
  line-height: 160%;
  color: var(--textColor);
  max-width: 650px;
  @media (max-height: 800px) {
    margin-top: 3%;
  }
`;

export const PreviewImage = styled.img`
  max-width: 520px;
  max-height: 70%;
`;

export const Line = styled.div`
  display: flex;
  flex-grow: 1;
  width: 30%;
  margin: 20px 10px;
  border-top: 1px solid var(--inputDisabledText);
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  color: var(--titleColor);
`;
