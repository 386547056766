import React from 'react';

const Projects = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M.5.75h11.25V2H.5V.75zm0 3.75h11.25v1.25H.5V4.5zm0 3.75h11.25V9.5H.5V8.25zM.5 12h11.25v1.25H.5V12zM14.25.75h1.25V2h-1.25V.75zm0 3.75h1.25v1.25h-1.25V4.5zm0 3.75h1.25V9.5h-1.25V8.25zm0 3.75h1.25v1.25h-1.25V12z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Projects;
