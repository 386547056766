import styled from 'styled-components';

import { Icon } from 'components';

export const MinimizedWrapper = styled.div`
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 71px;
  padding: 14px 20px;
  overflow-y: hidden;
  background: var(--inputBackground);
  border-top-right-radius: 4px;
  cursor: pointer;
  box-shadow: 2px -2px 10px 1px rgba(0, 0, 0, 0.1);
`;

export const ProjectName = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: var(--lightTextColor);
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
`;

export const Title = styled.div`
  position: relative;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  color: var(--titleColor);
`;

export const OpenFull = styled(Icon)`
  position: absolute;
  right: 0;
  top: 0;
  color: var(--textColor);
`;

export const Dot = styled(Icon)`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 10px;
  width: 7px;
  height: 7px;
  margin-left: 12px;
  color: var(--navTabFillActive);
  svg {
    width: 100%;
    height: 100%;
  }
`;
