import qs from 'qs';
import ApiClient from 'services/ApiClient';
import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 5;

class NotificationService extends ApiClient {
  getProjectNotificationsList({ projectId, page = 1, pageSize = PAGE_SIZE, sortBy }) {
    const query = {
      ProjectId: projectId,
      Page: page,
      PageSize: pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    return this.call({
      method: 'get',
      url: `/notifications?${qs.stringify(query)}`,
    });
  }
}

export default new NotificationService();
