import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as MuiTooltip } from '@mui/material';

import * as TeamMembersActions from 'myCompany/store/teamMembers.reducer';
import OpenAttachmentButton from 'modules/common/components/OpenAttachmentButton';
import { teamMembersData, membersSortBy, currentPage } from 'myCompany/store/teamMembers.selectors';
import { CONTACT_STATUSES, CONTACT_POSITIONS, renderStatusLabelByValue } from 'utils/constants';
import { mapValuesToLabels } from 'utils/FormatUtils';
import { ROLES_IDS } from 'services/AuthService';
import { Table, NoContent } from 'components';

import Controls from './Controls';
import { StatusTag, NameWrapper, StyledIcon } from './styles';

const renderName = (name, isPrimaryCoordinator) => {
  if (isPrimaryCoordinator) {
    return (
      <NameWrapper>
        <MuiTooltip arrow title="Administrator, Primary Contact">
          <span>
            <StyledIcon icon="primaryUserAdmin" noWrapper />
          </span>
        </MuiTooltip>
        <span>{name}</span>
      </NameWrapper>
    );
  }
  return (
    <NameWrapper>
      <MuiTooltip arrow title="Administrator">
        <span>
          <StyledIcon icon="userAdmin" noWrapper />
        </span>
      </MuiTooltip>
      <span>{name}</span>
    </NameWrapper>
  );
};

const columns = [
  {
    width: '18%', key: 'fullName', title: 'Name',
    render: ({ fullName, position, isPrimaryCoordinator }) => (
      position === ROLES_IDS.ICCoordinator || position === ROLES_IDS.RCCoordinator
        ? renderName(fullName, isPrimaryCoordinator)
        : fullName
    ),
  },
  {
    width: '14%', key: 'position', title: 'System Role', noSort: true,
    render: ({ position }) => {
      const values =  mapValuesToLabels(position, CONTACT_POSITIONS);
      return values;
    }
  },
  {
    width: '14%', key: 'designation', title: 'Designation', noSort: true,
  },
  {
    width: '19%', key: 'email', title: 'Email', noSort: true,
  },
  {
    width: '12%', key: 'status', title: 'Status', noSort: true,
    render: ({ status }) => (
      <StatusTag status={status}>
        {renderStatusLabelByValue(status, CONTACT_STATUSES)}
      </StatusTag>
    ),
  },
  {
    width: '12%', key: 'location', title: 'Office Location', noSort: true, minWidth: '85px',
  },
  {
    width: '4%', key: 'cv', title: 'CV', noSort: true,
    render: ({ cvFileInfo }) => (
      <OpenAttachmentButton
        icon="cvIcon"
        fileInfo={cvFileInfo}
      />
    ),
  },
  {
    width: '7%', key: 'controls', title: '', align: 'right', noSort: true, minWidth: '85px',
  },
];

const TeamMembersTable = ({ searchFilter, onRowClick }) => {
  const dispatch = useDispatch();
  const sortBy = useSelector(membersSortBy);
  const teamMembers = useSelector(teamMembersData);
  const page = useSelector(currentPage);

  const { isLoading, items = [], totalPages, totalCount } = teamMembers || {};

  function setPage(pageNumber) {
    dispatch(TeamMembersActions.setCurrentPage(pageNumber));
  }

  function handleRowClick(item) {
    if (onRowClick) {
      onRowClick(item);
    }
  }

  function handleSortBy(newSortBy) {
    dispatch(TeamMembersActions.setTeamMembersSortBy(newSortBy));
  }

  function fetchTeamMembersList() {
    dispatch(TeamMembersActions.getTeamMembersList({ page, sortBy, name: searchFilter }));
  }

  function handlePrevPageClick() {
    setPage(page - 1);
  }

  function handleNextPageClick() {
    setPage(page + 1);
  }

  function handleGoToPage(selectedPage) {
    setPage(selectedPage);
  }

  function renderControls(member) {
    return (
      <Controls
        member={member}
        onSuccess={fetchTeamMembersList}
        members={items}
      />
    );
  }

  function renderNoContent() {
    if (searchFilter) {
      return (
        <NoContent
          title="No results found."
          descr="Try adjust your search to find what you are looking for."
          customIcon="contacts"
        />
      );
    }
    return (
      <NoContent
        title="There are no contacts yet."
        descr="Click &quot;New Team Member&quot; to add a new contact."
        customIcon="contacts"
      />
    );
  }

  useEffect(() => {
    setPage(1);
  }, [searchFilter]);

  useEffect(() => {
    fetchTeamMembersList();
  }, [page, sortBy, searchFilter]);

  // Primary Contact should be always on top
  const primaryContact = items.find((contact) => contact.isPrimaryCoordinator);
  const listWithoutPrimary = items.filter((el) => !el.isPrimaryCoordinator);
  const sortedItems = primaryContact ? [primaryContact, ...listWithoutPrimary] : items;

  return (
    <Table
      items={sortedItems}
      columns={columns}
      sortBy={sortBy}
      isLoading={isLoading}
      onRowClick={handleRowClick}
      onSortBy={handleSortBy}
      onNoContent={renderNoContent}
      onRenderControls={renderControls}
      pagination={{
        page,
        totalPages,
        itemsCount: items.length,
        totalCount,
      }}
      onPrevPage={handlePrevPageClick}
      onNextPage={handleNextPageClick}
      onGoToPage={handleGoToPage}
    />
  );
};

export default TeamMembersTable;
