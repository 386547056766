import ApiClient from 'services/ApiClient';

class RatesService extends ApiClient {
  getRates() {
    return this.call({
      method: 'get',
      url: '/rates',
    });
  }

  addTimeRates(data) {
    return this.call({
      method: 'post',
      url: '/rates/time',
      data,
    });
  }

  addExpenseRates(data) {
    return this.call({
      method: 'post',
      url: '/rates/expense',
      data,
    });
  }

  deleteRate(id) {
    return this.call({
      method: 'delete',
      url: `/rates/${id}`,
    });
  }
}

export default new RatesService();
