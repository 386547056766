import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const RecommendationsAwaitingIssue = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: { projectId, projectName } } = notify;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    handleClose();
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToRecommendations = () => {
    handleClose();
    closeNotificationList();
    navigate(`/projects/${projectId}/recommendations`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="Recommendations Awaiting Issue"
      cancelButtonTitle="Close"
      confirmButtonTitle="View Recommendations"
      onClose={handleClose}
      onCancel={handleCancel}
      onConfirm={redirectToRecommendations}
    >
      <ConfirmationText
        value={projectName}
        text='Following the inspection of {placeholder}, please review the recommendations (if there are any) and issue them to the Insured or select "No Recommendations". Note that the recommendations should be issued or sent for the approval from the web portal.'
      />
    </ConfirmationDialog>
  );
};

export default RecommendationsAwaitingIssue;
