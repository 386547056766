import { isString } from 'lodash';
import { CURRENCIES } from 'utils/constants';

export const formatCurrency = (currency: string | number) => {
  if (!currency && currency !== 0) {
    return '';
  }
  const unformattedCurrency = isString(currency) ? Number(currency.replace(/,/g, '')) : currency;
  const formattedCurrency = new Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(unformattedCurrency);

  return formattedCurrency;
};

export const formatNoDecimalCurrency = (currency: string | number) => {
  if (!currency && currency !== 0) {
    return '';
  }
  const unformattedCurrency = isString(currency) ? Number(currency.replace(/,/g, '')) : currency;
  const formattedCurrency = new Intl.NumberFormat('en', { maximumFractionDigits: 0 }).format(unformattedCurrency);

  return formattedCurrency;
};

export const currencyStringToNumber = (currency: string) => (isString(currency) ? Number(currency.replace(/,/g, '')) : currency);

export const getCurrencySymbol = (currencyCode: string) => (
  CURRENCIES.find((currency) => currency.value === currencyCode)?.currencySymbol || '¤'
);

export const getAmountWithCurrencySymbol = (amount: number, currencyCode: string) => {
  if (!amount && amount !== 0) {
    return '';
  }

  return `${getCurrencySymbol(currencyCode)}${formatCurrency(amount)}`;
};
