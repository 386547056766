import React from 'react';

const PrimaryUserAdmin = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 1a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM10 15h1v-2.5A3.5 3.5 0 007.5 9h-3A3.5 3.5 0 001 12.5V15h9z" fill="#fff" fillOpacity=".87" />
      <path d="M12.5 8.09l-1.295-1.295-.705.705 2 2L16 6l-.705-.705L12.5 8.09z" fill="#96CEF6" />
    </svg>
  );
};

export default PrimaryUserAdmin;
