import LocationService from 'projects/api/LocationService';

const GET_LOCATIONS_LIST = 'GET_LOCATIONS_LIST';
const SET_PRIMARY_LOCATION = 'SET_PRIMARY_LOCATION';

export const getLocationsList = (params) => ({
  type: GET_LOCATIONS_LIST,
  apiCall: () => LocationService.getLocationsList(params),
});

export const setPrimaryLocation = (id) => ({
  type: SET_PRIMARY_LOCATION,
  apiCall: () => LocationService.setPrimaryLocation(id),
});

const initialState = {
  locationsList: {
    data: [],
  },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_LOCATIONS_LIST: {
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          ...response,
        },
      };
    }

    case SET_PRIMARY_LOCATION: {
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          data: state.locationsList.data.map((location) => ({
            ...location,
            isPrimary: location.id === response.id,
          })),
        },
      };
    }

    default:
      return state;
  }
};
