import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import InfoCard from 'common/components/InfoCard';

export const InfoWrapper = styledMui('div')(({ theme }) => ({
  width: 511,
  marginTop: 40,
  padding: '18px 24px 6px',
  background: theme.palette.sidebar.selected.backgroundColor,
  borderRadius: 4,
  border: 'none',
}));

export const InfoTitle = styledMui('div')(({ theme }) => ({
  paddingBottom: 16,
  marginBottom: 16,
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '150%',
  color: theme.palette.text.main,
  borderBottom: `1px solid ${theme.palette.border.light}`,
}));

export const InfoDataWrapper = styled.div`
  display: flex;
`;

export const InfoText = styledMui('div')(({ theme }) => ({
  display: 'inline-block',
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '150%',
  color: theme.palette.text.main,
}));

export const InfoTextBold = styled(InfoText)`
  font-weight: 500;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  ${({ right }) => right && 'align-self: flex-end;'}
  > * {
    margin-bottom: 12px;
  }
`;

export const SubTitle = styledMui('div')(({ theme }) => ({
  margin: '24px 0',
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 16,
  lineHeight: '150%',
  letterSpacing: '-0.02em',
  color: theme.palette.text.title,
  opacity: 0.85,
}));

export const StyledInfoCard = styled(InfoCard)(({ theme }) => {
  return ({
    width: 511,
    background: theme.palette.sidebar.selected.backgroundColor,
    borderRadius: 4,
    border: 'none',
  });
});

export const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 15px;
  }
`;
