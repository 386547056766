import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Library from "./pages/Library";

const LibraryRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Library />} />
      <Route exact path="/documents" element={<Library />} />
      <Route path="*" element={<Navigate to="/library" replace />} />
    </Routes>
  );
};

export default LibraryRoutes;
