import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SubjectMenu from "help/components/SubjectMenu";
import { GE_SUBJECTS } from "help/constants";

import Overview from "./Overview";
import AdvancedDetails from "./AdvancedDetails";

import { Root } from "../styles";

const GlobalEcosystem = () => (
  <>
    <SubjectMenu section="global-ecosystem" subjects={GE_SUBJECTS} />
    <Root>
      <Routes>
        <Route
          path="/help/global-ecosystem/overview"
          exact
          component={Overview}
        />
        <Route
          path="/help/global-ecosystem/advanced-details"
          exact
          component={AdvancedDetails}
        />
        <Route
          path="/help/global-ecosystem"
          element={<Navigate to="/help/global-ecosystem/overview" replace />}
        />
      </Routes>
    </Root>
  </>
);

export default GlobalEcosystem;
