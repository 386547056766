import { styled as styledMui } from '@mui/material/styles';
import Button from 'components/buttons/Button';

export const StyledButton = styledMui(Button)(({ theme }) => {
  return {
    minHeight: 30,
    marginLeft: -15,
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 11,
    lineHeight: '15px',
    letterSpacing: '0.05em',
    color: theme.palette.sidebar.selected.icon.color,
    textTransform: 'uppercase',
    '&.MuiButton-textPrimary': {
      backgroundColor: 'transparent',
    },
  };
});
