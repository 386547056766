import React from 'react';

const ThreeDots = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM10 11.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM10 16.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default ThreeDots;
