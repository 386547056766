import styled from 'styled-components';

export const Text = styled.div`
  margin-top: 20px;
`;

export const CardsContainer = styled.div`
  display: flex;

  > :last-child {
    margin-left: 15px;
  }
`;
