import React from 'react';
import * as Yup from 'yup';

import FormDialog from 'components/Dialogs/FormDialog';
import { FormikField, Input } from 'components';

import { showNotifySuccess, showNotifyError } from 'services/toaster';
import LibraryService from 'library/api/LibraryService';
import { nullableRequiredString, nullableString } from 'utils/validation/customTypes';

import { Wrapper } from './styles';

const FOLDER_MAX_LENGTH = 30;

const ADD_FOLDER_SCHEMA = Yup.object().noUnknown().shape({
  name: nullableRequiredString.max(FOLDER_MAX_LENGTH),
  parentFolderId: nullableString,
});

const EDIT_FOLDER_SCHEMA = Yup.object().noUnknown().shape({
  id: nullableRequiredString,
  name: nullableRequiredString.max(FOLDER_MAX_LENGTH),
});

const FolderDialogForm = ({ id, name, parentFolderId, isEdit, isOpen, onCancel, onSubmit }) => {
  const initialValues = isEdit ? {
    id,
    name,
  } : {
    name: '',
    parentFolderId,
  };

  const handleSubmit = async (values, formikBag) => {
    const request = isEdit
      ? (value) => LibraryService.renameFolder(value)
      : (value) => LibraryService.addFolder(value);

    try {
      await request(values);

      if (isEdit) {
        showNotifySuccess(`${initialValues.name} folder was successfully renamed to ${values.name}.`);
      } else {
        showNotifySuccess(`${values.name} folder was successfully created.`);
      }

      onCancel();
      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      if (error.fieldErrors) {
        formikBag.setErrors(error.fieldErrors);
      } else {
        showNotifyError(error);
      }
    }
  };

  return (
    <FormDialog
      title={isEdit ? 'Rename Folder' : 'New Folder'}
      submitButtonTitle="Save"
      open={isOpen}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={isEdit ? EDIT_FOLDER_SCHEMA : ADD_FOLDER_SCHEMA}
    >
      <Wrapper>
        <FormikField
          component={Input}
          required
          name="name"
          label="Folder Name"
          maxLength={FOLDER_MAX_LENGTH}
        />
      </Wrapper>
    </FormDialog>
  );
};

export default FolderDialogForm;
