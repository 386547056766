import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pickBy } from 'lodash';
import { Navigate } from 'react-router-dom';
import { parseISO, differenceInYears } from 'date-fns';

import GlobalEcosystemModal from 'onboarding/pages/GlobalEcosystemModal';
import * as GlobalEcosystemActions from 'globalEcosystem/store/globalEcosystem.reducer';
import { getSummary, getCharts, getFilters } from 'globalEcosystem/store/globalEcosystem.selectors';
import { subYearsFromDate } from 'utils/DateUtils';
import { PACKAGES_IDS } from 'subscriptions/constants';
import AuthService from 'services/AuthService';
import { FilterButton, ClearButton, Tooltip, GlobalMap } from 'components';
import FilterForm from 'globalEcosystem/components/FilterForm';
import ChartSection from 'globalEcosystem/components/ChartSection';
import FlipNumber from 'globalEcosystem/components/FlipNumber';

import { Title } from 'common/components/styles';

import {
  Root, PageHeader, StyledIcon, MainInfo, CommonInfoBlock, MapWrapper,
  Item, IconBlock, TextBlock, FilterBlock, FiltersButtonsBlock, TooltipBlock,
} from './styles';

const GlobalEcosystem = () => {
  const dispatch = useDispatch();
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [claims, setClaims] = useState('0');
  const [recommendations, setRecommendations] = useState('0');
  const [claimsTotal, setClaimsTotal] = useState('0');
  const [projectsTotal, setProjectsTotal] = useState('0');
  const [filtersCount, setFiltersCount] = useState(0);
  const [isCleared, setIsCleared] = useState(false);
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const summaryData = useSelector(getSummary);
  const chartsData = useSelector(getCharts);
  const filtersData = useSelector(getFilters);

  const subscription = AuthService.getUserSubscription();
  const isPackageGlobal = subscription === PACKAGES_IDS.Global;

  const fetchGlobalEcosystemInfo = async () => {
    await Promise.all([
      dispatch(GlobalEcosystemActions.getSummary()),
      dispatch(GlobalEcosystemActions.getCharts()),
      dispatch(GlobalEcosystemActions.getFilters()),
    ]);
  };

  const handleCloseOnboardingModal = () => {
    setIsOnboardingModalOpen(false);
    AuthService.updateOnboardConfig('globalEcosystem');
  };

  useEffect(() => {
    fetchGlobalEcosystemInfo();
    const isVisited = AuthService.checkSectionVisit('globalEcosystem');
    setIsOnboardingModalOpen(!isVisited);
  }, []);

  useEffect(() => {
    setIsPlayed(false);
    setClaims('');
    setRecommendations('');
    setClaimsTotal('');
    setProjectsTotal('');

    const { totalClaimsCount, totalRisksCount, totalClaimsTotalAmount, totalProjectsCount } = summaryData;

    if (totalClaimsCount >= 0) {
      setClaims(`${totalClaimsCount || 0}`);
    }
    if (totalRisksCount >= 0) {
      setRecommendations(`${totalRisksCount || 0}`);
    }
    if (totalClaimsTotalAmount >= 0) {
      setClaimsTotal(`${totalClaimsTotalAmount || 0}`);
    }

    if (totalProjectsCount >= 0) {
      setProjectsTotal(`${totalProjectsCount || 0}`);
    }

    setTimeout(() => {
      setIsPlayed(true);
    }, 100);
  }, [summaryData]);

  const handleOpenFilterClick = () => {
    setIsFiltersOpened(!isFiltersOpened);
  };

  const handleSubmit = async (values) => {
    let fromPolicyStartDateTime;
    if (!values.fromPolicyStartDateTime) {
      fromPolicyStartDateTime = values.fromPolicyStartDateTime;
    } else {
      const dateTime = parseISO(values.fromPolicyStartDateTime);
      const yearValue = differenceInYears(new Date(), dateTime);
      fromPolicyStartDateTime = subYearsFromDate(new Date(), yearValue);
    }
    const newValues = pickBy({ ...values, fromPolicyStartDateTime }, (value) => value);
    await Promise.all([
      dispatch(GlobalEcosystemActions.getSummary(newValues)),
      dispatch(GlobalEcosystemActions.getCharts(newValues)),
    ]);
  };

  const handleFiltersChange = async (values) => {
    const countOfFilters = Object.values(values).reduce((acc, value) => (value ? acc + 1 : acc), 0);
    setFiltersCount(countOfFilters);
    const filterValues = countOfFilters > 0 ? values : undefined;
    await dispatch(GlobalEcosystemActions.getFilters(filterValues));
  };

  const handleClear = () => {
    setIsCleared(true);
    fetchGlobalEcosystemInfo();
  };

  if (!isPackageGlobal) {
    return <Navigate to="/" />;
  }

  return (
    <Root>
      <PageHeader>
        <Title>
          Global Ecosystem
        </Title>
        <FiltersButtonsBlock>
          {isFiltersOpened && filtersCount > 0 && (
            <ClearButton
              filtersCount={filtersCount}
              onClick={handleClear}
            />
          )}
          <FilterButton
            opened={isFiltersOpened}
            disabled={!filtersData}
            onClick={handleOpenFilterClick}
          />
        </FiltersButtonsBlock>
      </PageHeader>
      <FilterBlock opened={isFiltersOpened}>
        <FilterForm
          filters={filtersData}
          onSubmit={handleSubmit}
          onFiltersChange={handleFiltersChange}
          isCleared={isCleared}
          setIsCleared={setIsCleared}
        />
      </FilterBlock>
      <MainInfo>
        <CommonInfoBlock>
          <Item>
            <IconBlock color="#96CEF6">
              <StyledIcon
                icon="projects" noWrapper />
            </IconBlock>
            <TextBlock>
              <FlipNumber
                withNoData
                numbers={projectsTotal}
                isPlayed={isPlayed}
              />
              <div>Projects</div>
            </TextBlock>
          </Item>
          <Item>
            <IconBlock color="#969EF6">
              <StyledIcon icon="claims" noWrapper />
            </IconBlock>
            <TextBlock>
              <FlipNumber
                withNoData
                numbers={claims}
                isPlayed={isPlayed}
              />
              <div>Claims</div>
            </TextBlock>
          </Item>
          <Item>
            <IconBlock color="#96F6EE">
              <StyledIcon icon="recommendations" noWrapper />
            </IconBlock>
            <TextBlock>
              <FlipNumber numbers={recommendations} isPlayed={isPlayed} />
              <div>Recommendations</div>
            </TextBlock>
          </Item>
          <Item>
            <IconBlock color="#4205DE">
              <StyledIcon icon="expenses" iconColor="#FFFFFF" noWrapper />
            </IconBlock>
            <TextBlock>
              <FlipNumber
                withNoData
                numbers={claimsTotal}
                isPlayed={isPlayed}
                isCurrency
                noDecimal
              />
              <div>Claims Total</div>
            </TextBlock>
            <TooltipBlock>
              <Tooltip
                placement="right-start"
                text="In case project currency is different from the USD ($), this is converted based on the current exchange rate."
              />
            </TooltipBlock>
          </Item>
        </CommonInfoBlock>
        <ChartSection chartData={chartsData} />
      </MainInfo>

      <MapWrapper>
        <GlobalMap
          data={summaryData && summaryData.countries}
          mapType="globalEcosystem"
        />
      </MapWrapper>
      {isOnboardingModalOpen && (
        <GlobalEcosystemModal
          open={isOnboardingModalOpen}
          onCancel={handleCloseOnboardingModal}
        />
      )}
    </Root>
  );
};

export default GlobalEcosystem;
