import NotificationService from 'notifications/api/NotificationService';
import { NOTIFICATION_SUBJECTS, AUTOVIEWED_TYPES, ALERT_TYPES } from 'utils/constants';
import AuthService from 'services/AuthService';

const GET_ALL_INBOX_NOTIFICATIONS = 'GET_ALL_INBOX_NOTIFICATIONS';
const MARK_AS_VIEWED = 'MARK_AS_VIEWED';
const SET_NOTIFICATIONS_VIEW = 'SET_NOTIFICATIONS_VIEW';
const SET_OPENED_NOTIFICATION = 'SET_OPENED_NOTIFICATION';
const SET_PENDING_NOTIFICATION = 'SET_PENDING_NOTIFICATION';
const SET_ALERTS_VIEW = 'SET_ALERTS_VIEW';
const SET_OPENED_ALERT = 'SET_OPENED_ALERT';
const SET_GLOBAL_LIGHT_THEME = 'SET_GLOBAL_LIGHT_THEME';
const SET_GLOBAL_DARK_THEME = 'SET_GLOBAL_DARK_THEME';
const SET_NOTIFICATION_SLIDE_OUT = 'SET_NOTIFICATION_SLIDE_OUT';

export const getAllInboxNotificationsList = () => (dispatch) => dispatch({
  type: GET_ALL_INBOX_NOTIFICATIONS,
  apiCall: () => NotificationService.getInboxNotificationsList(),
});

export const markAsViewed = (notificationId) => async (dispatch) => {
  await dispatch({
    type: MARK_AS_VIEWED,
    apiCall: () => NotificationService.markAsViewed(notificationId),
  });
  await dispatch(getAllInboxNotificationsList());
};

// view = 'all' / 'new' / ''
export const setNotificationsView = (view) => ({
  type: SET_NOTIFICATIONS_VIEW,
  view,
});

export const setAlertsView = (view) => ({
  type: SET_ALERTS_VIEW,
  view,
});

export const setOpenedNotification = (notify) => (dispatch) => {
  dispatch({ type: SET_OPENED_NOTIFICATION, notify });
  if (!notify || notify.isViewed) return;
  if (AUTOVIEWED_TYPES.includes(notify.type)) {
    dispatch(markAsViewed(notify.id));
  }
};

export const setPendingNotification = (notify) => (dispatch) => {
  dispatch({ type: SET_PENDING_NOTIFICATION, notify });
};

export const setSlideOut = (slideOut) => (dispatch) => {
  dispatch({ type: SET_NOTIFICATION_SLIDE_OUT, slideOut });
};

export const setOpenedAlert = (notify) => (dispatch) => {
  dispatch({ type: SET_OPENED_ALERT, notify });
  if (!notify || notify.isViewed) return;
  dispatch(markAsViewed(notify.id));
};

export const setGlobalLightTheme = () => ({
  type: SET_GLOBAL_LIGHT_THEME,
  theme: 'light',
});

export const setGlobalDarkTheme = () => ({
  type: SET_GLOBAL_DARK_THEME,
  theme: 'dark',
});

const initialState = {
  allNotifications: {
    data: [],
  },
  notificationsView: '',
  openedNotification: null,
  pendingNotification: null,
  slideOut: false,
  // Global Theme
  theme: AuthService.getTheme(),
};

const mapNotifications = (response) => {
  if (!response.data) {
    return response;
  }

  const parsedData = response.data
    .filter((item) => !ALERT_TYPES.includes(item.type))
    .map((item) => ({
      ...item,
      creationDateTime: (new Date(item.creationDateTime)).toISOString(),
      subject: NOTIFICATION_SUBJECTS[item.type],
    }));

  return {
    ...response,
    data: parsedData,
  };
};

const mapAlerts = (response) => {
  if (!response.data) {
    return response;
  }
  const parsedData = response.data
    .filter((item) => ALERT_TYPES.includes(item.type))
    .map((item) => ({
      ...item,
      creationDateTime: new Date(item.creationDateTime),
      subject: NOTIFICATION_SUBJECTS[item.type],
    }));

  return {
    ...response,
    data: parsedData,
  };
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_ALL_INBOX_NOTIFICATIONS: {
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          ...mapNotifications(response),
        },
        allAlerts: {
          ...state.allAlerts,
          ...mapAlerts(response),
        },
      };
    }

    case SET_NOTIFICATIONS_VIEW: {
      return {
        ...state,
        notificationsView: action.view,
      };
    }

    case SET_ALERTS_VIEW: {
      return {
        ...state,
        alertsView: action.view,
      };
    }

    case SET_OPENED_NOTIFICATION: {
      return {
        ...state,
        openedNotification: action.notify,
      };
    }

    case SET_PENDING_NOTIFICATION: {
      return {
        ...state,
        pendingNotification: action.notify,
      };
    }

    case SET_OPENED_ALERT: {
      return {
        ...state,
        openedAlert: action.notify,
      };
    }

    case SET_GLOBAL_DARK_THEME:
    case SET_GLOBAL_LIGHT_THEME: {
      return {
        ...state,
        theme: action.theme,
      };
    }

    case SET_NOTIFICATION_SLIDE_OUT: {
      return {
        ...state,
        slideOut: action.slideOut,
      };
    }

    default:
      return state;
  }
};
