import React from 'react';
import { useTheme } from '@mui/material/styles';

import AuthService from 'services/AuthService';

const Logo = () => {
  const theme = AuthService.getTheme();
  const muiTheme = useTheme();
  const isLightLogo = muiTheme.light || theme === 'light';

  return (
    <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg">
      {isLightLogo
        ? <use xlinkHref="#light-logo" />
        : <use xlinkHref="#dark-logo" />
      }
    </svg>
  );

  // Hack for Safari - to fix svg gradient glitch
  // Original SVG moved to /index-template.html inside body tag
  // and here we just return xlink to it
  //
  // return (
  //   <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path fill="url('#paint0_radial')" fillRule="evenodd" clipRule="evenodd" d="M25.865 17.5c7.267-2.221 11.914 2.015 12.673 6.88 1.166 7.43-6.639 12.796-12.91 7.953-4.532-3.51-6.46-7.629-11.087-11.145-4.389-3.337-9.132.372-8.368 5.409.477 3.118 3.52 6.397 9.966 7.191-7.53 1.831-11.91-2.016-12.669-6.88-1.17-7.437 6.314-13.056 12.585-8.204 4.531 3.51 6.782 7.872 11.409 11.395 4.389 3.337 9.134-.371 8.368-5.407-.476-3.12-3.605-6.228-9.967-7.193z" />
  //     <path fill={logoWhiteColor} fillOpacity=".87" fillRule="evenodd" clipRule="evenodd" d="M14.633 24.122l-.8.852c2.963 3.1 6.777 7.225 10.638 9.135-2.718.794-5.912-.138-7.951-2.047-1.551-1.454-3.288-3.447-4.658-5.065l-.826.826-.434-4.132 4.031.43zM23.611 16.602c-1.939-2.09-5.075-3.815-8.478-3.815 4.004-4.501 10.955-2.28 13.17 2.05h3.135c-6.888-12.341-18.774-6.617-20.945.448 5.716-1.296 8.606.707 11.913 4.217l5.868 6.142-.796.848 4.031.429-.443-4.113-.816.815-6.639-7.021z" />
  //     <defs>
  //       <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.918 17.766) scale(38.8111)">
  //         <stop offset="0" stopColor="#0092D0" />
  //         <stop offset="1" stopColor="#005480" />
  //       </radialGradient>
  //     </defs>
  //   </svg>
  // );
};

export default Logo;
