import React from 'react';

const PaperClip = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.05 9.45l-7.5-7.5C5.3.65 3.25.65 1.95 1.9a3.2 3.2 0 000 4.6l.05.05L3.4 8l.7-.7-1.45-1.45c-.85-.85-.85-2.3 0-3.15.85-.85 2.3-.9 3.15-.05l.05.05 7.45 7.45c.9.85.9 2.3.05 3.15-.85.9-2.3.9-3.15.05l-.05-.05-3.7-3.7c-.5-.5-.45-1.3 0-1.75.5-.45 1.25-.45 1.75 0l2.05 2.05.7-.7-2.1-2.1c-.9-.85-2.3-.8-3.15.1-.8.85-.8 2.2 0 3.1l3.75 3.75c1.25 1.3 3.3 1.3 4.6.05 1.3-1.25 1.3-3.35 0-4.65 0 .05 0 0 0 0z" fill="#96CEF6" />
    </svg>
  );
};

export default PaperClip;
