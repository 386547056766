import React from 'react';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { IconButton } from '@mui/material';

const getFillColor = ({ color, disabled, theme }) => {
  if (color === 'primary') {
    const value = disabled ? theme.palette.icon.primary.disabled : theme.palette.sidebar.selected.icon.background;
    return value;
  }
  const nonPrimaryValue = disabled ? theme.palette.navbar.tab.disabled : theme.palette.icon.primary.color;
  return nonPrimaryValue;
};

const getHoverFillColor = ({ color, disabled }) => {
  if (!disabled) return '#BEE0F9';
  return color === 'primary' ? 'rgba(150, 206, 246, 0.6)' : 'rgba(255, 255, 255, 0.38)';
};

export const StyledButton = styledMui(IconButton)(({ theme, $noWrapper, transparent, originalColors, color, disabled }) => {
  const styled = {
    padding: 0,
    background: transparent ? 'transparent' : '#2A2B33',
  };
  if (!$noWrapper) {
    styled.minWidth = 30;
    styled.minHeight = 30;
  }
  if (!originalColors) {
    styled['svg path'] = {
      fillOpacity: 1,
      fill: getFillColor({ theme, disabled, color }),
    };
  }
  styled['&:hover'] = {
    '& svg path': {
      fill: getHoverFillColor({ theme, disabled, color }),
    },
  };
  return styled;
});

// export const StyledButton1 = styled(({ color, originalColors, transparent, noWrapper, ...rest }) => <IconButton {...rest} />)`
//   ${({ noWrapper }) => !noWrapper && `
//     min-width: 30px;
//     min-height: 30px;
//   `};

//   padding: 0px;
//   background: ${(p) => (p.transparent ? 'transparent' : '#2A2B33')};

//   ${(p) => !p.originalColors && `
//     svg path {
//       fill-opacity: 1;
//       fill: ${getFillColor(p)};
//     }
//   `}

//   :hover {
//     svg path {
//       fill: ${(p) => getHoverFillColor(p)};
//     }
//   }
// `;

export const Wrapper = styled.span`
  display: flex;
  transform: ${({ $flipHorizontal, rotateLeft, rotateRight }) => {
    if ($flipHorizontal) {
      return 'rotate(180deg)';
    }
    if (rotateLeft) {
      return 'rotate(270deg)';
    }
    if (rotateRight) {
      return 'rotate(90deg)';
    }

    return 'unset';
  }};
`;

export const TooltipBlock = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;
