import React from 'react';

const Rejected = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fill="#FA928D" fillRule="evenodd" d="M8 0C3.644 0 0 3.644 0 8s3.644 8 8 8 8-3.556 8-8-3.556-8-8-8zm3.111 12L8 8.889 4.889 12 4 11.111 7.111 8 4 4.889 4.889 4 8 7.111 11.111 4l.889.889L8.889 8 12 11.111l-.889.889z" clipRule="evenodd" />
      <path fill="#FA928D" fillRule="evenodd" d="M11.111 12L8 8.889 4.889 12 4 11.111 7.111 8 4 4.889 4.889 4 8 7.111 11.111 4l.889.889L8.889 8 12 11.111l-.889.889z" clipRule="evenodd" opacity=".01" />
    </svg>
  );
};

export default Rejected;
