import React, { useState, useReducer } from "react";
import { useDispatch } from "react-redux";

import { ActionButton, Tooltip, PulsatingTooltip } from "components";
import * as RatesActions from "myCompany/store/rates.reducer";
import AuthService, { ROLES_IDS } from "services/AuthService";
import { Title } from "common/components/styles";

import TimeRatesTable from "./TimeRatesTable";
import ExpenseRatesTable from "./ExpenseRatesTable";
import RateDialogForm from "./RateDialogForm";

import { tooltipTexts } from "./constants";
import {
  RatesRoot,
  HeaderPanel,
  SubTitle,
  Controls,
  Counter,
  ButtonWrapper,
} from "./styles";

const reduceFunc = (prevState, newState) => ({
  ...prevState,
  ...newState,
});

const Rates = () => {
  const dispatch = useDispatch();
  const [{ timeCounter, expenseCounter }, setCounter] = useReducer(reduceFunc, {
    timeCounter: 0,
    expenseCounter: 0,
  });
  const [selectedRate, setSelectedRate] = useState(null);

  const { role, companyType } = AuthService.getUser();

  const isICC = role === ROLES_IDS.ICCoordinator;
  const isRCC = role === ROLES_IDS.RCCoordinator;

  const tooltipText = isICC ? tooltipTexts.ICC : tooltipTexts.other;

  const handleRowClick = (rate) => {
    setSelectedRate(rate);
  };

  const handleModalClose = () => {
    setSelectedRate(null);
  };

  const fetchRates = async () => {
    await dispatch(RatesActions.getRates());
  };

  return (
    <RatesRoot>
      <HeaderPanel>
        <Title>Time Rates</Title>
        <Controls>
          {(isICC || isRCC) && <Tooltip text={tooltipText} />}
          <ButtonWrapper>
            <PulsatingTooltip
              place="RatesButton"
              tooltip="Click “Add Rate” to set up your company rates."
            />
            <ActionButton
              text="Add Rate"
              color="primary"
              variant="contained"
              small
              dialogTitle="Add Rate"
              dialogForm={
                <RateDialogForm
                  onSuccess={fetchRates}
                  onClose={handleModalClose}
                  companyType={companyType}
                />
              }
              onSubmit={handleModalClose}
              confirmButtonTitle="Save"
            />
          </ButtonWrapper>
        </Controls>
      </HeaderPanel>

      <TimeRatesTable setCounter={setCounter} onRowClick={handleRowClick} />
      {timeCounter > 0 && (
        <Counter>{`1-${timeCounter} of ${timeCounter} items`}</Counter>
      )}

      <SubTitle>Expense Rates</SubTitle>
      <ExpenseRatesTable setCounter={setCounter} onRowClick={handleRowClick} />

      {expenseCounter > 0 && (
        <Counter>{`1-${expenseCounter} of ${expenseCounter} items`}</Counter>
      )}

      {selectedRate && (
        <RateDialogForm
          isOpen
          mode="view"
          rateId={selectedRate.id}
          onCancel={handleModalClose}
          time={selectedRate.time}
          expense={selectedRate.expense}
          onSuccess={fetchRates}
        />
      )}
    </RatesRoot>
  );
};

export default Rates;
