import UserService from 'projects/api/UserService';

const GET_USER_BY_INVITE = 'GET_USER_BY_INVITE';
const GET_CURRENT_USER = 'GET_CURRENT_USER';

export const getUserByInvite = (inviteToken) => ({
  type: GET_USER_BY_INVITE,
  apiCall: () => UserService.getUserByInvite(inviteToken),
});

export const getCurrentUserInfo = () => ({
  type: GET_CURRENT_USER,
  apiCall: () => UserService.getCurrentUserInfo(),
});

const initialState = {
  userByInvite: {},
  userInfo: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_USER_BY_INVITE: {
      return {
        ...state,
        userByInvite: {
          ...state.userByInvite,
          ...response,
        },
      };
    }
    case GET_CURRENT_USER: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...response,
        },
      };
    }
    default:
      return state;
  }
};
