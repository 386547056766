import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SubjectMenu from "help/components/SubjectMenu";
import { MY_COMPANY_SUBJECTS } from "help/constants";

import Subscription from "./Subscription";
import TeamMembers from "./TeamMembers";
import CompanyInfo from "./CompanyInfo";
import Overview from "./Overview";
import Rates from "./Rates";

import { Root } from "../styles";

const MyCompany = () => (
  <>
    <SubjectMenu section="my-company" subjects={MY_COMPANY_SUBJECTS} />
    <Root>
      <Routes>
        <Route path="/help/my-company/overview" exact element={<Overview/>} />
        <Route
          path="/help/my-company/company-info"
          exact
          element={<CompanyInfo/>}
        />
        <Route
          path="/help/my-company/team-members"
          exact
          element={<TeamMembers/>}
        />
        <Route path="/help/my-company/rates" exact element={<Rates/>} />
        <Route
          path="/help/my-company/subscription"
          exact
          element={<Subscription/>}
        />
        <Route
          path="/help/my-company"
          element={<Navigate to="/help/my-company/overview" replace />}
        />
      </Routes>
    </Root>
  </>
);

export default MyCompany;
