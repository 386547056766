import React from 'react';

import SearchInput from '../SearchInput';

import { StyledOption, StyledAutocomplete } from './styles';

const AutocompleteInput = ({ placeholder, value, inputValue, onChange, onInputChange,
  options, onClearSearch, disabled, noIcon, regularColor, textarea, useEventTarget, ...rest }) => {
  const handleChange = (e, newValue) => {
    onChange(newValue);
  };

  const handleInputChange = (e, newValue) => {
    if (useEventTarget && newValue === '') {
      if (e && e.target) {
        onInputChange(e?.target?.value);
      }
    } else {
      onInputChange(newValue);
    }
  };

  const getOptionLabel = (option) => {
    if (option?.text == null) return '';
    return (typeof option === 'string' ? option : option.text);
  };

  const filterOptions = (opts) => opts; // do not filter options by inputValue

  const renderInput = (params) => {
    const { inputProps } = params;
    return (
      <SearchInput
        {...rest}
        {...params}
        {...inputProps}
        placeholder={placeholder}
        fullWidth
        onClearSearch={onClearSearch}
        noIcon={noIcon}
        regularColor={regularColor}
        multiline={textarea}
      />
    );
  };

  const renderOption = (option) =>{
    return (
    <StyledOption {...option} key={option.id} value={option.id} >
      {option.key}
    </StyledOption>
  );
  }

  return (
    <StyledAutocomplete
      $noIcon={noIcon}
      disabled={disabled}
      fullWidth
      freeSolo
      disableClearable
      getOptionLabel={getOptionLabel}
      options={options}
      inputValue={inputValue}
      value={value}
      onChange={handleChange}
      onInputChange={handleInputChange}
      filterOptions={filterOptions}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  );
};

export default AutocompleteInput;
