import React, { useState } from 'react';

import Icon from 'components/Icon';

import { StyledFormControl, StyledInput, StyledFormHelperText, StartIcon, StyledIconButton } from './styles';

const SearchInput = ({
  fullWidth,
  value,
  onChange,
  required,
  disabled,
  error,
  placeholder,
  onClearSearch,
  borderless,
  InputProps = {},
  InputLabelProps,
  noIcon,
  regularColor,
  textarea,
  ...rest
}) => {
  // inner input is needed for smooth typing with debouncing
  const [input, setInput] = useState(value);

  const handleChange = (event) => {
    onChange(event);
    setInput(event.target.value);
  };

  const handleClearSearch = () => {
    onClearSearch();
    setInput('');
  };

  const EndIcon = value ? <StyledIconButton icon="crossSmall" onClick={handleClearSearch} /> : undefined;

  return (
    <StyledFormControl
      variant="filled"
      fullWidth={fullWidth}
      error={!!error}
      disabled={disabled}
      borderless={borderless}
    >
      <StyledInput
        value={input}
        regularColor={regularColor}
        disableUnderline
        error={!!error}
        placeholder={placeholder}
        {...rest}
        ref={InputProps.ref}
        endAdornment={EndIcon}
        noIcon={noIcon}
        onChange={handleChange}
      />

      {!noIcon && (
      <StartIcon>
        <Icon icon="search" noWrapper />
      </StartIcon>
      )}

      <StyledFormHelperText>
        {error || ' '}
      </StyledFormHelperText>
    </StyledFormControl>
  );
};

export default SearchInput;
