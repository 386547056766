import React from 'react';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import InfoCard from 'common/components/InfoCard';

import { Text, CardsContainer } from './styles';

const SUPPORT_INFO = {
  email: 'support@imitig8risk.com',
  fullName: 'Support',
};

const SALES_INFO = {
  email: 'sales@imitig8risk.com',
  fullName: 'Sales',
};

const ContactUsModal = ({ onClose }) => (
  <ConfirmationDialog
    open
    onClose={onClose}
    onConfirm={onClose}
    onCancel={onClose}
    title="Contact Us"
    confirmButtonTitle="Close"
    noCancel
  >
    <Text>For any information please contact us:</Text>
    <CardsContainer>
      <InfoCard adminInfo={SUPPORT_INFO} />
      <InfoCard adminInfo={SALES_INFO} />
    </CardsContainer>
  </ConfirmationDialog>
);

export default ContactUsModal;
