import React, { useState } from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';

import SalesDepartmentModal from 'help/components/SalesDepartmentModal';

import { Title, TextSection, TextButton, List } from '../../styles';

const imageSrc = 'my-company-subscription.png';

const Subscription = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <ImageContainer src={imageSrc} alt="subscription" />
      <Title>Overview</Title>
      <TextSection>
        The section provides you the most important information about your subscription such as:
      </TextSection>
      <List>
        <li>Status</li>
        <li>Package</li>
        <li>Start & End Date</li>
        <li>Sales Manager Contact Details</li>
      </List>
      <TextSection>
        For more details about the subscription, please contact our
        <TextButton onClick={handleModalOpen}>
          sales departments
        </TextButton>
        .
      </TextSection>
      <TextSection>
        <i>
          Note that the section is only visible if you have subscribed to the product.
          If you use the free version, the section will not be available for you.
        </i>
      </TextSection>
      {modalOpen && (
        <SalesDepartmentModal onClose={handleModalClose} />
      )}
    </>
  );
};

export default Subscription;
