import qs from 'qs';
import ApiClient from 'services/ApiClient';

class BudgetService extends ApiClient {
  getBudgetsList(projectId) {
    return this.call({
      method: 'get',
      url: `/budgets?${qs.stringify({ projectId })}`,
    });
  }

  addBudget(budgetData) {
    return this.call({
      method: 'post',
      url: '/budgets',
      data: {
        ...budgetData,
      },
    });
  }

  updateBudget(budgetData) {
    return this.call({
      method: 'put',
      url: '/budgets',
      data: {
        ...budgetData,
      },
    });
  }

  deleteBudget(budgetId) {
    return this.call({
      method: 'delete',
      url: `/budgets/${budgetId}`,
    });
  }

  getAllowances() {
    return this.call({
      method: 'get',
      url: '/budget-allowances',
    });
  }

  addAllowance(allowanceData) {
    return this.call({
      method: 'post',
      url: '/budget-allowances',
      data: {
        ...allowanceData,
      },
    });
  }

  getBudgetServiceTypes({ projectId }) {
    return this.call({
      method: 'get',
      url: `/budgets/service-types?${qs.stringify({ projectId })}`,
    });
  }
}

export default new BudgetService();
