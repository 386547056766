import { ModulePermissions } from "types";

export const communications: ModulePermissions = {
  communications: {
    Admin: {},
  },
  Help: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    RCCoordinator: {},
    RiskEngineer: {},
    SiteContact: {},
  },
};
