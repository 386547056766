import React from 'react';
import { PDFObject } from 'react-pdfobject';

import Spinner from 'components/Spinner';
import CloseButton from 'components/buttons/CloseButton';
import { isSafari } from 'utils/DocumentsUtils';

import { StyledDialog, PdfWrapper } from './styles';

const containerProps = { style: { width: '100%' } };
const pdfOpenParams = { view: 'FitV' };

const PdfViewer = ({ opened, onClose, pdfUrl, noSpinner }) => {
  const renderPdfPreview = () => {
    if (isSafari) {
      return (
        <div>
          Cannot preview PDF in Safari (due to bug in it).
        </div>
      );
    }

    return (
      <PdfWrapper>
        <PDFObject
          url={pdfUrl}
          forceIframe
          pdfOpenParams={pdfOpenParams}
          containerProps={containerProps}
        />
      </PdfWrapper>
    );
  };

  return (
    <StyledDialog
      fullScreen
      open={opened}
    >
      <CloseButton onClick={onClose} top="10px" />
      {noSpinner || pdfUrl ? renderPdfPreview() : <Spinner />}
    </StyledDialog>
  );
};

export default PdfViewer;
