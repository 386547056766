import React from 'react';

import Icon from 'components/Icon';

import { StyledStatus } from './styles';

const Status = ({ status }) => {
  const isAccepted = status.toLowerCase() === 'approved' || status.toLowerCase() === 'accepted';
  const capitalizedStatus = status[0].toUpperCase() + status.slice(1);

  return (
    <StyledStatus status={isAccepted ? 'accepted' : 'rejected'}>
      <Icon icon={isAccepted ? 'accepted' : 'rejected'} />
      {capitalizedStatus}
    </StyledStatus>
  );
};

export default Status;
