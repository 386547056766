import React from 'react';

const Email = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="32" fill="none">
      <path fill="#fff" fillOpacity=".4" d="M40.75.375H3.25A3.125 3.125 0 00.125 3.5v25a3.125 3.125 0 003.125 3.125h37.5a3.125 3.125 0 003.125-3.125v-25A3.125 3.125 0 0040.75.375zM37.312 3.5L22 14.094 6.687 3.5h30.625zM3.25 28.5V4.922l17.86 12.36a1.562 1.562 0 001.78 0l17.86-12.36V28.5H3.25z" />
    </svg>
  );
};

export default Email;
