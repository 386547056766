import React from 'react';
import { get } from 'lodash';

function FormikFastField({ wrappedComponent, children, form, field, onChange, ...rest }) {
  const { name, value } = field;
  const error = form.errors[name];
  const isTouched = get(form.touched, name);

  function handleChange(event) {
    const { onChange: fieldOnChange } = field;

    fieldOnChange(event);

    if (onChange) {
      onChange(event.target.value);
    }
  }

  function handleBlur() {
    form.setFieldTouched(field.name, true);
  }

  function handleSetValue(newValue) {
    form.setFieldValue(field.name, newValue);
  }

  const props = {
    ...field,
    setValue: handleSetValue,
    ...rest,
    error: isTouched ? error : undefined,
    value,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    React.createElement(
      wrappedComponent,
      props,
      children,
    )
  );
}

export default React.memo(FormikFastField);
