import styled from 'styled-components';
import { SearchInput, Button } from 'components';

export const HeaderPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledSearchInput = styled(SearchInput)`
  margin-top: 13px;
  margin-right: 4px;
`;

export const Title = styled.div`
  margin: 0;
  font-family: Avenir;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: var(--titleColor);
  white-space: nowrap;
  text-transform: none;
`;

export const NavContainer = styled.div`
  display: flex;

  .MuiInputBase-input {
    display: block;
  }
`;

export const Controls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60%;

  > :last-child {
    margin-left: 24px;
  }
`;

export const StyledButton = styled(Button)`
  && {
    height: 40px;
    max-width: 121px;
    margin-left: 24px;
    color: var(--buttonText);
    background: var(--buttonColor);
    border-radius: 4px;
    font-size: 14px;
  }
`;
