import styled from 'styled-components';

import { IconButton, Icon } from 'components';

export const Wrapper = styled.div`
display: flex;
flex-direction: column;

> * {
  margin-bottom: 24px;
}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 38px;

  > div:not(:last-of-type) {
    max-width: 50%;
    margin-right: 24px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 8px;
  align-self: center;
  svg > path {
    fill: var(--sidebarSelectedIconBackground);
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 9px;
  path {
    fill: var(--iconPrimaryColor);
  }

  &.user {
    margin-bottom: 3px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
`;
