import React from 'react';

const Budget = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9348 13.8586H19.5652V15.4891H17.9348V13.8586Z" fill="white" fillOpacity="0.87" />
      <path d="M22.8261 6.52177H3.26087V4.07611H21.1957V2.44568H3.26087C2.82845 2.44568 2.41374 2.61746 2.10798 2.92322C1.80221 3.22899 1.63043 3.6437 1.63043 4.07611V21.1957C1.63043 21.6281 1.80221 22.0428 2.10798 22.3486C2.41374 22.6543 2.82845 22.8261 3.26087 22.8261H22.8261C23.2585 22.8261 23.6732 22.6543 23.979 22.3486C24.2847 22.0428 24.4565 21.6281 24.4565 21.1957V8.1522C24.4565 7.71978 24.2847 7.30508 23.979 6.99931C23.6732 6.69354 23.2585 6.52177 22.8261 6.52177ZM3.26087 21.1957V8.1522H22.8261V10.5979H16.3043C15.8719 10.5979 15.4572 10.7696 15.1515 11.0754C14.8457 11.3812 14.6739 11.7959 14.6739 12.2283V17.1196C14.6739 17.552 14.8457 17.9667 15.1515 18.2725C15.4572 18.5782 15.8719 18.75 16.3043 18.75H22.8261V21.1957H3.26087ZM22.8261 12.2283V17.1196H16.3043V12.2283H22.8261Z" fill="white" fillOpacity="0.87" />
    </svg>
  );
};

export default Budget;
