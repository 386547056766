import React from "react";
import { toast, Slide, ToastOptions, TypeOptions } from "react-toastify";
import isObject from "lodash/isObject";
import ToastMsg from "components/ToastMsg";

const options: ToastOptions = {
  autoClose: 3000,
  draggable: false,
  pauseOnHover: true,
  hideProgressBar: true,
  position: "top-right",
  transition: Slide,
  icon: false,
  progress: undefined,
};

const parseErrorObject = (object: any) => {
  if (object.message) {
    return object.message;
  }

  return JSON.stringify(object);
};

const showToastNotify = (message: string, type: TypeOptions, onClick?: () => any) => {
  const msgString = isObject(message) ? parseErrorObject(message) : message;
  const toastOptions = { ...options, type };

  const toastMsg = (
    <ToastMsg type={type} message={msgString} onClick={onClick} />
  );

  toast(toastMsg, toastOptions);
};

export const showNotifySuccess = (message: string, onClick?: () => any) => {
  showToastNotify(message, "success", onClick);
};

export const showNotifyAlert = (message: string, onClick?: () => any) => {
  showToastNotify(message, "warning", onClick);
};

export const showNotifyError = (message: string, onClick?: () => any) => {
  showToastNotify(message, "error", onClick);
};

export const showNotifyInfo = (message: string, onClick?: () => any) => {
  showToastNotify(message, "info", onClick);
};
