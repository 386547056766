import qs from 'qs';
import ApiClient from 'services/ApiClient';
import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class ProjectService extends ApiClient {
  getProjectData(id) {
    return this.call({
      method: 'get',
      url: `/projects/${id}`,
    });
  }

  generateSeedReportNotes(id) {
    return this.call({
      method: 'put',
      url: '/seed/generate-report-notes',
      data: { id },
    });
  }

  // Example: /projects?Page=1&PageSize=10&Filter=qwer&SortFieldName=name&SortDirection=Descending
  getProjectsList({ page = 1, pageSize = PAGE_SIZE, sortBy, searchFilter }) {
    const query = {
      Page: page,
      PageSize: pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    if (searchFilter) {
      query.Name = searchFilter;
    }

    return this.call({
      method: 'get',
      url: `/projects?${qs.stringify(query)}`,
    });
  }

  getProjectForSC(id) {
    return this.call({
      method: 'get',
      url: `/projects/${id}/site-contact`,
    });
  }

  getProjectsCountLimits() {
    return this.call({
      method: 'get',
      url: '/projects/count-limits',
    });
  }

  addNewProject(projectData) {
    return this.call({
      method: 'post',
      url: '/projects',
      data: {
        ...projectData,
      },
    });
  }

  updateProject(projectData) {
    return this.call({
      method: 'put',
      url: '/projects',
      data: {
        ...projectData,
      },
    });
  }

  updateProjectDetails(id, description) {
    return this.call({
      method: 'put',
      url: `/projects/${id}/description`,
      data: {
        description,
      },
    });
  }

  // Deprecated, should be removed later
  // updatePolicyDate(id, policyStartDate) {
  //   return this.call({
  //     method: 'put',
  //     url: `/projects/${id}/policy-start-date`,
  //     data: {
  //       policyStartDate,
  //     },
  //   });
  // }

  deleteProject(id) {
    return this.call({
      method: 'delete',
      url: `/projects/${id}`,
      data: {
        ...id,
      },
    });
  }

  getParentCompanies() {
    return this.call({
      method: 'get',
      url: '/parent-companies',
    });
  }

  getProjectBusinessTypes() {
    return this.call({
      method: 'get',
      url: '/project-business-types',
    });
  }

  getProjectTypes() {
    return this.call({
      method: 'get',
      url: '/project-types',
    });
  }

  addParentCompany(name) {
    return this.call({
      method: 'post',
      url: '/parent-companies',
      data: {
        name,
      },
    });
  }

  addProjectType(value) {
    return this.call({
      method: 'post',
      url: '/project-types',
      data: {
        value,
      },
    });
  }

  addProjectBusinessType(projectTypeId, value) {
    return this.call({
      method: 'post',
      url: '/project-business-types',
      data: {
        projectTypeId,
        value,
      },
    });
  }

  editParentCompany(id, name) {
    return this.call({
      method: 'put',
      url: '/parent-companies',
      data: {
        id,
        name,
      },
    });
  }

  editProjectType(id, value) {
    return this.call({
      method: 'put',
      url: '/project-types',
      data: {
        id,
        value,
      },
    });
  }

  editProjectBusinessType(id, value) {
    return this.call({
      method: 'put',
      url: '/project-business-types',
      data: {
        id,
        value,
      },
    });
  }

  getProjectTemplate() {
    return this.call({
      method: 'get',
      url: '/projects/import/template',
    });
  }

  importProjects(file, onUploadProgress) {
    const formDataFile = new FormData();

    formDataFile.append('template', file);

    return this.call({
      method: 'POST',
      url: '/projects/import',
      data: formDataFile,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }

  setPrimaryUnderwriter(id, email) {
    return this.call({
      method: 'put',
      url: `/projects/${id}/primary-underwriter`,
      data: { email },
    });
  }

  getPrimaryUnderwriter(id) {
    return this.call({
      method: 'get',
      url: `/projects/${id}/primary-underwriter`,
    });
  }

  getPrimaryRiskEngineer(id) {
    return this.call({
      method: 'get',
      url: `/projects/${id}/primary-risk-engineer`,
    });
  }

  setPrimarySiteContact(id, email) {
    return this.call({
      method: 'put',
      url: `/projects/${id}/primary-site-contact`,
      data: { email },
    });
  }

  getIsReadyForReallocation(id) {
    return this.call({
      method: 'get',
      url: `/projects/${id}/is-ready-for-reallocation`,
    });
  }
}

export default new ProjectService();
