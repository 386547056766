import React from "react";
import dayjs from "dayjs";
import { FormControl, FormHelperText } from "@mui/material";

import { StyledDateTimePicker, StyledIcon } from "./styles";
import {
  renderDateViewCalendar,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

const inputProps = {
  disableUnderline: true,
  endAdornment: <StyledIcon icon="calendar" />,
};

const DateTimePicker = ({
  required,
  disabled,
  label,
  error,
  value = null,
  minDate,
  maxDate,
  setValue,
  readOnly,
  type,
}) => {
  const isValueNull = value == null || value === "";
  const dateValue = isValueNull ? null : dayjs(value).toDate();
  const minDateValue = typeof minDate === "string" ? dayjs(minDate) : minDate;
  const maxDateValue = typeof maxDate === "string" ? dayjs(maxDate) : maxDate;

  const handleDateChange = (newDate) => {
    if (setValue) {
      setValue(newDate, false);
    }
  };

  const onOpen = () => {
    if (isValueNull) {
      setValue(dayjs());
    }
  };

  return (
    <FormControl fullWidth>
      <StyledDateTimePicker
        slotProps={{
          toolbar: {
            sx: {
              ".MuiPickersToolbar-content > div > div > button": {
                height: "auto",
              },
            },
          },
          field: {
            InputProps: inputProps,
          },
        }}
        clearable
        defaultValue={isValueNull ? undefined : dayjs(value).toDate()}
        variant="dialog"
        // inputVariant="filled"
        fullWidth
        format="MM/dd/yyyy hh:mm aa"
        openTo={type === "time" ? "hours" : "month"}
        views={
          type === "time"
            ? ["hours", "minutes"]
            : ["year", "month", "day", "hours", "minutes"]
        }
        onOpen={onOpen}
        label={label}
        hideTabs={type === "time"}
        disabled={disabled && !readOnly}
        value={dateValue}
        required={required}
        readOnly={readOnly}
        InputProps={inputProps}
        error={!!error}
        minDate={minDateValue}
        maxDate={maxDateValue}
        onChange={handleDateChange}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
          day: renderDateViewCalendar,
          month: renderDateViewCalendar,
          year: renderDateViewCalendar,
        }}
      />
      <FormHelperText variant="filled">{error || " "}</FormHelperText>
    </FormControl>
  );
};

export default DateTimePicker;
