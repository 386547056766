import qs from 'qs';
import ApiClient from 'services/ApiClient';
import { pickBy } from 'lodash';

import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class ContactService extends ApiClient {
  getContactsList({ projectId, page = 1, pageSize = PAGE_SIZE, sortBy, searchFilter }) {
    const query = {
      ProjectId: projectId,
      Page: page,
      PageSize: pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      switch (sortBy.field) {
        case 'companyName':
          query.SortFieldName = 'ContactInfo.CompanyName';
          break;
        case 'fullName':
          query.SortFieldName = 'ContactInfo.Name.FullName';
          break;
        default:
          query.SortFieldName = formatSortField(sortBy.field);
      }
      query.SortDirection = sortBy.direction;
    }

    if (searchFilter) {
      query.Name = searchFilter;
    }

    return this.call({
      method: 'get',
      url: `/contacts?${qs.stringify(query)}`,
    });
  }

  getGlobalContactsList({ name, position, page = 1, pageSize = PAGE_SIZE, sortBy }) {
    const query = pickBy({
      name,
      position,
      page,
      pageSize,
    });

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      switch (sortBy.field) {
        case 'companyName':
          query.SortFieldName = 'ContactInfo.CompanyName';
          break;
        case 'fullName':
          query.SortFieldName = 'ContactInfo.Name.FullName';
          break;
        default:
          query.SortFieldName = formatSortField(sortBy.field);
      }
      query.SortDirection = sortBy.direction;
    }

    return this.call({
      method: 'get',
      url: `/contacts/global?${qs.stringify(query)}`,
    });
  }

  getGlobalContactsForPosition({ position, projectId }) {
    const query = {
      Position: position,
      ProjectIdToExclude: projectId,
    };

    return this.call({
      method: 'get',
      url: `/contacts/global/by-position?${qs.stringify(query)}`,
    });
  }

  getGlobalInternalRiskEngineers() {
    return this.call({
      method: 'get',
      url: '/contacts/global/risk-engineers/internal',
    });
  }

  getGlobalInternalRiskEngineersForProject(id) {
    return this.call({
      method: 'get',
      url: `/contacts/global/risk-engineers/internal/${id}`,
    });
  }

  getGlobalExternalRiskEngineers(id) {
    return this.call({
      method: 'get',
      url: `/contacts/global/risk-engineers/external/${id}`,
    });
  }

  getContactData(id) {
    return this.call({
      method: 'get',
      url: `/contacts/${id}`,
    });
  }

  getDropdownContactData(projectId) {
    return this.call({
      method: 'get',
      url: `/contacts/notify-dropdown?${qs.stringify({ projectId })}`,
    });
  }

  getGlobalContactData(id) {
    return this.call({
      method: 'get',
      url: `/contacts/global/${id}`,
    });
  }

  addContact(contactData) {
    return this.call({
      method: 'post',
      url: '/contacts',
      data: {
        ...contactData,
      },
    });
  }

  addContactFromUser(contactData) {
    return this.call({
      method: 'post',
      url: '/contacts/from-user',
      data: {
        ...contactData,
      },
    });
  }

  addGlobalContact(contactData) {
    return this.call({
      method: 'post',
      url: '/contacts/global',
      data: {
        ...contactData,
      },
    });
  }

  getCountriesData() {
    return this.call({
      method: 'get',
      url: '/address/countries',
    });
  }

  updateContact(contactData) {
    return this.call({
      method: 'put',
      url: '/contacts',
      data: {
        ...contactData,
      },
    });
  }

  updateContactFromUser(contactData) {
    return this.call({
      method: 'put',
      url: '/contacts/from-user',
      data: {
        ...contactData,
      },
    });
  }

  updateGlobalContact(contactData) {
    return this.call({
      method: 'put',
      url: '/contacts/global',
      data: {
        ...contactData,
      },
    });
  }

  deleteContact(contactId) {
    return this.call({
      method: 'delete',
      url: `/contacts/${contactId}`,
    });
  }

  deleteGlobalContact(contactId) {
    return this.call({
      method: 'delete',
      url: `/contacts/global/${contactId}`,
    });
  }

  getCanAddReContact(id) {
    return this.call({
      method: 'get',
      url: `/contacts/${id}/can-add-re-contact`,
    });
  }
}

export default new ContactService();
