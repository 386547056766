import React from 'react';
import { useNavigate } from 'react-router-dom';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';
import AuthService from 'services/AuthService';
import { getNextPageInfo, getProjectSubjectsByRole, getFolderByRole } from 'help/constants';

import { Title, TextSection, List, TextButton } from '../../styles';

const Overview = () => {
  const { role } = AuthService.getUser();
  const navigate = useNavigate();

  const folder = getFolderByRole(role);

  const overviewSrc = `projects/${folder}/projects-overview.png`;

  const { title: nextPageTitle, path: nextPagePath } = getNextPageInfo(role, 'overview');

  const subjects = getProjectSubjectsByRole(role);
  const sectionsArray = Object.values(subjects).slice(1);

  return (
    <>
      <ImageContainer src={overviewSrc} alt="overview" />
      <Title>Overview</Title>
      <TextSection>
        Projects section lists all the projects you are involved into. Accessing the project provides the detailed
        information about the project. The following sub-sections are available for you within that section:
      </TextSection>
      <List>
        {sectionsArray.map(({ title, path }) => {
          const handleRedirectClick = () => navigate(path);
          return (
            <li key={title}>
              <TextButton onClick={handleRedirectClick}>{title}</TextButton>
            </li>
          );
        })}
      </List>
      <RedirectButton redirectTitle={nextPageTitle} redirectPath={nextPagePath} />
    </>
  );
};

export default Overview;
