import styled from 'styled-components';

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const ActionItem = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child > div:last-child {
    margin-bottom: 0;
  }
`;

export const ActionSubtitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: var(--textColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ActionNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 18px;
  height: 18px;
  background: var(--buttonPrimaryDisabled);
  border-radius: 50%;
  font-family: Avenir;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  color: var(--textColor);
`;

export const ActionData = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  display: flex;
  width: 100%;
  background: var(--tableHighlitedColor);
  border-radius: 4px;
  padding: 18px 20px;
  margin: 18px 0 16px;
`;

export const DataName = styled.span`
  color: #afb0b4;
`;

export const OverdueText = styled.span`
  color: #e68885;
`;
