import styled from 'styled-components';

import { NoContent } from 'components';

export const DesignationTitle = styled.span`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 138.5%;
  color: var(--sidebarSelectedIconBackground);
`;

export const StyledNoContent = styled(NoContent)`
  min-height: 200px;
`;
