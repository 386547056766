import React from 'react';
import styled from 'styled-components';

import { Button } from '@mui/material';
import Icon from 'components/Icon';

export const StyledIcon = styled(Icon)`
  svg > path {
    fill: var(--iconPrimaryColor);
  }
`;

export const StyledButton = styled(({ ...rest }) => <Button {...rest} />)`
  display: flex;
  justify-content: start;
  height: 70px;
  width: calc(100% * (1/3) - 24px - 2px);
  min-width: 245px;
  margin: 12px;
  padding: 12px 14px;
  white-space: nowrap;
  font-size: 16px;
  text-transform: none;
  color: var(--textColor);
  border: 1px solid ${({ isSelected }) => (
    isSelected
      ? 'var(--iconHover)'
      : 'var(--sectionButtonBorder)'
  )};
  border-radius: 6px;
  background-color: var(--sectionButtonBackground);
`;

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-right: 25px;
  background: var(--iconContainerBackground);
  border-radius: 4px;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  color: var(--titleColor);
`;

export const Line = styled.div`
  display: flex;
  flex-grow: 1;
  width: 30%;
  margin: 20px 10px;
  border-top: 1px solid var(--inputDisabledText);
`;
