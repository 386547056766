import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InviteService from 'notifications/api/InviteService';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import * as NotificationSelectors from 'notifications/store/notification.selectors';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import NotificationDialog from 'components/Dialogs/NotificationDialog';
import RejectionDialog from 'components/Dialogs/RejectionDialog';
import { Status } from 'components';

import FormDescription from './FormDescription';
import { RejectionReasonWrapper, RejectionReasonTitle, RejectionReasonText } from './styles';

const RegFormSubmitted = ({ notify }) => {
  const { isCompany } = notify;

  const dispatch = useDispatch();
  const slideOut = useSelector(NotificationSelectors.slideOut);

  const handleClose = (event) => {
    event.stopPropagation();
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  // Go to Second Dialog
  const handleReject = (event) => {
    event.stopPropagation();
    dispatch(NotificationsActions.setOpenedNotification({ ...notify, rejectionInProgress: true }));
  };

  // Return to Previous Dialog
  const handleCancelRejection = (event) => {
    event.stopPropagation();
    dispatch(NotificationsActions.setOpenedNotification({ ...notify, rejectionInProgress: false }));
  };

  const handleSendRejectionReason = async (values, formikBag) => {
    try {
      const { inviteId, userFullName, companyName, email } = notify.data;
      await isCompany
        ? InviteService.rejectCompany(inviteId, values.rejectionReason)
        : InviteService.reject(inviteId, values.rejectionReason);
      await dispatch(NotificationsActions.markAsViewed(notify.id));
      await dispatch(NotificationsActions.setOpenedNotification(null));
      dispatch(NotificationsActions.getAllInboxNotificationsList());
      showNotifySuccess(`${isCompany ? companyName : userFullName} registration was successfully rejected. The notification email was sent to ${email}.`);
    } catch (error) {
      const { fieldErrors } = error;
      if (fieldErrors) {
        formikBag.setErrors(fieldErrors);
      } else {
        showNotifyError(error);
      }
    }
  };

  const handleGiveAccess = async (event) => {
    event.stopPropagation();
    try {
      const { inviteId, userFullName, companyName, email } = notify.data;
      await isCompany
        ? InviteService.approveCompany(inviteId)
        : InviteService.approve(inviteId);
      await dispatch(NotificationsActions.markAsViewed(notify.id));
      await dispatch(NotificationsActions.setOpenedNotification(null));
      dispatch(NotificationsActions.getAllInboxNotificationsList());
      showNotifySuccess(`${isCompany ? companyName : userFullName} registration was successfully accepted. The confirmation email was sent to ${email}.`);
    } catch (error) {
      if (error.status === 400.5) {
        showNotifyError('The contact was deleted, you cannot approve or reject it.');
      } else {
        showNotifyError(error);
      }
    }
  };

  if (notify.rejectionInProgress) {
    return (
      <RejectionDialog
        onCancel={handleCancelRejection}
        onClose={handleCancelRejection}
        onSubmit={handleSendRejectionReason}
      />
    );
  }

  if (notify.data.status === 'Pending') {
    return (
      <ConfirmationDialog
        open
        borderedTitle
        projectName={notify.data.projectName}
        title="Registration Form Submitted"
        cancelButtonTitle="Reject"
        confirmButtonTitle="Give Access"
        onClose={handleClose}
        onCancel={handleReject}
        onConfirm={handleGiveAccess}
        slideOut={slideOut}
        animations
        withScroll
      >
        <FormDescription notify={notify} />
      </ConfirmationDialog>
    );
  }

  if (notify.data.status === 'Approved') {
    return (
      <NotificationDialog
        open
        title="Registration Form Submitted"
        footer={<Status status="accepted" />}
        onClose={handleClose}
        slideOut={slideOut}
        animations
      >
        <FormDescription notify={notify} />
      </NotificationDialog>
    );
  }

  return (
    <NotificationDialog
      open
      title="Registration Form Submitted"
      footer={<Status status="rejected" />}
      onClose={handleClose}
      slideOut={slideOut}
      animations
    >
      <>
        <FormDescription notify={notify} />
        <br />
        <RejectionReasonWrapper>
          <RejectionReasonTitle>Rejection Reason</RejectionReasonTitle>
          <RejectionReasonText>
            {notify.data.rejectionReason || 'Rejection reason undefined. Something went wrong.'}
          </RejectionReasonText>
        </RejectionReasonWrapper>
      </>
    </NotificationDialog>
  );
};

export default RegFormSubmitted;
