import React from 'react';

const Analytics = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.125 1.563H1.562v20.312a1.563 1.563 0 001.563 1.563h20.313v-1.563H3.125V1.562z" fill="#96CEF6" />
      <path d="M23.438 7.031h-5.47v1.563h2.805l-5.93 5.93-3.35-3.36a.779.779 0 00-1.11 0l-5.695 5.703 1.101 1.102 5.149-5.149 3.351 3.36a.782.782 0 001.11 0l6.476-6.485V12.5h1.563V7.031z" fill="#96CEF6" />
    </svg>
  );
};

export default Analytics;
