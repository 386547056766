import React, { useEffect } from 'react';

import { useMap } from './loadHelpers';
import { drawProjectLocations } from './graphicsHelpers';

import { StyledMap, Wrapper } from './styles';

const ReportLocationMap = (props) => {
  const {
    projectLocations,
    className,
    setScreens,
    setLayers,
  } = props;
  const loadOptions = {
    view: {
      center: [-47, 45],
      zoom: 3,
    },
    isProjectLocation: true,
  };

  const [mapRef, mapView, layers, graphicLayers, locator] = useMap(loadOptions);

  useEffect(() => {
    if (mapView && locator) {
      mapView.when(
        () => {
          const locationCoords = projectLocations.find((loc) => loc.isPrimary)?.coordinate;
          if (!locationCoords) return;
          setLayers(layers);
          setTimeout(() => {
            mapView.goTo({
              center: [Number(locationCoords.longitude), Number(locationCoords.latitude)],
            }).then(() => {
              setScreens(mapView);
            });
          }, 1000);
        },
      );
    }
  }, [mapView]);

  useEffect(() => {
    if (graphicLayers && projectLocations) {
      drawProjectLocations(graphicLayers.locations, projectLocations);
    }
  }, [projectLocations, graphicLayers]);

  return (
    <Wrapper>
      <div />
      <StyledMap ref={mapRef} className={className} />
    </Wrapper>
  );
};

export default ReportLocationMap;
