import React from 'react';

const CheckMarkRoundFilled = ({ size = 12, opacity = 1, color = '#96CEF6' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} fillOpacity={opacity} fillRule="evenodd" clipRule="evenodd" d="M6 0C2.733 0 0 2.733 0 6s2.733 6 6 6 6-2.667 6-6-2.667-6-6-6zm-.867 8.333L3 6.2l.667-.667L5.133 7l3.2-3.2.667.667-3.867 3.866z" />
      <path fill={color} fillOpacity=".68" opacity=".01" fillRule="evenodd" clipRule="evenodd" d="M5.133 8.334L3 6.201l.667-.667 1.466 1.467 3.2-3.2.667.666-3.867 3.867z" />
    </svg>
  );
};

export default CheckMarkRoundFilled;
