import numeral from 'numeral';
import { startCase, isEmpty, capitalize } from 'lodash';
import { LabelValue, Nullable } from 'types';

export const formatSum = (sum: number): string => {
  if (sum === 0 || !sum) {
    return '0';
  }

  return numeral(sum).format('0,0');
};

export const formatSize = (size: number): string => {
  if (size === 0 || !size) {
    return '0';
  }

  const result = numeral(size)
    .format('0.0 ib') // MiB
    .replace('i', ''); // MiB => MB

  return result;
};

export const getFileType = (fileName: string): string => {
  const fileType = fileName.split('.').reverse()[0];

  return fileType.toLowerCase();
};

// TODO: fix the need of Capitalizing first letter of field
export const formatSortField = (field: string): string => {
  const fieldForBackend = startCase(field).replace(/ /g, '');

  return fieldForBackend;
};

export const replaceNullWithEmptyStringsInObject = (object: any): any => {
  Object.keys(object).forEach((field) => {
    if (object[field] === null) {
      object[field] = '';
    }
  });

  return object;
};

export const mapValuesToLabels = (value: string | number, array: LabelValue[]): Nullable<string> => array.find((item) => item.value === value)?.label;

export const findOptionByValue = (value: string | number, array: LabelValue[]): Nullable<LabelValue> => (isEmpty(array) ? undefined : array.find((item) => item.value === value));

export const getServiceName = (serviceType: string, projectTypeName: string): string => {
  switch (serviceType) {
    case 'ProjectType':
      return projectTypeName;
    case 'DSU':
      return 'Delay in Start Up (DSU)';
    case 'TRIA':
      return 'Terrorism (TRIA)';
    default:
      return '';
  }
};

export const formatPercentage = (firstNumber: number, secondNumber: number): number => {
  const percentage = secondNumber ? (Math.round(((firstNumber / secondNumber) * 100 + Number.EPSILON) * 100) / 100) : 0;

  return percentage > 100 ? 100 : percentage;
};

export function getFormattedWithUnits(value: number, unitName: string, empty = '0'): string {
  if (!value) return empty;
  const units = value > 1 ? unitName.concat('s') : unitName;

  return `${value.toFixed(2)} ${units}`;
}

export function removeExtension(name: string) {
  const newName = name.split('.');

  if (newName.length > 1) {
    return newName.splice(0, newName.length - 1).join('');
  }

  return name;
}

export function getRiskScore(value: number, max: number): number {
  if (value >= max) {
    return max;
  }

  return value;
}

export function capitalizeAll(str: string): string {
  return str.split(' ').map((word) => capitalize(word)).join(' ');
}
