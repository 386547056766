import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import AuthService from 'services/AuthService';

import RedirectButton from 'help/components/RedirectButton';
import RecommendationsSection from 'help/pages/Projects/components/RecommendationsSection';
import ClaimsSection from 'help/pages/Projects/components/ClaimsSection';
import { getNextPageInfo, getFolderByRole } from 'help/constants';

import { Title, TextSection } from '../../styles';

const ReportNotesAndRecommendationsAndClaims = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const reportNotesSrc = `projects/${folder}/projects-report-notes.png`;

  const { title, path } = getNextPageInfo(role, 'report-notes-recommendations-claims');

  return (
    <>
      <ImageContainer src={reportNotesSrc} alt="report-notes" />
      <Title>Report Notes</Title>
      <TextSection>
        Report Notes sub-section represent a template with the default set of questions that should be populated
        for each project by the risk engineer. Reports Notes sub-section is only available for the
        Risk Engineer and can be populated from the web or mobile app.
      </TextSection>
      <TextSection>
        When the Reports Notes are populated once, the data can be reused and updated for the next inspections.
        The data specified in the Report Notes affects the project risk score that is
        calculated within the Analytics sub-section.
      </TextSection>
      <RecommendationsSection />
      <ClaimsSection />
      <RedirectButton redirectTitle={title} redirectPath={path} />
    </>
  );
};

export default ReportNotesAndRecommendationsAndClaims;
