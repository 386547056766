import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import Icon from 'components/Icon';

export const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 330px;
  margin-top: 24px;
  margin-bottom: 40px;
`;

export const Title = styledMui('div')(({ theme }) => ({
  marginTop: 18,
  marginBottom: 11,
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '18px',
  letterSpacing: '0.16px',
  color: theme.palette.text.main,
}));

export const Descr = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '18px',
  letterSpacing: 0.16,
  color: theme.palette.text.light,
}));

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  marginBottom: 8,
  'svg > path': {
      fill: theme.palette.icon.primary.color,
  },
}));
