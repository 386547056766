import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';

import { HighlightedText } from './styles';

const AssignmentConfirmed = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: { projectId, userFullName, projectName } } = notify;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToProject = () => {
    handleClose();
    closeNotificationList();
    dispatch(NotificationsActions.markAsViewed(notify.id));
    navigate(`/projects/${projectId}/details`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="Assignment Confirmed"
      cancelButtonTitle="Close"
      confirmButtonTitle="Go to Project"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToProject}
    >
      <HighlightedText>{userFullName}</HighlightedText>
      {' '}
      has confirmed the assignment for
      {' '}
      <HighlightedText>{projectName}</HighlightedText>
      {' '}
      project. You’ll be notified on further project activity.
    </ConfirmationDialog>
  );
};

export default AssignmentConfirmed;
