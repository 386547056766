import qs from 'qs';
import ApiClient from 'services/ApiClient';

class ReportNotesService extends ApiClient {
  getReportNotes(projectId) {
    return this.call({
      method: 'get',
      url: `/report-notes?${qs.stringify({ projectId })}`,
    });
  }

  getReportNotesPopulated(projectId) {
    return this.call({
      method: 'get',
      url: `/report-notes/populated?${qs.stringify({ projectId })}`,
    });
  }

  updateReportNotes(reportNotesData, useDynamic) {
    let url = '/report-notes';

    if (useDynamic) {
      url = `${url}/dynamic`;
    }

    return this.call({
      method: 'put',
      url,
      data: {
        ...reportNotesData,
      },
    });
  }

  isTabAvailable(projectId) {
    return this.call({
      method: 'get',
      url: `/report-notes/is-tab-available?${qs.stringify({ projectId })}`,
    });
  }
}

export default new ReportNotesService();
