import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Sector, Cell, Tooltip } from 'recharts';
import { useTheme } from '@mui/material/styles';

import { formatCurrency } from 'utils/CurrencyUtils';

import { COLORS } from './constants';
import {
  StyledResponsiveContainer, TooltipRoot, TooltipLabel, TooltipInfo,
  TooltipValue, TooltipValueWrapper, TooltipDataName,
} from './styles';

const NO_DATA_LIGHT_COLOR = '#E6E9F4';
const NO_DATA_DARK_COLOR = '#3d3e49';
const START_ANIMATION_DELAY = 20;
const ALLOW_ESCAPE_VIEW_BOX = { x: false, y: false };

const PieTooltip = ({ active, payload, hoveredPercent, hiddenValues = [], dataName, dataType }) => {
  if (!active || !payload) return null;
  const { name, value } = payload[0];
  const isCurrency = dataType === 'currency';

  const isValueHidden = hiddenValues.includes(dataName);

  return (
    <TooltipRoot>
      <TooltipLabel>
        {`${name}`}
      </TooltipLabel>
      <TooltipInfo>
        {dataName && !isValueHidden && (
          <TooltipValueWrapper>
            <TooltipDataName>{`${dataName}:`}</TooltipDataName>
            <TooltipValue>
              {isCurrency ? `$${formatCurrency(value)}` : value}
            </TooltipValue>
          </TooltipValueWrapper>
        )}

        <TooltipDataName>Share:</TooltipDataName>
        <TooltipValue>
          {(Number((hoveredPercent * 100).toFixed(2)))}
          %
        </TooltipValue>
      </TooltipInfo>
    </TooltipRoot>
  );
};

const Chart = ({
  data, empty, onClick, containerWidth, containerHeight, hiddenValues,
  colors, innerR = 85, outerR = 130, dataName, dataType, noAnimations, alignTop,
}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [currentData, setCurrentData] = useState([]);
  const [hoveredPercent, setHoveredPercent] = useState(null);
  let timeoutHandler = null;

  const theme = useTheme();
  const NO_DATA_COLOR = theme.light ? NO_DATA_LIGHT_COLOR : NO_DATA_DARK_COLOR;

  const loadData = () => {
    setCurrentData([]);
    timeoutHandler = setTimeout(() => {
      timeoutHandler = null;
      setCurrentData(data);
    }, START_ANIMATION_DELAY);
  };

  useEffect(() => {
    if (!empty && data) {
      noAnimations ? setCurrentData(data) : loadData();
    }
    if (empty) {
      setCurrentData([{ name: 'None', value: 1 }]);
    }

    /*
      Fix for Warning: Can't perform a React state update on an unmounted component.
      This is a no-op, but it indicates a memory leak in your application.
      To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    */
    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [data]);

  const handleMouseEnter = (activeData, index) => {
    const { percent } = activeData;
    setHoveredPercent(percent);
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const handleClick = ({ name }) => {
    if (onClick) {
      setActiveIndex(null);
      onClick(name);
    }
  };

  const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }) => (
    <Sector
      className="active"
      cx={cx}
      cy={cy}
      innerRadius={innerRadius + 5}
      outerRadius={outerRadius + 10}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );

  const renderEmptyChart = () => (
    <PieChart
      isAnimationActive={false}
    >
      <Pie
        isAnimationActive={false}
        data={currentData}
        dataKey="value"
        innerRadius={innerR}
        outerRadius={outerR}
      >
        <Cell
          stroke={NO_DATA_COLOR}
          fill={NO_DATA_COLOR}
        />
      </Pie>
    </PieChart>
  );

  const renderChart = () => (
    <PieChart
      isAnimationActive={!noAnimations}
      cursor="pointer"
      onMouseLeave={handleMouseLeave}
      alignTop
    >
      <Pie
        isAnimationActive={!noAnimations}
        animationEasing="ease"
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={currentData}
        dataKey="value"
        innerRadius={innerR}
        outerRadius={outerR}
        onMouseEnter={handleMouseEnter}
        onMouseUp={handleClick}
      >
        {currentData.map((entry, index) => {
          let color = COLORS[index % COLORS.length];
          if (colors) {
            const colorSample = colors.find((c) => (c.name === entry.name));
            color = colorSample ? colorSample.color : '';
          }
          return (
            <Cell
              key={`cell-${index}`}
              stroke={color}
              fill={color}
            />
          );
        })}
      </Pie>
      {(activeIndex >= 0) && currentData && (
        <Tooltip
          cursor
          allowEscapeViewBox={ALLOW_ESCAPE_VIEW_BOX}
          content={(
            <PieTooltip
              hoveredPercent={hoveredPercent}
              hiddenValues={hiddenValues}
              dataName={dataName}
              dataType={dataType}
            />
          )}
        />
      )}
    </PieChart>
  );

  return (
    <StyledResponsiveContainer
      maxHeight={containerHeight}
      maxWidth={containerWidth}
      alignTop={alignTop}
    >
      {empty ? renderEmptyChart() : renderChart()}
    </StyledResponsiveContainer>
  );
};

export default Chart;
