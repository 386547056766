import React from 'react';

import { StepsRoot, Step, NumberIcon, Title } from './styles';

const Steps = () => {
  return (
    <StepsRoot>
      <Step>
        <NumberIcon>1</NumberIcon>
        <Title>Invite Team Members</Title>
      </Step>
      <Step>
        <NumberIcon>2</NumberIcon>
        <Title>Set Up Company Rates</Title>
      </Step>
      <Step>
        <NumberIcon>3</NumberIcon>
        <Title>Create Your First Project</Title>
      </Step>
    </StepsRoot>
  );
};

export default Steps;
