import ResourcesService from 'projects/api/ResourcesService';

const GET_RESOURCES_LIST = 'GET_RESOURCES_LIST';
const GET_RESOURCE_DATA = 'GET_RESOURCE_DATA';
const ADD_RESOURCE = 'ADD_RESOURCE';
const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
const GET_RESOURCE_SUGGESTIONS = 'GET_RESOURCE_SUGGESTIONS';
const ADD_RESOURCE_SUGGESTION = 'ADD_RESOURCE_SUGGESTION';
const CLEAR_RESOURCE_SUGGESTIONS = 'CLEAR_RESOURCE_SUGGESTIONS';
const CLEAR_RESOURCE_STATE = 'CLEAR_RESOURCE_STATE';
const SEND_PENDING_BILLS = 'SEND_PENDING_BILLS';

export const getResourcesList = (projectId) => ({
  type: GET_RESOURCES_LIST,
  apiCall: () => ResourcesService.getResourcesList(projectId),
});

export const getResourceData = (id) => ({
  type: GET_RESOURCE_DATA,
  apiCall: () => ResourcesService.getResourceData({ id }),
});

export const getResourceSuggestions = (data) => ({
  type: GET_RESOURCE_SUGGESTIONS,
  apiCall: () => ResourcesService.getResourceSuggestions(data),
});

export const addResource = (data) => ({
  type: ADD_RESOURCE,
  apiCall: () => ResourcesService.addResource(data),
});

export const updateResource = (data) => ({
  type: UPDATE_RESOURCE,
  apiCall: () => ResourcesService.updateResource(data),
});

export const addResourceSuggestionFromInbox = (suggestion) => ({
  type: ADD_RESOURCE_SUGGESTION,
  suggestion,
});

export const clearResourceSuggestions = () => ({
  type: CLEAR_RESOURCE_SUGGESTIONS,
});

export const clearResources = () => ({
  type: CLEAR_RESOURCE_STATE,
});

export const sendPendingBills = (data) => ({
  type: SEND_PENDING_BILLS,
  apiCall: () => ResourcesService.sendPendingBills(data),
});

const initialState = {
  current: {},
  resourcesList: {},
  addResources: {},
  resourceSuggestions: {},
  resourceSuggestionFromInbox: {},
};

export default (state = initialState, action) => {
  const { response, suggestion } = action;

  switch (action.type) {
    case GET_RESOURCE_DATA: {
      return {
        ...state,
        current: {
          ...state.current,
          ...response,
        },
      };
    }

    case GET_RESOURCES_LIST: {
      return {
        ...state,
        resourcesList: {
          ...response,
        },
      };
    }
    case GET_RESOURCE_SUGGESTIONS: {
      return {
        ...state,
        resourceSuggestions: {
          ...response,
        },
      };
    }
    case ADD_RESOURCE: {
      return {
        ...state,
        addResources: {
          ...state.addResources,
          ...response,
        },
      };
    }
    case ADD_RESOURCE_SUGGESTION: {
      return {
        ...state,
        resourceSuggestionFromInbox: {
          ...suggestion,
        },
      };
    }
    case CLEAR_RESOURCE_SUGGESTIONS: {
      return {
        ...state,
        resourceSuggestions: {},
        resourceSuggestionFromInbox: {},
      };
    }
    case CLEAR_RESOURCE_STATE: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
};
