import React from 'react';

const Delete = () => {
  return (
    <svg width="15" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 .7H5v1.166h5V.7zM0 3.032v1.165h1.25v11.659c0 .644.56 1.166 1.25 1.166h10c.69 0 1.25-.522 1.25-1.166V4.197H15V3.032H0zm2.5 12.824V4.198h10v11.658h-10z" fill="currentColor" />
    </svg>
  );
};

export default Delete;
