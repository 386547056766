import React, { useReducer, useState } from 'react';
import { DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';

import { Button, CloseButton } from 'components';
import { addSubscriptionInitialValues, PACKAGES_IDS } from 'subscriptions/constants';
import SubscriptionsService from 'subscriptions/api/SubscriptionsService';
import { showNotifySuccess } from 'services/toaster';
import AddSubscriptionForm from 'subscriptions/components/AddSubscriptionForm';

import { StyledDialog, ButtonWrapper } from './styles';

const reduceFunc = (prevState, newState) => ({
  ...prevState,
  ...newState,
});

const AddSubscriptionModal = ({
  isOpen, onCancel, subscriberType, subscriberId, onSuccess, subscription, subscriberEmail, view,
}) => {
  const theme = useTheme();
  const isIC = subscriberType === 'InsuranceCompany';
  const subscriptionData = subscription ? {
    ...subscription,
    startDate: new Date(subscription.startDate),
    packageType: subscription.package,
  } : {
    packageType: isIC ? PACKAGES_IDS.Lite : PACKAGES_IDS.Pro,
  };
  const [state, setState] = useReducer(reduceFunc, { ...addSubscriptionInitialValues, ...subscriptionData });
  const [error, setError] = useState();
  const { packageType, type, period, startDate, maxProjectsCount } = state;

  let dialogTitle;
  if (subscription) {
    if (view) {
      dialogTitle = 'View Subscription';
    } else {
      dialogTitle = 'Edit Subscription';
    }
  } else {
    dialogTitle = 'Add New Subscription';
  }

  const handleSubmit = async () => {
    try {
      if (subscription) {
        await SubscriptionsService.editSubscription({
          subscriberType,
          id: subscription.id,
          type,
          package: packageType,
          period,
          startDate,
          maxProjectsCount,
        });
        showNotifySuccess(`New subscription was successfully edited. The notification email was sent to ${subscriberEmail}.`);
      } else {
        await SubscriptionsService.addSubscription({
          subscriberType,
          subscriberId,
          type,
          package: packageType,
          period,
          startDate,
          maxProjectsCount,
        });
        showNotifySuccess(`New subscription was successfully added. The notification email was sent to ${subscriberEmail}.`);
      }
      onSuccess();
      onCancel();
    } catch ({ fieldErrors }) {
      const dateError = fieldErrors.startDate || fieldErrors.date;

      if (fieldErrors.maxProjectsCount) setError({ maxProjectsCount: fieldErrors.maxProjectsCount });
      if (dateError) setError({ date: dateError });
    }
  };

  return (
    <StyledDialog
      className={cx(theme.light && 'light-theme')}
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <CloseButton onClick={onCancel} />
      <AddSubscriptionForm
        subscriberType={subscriberType}
        state={state}
        setState={setState}
        error={error}
        view={view}
      />
      <ButtonWrapper>
        <Button
          onClick={onCancel}
          label="cancel"
          variant="contained"
          formNoValidate
          text={view ? 'Close' : 'Cancel'}
        />
        {!view && (
          <Button
            onClick={handleSubmit}
            label="submit"
            type="submit"
            color="primary"
            variant="contained"
            formNoValidate
            text={subscription ? 'Update' : 'Save'}
          />
        )}
      </ButtonWrapper>
    </StyledDialog>
  );
};

export default AddSubscriptionModal;
