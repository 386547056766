import styled from 'styled-components';

import { Button, ActionButton } from 'components';

export const ControlsWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const StyledButton = styled(Button)`
  && {
    height: unset;
    font-size: 13px;
    border: 1px solid var(--buttonPrimaryColor);
    :hover {
      border: 1px solid var(--buttonPrimaryHover);
    }
    &.Mui-disabled {
      border: 1px solid var(--buttonPrimaryDisabled);
    }
  }
`;

export const StyledActionButton = styled(ActionButton)`
  && {
    margin-left: 24px;
    min-width: unset;
    height: auto;
    font-size: 13px;
    border: 1px solid var(--buttonPrimaryColor);
    :hover {
      border: 1px solid var(--buttonPrimaryHover);
    }
    &.Mui-disabled {
      border: 1px solid var(--buttonPrimaryDisabled);
    }
  }
`;

export const BulkApproveButtonsBlock = styled.div`
  display: flex;
  padding-right: 10px;
  border-right: 1px solid var(--lightBorderColor);
  && {
    button {
      min-width: unset;
      margin-left: 10px;
    }
  }
`;

export const ApproveButton = styled(ActionButton)`
  color: #88EEAC;
  svg path {
    fill: #88EEAC;
  }
  :hover {
    color: #B8F5CD;
    svg path {
      fill: #B8F5CD;
    }
  }
`;

export const IssuedDate = styled.span`
  font-weight: 500;
`;
