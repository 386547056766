import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

import Spinner from 'components/Spinner';

import { useMap } from './loadHelpers';
import { drawProjectsData, drawGraphic } from './graphicsHelpers';

import { StyledMap, Wrapper } from './styles';

const GlobalMap = (props) => {
  const { className, data, mapType } = props;
  const theme = useTheme();

  const loadOptions = {
    view: {
      center: [-47, 45],
      zoom: 2,
    },
    data,
  };

  const [mapRef, mapView, graphicsLayers] = useMap(loadOptions);

  const applyMouseHandlersToView = async () => {
    mapView.on('click', async (event) => {
      const { results } = await mapView.hitTest(event);
      const locationClickResult = results.find(
        (result) => result.graphic.layer?.id === 'locations' && result.graphic.attributes,
      );
      const isClickOnLocation = !!locationClickResult;
      const hoveredDotColor = locationClickResult
        ? locationClickResult.graphic.attributes.dotColor
        : '#96CEF6';

      if (isClickOnLocation) {
        const { longitude, latitude } = results[0].graphic.geometry;
        const geometry = {
          type: 'point',
          longitude,
          latitude,
        };
        const symbol = {
          type: 'simple-marker',
          color: '#FFF',
          size: '16px',
          outline: {
            color: hoveredDotColor,
            width: 4.5,
          },
        };
        drawGraphic(graphicsLayers.hover); // erase point
        drawGraphic(graphicsLayers.points, { geometry, symbol });
      } else {
        drawGraphic(graphicsLayers.points); // erase point
      }
    });

    mapView.on('pointer-move', async (event) => {
      const { results } = await mapView.hitTest(event);
      const hoveredResult = results.find(
        (result) => result.graphic.layer?.id === 'locations' && result.graphic.attributes,
      );
      const hoveredDotColor = hoveredResult
        ? hoveredResult.graphic.attributes.dotColor
        : '#96CEF6';
      const isHoverOverPoint = !!hoveredResult;
      const isPointSelected = results.some(
        (result) => result.graphic.layer?.id === 'points',
      );

      if (isHoverOverPoint) {
        const { longitude, latitude } = results[0].graphic.geometry;
        const geometry = {
          type: 'point',
          longitude,
          latitude,
        };
        const defaultHoveredColor = theme.palette.map.outline;
        const dotColor = isPointSelected ? '#FFF' : hoveredDotColor;
        const outlineColor = isPointSelected
          ? hoveredDotColor
          : defaultHoveredColor; // if reverse colors needed
        // const outlineColor = '#343640';
        const symbol = {
          type: 'simple-marker',
          size: '20px',
          color: dotColor,
          outline: {
            color: outlineColor,
            width: 4.5,
          },
        };

        drawGraphic(graphicsLayers.hover, { geometry, symbol });
      } else {
        drawGraphic(graphicsLayers.hover); // erase point
      }
    });
  };

  useEffect(() => {
    if (mapView) {
      applyMouseHandlersToView();
    }
  }, [mapView]);

  // handling drawing data on map
  useEffect(() => {
    if (graphicsLayers.locations && data) {
      drawProjectsData(data, graphicsLayers.locations, mapType, theme);
    }
  }, [data, graphicsLayers]);

  if (!mapView) {
    return (
      <Wrapper>
        <Spinner />
        <StyledMap ref={mapRef} className={className} isGlobal />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div />
      <StyledMap ref={mapRef} className={className} isGlobal />
    </Wrapper>
  );
};

export default GlobalMap;
