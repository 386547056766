import React from 'react';

const Contacts = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.092 1.853H7.152a1.549 1.549 0 00-1.549 1.549V6.5H4.054v1.549h1.55v3.872h-1.55v1.549h1.55v3.872h-1.55v1.55h1.55v3.097a1.549 1.549 0 001.548 1.549h13.94a1.549 1.549 0 001.55-1.55V3.403a1.549 1.549 0 00-1.55-1.549zm0 20.136H7.152V18.89h1.55v-1.549h-1.55V13.47h1.55V11.92h-1.55V8.049h1.55v-1.55h-1.55V3.403h13.94v18.587z" fill="#fff" fillOpacity=".87" />
      <path d="M11.799 6.5h6.196v1.549h-6.196v-1.55zm0 5.421h6.196v1.549h-6.196V11.92zm0 5.421h6.196v1.55h-6.196v-1.55z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Contacts;
