import styled from 'styled-components';

import { Button, Icon } from 'components';

export const Text = styled.div`
  font-family: Graphik LC;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: var(--textColor);
`;

export const HighlightedText = styled.span`
  margin: 0 2px;
  font-weight: 600;
`;

export const AgendaLine = styled.div`
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 20px 25px 20px;
  margin-top: 15px;
  background: var(--notificationInfoBackground);
  border-radius: 4px;
`;

export const Contact = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const StyledIcon = styled(Icon)`
  color: var(--sidebarSelectedIconBackground);
  margin: 0 10px;
  transform: rotate(180deg);
`;

export const StyledButton = styled(Button)`
  font-family: Graphik LC;
  font-style: normal;
  font-size: 9px;
  line-height: 24px;
  color: var(--sidebarSelectedIconBackground);
  
  &&& {
    padding-left: 0;
    font-weight: normal;
  }
`;

export const Link = styled.a`
  color: var(--sidebarSelectedIconBackground);
`;
