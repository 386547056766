import { LabelValue, NameEmail } from 'types';
import { COMPANY_TYPES } from './company';

export interface User {
  role: string;
  user: NameEmail;
  companyType: COMPANY_TYPES;
  companyId: string;
  subscription: string;
  hasAcceptedLicense: boolean;
  hasAcceptedEula: boolean;
}

export enum ROLE {
  ADMIN = 'Admin',
  IC_COORDINATOR = 'ICCoordinator',
  UNDERWRITER = 'ICUnderwriter',
  BROKER = 'Broker',
  INSURANCE_REPRESENTATIVE = 'InsuranceRepresentative',
  RISK_MANAGER = 'RiskManager',
  DEVELOPER_OWNER = 'DeveloperOwner',
  SITE_CONTACT = 'SiteContact',
  RC_COORDINATOR = 'RCCoordinator',
  RISK_ENGINEER = 'RiskEngineer',
  NONE = 'None',
}

export interface RoleIdName {
  id: ROLE;
  name: string;
  brokerCompanyName: string;
}

export enum CONTACT_POSITION {
  IC_COORDINATOR = 'ICCoordinator',
  UNDERWRITER = 'ICUnderwriter',
  BROKER = 'Broker',
  INSURANCE_REPRESENTATIVE = 'InsuranceRepresentative',
  RISK_MANAGER = 'RiskManager',
  DEVELOPER_OWNER = 'DeveloperOwner',
  SITE_CONTACT = 'SiteContact',
  RC_COORDINATOR = 'RCCoordinator',
  RISK_ENGINEER = 'RiskEngineer',
  EXTERNAL_RISK_ENGINEER = 'ExternalRE',
  INTERNAL_RISK_ENGINEER = 'InternalRE',
}

export interface ContactLabelValue extends Pick<LabelValue, 'label'> {
  value: CONTACT_POSITION;
  brokerCompanyName: string;
}
