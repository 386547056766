import React from 'react';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';

import { requiredString } from 'utils/validation/customTypes';
import FormDialog from 'components/Dialogs/FormDialog';
import { FormikField, Input } from 'components';

import { ContentWrapper } from './styles';

const REASON_MAX_LENGTH = 1000;

const REJECTION_SCHEMA = Yup.object().shape({
  rejectionReason: requiredString,
});

const initialValues = {
  rejectionReason: '',
};

const RejectionDialog = ({ onCancel, onSubmit, onClose }) => {
  const theme = useTheme();

  return (
    <FormDialog
      className={cx(theme.light && 'light-theme')}
      open
      shortTitle
      title="Reject Reason"
      submitButtonTitle="Send Reason"
      onCancel={onCancel}
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={REJECTION_SCHEMA}
    >
      <ContentWrapper>
        <div>Please provide rejection reason below.</div>
        <FormikField
          required
          rows={5}
          multiline
          component={Input}
          name="rejectionReason"
          label="Reject Reason"
          maxLength={REASON_MAX_LENGTH}
        />
      </ContentWrapper>
    </FormDialog>
  );
};

export default RejectionDialog;
