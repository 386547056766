import qs from 'qs';
import ApiClient from 'services/ApiClient';
import { pickBy } from 'lodash';

import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class TeamMembersService extends ApiClient {
  getTeamMembersList(params) {
    const { name, page = 1, pageSize = PAGE_SIZE, sortBy } = params || {};
    const query = pickBy({
      name,
      page,
      pageSize,
    });

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      if (sortBy.field === 'fullName') {
        query.SortFieldName = 'ContactInfo.Name.FullName';
      } else {
        query.SortFieldName = formatSortField(sortBy.field);
      }
      query.SortDirection = sortBy.direction;
    }

    return this.call({
      method: 'get',
      url: `/team-members?${qs.stringify(query)}`,
    });
  }

  addTeamMember(data) {
    return this.call({
      method: 'post',
      url: '/team-members',
      data,
    });
  }

  updateTeamMember(data) {
    return this.call({
      method: 'put',
      url: '/team-members',
      data,
    });
  }

  getTeamMember(memberId) {
    return this.call({
      method: 'get',
      url: `/team-members/${memberId}`,
    });
  }

  getTeamMemberCount() {
    return this.call({
      method: 'get',
      url: '/team-members/count-limits',
    });
  }

  deleteTeamMember(memberId) {
    return this.call({
      method: 'delete',
      url: `/team-members/${memberId}`,
    });
  }

  deleteTeamMemberWithTransfer(data) {
    return this.call({
      method: 'delete',
      url: '/team-members',
      data,
    });
  }
}

export default new TeamMembersService();
