import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { StyledBackdrop, SpinnerWrapper } from './styles';

const Spinner = (props) => {
  const { fullscreen, size = 40, ...rest } = props;
  if (fullscreen) {
    return (
      <StyledBackdrop open>
        <CircularProgress />
      </StyledBackdrop>
    );
  }

  return (
    <SpinnerWrapper {...rest}>
      <CircularProgress size={size} />
    </SpinnerWrapper>
  );
};

export default Spinner;
