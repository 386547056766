import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';

import {
  StyledSelect,
  StyledInputLabel,
  StyledMenuItem,
} from './styles';

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: -2,
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const newContactOption = {
  label: 'New Coordinator',
  value: 'new',
};

const CoordinatorSelector = ({
  options,
  required,
  disabled,
  label,
  error,
  readOnly,
  addBtnText = '+ Add New Coordinator',
  onChange,
  value,
  fullName,
  withoutAddNew,
}) => {
  const theme = useTheme();
  // TODO: Need to discuss the situation with different IDs for entities in Global list and Project list
  // need addtional field for project contact like 'globalContactId'
  // now here is workaround (it also prevents annoying MUI warnings):
  const matchedOption = options.concat(newContactOption)
    .find((option) => (option.value === value || option.label === fullName));
  const fixedValue = matchedOption ? matchedOption.value : value;
  const hasOptions = options.length > 0;

  const renderSelectedValue = (selectedValue) => options
    .concat(newContactOption)
    .find((option) => (option.value === selectedValue || option.label === fullName))?.label;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const renderMenuItem = (itemValue, itemLabel, className, itemDisabled = false) => {
    return (
      <StyledMenuItem
        className={cx(theme.light && 'light-theme', className)}
        key={itemValue}
        value={itemValue}
        disabled={itemDisabled}
      >
        {itemLabel}
      </StyledMenuItem>
    );
  };

  return (
    <FormControl
      variant="filled"
      fullWidth
      required={required}
      error={!!error}
      disabled={disabled}
    >
      <StyledInputLabel error={!!error}>
        {label}
      </StyledInputLabel>

      <StyledSelect
        value={fixedValue}
        renderValue={renderSelectedValue}
        disableUnderline
        error={!!error}
        readOnly={readOnly}
        MenuProps={menuProps}
        defaultValue=""
        onChange={handleChange}
      >
        {hasOptions && options.map((option) => renderMenuItem(option.value, option.label))}
        {withoutAddNew
          ? (!hasOptions && renderMenuItem(null, 'No options', null, true))
          : renderMenuItem(newContactOption.value, addBtnText, 'addItem')
        }
      </StyledSelect>

      <FormHelperText>
        {error || ' '}
      </FormHelperText>
    </FormControl>
  );
};

export default CoordinatorSelector;
