import ReportsService from 'projects/api/ReportsService';

const GET_REPORTS_LIST = 'GET_REPORTS_LIST';
const GET_PENDING_REPORTS = 'GET_PENDING_REPORTS';
const GET_REPORT_DATA = 'GET_REPORT_DATA';
const SET_PENDING_REPORT_FILE_ID = 'SET_PENDING_REPORT_FILE_ID';
const CLEAR_PENDING_REPORT = 'CLEAR_PENDING_REPORT';

export const getReportsList = (params) => ({
  type: GET_REPORTS_LIST,
  apiCall: () => ReportsService.getReportsList(params),
});

export const setPendingReportFileId = (fileId) => ({
  type: SET_PENDING_REPORT_FILE_ID,
  fileId,
});

export const getPendingReport = (params) => ({
  type: GET_PENDING_REPORTS,
  apiCall: () => ReportsService.getPendingReport(params),
});

export const getReportData = (params) => ({
  type: GET_REPORT_DATA,
  apiCall: () => ReportsService.getReportData(params),
});

export const clearPendingReport = () => ({
  type: CLEAR_PENDING_REPORT,
});

const initialState = {
  reportsList: {
    data: [],
  },
  pendingReport: {
    isLoading: true,
  },
  reportData: {
    isLoading: true,
  },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_REPORTS_LIST: {
      return {
        ...state,
        reportsList: {
          ...state.reportsList,
          ...response,
        },
      };
    }
    case GET_PENDING_REPORTS: {
      return {
        ...state,
        pendingReport: {
          ...response,
        },
      };
    }
    case GET_REPORT_DATA: {
      return {
        ...state,
        reportData: {
          ...state.reportData,
          ...response,
        },
      };
    }
    case SET_PENDING_REPORT_FILE_ID: {
      return {
        ...state,
        pendingReportFileId: action.fileId || state.pendingReportFileId,
      };
    }
    case CLEAR_PENDING_REPORT: {
      return {
        ...state,
        pendingReport: {
          ...state.pendingReport,
          pendingReportInfo: {
            ...state.pendingReport.pendingReportInfo,
            inspectionId: null,
          },
        },
      };
    }
    default:
      return state;
  }
};
