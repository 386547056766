import React from 'react';

const Txt = () => {
  return (
    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity=".9" fill="#fff" fillOpacity=".87">
        <path d="M24.67 8.933l-6.767-6.768a.879.879 0 00-.676-.29H6.62A1.934 1.934 0 004.687 3.81v23.203a1.934 1.934 0 001.934 1.934h16.406a1.934 1.934 0 001.934-1.934V9.61a.88.88 0 00-.29-.677zm-7.443-4.737L22.64 9.61h-5.414V4.196zm5.8 22.816H6.621V3.809h8.672v5.8a1.934 1.934 0 001.934 1.934h5.8v15.469z" />
        <path d="M10.35 21h.804v-3.66h1.194v-.63H9.156v.63h1.194V21zm2.335 0h.816l1.008-1.674L15.517 21h.912l-1.374-2.22 1.266-2.07h-.822l-.906 1.524-.912-1.524h-.9l1.284 2.088L12.685 21zm5.247 0h.804v-3.66h1.194v-.63h-3.192v.63h1.194V21z" />
      </g>
    </svg>
  );
};

export default Txt;
