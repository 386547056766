import React from 'react';

const Administration = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 12.5H12.5v1.25h6.25V12.5zM18.75 15H12.5v1.25h6.25V15zM18.75 17.5H12.5v1.25h6.25V17.5z" fill="#fff" fillOpacity=".87" />
      <path d="M18.315 6.902L16.84 4.348a1.248 1.248 0 00-1.483-.56l-1.521.515a6.895 6.895 0 00-.82-.474L12.7 2.255a1.25 1.25 0 00-1.226-1.005h-2.95a1.25 1.25 0 00-1.226 1.005l-.315 1.574a6.854 6.854 0 00-.829.471l-1.512-.512a1.248 1.248 0 00-1.483.56L1.685 6.902a1.25 1.25 0 00.257 1.564l1.207 1.061c-.01.157-.024.313-.024.473 0 .161.006.32.017.479l-1.2 1.055a1.25 1.25 0 00-.257 1.564l1.475 2.554a1.249 1.249 0 001.483.56l1.521-.515c.262.177.536.335.82.474l.315 1.574a1.25 1.25 0 001.226 1.005H10V17.5H8.525l-.444-2.22a5.687 5.687 0 01-1.684-.981l-2.154.728-1.475-2.554 1.702-1.497a5.579 5.579 0 01-.004-1.955L2.767 7.527l1.476-2.554 2.142.724a5.644 5.644 0 011.696-.978L8.525 2.5h2.95l.444 2.22c.614.227 1.183.56 1.684.981l2.154-.728 1.475 2.554-1.748 1.533.824.94 1.75-1.534a1.25 1.25 0 00.257-1.564z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Administration;
