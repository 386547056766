import React from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledButton, StyledIcon } from './styles';

const RedirectButton = ({ redirectTitle, redirectPath }) => {
  const navigate = useNavigate();

  const handleRedirect = () => navigate(redirectPath);

  return (
    <StyledButton
      small
      color="error"
      variant="text"
      onClick={handleRedirect}
    >
      Learn more about {redirectTitle}
      <StyledIcon icon="arrow" noWrapper />
    </StyledButton>
  );
};

export default RedirectButton;
