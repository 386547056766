import { ROLES_IDS } from 'services/AuthService';

export const SECTIONS = {
  dashboard: {
    title: 'Dashboard',
    path: 'dashboard',
  },
  projects: {
    title: 'Projects',
    path: 'projects',
  },
  globalEcosystem: {
    title: 'Global Ecosystem',
    path: 'global-ecosystem',
  },
  account: {
    title: 'Account',
    path: 'account',
  },
  myCompany: {
    title: 'My Company',
    path: 'my-company',
  },
  communications: {
    title: 'Communications',
    path: 'communications',
  },
};

export const DASHBOARD_SUBJECTS = {
  overview: {
    title: 'Overview',
    path: 'overview',
  },
  tools: {
    title: 'Tools',
    path: 'tools',
  },
};

export const PROJECT_SUBJECTS = {
  overview: {
    title: 'Overview',
    path: 'overview',
  },
};

export const GE_SUBJECTS = {
  overview: {
    title: 'Overview',
    path: 'overview',
  },
  advancedDetails: {
    title: 'Advanced Details',
    path: 'advanced-details',
  },
};

export const ACCOUNT_SUBJECTS = {
  overview: {
    title: 'Overview',
    path: 'overview',
  },
};

export const MY_COMPANY_SUBJECTS = {
  overview: {
    title: 'Overview',
    path: 'overview',
  },
  companyInfo: {
    title: 'Company Info',
    path: 'company-info',
  },
  teamMembers: {
    title: 'Team Members',
    path: 'team-members',
  },
  rates: {
    title: 'Rates',
    path: 'rates',
  },
  subscription: {
    title: 'Subscription',
    path: 'subscription',
  },
};

export const getProjectSubjectsByRole = (role) => {
  switch (role) {
    case ROLES_IDS.ICCoordinator:
    case ROLES_IDS.ICUnderwriter:
      return {
        overview: {
          title: 'Overview',
          path: 'overview',
        },
        detailsAndLocation: {
          title: 'Project Details & Location',
          path: 'details-location',
        },
        documentsAndNotes: {
          title: 'Documents & Notes',
          path: 'documents-notes',
        },
        budgetAndFeeProposal: {
          title: 'Budget & Fee Proposal',
          path: 'budget-fee-proposal',
        },
        contactsAndResources: {
          title: 'Contacts & Resources',
          path: 'contacts-resources',
        },
        recommendationsAndClaimsAndAnalytics: {
          title: 'Recommendations, Claims & Analytics',
          path: 'recommendations-claims-analytics',
        },
      };
    case ROLES_IDS.RCCoordinator:
      return {
        overview: {
          title: 'Overview',
          path: 'overview',
        },
        detailsAndLocation: {
          title: 'Project Details & Location',
          path: 'details-location',
        },
        documentsAndNotes: {
          title: 'Documents & Notes',
          path: 'documents-notes',
        },
        contactsAndResources: {
          title: 'Contacts & Resources',
          path: 'contacts-resources',
        },
        inspectionsAndTE: {
          title: 'Inspections & Time and Expenses',
          path: 'inspections-time-and-expenses',
        },
        recommendationsAndClaimsAndAnalytics: {
          title: 'Recommendations, Claims & Analytics',
          path: 'recommendations-claims-analytics',
        },
      };
    case ROLES_IDS.RiskEngineer:
      return {
        overview: {
          title: 'Overview',
          path: 'overview',
        },
        detailsAndLocation: {
          title: 'Project Details & Location',
          path: 'details-location',
        },
        documentsAndNotes: {
          title: 'Documents & Notes',
          path: 'documents-notes',
        },
        contacts: {
          title: 'Contacts',
          path: 'contacts',
        },
        inspectionsAndTE: {
          title: 'Inspections & Time and Expenses',
          path: 'inspections-time-and-expenses',
        },
        reportNotesAndRecommendationsAndClaims: {
          title: 'Report Notes, Recommendations & Claims',
          path: 'report-notes-recommendations-claims',
        },
        analyticsAndReports: {
          title: 'Analytics & Reports',
          path: 'analytics-reports',
        },
      };
    case ROLES_IDS.RiskManager:
      return {
        overview: {
          title: 'Overview',
          path: 'overview',
        },
        detailsAndLocation: {
          title: 'Project Details & Location',
          path: 'details-location',
        },
        documents: {
          title: 'Documents',
          path: 'documents',
        },
        recommendationsAndClaimsAndAnalytics: {
          title: 'Recommendations, Claims & Analytics',
          path: 'recommendations-claims-analytics',
        },
      }; case ROLES_IDS.DeveloperOwner:
      return {
        overview: {
          title: 'Overview',
          path: 'overview',
        },
        detailsAndLocation: {
          title: 'Project Details & Location',
          path: 'details-location',
        },
        documents: {
          title: 'Documents',
          path: 'documents',
        },
        recommendationsAndClaimsAndAnalytics: {
          title: 'Recommendations, Claims & Analytics',
          path: 'recommendations-claims-analytics',
        },
      };
    case ROLES_IDS.InsuranceRepresentative:
    case ROLES_IDS.Broker:
      return {
        overview: {
          title: 'Overview',
          path: 'overview',
        },
        detailsAndLocation: {
          title: 'Project Details & Location',
          path: 'details-location',
        },
        documentsAndContacts: {
          title: 'Documents & Contacts',
          path: 'documents-contacts',
        },
        recommendationsAndClaimsAndAnalytics: {
          title: 'Recommendations, Claims & Analytics',
          path: 'recommendations-claims-analytics',
        },
      };
    case ROLES_IDS.SiteContact:
      return {
        overview: {
          title: 'Overview',
          path: 'overview',
        },
      };
    default: return [];
  }
};

export const getNextPageInfo = (role, currentPagePath) => {
  const subjects = getProjectSubjectsByRole(role);

  const sectionsArray = Object.values(subjects);

  const currentIndex = sectionsArray.findIndex(({ path }) => currentPagePath === path);
  const nextPageInfo = sectionsArray[currentIndex + 1];

  return nextPageInfo || { isCurrentPageLast: true };
};

export const getFolderByRole = (role) => {
  switch (role) {
    case ROLES_IDS.ICCoordinator:
    case ROLES_IDS.ICUnderwriter:
      return 'ICCoordinator';
    case ROLES_IDS.RCCoordinator:
    case ROLES_IDS.RiskEngineer:
      return 'RiskEngineer';
    case ROLES_IDS.RiskManager:
    case ROLES_IDS.DeveloperOwner:
    case ROLES_IDS.InsuranceRepresentative:
    case ROLES_IDS.Broker:
      return 'Broker';
    default:
      return 'SiteContact';
  }
};
