import React from 'react';

const Dashboard = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.188 14.75V2.375h-4.5V14.75h-2.25V6.875h-4.5v7.875H1.25V.125H.125V14.75a1.125 1.125 0 001.125 1.125h14.625V14.75h-1.688zm-7.876 0h-2.25V8h2.25v6.75zm6.75 0h-2.25V3.5h2.25v11.25z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Dashboard;
