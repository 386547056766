import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { FormikField, Select, Input } from 'components';
import { requiredString } from 'utils/validation/customTypes';
import { SUBSCRIPTION_CANCELLATION_REASONS } from 'subscriptions/constants';

import { StyledFormDialog, FieldContainer, Root } from './styles';

const validationSchema = Yup.object().shape({
  reason: requiredString,
  customReason: Yup.string().when('reason', {
    is: (reason) => reason === 'Other',
    then: Yup.string().required('Field is required'),
  }),
  contactName: requiredString,
  contactRole: requiredString,
  contactEmail: requiredString,
  contactPhoneNumber: requiredString,
  feedback: requiredString,
});

const RequestToCancelForm = ({ isOpen, onClose, onSubmit, isView, data }) => {
  const reasonOptions = SUBSCRIPTION_CANCELLATION_REASONS.map((reason) => ({ value: reason, label: reason }));
  const [values, setValues] = useState(isView ? data : {});

  useEffect(() => {
    if (isView) {
      setValues(data);
    }
  }, [isView]);

  return (
    <StyledFormDialog
      title="Request to Cancel"
      submitButtonTitle="Send"
      open={isOpen}
      onCancel={onClose}
      onSubmit={onSubmit}
      onClose={onClose}
      validationSchema={validationSchema}
      initialValues={isView ? data : {}}
      onFormChange={setValues}
      enableReinitialize
      noButtons={isView}
      noCancel={isView}
    >
      <Root>
        <FieldContainer>
          {isView ? (
            <FormikField
              component={Input}
              maxLength={150}
              name="reason"
              label="Reason"
              required
              readOnly={isView}
            />
          )
            : (
              <FormikField
                component={Select}
                name="reason"
                label="Select the reason for your cancellation"
                required
                options={reasonOptions}
                readOnly={isView}
              />
            )}
        </FieldContainer>
        {values?.reason === 'Other' && (
          <FieldContainer>
            <FormikField
              component={Input}
              maxLength={150}
              name="customReason"
              label="Please describe"
              required
              readOnly={isView}
            />
          </FieldContainer>
        )}

        <FieldContainer>
          <FormikField
            component={Input}
            name="contactName"
            label="Name"
            required
            readOnly={isView}
          />
        </FieldContainer>
        <FieldContainer>
          <FormikField
            component={Input}
            name="contactRole"
            label="Company Role"
            required
            readOnly={isView}
          />
        </FieldContainer>
        <FieldContainer>
          <FormikField
            component={Input}
            name="contactEmail"
            label="Contact Email"
            required
            type="email"
            readOnly={isView}
          />
        </FieldContainer>
        <FieldContainer>
          <FormikField
            component={Input}
            name="contactPhoneNumber"
            label="Best Contact Phone Number"
            required
            options={reasonOptions}
            type="phone"
            readOnly={isView}
          />
        </FieldContainer>
        <FieldContainer>
          <FormikField
            component={Input}
            name="feedback"
            label="Feedback"
            required
            rows={3}
            readOnly={isView}
          />
        </FieldContainer>
      </Root>
    </StyledFormDialog>
  );
};

export default RequestToCancelForm;
