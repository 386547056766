import AnalyticService from 'projects/api/AnalyticsService';

const GET_ANALYTICS_ACCESS = 'GET_ANALYTICS_ACCESS';
const GET_RISK_SCORE = 'GET_RISK_SCORE';
const GET_IMPROVEMENTS = 'GET_IMPROVEMENTS';

export const hasAccess = (params) => ({
  type: GET_ANALYTICS_ACCESS,
  apiCall: () => AnalyticService.hasAccess(params),
});

export const getRiskScore = (params) => ({
  type: GET_RISK_SCORE,
  apiCall: () => AnalyticService.getRiskScore(params),
});

export const getImprovements = (params) => ({
  type: GET_IMPROVEMENTS,
  apiCall: () => AnalyticService.getImprovements(params),
});

const initialState = {
  hasAccess: {
    data: false,
  },
  riskScore: {
    data: [],
  },
  improvements: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_ANALYTICS_ACCESS: {
      return {
        ...state,
        hasAccess: {
          ...state.hasAccess,
          ...response,
        },
      };
    }

    case GET_RISK_SCORE: {
      return {
        ...state,
        riskScore: {
          ...state.riskScore,
          ...response,
        },
      };
    }

    case GET_IMPROVEMENTS: {
      return {
        ...state,
        improvements: {
          ...state.improvements,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
