import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 370px;

  > img {
    max-width: 560px;
    max-height: 350px;
    border: solid var(--imageBorder) 20px;
    border-radius: 10px;
  }
`;
