import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  font-family: Graphik LC;
  font-style: normal;
  font-size: 16px;
  color: var(--textColor);
  width: 100%;
`;

export const Title = styled.div`
  margin: 10px 27px;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const TextSection = styled.div`
  display: inline;
  flex-direction: column;
  max-width: 650px;
  margin: 5px 27px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
`;

export const TextButton = styled.span`
  margin-left: 5px;
  ${({ withMarginRight }) => withMarginRight && 'margin-right: 5px'};
  cursor: pointer;
  color: var(--sidebarSelectedIconBackground);

  &&:hover {
    color: var(--iconHover);
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  margin: 5px 27px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;

  > li {
    display: list-item;
  }
`;

export const HighlightedText = styled.span`
  font-weight: 800;
  margin-right: 3px;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;
