import { Select } from 'components';
import styled from 'styled-components';

export const HeaderPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  margin: 0;
  font-family: Avenir;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: var(--titleColor);
  white-space: nowrap;
  text-transform: none;
`;

export const Root = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: 48%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

  > div {
    margin-left: 10px;
  }
`;

export const Subscribers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 17px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding-top: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    width: 50%;
  }
`;
