import qs from 'qs';
import ApiClient from 'services/ApiClient';

import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class ClaimService extends ApiClient {
  getClaimsList({ projectId, page = 1, pageSize = PAGE_SIZE, sortBy }) {
    const query = {
      projectId,
      page,
      pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    return this.call({
      method: 'get',
      url: `/claims?${qs.stringify(query)}`,
    });
  }

  getClaim(id) {
    return this.call({
      method: 'get',
      url: `/claims/${id}`,
    });
  }

  addClaim(claimData) {
    return this.call({
      method: 'post',
      url: '/claims',
      data: {
        ...claimData,
      },
    });
  }

  updateClaim(claimData) {
    return this.call({
      method: 'put',
      url: '/claims',
      data: {
        ...claimData,
      },
    });
  }

  deleteClaim(id) {
    return this.call({
      method: 'delete',
      url: `/claims/${id}`,
    });
  }

  getCausesOfLoss() {
    return this.call({
      method: 'get',
      url: '/claim-causes-of-loss',
    });
  }

  addCausesOfLoss({ value }) {
    return this.call({
      method: 'post',
      url: '/claim-causes-of-loss',
      data: {
        value,
      },
    });
  }

  editCausesOfLoss({ id, value }) {
    return this.call({
      method: 'put',
      url: '/claim-causes-of-loss',
      data: {
        id,
        value,
      },
    });
  }
}

export default new ClaimService();
