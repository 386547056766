import GlobalEcosystemService from 'globalEcosystem/api/GlobalEcosystemService';

const GET_GLOBAL_SUMMARY = 'GET_GLOBAL_SUMMARY';
const GET_GLOBAL_FILTERS = 'GET_GLOBAL_FILTERS';
const GET_GLOBAL_CHARTS = 'GET_GLOBAL_CHARTS';

export const getSummary = (params) => ({
  type: GET_GLOBAL_SUMMARY,
  apiCall: () => GlobalEcosystemService.getSummary(params),
});

export const getFilters = (params) => ({
  type: GET_GLOBAL_FILTERS,
  apiCall: () => GlobalEcosystemService.getFilters(params),
});

export const getCharts = (params) => ({
  type: GET_GLOBAL_CHARTS,
  apiCall: () => GlobalEcosystemService.getCharts(params),
});

const initialState = {
  summary: {},
  filters: {},
  charts: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_GLOBAL_SUMMARY: {
      return {
        ...state,
        summary: {
          ...state.summary,
          ...response,
        },
      };
    }
    case GET_GLOBAL_FILTERS: {
      return {
        ...state,
        filters: {
          ...response,
        },
      };
    }
    case GET_GLOBAL_CHARTS: {
      return {
        ...state,
        charts: {
          ...state.charts,
          ...response,
        },
      };
    }
    default:
      return state;
  }
};
