import styled from 'styled-components';

import { MenuItem, Menu } from '@mui/material';

export const PageNumber = styled.span`
  display: flex;
  align-items: center;
  width: 48px;
  margin-top: 1px;
  padding-left: 10px;
  border-bottom: 1px solid transparent;
  color: var(--textColor);

  ${({ open }) => (open && `
    border-radius: 4px 4px 0 0;
    background-color: var(--sidebarSelectedBackground);
    border-bottom: 1px solid var(--disabledCheckbox);
  `)}
`;

export const StyledMenuItem = styled(MenuItem)`
  width: 48px;
  padding: 4px 10px;
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border-radius: 0 0 4px 4px;
    box-shadow: none;
  }
`;
