import React, { useState } from 'react';

import DocumentService from 'projects/api/DocumentService';
import { IconButton } from 'components';
import CloseButton from 'components/buttons/CloseButton';
import {
  AttachmentRoot, NameWithIconsWrapper, StyledDialog, ImageWrapper,
  UnderscoredButton, StyledImage, PaperClip, PreviewWrapper,
} from './styles';

const stopPropagation = (event) => {
  event.stopPropagation();
};

const OpenAttachmentButton = ({ children, preview, fileInfo, icon, attachedName, withIcons }) => {
  const [opened, setOpened] = useState(false);
  const isImage = fileInfo?.name?.split('.').pop() !== 'pdf';

  async function getPDFUrl() {
    const pdfUrl = await DocumentService.downloadFile(fileInfo.url, fileInfo.name, true);
    return pdfUrl;
  }

  const handleCloseDialog = () => setOpened(false);

  const handleOpenClick = async () => {
    const res = await getPDFUrl();

    if (isImage) {
      setOpened(true);
      return;
    }

    if (!isImage && res) {
      window.open(res);
    }
  };

  if (!fileInfo) return null;

  return (
    <AttachmentRoot onClick={stopPropagation}>
      {preview && <PreviewWrapper onClick={handleOpenClick}>{children}</PreviewWrapper>}
      {attachedName && fileInfo && fileInfo.name && (
        <NameWithIconsWrapper onClick={handleOpenClick}>
          {withIcons && (
            <PaperClip
              noWrapper
              icon="paperClip"
              color="primary"
            />
          )}
          <UnderscoredButton>
            {fileInfo.name}
          </UnderscoredButton>
        </NameWithIconsWrapper>
      )}

      {icon && (
        <IconButton
          noWrapper
          icon={icon}
          color="primary"
          transparent
          onClick={handleOpenClick}
        />
      )}

      {opened && isImage && (
        <StyledDialog
          fullScreen
          open={opened}
        >
          <CloseButton onClick={handleCloseDialog} top="10px" />
          <ImageWrapper onClick={handleCloseDialog}>
            <StyledImage src={fileInfo.url} alt="" onClick={stopPropagation} />
          </ImageWrapper>
        </StyledDialog>
      )}
    </AttachmentRoot>
  );
};

export default OpenAttachmentButton;
