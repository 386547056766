import ApiClient from 'services/ApiClient';
import qs from 'qs';

import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class DashboardReportsService extends ApiClient {
  getDashboardReports({ page = 1, pageSize = PAGE_SIZE, sortBy, searchFilter }) {
    const query = {
      page,
      pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    if (searchFilter) {
      query.ProjectName = searchFilter;
    }

    return this.call({
      method: 'get',
      url: `/dashboard/reports?${qs.stringify(query)}`,
    });
  }

  approveReport(id) {
    return this.call({
      method: 'put',
      url: `/reports/main/${id}/approve`,
      data: { id },
    });
  }

  rejectReport(data) {
    return this.call({
      method: 'put',
      url: '/reports/main/reject',
      data,
    });
  }

  getReportsComments(id) {
    return this.call({
      method: 'get',
      url: `/reports/main/${id}/comments`,
    });
  }

  sendReportsComment(id, text) {
    return this.call({
      method: 'post',
      url: `/reports/main/${id}/comments`,
      data: {
        id,
        text,
      },
    });
  }
}

export default new DashboardReportsService();
