import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const DocumentsNotifications = ({ notify, isLayouts, isDelete }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: {
      projectId, projectName, policyNumber, folderId,
      userFullName, fileName, numberOfFiles,
    },
  } = notify;

  const handleClose = () => dispatch(NotificationsActions.setOpenedNotification(null));

  const closeNotificationList = () => dispatch(NotificationsActions.setNotificationsView(''));

  const redirectToSpecialFolder = async () => {
    handleClose();
    closeNotificationList();

    navigate(`/projects/${projectId}/documents?folderId=${folderId}`);
  };

  const getValues = () => {
    if (isDelete) {
      if (numberOfFiles === '1') {
        return [fileName, userFullName, projectName, policyNumber];
      }
      return [numberOfFiles, userFullName, projectName, policyNumber];
    }

    if (numberOfFiles === '1') {
      return [projectName, policyNumber, userFullName, fileName];
    }
    return [numberOfFiles, projectName, policyNumber, userFullName];
  };

  const getText = () => {
    if (numberOfFiles === '1') {
      if (isLayouts) {
        return `Please be notified that a new layout map was uploaded to {placeholder}, with policy number {placeholder}.
        The layout plan was uploaded by {placeholder} and was named {placeholder}. This will be available for your use during any future inspections.`;
      }
      if (isDelete) {
        return `Please be notified that the document stored under {placeholder} has been deleted by {placeholder}
          from {placeholder} with policy number {placeholder}. This document will no longer be available to view.`;
      }
      return `Please be notified that a new document was uploaded to {placeholder}, with policy number {placeholder}.
        The document was uploaded by {placeholder} and was named {placeholder}.`;
    }

    if (isLayouts) {
      return `Please be notified that {placeholder} layout plans were uploaded to {placeholder}, with policy number {placeholder}.
      The layout plans were uploaded by {placeholder}. This will be available for your use during any future inspections.`;
    }
    if (isDelete) {
      return `Please be notified that {placeholder} documents have been deleted by {placeholder} from {placeholder}
      with policy number {placeholder}. These documents will no longer be available to view.`;
    }

    return `Please be notified that {placeholder} documents were uploaded to {placeholder}, with policy number {placeholder}.
      The documents were uploaded by {placeholder}.`;
  };

  const getTitle = () => {
    if (isLayouts) return 'New Layout Map';
    if (isDelete) return 'Documents Deleted';
    return 'Documents Uploaded';
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title={getTitle()}
      cancelButtonTitle="Close"
      confirmButtonTitle={isLayouts ? 'View Layouts' : 'View Documents'}
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToSpecialFolder}
    >
      <ConfirmationText
        value={getValues()}
        text={getText()}
      />
    </ConfirmationDialog>
  );
};

export default DocumentsNotifications;
