import styled from 'styled-components';

export const StepsRoot = styled.div`
  display: flex;
  width: 98%;
  max-width: 512px;
  margin-top: 2%;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 33%;
`;

export const NumberIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  background: #96CEF6;
  border-radius: 50%;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  color: var(--imageBorder);
`;

export const Title = styled.div`
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.5px;
  color: var(--textColor);
`;
