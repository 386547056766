import CommunicationService from 'communication/api/CommunicationService';

const GET_COMMUNICATION_LIST = 'GET_COMMUNICATION_LIST';
const ADD_COMMUNICATION = 'ADD_COMMUNICATION';
const UPDATE_COMMUNICATION = 'UPDATE_COMMUNICATION';
const GET_ACTIVE_COMMUNICATION = 'GET_ACTIVE_COMMUNICATION';

export const getCommunicationsList = (params) => (dispatch) => dispatch({
  type: GET_COMMUNICATION_LIST,
  apiCall: () => CommunicationService.getCommunicationsList(params),
});

export const addCommunication = (params) => ({
  type: ADD_COMMUNICATION,
  apiCall: () => CommunicationService.addCommunication(params),
});

export const updateCommunication = (params) => ({
  type: UPDATE_COMMUNICATION,
  apiCall: () => CommunicationService.editCommunication(params),
});

export const getActiveCommunication = (params) => ({
  type: GET_ACTIVE_COMMUNICATION,
  apiCall: () => CommunicationService.getActiveCommunication(params),
});

const initialState = {
  current: {},
  sortBy: null,
  communicationsList: {
    items: [],
  },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_COMMUNICATION_LIST: {
      return {
        ...state,
        communicationsList: {
          ...state.communicationsList,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
