import React, { useState, useEffect, Fragment } from 'react';

import PageLayout from 'layouts/PageLayout';

import FoldersList from '../../components/FoldersList';
import FilesList from '../../components/FilesList';
import { HeaderPanel, Title } from './styles';
import LibraryService from '../../api/LibraryService';
import AuthService, { ROLES_IDS } from '../../../../services/AuthService';
import { COMPANY_TYPES } from 'types/company';

const Library = () => {
  const [folders, setFolders] = useState([]);
  const { companyType, subscription, role } = AuthService.getUser();
  const canEditICFolder = companyType === COMPANY_TYPES.INSURANCE || role !== ROLES_IDS.RiskEngineer;
  const canCreateFolder = companyType === COMPANY_TYPES.INSURANCE || role !== ROLES_IDS.RiskEngineer || subscription !== 'FreeUsage';

  const getFolders = async () => {
    const data = await LibraryService.getFolders();
    setFolders(data.data.data);
  };

  useEffect(() => {
    getFolders();
  }, []);

  const permissionsFolder = {
    add: canCreateFolder,
    rename: canEditICFolder,
    delete: canEditICFolder,
    move: canEditICFolder,
    ownerRightsCheck: canEditICFolder,
  };
  const permissionsFile = {
    add: canEditICFolder,
    rename: canEditICFolder,
    delete: canEditICFolder,
    move: canEditICFolder,
    makePrivate: canEditICFolder,
    ownerRightsCheck: canEditICFolder,
    upload: canEditICFolder,
    layout: {
      delete: canEditICFolder,
      ownerRightsCheck: canEditICFolder,
      upload: canEditICFolder,
    },
    risks: {
      delete: canEditICFolder,
      upload: canEditICFolder,
    },
  };

  return (
    <>
      <PageLayout>
        <Fragment key="title">
          <HeaderPanel>
            <Title>Library</Title>
          </HeaderPanel>
        </Fragment>
        <Fragment key="content">
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
            <FoldersList onSuccess={getFolders} folders={folders} permissions={permissionsFolder} />
            <FilesList
              folders={folders}
              permissions={permissionsFile} />
          </div>
        </Fragment>
      </PageLayout>
    </>
  );
};

export default Library;
