import React, { useState } from 'react';

import Progress from 'modules/common/components/Progress';

export default function useProgress(isLayoutsFolder: boolean) {
  const [inProgress, setInProgress] = useState(false);
  const [currentProgressInfo, setCurrentProgressInfo] = useState({ name: '', size: '', count: 0, maxCount: 1 });
  const [progressValue, setProgressValue] = useState<number | number[]>(0);

  const handleDownloadProgress = (event: any) => {
    const { loaded, total } = event;
    const percentCompleted = Math.floor((loaded * 100) / total);

    setProgressValue(percentCompleted);
  };

  const handleMultiDownloadProgress = (event: any, { maxCount, size, index }: any) => {
    const { loaded, total } = event;
    const percentCompleted = Math.floor((loaded * 100) / size);

    if (loaded === total && currentProgressInfo.count < maxCount) {
      setCurrentProgressInfo((prevState) => ({ ...prevState, count: prevState.count + 1 }));
    }
    setProgressValue((prevState) => {
      const newProgress = Array.isArray(prevState) ? [...prevState] : [];
      newProgress[index] = percentCompleted;
      return newProgress;
    });
  };

  const renderProgress = () => {
    if (!inProgress) return null;
    return (
      <Progress
        currentProgressInfo={currentProgressInfo}
        progressValue={progressValue}
        isLayoutsFolder={isLayoutsFolder}
      />
    );
  };
  const clearProgress = () => {
    setInProgress(false);
    setProgressValue(0);
    setCurrentProgressInfo({ name: '', size: '', count: 0, maxCount: 1 });
  };

  return {
    inProgress,
    setInProgress,
    currentProgressInfo,
    setCurrentProgressInfo,
    progressValue,
    setProgressValue,
    handleDownloadProgress,
    handleMultiDownloadProgress,
    renderProgress,
    clearProgress,
  };
}
