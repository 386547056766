/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import React, { useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { CONTACT_MAX_LENGTHS } from 'utils/constants';
import { requiredString } from 'utils/validation/customTypes';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import * as MyCompanyActions from 'myCompany/store/companyInfo.reducer';
import MyCompanyService from 'myCompany/api/MyCompanyService';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import { getCountries } from 'projects/store/contacts.selectors';
import { Input, Select, FormikField, Button, Spinner, Tooltip } from 'components';
import UploadImage from 'common/components/UploadImage';

import { isDownloadingData } from 'myCompany/store/companyInfo.selectors';
import { Title, Column, Root, ContactInfo, ContactRow, StyledIcon, Row, ButtonWrapper } from './styles';
import RequestService from '../../../account/api/RequestService';

const validationSchema = Yup.object().shape({
  name: requiredString,
  address: Yup.object().shape({
    country: requiredString,
    state: requiredString,
    city: requiredString,
    addressLine: requiredString,
    zipCode: requiredString,
  }),
  phone: requiredString,
  primaryUserEmail: requiredString,
});

const reducerFunc = (prevState, newState) => ({
  ...prevState,
  ...newState,
});

const MAX_LOGO_FILE_SIZE = 102400;

const CompanyInfo = () => {
  const dispatch = useDispatch();
  const countriesData = useSelector(getCountries);
  const isDownloading = useSelector(isDownloadingData);
  const [state, setState] = useReducer(reducerFunc, {
    companyData: null,
    coordinatorsData: [],
    isEditable: false,
    isLoading: false,
    contactData: null,
    logoFileId: '',
    cancellationRequest: null,
  });

  const {
    companyData, isEditable, contactData, isLoading, coordinatorsData, logoFileId, cancellationRequest,
  } = state;

  const { role, subscription } = AuthService.getUser();
  const isRCC = role === ROLES_IDS.RCCoordinator;

  const loadCancellationRequest = async () => {
    const data = await RequestService.getCurrentSubRequestToCancelSubscription();
    setState({ cancellationRequest: data.data.data });
  };

  useEffect(() => {
    loadCancellationRequest();
  }, [companyData]);

  const fetchCompanyData = async () => {
    const { data: { data } } = isRCC
      ? await MyCompanyService.getRiskCompanyInfo()
      : await MyCompanyService.getInsuranceCompanyInfo();
    const { info, logoFileInfo, primaryContact } = data;
    const newCompanyData = { ...info, logoFileInfo, primaryUserEmail: primaryContact.email };

    setState({
      companyData: newCompanyData,
      contactData: primaryContact,
    });
  };

  const fetchCoordinatorsData = async () => {
    const { data: { data } } = isRCC
      ? await MyCompanyService.getRiskCompanyCoordinators()
      : await MyCompanyService.getInsuranceCompanyCoordinators();
    setState({ coordinatorsData: data });
  };

  useEffect(() => {
    fetchCompanyData();
    fetchCoordinatorsData();
  }, []);

  const hasSubsription = () => {
    return subscription != null && subscription !== 'FreeUsage';
  };

  const downloadCompanyData = async () => {
    if (!hasSubsription()) return;
    dispatch(MyCompanyActions.setIsDownloadingData(true));
    try {
      const res = await MyCompanyService.downloadData(isRCC);
      showNotifySuccess('Download is ready');
      dispatch(MyCompanyActions.setIsDownloadingData(false));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `iMitig8_${companyData.name}_data.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(MyCompanyActions.setIsDownloadingData(false));
      showNotifyError('Error downloading data. ');
    }
  };

  const getStateOptions = (selectedCountry) => {
    const states = countriesData.find((countryItem) => countryItem.name === selectedCountry)?.states || [];
    return states.map((countryState) => ({
      label: countryState,
      value: countryState,
    }));
  };
  const getStateComponent = (selectedCountry) => (['United States', 'United Kingdom'].includes(selectedCountry) ? Select : Input);
  const getStateLabel = (selectedCountry) => (selectedCountry === 'United States' ? 'State' : 'Region');
  const getZipCodeLabel = (selectedCountry) => (selectedCountry === 'United Kingdom' ? 'Postal Code' : 'Zip Code');
  const contactOptions = coordinatorsData.map(({ fullName, email }) => ({
    label: fullName, value: email,
  }));

  if (!companyData) {
    return (
      <Root>
        <Spinner />
      </Root>
    );
  }

  const handleSubmit = async (values, formikBag) => {
    try {
      setState({ isLoading: true });
      const updateValues = {
        ...values,
        logoFileId: logoFileId || '',
      };
      if (isRCC) {
        await MyCompanyService.updateRiskCompanyInfo(updateValues);
      } else {
        await MyCompanyService.updateInsuranceCompanyInfo(updateValues);
      }
      fetchCompanyData();
      setState({ isLoading: false, isEditable: false });
      showNotifySuccess('Your company info was successfully updated.');
    } catch (error) {
      const { fieldErrors } = error;
      if (fieldErrors) {
        if (fieldErrors.logoFileId) showNotifyError(fieldErrors.logoFileId);
        formikBag.setErrors(fieldErrors);
      } else {
        showNotifyError(error);
      }
      setState({ isLoading: false });
    }
  };

  const setCvFileId = ({ value }) => {
    setState({ logoFileId: value });
  };

  const renderCompanyInfoForm = ({ setFieldValue, submitForm, values, resetForm }) => {
    const countryOptions = countriesData.map((countryItem) => ({ label: countryItem.name, value: countryItem.name }));
    const { address: { country } } = values;
    const companyStateOptions = getStateOptions(country);
    const companyStateComponent = getStateComponent(country);
    const companyStateLabel = getStateLabel(country);
    const companyZipCodeLabel = getZipCodeLabel(country);

    const handleCountryChange = (value) => {
      setFieldValue('address.country', value);
      setFieldValue('address.state', '');
    };

    const handleContactChange = (value) => {
      setFieldValue('primaryUserEmail', value);
      const newCoordinator = coordinatorsData.find((coordinator) => coordinator.email === value);
      setState({ contactData: newCoordinator });
    };

    const handleCancelClick = () => {
      resetForm();
      setState({ isEditable: false });
    };

    const handleUpdateClick = () => {
      setState({ isEditable: true });
    };

    return (
      <Form>
        <Row>
          <FormikField
            component={Input}
            required
            name="name"
            label="Company Name"
            maxLength={CONTACT_MAX_LENGTHS.companyName}
            readOnly={!isEditable}
          />
        </Row>
        <Row>
          <FormikField
            component={Select}
            required
            name="address.country"
            label="Country"
            options={countryOptions}
            readOnly={!isEditable}
            onChange={handleCountryChange}
          />
        </Row>
        <Row>
          <FormikField
            component={Input}
            required
            name="address.addressLine"
            label="Address Line"
            maxLength={CONTACT_MAX_LENGTHS.address}
            readOnly={!isEditable}
          />
        </Row>
        <Row>
          <FormikField
            component={companyStateComponent}
            required
            disabled={!country}
            name="address.state"
            label={companyStateLabel}
            maxLength={CONTACT_MAX_LENGTHS.state}
            options={companyStateOptions}
            readOnly={!isEditable}
          />
          <FormikField
            component={Input}
            required
            name="address.city"
            label="City"
            maxLength={CONTACT_MAX_LENGTHS.city}
            readOnly={!isEditable}
          />
        </Row>
        <Row>
          <FormikField
            component={Input}
            required
            name="address.zipCode"
            label={companyZipCodeLabel}
            maxLength={CONTACT_MAX_LENGTHS.zipCode}
            readOnly={!isEditable}
          />
          <FormikField
            readOnly={!isEditable}
            component={Input}
            required
            name="phone"
            type="phone"
            phoneCountryMask={country}
            label="Phone"
          />
        </Row>

        <Row>
          <FormikField
            component={Select}
            required
            name="primaryUserEmail"
            label="Primary Contact"
            options={contactOptions}
            readOnly={!isEditable}
            onChange={handleContactChange}
          />
        </Row>

        {!isEditable && (
          <Button
            onClick={handleUpdateClick}
            label="submit"
            color="primary"
            variant="contained"
            formNoValidate
            text="Update"
          />
        )}
        {isEditable && (
          <ButtonWrapper>
            <Button
              onClick={handleCancelClick}
              label="cancel"
              variant="contained"
              formNoValidate
              text="Cancel"
            />
            <Button
              onClick={submitForm}
              label="submit"
              type="submit"
              color="primary"
              variant="contained"
              formNoValidate
              text="Save"
            />
          </ButtonWrapper>
        )}
      </Form>
    );
  };

  return (
    <Root>
      <Column>
        {isRCC && <Title>Company Details</Title>}
        <Formik
          initialValues={companyData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {isLoading ? <Spinner /> : renderCompanyInfoForm}
        </Formik>
      </Column>
      {contactData && (
        <Column>
          <Title>Primary Contact Details</Title>
          <ContactInfo>
            <div>
              {contactData.fullName}
            </div>
            <ContactRow>
              <StyledIcon icon="address" noWrapper />
              <span>{contactData.address}</span>
            </ContactRow>
            <ContactRow>
              <StyledIcon icon="phone" noWrapper />
              <span>{contactData.phone}</span>
            </ContactRow>
            <ContactRow>
              <StyledIcon icon="mail" noWrapper />
              <span>{contactData.email}</span>
            </ContactRow>
          </ContactInfo>

          <Title>
            Company Logo
            <Tooltip text="When the company logo is attached, it will be used in the generated reports." />
          </Title>
          <UploadImage
            name="logoFileId"
            setFieldsToChange={setCvFileId}
            fileInfo={companyData.logoFileInfo}
            disabled={!isEditable}
            showEmptyMessage
            onlyPicture
            previewImage
            maxSize={MAX_LOGO_FILE_SIZE}
          />

          {cancellationRequest && cancellationRequest.status === 'Approved' && cancellationRequest.isValid && (
            <ButtonWrapper>
              <Button
                onClick={downloadCompanyData}
                label="Download Company Data"
                variant="contained"
                formNoValidate
                text="Download Company Data"
                color="secondary"
                disabled={!hasSubsription() || isDownloading || !cancellationRequest.isValid}
                disabledTooltip={isDownloading ? 'Downloading data' : 'Feature available for subscribers only'}
                loading={isDownloading}
              />
            </ButtonWrapper>
          )}
        </Column>
      )}
    </Root>
  );
};

export default CompanyInfo;
