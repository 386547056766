import BudgetService from 'projects/api/BudgetService';

const GET_BUDGETS_LIST = 'GET_BUDGETS_LIST';
const GET_BUDGET_DATA = 'GET_BUDGET_DATA';
const ADD_BUDGET = 'ADD_BUDGET';
const GET_ALLOWANCES = 'GET_ALLOWANCES';

export const getBudgetsList = (projectId) => (dispatch) => dispatch({
  type: GET_BUDGETS_LIST,
  apiCall: () => BudgetService.getBudgetsList(projectId),
});

export const getBudgetData = (id) => ({
  type: GET_BUDGET_DATA,
  apiCall: () => BudgetService.getBudgetData(id),
});

export const addBudget = (data) => (dispatch) => dispatch({
  type: ADD_BUDGET,
  apiCall: () => BudgetService.addBudget(data),
});

export const getAllowances = () => (dispatch) => {
  return dispatch({
    type: GET_ALLOWANCES,
    apiCall: () => BudgetService.getAllowances(),
  });
};

const initialState = {
  current: {},
  budgetsList: {
    data: [],
  },
  allowances: {},
  addBudget: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_BUDGET_DATA: {
      return {
        ...state,
        current: {
          ...state.current,
          ...response,
        },
      };
    }

    case GET_BUDGETS_LIST: {
      return {
        ...state,
        budgetsList: {
          ...state.budgetsList,
          ...response,
        },
      };
    }
    case ADD_BUDGET: {
      return {
        ...state,
        addBudget: {
          ...state.addBudget,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
