import CompanyService from 'common/api/CompanyService';

const GET_COMPANY_BY_INVITE = 'GET_COMPANY_BY_INVITE';
const GET_COMPANY_SETTINGS_BY_PROJECT = 'GET_COMPANY_SETTINGS_BY_PROJECT';

export const getRiskCompanyByInvite = (inviteToken) => ({
  type: GET_COMPANY_BY_INVITE,
  apiCall: () => CompanyService.getRiskCompanyContactByInvite(inviteToken),
});

export const getInsuranceCompanySettingsByProject = (id) => ({
  type: GET_COMPANY_SETTINGS_BY_PROJECT,
  apiCall: () => CompanyService.getInsuranceCompanySettingsByProject(id),
});

export const getInsuranceCompanyByInvite = (inviteToken) => ({
  type: GET_COMPANY_BY_INVITE,
  apiCall: () => CompanyService.getInsuranceCompanyContactByInvite(inviteToken),
});

export const getBrokerCompanyByInvite = (inviteToken) => ({
  type: GET_COMPANY_BY_INVITE,
  apiCall: () => CompanyService.getBrokerCompanyByInvite(inviteToken),
});

const initialState = {
  companyByInvite: {},
  settings: { data: [] },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_COMPANY_BY_INVITE: {
      return {
        ...state,
        companyByInvite: {
          ...state.userByInvite,
          ...response,
        },
      };
    }
    case GET_COMPANY_SETTINGS_BY_PROJECT: {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...response,
        },
      };
    }
    default:
      return state;
  }
};
