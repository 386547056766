import React from 'react';
import { useDispatch } from 'react-redux';
import FormDialog from 'components/Dialogs/FormDialog';
import {
  FormikField,
  Input,
} from 'components';
import * as CommunicationSectionActions from 'communication/store/communicationSection.reducer';
import { pickBy } from 'lodash';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import * as Yup from 'yup';
import {
  Wrapper,
  Row,
} from './styles';
import { requiredString } from '../../../../../utils/validation/customTypes';

const initialAddValues = {
  title: '',
  description: '',
};

const AddSection = ({
  onCancel,
  isView,
  onClose,
  formTitle,
  isEdit,
  isAdd,
  onSuccess,
  communication,
}) => {
  const dispatch = useDispatch();
  const addNewSection = async (newSection) => {
    const response = await dispatch(CommunicationSectionActions.addCommunicationSection(newSection));

    return response;
  };

  const removeFalsyValues = (data) => pickBy(data, (value) => value || value === 0);

  const handleSubmit = async (formValues) => {
    formValues.communicationId = communication.id;

    const sendData = {
      ...formValues,
    };

    const newSection = removeFalsyValues(sendData);

    let response;
    if (isAdd) {
      response = await addNewSection(newSection);
    }
    if (response && response.status !== 400) {
      onClose();
      const successMessage = isEdit ? 'The time and expenses were successfully updated.' : 'Section succesfully';
      onSuccess();
      showNotifySuccess(successMessage);
    } else {
      const errorMessage = isEdit ? 'The time and expenses were successfully updated.' : 'Section was not added, please check the page number';
      showNotifyError(errorMessage);
    }
    return response;
  };

  const getFormProps = () => {
    return {
      submitButtonTitle: 'Save',
      noSubmit: false,
      onSubmit: handleSubmit,
      initialValues: initialAddValues,
    };
  };

  const { submitButtonTitle, onSubmit, initialValues } = getFormProps();

  function getValidations() {
    return Yup.object().shape({
      title: requiredString,
      description: requiredString,
    });
  }

  return (
    <>
      <FormDialog
        title={formTitle}
        submitButtonTitle={submitButtonTitle}
        onCancel={onCancel}
        noCancel={isView}
        onSubmit={onSubmit}
        onClose={onClose}
        initialValues={initialValues}
        isLoading={false}
        open
        noSubmit={false}
        validationSchema={getValidations}
            >
        <Wrapper>
          <Row>
            <FormikField
              component={Input}
              name="title"
              label="Title"
              required
                        />
          </Row>
          <Row>
            <FormikField
              component={Input}
              name="description"
              label="Description"
              required
                        />
          </Row>
        </Wrapper>
      </FormDialog>
    </>
  );
};

export default AddSection;
