export const WEATHER = [
  { label: 'Dry sunny', value: 'DrySunny' },
  { label: 'Rain', value: 'Rain' },
  { label: 'Windy', value: 'Windy' },
  { label: 'Overcast', value: 'Overcast' },
];

export const LETTERS = ['A', 'B', 'C', 'D', 'E'];

export const PLANS_MANAGEMENT = [
  {
    name: 'FireAndSecurity',
    label: 'Fire & Security',
    isGroup: true,
    items: [
      {
        name: 'FirePreventionProcedure',
        label: 'Fire Prevention Procedure',
      },
      {
        name: 'HotWorkProcedure',
        label: 'Hot Work Procedure',
      },
      {
        name: 'TemporaryFireExtinguisherProcedurePlan',
        label: 'Temporary Fire Extinguisher Procedure/Plan',
      },
      {
        name: 'TemporaryFuelStorageProcedure',
        label: 'Temporary Fuel Storage Procedure',
      },
      {
        name: 'TemporaryHeatingProcedure',
        label: 'Temporary Heating Procedure',
      },
      {
        name: 'HousekeepingProcedure',
        label: 'Housekeeping Procedure',
      },
      {
        name: 'MaterialStorageAndProtection',
        label: 'Material Storage & Protection',
      },
      {
        name: 'FireDeptDistance',
        label: 'Fire Dept – Distance',
        options: [
          {
            label: '<2 Miles - Very Good',
            value: 'Excellent',
          },
          {
            label: '5 miles - Good',
            value: 'VeryGood',
          },
          {
            label: '10 miles - Average',
            value: 'Good',
          },
          {
            label: '> 10 miles - Poor',
            value: 'Average',
          },
        ],
      },
      {
        name: 'FireDeptOrientation',
        label: 'Fire Dept Orientation',
      },
      {
        name: 'TemporaryGasStorage',
        label: 'Temporary Gas Storage',
      },
      {
        name: 'SecurityLogisticsProcedure',
        label: 'Security Logistics Procedure',
      },
    ],
  },
  {
    name: 'SafetyAndManagement',
    label: 'Safety & Management',
    isGroup: true,
    items: [
      {
        name: 'DocumentControl',
        label: 'Document Control',
      },
      {
        name: 'HoldPointInspectionProcedure',
        label: 'Hold Point Inspection Procedure',
      },
      {
        name: 'NonComplianceProcedure',
        label: 'Non-Compliance Procedure',
      },
      {
        name: 'ElectricalCommissioningProcedure',
        label: 'Electrical Commissioning Procedure',
      },
      {
        name: 'MaterialTestingAndInspections',
        label: 'Material Testing & Inspections',
      },
      {
        name: 'ThirdPartyInspections',
        label: 'Third Party Inspections',
      },
      {
        name: 'SignOffProcedure',
        label: 'Sign Off Procedure',
      },
      {
        name: 'NonConformancePlan',
        label: 'Non-Conformance Plan',
      },
      {
        name: 'TemporaryMonitoringMethods',
        label: 'Temporary Monitoring Methods',
      },
      {
        name: 'DemolitionProcedure',
        label: 'Demolition Procedure',
      },
      {
        name: 'CraneLiftingProcedure',
        label: 'Crane Lifting Procedure',
      },
      {
        name: 'ExcavationProcedure',
        label: 'Excavation Procedure',
      },
      {
        name: 'SiteSpecificSafetyPlan',
        label: 'Site Specific Safety Plan',
      },
      {
        name: 'VibrationMovementMonitoring',
        label: 'Vibration/Movement Monitoring',
      },
      {
        name: 'TrafficManagementProcedure',
        label: 'Traffic Management Procedure',
      },
      {
        name: 'BlastOrExplosivesProcedure',
        label: 'Blast / Explosives Procedure',
      },
      {
        name: 'SiteManagementProcedure',
        label: 'Site Management Procedure',
      },
      {
        name: 'TemporarySupportProcedure',
        label: 'Temporary Support Procedure',
      },
    ],
  },
  {
    name: 'WaterDamagePrevention',
    label: 'Water Damage Prevention',
    isGroup: true,
    items: [
      {
        name: 'WaterLeakPreventionProcedure',
        label: 'Water Leak Prevention Procedure',
      },
      {
        name: 'TemporaryProtectionProcedure',
        label: 'Temporary Protection Procedure',
      },
      {
        name: 'AutoWaterShutOff',
        label: 'Auto Water Shut Off',
      },
      {
        name: 'HydrostaticTestProcedureSignOff',
        label: 'Hydrostatic Test Procedure/Sign off',
      },
    ],
  },
  {
    name: 'NaturalHazards',
    label: 'Natural Hazards',
    isGroup: true,
    items: [
      {
        name: 'StormPreparednessProcedure',
        label: 'Storm Preparedness Procedure',
      },
      {
        name: 'FloodMitigationProcedure',
        label: 'Flood Mitigation Procedure',
      },
      {
        name: 'EarthquakeMitigationPlan',
        label: 'Earthquake Mitigation Plan',
      },
      {
        name: 'LandslideMitigationPlan',
        label: 'Landslide Mitigation Plan',
      },
      {
        name: 'LightningMitigationPlan',
        label: 'Lightning Protection',
      },
    ],
  },
  {
    name: 'NA',
    isGroup: true,
    items: [],
  },
];

export const PLANS_CONSTRUCTION = [
  {
    name: 'Fire',
    isGroup: true,
    items: [
      {
        name: 'SprinklerSystem',
        label: 'Temporary Sprinklers',
      },
      {
        name: 'OtherFireSuppression',
        label: 'Other Fire Suppression',
      },
      {
        name: 'SmokeSensors',
        label: 'Temporary Heat Sensor',
      },
      {
        name: 'compartmentation',
        type: 'Compartmentation',
        label: 'Compartmentation',
        options: [
          {
            label: '1-Hour',
            value: 'OneHour',
          },
          {
            label: '2-Hours',
            value: 'TwoHours',
          },
          {
            label: '3-Hours',
            value: 'ThreeHours',
          },
          {
            label: '4-Hours',
            value: 'FourHours',
          },
        ],
      },
      {
        name: 'treatedWoodFireProtection',
        type: 'TreatedWoodFireProtection',
        label: 'Treated Wood – Fire Protection',
        options: [
          {
            label: '0%',
            value: 'ZeroPercent',
          },
          {
            label: '<10%',
            value: 'LessThanTenPercent',
          },
          {
            label: '11%-25%',
            value: 'Between11And25Percent',
          },
          {
            label: '26%-50%',
            value: 'Between26And50Percent',
          }, {
            label: '51%-80%',
            value: 'Between51And80Percent',
          },
          {
            label: '100%',
            value: 'OneHundredPercent',
          },
          {
            label: 'N/A',
            value: 'NA',
          },
        ],
      },
    ],
  },
  {
    name: 'MechanicalAndElectrical',
    label: 'Mechanical & Electrical',
    isGroup: true,
    items: [
      {
        name: 'LeakPreventionDevice',
        label: 'Leak Prevention Device',
      },
      {
        name: 'ChillerPlantOrAHU',
        label: 'Chiller/AHU',
      },
      {
        name: 'CoolingTower',
        label: 'Cooling Tower',
      },
      {
        name: 'Transformers',
        label: 'Transformers',
      },
      {
        name: 'SwitchGears',
        label: 'Switch Gears',
      },
      {
        name: 'Ats',
        label: 'ATS',
      },
      {
        name: 'Inverter',
        label: 'Inverter',
      },
      {
        name: 'MeterBox',
        label: 'Meter Box',
      },
    ],
  },
  {
    name: 'OtherFeature',
    label: 'Other Feature',
    isGroup: true,
    items: [
      {
        name: 'buildingSeparation',
        type: 'BuildingSeparation',
        label: 'Building Separation',
        options: [
          {
            label: '< 5ft',
            value: 'Less5',
          },
          {
            label: '5ft - 10ft',
            value: 'From5To10',
          },
          {
            label: '11ft - 30ft',
            value: 'From11To30',
          },
          {
            label: '31ft - 50ft',
            value: 'From31To50',
          },
          {
            label: '51ft - 100ft',
            value: 'From51To100',
          },
          {
            label: '> 100 ft',
            value: 'More100',
          },
          {
            label: 'Unknown',
            value: 'Unknown',
          },
          {
            label: 'N/A',
            value: 'NA',
          },
        ],
      },
      {
        name: 'buildingElevation',
        type: 'BuildingElevation',
        label: 'Building Elevation',
        options: [
          {
            label: '< 5ft',
            value: 'Less5',
          },
          {
            label: '5ft - 10ft',
            value: 'From5To10',
          },
          {
            label: '11ft - 30ft',
            value: 'From11To30',
          },
          {
            label: '31ft - 50ft',
            value: 'From31To50',
          },
          {
            label: '51ft - 100ft',
            value: 'From51To100',
          },
          {
            label: '> 100 ft',
            value: 'More100',
          },
          {
            label: 'Unknown',
            value: 'Unknown',
          },
          {
            label: 'N/A',
            value: 'NA',
          },
        ],
      },
    ],
  }, {
    name: 'PlantAndEquipment',
    label: 'Plant & Equipment',
    isGroup: true,
    items: [
      {
        name: 'BackupGenerator',
        label: 'Generators',
      },
      {
        name: 'SolarPanels',
        label: 'Solar Panels',
      },
      {
        name: 'UpsBattery',
        label: 'UPS Battery',
      },
      {
        name: 'Pumps',
        label: 'Pumps',
      },
    ],
  },
  {
    name: 'Security',
    isGroup: true,
    items: [
      {
        name: 'securitySystem',
        type: 'SecuritySystem',
        label: 'Security System',
        multiple: true,
        clearWithNa: true,
        options: [
          {
            label: 'Fenced',
            value: 'Fenced',
          },
          {
            label: 'CCTV',
            value: 'CCTV',
          },
          {
            label: 'K9 Support',
            value: 'K9Support',
          },
          {
            label: 'Alarm',
            value: 'Alarm',
          },
          {
            label: 'Locked',
            value: 'Locked',
          },
          {
            label: 'Security Personnel',
            value: 'SecurityPersonnel',
          },
          {
            label: 'Flood Lights',
            value: 'FloodLights',
          },
          {
            label: 'Unknown',
            value: 'Unknown',
          },
          {
            label: 'N/A',
            value: 'NA',
          },
        ],
      },
    ],
  },
  {
    name: 'Substructure',
    isGroup: true,
    items: [
      {
        name: 'Basement',
        label: 'Basement',
      },
      {
        name: 'foundation',
        type: 'Foundation',
        label: 'Foundations',
        options: [
          {
            label: 'Strip',
            value: 'Strip',
          },
          {
            label: 'Raft (Slab-on-Grade)',
            value: 'Raft',
          },
          {
            label: 'Driven Pile',
            value: 'DrivenPile',
          },
          {
            label: 'CFA Pile',
            value: 'CFAPile',
          },
          {
            label: 'Unknown',
            value: 'Unknown',
          },
          {
            value: 'NA',
            label: 'NA',
          },
          {
            label: 'Add New +',
            value: 'New',
          },
        ],
      },
    ],
  },
  {
    name: 'SuperStructure',
    label: 'Super Structure',
    isGroup: true,
    items: [
      {
        name: 'frame',
        type: 'Frame',
        label: 'Frame',
        options: [
          {
            value: 'TypeIAFireResistiveNonCombustible',
            label: 'Type IA- Fire Resistive- Non-Combustible',
          },
          {
            value: 'TypeIBFireResistiveNonCombustible',
            label: 'Type IB- Fire Resistive- Non-Combustible',
          },
          {
            value: 'TypeIIAProtectedNonCombustible',
            label: 'Type IIA – Protected – Non-Combustible',
          },
          {
            value: 'TypeIIBUnProtectedNonCombustible',
            label: 'Type IIB – Unprotected – Non-Combustible',
          },
          {
            value: 'TypeIIIAProtectedCombustible',
            label: ' Type IIIA – Protected – Combustible',
          },
          {
            value: 'TYpeIIIBUnprotectedCombustible',
            label: ' Type IIIB – Unprotected – Combustible',
          },
          {
            value: 'TypeIVHeavyTimber',
            label: 'Type IV – Heavy Timber',
          },
          {
            value: 'TypeVAProtectedWoodFrame',
            label: 'Type VA – Protected Wood Frame',
          },
          {
            value: 'TypeVBUnprotectedWoodFrame',
            label: 'Type VB – Unprotected Wood Frame',
          },
        ],
      },
      {
        name: 'cladding',
        type: 'Cladding',
        label: 'Cladding',
        options: [
          {
            value: 'EFISPanel',
            label: 'EFIS Panel',
          },
          {
            value: 'PolystyreneStucco',
            label: 'Polystyrene – Stucco',
          },
          {
            value: 'CementBoard',
            label: 'Cement Board',
          },
          {
            value: 'Metal',
            label: 'Metal',
          },
          {
            value: 'UntreatedWood',
            label: 'Untreated Wood',
          },
          {
            value: 'RainScreenCurtinWall',
            label: 'Rain Screen / Curtin Wall',
          },
          {
            value: 'BrickVeneer',
            label: 'Brick Veneer',
          },
          {
            value: 'TileConcrete',
            label: 'Tile/Concrete',
          },
          {
            label: 'Add New +',
            value: 'New',
          },
        ],
      },
      {
        name: 'roof',
        type: 'Roof',
        label: 'Roof',
        options: [
          {
            value: 'CombustibleInsulatedPanel',
            label: 'Combustible Insulated Panel',
          },
          {
            value: 'MetalConcreteDeck',
            label: 'Metal Concrete Deck',
          },
          {
            value: 'TPO',
            label: 'TPO',
          },
          {
            value: 'RubberBitumen',
            label: 'Rubber/Bitumen',
          },
          {
            value: 'ConcreteTile',
            label: 'Concrete Tile',
          },
          {
            value: 'WoodTruss',
            label: 'Wood Truss',
          },
          {
            value: 'FeltShingles',
            label: 'Felt/Shingles',
          },
          {
            value: 'Unknown',
            label: 'Unknown',
          },
          {
            value: 'NA',
            label: 'NA',
          },
          {
            label: 'Add New +',
            value: 'New',
          },
        ],
      },
      {
        name: 'interiorFinishes',
        type: 'InteriorFinishes',
        label: 'Interior Finishes',
        options: [
          {
            label: 'Plasterboard/Drywall',
            value: 'PlasterboardDrywall',
          },
          {
            label: 'Exposed CLT',
            value: 'ExposedCLT',
          },
          {
            label: 'Tile/Metal/Concrete',
            value: 'TileMetalConcrete',
          },
          {
            label: 'Add New +',
            value: 'New',
          },
        ],
      },
    ],
  },
];

export const QA_QC_MANAGEMENT = {
  availability: [
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
    {
      label: 'Unknown',
      value: 'Unknown',
    },
    {
      label: 'N/A',
      value: 'NA',
    },
  ],
  quality: [
    {
      label: 'Excellent',
      value: 'Excellent',
    },
    {
      label: 'Very Good',
      value: 'VeryGood',
    },
    {
      label: 'Good',
      value: 'Good',
    },
    {
      label: 'Average',
      value: 'Average',
    },
    {
      label: 'Below Average',
      value: 'BelowAverage',
    },
    {
      label: 'Poor',
      value: 'Poor',
    },
    {
      label: 'Unknown',
      value: 'Unknown',
    },
    {
      label: 'N/A',
      value: 'NA',
    },
  ],
};
