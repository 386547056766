import styled from 'styled-components';

export const HeaderPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  margin: 0;
  font-family: Avenir;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: var(--titleColor);
  white-space: nowrap;
  text-transform: none;
`;

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavContainer = styled.div`
  display: flex;
`;
