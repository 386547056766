import React from 'react';
import { useSelector } from 'react-redux';

import { CONTACT_MAX_LENGTHS } from 'utils/constants';
import { getCountriesOptions, getCountries } from 'projects/store/contacts.selectors';
import { Input, FormikField, Select } from 'components';

import { Row, StyledForm, Title } from '../AddICForm/styles';

const AddBCForm = ({ values, setFieldValue }) => {
   const countriesData = useSelector(getCountries);
  const countryOptions = useSelector(getCountriesOptions);

  const { address = {} } = values;

  const getStateOptions = (selectedCountry) => {
    const states = countriesData.find((countryItem) => countryItem.name === selectedCountry)?.states || [];
    return states.map((countryState) => ({
      label: countryState,
      value: countryState,
    }));
  };

  const companyStateOptions = getStateOptions(address.country);
  const companyStateComponent = ['United States', 'United Kingdom'].includes(address.country) ? Select : Input;
  const companyStateLabel = address.country === 'United States' ? 'State' : 'Region';
  const companyZipCodeLabel = address.country === 'United Kingdom' ? 'Postal Code' : 'Zip Code';

  const handleCountryChange = (value) => {
    setFieldValue('address.country', value);
    setFieldValue('address.state', '');
  };

  return (
    <StyledForm>
      <FormikField
        component={Input}
        required
        name="companyName"
        label="Company Name"
        maxLength={CONTACT_MAX_LENGTHS.companyName}
      />
      <FormikField
        component={Select}
        required
        name="address.country"
        label="Country"
        options={countryOptions}
        onChange={handleCountryChange}
      />
      <FormikField
        component={Input}
        required
        name="address.addressLine"
        label="Address Line"
        maxLength={CONTACT_MAX_LENGTHS.address}
      />
      <Row>
        <FormikField
          component={companyStateComponent}
          required
          disabled={!address.country}
          name="address.state"
          label={companyStateLabel}
          maxLength={CONTACT_MAX_LENGTHS.state}
          options={companyStateOptions}
        />
        <FormikField
          component={Input}
          required
          name="address.city"
          label="City"
          maxLength={CONTACT_MAX_LENGTHS.city}
        />
      </Row>
      <Row>
        <FormikField
          component={Input}
          required
          name="address.zipCode"
          label={companyZipCodeLabel}
          maxLength={CONTACT_MAX_LENGTHS.zipCode}
        />
        <FormikField
          component={Input}
          required
          name="phone"
          type="phone"
          phoneCountryMask={address.country}
          label="Phone"
        />
      </Row>
      <FormikField
        component={Input}
        required
        name="location"
        label="Office Location"
        />
      <Title>
        Primary Contact
      </Title>
      <Row>
        <FormikField
          component={Input}
          required
          name="primaryUserName.firstName"
          label="First Name"
          maxLength={CONTACT_MAX_LENGTHS.name}
        />
        <FormikField
          component={Input}
          required
          name="primaryUserName.lastName"
          label="Last Name"
          maxLength={CONTACT_MAX_LENGTHS.name}
        />
      </Row>
      <FormikField
        component={Input}
        required
        name="primaryUserEmail"
        label="Email"
      />
    </StyledForm>
  );
};

export default AddBCForm;
