import styled from 'styled-components';
import { Form } from 'formik';
import Autocomplete from '@mui/lab/Autocomplete';

import { Icon } from 'components';

export const StyledAutocomplete = styled(Autocomplete)`
  & li.MuiAutocomplete-option {
    height: 40px;
  }
  & li.MuiAutocomplete-option[data-focus="true"] {
    background-color: rgba(115, 119, 140, 0.5);
  }

  > div {
    max-width: none;
  }
`;

export const StyledForm = styled(Form)`
  > div {
    margin-top: 24px;
  }
`;

export const ContactInfo = styled.div`
  border: 1px solid var(--inputReadOnlyBorder);
  border-radius: 4px;
  padding: 18px 20px;
  margin-top: 20px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--lightTextColor);

  > :first-child {
    margin-bottom: 8px;
    color: var(--textColor);
    font-weight: 600;
  }
`;

export const ContactRow = styled.div`
  height: 23px;
  display: flex;
  align-items: center;

  &&:not(:last-child) {
    margin-bottom: 5px;
  }

  > span {
    line-height: 14px;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 9px;

  svg > path {
    fill: var(--iconPrimaryColor);
  }

  &.user {
    margin-bottom: 3px;
  }
`;
