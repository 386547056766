import React from 'react';

const Receipt = () => {
  return (
    <svg width="27" height="27" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
      <path d="M17.859 13.348h1.63v1.63h-1.63v-1.63zm-9.783 0h6.522v1.63H8.076v-1.63zm9.783-3.261h1.63v1.63h-1.63v-1.63zm-9.783 0h6.522v1.63H8.076v-1.63zm0-3.261H19.49v1.63H8.076v-1.63z" fill="#fff" fillOpacity=".87" />
      <path d="M21.12 1.935H6.446a1.632 1.632 0 00-1.63 1.63v20.38a.815.815 0 00.815.816h.815a.815.815 0 00.652-.326l1.793-2.391 1.794 2.39a.843.843 0 001.304 0l1.794-2.39 1.793 2.39a.844.844 0 001.305 0l1.793-2.39 1.794 2.39a.814.814 0 00.652.327h.815a.815.815 0 00.815-.816V3.565a1.632 1.632 0 00-1.63-1.63zm0 20.651l-1.794-2.39a.845.845 0 00-1.304 0l-1.794 2.39-1.793-2.39a.846.846 0 00-1.305 0l-1.793 2.39-1.793-2.39a.845.845 0 00-1.305 0l-1.793 2.39V3.566H21.12v19.021z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Receipt;
