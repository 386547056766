import styled from 'styled-components';
import { ResponsiveContainer } from 'recharts';

import Select from 'components/Select';

export const StyledResponsiveContainer = styled(ResponsiveContainer)`
  max-width: ${({ maxWidth }) => (maxWidth || '500px')};
  max-height: ${({ maxHeight }) => (maxHeight || '300px')};
  margin: auto;
  padding-top: ${({ heightOffset }) => (heightOffset > 0 ? `${heightOffset}px` : '0')};
  ${({ alignTop }) => alignTop && `
    margin-top: 0;
    margin-bottom: auto;
  `}
`;

export const TooltipRoot = styled.div`
  z-index: 10;
  pointer-events: none;
  background-color: white;
  padding: 1rem;
  background: var(--chartTooltip);
  border-radius: 4px;
  box-shadow: 0px 1px 15px var(--backdrop);
`;

export const TooltipLabel = styled.div`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--titleColor);
`;

export const TooltipInfo = styled.div`
  display: flex;
  margin-top: 5px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: var(--textColor);
`;

export const TooltipValueWrapper = styled.span`
  display: flex;
  border-right: 1px solid #818289;
  padding-right: 10px;
  margin-right: 10px;
`;

export const TooltipDataName = styled.span`
  white-space: nowrap;
`;

export const TooltipValue = styled.span`
  margin-left: 6px;
  white-space: nowrap;
  color: var(--navBarSelectedTabTextColor);
`;

export const TooltipSeparator = styled.span`
  color: #818289;
  font-size: 12px;
  line-height: 14px;
`;

export const RightPanel = styled.div`
  position: relative;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  min-width: 194px;
  height: 100%;
  padding: 0;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const StyledSelect = styled(Select)`
  background: var(--sidebarBackground);
  && .MuiFilledInput-root {
    border-color: transparent;
  }
  & .MuiSelect-selectMenu {
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
    color: var(--titleColor);
    background: var(--sidebarBackground);
    border-radius: 6px;
    padding: 8px 30px 6px 16px;
  }
`;

const getColor = (index, colors) => {
  return colors[index];
};

export const DataName = styled.div`
  min-width: 180px;
  position: relative;
  display: flex;
  align-items: center;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--textColor);
  margin-bottom: 16px;

  &::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 4px;
    margin-right: 8px;
    background: ${({ colorIndex, colors }) => (getColor(colorIndex, colors))};
    border-radius: 2px;
  }
`;
