import React, { useState } from 'react';
import { Menu, MenuItem } from 'components';
import { showNotifySuccess } from 'services/toaster';
import CommunicationService from '../../api/CommunicationService';
import AddSection from '../../pages/Sections/AddSection/index';
import AddCommunication from '../../pages/Communications/AddCommunication/index';
import { ControlsWrapper } from './styles';

const CommunicationsControls = ({ communication, onSuccess }) => {
  const [sectionModalOpen, setSectionModalOpen] = useState(false);
  const [communicationModalOpen, setCommunicationModalOpen] = useState(false);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const deleteCommunication = () => {
    CommunicationService.deleteCommunication(communication.id)
      .then((response) => {
        showNotifySuccess('The communication  was successfully deleted.');
        onSuccess();
        return response;
      })
      .catch((error) => (error));
  };

  const handleSectionModalClose = () => {
    setSectionModalOpen(false);
  };

  const handleModalClose = () => {
    setCommunicationModalOpen(false);
  };

  return (
    <ControlsWrapper onClick={stopPropagation}>
      <Menu>
        <MenuItem icon="add" text="Add Section" onClick={() => { setSectionModalOpen(true); }} />
        <MenuItem icon="edit" text="Edit" onClick={() => { setCommunicationModalOpen(true); }} />
        <MenuItem icon="deleteSmall" text="Delete" onClick={deleteCommunication} />
      </Menu>

      {sectionModalOpen && (
        <AddSection
          isOpen
          onCancel={handleSectionModalClose}
          formTitle="Add Section"
          isEdit={false}
          isView={false}
          isAdd
          onClose={handleSectionModalClose}
          onSuccess={onSuccess}
          communication={communication}
        />
      )}

      {communicationModalOpen && (
        <AddCommunication
          isOpen
          onCancel={handleModalClose}
          formTitle="Edit Communication"
          isEdit
          isView={false}
          isAdd={false}
          onClose={handleModalClose}
          onSuccess={onSuccess}
          communication={communication}
        />
      )}
    </ControlsWrapper>
  );
};

export default CommunicationsControls;
