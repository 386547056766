import ApiClient from 'services/ApiClient';

class RequestService extends ApiClient {
  requestToCancelSubscription(data) {
    return this.call({
      method: 'post',
      url: '/requests/cancel-subscription',
      data,
    });
  }

  getRequestToCancelSubscription(id) {
    return this.call({
      method: 'get',
      url: `/requests/cancel-subscription/${id}`,
    });
  }

  getCurrentSubRequestToCancelSubscription() {
    return this.call({
      method: 'get',
      url: '/requests/current/cancel-subscription',
    });
  }

  approveRequest(data) {
    return this.call({
      method: 'put',
      url: '/requests/approve',
      data,
    });
  }

  rejectRequest(data) {
    return this.call({
      method: 'put',
      url: '/requests/reject',
      data,
    });
  }
}

export default new RequestService();
