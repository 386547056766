export function ratesList({ rates }) {
  return rates.ratesList || {};
}

export function expenseRatesSortBy({ rates }) {
  return rates.expenseSortBy;
}

export function getDesignations({ rates }) {
  return [...new Set(rates?.ratesList?.timeRates.map((t) => t.designation))];
}
