import styled from 'styled-components';
import { Form } from 'formik';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    width: 48%;
  }
`;

export const StyledForm = styled(Form)`
  > div {
    margin-top: 24px;
  }
`;

export const Title = styled.div`
  font-family: Avenir;
  font-weight: 900;
  line-height: 18px;
  color: var(--textColor);
`;
