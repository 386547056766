import React, { useState } from 'react';
import { List, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';

import { showNotifySuccess, showNotifyError } from 'services/toaster';
import LibraryService from 'library/api/LibraryService';
import { Icon, Menu, MenuItem } from 'components';
import ConfirmationText from 'components/Dialogs/ConfirmationText';
import { userInfo } from 'projects/store/users.selectors';

import { checkChildrensIds } from '../helpers';

import { ChildrenWrapper, StyledListItem, StyledItemIcon, StyledIconButton } from './styles';

function countNestedFiles(childrenFolders) {
  return childrenFolders
    .reduce((acc, folder) => acc + folder.childrenFiles.length + countNestedFiles(folder.childrenFolders), 0);
}

const Folder = (props) => {
  const {
    folder, selectedId, expandedByDefault, permissions, openedFolders, setOpenedFolders,
    onClick, onAddFolderClick, onDeleteClick, onRenameClick, onRefresh, projectClosed,
  } = props;

  const { id, name, childrenFolders = [], childrenFiles, isImmutable, creatorId } = folder;

  const user = useSelector(userInfo);

  const [draggedOver, setDraggedOver] = useState(false);
  const [expanded, setExpanded] = useState(expandedByDefault || openedFolders.includes(id));

  const isEmpty = childrenFolders.length === 0;
  const isSelected = id === selectedId;
  const icon = isEmpty ? 'folderEmpty' : 'folder';

  const isLayoutsFolder = folder && isImmutable && name === 'Layouts';
  const isRiskReviewFolder = folder && isImmutable && name === 'Risk Review Information';

  const filesCount = countNestedFiles(childrenFolders) + childrenFiles.length;
  let description = filesCount === 1 ? '1 file' : `${filesCount} files`;
  if (childrenFolders && childrenFolders.length) {
    description += `, ${childrenFolders.length} folders`;
  }

  let isOwner = true;
  if (permissions.ownerRightsCheck) {
    isOwner = creatorId === user.id;
  }

  const handleMoveFolder = async (folderId, folderName, targetFolderId) => {
    try {
      await LibraryService.moveFolder(folderId, targetFolderId);
      showNotifySuccess(`${folderName} folder was successfully moved to ${name} folder.`);
      onRefresh();
    } catch (error) {
      const { status, fieldErrors } = error;
      if (status === 400 && fieldErrors) {
        // {"fieldErrors":{"id":"Folder is already in target folder."}
        const errorMessage = Object.values(fieldErrors).join('\n');
        showNotifyError(errorMessage);
      } else {
        showNotifyError(`${folderName} folder can't be moved.`);
      }
    }
  };

  const handleMoveFile = async (fileId, fileName, targetFolderId) => {
    try {
      await LibraryService.moveFiles([fileId], targetFolderId);
      showNotifySuccess(`${fileName} file was successfully moved to ${name} folder.`);
      onRefresh();
    } catch (error) {
      const { status, fieldErrors } = error;
      if (status === 400 && fieldErrors) {
        // {"fieldErrors":{"ids":"Files are already in target folder."}
        showNotifyError(fieldErrors.ids || fieldErrors.folderId);
      } else {
        showNotifyError(error);
      }
    }
  };

  function toggleExpanded() {
    setExpanded(!expanded);
    if (!expanded) {
      setOpenedFolders((prevFolders) => [...prevFolders, id]);
    } else {
      setOpenedFolders((prevFolders) => prevFolders.filter((folderId) => folderId !== id));
    }
  }

  function handleFolderClick() {
    if (onClick) onClick(folder);
    if (!expanded) toggleExpanded();
  }

  function handleAddFolderClick() {
    if (onAddFolderClick) onAddFolderClick(id);
  }

  function handleRenameClick() {
    if (onRenameClick) onRenameClick(id, name);
  }

  function handleDeleteClick() {
    if (onDeleteClick) {
      const deleteConfirmationText = (
        <ConfirmationText
          value={name}
          text="Are you sure you want to delete {placeholder} folder? It will be deleted immediately, you can&apos;t undo this action."
        />
      );
      onDeleteClick(id, name, deleteConfirmationText);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    setDraggedOver(true);
  }

  function handleDragEnter(e) {
    e.preventDefault();
    setDraggedOver(true);
  }

  function handleDragLeave() {
    setDraggedOver(false);
  }

  function handleDrop(e) {
    setDraggedOver(false);
    const file = e.dataTransfer.getData('file');
    const srcFolder = e.dataTransfer.getData('folder');

    if (srcFolder) {
      const [folderId, folderName, folderIsImmutable] = srcFolder.split(':');

      if (folderId === id) return;
      if (folderIsImmutable) {
        showNotifyError('Default folders cannot be moved.');
        return;
      }
      handleMoveFolder(folderId, folderName, id);
    } else if (file) {
      const [fileId, fileName] = file.split(':');

      // Files are already in that selected folder.
      if (isSelected) return;

      handleMoveFile(fileId, fileName, id);
    }
  }

  const handleDragStart = (event) => {
    event.dataTransfer.setData('folder', `${id}:${name}:${isImmutable || ''}`);
  };

  const renderMenu = () => {
    if (isLayoutsFolder || isRiskReviewFolder) return null;
    if (!permissions.add && !permissions.rename && !permissions.delete) return null;

    return (
      <Menu dotsIcon={StyledIconButton}>
        {permissions.add && <MenuItem icon="plus" text="Add Folder" onClick={handleAddFolderClick} />}
        {permissions.rename && isOwner && !isImmutable && (
          <MenuItem icon="edit" text="Rename" onClick={handleRenameClick} />
        )}
        {permissions.delete && isOwner && !isImmutable && (
          <MenuItem icon="deleteSmall" text="Delete" onClick={handleDeleteClick} />
        )}
      </Menu>
    );
  };

  return (
    <>
      <StyledListItem
        key={name}
        button
        draggable
        className={draggedOver ? 'draggedOver' : ''}
        selected={isSelected}
        onClick={handleFolderClick}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragStart={handleDragStart}
      >
        <StyledItemIcon title={name} selected={isSelected}>
          <Icon icon={icon} title={name} />
        </StyledItemIcon>

        <ListItemText
          primary={name}
          secondary={description}
        />

        {!isEmpty && (
          <StyledIconButton
            icon={expanded ? 'chevronUp' : 'chevronDown'}
            onClick={toggleExpanded}
            transparent
          />
        )}

        {!projectClosed && renderMenu()}
      </StyledListItem>

      {!isEmpty && expanded && (
        <ChildrenWrapper>
          <List>
            {childrenFolders.map((childFolder) => {
              const isOpened = checkChildrensIds(childFolder, selectedId);

              return (
                <Folder
                  key={childFolder.id}
                  folder={childFolder}
                  selectedId={selectedId}
                  onClick={onClick}
                  onRefresh={onRefresh}
                  expandedByDefault={isOpened}
                  onAddFolderClick={onAddFolderClick}
                  onDeleteClick={onDeleteClick}
                  onRenameClick={onRenameClick}
                  permissions={permissions}
                  openedFolders={openedFolders}
                  setOpenedFolders={setOpenedFolders}
                  projectClosed={projectClosed}
                />
              );
            })}
          </List>
        </ChildrenWrapper>
      )}
    </>
  );
};

export default Folder;
