import React from 'react';

const ArrowFilled = () => {
  return (
    <svg width="8" height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.642.412a.3.3 0 01.51 0l3.359 5.43a.3.3 0 01-.255.458H.538a.3.3 0 01-.255-.458L3.642.412z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default ArrowFilled;
