import React from 'react';
import { formatCommonDate, formatDifferenceInDays } from 'utils/DateUtils';
import { ActionWrapper, ActionItem, ActionSubtitle, ActionData, DataName, ActionNumber, OverdueText } from './styles';

const RecommendationActions = ({ risksAwaiting = [] }) => {
  return (
    <>
      <ActionWrapper>
        {risksAwaiting.map((item, i) => {
          const { category, type, dueDateTime, number } = item;
          const { daysLeft, overdue } = formatDifferenceInDays(dueDateTime);

          return (
            <ActionItem key={i}>
              <ActionSubtitle>
                <ActionNumber>{number}</ActionNumber>
                <span>{`${category}, ${type}`}</span>
              </ActionSubtitle>
              <ActionData>
                <DataName>Due Date:&nbsp;</DataName>
                {` ${formatCommonDate(dueDateTime)}, `}
                {daysLeft && (
                  <>
                    <DataName>&nbsp;Time Left:&nbsp;</DataName>
                    {daysLeft}
                  </>
                )}
                {overdue && (
                  <>
                    <DataName>&nbsp;Overdue:&nbsp;</DataName>
                    <OverdueText>{overdue}</OverdueText>
                  </>
                )}
              </ActionData>
            </ActionItem>
          );
        })}
      </ActionWrapper>
    </>
  );
};

export default RecommendationActions;
