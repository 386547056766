import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectsNumberBlock = styled.div`
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  max-width: 260px;
  margin-top: 10px;

  ${({ visible }) => visible && `
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  `}

  ${({ error }) => error && 'padding-bottom: 40px'};
`;

export const Section = styled.div`
    background: var(--notificationsDayTitle);
    border-radius: 6px;
    margin-bottom: 24px;
    padding:  20px 24px 11px 24px;

    ${({ error }) => error && 'padding-bottom: 40px;'};
`;

export const RadioTitle = styled.div`
  margin-bottom: 5px;
  font-family: Avenir;
  font-weight: 900;
  letter-spacing: 0.16px;
  color: var(--textColor);
`;

export const TimeBlock = styled.div`
  margin-bottom: 15px;
`;

export const Text = styled.div`
  margin-bottom: 5px;
  letter-spacing: 0.16px;
  font-weight: 400;
  font-size: 14px;
  color: var(--lightTextColor);
`;

export const HighLightedText = styled.span`
  margin: 0 4px;
  font-size: 14px;
  font-weight: 500;
  color: var(--textColor);
`;

export const DateBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  
  > div {
    width: 48%;
  }
`;
