import styled from 'styled-components';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background-color: #1D1E24;
    padding-top: 30px;
  }
`;

export const PdfWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 20px;
`;
