import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import AuthService from 'services/AuthService';
import { getFolderByRole } from 'help/constants';

import { Title, TextSection, Underline } from 'help/pages/styles';

const AnalyticsSection = () => {
  const { role } = AuthService.getUser();
  const folder = getFolderByRole(role);

  const analyticsSrc = `projects/${folder}/projects-analytics.png`;

  return (
    <>
      <ImageContainer src={analyticsSrc} alt="analytics" />
      <Title>Analytics</Title>
      <TextSection>
        Analytics sub-section provides the analytical information about the project.
        The risk score calculated for the project is based on the data gathered from various
        project sections and demonstrates how high the risk is for the project.
      </TextSection>
      <TextSection>
        The risk scores for the project are calculated after the first inspection.
        The <Underline>current risk score</Underline> is
        constantly updated when the relevant changes are made to the project
        (e.g. recommendations are closed or overdue)
        whereas the <Underline>inspection risk score</Underline> is
        captured ones and is not recalculated so that the dynamic of the
        risks score can be tracked.
      </TextSection>
      <TextSection>
        Additionally, within that section you can find various visual charts showing the recommendations
        claims project data from different angles as well as find the information about how the
        risk score for the project can be improved.
      </TextSection>
    </>
  );
};

export default AnalyticsSection;
