import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationText from 'components/Dialogs/ConfirmationText';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';

const ClaimAlert = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: { projectId, projectName } } = notify;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedAlert(null));
  };

  const closeAlertList = () => {
    dispatch(NotificationsActions.setAlertsView(''));
  };

  const redirectToClaims = () => {
    handleClose();
    closeAlertList();
    navigate(`/projects/${projectId}/claims`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="New Insurance Claim"
      cancelButtonTitle="Close"
      confirmButtonTitle="View Claim"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToClaims}
    >
      <ConfirmationText
        value={projectName}
        text="Please be advised that a new insurance claim has been added for {placeholder}."
      />
    </ConfirmationDialog>
  );
};

export default ClaimAlert;
