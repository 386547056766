import React, { useState } from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import TeamMembersService from 'myCompany/api/TeamMembersService';
import { showNotifySuccess, showNotifyError } from 'services/toaster';
import { CONTACT_STATUSES } from 'utils/constants';
import InviteService from 'modules/notifications/api/InviteService';
import * as TeamMembersActions from 'myCompany/store/teamMembers.reducer';
import { userInfo } from 'projects/store/users.selectors';

import TeamMembersForm from 'myCompany/pages/TeamMembers/TeamMembersForm';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';
import { IconButton, Menu, MenuItem } from 'components';

import { ControlsWrapper } from './styles';
import LocationForm from '../LocationForm';
import MyCompanyService from '../../../api/MyCompanyService';

const stopPropagation = (event) => {
  event.stopPropagation();
};

function getDeleteConfirmationMessage() {
  return 'Are you sure you want to delete this location?';
}

const Controls = ({ location, onSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector(userInfo);
  const [deleteDialog, setDeleteDialog] = useState(null);
  const [editDialog, setEditDialog] = useState(null);
  const [resendDialog, setResendDialog] = useState(false);

  function handleLocationEditClick() {
    const { id, fullName } = location;
    setEditDialog({ id, fullName });
  }

  function handleContactDeleteClick() {
    const { id, fullName, status } = location;
    const deleteConfirmationText = (
      <ConfirmationText
        value={fullName}
        text={getDeleteConfirmationMessage()}
      />
    );
    setDeleteDialog({ id, fullName, deleteConfirmationText });
  }

  function closeEditDialog() {
    setEditDialog(null);
  }

  function closeDeleteDialog() {
    setDeleteDialog(null);
  }

  async function handleEditConfirm(values) {
    const response = await MyCompanyService.updateInsuranceCompanyLocationInfo(values);
    if (!response.fieldErrors) {
      showNotifySuccess('Location was successfully updated.');
      closeEditDialog();
      if (onSuccess) onSuccess();
    }

    return response;
  }

  async function handleDeleteConfirm() {
    const response = await MyCompanyService.deleteInsuranceCompanyLocationById(deleteDialog.id);
    if (!response.fieldErrors) {
      showNotifySuccess(`${deleteDialog.name} was successfully deleted from your company.`);
      closeEditDialog();
      if (onSuccess) onSuccess();
    }

    return response;
  }

  function handleResendConfirm() {
    InviteService.resendTeamMember(member.id)
      .then(() => {
        showNotifySuccess(`The invitation email was successfully sent to ${member.email}.`);
        closeDeleteDialog();
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => showNotifyError(error));
  }

  return (
    <ControlsWrapper onClick={stopPropagation}>
      <Menu>
        <MenuItem icon="edit" text="Edit" onClick={handleLocationEditClick} />
        {/* <MenuItem
          icon="deleteSmall"
          text="Delete"
          onClick={handleContactDeleteClick}
          /> */}
      </Menu>

      {deleteDialog && (
        <ConfirmationDialog
          open
          title="Delete Location"
          confirmButtonTitle="Delete"
          onClose={closeDeleteDialog}
          onCancel={closeDeleteDialog}
          onConfirm={handleDeleteConfirm}
        >
          {deleteDialog.deleteConfirmationText}
        </ConfirmationDialog>
      )}

      {editDialog && (
        <LocationForm
          isOpen
          mode="edit"
          onCancel={closeEditDialog}
          onSubmit={handleEditConfirm}
          onClose={closeEditDialog}
          location={location}
        />
      )}
    </ControlsWrapper>
  );
};

export default Controls;
