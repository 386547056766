import React from 'react';

import AuthService, { ROLES_IDS } from 'services/AuthService';
import StepperModal from 'onboarding/components/StepperModal';
import ModalPage from 'onboarding/components/ModalPage';
import RedirectMenu from 'onboarding/components/RedirectMenu';

import {
  overviewPage,
  locationPage,
  documentsPage,
  contactsPage,
  resourcesPage,
  recommendationsPage,
  analyticsPage,
  reportsPage,
} from '../constants';

const getFolderNameByRole = (role) => {
  switch (role) {
    case ROLES_IDS.ICCoordinator:
    case ROLES_IDS.ICUnderwriter:
      return 'ICCoordinator';
    case ROLES_IDS.RCCoordinator:
    case ROLES_IDS.RiskEngineer:
      return 'RiskEngineer';
    case ROLES_IDS.DeveloperOwner:
    case ROLES_IDS.RiskManager:
    case ROLES_IDS.Broker:
    case ROLES_IDS.InsuranceRepresentative:
      return 'Broker';
    default:
      return 'SiteContact';
  }
};

const getPagesByRole = (role) => {
  switch (role) {
    case ROLES_IDS.ICCoordinator:
    case ROLES_IDS.ICUnderwriter:
    case ROLES_IDS.RCCoordinator:
      return [
        overviewPage,
        locationPage,
        documentsPage,
        contactsPage,
        resourcesPage,
        recommendationsPage,
        analyticsPage,
      ];
    case ROLES_IDS.RiskEngineer:
      return [
        overviewPage,
        locationPage,
        documentsPage,
        contactsPage,
        recommendationsPage,
        analyticsPage,
        reportsPage,
      ];
    case ROLES_IDS.RiskManager:
    case ROLES_IDS.DeveloperOwner:
      return [
        overviewPage,
        documentsPage,
        contactsPage,
        recommendationsPage,
        analyticsPage,
      ];
    case ROLES_IDS.Broker:
    case ROLES_IDS.InsuranceRepresentative:
      return [overviewPage, documentsPage, recommendationsPage, analyticsPage];
    case ROLES_IDS.SiteContact:
      return [overviewPage, recommendationsPage, analyticsPage];
    default:
      return [];
  }
};

const ProjectsModal = ({ open, onCancel }) => {
  const role = AuthService.getRoleId();
  const pages = getPagesByRole(role);

  const renderStepContent = (activeStep) => {
    if (activeStep === pages.length) {
      return <RedirectMenu onCancel={onCancel} currentSection="projects" />;
    }
    const { title, src, description } = pages[activeStep];
    return (
      <ModalPage
        title={title}
        src={`projects/${getFolderNameByRole(role)}/${src}`}
        description={description}
      />
    );
  };

  return (
    <StepperModal
      renderStepContent={renderStepContent}
      numberOfSteps={pages.length + 1}
      open={open}
      onCancel={onCancel}
    />
  );
};

export default ProjectsModal;
