import React from 'react';
import { useDispatch } from 'react-redux';
import { DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';

import * as NotificationsActions from 'notifications/store/notification.reducer';

// note - dependency cycle in ActionButton (import ConfirmationDialog inside)
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import Checkbox from 'components/Checkbox';

import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  ProjectName,
  SubTitle,
  AdditionalWrapper,
  StyledSeparatedDialogActions,
} from './styles';

const NEGATIVE_LIST = ['Delete', 'Reject', 'Delete Forever'];

const ConfirmationDialog = ({
  title,
  projectName,
  children,
  open,
  borderedTitle,
  onClose,
  onConfirm,
  onCancel,
  onAdditionalAction,
  noCancel,
  maxWidth,
  noConfirm,
  withScroll,
  cancelButtonTitle = 'Cancel',
  confirmButtonTitle = 'Confirm',
  additionalActionTitle = 'Later',
  subTitle,
  noButtons,
  noMargin,
  animations,
  slideOut,
  disabledConfirm = false,
  addCheckBox,
  checkBoxTitle,
  onCheck,
  checked,
}) => {
  const dispatch = useDispatch();
  const isNegativeCancel = NEGATIVE_LIST.includes(cancelButtonTitle);
  const isNegativeConfirm = NEGATIVE_LIST.includes(confirmButtonTitle);

  const theme = useTheme();

  const animationEnd = () => {
    dispatch(NotificationsActions.setSlideOut(false));
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const renderDialogActions = () => {
    if (addCheckBox) {
      return !noButtons && (
      <StyledSeparatedDialogActions addCheckBox>
        <div>
          {addCheckBox && (
          <Checkbox
            label={checkBoxTitle}
            value={checked}
            onChange={onCheck}
            className="wrap-left" />
          )}
        </div>
        <div>{onAdditionalAction && (
        <AdditionalWrapper>
          <Button
            small
            onClick={onAdditionalAction}
            variant="outlined"
            text={additionalActionTitle}
            color="primary"
              />
        </AdditionalWrapper>
        )}
          {!noCancel && (
          <Button
            small
            onClick={onCancel}
            color={isNegativeCancel ? 'error' : 'secondary'}
            variant="contained"
            text={cancelButtonTitle}
            />
          )}
          {!noConfirm && (
          <Button
            small
            onClick={onConfirm}
            color={isNegativeConfirm ? 'error' : 'primary'}
            variant="contained"
            text={confirmButtonTitle}
            disabled={disabledConfirm}
            />
          )}
        </div>

      </StyledSeparatedDialogActions>
      );
    }
    return !noButtons && (
      <StyledDialogActions>
        {onAdditionalAction && (
        <AdditionalWrapper>
          <Button
            small
            onClick={onAdditionalAction}
            variant="outlined"
            text={additionalActionTitle}
            color="primary"
              />
        </AdditionalWrapper>
        )}
          {!noCancel && (
          <Button
            small
            onClick={onCancel}
            color={isNegativeCancel ? 'error' : 'secondary'}
            variant="contained"
            text={cancelButtonTitle}
            />
          )}
          {!noConfirm && (
          <Button
            small
            onClick={onConfirm}
            color={isNegativeConfirm ? 'error' : 'primary'}
            variant="contained"
            text={confirmButtonTitle}
            disabled={disabledConfirm}
            />
          )}
      </StyledDialogActions>
    );
  };

  return (
    <StyledDialog
      className={cx(theme.light && 'light-theme')}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={maxWidth}
      borderedTitle={borderedTitle}
      slideOut={slideOut}
      animations={animations}
      onAnimationEnd={animations ? animationEnd : null}
      withScroll={withScroll}
    >
      {projectName && <ProjectName>{projectName}</ProjectName>}

      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>

      <CloseButton onClick={onClose} />

      <StyledDialogContent noMargin={noMargin}>{children}</StyledDialogContent>

      {renderDialogActions()}
    </StyledDialog>
  );
};

export default ConfirmationDialog;
