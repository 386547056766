import React from 'react';

const CheckedIcon = () => (
  <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" rx="2" fill="currentColor" />
    <path d="M10.305 3.762L6.116 7.951l1.047 1.047 4.189-4.188-1.047-1.048z" fill="#fff" />
    <path d="M7.163 8.998l-3.14-3.141-1.048 1.047 3.141 3.141 1.047-1.047z" fill="#fff" />
  </svg>
);

export default CheckedIcon;
