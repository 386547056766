import React from 'react';

const Folder = () => {
  return (
    <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.063 18.375H3.938a1.314 1.314 0 01-1.313-1.313V7.22a1.314 1.314 0 011.313-1.313h3.718c.284 0 .56.093.788.263l2.275 1.706h6.344a1.314 1.314 0 011.312 1.313v7.874a1.314 1.314 0 01-1.313 1.313zM7.655 7.219h-3.72l.002 9.843h13.124V9.188h-6.78L7.655 7.22zm10.719-1.313h-6.781L8.969 3.938H3.938V2.625h5.03c.285 0 .561.093.789.263l2.274 1.706h6.344v1.312z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default Folder;
