import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import Icon from 'components/Icon';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1074px;
`;

export const FiltersButtonsBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 0 auto;

`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MainInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: 25px 0;
`;

export const CommonInfoBlock = styled.div`
  display: flex;
  justify-content:space-between;
  flex-direction: column;
  min-width: 280px;

  @media (min-width: 1920px) {
    min-width: 410px;
  }
`;

export const Item = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'auto',
  height: 80,
  background: theme.palette.background.default,
  borderRadius: 6,
}));

export const StyledIcon = styledMui(Icon)(({ iconColor }) => ({
  margin: '0 5px',
  path: {
    fill: iconColor,
  },
}));

export const TextBlock = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: 14,
  lineHeight: '19px',
  color: theme.palette.text.light,
}));

export const IconBlock = styledMui('div')(({ theme, color }) => ({
  width: 52,
  height: 52,
  borderRadius: 4,
  margin: '14px 20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: color,
  '*': {
    fill: theme.palette.sidebar.selected.icon,
  },
}));

export const FilterBlock = styled.div`
  max-height: 0;
  transition: all 0.15s ease-out;
  overflow: hidden;

  ${({ opened }) => opened && `
    max-height: 500px;
    margin-top: 24px;
    transition: all 0.25s ease-in;
  `}
`;

export const TooltipBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 14px 20px 14px auto;
`;

export const MapWrapper = styledMui('div')(({ theme }) => ({
  padding: '20px 24px',
  marginBottom: 24,
  background: theme.palette.background.default,
  borderRadius: 6,
}));
