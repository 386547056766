import styled from 'styled-components';

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--navTabsBorderColor);

  >:not(:last-child) {
    padding-right: 10px;
  }
`;
