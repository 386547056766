import React from 'react';

import { StyledCloseButton } from './styles';

const CloseButton = ({ onClick, top, right }) => (
  <StyledCloseButton
    icon="cross"
    transparent
    top={top}
    right={right}
    onClick={onClick}
  />
);

export default CloseButton;
