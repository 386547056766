import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatCommonDate, differenceInDays } from 'utils/DateUtils';
import * as SubscribersActions from 'subscriptions/store/subscriptions.reducer';
import { current } from 'subscriptions/store/subscriptions.selectors';
import { PACKAGE_STATUSES, getPackageName } from 'subscriptions/constants';
import { Button } from 'components';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';

import { showNotifyError } from 'services/toaster';
import { InfoWrapper, InfoDataWrapper, InfoText, InfoTextBold, InfoColumn, InfoTitle, SubTitle, StyledInfoCard, ButtonWrapper } from './styles';

import RequestToCancelForm from '../../../components/RequestToCancelForm/index';
import RequestService from '../../../api/RequestService';
import AuthService, { ROLES_IDS } from '../../../../../services/AuthService';
import MyCompanyService from '../../../../myCompany/api/MyCompanyService';
import { useTheme } from '@mui/material';

const Subscription = () => {
  const dispatch = useDispatch();
  const { role } = AuthService.getUser();
  const { email } = AuthService.getUserData();
  const [showRequestToCancel, setShowRequestToCancel] = useState(false);
  const [showRequestToCancelConfirmation, setShowRequestToCancelConfirmation] = useState(false);
  const [cancelRequest, setCancelRequest] = useState(null);
  const { subscriptionInfo = {} } = useSelector(current);
  const { status, startDate, endDate } = subscriptionInfo;
  const [isAllowedToRequestToCancel, setIsAllowedToRequestToCancel] = useState(false);
  const isICC = role === ROLES_IDS.ICCoordinator;

  const expiresIn = status !== PACKAGE_STATUSES.Expired && differenceInDays(new Date(), endDate);

  const canCancelRequest = async () => {
    if (isICC) {
      const props = await MyCompanyService.getInsuranceCompanyInfo();
      const { data: { data } } = await MyCompanyService.getInsuranceCompanyInfo();
      setIsAllowedToRequestToCancel(status === PACKAGE_STATUSES.Active && data.primaryContact.email === email);
    }
  };

  useEffect(() => {
    canCancelRequest();
  }, []);

  function getCurrentSubscription() {
    dispatch(SubscribersActions.getCurrentSubscription());
  }

  const getCancelRequest = async () => {
    const res = await RequestService.getCurrentSubRequestToCancelSubscription();
    if (JSON.stringify(res.data) === '{}') return;
    setCancelRequest(res.data);
  };

  useEffect(() => {
    getCancelRequest();
  }, []);

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  function toggleRequestToCancel() {
    setShowRequestToCancel(!showRequestToCancel);
  }

  function toggleRequestToCancelConfirmation() {
    setShowRequestToCancelConfirmation(!showRequestToCancelConfirmation);
  }

  const handleRequestToCancelSubmit = async (data) => {
    try {
      await RequestService.requestToCancelSubscription(data);
      toggleRequestToCancelConfirmation();
      setCancelRequest(data);
    } catch (error) {
      showNotifyError(error);
    }
  };

  return (
    <>
      <InfoWrapper>
        <InfoTitle>Subscription Info</InfoTitle>
        <InfoDataWrapper>
          <InfoColumn>
            <InfoText>
              Subscription Status:
              {' '}
              <InfoTextBold>{status}</InfoTextBold>
            </InfoText>
            <InfoText>
              Start Date:
              {' '}
              <InfoTextBold>{formatCommonDate(startDate)}</InfoTextBold>
            </InfoText>
            {expiresIn && (
              <InfoText>
                Expires In:
                {' '}
                <InfoTextBold>{expiresIn}</InfoTextBold>
              </InfoText>
            )}
          </InfoColumn>
          <InfoColumn>
            <InfoText>
              Subscription Package:
              {' '}
              <InfoTextBold>{getPackageName(subscriptionInfo.package)}</InfoTextBold>
            </InfoText>
            <InfoText>
              End Date:
              {' '}
              <InfoTextBold>{formatCommonDate(endDate)}</InfoTextBold>
            </InfoText>
          </InfoColumn>
        </InfoDataWrapper>
      </InfoWrapper>

      <SubTitle>For any further information please contact us:</SubTitle>

      <StyledInfoCard theme={useTheme()} />

      {isAllowedToRequestToCancel && (
        <ButtonWrapper>
          <Button
            onClick={toggleRequestToCancel}
            label="Request to Cancel"
            variant="contained"
            formNoValidate
            disabled={cancelRequest}
            text={cancelRequest == null ? 'Request to Cancel' : 'Cancel Request Submitted'}
          />
        </ButtonWrapper>
      )}
      {showRequestToCancel && <RequestToCancelForm isOpen={showRequestToCancel} onClose={toggleRequestToCancel} onSubmit={handleRequestToCancelSubmit} />}
      {showRequestToCancelConfirmation && (
        <ConfirmationDialog
          open
          title="Request received"
          onClose={toggleRequestToCancelConfirmation}
          onCancel={toggleRequestToCancelConfirmation}
          onConfirm={toggleRequestToCancelConfirmation}
          confirmButtonTitle="Close"
          noCancel
        >
          We have received your request, we will contact you shortly to proceed with your cancellation
        </ConfirmationDialog>
      )}
    </>
  );
};

export default Subscription;
