import DesignationService from 'common/api/DesignationService';

const GET_DESIGNATIONS = 'GET_DESIGNATIONS';
const ADD_DESIGNATION = 'ADD_DESIGNATION';
const UPDATE_DESIGNATION = 'UPDATE_DESIGNATION';

export const getDesignations = (params) => ({
  type: GET_DESIGNATIONS,
  apiCall: () => DesignationService.getDesignations(params),
});

export const addDesignation = (params) => ({
  type: ADD_DESIGNATION,
  apiCall: () => DesignationService.addDesignation(params),
});

export const updateDesignation = (params) => ({
  type: UPDATE_DESIGNATION,
  apiCall: () => DesignationService.updateDesignation(params),
});

const initialState = {
  designationsList: {
    data: [],
  },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_DESIGNATIONS: {
      return {
        ...state,
        designationsList: {
          ...state.designationsList,
          ...response,
        },
      };
    }
    default:
      return state;
  }
};
