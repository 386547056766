import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const ProjectStatusChanged = ({ notify, status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: { projectId, projectName, closingReason }, subject } = notify;
  const { closed, canceled } = status;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToProject = () => {
    handleClose();
    closeNotificationList();
    navigate(`/projects/${projectId}/details`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title={subject}
      cancelButtonTitle="Close"
      confirmButtonTitle="Go to Project"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToProject}
      noConfirm={canceled}
    >
      {canceled && (
        <ConfirmationText
          value={projectName}
          text="Please be notified that {placeholder} has been canceled by the Insurance Company. The files will no longer be available."
        />
      )}
      {closed && (
        <ConfirmationText
          value={[projectName, closingReason]}
          text="Please be notified that the {placeholder} is now closed. Closing reason: {placeholder}. Analytical information will still be available on the portal, as well as documents for review."
        />
      )}
    </ConfirmationDialog>
  );
};

export default ProjectStatusChanged;
