import { styled as styledMui } from '@mui/material/styles';
import { Menu } from '@mui/material';

export const StyledMenu = styledMui(Menu)(({ theme }) => {
  return {
    '.MuiMenu-paper': {
      minWidth: 200,
    },
    '.MuiMenuItem-root': {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      paddingLeft: 5,
      fontFamily: 'Graphik LC',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: 0.15,
      color: theme.palette.text.main,
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        backgroundColor: theme.palette.input.select.topBorder,
      },
    },
  };
});

// export const StyledMenu = styled(Menu)`
//   .MuiMenu-paper {
//     min-width: 200px;
//   }
//
//   .MuiMenuItem-root {
//     display: flex;
//     align-items: center;
//     padding: 0;
//     padding-left: 5px;
//
//     font-family: Graphik LC;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 24px;
//     letter-spacing: 0.15px;
//     color: var(--textColor);
//     background-color: var(--inputBackground);
//
//     &:hover {
//       background-color: var(--editableSelectTopBorder);
//     }
//   }
// `;
//
// export const IconWrapper = styled.div`
//   width: 36px;
//   height: 36px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
//
// export const TextWrapper = styled.div`
//   margin-top: 2px;
// `;
