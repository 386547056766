export enum SUBSCRIPTION_PACKAGE {
  FREE_USAGE = 'FreeUsage',
  LITE = 'Lite',
  LITE_PLUS = 'LitePlus',
  PRO = 'Pro',
  GLOBAL = 'Global',
}

export enum SUBSCRIBER_TYPE {
  INSURANCE_COMPANY = 'InsuranceCompany',
  RISK_COMPANY = 'RiskCompany',
  BROKER = 'Broker',
  RISK_MANAGER = 'RiskManager',
  BROKER_COMPANY = 'BrokerCompany',
  DEVELOPER_OWNER = 'DeveloperOwner',
  INSURANCE_REPRESENTATIVE = 'InsuranceRepresentative',
  INVITED_COMPANY = 'InvitedCompany',
}
