import ApiClient from 'services/ApiClient';
import qs from 'qs';

class GlobalEcosystemService extends ApiClient {
  getSummary(params) {
    return this.call({
      method: 'get',
      url: `/global-ecosystem/summary?${qs.stringify(params)}`,
    });
  }

  getFilters(params) {
    return this.call({
      method: 'get',
      url: `/global-ecosystem/filters?${qs.stringify(params)}`,
    });
  }

  getCharts(params) {
    return this.call({
      method: 'get',
      url: `/global-ecosystem/charts?${qs.stringify(params)}`,
    });
  }
}

export default new GlobalEcosystemService();
