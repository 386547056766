import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  communicationsList,
} from 'communication/store/communication.selectors';
import { formatPolicyDate } from 'utils/DateUtils';
import CommunicationsControls from '../communicationsControls';
import AccordionTable from '../../../../components/AccordionTable';
import { CommunicationsListRoot, StatusTag } from './style';

const renderLabels = (userLabels) => {
  const response = userLabels?.map((value) => <StatusTag>{value}</StatusTag>);

  return response;
};

const renderDescription = (description) => {
  return description.slice(0, 70);
};

const getColumns = () => [
  {
    width: '20%',
    key: 'title',
    title: 'Title',
    inTwoLines: true,
  },
  {
    width: '15%',
    key: 'startDate',
    title: 'Start Date',
    noSort: true,
    render: (data) => formatPolicyDate(data.startDate),
  },
  {
    width: '15%',
    key: 'endDate',
    title: 'End Date',
    render: (data) => formatPolicyDate(data.endDate),
  },
  {
    width: '40%',
    key: 'roles',
    title: 'Users',
    noSort: true,
    render: ({ roles }) => roles && renderLabels(roles),
  },
  {
    width: '10%',
    key: 'controls',
    title: '',
    align: 'right',
    noSort: true,
  },
];

const columns = [
  {
    width: '20%',
    key: 'title',
    title: 'Title',
    inTwoLines: true,
  },
  {
    width: '45%',
    key: 'description',
    title: 'Description',
    noSort: true,
    render: ({ description }) => renderDescription(description),
  },
  {
    width: '10%',
    key: 'pageNumber',
    title: 'Page Number',
    render: ({ pageNumber }) => `${pageNumber}`,
  },
  {
    width: '18%',
    key: 'image',
    title: 'Image',
    render: () => 'Click to see the image',
  },
  {
    width: '10%',
    key: 'controls',
    title: '',
    align: 'right',
    noSort: true,
  },
];

const CommunicationsTable = ({ getCommunicationsList }) => {
  const communications = useSelector(communicationsList);
  const [page, setPage] = useState(1);
  const { isLoading, items, totalPages, totalCount } = communications;
  const navigate = useNavigate();
  const [expandedTables, setExpandedTables] = useState([]);

  function handlePrevPageClick() {
    setPage(page - 1);
  }

  function handleNextPageClick() {
    setPage(page + 1);
  }

  function handleGoToPage(selectedPage) {
    setPage(selectedPage);
  }

  const viewSection = (sec) => {
    navigate(`/communications/view/${sec.communicationId}/${sec.id}`);
  };

  const renderControls = (communication) => {
    return <CommunicationsControls communication={communication} onSuccess={getCommunicationsList} />;
  };

  const mappedItems = items?.map((item) => ({ ...item, columns, items: item.sections ?? [] }));

  return (
    <CommunicationsListRoot>
      <AccordionTable
        nonCheckableRows
        items={mappedItems ?? []}
        columns={getColumns()}
        isLoading={isLoading}
        onRowClick={viewSection}
        onRenderControls={renderControls}
        setExpandedTables={setExpandedTables}
        expandedTables={expandedTables}
        pagination={{
          page,
          totalPages,
          itemsCount: items.length,
          totalCount,
        }}
        onPrevPage={handlePrevPageClick}
        onNextPage={handleNextPageClick}
        onGoToPage={handleGoToPage}
      />
    </CommunicationsListRoot>
  );
};

export default CommunicationsTable;
