export function analyticsAvailable({ analytics }) {
  return analytics.hasAccess.isLoading ? false : analytics.hasAccess.data;
}

export function riskScore({ analytics }) {
  return analytics.riskScore.data;
}

export function improvements({ analytics }) {
  return analytics.improvements;
}
