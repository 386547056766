import { endsWith, isArray, isObject } from 'lodash';
import { FAILURE_SUBTYPE, REQUEST_SUBTYPE, SUCCESS_SUBTYPE } from './apiCallMiddleware';

const defaultPayload = {
  isLoading: false,
  error: null,
  fieldErrors: null,
};

interface MiddlewarePayloadAction {
  payload: any;
  subtype: string;
  response: any;
}

// eslint-disable-next-line no-unused-vars
const payloadMiddleware = () => (next: any) => (action: MiddlewarePayloadAction) => {
  const { result, error, fieldErrors } = action.payload || {};

  if (!action.subtype) {
    return next(action);
  }

  if (endsWith(action.subtype, REQUEST_SUBTYPE)) {
    action.response = {
      ...defaultPayload,
      isLoading: true,
    };
  } else if (endsWith(action.subtype, FAILURE_SUBTYPE)) {
    action.response = {
      ...defaultPayload,
      error,
      fieldErrors,
    };
  } else if (endsWith(action.subtype, SUCCESS_SUBTYPE)) {
    if (isArray(result) || !isObject(result)) {
      action.response = {
        ...defaultPayload,
        data: result,
      };
    } else {
      action.response = {
        ...result,
        ...defaultPayload,
      };
    }
  }

  return next(action);
};

export default payloadMiddleware;
