import React from 'react';

const Comment = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path fill="#96CEF6" d="M11.088 18.75L10 18.125l2.5-4.375h3.75a1.25 1.25 0 001.25-1.25V5a1.25 1.25 0 00-1.25-1.25H3.75A1.25 1.25 0 002.5 5v7.5a1.25 1.25 0 001.25 1.25h5.625V15H3.75a2.5 2.5 0 01-2.5-2.5V5a2.5 2.5 0 012.5-2.5h12.5a2.5 2.5 0 012.5 2.5v7.5a2.5 2.5 0 01-2.5 2.5h-3.025l-2.137 3.75z" />
    <path fill="#96CEF6" d="M15 6.25H5V7.5h10V6.25zM11.25 10H5v1.25h6.25V10z" />
  </svg>
);

export default Comment;
