import qs from 'qs';
import ApiClient from 'services/ApiClient';

import { formatSortField } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class SubscriptionsService extends ApiClient {
  getSubscribersList({ subscriberType, page = 1, pageSize = PAGE_SIZE, sortBy, searchFilter }) {
    const query = {
      page,
      pageSize,
      subscriberType,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    if (searchFilter) {
      query.Name = searchFilter;
    }

    return this.call({
      method: 'get',
      url: `/subscriptions/subscribers?${qs.stringify(query)}`,
    });
  }

  getSubscriptionsList({ subscriberType, subscriberId, page = 1, pageSize = PAGE_SIZE, sortBy }) {
    const query = {
      page,
      pageSize,
      subscriberType,
      subscriberId,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    return this.call({
      method: 'get',
      url: `/subscriptions/?${qs.stringify(query)}`,
    });
  }

  getSummaryInfo(subscriberType) {
    return this.call({
      method: 'get',
      url: `/subscriptions/summary?${qs.stringify({ subscriberType })}`,
    });
  }

  getRCWithoutSubscription() {
    return this.call({
      method: 'get',
      url: '/risk-companies/without-subscription',
    });
  }

  getUsersWithoutSubscription(role) {
    return this.call({
      method: 'get',
      url: `/users/without-subscription?${qs.stringify({ role })}`,
    });
  }

  addSubscription(data) {
    return this.call({
      method: 'post',
      url: '/subscriptions',
      data,
    });
  }

  editSubscription(data) {
    return this.call({
      method: 'put',
      url: '/subscriptions',
      data,
    });
  }

  deleteSubscription(id) {
    return this.call({
      method: 'delete',
      url: `/subscriptions/${id}`,
    });
  }

  getCurrentSubscription() {
    return this.call({
      method: 'get',
      url: '/subscriptions/current',
    });
  }

  downloadData(id) {
    return this.call({
      method: 'GET',
      url: `/subscriptions/${id}/download-data`,
      responseType: 'blob',
    });
  }
}

export default new SubscriptionsService();
