import styled from 'styled-components';

import { Select, InputLabel, MenuItem } from '@mui/material';

export const StyledMenuItem = styled(MenuItem)`
  min-height: 40px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--inputText);
  background: var(--inputBackground);

  &&.addItem {
    z-index: 100;
    position: sticky;
    bottom: 0;
    color: var(--inputFocusedBorder);
    border-top: 1px solid var(--editableSelectTopBorder);
  }

  &:hover {
    background-color: var(--inputHoverBackground);
  }

  &&.MuiListItem-root.Mui-selected {
    background-color: transparent;
    border: 1px solid #96CEF6;
  }

  &&.MuiListItem-root.Mui-disabled {
    pointer-events: none;
    background-color: var(--inputHoverBackground);
    color: var(--inputDisabledText);
  }

  &&.MuiListItem-root.Mui-selected:hover {
    background-color: var(--inputHoverBackground);
  }
`;

export const StyledSelect = styled(Select)`
  && {
    position: relative;
    font-family: Graphik LC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: var(--inputText);

    background-color: var(--inputBackground);
    border-radius: 4px;
    border: 1px solid var(--inputBorder);

    ${({ error }) => error && 'border: 1px solid var(--error);'}

    :hover {
      background-color: var(--inputHoverBackground);
    }

    ${({ readOnly }) => readOnly && `
      border: 1px solid var(--inputReadOnlyBorder);
      background-color: transparent;
      pointer-events: none;
    `}
  }

  & .MuiSelect-icon {
    top: calc(50% - 10px);
    ${({ readOnly }) => readOnly && 'display: none;'}
  }

  & .MuiSelect-iconFilled {
    right: 14px;
  }

  & .MuiIconButton-edgeEnd {
    margin-right: -8px;
  }

  &&.Mui-focused {
    border: 1px solid var(--inputFocusedBorder);
    background-color: var(--inputBackground);
  }
  &&.Mui-disabled {
    background-color: var(--inputDisabledBackground);
    color: var(--inputDisabledText);
  }

  & .MuiFilledInput-input {
    padding: 22px 16px 6px;
  }

  & .MuiFormLabel-root {
    font-family: Graphik LC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: var(--lightTextColor);
  }

  & .MuiInputLabel-root {
    font-family: Graphik LC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: var(--lightTextColor);
  }

  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(16px, 13px) scale(0.70);
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  && {
    font-family: Graphik LC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: var(--lightTextColor);

    ${({ error }) => error && 'color: var(--error)'}
  }

  &&.Mui-disabled {
    color: var(--inputDisabledText);
  }

  &&.MuiInputLabel-animated {
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
      font-size 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  }
`;
