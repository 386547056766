import React, { Fragment, useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";

import PageLayout from "layouts/PageLayout";
import { BackButton, Button } from "components";
import NavTabs from "components/NavTabs";
import { subscribersTypePaths } from "subscriptions/constants";
import UserService from "projects/api/UserService";
import MyCompanyService from "myCompany/api/MyCompanyService";
import CompanyService from "common/api/CompanyService";
import * as SubscribersActions from "subscriptions/store/subscriptions.reducer";

import SubscriberData from "./SubscriberData";
import SubscriptionHistory from "./SubscriptionHistory";
import AddSubscriptionModal from "./AddSubscriptionModal";

import { HeaderPanel, Title, Headers, NavContainer } from "./styles";

export const tabs = {
  subscriberData: {
    label: "Subscriber Data",
    value: "SubscriberData",
    path: "info",
  },
  subscriptionHistory: {
    label: "Subscription History",
    value: "SubscriptionHistory",
    path: "history",
  },
};

const ViewSubscriber = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [subscriberData, setSubscriberData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const isUser = subscriberData && subscriberData.type === "User";
  const isContact = subscriberData && subscriberData.type === "Contact";
  const showSubsHistory = !isUser && !isContact;
  const { subscriberType, subscriberId } = useParams();

  const navTabs = showSubsHistory
    ? Object.values(tabs)
    : Object.values({ subscriberData: tabs.subscriberData });
  const prevTab = navTabs.find(
    (navTab) => location.pathname.split("/").pop() === navTab.path
  );

  const [selectedTab, setSelectedTab] = useState(
    prevTab && prevTab !== subscriberId ? prevTab.value : navTabs[0].value
  );

  const role =
    Object.values(subscribersTypePaths).find(
      ({ path }) => path === subscriberType
    ) || {};

  const getSubscriptions = () =>
    dispatch(
      SubscribersActions.getSubscriptionsList({
        page: 1,
        subscriberType: role.name,
        subscriberId,
      })
    );

  const handleSubscribersListClick = () => navigate("/subscriptions");

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  const fetchSubscriberData = async () => {
    let subscriber;
    switch (subscriberType) {
      case subscribersTypePaths.InvitedCompany.path:
        subscriber = await CompanyService.getInsuranceCompanyByInvitedICId(
          subscriberId
        );
        break;
      case subscribersTypePaths.InsuranceCompany.path:
        subscriber = await MyCompanyService.getInsuranceCompanyById(
          subscriberId
        );
        break;
      case subscribersTypePaths.RiskCompany.path:
        subscriber = await MyCompanyService.getRiskCompanyById(subscriberId);
        break;
      default:
        subscriber = await UserService.getUser(subscriberId);
    }
    setSubscriberData(subscriber.data?.data);
  };

  useEffect(() => {
    fetchSubscriberData();
  }, [subscriberType, subscriberId]);

  useEffect(() => {
    const selectedTabObject = navTabs.find((tab) => tab.value === selectedTab);
    navigate(`${selectedTabObject.path}`);
  }, [selectedTab]);

  return (
    <PageLayout>
      <Fragment key="title">
        <Headers>
          <HeaderPanel>
            <BackButton
              text="subscribers"
              onClick={handleSubscribersListClick}
            />
          </HeaderPanel>
          <HeaderPanel>
            <Title>View Subscriber</Title>
          </HeaderPanel>
        </Headers>
      </Fragment>
      <Fragment key="content">
        <NavContainer>
          <NavTabs
            selectedTab={selectedTab}
            onSelectTab={setSelectedTab}
            tabsList={navTabs}
          />
          {selectedTab === tabs.subscriptionHistory.value && (
            <Button
              small
              text="Add New Subscription"
              color="primary"
              variant="contained"
              onClick={handleModalOpen}
            />
          )}
        </NavContainer>
        <Routes>
          <Route
            path="/:subscriberType/:subscriberId/info"
            exact
            element={
            <SubscriberData subscriberData={subscriberData} />}
          >
          </Route>
          {showSubsHistory && (
            <Route
              path="/:subscriberType/:subscriberId/history"
              exact
              element={
              <SubscriptionHistory
                subscriberType={subscriberType}
                subscriberData={subscriberData}
                getSubscriptions={getSubscriptions}
                subscriberEmail={
                  subscriberData?.primaryContactEmail || subscriberData?.email
                }
              />}
            >
            </Route>
          )}
          <Route
            path="/:subscriberType/:subscriberId"
            element={
              <Navigate
                to="/subscriptions/view/:subscriberType/:subscriberId/info"
                replace
              />
            }
          />
        </Routes>
        {modalOpen && (
          <AddSubscriptionModal
            isOpen={modalOpen}
            onCancel={handleModalClose}
            subscriberEmail={subscriberData?.primaryContactEmail}
            subscriberType={role.name}
            subscriberId={subscriberId}
            onSuccess={getSubscriptions}
          />
        )}
      </Fragment>
    </PageLayout>
  );
};

export default ViewSubscriber;
