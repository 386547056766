import React, { Fragment, useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { debounce } from "lodash";

import PageLayout from "layouts/PageLayout";
import NavTabs from "components/NavTabs";
import InsuranceCompany from "subscriptions/pages/InsuranceCompany";
import RiskCompany from "subscriptions/pages/RiskCompany";
import BrokerCompany from "subscriptions/pages/BrokerCompany";
import Broker from "subscriptions/pages/Broker";
import InsuranceRepresentative from "subscriptions/pages/InsuranceRepresentative";
import RiskManager from "subscriptions/pages/RiskManager";
import DeveloperOwner from "subscriptions/pages/DeveloperOwner";
import { Button } from "components";

import { HeaderPanel, Title, NavContainer, StyledSearchInput } from "./styles";

export const tabs = {
  insuranceCompany: {
    label: "Insurance Company",
    value: "InsuranceCompany",
    path: "insurance-company",
  },
  riskCompany: {
    label: "Risk Company",
    value: "RiskCompany",
    path: "risk-company",
  },
  brokerCompany: {
    label: "Broker Company",
    value: "BrokerCompany",
    path: "broker-company",
  },
  broker: {
    label: "Broker",
    value: "Broker",
    path: "broker",
  },
  insuranceRepresentative: {
    label: "Insurance Representative",
    value: "InsuranceRepresentative",
    path: "insurance-representative",
  },
  riskManager: {
    label: "Risk Manager",
    value: "RiskManager",
    path: "risk-manager",
  },
  developerOwner: {
    label: "Developer Owner",
    value: "DeveloperOwner",
    path: "developer-owner",
  },
};

const Subscriptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchFilter, setSearchFilter] = useState("");
  const navTabs = Object.values(tabs);
  const prevTab = navTabs.find(
    (navTab) => location.pathname.split("/").pop() === navTab.path
  );

  const [selectedTab, setSelectedTab] = useState(
    prevTab && prevTab !== "list" ? prevTab.value : navTabs[0].value
  );

  const debouncedSetSearchFilter = debounce(
    (value) => setSearchFilter(value),
    400
  );

  const handleFilterChange = (event) => {
    debouncedSetSearchFilter(event.target.value);
  };

  const clearSearchFilter = () => {
    setSearchFilter("");
  };

  const handleAddClick = () => navigate("/subscriptions/new");

  useEffect(() => {
    const selectedTabObject = navTabs.find((tab) => tab.value === selectedTab);
    navigate(`/subscriptions/list/${selectedTabObject.path}`);
  }, [selectedTab]);

  return (
    <PageLayout>
      <Fragment key="title">
        <HeaderPanel>
          <Title>Subscriptions</Title>
        </HeaderPanel>
      </Fragment>
      <Fragment key="content">
        <NavContainer>
          <NavTabs
            selectedTab={selectedTab}
            onSelectTab={setSelectedTab}
            tabsList={navTabs}
          />
          <StyledSearchInput
            fullWidth
            placeholder="Search by Name"
            value={searchFilter}
            onChange={handleFilterChange}
            onClearSearch={clearSearchFilter}
          />
          <Button
            small
            text="Add New Subscriber"
            color="primary"
            variant="contained"
            onClick={handleAddClick}
          />
        </NavContainer>
        <Routes>
          <Route path="/insurance-company" exact element={
            <InsuranceCompany searchFilter={searchFilter} />}>
          </Route>
          <Route path="/risk-company" exact element={
            <RiskCompany searchFilter={searchFilter} />}>
          </Route>
          <Route path="/broker-company" exact element={
            <BrokerCompany searchFilter={searchFilter} />}>
          </Route>
          <Route path="/broker" exact element={
            <Broker searchFilter={searchFilter} />}>
          </Route>
          <Route path="/insurance-representative" exact element={
            <InsuranceRepresentative searchFilter={searchFilter} />}>
          </Route>
          <Route path="/risk-manager" exact element={
            <RiskManager searchFilter={searchFilter} />}>
          </Route>
          <Route path="/developer-owner" exact element={
            <DeveloperOwner searchFilter={searchFilter} />}>
          </Route>

          <Route
            path="/"
            element={
              <Navigate to="/list/insurance-company" replace />
            }
          />
        </Routes>
      </Fragment>
    </PageLayout>
  );
};

export default Subscriptions;
