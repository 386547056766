import React from 'react';

const Resources = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M26.87 11.413h-3.261v-3.26h-1.63v3.26h-3.261v1.63h3.26v3.261h1.63v-3.26h3.262v-1.631zM10.565 3.261a4.076 4.076 0 110 8.151 4.076 4.076 0 010-8.151zm0-1.63a5.707 5.707 0 100 11.412 5.707 5.707 0 000-11.413zm8.153 22.825h-1.63V20.38a4.076 4.076 0 00-4.077-4.076H8.12a4.076 4.076 0 00-4.076 4.076v4.076h-1.63V20.38a5.706 5.706 0 015.706-5.706h4.891a5.707 5.707 0 015.707 5.706v4.076z" fill="#fff" fillOpacity=".87" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" transform="translate(.783)" d="M0 0h26.087v26.087H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Resources;
