import React from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';

import useNewCompany from 'utils/hooks/useNewCompany';

import { PulsatingCircle } from './styles';

const navTabsStyles = {
  position: 'relative',
  top: 8,
  right: 'unset',
  left: 'unset',
};
const stylesheets = {
  Rates: {
    style: navTabsStyles,
    placement: 'right',
    tooltip: "Navigate to 'Rates' sub-section to set up rates for the risk engineers.",
  },
  TeamMembers: {
    style: navTabsStyles,
    placement: 'right',
    tooltip: "Navigate to 'Team Members' sub-section to invite your first team members.",
  },
  RatesButton: {
    style: { left: -40, right: 'unset' },
    placement: 'left',
    tooltip: 'Click “Add Rate” to set up your company rates.',
  },
  TeamMembersButton: {
    style: { left: -24, right: 'unset' },
    placement: 'left',
    tooltip: "Click 'New Team Member' to add your team members.",
  },
};

const PulsatingTooltip = ({ place }) => {
  const { isICC, isRCC, ratesExist, membersExist, areMembersAndRatesExist } = useNewCompany();

  if (!isICC && !isRCC) {
    return null;
  }
  if (areMembersAndRatesExist) {
    return null;
  }

  if (place === 'Sidebar') {
    return (
      <MuiTooltip arrow title="Navigate to 'My Company' section to invite your team members and set up company rates." placement="right">
        <PulsatingCircle />
      </MuiTooltip>
    );
  }
  if (['Rates', 'RatesButton'].includes(place) && !ratesExist) {
    return (
      <MuiTooltip arrow title={stylesheets[place].tooltip} placement={stylesheets[place].placement}>
        <PulsatingCircle style={stylesheets[place].style} />
      </MuiTooltip>
    );
  }
  if (['TeamMembers', 'TeamMembersButton'].includes(place) && !membersExist) {
    return (
      <MuiTooltip arrow title={stylesheets[place].tooltip} placement={stylesheets[place].placement}>
        <PulsatingCircle style={stylesheets[place].style} />
      </MuiTooltip>
    );
  }

  return null;
};

export default PulsatingTooltip;
