import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  background: 'black';
`;

export const Summary = styledMui('div')(({ theme }) => {
  return {
    display: 'flex',
    height: 116,
    width: '100%',
    padding: '15px 0',
    marginBottom: 24,
    background: theme.palette.table.header.background,
    borderRadius: 6,
    '> div:not(:last-child)': {
      borderRight: '1px solid ' + theme.palette.text.main,
    },
  };
});

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  font-family: Avenir;
  letter-spacing: 0.5px;

  ${({ widthPercentage }) => `width: ${widthPercentage}`};
`;

export const Text = styledMui('div')(({ theme }) => {
  return {
    fontWeight: 800,
    textAlign: 'center',
    color: theme.palette.text.main,
  };
});

export const Digits = styledMui('div')(({ theme }) => {
  return {
    marginTop: 20,
    fontWeight: 900,
    fontSize: 32,
    color: theme.palette.sidebar.selected.icon.background,
  };
});
