import qs from 'qs';
import ApiClient from 'services/ApiClient';
import { formatSortField } from 'utils/FormatUtils';

class FeeProposalService extends ApiClient {
  getFeeProposalsList(projectId, sortBy) {
    const query = {
      ProjectId: projectId,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    return this.call({
      method: 'get',
      url: `/fee-proposals?${qs.stringify(query)}`,
    });
  }

  addFeeProposal(feeProposalData) {
    return this.call({
      method: 'post',
      url: '/fee-proposals',
      data: {
        ...feeProposalData,
      },
    });
  }

  getFeeProposalFormData({ feeProposalId, companyContactId }) {
    return this.call({
      method: 'get',
      url: `/fee-proposals/response/form-data?${qs.stringify({ feeProposalId, companyContactId })}`,
    });
  }

  submitFeeProposal(feeProposalData) {
    return this.call({
      method: 'put',
      url: '/fee-proposals/response/submit',
      data: {
        ...feeProposalData,
      },
    });
  }

  getSubmittedFeeProposalData({ feeProposalId, companyContactId }) {
    return this.call({
      method: 'get',
      url: `/fee-proposals/response/details?${qs.stringify({ feeProposalId, companyContactId })}`,
    });
  }

  acceptFeeProposal({ feeProposalId, companyContactId }) {
    return this.call({
      method: 'put',
      url: '/fee-proposals/accept',
      data: {
        feeProposalId,
        companyContactId,
      },
    });
  }

  rejectFeeProposal(feeProposalId) {
    return this.call({
      method: 'put',
      url: '/fee-proposals/reject',
      data: {
        feeProposalId,
      },
    });
  }
}

export default new FeeProposalService();
