import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import { subscribersTypePaths } from 'subscriptions/constants';
import SubscriptionsService from 'subscriptions/api/SubscriptionsService';
import { showNotifyError, showNotifySuccess } from 'services/toaster';
import AddSubscriptionModal from 'subscriptions/pages/ViewSubscriber/AddSubscriptionModal';
import { Menu, MenuItem } from 'components';

import { ControlsWrapper } from './styles';

const stopPropagation = (event) => {
  event.stopPropagation();
};

const ContactControls = ({ subscription, onSuccess, name, subscriberEmail }) => {
  const { subscriberType, subscriberId } = useParams();
  const [deleteDialogState, setDeleteDialogState] = useState(null);
  const [editDialogState, setEditDialogState] = useState(null);

  const role = Object.values(subscribersTypePaths).find(({ path }) => path === subscriberType) || {};

  const { id } = subscription;

  const closeEditDialog = () => setEditDialogState(null);

  const closeDeleteDialog = () => setDeleteDialogState(null);

  const handleContactEditClick = () => setEditDialogState(id);

  const handleSubscriptionDeleteClick = () => setDeleteDialogState(id);

  const handleDeleteConfirm = async () => {
    try {
      await SubscriptionsService.deleteSubscription(id);
      showNotifySuccess(`Subscription was successfully deleted. The notification email was sent to ${subscriberEmail}.`);
      onSuccess();
      closeDeleteDialog();
    } catch (error) {
      showNotifyError(error);
    }
  };

  return (
    <ControlsWrapper onClick={stopPropagation}>
      <Menu>
        <MenuItem icon="edit" text="Edit" onClick={handleContactEditClick} />
        <MenuItem icon="deleteSmall" text="Delete" onClick={handleSubscriptionDeleteClick} />
      </Menu>

      {deleteDialogState && (
        <ConfirmationDialog
          open
          title="Delete Subscription"
          confirmButtonTitle="Delete"
          onClose={closeDeleteDialog}
          onCancel={closeDeleteDialog}
          onConfirm={handleDeleteConfirm}
        >
          Are you sure you want to delete subscription for {name.fullName || name}?
          It will be deleted immediately, you can&apos;t undo this action.
        </ConfirmationDialog>
      )}
      {editDialogState && (
        <AddSubscriptionModal
          isOpen={!!editDialogState}
          onCancel={closeEditDialog}
          subscriberType={role.name}
          subscriberId={subscriberId}
          onSuccess={onSuccess}
          subscription={subscription}
          subscriberEmail={subscriberEmail}
        />
      )}
    </ControlsWrapper>
  );
};

export default ContactControls;
