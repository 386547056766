import React from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { IconWrapper, TooltipText } from './styles';

const Icon = ({ theme }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
    <circle cx="7" cy="7" r="7" fill={theme.dark ? '#73778C' : '#E6E9F4'} />
    <path fill={theme.dark ? '#FFFFFF' : '#064789'} d="M6.869 11.594a.766.766 0 100-1.532.766.766 0 000 1.532zm.511-8.677h-.766a2.292 2.292 0 00-2.297 2.297v.255h1.02v-.255a1.276 1.276 0 011.277-1.276h.765a1.276 1.276 0 010 2.552H6.36v2.297h1.02V7.51a2.297 2.297 0 100-4.594z" />
  </svg>
);
const WarningIcon = ({ theme }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <path fill={theme.dark ? '#73778C' : '#E6E9F4'} d="M8 1C4.15 1 1 4.15 1 8s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7zm-.55 3h1.1v5.5h-1.1V4zM8 12.5c-.4 0-.75-.35-.75-.75S7.6 11 8 11s.75.35.75.75-.35.75-.75.75z" />
    <path fill={theme.dark ? '#FFFFFF' : '#064789'} d="M8.75 11.75c0 .4-.35.75-.75.75s-.75-.35-.75-.75S7.6 11 8 11s.75.35.75.75zM8.55 4h-1.1v5.5h1.1V4z" />
  </svg>
);

const Tooltip = ({ text, placement, isWarning, className }) => {
  const theme = useTheme();
  return (
    <MuiTooltip
      arrow
      placement={placement || 'bottom'}
      title={(
        <TooltipText>
          {text}
        </TooltipText>
      )}
    >
      <IconWrapper className={className}>
        {isWarning ? <WarningIcon theme={theme} /> : <Icon theme={theme} />}
      </IconWrapper>
    </MuiTooltip>
  );
};

export default Tooltip;
