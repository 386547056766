import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';

import { Title, TextSection } from '../../styles';

const overviewSrc = 'projects/SiteContact/projects-overview.png';
const recommendationsSrc = 'projects/SiteContact/projects-recommendations.png';
const analyticsSrc = 'projects/SiteContact/projects-analytics.png';

const SCOverview = () => {
  return (
    <>
      <ImageContainer src={overviewSrc} alt="overview" />
      <Title>Overview</Title>
      <TextSection>
        Projects section lists all the projects you are involved into. Accessing the project provides
        the detailed information about the project such as recommendations and analytics.
      </TextSection>
      <ImageContainer src={recommendationsSrc} alt="recommendations" />
      <Title>Recommendations</Title>
      <TextSection>
        Recommendations sub-section lists all the recommendations that were issued by the risk engineer.
      </TextSection>
      <TextSection>
        Recommendations are added by the risk engineer during the inspections and are actioned by the site
        contact within the calculated timeline. When the recommendations are issued by the risk engineer,
        all the relevant stakeholders including the site contact receive the notification about that.
        When the recommendation are actioned by the site contact, such action is reviewed by the
        risk engineer and if approved, the recommendation is closed.
      </TextSection>
      <ImageContainer src={analyticsSrc} alt="analytics" />
      <Title>Analytics</Title>
      <TextSection>
        Analytics sub-section provides the analytical information about the project.
        The risk score calculated for the project is based on the data gathered from various
        project sections and demonstrates how high the risk is for the project.
      </TextSection>
      <TextSection>
        The risk scores for the project are calculated after the first inspection. The current risk score
        is constantly updated when the relevant changes are made to the project (e.g. recommendations are
        closed or overdue) whereas the inspection risk score is captured ones and is not
        recalculated so that the dynamic of the risks score can be tracked.
      </TextSection>
      <TextSection>
        Additionally, within that section you can find various visual charts showing the recommendations data
        from different angles as well as find the information about how the risk score for the project can be improved.
      </TextSection>
    </>
  );
};

export default SCOverview;
