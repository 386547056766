import React, { useState } from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import SalesDepartmentModal from 'help/components/SalesDepartmentModal';
import RedirectButton from 'help/components/RedirectButton';

import { Title, TextSection, TextButton, List } from '../../styles';

const imageSrc = 'ge-overview.png';

const Overview = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <ImageContainer src={imageSrc} alt="overview" />
      <Title>Overview</Title>
      <TextSection>
        Global Ecosystem section collates the risk recommendations and claims data across all
        the users and allows you to get a deeper understanding of the type of the recommendations and claims globally.
        The information gathered is displayed in an anonymized way without disclosing any sensitive information.
      </TextSection>
      <TextSection>
        The section consists of the three main elements:
      </TextSection>
      <List>
        <li>Map</li>
        <li>Charts</li>
        <li>Filters</li>
      </List>
      <RedirectButton redirectTitle="Map, Charts and Filters" redirectPath="advanced-details" />
      <Title>Subscription</Title>
      <TextSection>
        The sections is only available for the subscribers. For more details about the subscription packages,
        please contact our
        <TextButton onClick={handleModalOpen}>
          sales departments
        </TextButton>
        .
      </TextSection>
      {modalOpen && (
        <SalesDepartmentModal onClose={handleModalClose} />
      )}
    </>
  );
};

export default Overview;
