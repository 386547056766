import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LibraryService from 'library/api/LibraryService';
import * as LibraryActions from 'library/store/library.reducer';
import { filesList, filesSortBy } from 'library/store/library.selectors';
import { formatCommonDate } from 'utils/DateUtils';
import { formatSize, getFileType } from 'utils/FormatUtils';
import { Icon, Table, NoContent, Tooltip } from 'components';
import { showNotifyError } from 'services/toaster';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import useProgress from 'utils/hooks/useProgress';

import FileControls from './FileControls';
import OpenFile from './OpenFile';
import { FilesTableRoot, TypeIconWrapper, Disclaimer } from './styles';

const IMAGE_FORMATS = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];
const VIDEO_FORMATS = ['mp4', 'mov'];
const DOC_FORMATS = ['doc', 'docx', 'xlsx'];

const columns = [
  {
    width: '10%', key: 'icon', title: 'Type', noSort: true,
    render: (data) => (
      <TypeIconWrapper>
        <Icon icon={getFileType(data.name)} fallback="unknown" />
      </TypeIconWrapper>
    ),
  },
  {
    width: '45%', key: 'name', title: 'File Name',
  },
  {
    width: '45%', key: 'status', title: 'Status',
  },
  {
    width: '15%', key: 'size', title: 'Size',
    render: (data) => formatSize(data.size),
  },
  {
    width: '15%', key: 'creationDateTime', title: 'Date',
    render: (data) => formatCommonDate(data.creationDateTime),
  },
  {
    width: '15%', key: 'controls', title: '', align: 'right', noSort: true,
    minWidth: '100px',
    paddingRight: '10px',
  },
];

const FilesTable = ({
  searchFilter, checkedItems, onSetCheckedItems, permissions,
  isLayoutsFolder, isRiskReviewFolder, isUploading, folderId,
  canEditName, openEditName, isFolderOwner,
}) => {
  const dispatch = useDispatch();
  const { role } = AuthService.getUser();

  const [page, setPage] = useState(1);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [opened, setOpened] = useState(false);

  const files = useSelector(filesList);
  const sortBy = useSelector(filesSortBy);

  const {
    setInProgress,
    setCurrentProgressInfo,
    handleDownloadProgress,
    renderProgress,
    clearProgress,
  } = useProgress();

  const isRE = role === ROLES_IDS.RiskEngineer;
  const shouldSeeStatus = (isRE || role === ROLES_IDS.SiteContact) && (isLayoutsFolder || isRiskReviewFolder);

  function handleCheckItems(items) {
    onSetCheckedItems(items);
  }

  function handleSortBy(newSortBy) {
    dispatch(LibraryActions.setFilesSortBy(newSortBy));
  }
  function getFiles() {
    dispatch(LibraryActions.getFiles({ folderId, page, sortBy, searchFilter }));
  }

  function handlePrevPageClick() {
    setPage(page - 1);
  }

  function handleNextPageClick() {
    setPage(page + 1);
  }

  function handleGoToPage(selectedPage) {
    setPage(selectedPage);
  }

  async function markAsOpened(file) {
    if (!isRE) return;
    await LibraryService.markAsOpened(file.id);
    getFiles();
  }

  async function handleOpenFile(file) {
    if (opened || isFileLoading) return;
    try {
      const { downloadUrl, name, size } = file;
      const fileType = getFileType(file.name);

      const isPDF = fileType === 'pdf';
      const isImage = IMAGE_FORMATS.includes(fileType);
      const isVideo = VIDEO_FORMATS.includes(fileType);
      const isDoc = DOC_FORMATS.includes(fileType);
      if (!isPDF && !isImage && !isVideo && !isDoc) {
        return;
      }

      setInProgress(true);
      setCurrentProgressInfo({ name, size });

      if (isVideo || isDoc) {
        await LibraryService.downloadFile(downloadUrl, name, false, handleDownloadProgress);
        clearProgress();
        markAsOpened(file);
        return;
      }

      setIsFileLoading(true);

      if (isPDF) {
        const pdfUrl = await LibraryService.downloadFile(downloadUrl, name, true);
        window.open(pdfUrl);
        setIsFileLoading(false);
        clearProgress();
        markAsOpened(file);
        return;
      }

      const blobUrl = await LibraryService.downloadFile(
        downloadUrl, name, isPDF, handleDownloadProgress, null, true,
      );
      if (blobUrl) {
        setFileInfo({ url: blobUrl, name, isPDF });
      } else {
        setFileInfo({ url: downloadUrl, name });
      }
      setOpened(true);
    } catch (error) {
      showNotifyError(error);
    }
    setIsFileLoading(false);
    clearProgress();
  }

  useEffect(() => {
    setPage(1);
  }, [searchFilter]);

  useEffect(() => {
    if (folderId) {
      getFiles();
    }
    onSetCheckedItems([]);
  }, [page, sortBy, folderId, searchFilter]);

  const { isLoading, items = [], totalPages = 0, totalCount = 0 } = files;

  function renderControls(file) {
    return (
      <FileControls
        file={file}
        onSuccess={getFiles}
        permissions={permissions}
        isLayoutsFolder={isLayoutsFolder}
        isRiskReviewFolder={isRiskReviewFolder}
        markAsOpened={markAsOpened}
        canEditName={canEditName}
        openEditName={openEditName}
        isFolderOwner={isFolderOwner}
            />
    );
  }

  function renderNoContent() {
    if (isUploading) return null;
    const messageUploadAllowed = 'Click "Upload File" to upload a new file to this folder.';
    const messageUploadNotAllowed = 'Once they are added, they will appear here.';
    const descr = permissions.upload ? messageUploadAllowed : messageUploadNotAllowed;
    if (isLayoutsFolder) {
      return (
        <NoContent
          title="There are no files yet in the chosen folder."
          descr={messageUploadAllowed}
                >
          <Disclaimer>
            <Tooltip
              isWarning
              text="Please use PDF files for the layouts, the other formats are not supported. This is recommended to upload one-page documents for better user experience."
                        />
            Only PDF files can be upload to this folder.
          </Disclaimer>
        </NoContent>
      );
    }
    return (
      <NoContent
        title="There are no files yet in the chosen folder."
        descr={!(isRiskReviewFolder && isRE) && descr}
            />
    );
  }

  return (
    <FilesTableRoot>
      <Table
        checkable
        draggable
        items={items}
        columns={columns.filter((t) => {
          if (shouldSeeStatus) {
            return t;
          }
          return t.key !== 'status';
        })}
        sortBy={sortBy}
        isLoading={isLoading}
        checkedItems={checkedItems}
        onCheckItems={handleCheckItems}
        onRowClick={handleOpenFile}
        onSortBy={handleSortBy}
        onNoContent={renderNoContent}
        onRenderControls={renderControls}
        pageSize={5}
        pagination={{
          page,
          totalPages,
          itemsCount: items.length,
          totalCount,
        }}
        onPrevPage={handlePrevPageClick}
        onNextPage={handleNextPageClick}
        onGoToPage={handleGoToPage}
            />
      <OpenFile
        fileInfo={fileInfo}
        opened={opened}
        setOpened={setOpened}
        isLoading={isFileLoading}
            />
      {renderProgress()}
    </FilesTableRoot>
  );
};

export default FilesTable;
