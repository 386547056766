import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NOTIFICATION_ENUM } from 'utils/constants';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const getNotificationByType = (type, notify) => {
  const { data: { projectName, insuredName, policyNumber, companyName } } = notify;

  switch (type) {
    case NOTIFICATION_ENUM.mainReportAvailable:
      return {
        body: `Please be advised that following the inspection for the above project, the Risk Engineering Report is approved by Underwriters for issue.
          You can access the report on your dashboard, download and share this with the relevant stakeholders.`,
        subject: `${projectName} - ${policyNumber}, ${insuredName}`,
        title: 'Risk Engineering Report Available',
      };
    case NOTIFICATION_ENUM.mainReportAvailableWithoutApproval:
      return {
        body: `Please be advised that following the inspection for the above project, the Risk Engineering Report has been automatically approved.
          You can access the report on your dashboard, download and share this with the relevant stakeholders.`,
        subject: `${projectName} - ${policyNumber}, ${insuredName}`,
        title: 'Risk Engineering Report Available',
      };
    case NOTIFICATION_ENUM.mainReportApproved:
      return {
        body: `Please be advised that the Risk Engineering Report for {placeholder} has been approved by the Underwriter at {placeholder}.
          The report has automatically been generated for the Insurance Broker.`,
        subject: projectName,
        title: 'Risk Engineering Report – Approved',
        value: [projectName, companyName],
      };
    case NOTIFICATION_ENUM.mainReportRejected:
      return {
        body: `Following a review of the Risk Engineering Report for {placeholder}, we have found it necessary to reject the report with comments that require your action.
          Please review the suggested amendments and resubmit the report for approval within 3-days.`,
        subject: projectName,
        title: 'Risk Engineering Report - Require Amendments',
        value: [projectName],
      };
    case NOTIFICATION_ENUM.mainReportPending:
      return {
        body: `iMitig8 Risk Global has detected that the Risk Report has not been issued.
          Please generate the report and send it for approval to the Underwriter.`,
        subject: projectName,
        title: 'Risk Engineering Report - Require Amendments',
      };

    case NOTIFICATION_ENUM.abridgedReportPending:
      return {
        body: `iMitig8 Risk Global has detected that the Abridged Report has not been issued to the Insured.
          Please generate the report and issue to the Site Contact on file.`,
        subject: projectName,
        title: 'Abridged Recommendation Report Reminder',
      };
    case NOTIFICATION_ENUM.mainReportIssued:
      return {
        body: 'Please be advised that following the inspection of the above named project, the Risk Report is now available and can be accessed from your dashboard.',
        subject: `${projectName} - ${policyNumber}, ${insuredName}`,
        title: 'Risk Engineering Report Issued',
      };
    case NOTIFICATION_ENUM.abridgedReportApproved:
      return {
        body: 'Please be advised that the Risk Engineering Report for {placeholder} has been approved.',
        subject: projectName,
        title: 'Risk Engineering Report – Approved',
        value: [projectName, companyName],
      };
    default: return null;
  }
};

const ReportNotification = ({ notify, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = AuthService.getUser();
  const isRE = role === ROLES_IDS.RiskEngineer;

  const { data: { projectId } } = notify;
  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToReports = () => {
    handleClose();
    closeNotificationList();
    const reportsRoute = isRE
      ? `/projects/${projectId}/reports`
      : '/dashboard/reports';
    navigate(reportsRoute);
  };

  const { subject, body, title, value } = getNotificationByType(type, notify);

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={subject}
      title={title}
      cancelButtonTitle="Close"
      confirmButtonTitle="View Report"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={redirectToReports}
    >
      <ConfirmationText
        value={value}
        text={body}
      />
    </ConfirmationDialog>
  );
};

export default ReportNotification;
