import React from 'react';

const LogOut = () => {
  return (
    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.813 14.063h5.624a.938.938 0 00.938-.938v-1.406h-.938v1.406H2.814V1.875h5.624v1.406h.938V1.875a.939.939 0 00-.938-.938H2.814a.939.939 0 00-.938.938v11.25a.939.939 0 00.938.938z" fill="#fff" fillOpacity=".87" />
      <path d="M9.65 9.65l1.68-1.681H4.688V7.03h6.644L9.65 5.351l.662-.663L13.126 7.5l-2.813 2.813-.662-.663z" fill="#fff" fillOpacity=".87" />
    </svg>
  );
};

export default LogOut;
