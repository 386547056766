export function projectsList({ projects }) {
  return projects.projectsList;
}

export function currentProject({ projects }) {
  return projects.current;
}

export function projectsSortBy({ projects }) {
  return projects.sortBy;
}

export function currentProjectCurrency({ projects }) {
  return projects.current.currency;
}

export function currentProjectLinePercent({ projects }) {
  return projects.current.linePercent;
}

export function currentProjectType({ projects }) {
  return projects.current.projectType?.value;
}

export function currentProjectStatus({ projects }) {
  return projects.current.status;
}

export function primaryRiskEngineer({ projects }) {
  return projects.current.primaryRiskEngineer;
}

export function primarySiteContact({ projects }) {
  return projects.current.primarySiteContact;
}

export function isPrimarySiteContact({ projects }) {
  return projects.projectSCData.isPrimarySiteContact;
}

export function canUploadFiles({ projects }) {
  return projects?.projectSCData.canUploadDocuments ?? true;
}

export function riskScoreMax({ projects }) {
  return projects.current?.riskScoreMax ?? projects.projectSCData.riskScoreMax ?? 1000;
}

export function isProjectClosed({ projects }) {
  let tempStatus = projects.current.status === 'Closed';

  if (!tempStatus) {
    tempStatus = projects.projectSCData.status === 'Closed';
  }

  return tempStatus;
}

export function projectDropdown({ projects }) {
  return projects.projectDropdown.data || [];
}

export function disclaimerCompanyName({ projects }) {
  return projects.current.disclaimerCompanyName;
}

export function projectPremiumValues({ projects }) {
  const { brPremium, dsuPremium, triaPremium } = projects.current || {};
  const premiumValues = { brPremium, dsuPremium, triaPremium };

  return premiumValues;
}
