import React from 'react';

const Doc = () => {
  return (
    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity=".9" fill="#fff" fillOpacity=".87">
        <path d="M24.67 8.933l-6.767-6.768a.879.879 0 00-.676-.29H6.62A1.934 1.934 0 004.687 3.81v23.203a1.934 1.934 0 001.934 1.934h16.406a1.934 1.934 0 001.934-1.934V9.61a.88.88 0 00-.29-.677zm-7.443-4.737L22.64 9.61h-5.414V4.196zm5.8 22.816H6.621V3.809h8.672v5.8a1.934 1.934 0 001.934 1.934h5.8v15.469z" />
        <path d="M8.492 21h1.29c1.506 0 2.232-.888 2.232-2.136v-.054c0-1.248-.726-2.1-2.22-2.1H8.492V21zm.798-.63v-3.03h.462c1.008 0 1.428.558 1.428 1.482v.048c0 .948-.462 1.5-1.422 1.5H9.29zm5.441.69c1.302 0 2.16-.942 2.16-2.196v-.048c0-1.26-.882-2.166-2.16-2.166-1.272 0-2.172.93-2.172 2.184v.048c0 1.236.858 2.178 2.172 2.178zm.012-.642c-.78 0-1.35-.606-1.35-1.542v-.048c0-.924.498-1.542 1.338-1.542.84 0 1.332.654 1.332 1.536v.048c0 .93-.51 1.548-1.32 1.548zm4.797.642c1.02 0 1.752-.522 1.878-1.554h-.78c-.084.606-.504.906-1.098.906-.792 0-1.254-.57-1.254-1.536v-.048c0-.96.492-1.548 1.248-1.548.618 0 .936.294 1.026.864h.81c-.096-1.008-.858-1.5-1.842-1.5-1.194 0-2.082.9-2.082 2.19v.048c0 1.32.75 2.178 2.094 2.178z" />
      </g>
    </svg>
  );
};

export default Doc;
