import styled from 'styled-components';
import Autocomplete from '@mui/lab/Autocomplete';

export const InputWrapper = styled.div`
  .Mui-disabled {
    opacity: 0.5
  }

  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}
`;

export const StyledOption = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  & li.MuiAutocomplete-option {
    height: 40px;
  }
  & li.MuiAutocomplete-option[data-focus="true"] {
    background-color: rgba(115, 119, 140, 0.5);
  }
  & > div > .MuiAutocomplete-input {
    padding: 0;
  }
  & > div > .MuiAutocomplete-input > .MuiAutocomplete-input {
    padding-top: 1px;
    padding-bottom: 0;
    padding-left: 12px;
    ${({ $noIcon }) => (!$noIcon && 'padding-left: 40px;')}
  }
`;
