import React from 'react';
import { useDispatch } from 'react-redux';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

const ContractValueIncreased = ({ notify }) => {
  const dispatch = useDispatch();
  const { data: { increasePercent, fromValue, projectName, toValue } } = notify;

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="Contract Value Increased"
      cancelButtonTitle="Close"
      onClose={handleClose}
      onCancel={handleClose}
      noConfirm
    >
      <ConfirmationText
        value={[projectName, fromValue, toValue, increasePercent]}
        text="iMR Global has detected that there has been an increase in the Contract Value for {placeholder} from {placeholder} to {placeholder}. This represents a {placeholder}% increase.
        You may wish to review and discuss this with the risk engineer and the insurance broker."
      />
    </ConfirmationDialog>
  );
};

export default ContractValueIncreased;
