import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 572px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin-bottom: 24px;

  > div {
    margin-right: 15px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 125px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: Avenir;
  font-size: 24px;
  & > * {
    margin-left: 8px;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: Avenir;
  font-size: 14px;
`;
