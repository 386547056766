import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  button.MuiButton-text {
    .MuiButton-startIcon {
      margin-left: 0px;
    }
  }
`;
