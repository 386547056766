import React from 'react';

import Button from 'components/buttons/Button';

import { ButtonText, Counter } from './styles';

const crossIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
    <path fill="#96CEF6" fillRule="evenodd" d="M15.5 7.287l-.787-.787L11 10.213 7.287 6.5l-.787.787L10.213 11 6.5 14.713l.787.787L11 11.787l3.713 3.713.787-.787L11.787 11 15.5 7.287z" clipRule="evenodd" />
  </svg>
);

const ClearButton = ({ onClick, filtersCount }) => {
  return (
    <Button
      small
      color="primary"
      variant="text"
      onClick={onClick}
    >
      {crossIcon}
      <ButtonText>
        Clear Filters
      </ButtonText>
      <Counter>
        {filtersCount}
      </Counter>
    </Button>
  );
};

export default ClearButton;
