import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const Root = styledMui('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: 16,
  color: theme.palette.text.main,
}));

export const Controls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 190px;
`;
