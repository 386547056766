import { css } from 'styled-components';

const breakpoints = {
  xs: '320px',
  sm: '480px',
  md: '768px',
  lg: '1024px',
};

const respondTo = (breakpoint, ...args) => css`
  @media (max-width: ${breakpoint}) {
    ${css(...args)};
  }
`;

export const mobileXs = (...args) => respondTo(breakpoints.xs, ...args);

export const mobile = (...args) => respondTo(breakpoints.sm, ...args);

export const tabletPortrait = (...args) => respondTo(breakpoints.md, ...args);

export const tablet = (...args) => respondTo(breakpoints.lg, ...args);
