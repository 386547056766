import qs from 'qs';
import ApiClient from 'services/ApiClient';

class ReportsService extends ApiClient {
  getReportsList(projectId) {
    return this.call({
      method: 'get',
      url: `/reports?${qs.stringify({ projectId })}`,
    });
  }

  getPendingReport(projectId) {
    return this.call({
      method: 'get',
      url: `/reports/pending?${qs.stringify({ projectId })}`,
    });
  }

  getReportData(id) {
    return this.call({
      method: 'get',
      url: `/reports/report-data?${qs.stringify({ id })}`,
    });
  }

  getContactsForDropdown(data) {
    return this.call({
      method: 'get',
      url: `/reports/share/dropdown?${qs.stringify(data)}`,
    });
  }

  createMainReport(data) {
    return this.call({
      method: 'post',
      url: '/reports/main',
      data,
    });
  }

  updateMainReport(data) {
    return this.call({
      method: 'put',
      url: '/reports/main',
      data,
    });
  }

  sendMainReportForApproval(data) {
    return this.call({
      method: 'put',
      url: '/reports/main/send-for-approval',
      data,
    });
  }

  shareReport(data) {
    return this.call({
      method: 'post',
      url: '/reports/share',
      data,
    });
  }

  createAbridgedReport(data) {
    return this.call({
      method: 'post',
      url: '/reports/abridged',
      data,
    });
  }

  approveAbridgedReport({ id }) {
    return this.call({
      method: 'put',
      url: `/reports/abridged/${id}/approve`,
      data: { id },
    });
  }

  rejectAbridgedReport({ id }) {
    return this.call({
      method: 'put',
      url: `/reports/abridged/${id}/reject`,
      data: { id },
    });
  }

  createPreliminaryReport(data) {
    return this.call({
      method: 'post',
      url: '/reports/preliminary',
      data,
    });
  }

  getReportConfiguration(id) {
    return this.call({
      method: 'get',
      url: `/reports/main/${id}/configuration`,
    });
  }

  getAbridgedReportNeedsApproval(data) {
    return this.call({
      method: 'get',
      url: `/reports/abridged/requires-approval?${qs.stringify(data)}`,
    });
  }
}

export default new ReportsService();
