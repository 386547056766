import React from 'react';

const Policy = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.875 10.124C16.8761 9.56075 16.7363 9.00616 16.4682 8.5108C16.2001 8.01543 15.8123 7.59504 15.3401 7.28794C14.8679 6.98084 14.3264 6.7968 13.7649 6.7526C13.2034 6.70839 12.6397 6.80543 12.1253 7.03486C11.6109 7.26429 11.1621 7.61881 10.8197 8.06613C10.4774 8.51344 10.2525 9.03931 10.1655 9.59581C10.0785 10.1523 10.1322 10.7217 10.3216 11.2522C10.511 11.7826 10.8302 12.2572 11.25 12.6328V16.874L13.5 15.8094L15.75 16.874V12.6328C16.1036 12.3174 16.3866 11.9309 16.5805 11.4986C16.7744 11.0662 16.8747 10.5978 16.875 10.124ZM14.625 15.0965L13.5 14.564L12.375 15.0965V13.3021C13.102 13.5647 13.898 13.5647 14.625 13.3021V15.0965ZM13.5 12.374C13.055 12.374 12.62 12.242 12.25 11.9948C11.88 11.7476 11.5916 11.3962 11.4213 10.985C11.251 10.5739 11.2064 10.1215 11.2932 9.68505C11.38 9.2486 11.5943 8.84769 11.909 8.53302C12.2237 8.21835 12.6246 8.00406 13.061 7.91724C13.4975 7.83042 13.9499 7.87498 14.361 8.04528C14.7722 8.21558 15.1236 8.50396 15.3708 8.87398C15.618 9.24399 15.75 9.679 15.75 10.124C15.7493 10.7205 15.5121 11.2924 15.0902 11.7143C14.6684 12.1361 14.0965 12.3733 13.5 12.374Z" fill="#96CEF6" />
      <path d="M9 7.875H5.0625V9H9V7.875Z" fill="#96CEF6" />
      <path d="M10.6875 4.5H5.0625V5.625H10.6875V4.5Z" fill="#96CEF6" />
      <path d="M3.375 16.875C3.07673 16.8747 2.79076 16.7561 2.57985 16.5451C2.36894 16.3342 2.25031 16.0483 2.25 15.75V2.25C2.25031 1.95173 2.36894 1.66576 2.57985 1.45485C2.79076 1.24394 3.07673 1.12531 3.375 1.125H12.375C12.6733 1.12531 12.9592 1.24394 13.1701 1.45485C13.3811 1.66576 13.4997 1.95173 13.5 2.25V4.5H12.375V2.25H3.375V15.75H9V16.875H3.375Z" fill="#96CEF6" />
    </svg>
  );
};

export default Policy;
