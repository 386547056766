import ApiClient from 'services/ApiClient';

class InviteService extends ApiClient {
  approve(inviteId) {
    return this.call({
      method: 'put',
      url: `/invites/${inviteId}/user/approve`,
    });
  }

  reject(inviteId, rejectionReason) {
    return this.call({
      method: 'put',
      url: '/invites/user/reject',
      data: {
        id: inviteId,
        rejectionReason,
      },
    });
  }

  resend(contactId) {
    return this.call({
      method: 'put',
      url: '/invites/user/contact/resend',
      data: {
        contactId,
      },
    });
  }

  resendTeamMember(teamMemberId) {
    return this.call({
      method: 'put',
      url: '/invites/user/team-member/resend',
      data: {
        teamMemberId,
      },
    });
  }

  approveCompany(inviteId) {
    return this.call({
      method: 'put',
      url: `/invites/${inviteId}/company/risk/approve`,
    });
  }

  rejectCompany(inviteId, rejectionReason) {
    return this.call({
      method: 'put',
      url: '/invites/company/risk/reject',
      data: {
        id: inviteId,
        rejectionReason,
      },
    });
  }
}

export default new InviteService();
