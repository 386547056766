import { configureStore, Tuple } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

// import { applyMiddleware, compose } from 'redux';

import apiCallMiddleware from './apiCallMiddleware';
import payloadMiddleware from './payloadMiddleware';

import rootReducer, {
  createReduxHistory,
  routerMiddleWare,
} from './rootReducer';

// const initialState = {};

const store = configureStore({
  reducer: rootReducer,
  // initialState,
  middleware: () => new Tuple(
    routerMiddleWare,
    thunk,
    apiCallMiddleware,
    payloadMiddleware,
    // window.__REDUX_DEVTOOLS_EXTENSION__
    //   ? window.__REDUX_DEVTOOLS_EXTENSION__()
    //   : (f) => f,
  ),
});

// if (module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     store.replaceReducer(rootReducer);
//   });
// }

export const history = createReduxHistory(store);

export default store;
