import React from 'react';

const Jpg = () => {
  return (
    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity=".9" fill="#fff" fillOpacity=".87">
        <path d="M24.67 8.933l-6.767-6.768a.879.879 0 00-.676-.29H6.62A1.934 1.934 0 004.687 3.81v23.203a1.934 1.934 0 001.934 1.934h16.406a1.934 1.934 0 001.934-1.934V9.61a.88.88 0 00-.29-.677zm-7.443-4.737L22.64 9.61h-5.414V4.196zm5.8 22.816H6.621V3.809h8.672v5.8a1.934 1.934 0 001.934 1.934h5.8v15.469z" />
        <path d="M10.388 21.054c.792 0 1.326-.426 1.326-1.356V16.71h-.804v2.97c0 .528-.252.732-.642.732a1.34 1.34 0 01-.552-.108v.624c.138.072.318.126.672.126zM12.652 21h.798v-1.632h.6c.918 0 1.572-.402 1.572-1.308v-.024c0-.9-.63-1.326-1.59-1.326h-1.38V21zm.798-2.208v-1.464h.582c.51 0 .81.198.81.708v.024c0 .474-.282.732-.81.732h-.582zm4.814 2.268c1.212 0 1.956-.72 1.956-1.908v-.396h-1.866v.6h1.08c-.024.594-.354 1.08-1.158 1.08-.9 0-1.326-.63-1.326-1.548v-.048c0-.942.486-1.566 1.326-1.566.558 0 .942.246 1.038.816h.798c-.114-1.026-.93-1.446-1.848-1.446-1.254 0-2.142.912-2.142 2.19v.048c0 1.266.768 2.178 2.142 2.178z" />
      </g>
    </svg>
  );
};

export default Jpg;
