import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 20px auto 40px auto;
  font-family: Graphik LC;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: var(--textColor);

  @media (max-height: 800px) {
    margin: 1% auto 5% auto;
  } 
`;

export const Description = styled.div`
  margin-top: 50px;
  font-family: Graphik LC;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: var(--textColor);

  @media (max-height: 800px) {
    margin-top: 3%;
  }
`;

export const Root = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const PreviewImage = styled.img`
  max-width: 520px;
  max-height: 70%;
`;
