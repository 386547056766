import React, { useState } from 'react';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import {
  Content,
  IntroBlock,
  SubTitle,
  List,
  StyledLink,
  TextSection,
} from './styles';

const PrivacyPolicyAgreement = ({ accept, close }) => {
  const [canAccept, setCanAccept] = useState(false);
  const onScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1;
    if (bottom) {
      setCanAccept(true);
    } else {
      setCanAccept(false);
    }
  };
  return (
    <ConfirmationDialog
      open
      onClose={close}
      onConfirm={accept}
      onCancel={close}
      title="Privacy Policy"
      confirmButtonTitle="I Agree"
      cancelButtonTitle="Decline"
      disabledConfirm={!canAccept}
      maxWidth="md"
    >
      <Content onScroll={onScroll}>
        <IntroBlock>
          <div>Mitig8 Risk LLC</div>
          <div>iMitig8 Risk GLOBAL </div>
          <div>Privacy Policy</div>
          <div>Version: 2.0 – 1 December 2022</div>
        </IntroBlock>
        <TextSection>
          iMitig8 Risk LLC (The Company) takes privacy seriously, and we want
          you to know how we collect, store, use, share and protect your
          personal information.
        </TextSection>
        <TextSection>
          This Privacy Policy (“Policy”), including its Appendix 1, applies to
          personal information about each user collected or stored by the
          Company by or through its mobile applications provided under its
          product name iMtig8 Risk GLOBAL (the “Application”) and websites
          (collectively, the Application and websites are referred to as the
          “Services”). Please read this Policy carefully before using the
          Services.
        </TextSection>
        <TextSection>
          The Services provide the means to submit content such as information,
          text, photographs, video, documents, and other media content (“User
          Content”) relating to a project (“Project”) initiated by an insurance
          provider as part of its insurance renewal, inspection, and/or other
          related products and services (“Applicable Insurer”). You, an end-user
          of the Services (“End User”) are an agents, employees and contractors
          of the Applicable Insurer, individual service providers and staff of
          business entities providing services with respect to each Project or
          other third party permitted to access a Project.
        </TextSection>
        <TextSection>
          You, as an End User, are required to submit Personal Information when
          registering with the Company to access and use the Services or
          collected during your use of the Services. Your Personal Information
          is essential to ensuring the proper attribution of all User Content.
        </TextSection>
        <TextSection>
          “Personal information” is information that could reasonably be used to
          identify a person individually, such as their name, e-mail address,
          mailing address, and telephone number, and any other personal
          information about you that is protected by applicable law.
        </TextSection>
        <SubTitle>Information We Collect</SubTitle>
        <TextSection>
          We collect information that you enter through our Services. For
          example, we collect information from you when you create or update an
          account (an “Account”), e-mail us with a question, or use the
          Services. We may collect the following Personal Information about you:
        </TextSection>
        <SubTitle>User Contact Information</SubTitle>
        <List>
          <li>Your login credentials for your Account</li>
          <li>First Name</li>
          <li>Last Name</li>
          <li>Email</li>
          <li>Company</li>
          <li>Designation</li>
          <li>Country</li>
          <li>State / Region</li>
          <li>City</li>
          <li>Zip Code</li>
          <li>Address</li>
          <li>Phone Number</li>
        </List>
        <SubTitle>Tracking and Other Data.</SubTitle>
        <List>
          <li>Tracking Cookies</li>
          <li>
            Image metadata, including geolocation tags, in content you submit
          </li>
          <li>
            Geolocation tags using Insured Project’s coordinates through
            automated means, metadata in images.
          </li>
        </List>
        <SubTitle>Company Information</SubTitle>We maintain information on your
        association within the Services with one or more businesses. For such
        businesses, we collect:
        <List>
          <li>Company Name</li>
          <li>Country</li>
          <li>State / Region</li>
          <li>City</li>
          <li>Zip Code</li>
          <li>Address</li>
          <li>Phone Number</li>
          <li>
            Primary Contact (of your company dedicated to use the Services)
          </li>
        </List>
        <SubTitle>How We Use Collected Personal Information</SubTitle>
        <TextSection>
          We use the Personal Information we collect to accomplish the purpose
          of the Services, to serve our customers and applicable third parties,
          and improve their experience with the Services, analyze and use
          aggregated data, some examples being:
        </TextSection>
        <List>
          <li>
            Attribute User Content to the appropriate businesses (for example,
            attributing your content to your employer, if any), individual users
            and businesses.
          </li>
          <li>Provide a seamless workflow between the stakeholders</li>
          <li>Reduce the time required to perform inspections</li>
          <li>
            Provide essential risk score information to assist stakeholders
            reduce risk
          </li>
          <li>
            Provide affirmative action to prevent insurance claims and repeat
            insurance events
          </li>
          <li>Efficiently processing, storing, and transferring information</li>
          <li>Improving customer service</li>
          <li>Continuously evaluating and improving our Users’ experience</li>
          <li>Provide risk modelling and analysis</li>
          <li>
            Provide weather related risk through geo-locations and weather data
          </li>
          <li>Otherwise further each Project’s objectives</li>
        </List>
        <TextSection>
          Your Personal Information helps the Applicable Insurer and other third
          parties to understand the source and purpose of User Content submitted
          by you. As such, in many or most circumstances, the continued access
          to your Personal Information, and the relationship between your
          identity and your User content within a Project, is essential to the
          risk management and regulatory compliance of the Applicable Insurer
          and other third parties.
        </TextSection>
        <SubTitle>How We Share Collected Information</SubTitle>
        <TextSection>
          We do not sell, rent or trade your Personal Information with third
          parties. However, the following are some of the ways we share Personal
          Information:
        </TextSection>
        <List>
          <li>
            To Applicable Insurers, other End Users and the businesses they
            represent, as part of, or related to, their use of the Services.
          </li>
          <li>
            To Insurance / Re-insurance companies, insurance brokers, risk
            company vendors, risk managers and site respondents (the responsible
            person selected and authorized to update risk recommendation
            action). All of these entities are obligated to keep the information
            that we provide to them confidential and to use the information only
            for the purpose for which the information was provided.
          </li>
        </List>
        <TextSection>
          Insurance Related Purposes: We may also share Personal Information for
          other permitted purposes, including:
        </TextSection>
        <List>
          <li>
            with an insurance company or other underwriting organization as
            permitted by applicable law
          </li>
          <li>with reinsurers as permitted by applicable law</li>
          <li>
            with insurance-support organizations that detect and prevent fraud
          </li>
          <li>with consumer reporting agencies as permitted by law</li>
          <li>
            with an insurance company or other underwriting organization as
            permitted by applicable law
          </li>
          <li>
            with state insurance departments or other governmental or law
            enforcement authorities if required by law or to protect our legal
            interests or in cases of suspected fraud or illegal activities
          </li>
          <li>if ordered by a subpoena, search warrant or other court order</li>
        </List>
        <SubTitle>Anonymized Data.</SubTitle>
        <TextSection>
          We do not share your account passwords with anyone, including you. We
          instead provide tools for you to confidentially reset your password.
        </TextSection>
        <SubTitle>Anonymized Data.</SubTitle>
        <TextSection>
          In addition to the uses of your Personal Information we have
          identified elsewhere in this Policy, we reserve the right to use, sell
          and disclose any User Content, including Personal Information, that is
          (i) effectively anonymized, pseudonymized and/or aggregated data so
          that it does not reveal the specific identity of any individual; and
          (ii) its use, sale and disclosure of such data (as prepared in
          accordance with subsection(i)) will comply with applicable laws.
        </TextSection>
        <SubTitle>iMitig8 Risk GLOBAL entities</SubTitle>
        <TextSection>
          If you provide information or User Content to the Company, or any of
          its subsidiaries or affiliates, on any of the websites owned or
          operated by iMitig8 Risk LLC, or its subsidiaries or affiliates, this
          information or User Content may be combined or shared among other
          current or future iMitig8 Risk LLC entities, including subsidiaries
          and affiliates, and each of their successors and assigns.
        </TextSection>
        <SubTitle>Third party service providers</SubTitle>
        <TextSection>
          We may also share information or User Content with third parties that
          are under contract with the Company, or its subsidiaries or
          affiliates, who contribute to the Company providing the Services,
          auditing, professional advisors and other commercially appropriate
          activities related to the Services and the regulatory compliance
          obligations of the Company.
        </TextSection>
        <SubTitle>Required disclosures</SubTitle>
        <TextSection>
          We may be required to disclose Personal Information in a court
          proceeding, or in response to a court order, subpoena, civil discovery
          request, other legal process, or as otherwise required by law.
        </TextSection>
        <SubTitle>Legal Compliance and Protections</SubTitle>
        <TextSection>
          We may disclose Personal Information when we believe disclosure is
          necessary to comply with the law or protect the rights, property, or
          safety of iMitig8 Risk LLC, its subsidiaries or affiliates, our users,
          or others.
        </TextSection>
        <SubTitle>Corporate Transactions</SubTitle>
        <TextSection>
          We reserve the right to disclose and transfer Personal Information: to
          a subsequent owner, co-owner, or operator of the Services.
        </TextSection>
        <TextSection>
          In connection with a corporate merger, consolidation, the sale of
          substantially, or all of our membership interests and/or assets or
          other corporate change, including to any prospective purchasers.
        </TextSection>
        <SubTitle>How We Protect the Information We Collect</SubTitle>
        <TextSection>
          We work to secure all information and User Content from being lost,
          accessed, used, modified, or disclosed by/to unauthorized persons.
          During transmission of personal information between you and our
          Services.
        </TextSection>
        <TextSection>
          All Personal Information is stored in systems that is encrypted using
          AES-256 with keys managed through Amazon Web Services’ Key Management
          Service. All data is stored securely and subject to the security
          policies and procedures of AWS.
        </TextSection>
        <TextSection>
          We encrypt many of our services using Secure Sockets Layer (SSL).
        </TextSection>
        <TextSection>
          The transmission of information via the internet is not completely
          secure. Although we will do our best to protect your personal data, we
          cannot guarantee the security of your data as transmitted to or from
          our servers via third party networks; any transmission is at your own
          risk. Once we have received your information, we use commercially
          appropriate procedures and security features to try to prevent
          unauthorized access.
        </TextSection>
        <TextSection>
          The information of our Users and all applicable customers and third
          parties is restricted in our offices. Only employees who need the
          information to perform a specific deliverable are granted access to
          personally identifiable information. All employees with access to
          personal information are kept up-to-date on our security and privacy
          practices. No personally identifiable information is kept in
          on-premises servers.
        </TextSection>
        <TextSection>
          It is important for you to protect against unauthorized access to your
          Account by keeping your password secret and access to your computer
          and mobile device secured.
        </TextSection>
        <TextSection>
          The Company may require you to periodically change your password. If
          so, you will be sent a reminder from the Company or a third-party
          vendor of the company to remind you to update your password.
        </TextSection>
        <TextSection>
          You are obligated to notify the Company if you leave your current
          organization or no longer require access to our Services.
        </TextSection>
        <TextSection>
          Please note that despite our reasonable efforts, no security measure
          is ever perfect or impenetrable, so we cannot guarantee the security
          of your personal information.
        </TextSection>
        <SubTitle>How Long We Keep Data and Information</SubTitle>
        <TextSection>
          We will retain your Personal Information and data for as long as you
          are a registered user of the Services, continue to maintain a copy of
          our Applications on any device, or to the extent your Personal
          Information is necessary to identify to Projects. Once the Project
          file is closed and deleted, the data for that Project relating to
          personal information is no longer required and will be deleted or
          anonymized. We will continue to use anonymized data about the project
          or property to assist in the predictive modelling of our Service.
        </TextSection>
        <SubTitle>Updating our Policy</SubTitle>
        <TextSection>
          We will continue to review and update our policy in accordance with
          local laws. The latest version will be dated at the head of our
          policy.
        </TextSection>
        <SubTitle>
          Updating, Editing, and Deleting User Content and Information
        </SubTitle>
        <TextSection>
          You may request that we update, delete and correct your Personal
          Information, in your Account or by contacting us. You may, make such
          request by contacting us at
          <StyledLink href="mailto:support@imitig8risk.com">
            support@imitig8risk.com
          </StyledLink>
          where we will respond to all such requests in accordance with
          applicable law. We will update, delete and correct all such Personal
          Information as required by applicable law. If we provide means for you
          to update or correct your Personal Information, you agree that in
          doing so, your Personal Information will remain complete and accurate.
        </TextSection>
        <SubTitle>CONTACT. </SubTitle>
        <TextSection>
          If you have any questions about our Privacy Policy including the
          attached
        </TextSection>
        <TextSection>
          Appendix 1, please contact as by sending an email to:
        </TextSection>
        <TextSection>
          Email Address:
          <StyledLink href="mailto:info@imitig8risk.com">
            info@imitig8risk.com
          </StyledLink>
        </TextSection>
        <SubTitle>Appendix 1 – Your Rights </SubTitle>
        <SubTitle>(a) GDPR</SubTitle>
        <TextSection>
          For the purpose of this Privacy Policy the controller of personal data
          is iMitig8 Risk LLC and our contact details are set out in the Contact
          section above.
        </TextSection>
        <SubTitle>The Legal Basis for Processing your Information</SubTitle>
        <TextSection>
          Under GDPR, the main grounds that we rely upon in order to process
          personal data collected via our websites and services are the
          following:
        </TextSection>
        <List>
          <li>
            Necessary for entering into, or performing, a contract – in order to
            perform obligations that we undertake in providing a service to you,
            or in order to take steps at your request to enter into a contract
            with us, it will be necessary for us to process your personal data;
          </li>
          <li>
            Necessary for compliance with a legal obligation – we are subject to
            certain legal requirements which may require us to process and hold
            your personal data. We may also be obliged by law to disclose your
            personal data to a regulatory body or law enforcement agency;
          </li>
          <li>
            Necessary for the purposes of legitimate interests – either we, or a
            third party, will need to process your personal data for the
            purposes of our (or a third party’s) legitimate interests, provided
            we have established that those interests are not overridden by your
            rights and freedoms, including your right to have your personal data
            protected. Our legitimate interests include responding to requests
            and enquiries from you or a third party, optimizing our website,
            applications and customer experience, informing you about our
            products and services and ensuring that our operations are conducted
            in an appropriate and efficient manner;
          </li>
          <li>
            Consent – in some circumstances, we may ask for your consent to
            process your personal data in a particular way.
          </li>
        </List>
        <SubTitle>Third Party Service Providers</SubTitle>
        <TextSection>
          As mentioned above, we will share your personal information with
          trusted third parties where we have retained them to provide services
          that you or our clients have requested, and to perform maintenance or
          respond to technical incidents affecting our services. Our current
          third-party service providers are listed in Appendix 2.
        </TextSection>
        <TextSection>
          Where we disclose personal information to third parties, we require
          minimum standards of confidentiality and data protection from such
          third parties.
        </TextSection>
        <SubTitle>
          Processing Outside of the European Economic Area (“EEA”)
        </SubTitle>
        <TextSection>
          To the extent that any personal information is provided to third
          parties outside the EEA, or who will access the information from
          outside the EEA, we will ensure that approved safeguards are in place
          to ensure that we comply with GDPR.
        </TextSection>
        <TextSection>
          iMitig8 Risk LLC may process personal information on our servers in
          many countries around the world, including outside of the EEA. We may
          process your personal information on a server located outside the
          country where you live, including outside the EEA. The primary
          location of user data and data uploaded to our products is a
          datacenter in the U.S. operated by our third-party cloud hosting
          provider, Amazon Web Services (“AWS”).
        </TextSection>
        <TextSection>
          AWS is a participant in the EU/US Privacy Shield, under which
          transfers of personal data to the U.S. are authorized.
        </TextSection>
        <SubTitle>Right of Access </SubTitle>
        <TextSection>
          You have the right at any time to ask us for a copy of the personal
          information about you that we hold. Where we have good reason, and if
          the GDPR permits, we can refuse your request for a copy of your
          personal information, or certain elements of the request. If we refuse
          your request or any element of it, we will provide you with our
          reasons for doing so.
        </TextSection>
        <SubTitle>Right of Correction or Completion</SubTitle>
        <TextSection>
          If personal information we hold about you is not accurate, out of date
          or incomplete, you have a right to have the data rectified, updated or
          completed. You can let us know by contacting us at
          <StyledLink href="mailto:support@imitig8risk.com">
            support@imitig8risk.com
          </StyledLink>
          .
        </TextSection>
        <SubTitle>Right of Erasure</SubTitle>
        <TextSection>
          In certain circumstances, you may have the right to request that
          personal information we hold about you is erased e.g., if the
          information is no longer necessary for the purposes for which it was
          collected or processed or our processing of the information is based
          on your consent and there are no other legal grounds on which we may
          retain and process the information.
        </TextSection>
        <SubTitle>Right of Data Portability</SubTitle>
        <TextSection>
          In certain instances, you have a right to receive any personal
          information that we hold about you in a structured, commonly used and
          machine-readable format. You can ask us to transmit that information
          to you or directly to a third party organization.
        </TextSection>
        <TextSection>
          The above right exists only in respect of personal information that:
        </TextSection>
        <List>
          <li>you have provided to us previously; and</li>
          <li>is processed by us using automated means.</li>
        </List>
        <TextSection>
          While we are happy for such requests to be made, we are not able to
          guarantee technical compatibility with a third party organization’s
          systems. We are also unable to comply with requests that relate to
          personal information of others without their consent.{' '}
        </TextSection>
        <TextSection>
          Most of the above rights are subject to limitations and exceptions. We
          will provide reasons if we are unable to comply with any request for
          the exercise of your rights.{' '}
        </TextSection>
        <TextSection>
          To the extent that we are processing your personal information based
          on your consent, you have the right to withdraw your consent at any
          time. You can do this by contacting us using the details in the
          Contact section above.{' '}
        </TextSection>
        <SubTitle>Automated decision-making</SubTitle>
        <TextSection>
          Automated decision-making takes place when an electronic system uses
          personal information to make a decision without human intervention. It
          is specifically regulated under GDPR where such decisions are taken
          which have legal or other significant effects on individuals. It is
          permitted in the following circumstances:
        </TextSection>
        <List>
          <li>
            Where it is necessary to enter into or perform our contract with you
            and appropriate measures are in place to safeguard your rights.
          </li>
          <li>
            Where it is necessary to enter into or perform our contract with you
            and appropriate measures are in place to safeguard your rights
          </li>
        </List>{' '}
        <TextSection>
          You will not be subject to decisions that will have a significant
          impact on you based solely on automated processing, unless we have a
          lawful basis for doing so, we have notified you and given you a right
          to challenge the decision or to require that the decision be taken by
          a person.
        </TextSection>
        <SubTitle>(b) California Consumer Privacy Act (“CCPA”)</SubTitle>
        <TextSection>
          The CCPA grants California residents certain additional rights
          regarding the personal information that iMitig8 Risk LLC may collect,
          disclose or sell. For purposes of this section, “Personal Information”
          means anything that identifies, relates to, describes, is reasonably
          capable of being associated with, or could be reasonably linked,
          directly or indirectly, with a particular California consumer or
          household. We do not collect, use or sell Personal Information of
          children age 16 or under.
        </TextSection>
        <TextSection>
          Individuals with disabilities may access this policy in an alternative
          format by sending an email to: completed. You can let us know by
          contacting us at
          <StyledLink href="mailto:info@imitig8risk.com">
            info@imitig8risk.com
          </StyledLink>
        </TextSection>
        <SubTitle>Your Disclosure Rights</SubTitle>
        <TextSection>As provided in this Privacy Policy:</TextSection>
        <List>
          <li>
            iMitig8 Risk LLC may share your Personal Information with third
            parties as provided in the “Information we share” section above and
            Appendix 1.
          </li>
          <li>
            iMitig8 Risk LLC does not sell (as such term is defined in the CCPA)
            the Personal Information that we may collect from you.
          </li>
        </List>
        <SubTitle>Requests</SubTitle>
        <TextSection>
          The CCPA also provides California residents with the right to request
          additional details about the Personal Information we collect
          (including how we use and disclose this information and whether it is
          sold) and, if necessary, the right to delete your Personal
          Information, subject to exceptions provided in the CCPA.
        </TextSection>
        <TextSection>
          California residents may make a request pursuant to your rights under
          the CCPA by contacting us at{' '}
          <StyledLink href="mailto:info@imitig8risk.com">
            info@imitig8risk.com
          </StyledLink>
          . To ensure that the request is coming from you and to protect the
          security of your Personal Information, we will verify your request
          using 2 out of the following 4 data points to verify your identify:
          (1) email address; (2) telephone number; (3) description of the
          product or service you purchased or inquired about, and (4) the
          security code from your credit card (if applicable). If you are
          requesting to delete sensitive information, you must provide us with
          us with 3 out of the following 4 data points described above to verify
          your identity. Government identification may be required.
        </TextSection>
        <TextSection>
          We also commit to not discriminate against any California consumers
          because you exercise any of your rights. To read more about the CCPA
          please visit California Legislative Information.
        </TextSection>
        <SubTitle>Non-discrimination</SubTitle>
        <TextSection>
          You have the right to receive our products and services on equal terms
          regardless of whether or not you exercise your rights under the CCPA.
          To read more about the CCPA please visit California Legislative
          Information.
        </TextSection>
      </Content>
    </ConfirmationDialog>
  );
};
export default PrivacyPolicyAgreement;
