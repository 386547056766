import React from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import RedirectButton from 'help/components/RedirectButton';

import { Title, TextSection } from '../../styles';

const imageSrc = 'my-company-rates.png';

const Rates = () => (
  <>
    <ImageContainer src={imageSrc} alt="rates" />
    <Title>Overview</Title>
    <TextSection>
      The section allows you to manage the time and expenses rates of your company.
    </TextSection>
    <Title>Time Rates</Title>
    <TextSection>
      The time rates is the amount that the company charges for performing a specific set of activities.
      The time rates are measured  hourly and are defined based on the Risk Engineer designation.
    </TextSection>
    <TextSection>
      For example, the hourly rate for Travel category for the Senior Risk Engineer
      can be set up higher than for the Surveyor.
    </TextSection>
    <Title>Expense Rates</Title>
    <TextSection>
      The expense rates is the amount that the company charges for the typical set of expenses that take place
      during the project such as Photocopying, Printing, Setting up files, etc. When the rates for these categories
      are set up within that section, the amount will be automatically pulled for the risk engineer when they populate
      their expenses for such categories.
    </TextSection>
    <TextSection>
      For the other set of expenses (that usually vary from project to project) such as Flight, Taxi, Hotel, etc. -
      the expenses are manually populated by the risk engineer after or during the inspection without pulling
      the rates from that section.
    </TextSection>
    <Title>Rates Setup</Title>
    <TextSection>
      Setting up rates is the essential part of setting up your company on the portal. When the rates are set up,
      it allows the Risk Engineers to add their time and expenses during the inspections which allows
      tracking the budget for each project.
    </TextSection>
    <RedirectButton redirectTitle="Subscription" redirectPath="subscription" />
  </>
);

export default Rates;
