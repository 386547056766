import { useSelector } from 'react-redux';

import AuthService from 'services/AuthService';
import { ratesList } from 'modules/myCompany/store/rates.selectors';
import { teamMembersList } from 'modules/myCompany/store/teamMembers.selectors';
import { ROLE } from 'types/user';

interface NewCompanyHook {
  isICC?: boolean;
  isRCC?: boolean;
  ratesExist?: boolean;
  membersExist?: boolean;
  areMembersAndRatesExist?: boolean;
}

export default function useNewCompany(): NewCompanyHook {
  const { role } = AuthService.getUser() as { role: ROLE };
  const isICC = role === ROLE.IC_COORDINATOR;
  const isRCC = role === ROLE.RC_COORDINATOR;

  const membersList = useSelector(teamMembersList);
  const membersExist = membersList.length > 1; // one Coordinator always exists for company

  const ratesData = useSelector(ratesList);
  const { timeRates = [], expenseRates = [] } = ratesData;
  const ratesExist = timeRates.length || expenseRates.length;

  const areMembersAndRatesExist = membersExist && ratesExist;

  if (isICC || isRCC) return { isICC, isRCC, ratesExist, membersExist, areMembersAndRatesExist };
  return {};
}
