import AuthService from 'services/AuthService';
import { Permission, Permissions } from 'types/permission';
import { ROLE } from 'types/user';

const highRoles: ROLE[] = [ROLE.IC_COORDINATOR, ROLE.UNDERWRITER, ROLE.RC_COORDINATOR, ROLE.RISK_ENGINEER];
const rightBasedRoles: ROLE[] = [ROLE.RC_COORDINATOR, ROLE.RISK_ENGINEER];
const lowRoles: ROLE[] = [
  ROLE.RISK_MANAGER,
  ROLE.DEVELOPER_OWNER,
  ROLE.SITE_CONTACT,
  ROLE.BROKER,
  ROLE.INSURANCE_REPRESENTATIVE,
];

export function getPermissions(primaryREEmail: string, primarySCEmail: string, isPrimarySC = false): Permissions {
  const role = AuthService.getUser().role as ROLE;

  if (role === ROLE.RISK_ENGINEER) {
    const email = AuthService.getUserData()?.email;

    if (email !== primaryREEmail) {
      const permissionsFolder: Permission = {
        add: false,
        rename: false,
        delete: false,
        move: false,
        ownerRightsCheck: false,
        layout: {
          ownerRightsCheck: false,
        },
      };
      const permissionsFile: Permission = {
        add: false,
        rename: false,
        delete: false,
        move: false,
        makePrivate: false,
        ownerRightsCheck: false,
        upload: false,
        layout: {
          delete: false,
          ownerRightsCheck: false,
          upload: false,
        },
        risks: {
          delete: false,
          upload: false,
        },
      };

      return { permissionsFolder, permissionsFile };
    }
  }
  if (role === ROLE.SITE_CONTACT) {
    const email = AuthService.getUserData()?.email;

    if (!isPrimarySC || (primarySCEmail && primarySCEmail !== email)) {
      const permissionsFolder: Permission = {
        add: false,
        rename: false,
        delete: false,
        move: false,
        ownerRightsCheck: false,
        layout: {
          ownerRightsCheck: false,
        },
      };
      const permissionsFile: Permission = {
        add: false,
        rename: false,
        delete: false,
        move: false,
        makePrivate: false,
        ownerRightsCheck: false,
        upload: false,
        layout: {
          delete: false,
          ownerRightsCheck: false,
          upload: false,
        },
        risks: {
          delete: false,
          upload: false,
        },
      };

      return { permissionsFolder, permissionsFile };
    }
  }

  const permissionsFolder: Permission = {
    add: highRoles.includes(role),
    rename: highRoles.includes(role),
    delete: highRoles.includes(role),
    move: highRoles.includes(role),
    ownerRightsCheck: rightBasedRoles.includes(role),
    layout: {
      ownerRightsCheck: rightBasedRoles.includes(role),
    },
  };
  const permissionsFile = {
    add: highRoles.includes(role),
    rename: highRoles.includes(role),
    delete: highRoles.includes(role),
    move: highRoles.includes(role),
    makePrivate: [ROLE.IC_COORDINATOR, ROLE.UNDERWRITER].includes(role),
    ownerRightsCheck: rightBasedRoles.includes(role),
    upload: [...highRoles, ROLE.SITE_CONTACT].includes(role),
    layout: {
      delete: true,
      ownerRightsCheck: [...rightBasedRoles, ...lowRoles].includes(role),
      upload: true,
    },
    risks: {
      delete: true,
      upload: lowRoles.includes(role),
    },
  };

  return { permissionsFolder, permissionsFile };
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
