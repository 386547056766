import { showNotifySuccess, showNotifyError } from 'services/toaster';
import LibraryService from 'library/api/LibraryService';

const getPlural = (word, count) => {
  return count > 1 ? `${word}s` : word;
};

const finishUpload = async (finishUploadProps) => {
  const {
    maxCount, uploaded, last, uploadedCount,
    successfullyLoaded, setCurrentUpload, refreshFiles,
  } = finishUploadProps;

  if ((maxCount === 1 && uploaded) || (maxCount > 1 && last && uploadedCount === 1)) {
    if (uploadedCount === 1) {
      showNotifySuccess(`${successfullyLoaded[0].name} file was successfully uploaded.`);
    } else {
      showNotifySuccess(`${uploaded.name} file was successfully uploaded.`);
    }
    setCurrentUpload({ count: 0, maxCount: 1 });
  } else if (maxCount > 1 && last) {
    showNotifySuccess(`${uploadedCount} ${getPlural('file', uploadedCount)} were successfully uploaded.`);
    setCurrentUpload({ count: 0, maxCount: 1 });
  }
  if (last) refreshFiles();
};

export const upload = async (uploadProps) => {
  const { file, last, count, maxCount, loaded, folder, setCurrentUpload, onFileSelect, refreshFiles } = uploadProps;

  if (file) {
    setCurrentUpload({
      name: file.name,
      size: file.size,
      count,
      maxCount,
    });
  }
  const uploaded = await onFileSelect(file);

  loaded.push(uploaded);
  const loadedWithoutErrors = loaded.filter((el) => el);

  if (last && loadedWithoutErrors.length > 0) {
    const storeResponse = await LibraryService.storeFilesMetadata(folder.id, loadedWithoutErrors);

    if (process.env.NODE_ENV === 'development') {
      console.log('storeFileMetadata:', storeResponse); // eslint-disable-line no-console
    }
  }
  const successfullyLoaded = loaded.filter((el) => el);
  const uploadedCount = successfullyLoaded.length;

  finishUpload({
    maxCount, uploaded, last, uploadedCount,
    successfullyLoaded, setCurrentUpload, refreshFiles,
  });
};

export const uploadLayouts = async (uploadProps) => {
  const { files, count, maxCount, onFileSelect, setCurrentUpload, refreshFiles } = uploadProps;

  setCurrentUpload({
    layouts: files,
    count,
    maxCount,
  });
  const uploaded = await onFileSelect(files);
  const successfullyLoaded = uploaded.filter((el) => el);
  const uploadedCount = successfullyLoaded.length / 2; // half of response are images
  const last = true;

  finishUpload({
    maxCount, uploaded, last, uploadedCount,
    successfullyLoaded, setCurrentUpload, refreshFiles,
  });
};

export const rejectFileFormatMessage = (isLayoutsFolder) => {
  if (isLayoutsFolder) {
    showNotifyError('The file format is not supported. Please use PDF files for the layouts.');
  } else {
    showNotifyError('The file format is not supported. Please use one of the following formats: .pdf, .jpg, .jpeg, .png, .gif, .bmp, .mp4, .mov, .doc, .docx, .xlsx.');
  }
};
