import { createSelector } from '@reduxjs/toolkit';

const selectUsers = (state) => {
  return state.users;
};

export const userByInvite = createSelector([selectUsers], (users) => users.userByInvite);

export const userInfo = createSelector([selectUsers], (users) => {
  return users.userInfo;
});

export const showReportNotesWarning = createSelector([selectUsers], (users) => !users.userInfo.skippedReportNotesNotification);
