import React from 'react';

const Address = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.26688 0.967284C7.18964 0.906248 7.09407 0.873047 6.99563 0.873047C6.89718 0.873047 6.80161 0.906248 6.72437 0.967284L0.4375 5.87166L0.98 6.56291L1.75 5.95916V11.3754C1.75 11.6075 1.84219 11.83 2.00628 11.9941C2.17038 12.1582 2.39294 12.2504 2.625 12.2504H11.375C11.6071 12.2504 11.8296 12.1582 11.9937 11.9941C12.1578 11.83 12.25 11.6075 12.25 11.3754V5.96353L13.02 6.56291L13.5625 5.87603L7.26688 0.967284ZM7.875 11.3754H6.125V7.87541H7.875V11.3754ZM8.75 11.3754V7.87541C8.75 7.64334 8.65781 7.42078 8.49372 7.25669C8.32962 7.0926 8.10706 7.00041 7.875 7.00041H6.125C5.89294 7.00041 5.67038 7.0926 5.50628 7.25669C5.34219 7.42078 5.25 7.64334 5.25 7.87541V11.3754H2.625V5.27666L7 1.86853L11.375 5.28103V11.3754H8.75Z" fill="white" fillOpacity="0.68" />
    </svg>
  );
};

export default Address;
