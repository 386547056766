import React from 'react';

import { COLORS } from 'components/Chart/constants';
import { formatPercentage } from 'utils/FormatUtils';

import { Wrapper, NoDataWrapper, DataName, PercentText } from './styles';

const reducer = (accumulator, currentValue) => ({ value: accumulator.value + currentValue.value });

const Legend = ({ data = [], colorsByName, specialNames, noScroll, withPercents, minWidth }) => {
  let dataToRender = data;
  if (withPercents && data.length > 0) {
    const overallCount = data.reduce(reducer).value;
    const dataWithPercents = data.map((el) => ({
      ...el,
      percent: formatPercentage(el.value, overallCount),
    }));
    dataToRender = dataWithPercents;
  }

  if (dataToRender.length > 0) {
    return (
      <Wrapper noScroll={noScroll} minWidth={minWidth}>
        {dataToRender.map((typeData, index) => (
          <DataName
            colorIndex={index}
            colors={COLORS}
            colorsByName={colorsByName}// colorsByName has priority over colors
            name={typeData.name}
            key={index}
            noScroll={noScroll}
          >
            {specialNames ? specialNames[typeData.name] : typeData.name}
            {withPercents && (
              <PercentText>
                {`| ${typeData.percent.toFixed(0)}%`}
              </PercentText>
            )}
          </DataName>
        ))}
      </Wrapper>
    );
  }
  return (
    <NoDataWrapper>
      <span>There is no data to display yet.</span>
    </NoDataWrapper>
  );
};

export default Legend;
