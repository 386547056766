import React, { useState } from 'react';
import { List } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { showNotifySuccess, showNotifyError } from 'services/toaster';

import { ActionButton, Spinner, NoContent } from 'components';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import IconLabelNewFolder from 'components/iconLabels/IconLabelNewFolder';

import useQuery from 'utils/hooks/useQuery';
import LibraryService from 'library/api/LibraryService';
import { FoldersRoot, ListRoot, Title } from './styles';
import Folder from './Folder';
import { checkChildrensIds } from './helpers';
import FolderDialogForm from '../FolderDialogForm';

const FoldersList = ({ permissions, onSuccess, folders }) => {
  const navigate = useNavigate();
  const query = useQuery();

  const folderId = query.get('folderId');
  const [addFolderDialogState, setAddFolderDialogState] = useState(null);
  const [deleteDialogState, setDeleteDialogState] = useState(null);
  const [renameDialogState, setRenameDialogState] = useState(null);
  const [openedFolders, setOpenedFolders] = useState([folderId]);

  function handleSelect(folder) {
    navigate(`/library/documents?folderId=${folder.id}`);
  }

  function closeAddFolderDialog() {
    setAddFolderDialogState(null);
  }

  function closeDeleteDialog() {
    setDeleteDialogState(null);
  }

  function closeRenameDialog() {
    setRenameDialogState(null);
  }

  function handleAddFolderClick(parentFolderId) {
    setAddFolderDialogState({ parentFolderId });
    setOpenedFolders((prevFolders) => [...prevFolders, parentFolderId]);
  }

  function handleFolderDeleteClick(id, name, deleteConfirmationText) {
    setDeleteDialogState({ id, name, deleteConfirmationText });
  }

  function handleFolderRenameClick(id, name) {
    setRenameDialogState({ id, name });
  }

  async function handleDeleteConfirm() {
    try {
      await LibraryService.deleteFolder(deleteDialogState.id);
      showNotifySuccess(`${deleteDialogState.name} folder was successfully deleted.`);
      setDeleteDialogState(null);
      onSuccess();
    } catch (error) {
      if (error.error) {
        showNotifyError(error.error);
      } else {
        showNotifyError(error);
      }
    }
  }

  function handleRenameConfirm() {
    closeRenameDialog();
    onSuccess();
  }

  function renderFolders() {
    return (
      <List>
        {folders.map((folder) => {
          const isOpened = checkChildrensIds(folder, folderId);

          return (
            <Folder
              key={folder.id}
              folder={folder}
              expandedByDefault={folder.id === folderId || isOpened}
              selectedId={folderId}
              onClick={handleSelect}
              onRefresh={onSuccess}
              onAddFolderClick={handleAddFolderClick}
              onDeleteClick={handleFolderDeleteClick}
              onRenameClick={handleFolderRenameClick}
              permissions={permissions}
              openedFolders={openedFolders}
              setOpenedFolders={setOpenedFolders}
            />
          );
        })}
      </List>
    );
  }

  return (
    <FoldersRoot>
      <Title>
        Folders
        {permissions.add && (
          <ActionButton
            variant="text"
            color="primary"
            dialogForm={(
              <FolderDialogForm
                parentFolderId={null}
              />
            )}
            onSubmit={onSuccess}
          >
            <IconLabelNewFolder />
          </ActionButton>
        )}

        {deleteDialogState && (
          <ConfirmationDialog
            open
            title="Delete Folder"
            confirmButtonTitle="Delete"
            onClose={closeDeleteDialog}
            onCancel={closeDeleteDialog}
            onConfirm={handleDeleteConfirm}
          >
            {deleteDialogState.deleteConfirmationText}
          </ConfirmationDialog>
        )}

        {renameDialogState && (
          <FolderDialogForm
            isEdit
            isOpen
            id={renameDialogState.id}
            name={renameDialogState.name}
            onCancel={closeRenameDialog}
            onSubmit={handleRenameConfirm}
          />
        )}

        {addFolderDialogState && (
          <FolderDialogForm
            isOpen
            parentFolderId={addFolderDialogState.parentFolderId}
            onCancel={closeAddFolderDialog}
            onSubmit={onSuccess}
          />
        )}
      </Title>
      <ListRoot>
        {folders && folders.length === 0 && (
        <NoContent
          title="No folders"
        />
        )}
        {!folders
          ? <Spinner />
          : renderFolders()
        }
      </ListRoot>
    </FoldersRoot>
  );
};

export default FoldersList;
