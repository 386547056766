import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Dialog, DialogContent, DialogActions } from "@mui/material";

export const StyledDialog = styledMui(Dialog)(
  ({ theme, slideOut, animations, isForm, shortTitle, borderedTitle, noPadding }) => {
    const styles = {
      "@keyframes diagonalOut": {
        "0%": { transform: "translateX(0) translateY(0)", opacity: 0.8 },
        "50%": {
          transform: "translateX(-350px) translateY(450px)",
          opacity: 0.4,
        },
        "100%": {
          transform: "translateX(-900px) translateY(1000px)",
          opacity: 0.1,
        },
      },
    };
    if (slideOut && animations) {
      styles;
      styles["display"] = "none";
      styles["animation"] = "diagonalOut 0.3s linear 1";
    }
    return {
      [`@keyframes diagonalOut`]: {
        "0%": { transform: "translateX(0) translateY(0)", opacity: 0.8 },
        "50%": {
          transform: "translateX(-350px) translateY(450px)",
          opacity: 0.4,
        },
        "100%": {
          transform: "translateX(-900px) translateY(1000px)",
          opacity: 0.1,
        },
      },
      ".MuiBackdrop-root": {
        display: slideOut && animations ? "none" : "block",
      },
      ".MuiDialog-paper": {
        background: theme.palette.notifications.color,
        padding: "30px",
        borderRadius: "4px",
        padding: "30px",
      },
      ".MuiDialogTitle-root": {
        padding: isForm && shortTitle ? '0 0 24px 0' : '0 0 8px 0',
        ...(borderedTitle && {
          padding: '0 0 18px 0',
          marginBottom: '22px',
          borderBottom: `1px solid ${theme.palette.border.light}`,
        }),
        ...(noPadding && {
          margin: '0 -30px',
          paddingLeft: 24
        }),
      },
      ".MuiDialogContent-root": {
        padding: "0",
        overflowY: "hidden",
      },
      "h2.MuiTypography-root": {
        fontFamily: "Avenir",
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "22px",
        lineHeight: "30px",
        color: theme.palette.text.main,
        display: "flex",
        "> div:not(:last-child)": {
          marginRight: "24px",
          flexShrink: 0,
        },
      },
    };
  }
);

export const StyledDialogContent = styledMui(DialogContent)(
  ({ theme, noMargin }) => ({
    fontFamily: "Graphik LC",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: theme.textColor,
    letterSpacing: "0.16px",
    marginBottom: "32px",
    ...(noMargin && { margin: 0 }),
    "> div": {
      ":not(:last-child)": {
        marginBottom: "12px",
      },
    },
  })
);

export const AdditionalErrorsWrapper = styled.div`
  position: relative;

  p {
    position: relative;
    top: 0;
    margin: 0 16px;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0;
  ${({ addCheckBox }) => addCheckBox && "display: flex"}
  ${({ addCheckBox }) => addCheckBox && "justify-content: space-between"}


  &.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 24px;
  }
`;

export const StyledSeparatedDialogActions = styled(DialogActions)`
  padding: 0;
  display: flex;
  justify-content: space-between;

  &.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 24px;
  }
`;

export const HighlightedText = styledMui("span")(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.text.main,
}));

export const SubTitle = styledMui("div")(({ theme }) => ({
  marginBottom: "10px",
  letterSpacing: "0.16px",
  textTransform: "uppercase",
  color: theme.palette.text.light,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: "95%",
}));

export const ProjectName = styledMui("span")(({ theme }) => ({
  fontFamily: "Graphik LC",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
  letterSpacing: "0.16px",
  textTransform: "uppercase",
  color: theme.palette.text.light,
  marginBottom: "6px",
}));

export const ContentWrapper = styledMui("div")(({ theme }) => ({
  fontFamily: "Graphik LC",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  color: theme.palette.text.main,
  letterSpacing: "0.16px",
  marginBottom: "32px",
  "> div:not(:last-child)": {
    marginBottom: "12px",
  },
}));

export const Footer = styled.div`
  display: flex;
  justify-content: ${({ isFooter }) =>
    isFooter ? "space-between" : "flex-end"};

  ${({ fixedFooter }) =>
    fixedFooter &&
    `
    box-shadow: 0px -10px 12px 0px rgba(0, 0, 0, 0.15);
    margin: 0 -30px;
    padding: 30px 30px 0;
  `}
`;

export const ScrollableArea = styled.div`
  ${({ fixedFooter }) =>
    fixedFooter &&
    `
    max-height: calc(100vh - 180px);
    position: relative;
    overflow-y: auto;
    scrollbar-width: thin;
    padding-right: 8px;
    margin: -30px -30px 0;
    padding: 30px;
  `}
`;

export const AdditionalWrapper = styled.div`
  margin-right: auto;
`;

export const ConfirmationWrapper = styled.span`
  ${({ italic }) => italic && "font-style: italic;"}
`;
