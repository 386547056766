import AnalyticService from 'dashboard/api/AnalyticService';

const GET_ANALYTICS_PROJECTS = 'GET_DASHBOARD_ANALYTICS_PROJECTS';
const GET_ANALYTICS_FILTERS = 'GET_DASHBOARD_ANALYTICS_FILTERS';
const GET_ANALYTICS_CHARTS = 'GET_DASHBOARD_ANALYTICS_CHARTS';
const GET_ANALYTICS_COUNTRIES_WITH_TIV = 'GET_ANALYTICS_COUNTRIES_WITH_TIV';

export const getProjects = (params) => ({
  type: GET_ANALYTICS_PROJECTS,
  apiCall: () => AnalyticService.getProjects(params),
});

export const getCountriesWithTiv = (params) => ({
  type: GET_ANALYTICS_COUNTRIES_WITH_TIV,
  apiCall: () => AnalyticService.getCountriesWithTiv(params),
});

export const getFilters = (params) => ({
  type: GET_ANALYTICS_FILTERS,
  apiCall: () => AnalyticService.getFilters(params),
});

export const getCharts = (params) => ({
  type: GET_ANALYTICS_CHARTS,
  apiCall: () => AnalyticService.getCharts(params),
});

const initialState = {
  projects: {},
  charts: {},
  countriesWithTiv: {
    data: [],
  },
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_ANALYTICS_PROJECTS: {
      return {
        ...state,
        projects: {
          ...state.projects,
          ...response,
        },
      };
    }
    case GET_ANALYTICS_COUNTRIES_WITH_TIV: {
      return {
        ...state,
        countriesWithTiv: {
          ...state.countriesWithTiv,
          ...response,
        },
      };
    }
    case GET_ANALYTICS_FILTERS: {
      return {
        ...state,
        filters: {
          ...response,
        },
      };
    }
    case GET_ANALYTICS_CHARTS: {
      return {
        ...state,
        charts: {
          ...state.charts,
          ...response,
        },
      };
    }
    default:
      return state;
  }
};
