import qs from 'qs';

import ApiClient from 'services/ApiClient';

class UserService extends ApiClient {
  getUsersList() {
    return this.call({
      method: 'get',
      url: '/users',
    });
  }

  getUser(id) {
    return this.call({
      method: 'get',
      url: `/users/${id}`,
    });
  }

  getCurrentUserInfo() {
    return this.call({
      method: 'get',
      url: '/users/current',
    });
  }

  getUserByEmail(email) {
    const query = {
      Email: email,
    };

    return this.call({
      method: 'get',
      url: `/users/by-email?${qs.stringify(query)}`,
    });
  }

  getUserByInvite(inviteToken) {
    return this.call({
      method: 'get',
      url: `/invites/${inviteToken}/user/info`,
    });
  }

  acceptLicenseAgreement() {
    return this.call({
      method: 'put',
      url: '/users/accept-license',
    });
  }

  acceptEula() {
    return this.call({
      method: 'put',
      url: '/users/accept-eula',
    });
  }

  getRiskQuickText(category, type) {
    const query = {
      category,
      type,
    };

    return this.call({
      method: 'get',
      url: `/users/current/library?${qs.stringify(query)}`,
    });
  }

  skipReportNotesWarning() {
    return this.call({
      method: 'put',
      url: '/users/skip-report-notes-warning',
    });
  }
}

export default new UserService();
