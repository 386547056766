import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MyCompanyModal from 'onboarding/pages/MyCompanyModal';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import PageLayout from 'layouts/PageLayout';
import CompanyInfo from 'myCompany/pages/CompanyInfo';
import NavTabs from 'components/NavTabs';
import Subscription from 'account/pages/AccountInfo/Subscription';
import { current } from 'modules/subscriptions/store/subscriptions.selectors';
import * as SubscriptionActions from 'modules/subscriptions/store/subscriptions.reducer';

import Rates from '../Rates';
import TeamMembers from '../TeamMembers';
import { HeaderPanel, Title } from './styles';
import Settings from '../Settings';
import Locations from '../Locations/index';
import MyCompanyService from '../../api/MyCompanyService';

export const tabs = {
  companyInfo: {
    label: 'Company Info',
    value: 'CompanyInfo',
  },
  teamMembers: {
    label: 'Team Members',
    value: 'TeamMembers',
    pulsatingLabel: true,
  },
  rates: {
    label: 'Rates',
    value: 'Rates',
    pulsatingLabel: true,
  },
  subscription: {
    label: 'Subscription',
    value: 'Subscription',
  },
  locations: {
    label: 'Locations',
    value: 'Locations',
  },
  settings: {
    label: 'Approval Settings',
    value: 'Settings',
  },
};

const getTabsWithRestrictions = (isRCC, subscriptionInfo, isPrimaryCoordinator) => {
  const newTabs = { ...tabs };
  if (isRCC && !subscriptionInfo) {
    newTabs.subscription = undefined;
  }
  if (isRCC) {
    newTabs.settings = undefined;
    newTabs.locations = undefined;
  }
  if (!isPrimaryCoordinator || subscriptionInfo == null || subscriptionInfo.active === false || (subscriptionInfo.package !== 'Global' && subscriptionInfo.package !== 'Pro')) {
    newTabs.locations = undefined;
  }
  return newTabs;
};

const MyCompany = () => {
  const dispatch = useDispatch();
  const { subscriptionInfo } = useSelector(current);
  const { role } = AuthService.getUser();
  const { email } = AuthService.getUserData();
  const isRCC = role === ROLES_IDS.RCCoordinator;
  const isICC = role === ROLES_IDS.ICCoordinator;
  const [isPrimaryCoordinator, setIsPrimaryCoordinator] = useState(false);
  const navTabs = Object.values(getTabsWithRestrictions(isRCC, subscriptionInfo, isPrimaryCoordinator)).filter((el) => el);
  const [selectedTab, setSelectedTab] = useState(navTabs[0].value);
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);

  const renderCurrentTab = (currentTab) => {
    switch (currentTab) {
      case tabs.companyInfo.value:
        return <CompanyInfo />;
      case tabs.teamMembers.value:
        return <TeamMembers />;
      case tabs.rates.value:
        return <Rates />;
      case tabs.subscription.value:
        return <Subscription />;
      case tabs.settings.value:
        return <Settings />;
      case tabs.locations.value:
        return <Locations />;
      default:
        return null;
    }
  };

  const handleCloseOnboardingModal = () => {
    setIsOnboardingModalOpen(false);
    AuthService.updateOnboardConfig('myCompany');
  };

  useEffect(() => {
    dispatch(SubscriptionActions.getCurrentSubscription());
    const isVisited = AuthService.checkSectionVisit('myCompany');
    setIsOnboardingModalOpen(!isVisited);
  }, []);

  const fetchCompanyData = async () => {
    if (isICC) {
      const { data: { data } } = await MyCompanyService.getInsuranceCompanyInfo();
      setIsPrimaryCoordinator(data.primaryContact.email === email);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  return (
    <PageLayout>
      <Fragment key="title">
        <HeaderPanel>
          <Title>My Company</Title>
        </HeaderPanel>
      </Fragment>
      <Fragment key="content">
        <NavTabs
          selectedTab={selectedTab}
          onSelectTab={setSelectedTab}
          tabsList={navTabs}
        />
        {renderCurrentTab(selectedTab)}
        {isOnboardingModalOpen && (
          <MyCompanyModal
            open={isOnboardingModalOpen}
            onCancel={handleCloseOnboardingModal}
          />
        )}
      </Fragment>
    </PageLayout>
  );
};

export default MyCompany;
