export function subscribersList({ subscriptions }) {
  return subscriptions.subscribers;
}

export function subscribersSortBy({ subscriptions }) {
  return subscriptions.subscribers.sortBy;
}

export function subscriptionsList({ subscriptions }) {
  return subscriptions.subscriptions;
}

export function subscriptionsSortBy({ subscriptions }) {
  return subscriptions.subscriptions.sortBy;
}

export function current({ subscriptions }) {
  return subscriptions.current;
}
