import styled from 'styled-components';

import { Icon } from 'components';

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--textColor);
`;

export const StyledIcon = styled(Icon)`
  color: var(--success);
`;
