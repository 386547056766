import TeamMembersService from 'myCompany/api/TeamMembersService';

const GET_TEAM_MEMBERS_DATA = 'GET_TEAM_MEMBERS_DATA';
const GET_TEAM_MEMBER = 'GET_TEAM_MEMBER';
const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
const UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER';
const TEAM_MEMBERS_LIST_SORT_BY = 'TEAM_MEMBERS_LIST_SORT_BY';
const TEAM_MEMBERS_LIST_CURRENT_PAGE = 'TEAM_MEMBERS_LIST_CURRENT_PAGE';

export const getTeamMembersList = (params) => ({
  type: GET_TEAM_MEMBERS_DATA,
  apiCall: () => TeamMembersService.getTeamMembersList(params),
});

export const getTeamMember = (params) => ({
  type: GET_TEAM_MEMBER,
  apiCall: () => TeamMembersService.getTeamMember(params),
});

export const addTeamMember = (params) => ({
  type: ADD_TEAM_MEMBER,
  apiCall: () => TeamMembersService.addTeamMember(params),
});

export const updateTeamMember = (params) => ({
  type: UPDATE_TEAM_MEMBER,
  apiCall: () => TeamMembersService.updateTeamMember(params),
});

export const setTeamMembersSortBy = (sortBy) => ({
  type: TEAM_MEMBERS_LIST_SORT_BY,
  sortBy,
});

export const setCurrentPage = (currentPage) => ({
  type: TEAM_MEMBERS_LIST_CURRENT_PAGE,
  currentPage,
});

const initialState = {
  sortBy: null,
  currentPage: 1,
  teamMembersData: {
    items: [],
    totalPages: 0,
    totalCount: 0,
    isLoading: true,
  },
};

export default (state = initialState, action) => {
  const { response, currentPage } = action;

  switch (action.type) {
    case TEAM_MEMBERS_LIST_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }
    case GET_TEAM_MEMBERS_DATA: {
      return {
        ...state,
        teamMembersData: {
          ...state.teamMembersData,
          ...response,
        },
      };
    }
    case TEAM_MEMBERS_LIST_CURRENT_PAGE: {
      return {
        ...state,
        currentPage,
      };
    }

    default:
      return state;
  }
};
