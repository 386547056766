import styled from 'styled-components';
import Icon from 'components/Icon';

export const Text = styled.div`
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: var(--textColor);
`;

export const HighlightedText = styled.span`
  font-weight: 600;
`;

export const Subtitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: var(--sidebarSelectedIconBackground);
  & {
    margin: 4px 0 12px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 20px 25px 20px;
  background: var(--notificationInfoBackground);
  border-radius: 4px;

  && {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Contact = styled.div`
  flex-basis: 50%;
  & > div:not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const ButtonOpenProject = styled.span`
  border-top: 1px solid #73778C;
  height: 48px;
  box-sizing: border-box;
  background: var(--notificationInfoBackground);
  width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 13px 29px 13px 19px;
  }
  div {
    padding-top: 2px;
  }
  :hover > * {
    color: var(--sidebarSelectedIconBackground);
    path {
      fill: var(--sidebarSelectedIconBackground);
    }
  }
`;

export const StyledIcon = styled(Icon)`
  width: 24px;
  margin-right: 17px;
  path {
    fill: ${(props) => (props.color || 'var(--sidebarSelectedIconBackground)')};
  }
`;

export const StyledArrow = styled(Icon)`
  margin-left: auto;
`;
