import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ConfirmationText from 'components/Dialogs/ConfirmationText';
import ProjectsService from 'notifications/api/ProjectsService';

const assignMissedForRC = 'iMR Global has detected that the {placeholder} project has yet to be allocated to a Risk Engineer.';
const assignMissedForIC = 'iMR Global has detected that the {placeholder} has yet to be allocated to a Risk Engineer. If you do not wish to allocate a resource to this project, please indicate by selecting "Do not Assign" button.';

const ProjectNotAssigned = ({ notify, isIC }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: { projectId, projectName } } = notify;
  const cancelTitle = isIC ? 'Do not Assign' : 'Close';

  const handleClose = () => {
    dispatch(NotificationsActions.setOpenedNotification(null));
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    if (isIC) ProjectsService.ignoreProjectHasNoAssignment(projectId);
    handleClose();
  };

  const closeNotificationList = () => {
    dispatch(NotificationsActions.setNotificationsView(''));
  };

  const redirectToResources = () => {
    handleClose();
    closeNotificationList();
    navigate(`/projects/${projectId}/resources`);
  };

  return (
    <ConfirmationDialog
      open
      borderedTitle
      projectName={projectName}
      title="Project Not Assigned"
      cancelButtonTitle={cancelTitle}
      confirmButtonTitle="Assign"
      onClose={handleClose}
      onCancel={handleCancel}
      onConfirm={redirectToResources}
      onAdditionalAction={isIC ? handleClose : undefined}
    >
      {/* <ConfirmationText
        value={projectName}
        text={isIC ? assignMissedForIC : assignMissedForRC}
      /> */}
    </ConfirmationDialog>
  );
};

export default ProjectNotAssigned;
