import React, { useState } from 'react';
import {
  Tooltip as MuiTooltip,
  ListItemText,
  Collapse,
} from '@mui/material';

import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import { PACKAGES_USER_LAYERS } from 'modules/subscriptions/constants';

import { layers, comingSoonLayers } from './constants';
import {
  StyledListItem,
  StyledList,
  Separator,
  Title,
  ListItemWrapper,
  ExpandedList,
  TooltipBlock,
} from './styles';
import { COMPANY_TYPES } from 'types/company';

const LayerSelector = ({
  selectedLayer,
  onChange,
  mapLoaded,
  withComingSoon,
  isHorizontal,
}) => {
  const [expanded, setExpanded] = useState(false);

  const { role, companyType, subscription } = AuthService.getUser();
  const isRMorBR =
    role === ROLES_IDS.RiskManager ||
    role === ROLES_IDS.Broker ||
    role === ROLES_IDS.InsuranceRepresentative ||
    role === ROLES_IDS.DeveloperOwner;
  const isExternalRE =
    role === ROLES_IDS.RiskEngineer && companyType === COMPANY_TYPES.NO_COMPANY;

  let packageRole;
  if (isRMorBR) {
    packageRole = role;
  } else if (isExternalRE) {
    packageRole = 'ExternalRE';
  } else {
    packageRole = companyType;
  }

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleClickNoLayer = () => {
    onChange(null);
  };

  return (
    <StyledList isHorizontal={isHorizontal}>
      <Title isHorizontal={isHorizontal}>
        LAYERS
        {isHorizontal && ':'}
      </Title>
      {layers.map((layer, index) => {
        const isSelected = layer.value === selectedLayer;
        const layerAllowed =
          PACKAGES_USER_LAYERS[packageRole][subscription]?.[layer.value];
        const layerAllowedTooltip = PACKAGES_USER_LAYERS[packageRole].tooltip;

        const handleClick = () => {
          onChange(layer.value);
        };

        if (!layerAllowed) {
          return (
            <StyledListItem
              key={layer.value + index}
              isHorizontal={isHorizontal}
              button
              disabledWithTooltip
            >
              <ListItemText primary={layer.label} />
              <MuiTooltip arrow title={layerAllowedTooltip}>
                <TooltipBlock />
              </MuiTooltip>
            </StyledListItem>
          );
        }
        return (
          <StyledListItem
            key={layer.value + index}
            isHorizontal={isHorizontal}
            selected={isSelected}
            disabled={!mapLoaded}
            button
            onClick={handleClick}
          >
            <ListItemText primary={layer.label} />
            <MuiTooltip arrow title={layer.tooltip}>
              <TooltipBlock />
            </MuiTooltip>
          </StyledListItem>
        );
      })}

      {withComingSoon && (
        <>
          <StyledListItem button onClick={handleExpand}>
            <ListItemWrapper>
              <ListItemText primary="Coming Soon" />
              <Tooltip text="These layers will be available in the upcoming release." />
            </ListItemWrapper>
            <Icon noWrapper icon={expanded ? 'chevronUp' : 'chevronDown'} />
          </StyledListItem>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <ExpandedList component="div" disablePadding>
              {comingSoonLayers.map((layer, index) => (
                <StyledListItem disabled button key={index}>
                  <ListItemText primary={layer} />
                </StyledListItem>
              ))}
            </ExpandedList>
          </Collapse>
        </>
      )}

      {!isHorizontal && <Separator />}
      <StyledListItem
        isHorizontal={isHorizontal}
        selected={!selectedLayer}
        button
        onClick={handleClickNoLayer}
      >
        <ListItemText primary="No Layer" />
      </StyledListItem>
    </StyledList>
  );
};

export default LayerSelector;
