import React from 'react';

import { Spinner } from 'components';
import CloseButton from 'components/buttons/CloseButton';
import PdfViewer from 'common/components/PdfViewer';
import { StyledDialog, ImageWrapper, StyledImage } from 'common/components/OpenAttachmentButton/styles';

const stopPropagation = (event) => {
  event.stopPropagation();
};

const OpenFile = ({ fileInfo, opened, setOpened, isLoading }) => {
  const { url, name, isPDF } = fileInfo || {};

  const handleCloseDialog = () => setOpened(false);

  if (!url) return null;

  if (isLoading) {
    return <Spinner fullscreen />;
  }

  return (
    <>
      {opened && !isPDF && (
        <StyledDialog
          fullScreen
          open={opened}
        >
          <CloseButton onClick={handleCloseDialog} top="10px" />
          <ImageWrapper onClick={handleCloseDialog}>
            <StyledImage src={url} alt={name} onClick={stopPropagation} />
          </ImageWrapper>
        </StyledDialog>
      )}

      {opened && isPDF && (
        <PdfViewer
          opened={opened}
          onClose={handleCloseDialog}
          pdfUrl={url}
        />
      )}
    </>
  );
};

export default OpenFile;
