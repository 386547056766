import React from 'react';

const Arrow = () => {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.373528 9.4486C0.711236 9.75967 1.23157 9.75805 1.56734 9.4449L5.54906 5.73133C5.97299 5.33595 5.973 4.66411 5.54907 4.26873L1.57421 0.561517C1.23513 0.245273 0.709178 0.245278 0.370109 0.561529V0.561529C-0.00828908 0.914463 -0.00333337 1.51571 0.380831 1.86236L3.85808 5.00003L0.378884 8.15199C-0.00370166 8.49859 -0.00618108 9.09885 0.373528 9.4486V9.4486Z" fill="#96CEF6" />
    </svg>
  );
};

export default Arrow;
