import { RISK_PRIORITIES_COLORS } from 'utils/constants';

export const DASHBOARD_CHART_OPTIONS = [
  {
    label: 'Project Types',
    value: 'projectTypes',
  },
  {
    label: 'Claims',
    value: 'claimTypes',
  },
  {
    label: 'Claims Total',
    value: 'claimAmounts',
  },
  {
    label: 'Recommendations',
    value: 'riskCategories',
  },
  {
    label: 'Recommendations Priorities',
    value: 'riskPriorities',
  },
  {
    label: 'Recommendations Statuses',
    value: 'riskStatuses',
  },
];

export const DASHBOARD_CHART_OPTIONS_WITHOUT_CLAIMS_TOTAL = [
  {
    label: 'Project Types',
    value: 'projectTypes',
  },
  {
    label: 'Claims',
    value: 'claimTypes',
  },
  {
    label: 'Recommendations',
    value: 'riskCategories',
  },
  {
    label: 'Recommendations Priorities',
    value: 'riskPriorities',
  },
  {
    label: 'Recommendations Statuses',
    value: 'riskStatuses',
  },
];

export const SC_DASHBOARD_CHART_OPTIONS = [
  {
    label: 'Project Types',
    value: 'projectTypes',
  },
  {
    label: 'Recommendations',
    value: 'riskCategories',
  },
  {
    label: 'Recommendations Priorities',
    value: 'riskPriorities',
  },
  {
    label: 'Recommendations Statuses',
    value: 'riskStatuses',
  },
];

export const DASHBOARD_ANALYTICS_CHART_OPTIONS = [
  {
    label: 'Project Types',
    value: 'projectTypes',
  },
  {
    label: 'Claims',
    value: 'claimTypes',
  },
  {
    label: 'Claims Value',
    value: 'claimAmounts',
  },
  {
    label: 'Recommendations',
    value: 'riskCategories',
  },
  {
    label: 'Recommendations Priorities',
    value: 'riskPriorities',
  },
  {
    label: 'Recommendations Statuses',
    value: 'riskStatuses',
  },
];

export const COLORS = [
  '#96CEF6',
  '#969EF6',
  '#96F6EE',
  '#4205DE',
  '#1FBAFA',
  '#88EEAC',
  '#F6DB81',
  '#F87E7E',
  '#73778C',
  '#96CEF6',
  '#969EF6',
  '#96F6EE',
  '#4205DE',
  '#1FBAFA',
  '#88EEAC',
  '#F6DB81',
  '#F87E7E',
  '#73778C',
  '#96CEF6',
  '#969EF6',
  '#96F6EE',
  '#4205DE',
  '#1FBAFA',
  '#88EEAC',
  '#F6DB81',
  '#F87E7E',
  '#73778C',
];

export const COLORS_RECOMMENDATIONS = [
  {
    name: 'Fire',
    color: '#FA7000',
  },
  {
    name: 'Flood',
    color: '#A3AABE',
  },
  {
    name: 'Water Damage',
    color: '#96F6EE',
  },
  {
    name: 'Impact',
    color: '#00249C',
  },
  {
    name: 'Natural Perils',
    color: '#B43DC7',
  },
  {
    name: 'Collapse',
    color: '#925E78',
  },
  {
    name: 'Explosion',
    color: '#FF2620',
  },
  {
    name: 'Health & Safety',
    color: '#66FF00',
  },
  {
    name: 'Quality Assurance',
    color: '#969EF6',
  },
  {
    name: 'Quality Procedures',
    color: '#FFF200',
  },
  {
    name: 'Security',
    color: '#0086F4',
  },
];

export const COLORS_RECOMMENDATIONS_PRIORITIES = [
  {
    name: 'Immediate',
    color: RISK_PRIORITIES_COLORS.Immediate.background,
  },
  {
    name: 'Routine',
    color: RISK_PRIORITIES_COLORS.Routine.background,
  },
  {
    name: 'Advisory',
    color: RISK_PRIORITIES_COLORS.Advisory.background,
  },
  {
    name: 'Urgent',
    color: RISK_PRIORITIES_COLORS.Urgent.background,
  },
];

export const COLORS_RECOMMENDATIONS_STATUSES = [
  {
    name: 'Issued',
    color: '#FAB095',
  },
  {
    name: 'Actioned',
    color: '#96F6EE',
  },
  {
    name: 'Further Action',
    color: '#FAE395',
  },
  {
    name: 'InProgress',
    color: '#969EF6',
  },
  {
    name: 'Closed',
    color: '#8F92A3',
  },
  {
    name: 'Rejected',
    color: '#FA928D',
  },
  {
    name: 'Amended',
    color: '#FA928D',
  },
];

export const COLORS_DASHBOARD_CHART = {
  projectTypes: null,
  claimAmounts: COLORS_RECOMMENDATIONS,
  claimTypes: COLORS_RECOMMENDATIONS,
  riskCategories: COLORS_RECOMMENDATIONS,
  riskPriorities: COLORS_RECOMMENDATIONS_PRIORITIES,
  riskStatuses: COLORS_RECOMMENDATIONS_STATUSES,
};

export const COLORS_GLOBAL_ECOSYSTEM_CHART = {
  projectTypes: null,
  claimAmounts: COLORS_RECOMMENDATIONS,
  claimTypes: COLORS_RECOMMENDATIONS,
  riskCategories: COLORS_RECOMMENDATIONS,
  riskPriorities: COLORS_RECOMMENDATIONS_PRIORITIES,
  riskStatuses: COLORS_RECOMMENDATIONS_STATUSES,
};

export const CHART_DATA_NAMES = {
  projectTypes: 'Projects',
  claimAmounts: 'Claim Value',
  claimTypes: 'Claims',
  riskCategories: 'Recommendations',
  riskPriorities: 'Recommendations',
  riskStatuses: 'Recommendations',
};

export const CHART_DATA_TYPES = {
  claimAmounts: 'currency',
};
