import { COMPANY_TYPES } from 'types/company';

export function contactsList({ contacts }) {
  return contacts.contactsList;
}

export function hasSecondaryRE({ contacts }) {
  return contacts?.contactsList.items.find((t) => t.companyType === COMPANY_TYPES.RISK && (t.position === 'InternalRE' || t.position === 'ExternalRE')) != null;
}

export function dropdownContactsList({ contacts }) {
  return contacts.contactsDropdown;
}

export function contactsSortBy({ contacts }) {
  return contacts.sortBy;
}

export function contactsAddingNewContact({ contacts }) {
  return contacts.addContact;
}

export function getGlobalContactsForPosition({ contacts }) {
  return contacts.contactsForPosition.data;
}

// TODO: Move such static data to separate reducer like 'dictionaries'
// and fetch them all on App Initialization
export function getCountries({ contacts }) {
  return contacts.countriesData.data || [];
}

export function getCountriesOptions({ contacts }) {
  if (!contacts.countriesData.data) return [];

  const countryOptions = contacts.countriesData.data.map((countryItem) => ({
    label: countryItem.name,
    value: countryItem.name,
  }));

  return countryOptions;
}

export function underwriter({ contacts }) {
  return contacts.contactsList.items.find((t) => t.position === 'ICUnderwriter' && t.isPrimaryUnderwriter);
}
