import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from 'components';
import AuthService from 'services/AuthService';
import useNewCompany from 'utils/hooks/useNewCompany';
import { PACKAGES_IDS } from 'modules/subscriptions/constants';
import ConfirmationText from 'components/Dialogs/ConfirmationText';

import Steps from './Steps';
import {
  Root, StyledSkipButton, IconContainer, StyledIcon, LogoText,
  LogoTextSmall, Intro, LastPageRoot, Divider,
} from './styles';

const getSectionInfoByKey = (section) => {
  switch (section) {
    case 'dashboard':
      return {
        title: 'Dashboard',
        path: 'dashboard',
      };
    case 'globalEcosystem':
      return {
        title: 'Global Ecosystem',
        path: 'global-ecosystem',
      };
    case 'help':
      return {
        title: 'Help',
        path: 'help',
      };
    case 'myCompany':
      return {
        title: 'My Company',
        path: 'my-company',
      };
    default:
      return {
        title: 'Projects',
        path: 'projects',
      };
  }
};

const welcomeTextForCoordinators = 'You can now go to {placeholder} section and start setting up your company by the following the steps below or you can continue enjoying the tour by selecting any other section below.';

const RedirectMenu = ({ onCancel, currentSection, isGeneral }) => {
  const navigate = useNavigate();
  const onboardingSettings = AuthService.getOnboardingSections();
  const { isICC, isRCC, areMembersAndRatesExist } = useNewCompany();

  const subscription = AuthService.getUserSubscription();
  const isPackageGlobal = subscription === PACKAGES_IDS.Global;

  // requirements - https://paralect.atlassian.net/wiki/spaces/IMITGR/pages/2974515232/Intro+-+Dashboard+General+Rule - ID-AC-5
  const unvisitedSections = Object.keys(onboardingSettings)
    .filter((section) => (
      section !== 'common' &&
      !onboardingSettings[section] &&
      section !== currentSection &&
      (section !== 'globalEcosystem' || isPackageGlobal) &&
      AuthService.hasPermission(getSectionInfoByKey(section).title, 'sidebar')
    ));

  const renderBody = (withSteps) => {
    return unvisitedSections.map((section) => {
      const { title, path } = getSectionInfoByKey(section);

      const redirectToSection = () => {
        if (onCancel) onCancel();
        isGeneral && section === 'dashboard' ? navigate('/') : navigate(path);
      };

      return (
        <StyledSkipButton
          key={section}
          onClick={redirectToSection}
          highlighted={withSteps && section === 'myCompany'}
          small={withSteps}
        >
          <IconContainer small={withSteps}>
            <StyledIcon icon={section} />
          </IconContainer>
          {title}
        </StyledSkipButton>
      );
    });
  };
  const sectionsCount = unvisitedSections.length;
  const introText = isGeneral
    ? 'You can now continue enjoying the tour and learn more about sections you are interested in or skip the tour and investigate the platform on your own.'
    : `You're done with ${getSectionInfoByKey(currentSection).title} basics! If you want to learn more, you can always find more details in the Help section.`;
  const finishText = 'Well done! You’re done with the basics for all section! If you want to learn more, you can always find more details in the Help section.';

  if (isGeneral && !areMembersAndRatesExist && (isICC || isRCC)) {
    return (
      <LastPageRoot>
        <Icon icon="congrats" noWrapper />
        <LogoTextSmall>Well done!</LogoTextSmall>
        <Intro maxWidth={512}>
          <ConfirmationText
            text={welcomeTextForCoordinators}
            value="My Company"
          />
        </Intro>
        <Steps />
        <Divider>
          <div />or<div />
        </Divider>
        {!!sectionsCount && (
          <Root>
            {renderBody(true)}
          </Root>
        )}
      </LastPageRoot>
    );
  }

  return (
    <LastPageRoot>
      <Icon icon="congrats" noWrapper />
      <LogoText>Well done!</LogoText>
      <Intro>
        {sectionsCount ? introText : finishText}
      </Intro>
      {!!sectionsCount && (
        <Root>
          {renderBody()}
        </Root>
      )}
    </LastPageRoot>
  );
};

export default RedirectMenu;
