import React, { useState } from 'react';

import ImageContainer from 'onboarding/components/ImageContainer';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import SalesDepartmentModal from 'help/components/SalesDepartmentModal';

import { Title, TextSection, TextButton, List } from '../../styles';

const infoSrc = 'account-overview.png';
const passwordSrc = 'account-update-password.png';
const rmInfoSrc = 'account-overview-rm.png';
const rmPasswordSrc = 'account-update-password-rm.png';
const subscriptionSrc = 'account-subscription.png';

const Overview = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { role } = AuthService.getUser();

  const isBroker = role === ROLES_IDS.Broker;
  const isIR = role === ROLES_IDS.InsuranceRepresentative;
  const isRM = role === ROLES_IDS.RiskManager;
  const isDO = role === ROLES_IDS.DeveloperOwner;

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <ImageContainer
        src={isBroker || isIR || isRM || isDO ? rmInfoSrc : infoSrc}
        alt="personal info"
      />
      <Title>Overview</Title>
      <TextSection>
        Account section allows you to view and update your personal information.
        The following personal information is displayed within that section:
      </TextSection>
      <List>
        <li>First and Last name</li>
        <li>Email</li>
        <li>Company Name</li>
        <li>Designation</li>
        <li>Country, State or Region, City, Address, Zip Code</li>
        <li>Phone Number </li>
      </List>
      <TextSection>
        When you update your personal information, the data is updated on all
        the projects you are involved into so that everyone can see the up to
        date details in case they need to contact you.
      </TextSection>
      <ImageContainer
        src={isBroker || isIR || isRM || isDO ? rmPasswordSrc : passwordSrc}
        alt="update password"
      />
      <Title>Password</Title>
      <TextSection>
        You can update your password within that section. Please note that for
        security purposes, we will ask you to update the password every 3 month.
        You will receive the notification in case the password should be
        updated. You can also update the password any time you need.
      </TextSection>
      {(isBroker || isIR || isRM || isDO) && (
        <>
          <ImageContainer src={subscriptionSrc} alt="subscription" />
          <Title>Subscription</Title>
          <TextSection>
            Within the subscription section you can find the most important
            information about your subscription such as:
          </TextSection>
          <List>
            <li>Status</li>
            <li>Package</li>
            <li>Start & End Date</li>
            <li>Sales Manager Contact Details</li>
          </List>
          <TextSection>
            For more details about the subscription, please contact our
            <TextButton onClick={handleModalOpen}>sales departments</TextButton>
            .
          </TextSection>
          <TextSection>
            <i>
              Note that the section is only visible if you have subscribed to
              the product. If you use the free version, the section will not be
              available for you.
            </i>
          </TextSection>
          {modalOpen && <SalesDepartmentModal onClose={handleModalClose} />}
        </>
      )}
    </>
  );
};

export default Overview;
