import React from 'react';

import AuthService from 'services/AuthService';

const NotificationNew = () => {
  const theme = AuthService.getTheme();

  const mainColor = theme === 'light' ? '#0A2463' : 'rgba(255, 255, 255, 0.87)';
  const circleColor = theme === 'light' ? '#1FBAFA' : '#BEE0F9';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
      <path fill={mainColor} fillOpacity=".8" d="M17.875 12.1v-1.787H16.5v2.062a.626.626 0 00.206.481l1.856 1.857v1.1H3.437v-1.1l1.857-1.857a.626.626 0 00.206-.481V8.937a5.5 5.5 0 015.5-5.5c.967 0 1.916.26 2.75.75V2.612a6.675 6.675 0 00-2.063-.55V.688h-1.375v1.375a6.978 6.978 0 00-6.187 6.874V12.1l-1.856 1.856a.626.626 0 00-.207.482V16.5a.646.646 0 00.688.688h4.812a3.438 3.438 0 006.875 0h4.813a.646.646 0 00.687-.688v-2.063a.624.624 0 00-.206-.48L17.875 12.1zM11 19.25a2.062 2.062 0 01-2.063-2.063h4.125A2.063 2.063 0 0111 19.25z" />
      <path fill={circleColor} d="M17.875 8.25a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5z" />
    </svg>
  );
};

export default NotificationNew;
