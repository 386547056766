import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import { FormLabel, Radio } from '@mui/material';

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledFormLabel = styledMui(FormLabel)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '18px',
  letterSpacing: 0.16,
  color: theme.palette.text.main,
  marginRight: 24,
  '&.MuiFormLabel-root.Mui-focused': {
    color: theme.palette.text.main,
  },
}));

export const StyledRadio = styledMui(Radio)(({ theme }) => ({
  '&.MuiRadio-colorPrimary': {
    svg: {
      path: {
        fill: theme.palette.navbar.tab.active,
      },
      circle: {
        stroke: theme.palette.border.light,
        strokeOpacity: 1,
      },
    },
  },
  '&.MuiRadio-colorPrimary.Mui-disabled': {
    svg: {
      path: {
        fill: theme.palette.border.light,
      },
      circle: {
        stroke: theme.palette.border.light,
        strokeOpacity: 1,
      },
    },
  },
}));

export const StyledRadioLabel = styledMui('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '> div': {
    marginLeft: 5,
  },
  '&.Mui-disabled': {
    stroke: theme.palette.border.light,
  },
}));
