import styled from 'styled-components';

export const TeamMembersRoot = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 392px;
`;

export const HeaderPanel = styled.div`
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Controls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > :last-child {
    margin-left: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
`;
