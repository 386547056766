import { useRef, useEffect, useState } from 'react';
import { loadModules, loadCss } from 'esri-loader';
import { useTheme } from '@mui/material/styles';

import LocationService from 'projects/api/LocationService';
import { layers } from './constants';

export const getLocationPoint = async ({ longitude, latitude }) => {
  const [Point] = await loadModules(['esri/geometry/Point']);

  return new Point({
    longitude, latitude,
  });
};

const loadGraphicLayers = async () => {
  const [GraphicsLayer] = await loadModules(['esri/layers/GraphicsLayer']);

  return {
    locations: new GraphicsLayer({ id: 'locations' }),
    points: new GraphicsLayer({ id: 'points' }),
    hover: new GraphicsLayer({ id: 'hover' }),
  };
};

const loadLayers = async (isLightTheme, selectedLayer) => {
  const [
    FeatureLayer,
    GroupLayer,
  ] = await loadModules([
    'esri/layers/FeatureLayer',
    'esri/layers/GroupLayer',
  ]);

  Object.values(layers).forEach((layer) => {
    const loadOptions = {
      url: layer.url,
      visible: selectedLayer === layer.name,
      id: layer.name,
      opacity: 0.7,
      blendMode: isLightTheme ? 'multiply' : 'overlay',
      outFields: ['*'],
    };

    if (layer.name === 'hurricane') {
      const subLayers = layer.subLayers.map(({ url, legendEnabled }) => {
        return new FeatureLayer({ url, legendEnabled });
      });

      layer.layer = new GroupLayer({
        visible: selectedLayer === layer.name,
        blendMode: isLightTheme ? 'multiply' : 'overlay',
        opacity: 0.7,
        layers: subLayers,
      });
    } else {
      layer.layer = new FeatureLayer(loadOptions);
    }
  });

  return layers;
};

const loadMap = async (options = {}, theme, graphicsLayers, featureLayers) => {
  const loadOptions = {
    // css: true,
  };
  const { baseMapId } = theme.palette.map;
  const { view: viewOptions } = options;

  const [
    IdentityManager,
    MapView,
    Expand, Legend,
    EsriMap, Basemap, VectorTileLayer,
  ] = await loadModules([
    'esri/identity/IdentityManager',
    'esri/views/MapView',
    'esri/widgets/Expand', 'esri/widgets/Legend',
    'esri/Map', 'esri/Basemap', 'esri/layers/VectorTileLayer',
  ], loadOptions);

  const { data: { data: token } } = await LocationService.getAccessToken();

  await IdentityManager.registerToken({
    server: 'https://www.arcgis.com/sharing/rest',
    token,
  });

  const basemap = new Basemap({
    baseLayers: [
      new VectorTileLayer({
        portalItem: {
          id: baseMapId,
        },
        id: 'basemap',
      }),
    ],
  });

  const map = new EsriMap({
    basemap,
    layers: [...featureLayers, ...graphicsLayers],
  });

  const view = new MapView({
    ...viewOptions,
    map,
    ui: {
      components: ['zoom'],
    },
    constraints: {
      minZoom: 2,
    },
    highlightOptions: {
      haloOpacity: 0,
      fillOpacity: 0,
    },
  });

  const legend = new Expand({
    content: new Legend({
      view,
    }),
    view,
    expanded: true,
    mode: 'floating',
    expandTooltip: 'Expand Legend',
    collapseTooltip: 'Collapse Legend',
    collapseIconClass: 'esri-icon-up-arrow',
    id: 'legend',
  });

  view.ui.add(legend, 'top-right');

  loadCss();

  return view;
};

const destroyView = (view) => {
  if (!view) {
    return;
  }
  view.container = null;
};

const getLocator = async () => {
  const locator = await loadModules(['esri/rest/locator']);

  return locator;
};

export const useMap = (options) => {
  const { isProjectLocation, isTivVar, selectedLayer } = options;
  const theme = useTheme();
  const isLightTheme = theme.palette.mode === 'light';

  // create a ref to element to be used as the map's container
  const elRef = useRef(null);

  const [view, setView] = useState(null);
  const [graphicsLayersItems, setGraphicsLayersItems] = useState({});
  const [locator, setLocator] = useState(null);
  const [legend, setLegend] = useState(null);

  useEffect(() => {
    // define local variables to be used in the clean up function
    let cancelled = false;
    let _view;

    async function load() {
      const layersItems = await loadLayers(isLightTheme, selectedLayer);
      const featureLayers = Object.values(layersItems).map((layer) => layer.layer);
      const _graphicLayerItems = await loadGraphicLayers();
      const graphicLayers = Object.values(_graphicLayerItems);

      _view = await loadMap(options, theme, graphicLayers, featureLayers);
      setGraphicsLayersItems(_graphicLayerItems);

      if (isProjectLocation) {
        const _locator = await getLocator();

        setLocator(_locator);
      }

      const _legend = _view.ui.find('legend');

      _view.ui.remove(_legend);
      setLegend(_legend);

      if (cancelled) {
        return;
      }

      _view.container = elRef.current;

      setView(_view);
    }
    load();

    return function cleanUp() {
      cancelled = true;
      // clean up the map view
      destroyView(_view);
    };
  }, [isLightTheme]);
  if (isProjectLocation) {
    return [elRef, view, layers, graphicsLayersItems, locator, legend];
  }
  if (isTivVar) {
    return [elRef, view, layers, graphicsLayersItems, legend];
  }

  return [elRef, view, graphicsLayersItems];
};
