import React, { Fragment, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';

import AuthService, { ROLES_IDS } from 'services/AuthService';
import ProjectsModal from 'onboarding/pages/ProjectsModal';
import ProjectService from 'projects/api/ProjectService';

import PageLayout from 'layouts/PageLayout';
import { SearchInput, Button } from 'components';
import ProjectsListTable from 'projects/components/ProjectsListTable';
import { Title } from 'common/components/styles';

import ImportProjects from './ImportProjects';

import { HeaderPanel, Controls } from './styles';

const DISABLED_TOOLTIP = 'The maximum number of projects is reached. Please contact Administrator sales@imitig8risk.com if you need to add more projects';

const ProjectsList = () => {
  const navigate = useNavigate();
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [projectsCount, setProjectsCount] = useState({});

  const { role } = AuthService.getUser();

  const permissions = AuthService.getPermissions('projects') || {};
  const isICC = role === ROLES_IDS.ICCoordinator;

  const debouncedSetSearchFilter = debounce((value) => setSearchFilter(value), 400);

  function handleFilterChange(e) {
    debouncedSetSearchFilter(e.target.value);
  }

  function clearSearchFilter() {
    setSearchFilter('');
  }

  const handleCloseOnboardingModal = () => {
    setIsOnboardingModalOpen(false);
    AuthService.updateOnboardConfig('projects');
  };

  const getProjectsCountLimits = async () => {
    const { data: { data } } = await ProjectService.getProjectsCountLimits();
    setProjectsCount(data);
  };

  const redirectToAdd = () => navigate('/projects/add');

  const isAddDisabled = projectsCount.currentCount >= projectsCount.allowedCount;

  useEffect(() => {
    const isVisited = AuthService.checkSectionVisit('projects');
    setIsOnboardingModalOpen(!isVisited);
    if (isICC) getProjectsCountLimits();
  }, []);

  return (
    <PageLayout>
      <Fragment key="title">
        Projects
      </Fragment>
      <Fragment key="subTitleNode">
        <HeaderPanel>
          <Title>All Projects</Title>
          <Controls>
            <SearchInput
              placeholder="Search by Project Name"
              fullWidth
              value={searchFilter}
              onChange={handleFilterChange}
              onClearSearch={clearSearchFilter}
            />
            {permissions.create && <ImportProjects disabled={isAddDisabled} />}
            {permissions.create && (
              <Button
                small
                text="Add Project"
                color="primary"
                variant="contained"
                onClick={redirectToAdd}
                disabled={isAddDisabled}
                disabledTooltip={DISABLED_TOOLTIP}
              />
            )}
          </Controls>
        </HeaderPanel>
      </Fragment>

      <Fragment key="content">
        <ProjectsListTable
          searchFilter={searchFilter}
        />
        {isOnboardingModalOpen && (
          <ProjectsModal
            open={isOnboardingModalOpen}
            onCancel={handleCloseOnboardingModal}
          />
        )}
      </Fragment>
    </PageLayout>
  );
};

export default ProjectsList;
