import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';

import { Text, InfoWrapper, StyledButton } from './styles';

const parseStringToArray = (string) => {
  let parsedData;
  try {
    parsedData = JSON.parse(string);
  } catch (e) {
    parsedData = [];
  }
  return parsedData;
};

const ProjectLocationNotAssigned = ({ notify }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, subject } = notify;

  const upText = 'iMR Global has detected that the location hasn’t been assigned for the following projects: ';
  const downText = 'If you want to assign the location, please select the project in the list and assign the project location within the ‘Location’ sub-section.';

  const handleClose = () => dispatch(NotificationsActions.setOpenedNotification(null));

  const closeNotificationList = () => dispatch(NotificationsActions.setNotificationsView(''));

  return (
    <ConfirmationDialog
      open
      borderedTitle
      title={subject}
      confirmButtonTitle="I Understand"
      onClose={handleClose}
      noCancel
      onConfirm={handleClose}
    >
      <Text>
        {upText}
        <br />
        <InfoWrapper>
          {parseStringToArray(data.projects).map((project) => {
            const redirectToProjectLocation = () => {
              handleClose();
              closeNotificationList();
              navigate(`/projects/${project.id}/location`);
            };
            return (
              <StyledButton
                small
                type="button"
                label="download"
                color="primary"
                variant="text"
                text={project.name}
                onClick={redirectToProjectLocation}
              />
            );
          })}
        </InfoWrapper>
        {downText}
      </Text>
    </ConfirmationDialog>
  );
};

export default ProjectLocationNotAssigned;
