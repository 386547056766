// const GET_IS_DOWNLOADING_DATA = 'GET_IS_DOWNLOADING_DATA';
const SET_IS_DOWNLOADING_DATA = 'SET_IS_DOWNLOADING_DATA';

export const setIsDownloadingData = (isDownloadingData) => ({
  type: SET_IS_DOWNLOADING_DATA,
  isDownloadingData,
});

const initialState = {
  isDownloadingData: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_DOWNLOADING_DATA: {
      return {
        ...state,
        isDownloadingData: action.isDownloadingData,
      };
    }
    default:
      return state;
  }
};
