import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROLES } from 'services/AuthService';

import * as NotificationsActions from 'notifications/store/notification.reducer';
import {
  Text, HighlightedText, Subtitle, InfoWrapper, Contact, ButtonOpenProject, StyledIcon, StyledArrow,
} from './styles';

const TextLine = ({ text, value, withoutNewLine }) => {
  const [startText, endText] = text.split('{placeholder}');

  return (
    <div>
      <span>
        {startText}
        {!withoutNewLine && <br />}
        <HighlightedText>{value}</HighlightedText>
        {endText}
      </span>
    </div>
  );
};

const getFormDescription = (notify) => {
  const { data, isCompany } = notify;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openProjectDetails = () => {
    navigate(`/projects/${data.projectId}/details`);
    dispatch(NotificationsActions.setPendingNotification(notify));
    dispatch(NotificationsActions.setNotificationsView(''));
    dispatch(NotificationsActions.setSlideOut(true));
  };

  return (
    <Text>
      <TextLine
        value={isCompany ? data.companyName : data.userFullName}
        text="The registration form has been submitted by {placeholder}."
        withoutNewLine
      />
      <div>Please verify the data below before providing the access.</div>
      <br />
      <InfoWrapper>

        <Contact>
          {isCompany && (
            <Subtitle>Primary Contact:</Subtitle>
          )}
          <TextLine value={data.userFullName} text="Name: {placeholder}" />
          <TextLine value={data.projectName} text="Project Name: {placeholder}" />
          <TextLine value={ROLES[data.userRole]} text="Role: {placeholder}" />
          <TextLine value={data.userDesignation} text="Designation: {placeholder}" />
          <TextLine value={data.email} text="Email: {placeholder}" />
          <TextLine value={data.userAddress} text="Address: {placeholder}" />
          <TextLine value={data.userPhone} text="Phone: {placeholder}" />
        </Contact>

        {isCompany && (
          <Contact>
            <Subtitle>Company Info:</Subtitle>
            <TextLine value={data.companyName} text="Company Name: {placeholder}" />
            {data.companyAddress && <TextLine value={data.companyAddress} text="Address: {placeholder}" />}
            {data.companyPhone && <TextLine value={data.companyPhone} text="Phone: {placeholder}" />}
          </Contact>
        )}

      </InfoWrapper>
      <ButtonOpenProject onClick={openProjectDetails}>
        <StyledIcon icon="details" noWrapper />
        <div>Open Project</div>
        <StyledArrow icon="chevronRight" noWrapper />
      </ButtonOpenProject>
    </Text>
  );
};

const FormDescription = ({ notify }) => getFormDescription(notify);

export default FormDescription;
