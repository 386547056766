import React from 'react';
import {
  MenuItem as MuiMenuItem,
  Tooltip as MuiTooltip,
} from '@mui/material';

import { IconWrapper, TextWrapper, MenuItemWrapper, StyledIcon, TooltipBlock } from './styles';

// https://github.com/devias-io/react-material-dashboard/issues/14#issuecomment-504315969
const MenuItem = React.forwardRef(({
  icon,
  text,
  onClick,
  disabled,
  tooltip,
}, ref) => (
  <MenuItemWrapper>
    <MuiMenuItem
      onClick={onClick}
      ref={ref}
      disabled={disabled}
    >
      <IconWrapper>
        <StyledIcon icon={icon} noWrapper />
      </IconWrapper>
      <TextWrapper>
        {text}
      </TextWrapper>
    </MuiMenuItem>

    {tooltip && (
      <MuiTooltip arrow title={tooltip}>
        <TooltipBlock />
      </MuiTooltip>
    )}
  </MenuItemWrapper>
));

export default MenuItem;
