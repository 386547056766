import { styled as styledMui } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

export const StyledToastContainer = styledMui(ToastContainer)(({ theme }) => ({
  zIndex: 9999,
  WebkitTransform: "translateZ(9999px)",
  position: "fixed",
  padding: 4,
  width: 320,
  boxSizing: "border-box",
  color: "#fff",
  "> .Toastify__toast": {
    position: "relative",
    minHeight: 64,
    boxSizing: "border-box",
    marginBottom: "1rem",
    padding: 8,
    borderRadius: 1,
    boxShadow:
      "0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05)",
    display: "flex",
    justifyContent: "space-between",
    maxHeight: 800,
    overflow: "hidden",
    fontFamily: "sans-serif",
    cursor: "pointer",
    direction: "ltr",

    "> .Toastify__close-button": {
      color: theme.palette.text.main,
      background: "transparent",
      outline: "none",
      border: "none",
      padding: 0,
      cursor: "pointer",
      opacity: 0.7,
      transition: "0.3s ease",
      alignSelf: "flex-start",
      zIndex: 1,
      svg: {
        height: 16,
        width: 14
      },
      '&:hover': {
        opacity: 1,
      },
      '&:focus': {
        opacity: 1,
      }
    },
  },
  ".Toastify__toast-container--top-right": {
    top: 74,
    right: 0,
    padding: 0,
  },
  ".Toastify__toast--warning, .Toastify__toast--error, .Toastify__toast--success, .Toastify__toast--info":
    {
      background: theme.palette.input.color,
      borderLeft: "7px solid",
      borderRadius: 4,
      color: theme.palette.text.main,
      fontFamily: "Graphik LC",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: "14px",
      padding: "16px 16px 16px 20px",
    },
  ".Toastify__toast--success": {
    borderColor: theme.palette.toast.success,
  },
  ".Toastify__toast--warning": {
    borderColor: theme.palette.toast.alert,
  },
  ".Toastify__toast--error": {
    borderColor: theme.palette.toast.error,
  },
  ".Toastify__toast--info": {
    borderColor: theme.palette.toast.info,
  },
  ".Toastify__toast-container": {
    minWidth: 350,
  },
}));
