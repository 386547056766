import React from 'react';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';

import { HighlightedText, ConfirmationWrapper } from './styles';

const ConfirmationText = ({ text, value, italic }) => {
  const theme = useTheme();

  if (Array.isArray(value)) {
    const plainTexts = text.split('{placeholder}');

    return plainTexts.map((txt, valueIndex) => {
      return (
        <ConfirmationWrapper
          // className={cx(theme.light && 'light-theme')}
          key={valueIndex}
          italic={italic}
        >
          {txt}
          <HighlightedText>
            {value[valueIndex]}
          </HighlightedText>
        </ConfirmationWrapper>
      );
    });
  }

  const [startText, endText] = text.split('{placeholder}');

  return (
    <ConfirmationWrapper
      className='confirmation-wrapper-text'
      // className={cx(theme.light && 'light-theme')}
      italic={italic}
    >
      {startText}
      <HighlightedText>
        {value}
      </HighlightedText>
      {endText}
    </ConfirmationWrapper>
  );
};

export default ConfirmationText;
